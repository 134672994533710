import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllImplantacaoAnimal } from '../service';
import {
  LIST_IMPLANTACAO_ANIMAL,
  LIST_IMPLANTACAO_ANIMAL_PENDING,
  LIST_IMPLANTACAO_ANIMAL_SUCCESS,
  LIST_IMPLANTACAO_ANIMAL_FAILURE
} from '../actionTypes';

function* sagaListAllImplantacaoAnimal(action) {
  yield put({ type: LIST_IMPLANTACAO_ANIMAL_PENDING })

  try {
    const list = yield call(findAllImplantacaoAnimal)
    
    yield put({ type: LIST_IMPLANTACAO_ANIMAL_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_IMPLANTACAO_ANIMAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllImplantacaoAnimal() {
  yield takeLatest(LIST_IMPLANTACAO_ANIMAL, sagaListAllImplantacaoAnimal)
}