import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { NEW_PLANTIO_MANEJO, TABLE_PLANTIO_MANEJO } from "@handler";
import { Button, Card, CardBody, CardHeader, ListGroup, ListGroupItem } from "reactstrap";
import { formatNumber, formatDateDDMMYYYY } from '@components/common/format';
import moment from "moment";
import IconTextInfoNoRegisterComponent from "@components/common/IconTextInfoNoRegisterComponent";

function ExecutionTab({
    area,
}) {
    const dispatch = useDispatch();

    const startSessionReducer = useSelector((state) => state.entities.startSessionReducer);
    const { fazendaSelecionada, safraAtiva } = startSessionReducer.configSession;
    const plantioManejoReducer = useSelector((state) => state.entities.plantioManejoReducer);

    useEffect(() => {
        dispatch({
            type: TABLE_PLANTIO_MANEJO,
            args: `?propriedade=${fazendaSelecionada.id}&safra=${safraAtiva.id}&status=0&talhao=${area.id}`
        });
    }, [area]);

    const renderItem = (leftText, rightText) => {
        return (
            <div className="activity-card-item">
                <span>{leftText}</span>
                <span>{rightText}</span>
            </div>
        );
    }

    const renderTotal = (label, total) => {
        return (
            <div className="activity-card-total">
                <span>{label}</span>
                <span>{'R$ ' + formatNumber(total, 2)}</span>
            </div>
        );
    }

    function renderDosagem(value) {
        if (!value.configLancAgricultura) {
            if (safraAtiva !== null && safraAtiva !== undefined && safraAtiva.cultura !== null && safraAtiva.cultura !== undefined) {
                return formatNumber(value.dosagemHa, 4) + ' ' + value.produtoInsumo.unidadeAplicacao.sigla + ((safraAtiva.cultura.formaLancPlantio === 'POR_HA') ? (fazendaSelecionada.unidadeArea === undefined || fazendaSelecionada.unidadeArea === null ? '/ha' : '/' + fazendaSelecionada.unidadeArea.sigla) : '');
            } else {
                return formatNumber(value.dosagemHa, 4) + ' ' + value.produtoInsumo.unidadeAplicacao.sigla;
            }
        } else {
            return formatNumber(value.dosagemHa, 4) + ' ' + value.produtoInsumo.unidadeAplicacao.sigla + ((value.configLancAgriculturaTipo === 'POR_HA') ? (fazendaSelecionada.unidadeArea === undefined || fazendaSelecionada.unidadeArea === null ? '/ha' : '/' + fazendaSelecionada.unidadeArea.sigla) : '');
        }
    };

    const renderCard = (item) => {
        return (
            <div className="card-map">
                <Card key={`activity_${item.id}`}>
                    <CardHeader />
                    <CardBody>
                        <ListGroup flush>
                            <ListGroupItem id="activity-group-item">
                                <h5>{item.procedimento.descricao}</h5>
                                <div>Data: {formatDateDDMMYYYY(item.data)}</div>
                                <div>Talhão: {item.talhao.nome}</div>
                            </ListGroupItem>
                            {(item.utilizaProdutoInsumo || item.utilizaMaquinario || item.utilizaFuncionario) && (
                                <ListGroupItem id="activity-group-item">
                                    {item.aplicacoes.map((aplicacao) => (
                                        renderItem(
                                            aplicacao.produtoInsumo.grupoText,
                                            renderDosagem(aplicacao)
                                        )
                                    ))}
                                    {item.utilizacaoMaquinarios.map((maq) => (
                                        renderItem(
                                            maq.maquinario.nome,
                                            maq.horasQuilometrosTotais
                                        )
                                    ))}
                                    {item.alocacaoFuncionarios.map((aFunc) => (
                                        renderItem(
                                            aFunc.funcionario.nome,
                                            aFunc.horasTrabalhadas
                                        )
                                    ))}
                                </ListGroupItem>
                            )}
                            <ListGroupItem id="activity-group-item">
                                {renderTotal(
                                    "Custo Estimado Talhão",
                                    item.custoTotalTalhao
                                )}
                                {renderTotal(
                                    "Custo Estimado / ha",
                                    item.custoTotalHa
                                )}
                            </ListGroupItem>
                        </ListGroup>
                    </CardBody>
                </Card>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Link to='/agricultura/execucao/novo' title='Novo'>
                <Button
                    onClick={() => {
                        dispatch({ type: NEW_PLANTIO_MANEJO, talhao: area });
                    }}
                    style={{ width: '100%' }}
                >
                    Novo Procedimento
                </Button>
            </Link>

            <div className="mt-2">
                {(plantioManejoReducer && plantioManejoReducer.table?.content?.length > 0) ?
                    plantioManejoReducer.table.content.map((item) => (
                        renderCard(item)
                    )) : <IconTextInfoNoRegisterComponent title='Nenhuma imagem NDVI encontrada' />
                }
            </div>
        </React.Fragment>
    );
}

export default React.memo(ExecutionTab);
