import { path, httpService } from '@commons';

///////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/dash/pec' };
///////////////////////////////////////////////////////////////////////////////


export function getTotaisPecuaria(propriedade, anoPecuario, inicio, fim) {
    return httpService.get({ ...args, args: '/totais/' + propriedade + '/' + anoPecuario + '?inicio=' + inicio + '&fim=' + fim });
}

export function getGraficoAnimaisAtivoPorLote(propriedade, anoPecuario, inicio, fim) {
    return httpService.get({ ...args, args: '/graficoAnimaisAtivoPorLote/' + propriedade + '/' + anoPecuario + '?inicio=' + inicio + '&fim=' + fim });
}

export function getGraficoAnimaisAtivoPorCategoria(propriedade, anoPecuario, inicio, fim) {
    return httpService.get({ ...args, args: '/graficoAnimaisAtivoPorCategoria/' + propriedade + '/' + anoPecuario + '?inicio=' + inicio + '&fim=' + fim });
}

export function getGraficoAnimaisAtivoPorSexo(propriedade, anoPecuario, inicio, fim) {
    return httpService.get({ ...args, args: '/graficoAnimaisAtivoPorSexo/' + propriedade + '/' + anoPecuario + '?inicio=' + inicio + '&fim=' + fim });
}

export function getGraficoAnimaisAtivoPesoPorLote(propriedade, anoPecuario, inicio, fim) {
    return httpService.get({ ...args, args: '/graficoAnimaisAtivoPesoPorLote/' + propriedade + '/' + anoPecuario + '?inicio=' + inicio + '&fim=' + fim });
}