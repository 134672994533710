import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  entradaEstoque,
  NEW_ENTRADA_ESTOQUE,
  NEW_ENTRADA_ESTOQUE_SUCCESS,
} from '@handler';

function* sagaNewEntradaEstoque(action) {

  yield put({ type: NEW_ENTRADA_ESTOQUE_SUCCESS, registro: 
    {
      ...entradaEstoque.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
    } });
}

export default function* watchNewEntradaEstoque() {
  yield takeLatest(NEW_ENTRADA_ESTOQUE, sagaNewEntradaEstoque)
}
