import { takeLatest, put, call } from 'redux-saga/effects';

import { listPerfilUsuarioWhiteLabel } from '../service';
import {
  TABLE_PERFIL_USUARIO_WHITE_LABEL,
  TABLE_PERFIL_USUARIO_WHITE_LABEL_PENDING,
  TABLE_PERFIL_USUARIO_WHITE_LABEL_SUCCESS,
  TABLE_PERFIL_USUARIO_WHITE_LABEL_FAILURE
} from '../actionTypes';

function* sagaListPerfilUsuarioWhiteLabel(action) {
  yield put({ type: TABLE_PERFIL_USUARIO_WHITE_LABEL_PENDING });

  try {
    const table = yield call(listPerfilUsuarioWhiteLabel, action.args);

    yield put({ type: TABLE_PERFIL_USUARIO_WHITE_LABEL_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_PERFIL_USUARIO_WHITE_LABEL_FAILURE });
  }
}

export default function* watchListPerfilUsuarioWhiteLabel() {
  yield takeLatest(TABLE_PERFIL_USUARIO_WHITE_LABEL, sagaListPerfilUsuarioWhiteLabel)
}