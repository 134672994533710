export const TIPO_CLIENTE_AGRARE_LOCAL_TRABALHO_ENUM = [
    {
        id: 'PROPRIEDADE_RURAL',
        descricao: "Propriedade Rural"
    },
    {
        id: 'CONSULTORIA',
        descricao: "Consultoria"
    },
    {
        id: 'COMPANHIA_GRUPO_AGRICOLA',
        descricao: "Companhia ou Grupo Agrícola"
    },
    {
        id: 'COOPERATIVA',
        descricao: "Cooperativa"
    },
    {
        id: 'REVENDA_INSUMOS',
        descricao: "Revenda de Insumos"
    },
    {
        id: 'ESCRITORIO_CONTABIL',
        descricao: "Escritório Contábil"
    },
    {
        id: 'PESQUISA_ESTUDOS',
        descricao: "Pesquisa ou Estudos"
    },
];

export function getTipoLocalTrabalhoDescricaoById(id) {
    const tipo = TIPO_CLIENTE_AGRARE_LOCAL_TRABALHO_ENUM.find(item => item.id === id);
    return tipo ? tipo.descricao : null;
}