import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createManejoPlanejamentoCadastro, updateManejoPlanejamentoCadastro } from '../service';
import {
  CONFIG_SESSION,
  CREATE_MANEJO_PLANEJAMENTO_CADASTRO,
  CREATE_MANEJO_PLANEJAMENTO_CADASTRO_PENDING,
  CREATE_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS,
  CREATE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE,
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

import { formatDateDDMMYYYY, isDateValidVerifyDateBetween } from '@components/common/format'


function* sagaCreateManejoPlanejamentoCadastro(action) {
  yield put({ type: CREATE_MANEJO_PLANEJAMENTO_CADASTRO_PENDING })

  try {
    const anoPecuarioAtivo = JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo;
    var dateFrom = anoPecuarioAtivo.dataInicial;
    var dateTo = anoPecuarioAtivo.dataFinal;
    var dateCheck = action.data.data;

    if (!isDateValidVerifyDateBetween(dateFrom, dateTo, dateCheck)) {
      yield put({ type: CREATE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE, registro: action.data })

      AlertWarning('Data informada fora do período do ano pecuário ativo. Período deve ser entre ' 
      + formatDateDDMMYYYY(dateFrom) + ' até ' + formatDateDDMMYYYY(dateTo) + '!');
    } else {
       let totalAnimais = 0;
      for (let index = 0; index < action.data.animais.length; index++) {
        totalAnimais += action.data.animais[index].quantidade;
      }
      action.data.nroAnimais = totalAnimais;

      if (action.data.id === undefined || action.data.id === null) {
        var saved = yield call(createManejoPlanejamentoCadastro, {
          ...action.data,
          'forJson': 'ManejoPlanejamento',
          'sync': false,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          }
        });
      } else {
        yield call(updateManejoPlanejamentoCadastro,
          {
            ...action.data,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS, registro: action.data })

      history.push('/pecuaria/manejo/planejamento');

      AlertSuccess('Manejo salvo com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o manejo! Tente novamente...');
  }
}

export default function* watchCreateManejoPlanejamentoCadastro() {
  yield takeLatest(CREATE_MANEJO_PLANEJAMENTO_CADASTRO, sagaCreateManejoPlanejamentoCadastro)
}
