
export const polygonDrawing = {
    editable: true,
    draggable: false,
    fillColor: "lightblue",
    strokeColor: "red",
    clickable: true,
    visible: true
}

export const polygonSelected = {
    editable: false,
    draggable: false,
    fillColor: "lightblue",
    strokeColor: "orange",
    clickable: true,
    visible: true
}

export const polygonDefault = {
    fillColor: "orange",
    strokeColor: "orange",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    visible: true
}

export const polygonNdvi = {
    fillColor: null,
    strokeColor: "orange",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    visible: true
}
