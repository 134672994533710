


import watchNewEvolucaoAnimal from './saga/new';
import watchFindEvolucaoAnimal from './saga/find';
import watchCreateEvolucaoAnimal from './saga/create';
import watchListAllEvolucaoAnimal from './saga/listAll';
import watchListEvolucaoAnimal from './saga/listTable';
import watchDeleteEvolucaoAnimal from './saga/delete';

export const evolucaoAnimalSaga = [
    watchNewEvolucaoAnimal(),
    watchFindEvolucaoAnimal(),
    watchCreateEvolucaoAnimal(),
    watchListAllEvolucaoAnimal(),
    watchListEvolucaoAnimal(),
    watchDeleteEvolucaoAnimal(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
