import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { formatDateDDMMYYYY } from '@components/common/format';
import { Button } from "reactstrap";
import { TABLE_NDVI, SELECT_NDVI, HAS_AUTHORITY } from "@handler";
import { decodePath, getPolygonCenter } from "../../utils/mapFunctions";
import './styles.css';
import IconTextInfoNoRegisterComponent from "@components/common/IconTextInfoNoRegisterComponent";

function NdviWindow({
    onClose,
    setCenter
}) {
    const dispatch = useDispatch();

    const startSessionReducer = useSelector((state) => state.entities.startSessionReducer);
    const { fazendaSelecionada } = startSessionReducer.configSession;
    const ndviReducer = useSelector((state) => state.entities.ndviReducer);

    useEffect(() => {
        dispatch({ type: HAS_AUTHORITY, role: 'AGRIC_NDVI', license: 'MODULO_NDVI' });
        dispatch({ type: TABLE_NDVI, args: `?propriedade=${fazendaSelecionada.id}` });
    }, []);

    const renderCard = (item) => {
        return (
            <div
                className="ndvi-card"
                key={`activity_${item.id}`}
                onClick={() => {
                    dispatch({ type: SELECT_NDVI, args: item })
                    setCenter(getPolygonCenter(decodePath(item.talhao.mapaPolygonPath)));
                }}
            >
                <img className="ndvi-image" src={item.imageUrl} alt={item.fileName} />
                <div className="ndvi-footer">
                    <h4 className="ndvi-info">{formatDateDDMMYYYY(item.date)}</h4>
                    <h5 className="ndvi-info-subtitle">{item.talhao?.nome ?? ''}</h5>
                    <span className="ndvi-info-subtitle">Satélite: Sentinel 2 L2A</span>
                </div>
            </div>
        );
    }

    return (
        <div className="map-menu-activity-container">
            <div className="map-menu-weather-header">
                <h3>Imagens Satélite - NDVI</h3>
                <Button className="btn btn-light" onClick={onClose}>
                    <i className='fa fa-arrow-left' />
                </Button>
            </div>

            {(ndviReducer && ndviReducer.table?.content?.length > 0) ?
                ndviReducer.table.content.map((item) => (
                    renderCard(item)
                )) : <IconTextInfoNoRegisterComponent title='Nenhuma imagem NDVI encontrada' />
            }
        </div>
    );
}

export default React.memo(NdviWindow);
