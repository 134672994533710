import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createMotoristaCadastro, updateMotoristaCadastro } from '../service';
import {
  CREATE_MOTORISTA_CADASTRO,
  CREATE_MOTORISTA_CADASTRO_PENDING,
  CREATE_MOTORISTA_CADASTRO_SUCCESS,
  CREATE_MOTORISTA_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaCreateMotoristaCadastro(action) {
  yield put({ type: CREATE_MOTORISTA_CADASTRO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createMotoristaCadastro, formatJsonRemoveEmptyOrNull(action.data));
    } else {
      yield call(updateMotoristaCadastro,
        formatJsonRemoveEmptyOrNull({
          ...action.data,
          id: action.data.id
        })
      );
    }

    yield put({ type: CREATE_MOTORISTA_CADASTRO_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/motoristaCadastro');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_MOTORISTA_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateMotoristaCadastro() {
  yield takeLatest(CREATE_MOTORISTA_CADASTRO, sagaCreateMotoristaCadastro)
}
