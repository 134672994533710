


import watchNewTipoEmbalagemWhiteLabel from './saga/new';
import watchFindTipoEmbalagemWhiteLabel from './saga/find';
import watchCreateTipoEmbalagemWhiteLabel from './saga/create';
import watchListTipoEmbalagemWhiteLabel from './saga/listTable';
import watchDeleteTipoEmbalagemWhiteLabel from './saga/delete';
import watchCancelTipoEmbalagemWhiteLabelRapido from './saga/cancel';

export const tipoEmbalagemWhiteLabelSaga = [
    watchNewTipoEmbalagemWhiteLabel(),
    watchFindTipoEmbalagemWhiteLabel(),
    watchCreateTipoEmbalagemWhiteLabel(),
    watchListTipoEmbalagemWhiteLabel(),
    watchDeleteTipoEmbalagemWhiteLabel(),
    watchCancelTipoEmbalagemWhiteLabelRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
