import { takeLatest, put, call } from 'redux-saga/effects';

import { listManejoPlanejamentoCadastro } from '../service';
import {
    TABLE_MANEJO_PLANEJAMENTO_CADASTRO,
    TABLE_MANEJO_PLANEJAMENTO_CADASTRO_PENDING,
    TABLE_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS,
    TABLE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListManejoPlanejamentoCadastro(action) {
  yield put({ type: TABLE_MANEJO_PLANEJAMENTO_CADASTRO_PENDING })

  try {
    const table = yield call(listManejoPlanejamentoCadastro, action.args)

    yield put({ type: TABLE_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE })
  }
}

export default function* watchListManejoPlanejamentoCadastro() {
  yield takeLatest(TABLE_MANEJO_PLANEJAMENTO_CADASTRO, sagaListManejoPlanejamentoCadastro)
}