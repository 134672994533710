import { takeLatest, put, call } from 'redux-saga/effects';

import { novoPerfilUsuarioWhiteLabel } from '../service';
import {
  NEW_PERFIL_USUARIO_WHITE_LABEL,
  NEW_PERFIL_USUARIO_WHITE_LABEL_PENDING,
  NEW_PERFIL_USUARIO_WHITE_LABEL_SUCCESS,
  NEW_PERFIL_USUARIO_WHITE_LABEL_FAILURE,
} from '@handler';
import { getJsonPerfilWl } from '../../../common/perfil/service';

function* sagaNewPerfilUsuarioWhiteLabel(action) {
  yield put({ type: NEW_PERFIL_USUARIO_WHITE_LABEL_PENDING })

  try {
    var registro = yield call(novoPerfilUsuarioWhiteLabel, action.id);

    registro = getJsonPerfilWl(registro);

    yield put({
      type: NEW_PERFIL_USUARIO_WHITE_LABEL_SUCCESS,
      registro: {
        ...registro,
        showPartnerProfile: false,
        permissionRoleWlRegisterUser: false,
        permissionRoleWlRegisterUserProfile: false,
        permissionRoleWlRegisterProduct: false,
        permissionRoleWlRegisterProductView: false,
        permissionRoleWlRegisterPartner: false,
        permissionRoleWlRegisterFarmer: false,
        permissionRoleWlRegisterGroupFarmer: false,
        permissionRoleWlRegisterKindPacking: false,
      },
      isCadastroRapido: action.isCadastroRapido,
    });
  } catch (error) {
    yield put({ type: NEW_PERFIL_USUARIO_WHITE_LABEL_FAILURE });
  }
}

export default function* watchNewPerfilUsuarioWhiteLabel() {
  yield takeLatest(NEW_PERFIL_USUARIO_WHITE_LABEL, sagaNewPerfilUsuarioWhiteLabel)
}
