import { takeLatest, put, call } from 'redux-saga/effects';

import { findContaBancoCadastro } from '../service';
import {
  FIND_CONTA_BANCO_CADASTRO,
  FIND_CONTA_BANCO_CADASTRO_PENDING,
  FIND_CONTA_BANCO_CADASTRO_SUCCESS,
  FIND_CONTA_BANCO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindContaBancoCadastro(action) {
  yield put({ type: FIND_CONTA_BANCO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findContaBancoCadastro, action.id);

    if (!registro.saldoInicialImplantado){
      registro.saldoInicial = {
        data: '',
        valor: 0
      }
    }

    yield put({ type: FIND_CONTA_BANCO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_CONTA_BANCO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindContaBancoCadastro() {
  yield takeLatest(FIND_CONTA_BANCO_CADASTRO, sagaFindContaBancoCadastro)
}
