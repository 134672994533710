import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/planejamento' };
/////////////////////////////////////////////////////////////////////////////////

export function createPlanejamento(payload) {
  return httpService.post(args, payload);
}

export function createPlanejamentoMulti(payload) {
  return httpService.post({ ...args, args: '/saveAll' }, payload);
}

export function updatePlanejamento(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listPlanejamento(idPropriedade, idSafraAtiva, datas, where) {
  return httpService.get({ ...args, args: `/getLancamentosSafraResumed?propriedade=${idPropriedade}&safra=${idSafraAtiva}&datas=${datas}${where}` });
}

export function listPlantioManejoTable(param) {
  return httpService.get({...args, args: param});
}

export function findPlanejamento(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deletePlanejamento(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function getCustoTotalSafra(idPropriedade, idSafraAtiva, datas, where) {
  return httpService.get({ ...args, args: `/getTotaisCustoSafra?propriedade=${idPropriedade}&safra=${idSafraAtiva}&datas=${datas}${where}` });
}

export function getRelatorioAtividadesPlanejamentoTalhaoSintetico(idPropriedade, idSafraAtiva, datas, where) {
  return httpService.getPdf({ ...args, args: `/relatorioAtividadesTalhaoSintetico/pdf?propriedade=${idPropriedade}&safra=${idSafraAtiva}&datas=${datas}${where}` });
}

export function getRelatorioAtividadesPlanejamentoTalhaoAnalitico(idPropriedade, idSafraAtiva, datas, where, idAplicacao) {
  return httpService.getPdf({ ...args, args: `/relatorioAtividadesTalhaoAnalitico/pdf?propriedade=${idPropriedade}&safra=${idSafraAtiva}&datas=${datas}${where}${idAplicacao}` });
}