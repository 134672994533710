import { takeLatest, put, call } from 'redux-saga/effects';

import { findPerfil, getJsonPerfil } from '../service';
import {
  FIND_PERFIL,
  FIND_PERFIL_PENDING,
  FIND_PERFIL_SUCCESS,
  FIND_PERFIL_FAILURE
} from '../actionTypes';
import { findAllPropriedadeCadastro } from '@handler/common/propriedadeCadastro';

function* sagaFindPerfil(action) {
  yield put({ type: FIND_PERFIL_PENDING, id: action.id })

  try {
    var registro = yield call(findPerfil, action.id);

    registro = getJsonPerfil(registro);


    const listPropriedades = yield call(findAllPropriedadeCadastro);

    let propriedades = [];
    for (let index = 0; index < listPropriedades.length; index++) {
      const prop = listPropriedades[index];
      let propCadastrada = false;
      let idPropCadastrada;

      for (let j = 0; j < registro.perfilPropriedades.length; j++) {
        if (registro.perfilPropriedades[j].propriedade.id === prop.id) {
          propCadastrada = true;
          idPropCadastrada = j;
        }
      }

      if (!propCadastrada) {
        propriedades = propriedades.concat({
          propriedade: prop,
          permiteAcesso: false,
        })
      } else {
        propriedades = propriedades.concat({
          ...registro.perfilPropriedades[idPropCadastrada]
        })
      }
    }

    yield put({
      type: FIND_PERFIL_SUCCESS, registro: {
        ...registro,
        perfilPropriedades: propriedades,
      }
    })
  } catch (error) {
    yield put({ type: FIND_PERFIL_FAILURE })
  }
}

export default function* watchFindPerfil() {
  yield takeLatest(FIND_PERFIL, sagaFindPerfil)
}
