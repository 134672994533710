import * as Yup from 'yup';

const newRegistro = {
    tipoOcorrencia: 'UNICA',
    data: '',
    dataEmissao: '',
    numeroDoc: '',
    valor: 0,
    pessoa: null,
    contaGerencial: null,
    historico: '',
    automatico: false,
    obs: '',
    forJson: 'contareceber',
    parcelas: [],
    dataPrimeiraParcela: '',
    numeroParcelas: null,
    contaPaga: false,
    tipoPagamento: null,
    contaCaixa: null,
    contaBanco: null,
};

const validation = Yup.object().shape({
    tipoOcorrencia: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    data: Yup.date().when('tipoOcorrencia', {
        is: (tipoOcorrencia) => tipoOcorrencia === 'UNICA',
        then: Yup.date()
            .required('Este campo é obrigatório')
    }).nullable(),
    dataEmissao: Yup.date()
        .required('Este campo é obrigatório'),
    contaGerencial: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    historico: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(255, 'Campo deve ter menos do que 255 caracteres')
        .required('Este campo é obrigatório'),
    valor: Yup.number()
        .typeError('Este campo é obrigatório')
        .positive('Por Favor, informe um valor maior que zero')
        .required("Este campo é obrigatório"),
    numeroDoc: Yup.string()
        .max(60, 'Campo deve ter menos do que 60 caracteres'),
    obs: Yup.string()
        .max(2000, 'Campo deve ter menos do que 2000 caracteres'),
});

export const contaReceber = {
    newRegistro,
    validation
}