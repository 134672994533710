import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/pecuaria/transferencia' };
/////////////////////////////////////////////////////////////////////////////////

export function createTransferenciaAnimal(payload) {
  return httpService.post(args, payload);
}

export function updateTransferenciaAnimal(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listTransferenciaAnimal(param) {
  return httpService.get({ ...args, args: param });
}

export function findTransferenciaAnimal(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllTransferenciaAnimal() {
  return httpService.get({ ...args, path: '/pecuaria/transferencia/all' });
}

export function deleteTransferenciaAnimal(id) {
  return httpService.del({ ...args, args: `/${id}` });
}