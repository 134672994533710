import { takeLatest, put, call } from 'redux-saga/effects';

import { listPlantioManejoTable } from '../service';
import {
  TABLE_PLANEJAMENTO,
  TABLE_PLANEJAMENTO_PENDING,
  TABLE_PLANEJAMENTO_SUCCESS,
  TABLE_PLANEJAMENTO_FAILURE
} from '../actionTypes';

function* sagaListPlanejamento(action) {
  yield put({ type: TABLE_PLANEJAMENTO_PENDING })

  try {
    const table = yield call(listPlantioManejoTable, action.args)
    yield put({ type: TABLE_PLANEJAMENTO_SUCCESS, table: table })

  } catch (error) {

    yield put({ type: TABLE_PLANEJAMENTO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListPlanejamento() {
  yield takeLatest(TABLE_PLANEJAMENTO, sagaListPlanejamento)
}