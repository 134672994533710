import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import other Dropdown
// import LanguageDropdown from "../../components/LanguageDropdown";
// import NotificationDropdown from "../../components/NotificationDropdown";
import ProfileMenu from "./ProfileMenu";

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    // this.props.toggleMenuCallback();
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  /**
   * Toggle full screen
   */
  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar-intranet">
          <div className="navbar-header">
            <div className="d-flex">
              <div
                className="navbar-brand-box"
              >

                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img
                      alt=""
                      height="50"
                      className={`logo-light-image`}
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      alt=""
                      height="50"
                      className={`logo-light-image`}
                    /> 
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img
                      alt=""
                      height="50"
                      className={`logo-light-image`}
                    /> 
                  </span>
                  <span className="logo-lg">
                    <img
                      alt=""
                      height="50"
                      className={`logo-light-image`}
                    /> 
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm mr-2 font-size-24 d-lg-none header-item waves-effect waves-light"
                onClick={this.toggleMenu}
              >
                <i className="mdi mdi-menu menu-color-mobile"></i>
              </button>
            </div>

            <div className="d-flex">
              {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <span className="fa fa-search"></span>
                </div>
              </form> */}

              {/* <LanguageDropdown /> */}
              {/* <div className="dropdown d-none d-lg-inline-block">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                >
                  <i className="mdi mdi-fullscreen" style={{ color: '#175B28' }} />
                </button>
              </div> */}

              {/* <NotificationDropdown /> */}

              <ProfileMenu />

              {/* <div className="dropdown d-inline-block">
                <button
                  type="button"
                  onClick={this.toggleRightbar}
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="mdi mdi-settings-outline"></i>
                </button>
              </div>

              <div className="dropdown d-inline-block">
                <button
                  type="button"
                  onClick={this.toggleRightbar}
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default TopBar;
