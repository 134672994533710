import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';


import {
    CONFIG_SESSION,
    TOTAIS_DASHBOARD_FINANCEIRO,
    TOTAIS_DASHBOARD_FINANCEIRO_PENDING,
    TOTAIS_DASHBOARD_FINANCEIRO_SUCCESS,
    TOTAIS_DASHBOARD_FINANCEIRO_FAILURE,
    GRAFICO_SALDO_CONTAS_DASHBOARD_FINANCEIRO_PENDING,
    GRAFICO_SALDO_CONTAS_DASHBOARD_FINANCEIRO_SUCCESS,
    GRAFICO_SALDO_CONTAS_DASHBOARD_FINANCEIRO_FAILURE,
} from '@handler';
import { formatDateDDMMYYYY } from '@components/common/format';
import { getdadosGraficoSaldoContas, getTotaisFinanceiro } from '..';

function* sagaTotaisFinanceiro(action) {
    // GET TOTAIS
    yield put({ type: TOTAIS_DASHBOARD_FINANCEIRO_PENDING })

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    var datas = formatDateDDMMYYYY(action.dataInicial) + '_' + formatDateDDMMYYYY(action.dataFinal);

    try {
        const totais = yield call(getTotaisFinanceiro,
            idFazenda, datas);
        yield put({
            type: TOTAIS_DASHBOARD_FINANCEIRO_SUCCESS, totais: totais
        })

    } catch (error) {
        yield put({ type: TOTAIS_DASHBOARD_FINANCEIRO_FAILURE })

    }


    // GET GRAFICO SALDO CONTAS

    yield put({ type: GRAFICO_SALDO_CONTAS_DASHBOARD_FINANCEIRO_PENDING })

    try {
        const dadosGraficoSaldoContas = yield call(getdadosGraficoSaldoContas,
            idFazenda,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        let chartData = [['', 'Saldo']];
        for (let i = 0; i < dadosGraficoSaldoContas.length; i += 1) {
            chartData.push([dadosGraficoSaldoContas[i].text, dadosGraficoSaldoContas[i].value])
        }

        yield put({ type: GRAFICO_SALDO_CONTAS_DASHBOARD_FINANCEIRO_SUCCESS, dadosGraficoSaldoContas: chartData })

    } catch (error) {
        yield put({ type: GRAFICO_SALDO_CONTAS_DASHBOARD_FINANCEIRO_FAILURE })

    }
}

export default function* watchTotaisFinanceiro() {
    yield takeLatest(TOTAIS_DASHBOARD_FINANCEIRO, sagaTotaisFinanceiro)
}
