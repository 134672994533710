import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA,
  NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA_PEDING,
  NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA_SUCCESS,
} from '@handler';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';
import { getLancamentos } from '../service';
import { LIST_TRANSF_CONTA_BANCO_CAIXA_SUCCESS } from '../actionTypes';

function* sagaNewFilterTransferenciaContaBancoCaixa(action) {
  yield put({ type: NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA_PEDING })

  var date = new Date();
  let dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
  let dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

  const datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);

  const list = yield call(getLancamentos, idFazenda, datas);

  yield put({
    type: LIST_TRANSF_CONTA_BANCO_CAIXA_SUCCESS, list: list, newFilter: {
      dataInicial,
      dataFinal,
    }
  })


  yield put({
    type: NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA_SUCCESS, newFilter: {
      dataInicial,
      dataFinal,
    }
  });
}

export default function* watchNewFilterTransferenciaContaBancoCaixa() {
  yield takeLatest(NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA, sagaNewFilterTransferenciaContaBancoCaixa)
}
