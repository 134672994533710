import { takeLatest, put, call } from 'redux-saga/effects';

import { findUserAuthResumed } from '../service';
import {
  FIND_USUARIO_RESUMED,
  FIND_USUARIO_RESUMED_PENDING,
  FIND_USUARIO_RESUMED_SUCCESS,
  FIND_USUARIO_RESUMED_FAILURE
} from '../actionTypes';

function* sagaFindUsuarioResumed(action) {
  yield put({ type: FIND_USUARIO_RESUMED_PENDING, id: action.id })

  try {
    const registro = yield call(findUserAuthResumed, action.id);

    yield put({ type: FIND_USUARIO_RESUMED_SUCCESS, registro: registro })
  } catch (error) {
    yield put({ type: FIND_USUARIO_RESUMED_FAILURE })
  }
}

export default function* watchFindUsuarioResumed() {
  yield takeLatest(FIND_USUARIO_RESUMED, sagaFindUsuarioResumed)
}
