


import watchNewControleContaBanco from './saga/new';
import watchNewFilterControleContaBanco from './saga/newFilter';
import watchNewFilterNextPageControleContaBanco from './saga/newFilterNextPage';
import watchFindControleContaBanco from './saga/find';
import watchCreateControleContaBanco from './saga/create';
import watchDeleteControleContaBanco from './saga/delete';

export const controleContaBancoSaga = [
    watchNewControleContaBanco(),
    watchNewFilterControleContaBanco(),
    watchNewFilterNextPageControleContaBanco(),
    watchFindControleContaBanco(),
    watchCreateControleContaBanco(),
    watchDeleteControleContaBanco(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
