import watchNewProcedimentoPecuarioCadastro from './saga/new';
import watchFindProcedimentoPecuarioCadastro from './saga/find';
import watchCreateProcedimentoPecuarioCadastro from './saga/create';
import watchListProcedimentoPecuarioCadastro from './saga/listTable';
import watchDeleteProcedimentoPecuarioCadastro from './saga/delete';
import watchCancelProcedimentoPecuarioCadastroRapido from './saga/cancel';

export const procedimentoPecuarioCadastroSaga = [
    watchNewProcedimentoPecuarioCadastro(),
    watchFindProcedimentoPecuarioCadastro(),
    watchCreateProcedimentoPecuarioCadastro(),
    watchListProcedimentoPecuarioCadastro(),
    watchDeleteProcedimentoPecuarioCadastro(),
    watchCancelProcedimentoPecuarioCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
