import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_GRUPO_CONTA_AGRARE_WHITE_LABEL_RAPIDO,
    CANCEL_GRUPO_CONTA_AGRARE_WHITE_LABEL_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelGrupoContaAgrareWhiteLabelRapido() {
  yield put({ type: CANCEL_GRUPO_CONTA_AGRARE_WHITE_LABEL_RAPIDO_SUCCESS})
}

export default function* watchCancelGrupoContaAgrareWhiteLabelRapido() {
  yield takeLatest(CANCEL_GRUPO_CONTA_AGRARE_WHITE_LABEL_RAPIDO, sagaCancelGrupoContaAgrareWhiteLabelRapido)
}