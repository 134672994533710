import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/pecuaria/movimentacao/venda' };
/////////////////////////////////////////////////////////////////////////////////

export function createVendaAnimal(payload) {
  return httpService.post(args, payload);
}

export function updateVendaAnimal(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listVendaAnimal(param) {
  return httpService.get({ ...args, args: param });
}

export function findVendaAnimal(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllVendaAnimal() {
  return httpService.get({ ...args, path: '/pecuaria/movimentacao/venda/all' });
}

export function deleteVendaAnimal(id) {
  return httpService.del({ ...args, args: `/${id}` });
}