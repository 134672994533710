import { takeLatest, put, call } from 'redux-saga/effects'

import { httpAuthService } from '@commons';
import { path } from '@commons/path';

import {
  LOGIN,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGIN_FAILURE
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

const login = async ({ email, password }) => {
  await httpAuthService.login(path, { email, password });
}


function* sagaLogin(action) {
  yield put({ type: LOGIN_PENDING })

  try {

    const newLogin = yield call(login, action.data);

    yield put({ type: LOGIN_SUCCESS, login: newLogin });
  } catch (error) {
    yield put({ type: LOGIN_FAILURE });
    
    AlertError(error.text);
  }

}

export default function* watchLogin() {
  yield takeLatest(LOGIN, sagaLogin);
}
