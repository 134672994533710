import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classname from "classnames";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    // if (matchingMenuItem) {
    //   this.activateParentDropdown(matchingMenuItem);
    // }
  }

  // activateParentDropdown = item => {
  //   item.classList.add("active");
  //   const parent = item.parentElement;

  //   if (parent) {
  //     parent.classList.add("active"); // li
  //     const parent2 = parent.parentElement;
  //     parent2.classList.add("active"); // li
  //     const parent3 = parent2.parentElement;
  //     if (parent3) {
  //       parent3.classList.add("active"); // li
  //       const parent4 = parent3.parentElement;
  //       if (parent4) {
  //         parent4.classList.add("active"); // li
  //         const parent5 = parent4.parentElement;
  //         if (parent5) {
  //           parent5.classList.add("active"); // li
  //         }
  //       }
  //     }
  //   }
  //   return false;
  // };

  closeAllMenu() {
    this.setState({
      MenuCadastroState: false,
      MenuAgriculturaState: false,
      MenuFinanceiroState: false,
      MenuEstoqueState: false,
      MenuFiscalState: false,
      MenuPecuariaState: false,
    });
    this.props.funcCloseMenu();
  }

  render() {
    const { startSessionReducer } = this.props;

    const moduloAgricultura = startSessionReducer.configSession !== null && startSessionReducer.configSession.moduloAtivo !== null && startSessionReducer.configSession.moduloAtivo === 'AGRICULTURA';
    const moduloPecuaria = startSessionReducer.configSession !== null && startSessionReducer.configSession.moduloAtivo !== null && startSessionReducer.configSession.moduloAtivo === 'PECUARIA';

    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
              >
                <div id="navigation">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/dashboard"
                        onClick={e => {
                          this.closeAllMenu();
                        }}>
                        <i className="mdi mdi-view-dashboard mr-2"></i>Dashboard
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/mapa"
                        onClick={e => {
                          this.closeAllMenu();
                        }}>
                        <i className="fa fa-map mr-2"></i>Mapa
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/calendario"
                        onClick={e => {
                          this.closeAllMenu();
                        }}>
                        <i className="fa fa-calendar mr-2"></i>Calendário
                      </Link>
                    </li>

                    {!moduloAgricultura ? <React.Fragment /> :
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle arrow-none"
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({ MenuAgriculturaState: !this.state.MenuAgriculturaState });
                          }}
                        >
                          <i className="fa fa-leaf mr-2"></i>Agricultura
                        </Link>

                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.MenuAgriculturaState
                          })}
                        >
                          <Link to="/agricultura/planejamento" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Planejamento
                          </Link>

                          <Link to="/agricultura/execucao" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Execução
                          </Link>

                          <Link to="/agricultura/colheita" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Colheita
                          </Link>
                          <hr />
                          <div className="dropdown">
                            <Link
                              to="/#"
                              className="dropdown-item dropdown-toggle arrow-none"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({
                                  EmailState: !this.state.EmailState
                                });
                              }}
                            >
                              Produção Agrícola <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: this.state.EmailState
                              })}
                              aria-labelledby="topnav-email"
                            >
                              <Link to="/agricultura/producao/venda" className="dropdown-item"
                                onClick={e => {
                                  this.closeAllMenu();
                                }}>
                                Venda de Produção
                              </Link>

                              <hr />

                              {/* <Link to="/agricultura/producao/transformacao" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Transformação Pós-Colheita
                          </Link> */}

                              <Link to="/agricultura/producao/implantacao" className="dropdown-item"
                                onClick={e => {
                                  this.closeAllMenu();
                                }}>
                                Implantação Estoque
                              </Link>

                              <Link to="/agricultura/producao/baixaManual" className="dropdown-item"
                                onClick={e => {
                                  this.closeAllMenu();
                                }}>
                                Baixa Manual Estoque
                              </Link>

                              <Link to="/agricultura/producao/transferencia" className="dropdown-item"
                                onClick={e => {
                                  this.closeAllMenu();
                                }}>
                                Transferência Estoque
                              </Link>

                              <hr />

                              <div className="dropdown">
                                <Link
                                  to="/#"
                                  className="dropdown-item dropdown-toggle arrow-none"
                                  onClick={e => {
                                    e.preventDefault();
                                    this.setState({
                                      EmailState: !this.state.EmailState
                                    });
                                  }}
                                >
                                  Relatórios <div className="arrow-down"></div>
                                </Link>
                                <div
                                  className={classname("dropdown-menu", {
                                    show: this.state.EmailState
                                  })}
                                  aria-labelledby="topnav-email"
                                >
                                  <Link to="/agricultura/producao/relatorioEstoque" className="dropdown-item"
                                    onClick={e => {
                                      this.closeAllMenu();
                                    }}>
                                    Estoque Produção
                                  </Link>
                                </div>
                              </div>

                            </div>
                          </div>

                          <hr />
                          <Link to="/pluviometroDigital" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Pluviômetro
                          </Link>
                        </div>
                      </li>
                    }


                    {!moduloPecuaria ? <React.Fragment /> :
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle arrow-none"
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({ MenuPecuariaState: !this.state.MenuPecuariaState });
                          }}
                        >
                          <i className="mdi mdi-cow mr-2"></i>Pecuária
                        </Link>

                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.MenuPecuariaState
                          })}
                        >
                          <Link to="/pecuaria/movimentacao/implantacao" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Implantar Estoque Animal
                          </Link>

                          <Link to="/pecuaria/movimentacao/compra" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Compra de Animais
                          </Link>
                          <Link to="/pecuaria/movimentacao/nascimento" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Nascimento
                          </Link>
                          <Link to="/pecuaria/movimentacao/venda" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Venda de Animais
                          </Link>
                          <Link to="/pecuaria/movimentacao/outrasSaidas" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Outras Saídas
                          </Link>

                          <hr />

                          <Link to="/pecuaria/relacaoAnimais" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Relação de Animais
                          </Link>

                          <hr />

                          <Link to="/pecuaria/transferencia" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Transferência de Campo
                          </Link>

                          <Link to="/pecuaria/evolucao" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Evolução Animal
                          </Link>

                          <Link to="/pecuaria/pesagem" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Controle de Peso
                          </Link>

                          <hr />

                          <Link to="/pecuaria/manejo/planejamento" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Planejar Manejo Sanitário
                          </Link>

                          <Link to="/pecuaria/manejo/execucao" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Executar Manejo Sanitário
                          </Link>

                          <hr />

                          <div className="dropdown">
                            <Link
                              to="/#"
                              className="dropdown-item dropdown-toggle arrow-none"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({
                                  EmailState: !this.state.EmailState
                                });
                              }}
                            >
                              Relatórios <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: this.state.EmailState
                              })}
                              aria-labelledby="topnav-email"
                            >
                              <Link to="/pecuaria/relatorio/movimentacaoAnimal" className="dropdown-item"
                                onClick={e => {
                                  this.closeAllMenu();
                                }}>
                                Movimentação Animal
                              </Link>

                              {/* <Link to="/pecuaria/relatorios/movimentacaoMes" className="dropdown-item"
                                onClick={e => {
                                  this.closeAllMenu();
                                }}>
                                Movimentação por Mês
                              </Link>

                              <Link to="/pecuaria/relatorios/movimentacaoMes" className="dropdown-item"
                                onClick={e => {
                                  this.closeAllMenu();
                                }}>
                                Animais por Campo
                              </Link> */}
                            </div>
                          </div>

                        </div>
                      </li>
                    }


                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuEstoqueState: !this.state.MenuEstoqueState });
                        }}
                      >
                        <i className="fa fa-boxes mr-2"></i>Estoque
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuEstoqueState
                        })}
                      >

                        <Link to="/estoque/entrada" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Entrada de Nota Fiscal
                        </Link>
                        <Link to="/estoque/implantacao" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Implantar Estoque
                        </Link>
                        <Link to="/estoque/baixarManual" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Baixa Manual de Estoque
                        </Link>
                        {/* <Link to="/estoque/entrada" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Ajustar Estoque
                        </Link> */}
                        <Link to="/estoque/transferencia" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Transferência Estoque
                        </Link>

                        <hr />

                        <Link to="/estoque/localEstoqueCadastro" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Local de Estoque
                        </Link>

                        <hr />

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                EmailState: !this.state.EmailState
                              });
                            }}
                          >
                            Relatórios <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.EmailState
                            })}
                            aria-labelledby="topnav-email"
                          >
                            <Link to="/estoque/movimentacao/estoqueAtual" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Estoque Atual
                            </Link>
                            <Link to="/estoque/movimentacao/estoquePorPeriodo" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Por Período
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>


                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuFinanceiroState: !this.state.MenuFinanceiroState });
                        }}
                      >
                        <i className="fa fa-hand-holding-usd mr-2"></i>Financeiro
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuFinanceiroState
                        })}
                      >
                        {/* <Link to="/financeiro/agendaFinanceira" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Agenda Financeira
                        </Link>

                        <hr /> */}

                        <Link to="/financeiro/contaPagar" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Contas à Pagar
                        </Link>

                        <Link to="/financeiro/contaReceber" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Contas à Receber
                        </Link>

                        <Link to="/financeiro/fluxoCaixa" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Fluxo de Caixa
                        </Link>

                        <hr />

                        <Link to="/financeiro/movimentacoesBancarias" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Movimentações Bancárias
                        </Link>

                        <Link to="/financeiro/movimentacoesCaixa" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Movimentações de Caixa
                        </Link>

                        <Link to="/financeiro/transferenciaContaBancoCaixa" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Transferência Banco/Caixa
                        </Link>

                        {/* <Link to="/financeiro/fluxoCaixaARealizar" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Fluxo de Caixa - À Realizar
                        </Link>

                        <Link to="/financeiro/fluxoCaixaRealizado" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Fluxo de Caixa - Realizado
                        </Link> */}

                        <hr />

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                EmailState: !this.state.EmailState
                              });
                            }}
                          >
                            Cadastros <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.EmailState
                            })}
                            aria-labelledby="topnav-email"
                          >
                            <Link to="/financeiro/contaBancoCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Conta Banco
                            </Link>
                            <Link to="/financeiro/contaCaixaCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Conta Caixa
                            </Link>
                            <Link to="/financeiro/planoContaCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Plano de Contas Gerencial
                            </Link>
                            {/* <Link to="/financeiro/contaCartaoCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Cartão
                            </Link> */}
                            {/* <Link to="/financeiro/contaChequePreDatadoCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Cheque Pré-Datado
                            </Link> */}
                          </div>
                        </div>
                      </div>
                    </li>


                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuFiscalState: !this.state.MenuFiscalState });
                        }}
                      >
                        <i className="fa fa-file-invoice-dollar mr-2"></i>Fiscal
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuFiscalState
                        })}
                      >

                        <Link to="/fiscal/monitoramento/nfeCompra" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Monitoramento NFe de Compra
                        </Link>
                        <hr />
                        <Link to="/fiscal/emitente" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Configuração Fiscal
                        </Link>
                      </div>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ MenuCadastroState: !this.state.MenuCadastroState });
                        }}
                      >
                        <i className="mdi mdi-tag-plus mr-2"></i>Cadastros
                      </Link>

                      <div
                        className={classname("dropdown-menu", {
                          show: this.state.MenuCadastroState
                        })}
                      >
                        {!moduloAgricultura ? <React.Fragment /> :
                          <React.Fragment>
                            <Link to="/culturaCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Cultura
                            </Link>

                            <Link to="/talhaoCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Talhão
                            </Link>

                            <Link to="/safraCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Safra
                            </Link>

                            <Link to="/procedimentoCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Procedimento
                            </Link>
                          </React.Fragment>
                        }


                        {!moduloPecuaria ? <React.Fragment /> :
                          <React.Fragment>

                            <Link to="/pecuaria/campoCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Campo
                            </Link>

                            <Link to="/pecuaria/anoPecuarioCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Ano Pecuário
                            </Link>

                            <Link to="/pecuaria/procedimentoPecuarioCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Procedimento Pecuário
                            </Link>

                            <div className="dropdown">
                              <Link
                                to="/#"
                                className="dropdown-item dropdown-toggle arrow-none"
                                onClick={e => {
                                  e.preventDefault();
                                  this.setState({
                                    EmailState: !this.state.EmailState
                                  });
                                }}
                              >
                                Animal <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: this.state.EmailState
                                })}
                                aria-labelledby="topnav-email"
                              >
                                <Link to="/pecuaria/animalCategoriaCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Categoria
                                </Link>

                                <Link to="/pecuaria/animalEspecieCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Espécie
                                </Link>

                                <Link to="/pecuaria/animalLoteCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Lote
                                </Link>

                                <Link to="/pecuaria/animalPelagemCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Pelagem
                                </Link>

                                <Link to="/pecuaria/animalRacaCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Raça
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>}

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                EmailState: !this.state.EmailState
                              });
                            }}
                          >
                            Produto e Insumo <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.EmailState
                            })}
                            aria-labelledby="topnav-email"
                          >
                            <Link to="/produtoInsumoCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Cadastro
                            </Link>
                            <hr />
                            <Link to="/agricultura/aplicacao/estagio" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Estágio de Aplicação
                            </Link>
                            <Link to="/tipoEmbalagemCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Tipo de Embalagem
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({
                                EmailState: !this.state.EmailState
                              });
                            }}
                          >
                            Pessoas <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.EmailState
                            })}
                            aria-labelledby="topnav-email"
                          >
                            <Link to="/clienteCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Cliente
                            </Link>
                            <Link to="/fornecedorCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Fornecedor
                            </Link>
                            <Link to="/transportadoraCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Transportadora
                            </Link>


                            <div className="dropdown">
                              <Link
                                to="/#"
                                className="dropdown-item dropdown-toggle arrow-none"
                                onClick={e => {
                                  e.preventDefault();
                                  this.setState({
                                    EmailState: !this.state.EmailState
                                  });
                                }}
                              >
                                Funcionário <div className="arrow-down"></div>
                              </Link>
                              <div
                                className={classname("dropdown-menu", {
                                  show: this.state.EmailState
                                })}
                                aria-labelledby="topnav-email"
                              >
                                <Link to="/funcionarioCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Cadastro
                                </Link>
                                <hr />
                                <Link to="/funcionarioCargoCadastro" className="dropdown-item"
                                  onClick={e => {
                                    this.closeAllMenu();
                                  }}>
                                  Cargo
                                </Link>
                              </div>
                            </div>
                            <Link to="/motoristaCadastro" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Motorista
                            </Link>


                          </div>
                        </div>


                        <Link to="/patrimonioCadastro" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Patrimônio
                        </Link>


                        {!moduloAgricultura ? <React.Fragment /> :
                          <Link to="/despesaDiretaCadastro" className="dropdown-item"
                            onClick={e => {
                              this.closeAllMenu();
                            }}>
                            Despesa Direta
                          </Link>
                        }

                        <Link to="/veiculoCadastro" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Veículo de Transporte
                        </Link>

                        <Link to="/identificadorCadastro" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Identificadores
                        </Link>
                        <Link to="/tributario/naturezaOperacaoCadastro" className="dropdown-item"
                          onClick={e => {
                            this.closeAllMenu();
                          }}>
                          Natureza da Operação
                        </Link>
                      </div>
                    </li>

                    {/* <li className="nav-item">
                      <Link className="nav-link" to="/"
                      onClick={e => {
                        this.closeAllMenu();
                      }}>
                        <i className="fa fa-thermometer-0 mr-2"></i>NFe
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/"
                      onClick={e => {
                        this.closeAllMenu();
                      }}>
                        <i className="fa fa-thermometer-0 mr-2"></i>Relatórios
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export function mapStateToProps(state) {
  const { startSessionReducer } = state.entities;
  return { startSessionReducer };
};

function mapDispatchToProps(dispatch) {
  return {}
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar))