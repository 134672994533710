export const NEW_COMPRA_ANIMAL = 'NEW_COMPRA_ANIMAL'
export const NEW_COMPRA_ANIMAL_SUCCESS = 'NEW_COMPRA_ANIMAL_SUCCESS'

export const CREATE_COMPRA_ANIMAL = 'CREATE_COMPRA_ANIMAL'
export const CREATE_COMPRA_ANIMAL_PENDING = 'CREATE_COMPRA_ANIMAL_PENDING'
export const CREATE_COMPRA_ANIMAL_SUCCESS = 'CREATE_COMPRA_ANIMAL_SUCCESS'
export const CREATE_COMPRA_ANIMAL_FAILURE = 'CREATE_COMPRA_ANIMAL_FAILURE'

export const TABLE_COMPRA_ANIMAL = 'TABLE_COMPRA_ANIMAL'
export const TABLE_COMPRA_ANIMAL_PENDING = 'TABLE_COMPRA_ANIMAL_PENDING'
export const TABLE_COMPRA_ANIMAL_SUCCESS = 'TABLE_COMPRA_ANIMAL_SUCCESS'
export const TABLE_COMPRA_ANIMAL_FAILURE = 'TABLE_COMPRA_ANIMAL_FAILURE'

export const LIST_COMPRA_ANIMAL = 'LIST_COMPRA_ANIMAL'
export const LIST_COMPRA_ANIMAL_PENDING = 'LIST_COMPRA_ANIMAL_PENDING'
export const LIST_COMPRA_ANIMAL_SUCCESS = 'LIST_COMPRA_ANIMAL_SUCCESS'
export const LIST_COMPRA_ANIMAL_FAILURE = 'LIST_COMPRA_ANIMAL_FAILURE'

export const FIND_COMPRA_ANIMAL = 'FIND_COMPRA_ANIMAL'
export const FIND_COMPRA_ANIMAL_PENDING = 'FIND_COMPRA_ANIMAL_PENDING'
export const FIND_COMPRA_ANIMAL_SUCCESS = 'FIND_COMPRA_ANIMAL_SUCCESS'
export const FIND_COMPRA_ANIMAL_FAILURE = 'FIND_COMPRA_ANIMAL_FAILURE'

export const DELETE_COMPRA_ANIMAL = 'DELETE_COMPRA_ANIMAL'
export const DELETE_COMPRA_ANIMAL_PENDING = 'DELETE_COMPRA_ANIMAL_PENDING'
export const DELETE_COMPRA_ANIMAL_SUCCESS = 'DELETE_COMPRA_ANIMAL_SUCCESS'
export const DELETE_COMPRA_ANIMAL_FAILURE = 'DELETE_COMPRA_ANIMAL_FAILURE'