import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import {
  changeLayout,
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayoutWidth,
} from "../../../store/actions";

// Other Layout related Component
// import RightSidebar from "../RightSidebar";
import TopBar from "./TopBar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import HelpDrawer from "@components/common/Help/HelpDrawer";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false
    };
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
  }

  /**
   * Open/close right sidebar
   */
  toggleRightSidebar() {
    this.props.toggleRightSidebar();
  }

  componentDidMount() {
    // Scrollto 0,0
    window.scrollTo(0, 0);

    this.props.changeLayout('horizontal');
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }
    if (this.props.showRightSidebar) {
      this.toggleRightSidebar();
    }
  }

  /**
   * Opens the menu - mobile
   */
  openMenu = e => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened });
  };

  closeMenu = e => {
    this.setState({ isMenuOpened: false });
  };


  render() {

    const { startSessionReducer } = this.props;

    if (startSessionReducer.configSession == null || startSessionReducer.configSession.fazendaSelecionada == null) {
      return <Redirect to="/home" />;
    }
    return (
      <React.Fragment>
        <div id="layout-wrapper">
          <TopBar
            theme={this.props.topbarTheme}
            isMenuOpened={this.state.isMenuOpened}
            toggleRightSidebar={this.toggleRightSidebar}
            openLeftMenuCallBack={this.openMenu}
          />
          <Navbar menuOpen={this.state.isMenuOpened} funcCloseMenu={this.closeMenu} />

          <div className="main-content">
            <div className="page-content">{this.props.children}</div>
          </div>
          <Footer />

          <HelpDrawer />
          {/* <RightSidebar /> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { startSessionReducer } = state.entities;
  return {
    ...state.Layout,
    startSessionReducer
  };
};
export default connect(mapStatetoProps, {
  changeTopbarTheme, toggleRightSidebar, changeLayout, changeLayoutWidth
})(withRouter(Layout));
