import {
    TOTAIS_DASHBOARD_PECUARIA_PENDING,
    TOTAIS_DASHBOARD_PECUARIA_SUCCESS,
    TOTAIS_DASHBOARD_PECUARIA_FAILURE,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_PENDING,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_SUCCESS,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_FAILURE,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_PENDING,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_SUCCESS,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_FAILURE,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_PENDING,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_SUCCESS,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_FAILURE,
    PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_PENDING,
    PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_SUCCESS,
    PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_FAILURE,
} from './actionTypes';

const totaisInit = {
    animaisAtivos: 0,
    compra: 0,
    nascimento: 0,
    venda: 0,
    pesoTotal: 0
}

const initialState = {
    totais: totaisInit,
    loading: false,
    dadosGraficoAnimaisAtivoPorLote: [['Lote', 'Animais']],
    dadosGraficoAnimaisAtivoPorCategoria: [['Categoria', 'Animais']],
    dadosGraficoAnimaisAtivoPorSexo: [['Sexo', 'Animais']],
    dadosGraficoAnimaisAtivoPesoPorLote: [['Lote', 'Animais']],
}


function dashboardPecuariaReducer(state = initialState, action) {

    switch (action.type) {
        case TOTAIS_DASHBOARD_PECUARIA_PENDING:
        case PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_PENDING:
        case PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_PENDING:
        case PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_PENDING:
        case PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_PENDING:
            return {
                ...state,
                loading: true,
            }
        case TOTAIS_DASHBOARD_PECUARIA_SUCCESS:
            return {
                ...state,
                totais: action.totais,
                // loading: false
            }
        case TOTAIS_DASHBOARD_PECUARIA_FAILURE:
            return {
                ...state,
                totais: totaisInit,
                // loading: false
            }
        case PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_SUCCESS:
            return {
                ...state,
                dadosGraficoAnimaisAtivoPorLote: action.dadosGraficoAnimaisAtivoPorLote,
                // loading: false
            }
        case PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_FAILURE:
            return {
                ...state,
                dadosGraficoAnimaisAtivoPorLote: [['Lote', 'Animais']],
                // loading: false
            }
        case PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_SUCCESS:
            return {
                ...state,
                dadosGraficoAnimaisAtivoPorCategoria: action.dadosGraficoAnimaisAtivoPorCategoria,
                // loading: false
            }
        case PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_FAILURE:
            return {
                ...state,
                dadosGraficoAnimaisAtivoPorCategoria: [['Categoria', 'Animais']],
                // loading: false
            }
        case PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_SUCCESS:
            return {
                ...state,
                dadosGraficoAnimaisAtivoPorSexo: action.dadosGraficoAnimaisAtivoPorSexo,
                // loading: false
            }
        case PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_FAILURE:
            return {
                ...state,
                dadosGraficoAnimaisAtivoPorSexo: [['Sexo', 'Animais']],
                // loading: false
            }
        case PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_SUCCESS:
            return {
                ...state,
                dadosGraficoAnimaisAtivoPesoPorLote: action.dadosGraficoAnimaisAtivoPesoPorLote,
                loading: false
            }
        case PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_FAILURE:
            return {
                ...state,
                dadosGraficoAnimaisAtivoPesoPorLote: [['Lote', 'Animais']],
                loading: false
            }
        default:
            return state
    }
}

export { dashboardPecuariaReducer };