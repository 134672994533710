import { takeLatest, put } from 'redux-saga/effects';

import {
  animalCategoriaCadastro,
  NEW_ANIMAL_CATEGORIA_CADASTRO,
  NEW_ANIMAL_CATEGORIA_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewAnimalCategoriaCadastro(action) {

  yield put({ 
    type: NEW_ANIMAL_CATEGORIA_CADASTRO_SUCCESS, 
    registro: animalCategoriaCadastro.newRegistro, 
    isCadastroRapido: action.isCadastroRapido, 
  });
}

export default function* watchNewAnimalCategoriaCadastro() {
  yield takeLatest(NEW_ANIMAL_CATEGORIA_CADASTRO, sagaNewAnimalCategoriaCadastro)
}
