import watchNewProdutoInsumoCadastro from './saga/new';
import watchFindProdutoInsumoCadastro from './saga/find';
import watchCreateProdutoInsumoCadastro from './saga/create';
import watchListProdutoInsumoCadastro from './saga/listTable';
import watchDeleteProdutoInsumoCadastro from './saga/delete';
import watchCancelProdutoInsumoCadastroRapido from './saga/cancel';
import watchNewProdutoInsumoVincularFornecedor from './saga/newVincularFornecedor';
import watchUpdateProdutoInsumoVincularFornecedor from './saga/updateVincularFornecedor';
import watchUpdateProdutoInsumoVincularFornecedorXml from './saga/updateVincularFornecedorXml';
import watchImportarProdutoWhiteLabel from './saga/importarProdutoWhiteLabel';

export const produtoInsumoCadastroSaga = [
    watchNewProdutoInsumoCadastro(),
    watchFindProdutoInsumoCadastro(),
    watchCreateProdutoInsumoCadastro(),
    watchListProdutoInsumoCadastro(),
    watchDeleteProdutoInsumoCadastro(),
    watchCancelProdutoInsumoCadastroRapido(),
    watchNewProdutoInsumoVincularFornecedor(),
    watchUpdateProdutoInsumoVincularFornecedor(),
    watchUpdateProdutoInsumoVincularFornecedorXml(),
    watchImportarProdutoWhiteLabel(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
export * from './entityVincularFornecedor';
export * from './produtoInsumoGrupoEnum';
