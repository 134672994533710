
export const NEW_PLANEJAMENTO = 'NEW_PLANEJAMENTO'
export const NEW_PLANEJAMENTO_SUCCESS = 'NEW_PLANEJAMENTO_SUCCESS'

export const CREATE_PLANEJAMENTO = 'CREATE_PLANEJAMENTO'
export const CREATE_PLANEJAMENTO_PENDING = 'CREATE_PLANEJAMENTO_PENDING'
export const CREATE_PLANEJAMENTO_SUCCESS = 'CREATE_PLANEJAMENTO_SUCCESS'
export const CREATE_PLANEJAMENTO_FAILURE = 'CREATE_PLANEJAMENTO_FAILURE'

export const TABLE_PLANEJAMENTO = 'TABLE_PLANEJAMENTO'
export const TABLE_PLANEJAMENTO_PENDING = 'TABLE_PLANEJAMENTO_PENDING'
export const TABLE_PLANEJAMENTO_SUCCESS = 'TABLE_PLANEJAMENTO_SUCCESS'
export const TABLE_PLANEJAMENTO_FAILURE = 'TABLE_PLANEJAMENTO_FAILURE'

export const LIST_PLANEJAMENTO = 'LIST_PLANEJAMENTO'
export const LIST_PLANEJAMENTO_PENDING = 'LIST_PLANEJAMENTO_PENDING'
export const LIST_PLANEJAMENTO_SUCCESS = 'LIST_PLANEJAMENTO_SUCCESS'
export const LIST_PLANEJAMENTO_FAILURE = 'LIST_PLANEJAMENTO_FAILURE'

export const FIND_PLANEJAMENTO = 'FIND_PLANEJAMENTO'
export const FIND_PLANEJAMENTO_PENDING = 'FIND_PLANEJAMENTO_PENDING'
export const FIND_PLANEJAMENTO_SUCCESS = 'FIND_PLANEJAMENTO_SUCCESS'
export const FIND_PLANEJAMENTO_FAILURE = 'FIND_PLANEJAMENTO_FAILURE'

export const DELETE_PLANEJAMENTO = 'DELETE_PLANEJAMENTO'
export const DELETE_PLANEJAMENTO_PENDING = 'DELETE_PLANEJAMENTO_PENDING'
export const DELETE_PLANEJAMENTO_SUCCESS = 'DELETE_PLANEJAMENTO_SUCCESS'
export const DELETE_PLANEJAMENTO_FAILURE = 'DELETE_PLANEJAMENTO_FAILURE'

export const RELATORIO_ATIVIDADES_PLANEJAMENTO_TALHAO_SINTETICO = 'RELATORIO_ATIVIDADES_PLANEJAMENTO_TALHAO_SINTETICO'
export const RELATORIO_ATIVIDADES_PLANEJAMENTO_TALHAO_ANALITICO = 'RELATORIO_ATIVIDADES_PLANEJAMENTO_TALHAO_ANALITICO'

export const NEW_FILTER_PLANEJAMENTO = 'NEW_FILTER_PLANEJAMENTO'
export const NEW_FILTER_PLANEJAMENTO_PEDING = 'NEW_FILTER_PLANEJAMENTO_PEDING'
export const NEW_FILTER_PLANEJAMENTO_SUCCESS = 'NEW_FILTER_PLANEJAMENTO_SUCCESS'
export const NEW_FILTER_PLANEJAMENTO_FAILURE = 'NEW_FILTER_PLANEJAMENTO_FAILURE'

export const NEW_FILTER_NEXT_PAGE_PLANEJAMENTO = 'NEW_FILTER_NEXT_PAGE_PLANEJAMENTO'
export const NEW_FILTER_NEXT_PAGE_PLANEJAMENTO_PENDING = 'NEW_FILTER_NEXT_PAGE_PLANEJAMENTO_PENDING'
export const NEW_FILTER_NEXT_PAGE_PLANEJAMENTO_SUCCESS = 'NEW_FILTER_NEXT_PAGE_PLANEJAMENTO_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_PLANEJAMENTO_FAILURE = 'NEW_FILTER_NEXT_PAGE_PLANEJAMENTO_FAILURE'

export const GET_TOTAIS_PLANEJAMENTO_PENDING = 'GET_TOTAIS_PLANEJAMENTO_PENDING'
export const GET_TOTAIS_PLANEJAMENTO_SUCCESS = 'GET_TOTAIS_PLANEJAMENTO_SUCCESS'
export const GET_TOTAIS_PLANEJAMENTO_FAILURE = 'GET_TOTAIS_PLANEJAMENTO_FAILURE'

export const GET_LOADING_PLANEJAMENTO_SUCCESS = 'GET_LOADING_PLANEJAMENTO_SUCCESS'
export const GET_LOADING_PLANEJAMENTO_PENDING = 'GET_LOADING_PLANEJAMENTO_PENDING'

