import React from 'react';

import {
    Col,
    FormGroup,
    Label,
    FormFeedback,
    InputGroup,
    FormText
} from 'reactstrap';

import { Field } from 'formik';

import CPFCNPJ from '@react-br-forms/cpf-cnpj-mask';

export const CPF_CNPJInput = (props) => {

    const { col, name, label, placeholder, plusChange } = props;

    return (
        <Col md={col}>
            <FormGroup>
                <Label htmlFor={'id_' + name} >{label}</Label>
                <Field name={name}>
                    {({ field, form, meta }) => (
                        <>
                            <InputGroup>
                                <CPFCNPJ
                                    {...field}
                                    id={'id_' + name}
                                    name={name}
                                    value={field.value}
                                    className="form-control"
                                    placeholder={placeholder}
                                    style={{
                                        borderColor: (meta.error === undefined || meta.touched === undefined ? '#ccc' :
                                            (meta.error && meta.touched ? 'red' : '#ccc')), borderTopLeftRadius: 0, borderBottomLeftRadius: 0
                                    }}
                                    onChange={(e) => {
                                        form.handleChange(e);
                                        plusChange(e);
                                    }}
                                />
                                <FormFeedback>{meta.error}</FormFeedback>
                            </InputGroup>
                            {meta.error && meta.touched ? <FormText color='danger'>{meta.error}</FormText> : <React.Fragment />}
                        </>
                    )}
                </Field>
            </FormGroup>
        </Col>
    )
}

CPF_CNPJInput.defaultProps = {
    col: 12,
    plusChange: () => { },
};