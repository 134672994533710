import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createBaixaManualEstoque, updateBaixaManualEstoque } from '../service';
import {
  CREATE_BAIXAR_MANUAL_ESTOQUE,
  CREATE_BAIXAR_MANUAL_ESTOQUE_PENDING,
  CREATE_BAIXAR_MANUAL_ESTOQUE_SUCCESS,
  CREATE_BAIXAR_MANUAL_ESTOQUE_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';
import { findBaixaManualEstoque } from '..';

function* sagaCreateBaixaManualEstoque(action) {
  yield put({ type: CREATE_BAIXAR_MANUAL_ESTOQUE_PENDING })

  try {
    var idBaixa;

    if (action.data.id === undefined || action.data.id === null) {
      var saved = yield call(createBaixaManualEstoque, formatJsonRemoveEmptyOrNull({
        ...action.data,
        propriedade: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
        }
      }));
      idBaixa = saved.data.id;
    } else {
      idBaixa = action.data.id;
      yield call(updateBaixaManualEstoque,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_BAIXAR_MANUAL_ESTOQUE_SUCCESS, registro: action.data })

    history.push('/estoque/baixarManual');

    AlertSuccess('Registro salvo com sucesso!');

    const novoRegistro = yield call(findBaixaManualEstoque, idBaixa);
    if (!novoRegistro.estoqueBaixado) {
      AlertWarning('Baixa de estoque não processada por falta de estoque disponível. Verifique a baixa...');
    }
  } catch (error) {
    yield put({ type: CREATE_BAIXAR_MANUAL_ESTOQUE_FAILURE, registro: action.data })

    AlertError('Falha ao baixar estoque! Tente novamente...');
  }
}

export default function* watchCreateBaixaManualEstoque() {
  yield takeLatest(CREATE_BAIXAR_MANUAL_ESTOQUE, sagaCreateBaixaManualEstoque)
}
