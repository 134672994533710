import { takeLatest, put, call } from 'redux-saga/effects';

import { findPagamentos } from '../service';
import {
  FIND_CONTA_RECEBER_PAGAMENTOS,
  FIND_CONTA_RECEBER_PAGAMENTOS_PENDING,
  FIND_CONTA_RECEBER_PAGAMENTOS_SUCCESS,
  FIND_CONTA_RECEBER_PAGAMENTOS_FAILURE
} from '../actionTypes';

function* sagaFindPagamentos(action) {
  yield put({ type: FIND_CONTA_RECEBER_PAGAMENTOS_PENDING, id: action.id })

  try {

    const pagamentos = yield call(findPagamentos, action.id);

    yield put({ type: FIND_CONTA_RECEBER_PAGAMENTOS_SUCCESS, pagamentos: pagamentos })

  } catch (error) {

    yield put({ type: FIND_CONTA_RECEBER_PAGAMENTOS_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindPagamentos() {
  yield takeLatest(FIND_CONTA_RECEBER_PAGAMENTOS, sagaFindPagamentos)
}
