import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/agricultura/estoque/movimentacao' };
/////////////////////////////////////////////////////////////////////////////////

export function getEstoqueProducaoPorPeriodoAnalitico(idPropriedade, datas, filter) {
  return httpService.get({
    ...args, path: '/agricultura/estoque/movimentacao/estoquePorPeriodoAnalitico?propriedade='
      + idPropriedade + getWhere(filter) + `&datas=${datas}`
  });
}

export function getEstoqueProducaoPorPeriodoAnaliticoPdf(idPropriedade, datas, filter) {
  return httpService.getPdf({
    ...args, path: '/agricultura/estoque/movimentacao/estoquePorPeriodoAnalitico/pdf?propriedade='
      + idPropriedade + getWhere(filter) + `&datas=${datas}`
  });
}

export function getEstoqueProducaoPorPeriodoSintetico(idPropriedade, datas, filter) {
  return httpService.get({
    ...args, path: '/agricultura/estoque/movimentacao/estoquePorPeriodoSintetico?propriedade='
      + idPropriedade + getWhere(filter) + `&datas=${datas}`
  });
}

export function getEstoqueProducaoPorPeriodoSinteticoPdf(idPropriedade, datas, filter) {
  return httpService.getPdf({
    ...args, path: '/agricultura/estoque/movimentacao/estoquePorPeriodoSintetico/pdf?propriedade='
      + idPropriedade + getWhere(filter) + `&datas=${datas}`
  });
}

function getWhere(filter) {
  var where = "";
  if (filter !== undefined && filter !== null && filter.localEstoque !== undefined && filter.localEstoque !== null) {
    where = where + '&localEstoque=' + filter.localEstoque.id
  }
  if (filter !== undefined && filter !== null && filter.cultura !== undefined && filter.cultura !== null) {
    where = where + '&cultura=' + filter.cultura.id
  }
  if (filter !== undefined && filter !== null && filter.cultivar !== undefined && filter.cultivar !== null) {
    where = where + '&cultivar=' + filter.cultivar.id
  }
  if (filter !== undefined && filter !== null && filter.identificador !== undefined && filter.identificador !== null) {
    where = where + '&identificador=' + filter.identificador.id
  }
  if (filter !== undefined && filter !== null && filter.filtroSomenteDaDataInformada !== undefined && filter.filtroSomenteDaDataInformada !== null) {
    where = where + '&filtroSomenteDaDataInformada=' + (filter.filtroSomenteDaDataInformada === 'S' ? 'true' : 'false')
  }
  return where;
}