import watchTotaisPatrimonio from './saga/totaisPatrimonio';

export const dashboardPatrimonioSaga = [
    watchTotaisPatrimonio(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
