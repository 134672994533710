
export const NEW_MANEJO_PLANEJAMENTO_CADASTRO = 'NEW_MANEJO_PLANEJAMENTO_CADASTRO'
export const NEW_MANEJO_PLANEJAMENTO_CADASTRO_PENDING = 'NEW_MANEJO_PLANEJAMENTO_CADASTRO_PENDING'
export const NEW_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS = 'NEW_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS'
export const NEW_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE = 'NEW_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE'

export const CREATE_MANEJO_PLANEJAMENTO_CADASTRO = 'CREATE_MANEJO_PLANEJAMENTO_CADASTRO'
export const CREATE_MANEJO_PLANEJAMENTO_CADASTRO_PENDING = 'CREATE_MANEJO_PLANEJAMENTO_CADASTRO_PENDING'
export const CREATE_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS = 'CREATE_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS'
export const CREATE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE = 'CREATE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE'

export const CANCEL_MANEJO_PLANEJAMENTO_CADASTRO_RAPIDO = 'CANCEL_MANEJO_PLANEJAMENTO_CADASTRO_RAPIDO'
export const CANCEL_MANEJO_PLANEJAMENTO_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_MANEJO_PLANEJAMENTO_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_MANEJO_PLANEJAMENTO_CADASTRO = 'TABLE_MANEJO_PLANEJAMENTO_CADASTRO'
export const TABLE_MANEJO_PLANEJAMENTO_CADASTRO_PENDING = 'TABLE_MANEJO_PLANEJAMENTO_CADASTRO_PENDING'
export const TABLE_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS = 'TABLE_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS'
export const TABLE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE = 'TABLE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE'

export const LIST_MANEJO_PLANEJAMENTO_CADASTRO = 'LIST_MANEJO_PLANEJAMENTO_CADASTRO'
export const LIST_MANEJO_PLANEJAMENTO_CADASTRO_PENDING = 'LIST_MANEJO_PLANEJAMENTO_CADASTRO_PENDING'
export const LIST_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS = 'LIST_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS'
export const LIST_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE = 'LIST_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE'

export const FIND_MANEJO_PLANEJAMENTO_CADASTRO = 'FIND_MANEJO_PLANEJAMENTO_CADASTRO'
export const FIND_MANEJO_PLANEJAMENTO_CADASTRO_PENDING = 'FIND_MANEJO_PLANEJAMENTO_CADASTRO_PENDING'
export const FIND_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS = 'FIND_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS'
export const FIND_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE = 'FIND_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE'

export const DELETE_MANEJO_PLANEJAMENTO_CADASTRO = 'DELETE_MANEJO_PLANEJAMENTO_CADASTRO'
export const DELETE_MANEJO_PLANEJAMENTO_CADASTRO_PENDING = 'DELETE_MANEJO_PLANEJAMENTO_CADASTRO_PENDING'
export const DELETE_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS = 'DELETE_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS'
export const DELETE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE = 'DELETE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE'
