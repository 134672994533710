import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deletePerfil } from '../service';
import {
  DELETE_PERFIL,
  DELETE_PERFIL_PENDING,
  DELETE_PERFIL_SUCCESS,
  DELETE_PERFIL_FAILURE
} from '../actionTypes';

function* sagaDeletePerfil(action) {
  yield put({ type: DELETE_PERFIL_PENDING, id: action.id })

  try {

    const a = yield call(deletePerfil, action.id);

    if (a.status !== 204) {
      throw new Error('API delete request failed');
    }
    yield put({ type: DELETE_PERFIL_SUCCESS, removed: true });

    swal({
      title: 'Excluído!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });
  } catch (error) {

    yield put({ type: DELETE_PERFIL_FAILURE, removed: false });
  }
}

export default function* watchDeletePerfil() {
  yield takeLatest(DELETE_PERFIL, sagaDeletePerfil)
}
