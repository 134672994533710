import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_PATRIMONIO_CADASTRO_RAPIDO,
    CANCEL_PATRIMONIO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelPatrimonioCadastroRapido() {
  yield put({ type: CANCEL_PATRIMONIO_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelPatrimonioCadastroRapido() {
  yield takeLatest(CANCEL_PATRIMONIO_CADASTRO_RAPIDO, sagaCancelPatrimonioCadastroRapido)
}