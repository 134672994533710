import { takeLatest, put } from 'redux-saga/effects';

import {
    CHANGE_HELP_DRAWER_DATA,
    CHANGE_HELP_DRAWER_DATA_SUCCESS,
} from '../actionTypes';

function* sagaHelpDrawerChangeData(action) {
    yield put({ type: CHANGE_HELP_DRAWER_DATA_SUCCESS, data: action.data });
}

export default function* watchHelpDrawerChangeData() {
  yield takeLatest(CHANGE_HELP_DRAWER_DATA, sagaHelpDrawerChangeData);
}