import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createAnimalPelagemCadastro, updateAnimalPelagemCadastro } from '../service';
import {
    CREATE_ANIMAL_PELAGEM_CADASTRO,
    CREATE_ANIMAL_PELAGEM_CADASTRO_PENDING,
    CREATE_ANIMAL_PELAGEM_CADASTRO_SUCCESS,
    CREATE_ANIMAL_PELAGEM_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateAnimalPelagemCadastro(action) {
  yield put({ type: CREATE_ANIMAL_PELAGEM_CADASTRO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createAnimalPelagemCadastro, action.data);
    } else {
      yield call(updateAnimalPelagemCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_ANIMAL_PELAGEM_CADASTRO_SUCCESS, registro: action.data })

    if(!action.isCadastroRapido){
      history.push('/pecuaria/animalPelagemCadastro');
    }  

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_ANIMAL_PELAGEM_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateAnimalPelagemCadastro() {
  yield takeLatest(CREATE_ANIMAL_PELAGEM_CADASTRO, sagaCreateAnimalPelagemCadastro)
}
