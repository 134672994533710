import watchNewClienteCadastro from './saga/new';
import watchFindClienteCadastro from './saga/find';
import watchCreateClienteCadastro from './saga/create';
import watchListAllClienteCadastro from './saga/listAll';
import watchListClienteCadastro from './saga/listTable';
import watchDeleteClienteCadastro from './saga/delete';
import watchCancelClienteCadastroRapido from './saga/cancel';

export const clienteCadastroSaga = [
    watchNewClienteCadastro(),
    watchFindClienteCadastro(),
    watchCreateClienteCadastro(),
    watchListAllClienteCadastro(),
    watchListClienteCadastro(),
    watchDeleteClienteCadastro(),
    watchCancelClienteCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
