import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_ANO_PECUARIO_CADASTRO_RAPIDO,
    CANCEL_ANO_PECUARIO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelAnoPecuarioCadastroRapido() {
  yield put({ type: CANCEL_ANO_PECUARIO_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelAnoPecuarioCadastroRapido() {
  yield takeLatest(CANCEL_ANO_PECUARIO_CADASTRO_RAPIDO, sagaCancelAnoPecuarioCadastroRapido)
}