import { takeLatest, put, call } from 'redux-saga/effects';

import { findTransferenciaEstoqueProducao } from '../service';
import {
  FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO,
  FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING,
  FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS,
  FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE
} from '../actionTypes';

function* sagaFindTransferenciaEstoqueProducao(action) {
  yield put({ type: FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING, id: action.id })

  try {

    const registro = yield call(findTransferenciaEstoqueProducao, action.id);

    yield put({ type: FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindTransferenciaEstoqueProducao() {
  yield takeLatest(FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO, sagaFindTransferenciaEstoqueProducao)
}
