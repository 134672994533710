import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_MANEJO_PLANEJAMENTO_CADASTRO_RAPIDO,
    CANCEL_MANEJO_PLANEJAMENTO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelManejoPlanejamentoCadastroRapido() {
  yield put({ type: CANCEL_MANEJO_PLANEJAMENTO_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelManejoPlanejamentoCadastroRapido() {
  yield takeLatest(CANCEL_MANEJO_PLANEJAMENTO_CADASTRO_RAPIDO, sagaCancelManejoPlanejamentoCadastroRapido)
}