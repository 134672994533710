
export const NEW_ANO_PECUARIO_CADASTRO = 'NEW_ANO_PECUARIO_CADASTRO'
export const NEW_ANO_PECUARIO_CADASTRO_PENDING = 'NEW_ANO_PECUARIO_CADASTRO_PENDING'
export const NEW_ANO_PECUARIO_CADASTRO_SUCCESS = 'NEW_ANO_PECUARIO_CADASTRO_SUCCESS'
export const NEW_ANO_PECUARIO_CADASTRO_FAILURE = 'NEW_ANO_PECUARIO_CADASTRO_FAILURE'

export const CREATE_ANO_PECUARIO_CADASTRO = 'CREATE_ANO_PECUARIO_CADASTRO'
export const CREATE_ANO_PECUARIO_CADASTRO_PENDING = 'CREATE_ANO_PECUARIO_CADASTRO_PENDING'
export const CREATE_ANO_PECUARIO_CADASTRO_SUCCESS = 'CREATE_ANO_PECUARIO_CADASTRO_SUCCESS'
export const CREATE_ANO_PECUARIO_CADASTRO_FAILURE = 'CREATE_ANO_PECUARIO_CADASTRO_FAILURE'

export const CANCEL_ANO_PECUARIO_CADASTRO_RAPIDO = 'CANCEL_ANO_PECUARIO_CADASTRO_RAPIDO'
export const CANCEL_ANO_PECUARIO_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_ANO_PECUARIO_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_ANO_PECUARIO_CADASTRO = 'TABLE_ANO_PECUARIO_CADASTRO'
export const TABLE_ANO_PECUARIO_CADASTRO_PENDING = 'TABLE_ANO_PECUARIO_CADASTRO_PENDING'
export const TABLE_ANO_PECUARIO_CADASTRO_SUCCESS = 'TABLE_ANO_PECUARIO_CADASTRO_SUCCESS'
export const TABLE_ANO_PECUARIO_CADASTRO_FAILURE = 'TABLE_ANO_PECUARIO_CADASTRO_FAILURE'

export const LIST_ANO_PECUARIO_CADASTRO = 'LIST_ANO_PECUARIO_CADASTRO'
export const LIST_ANO_PECUARIO_CADASTRO_PENDING = 'LIST_ANO_PECUARIO_CADASTRO_PENDING'
export const LIST_ANO_PECUARIO_CADASTRO_SUCCESS = 'LIST_ANO_PECUARIO_CADASTRO_SUCCESS'
export const LIST_ANO_PECUARIO_CADASTRO_FAILURE = 'LIST_ANO_PECUARIO_CADASTRO_FAILURE'

export const FIND_ANO_PECUARIO_CADASTRO = 'FIND_ANO_PECUARIO_CADASTRO'
export const FIND_ANO_PECUARIO_CADASTRO_PENDING = 'FIND_ANO_PECUARIO_CADASTRO_PENDING'
export const FIND_ANO_PECUARIO_CADASTRO_SUCCESS = 'FIND_ANO_PECUARIO_CADASTRO_SUCCESS'
export const FIND_ANO_PECUARIO_CADASTRO_FAILURE = 'FIND_ANO_PECUARIO_CADASTRO_FAILURE'

export const DELETE_ANO_PECUARIO_CADASTRO = 'DELETE_ANO_PECUARIO_CADASTRO'
export const DELETE_ANO_PECUARIO_CADASTRO_PENDING = 'DELETE_ANO_PECUARIO_CADASTRO_PENDING'
export const DELETE_ANO_PECUARIO_CADASTRO_SUCCESS = 'DELETE_ANO_PECUARIO_CADASTRO_SUCCESS'
export const DELETE_ANO_PECUARIO_CADASTRO_FAILURE = 'DELETE_ANO_PECUARIO_CADASTRO_FAILURE'
