
export const NEW_CONTA_PAGAR = 'NEW_CONTA_PAGAR'
export const NEW_CONTA_PAGAR_SUCCESS = 'NEW_CONTA_PAGAR_SUCCESS'

export const NEW_FILTER_CONTA_PAGAR = 'NEW_FILTER_CONTA_PAGAR'
export const NEW_FILTER_CONTA_PAGAR_PEDING = 'NEW_FILTER_CONTA_PAGAR_PEDING'
export const NEW_FILTER_CONTA_PAGAR_SUCCESS = 'NEW_FILTER_CONTA_PAGAR_SUCCESS'
export const NEW_FILTER_CONTA_PAGAR_SUCCESS_PDF = 'NEW_FILTER_CONTA_PAGAR_SUCCESS_PDF'
export const NEW_FILTER_CONTA_PAGAR_FAILURE = 'NEW_FILTER_CONTA_PAGAR_FAILURE'

export const NEW_FILTER_NEXT_PAGE_CONTA_PAGAR = 'NEW_FILTER_NEXT_PAGE_CONTA_PAGAR'
export const NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_PENDING = 'NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_PENDING'
export const NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_SUCCESS = 'NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_FAILURE = 'NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_FAILURE'

export const GET_TOTAIS_CONTA_PAGAR_PENDING = 'GET_TOTAIS_CONTA_PAGAR_PENDING'
export const GET_TOTAIS_CONTA_PAGAR_SUCCESS = 'GET_TOTAIS_CONTA_PAGAR_SUCCESS'
export const GET_TOTAIS_CONTA_PAGAR_FAILURE = 'GET_TOTAIS_CONTA_PAGAR_FAILURE'

export const CREATE_CONTA_PAGAR = 'CREATE_CONTA_PAGAR'
export const CREATE_CONTA_PAGAR_PENDING = 'CREATE_CONTA_PAGAR_PENDING'
export const CREATE_CONTA_PAGAR_SUCCESS = 'CREATE_CONTA_PAGAR_SUCCESS'
export const CREATE_CONTA_PAGAR_FAILURE = 'CREATE_CONTA_PAGAR_FAILURE'

export const FIND_CONTA_PAGAR = 'FIND_CONTA_PAGAR'
export const FIND_CONTA_PAGAR_PENDING = 'FIND_CONTA_PAGAR_PENDING'
export const FIND_CONTA_PAGAR_SUCCESS = 'FIND_CONTA_PAGAR_SUCCESS'
export const FIND_CONTA_PAGAR_FAILURE = 'FIND_CONTA_PAGAR_FAILURE'

export const FIND_CONTA_PAGAR_PAGAMENTOS = 'FIND_CONTA_PAGAR_PAGAMENTOS'
export const FIND_CONTA_PAGAR_PAGAMENTOS_PENDING = 'FIND_CONTA_PAGAR_PAGAMENTOS_PENDING'
export const FIND_CONTA_PAGAR_PAGAMENTOS_SUCCESS = 'FIND_CONTA_PAGAR_PAGAMENTOS_SUCCESS'
export const FIND_CONTA_PAGAR_PAGAMENTOS_FAILURE = 'FIND_CONTA_PAGAR_PAGAMENTOS_FAILURE'

export const DELETE_CONTA_PAGAR = 'DELETE_CONTA_PAGAR'
export const DELETE_CONTA_PAGAR_PENDING = 'DELETE_CONTA_PAGAR_PENDING'
export const DELETE_CONTA_PAGAR_SUCCESS = 'DELETE_CONTA_PAGAR_SUCCESS'
export const DELETE_CONTA_PAGAR_FAILURE = 'DELETE_CONTA_PAGAR_FAILURE'

export const CONTA_PAGAR_ESTORNAR_PAGAMENTO = 'CONTA_PAGAR_ESTORNAR_PAGAMENTO'
export const CONTA_PAGAR_ESTORNAR_PAGAMENTO_PENDING = 'CONTA_PAGAR_ESTORNAR_PAGAMENTO_PENDING'
export const CONTA_PAGAR_ESTORNAR_PAGAMENTO_SUCCESS = 'CONTA_PAGAR_ESTORNAR_PAGAMENTO_SUCCESS'
export const CONTA_PAGAR_ESTORNAR_PAGAMENTO_FAILURE = 'CONTA_PAGAR_ESTORNAR_PAGAMENTO_FAILURE'
