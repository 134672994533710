/**
 * Agrare @ 2023
 */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  GoogleMap,
  Polygon,
  DrawingManager,
  Marker,
  StandaloneSearchBox,
  GroundOverlay,
} from "@react-google-maps/api";

import {
  Button,
} from 'reactstrap';

import {
  LIST_ALL_LATEST_NDVI,
  TABLE_CAMPO_CADASTRO, TABLE_NDVI, HAS_AUTHORITY } from '@handler';
import { listTalhaoBySafra } from '@handler/common/talhaoCadastro/service';
import { polygonDefault, polygonDrawing, polygonSelected, polygonNdvi } from "./styles/polygon";
import { convertSquareMetersToAcres, convertSquareMetersToAlqueires, convertSquareMetersToHectares } from "./utils/conversions";
import {
  decodePath,
  encodePath,
  getPolygonBounds,
  getPolygonCenter,
  getPolygonPath,
} from "./utils/mapFunctions";
import { searchInputStyle } from "./styles/searchBox";
import './Map.css';
import MapMenu from "./components/MapMenu";
import NdviScale from "./components/NdviScale";
import MapModeSwitcher from "./components/MapModeSwitcher";

function Map({ isCadastro, value, polygon, setPolygon, polygonList }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [areasList, setAreasList] = useState([]);
  const [itemSelected, setItemSelected] = useState(null);
  const [ndviSelected, setNdviSelected] = useState(null);
  const [editOldPolygon, setEditOldPolygon] = useState(false);
  const [center, setCenter] = useState({
    lat: -3.977,
    lng: -38.433
  });
  const [searchBoxRef, setSearchBoxRef] = useState(null);
  const [map, setMap] = useState(null);

  const mapReducer = useSelector((state) => state.entities.mapReducer);
  const ndviReducer = useSelector((state) => state.entities.ndviReducer);

  const startSessionReducer = useSelector((state) => state.entities.startSessionReducer);
  const { moduloAtivo, fazendaSelecionada } = startSessionReducer.configSession;
  const campoCadastroReducer = useSelector((state) => state.entities.campoCadastroReducer);
  const talhaoCadastroReducer = useSelector((state) => state.entities.talhaoCadastroReducer);
  const reducer = moduloAtivo === 'AGRICULTURA' ? talhaoCadastroReducer : campoCadastroReducer;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const config = startSessionReducer.configSession;
      try {
        const response = await listTalhaoBySafra(
          config.fazendaSelecionada.id,
          config.safraAtiva.id
        );

        const filteredAreas = response.map(item => item.talhao);
        setAreasList(filteredAreas);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    if (moduloAtivo === 'AGRICULTURA') {
      if (isCadastro) {
        setAreasList(reducer?.table?.content);
      } else if (!polygonList) {
        fetchData();
      }
    } else if (moduloAtivo === 'PECUARIA' && isCadastro === false) {
      if (isCadastro) {
        dispatch({ type: TABLE_CAMPO_CADASTRO, args: `?propriedade=${fazendaSelecionada.id}` });
      } else {
        setAreasList([]);
      }
    }
  }, []);

  useEffect(() => {
    if (mapReducer.mode === "NDVI") {
      dispatch({ type: HAS_AUTHORITY, role: 'AGRIC_NDVI', license: 'MODULO_NDVI' });
      dispatch({ type: LIST_ALL_LATEST_NDVI, args: fazendaSelecionada.id });
    }
  }, [mapReducer.mode]);

  useEffect(() => {}, [ndviReducer]);

  const onPolygonComplete = newPolygon => {
    // Obtém os pontos do polígono
    const path = newPolygon.getPath().getArray();

    // Converte os pontos de coordenadas para o formato google.maps.LatLng
    const latLngPath = path.map((point) => new google.maps.LatLng(point.lat(), point.lng()));

    // Calcula a área em metros quadrados usando a função computeArea do Google Maps
    const areaInSquareMeters = google.maps.geometry.spherical.computeArea(latLngPath);

    let area;
    const { unidadeArea } = startSessionReducer.configSession.fazendaSelecionada;

    if (unidadeArea === undefined || unidadeArea === null || unidadeArea.sigla === 'ha') {
      area = convertSquareMetersToHectares(areaInSquareMeters);
    } else if (unidadeArea.sigla === 'alq') {
      area = convertSquareMetersToAlqueires(areaInSquareMeters);
    } else {
      area = convertSquareMetersToAcres(areaInSquareMeters);
    }

    setPolygon({
      polygon: newPolygon,
      area,
    });
  }

  function renderModal(item) {
    setItemSelected(item)
  }

  function getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        }
      )
    }
  }

  function getFarmCityLocation(map) {
    if (startSessionReducer !== undefined) {
      if (fazendaSelecionada !== undefined && fazendaSelecionada !== null) {
        let request = {
          query: (fazendaSelecionada.cidade.nome + ' ' + fazendaSelecionada.estado.sigla),
          fields: ["name", "geometry"]
        };

        let service = new google.maps.places.PlacesService(map);
        service.textSearch(request, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            setCenter(results[0].geometry.location)
          }
        });
      }
    }
  };

  const handleDeleteNewPolygon = () => {
    polygon.setMap(null);
    setPolygon({
      polygon: null,
    });
  }

  const handleOnLoad = map => {
    setMap(map);
  };

  const hanldeSearchBoxPlacesChanged = () => {
    const places = searchBoxRef.getPlaces()
    if (places && places.length > 0) {
      setCenter({
        lat: places[0].geometry.location.lat(),
        lng: places[0].geometry.location.lng()
      });
    }
  }

  return (
    <React.Fragment key={`${moduloAtivo}_MAPS`}>
      {polygon ? (
        <div className="map-edit-options mb-2">
          <Button className="btn-danger btn-outline-white" onClick={handleDeleteNewPolygon}>
            Deletar Marcação
          </Button>
        </div>
      ) : value?.id && value?.mapaPolygonPath ? (
        <div>
          {/* {!editOldPolygon ? (
            <Button className="btn-warning btn-outline-white" onClick={() => setEditOldPolygon(true)}>
              Editar Mapeamento
            </Button>
          ) : (
            <Button className="btn-danger btn-outline-white" onClick={() => setEditOldPolygon(false)}>
              Cancelar Edição
            </Button>
          )
          } */}
        </div>
      ) : <></>
      }

      { !isLoading ? (
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          zoom={15}
          version="weekly"
          mapTypeId="satellite" //hybrid , satellite
          options={{
            disableDefaultUI: true,
            keyboardShortcuts: false,
            mapTypeId: "satellite",
            zoomControl: true,
            fullscreenControl: false,
          }}
          on
          onLoad={
            map => handleOnLoad(map)
            // map => getFarmCityLocation(map)
            // ()=>getUserLocation()
          }
        >
          { isCadastro && (
            <StandaloneSearchBox
              onLoad={(ref) => setSearchBoxRef(ref)}
              onPlacesChanged={hanldeSearchBoxPlacesChanged}
            >
              <input
                type="text"
                placeholder="Pesquisar cidade"
                style={searchInputStyle}
              />
            </StandaloneSearchBox>
          )}

          <MapMenu
            areas={polygonList ? polygonList : areasList}
            areaSigla={startSessionReducer.configSession.fazendaSelecionada.unidadeArea === undefined || startSessionReducer.configSession.fazendaSelecionada.unidadeArea === null ? ' ha' : ' ' + startSessionReducer.configSession.fazendaSelecionada.unidadeArea.sigla}
            isCadastro={isCadastro}
            setCenter={(center) => setCenter(center)}
          />

          { !isCadastro && mapReducer.mode !== "KML" && <MapModeSwitcher /> }

          { !isCadastro && mapReducer.mode === "NDVI" ? (
            <>
              <div className="ndvi-legend-container">
                <NdviScale />
              </div>
              {(ndviReducer && ndviReducer.allLatest?.length > 0 ) ?
                  ndviReducer.allLatest.map((item) => (
                    <GroundOverlay
                      key={item.id}
                      bounds={getPolygonBounds(decodePath(item.polygonPath))}
                      url={item.imageUrl}
                    />
                  )) : <></>
              }
            </>
          ) : <></>}

          {!isCadastro && ndviReducer && ndviReducer.item && ndviReducer.item !== null ? (
            <GroundOverlay
              key={ndviReducer.item.id}
              bounds={getPolygonBounds(decodePath(ndviReducer.item.polygonPath))}
              url={ndviReducer.item.imageUrl}
            />
          ) : <></>}

          {/* IF IS CRUD ENABLE DRAW OPTION */}
          {isCadastro && polygon == null && !editOldPolygon && (
            <DrawingManager
              drawingMode={'polygon'}
              options={{
                drawingControl: true,
                drawingControlOptions: {
                  position: google.maps.ControlPosition.RIGHT_CENTER,
                  drawingModes: [google.maps.drawing.OverlayType.POLYGON]
                },
                polygonOptions: polygonDrawing,
              }}
              onPolygonComplete={onPolygonComplete}
            />
          )}

          {/* SHOW KML POLYGONS  */}
          {polygonList && polygonList.length > 0 ? (
            polygonList.map((item, index) => {
              return (
                <React.Fragment key={`Fragment_${index}`}>
                  <Polygon
                    key={`Polygon_${index}`}
                    path={item.mapaPolygonPath}
                    options={
                      polygonDefault
                    }
                    onLoad={(values) => {
                      getPolygonPath(values)
                      setCenter(getPolygonCenter(item.mapaPolygonPath));
                    }}
                    onClick={() => { }}
                  />
                  <Marker
                    key={`MarkerLabel_${index}`}
                    position={
                      getPolygonCenter(item.mapaPolygonPath)
                    }
                    label={{ text: `${item.nome}`, color: '#fff' }}
                    icon={"/"}
                    onClick={() => { }}
                  >
                  </Marker>
                </React.Fragment>
              )
            })
          ) : <></>}

          {/* MOSTRA POLY CADASTRADOS */}
          {areasList && areasList.map((item) => (
            !item.status ? <></> :
              !item.realizaMapeamento || item.mapaPolygonPath == null ? <></> : (
                <React.Fragment key={`Fragment_${item.id}`}>
                  <Polygon
                    key={`Polygon_${item.id}`}
                    paths={decodePath(item.mapaPolygonPath)}
                    options={
                      (mapReducer.mode === "NDVI" || ndviReducer && ndviReducer?.item?.talhao.id === item.id) ? polygonNdvi :
                        isCadastro && value !== undefined ? (
                          item.id === value.id ? (
                            !polygon ? (
                              editOldPolygon ? (
                                { ...polygonSelected, editable: false }
                              ) : polygonSelected
                            ) : { visible: false }
                          ) : polygonDefault
                        ) : polygonDefault
                    }
                    onLoad={(values) => {
                      getPolygonPath(values)
                      if (isCadastro && value !== undefined) {
                        if (item.id === value.id)
                          setCenter(getPolygonCenter(decodePath(item.mapaPolygonPath)));
                      } else {
                        setCenter(getPolygonCenter(decodePath(item.mapaPolygonPath)));
                      }
                    }}
                    onClick={() => {
                      renderModal(item);
                    }}
                  />
                  <Marker
                    key={`MarkerLabel_${item.id}`}
                    position={
                      isCadastro && value !== undefined ?
                        item.id === value.id && polygon ?
                          getPolygonCenter(decodePath(encodePath(polygon.getPath())))
                          : getPolygonCenter(decodePath(item.mapaPolygonPath))
                        : getPolygonCenter(decodePath(item.mapaPolygonPath))
                    }
                    label={{ text: `${item.nome}`, color: '#fff' }}
                    icon={"/"}
                    onClick={() => {
                      renderModal(item)
                    }}
                  >
                  </Marker>
                </React.Fragment>
              )
          ))
          }
        </GoogleMap>
      ) : <></>
      }
    </React.Fragment>
  );
}
export default React.memo(Map);
