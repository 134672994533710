import { takeLatest, put, call } from 'redux-saga/effects';

import { findPlantioManejo } from '../service';
import {
  FIND_PLANTIO_MANEJO,
  FIND_PLANTIO_MANEJO_PENDING,
  FIND_PLANTIO_MANEJO_SUCCESS,
  FIND_PLANTIO_MANEJO_FAILURE
} from '../actionTypes';

function* sagaFindPlantioManejo(action) {
  yield put({ type: FIND_PLANTIO_MANEJO_PENDING, id: action.id })

  try {

    const registro = yield call(findPlantioManejo, action.id);

    yield put({ type: FIND_PLANTIO_MANEJO_SUCCESS, registro: {
      ...registro,
      replicarLancamento: false,
      talhoesList: []
    } })

  } catch (error) {

    yield put({ type: FIND_PLANTIO_MANEJO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindPlantioManejo() {
  yield takeLatest(FIND_PLANTIO_MANEJO, sagaFindPlantioManejo)
}
