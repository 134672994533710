import React from 'react';
import { Link } from 'react-router-dom';

import {
    Button,
} from 'reactstrap';

import { CONST_ROWS_PER_PAGE } from '@commons/consts';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Delete } from '@components/form'

import './Table.css'

class TableReducer extends React.Component {

    constructor(props) {
        super(props);

        this.state = { rows: CONST_ROWS_PER_PAGE, first: 0 };
    }

    onPage = async (event) => {
        await this.paginate({ rows: event.rows, page: event.page });
        this.setState({ rows: event.rows, first: event.first });
    }

    paginate = async ({ rows, page }) => {
        await this.props.filterNextPage(rows, page);
    }

    render() {
        const refDt = React.createRef();
        const { reducer, table, filter, indexFieldMsgDelete, keyInput } = this.props;
        const { editLink, onRemove, onEdit } = this.props;

        const paginatorLeft = <React.Fragment />;

        return (
            <>
                {table && <DataTable
                    key={'id_' + keyInput} ref={refDt} value={table.content} paginator={true} lazy={true} paginatorLeft={paginatorLeft}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} até {last} do total de {totalRecords} registros"
                    rows={this.state.rows} rowsPerPageOptions={[5, 10, 50, 100, 500]}
                    className='table table-striped my-4 w-100 p-datatable-sm' totalRecords={table.totalElements} autoLayout={true}
                    first={this.state.first} onPage={this.onPage} emptyMessage="Nenhum registro encontrado" csvSeparator=';'
                    scrollable={this.props.scrollable}
                >
                    {this.props.children}

                    {
                        <Column style={{ textAlign: 'center', width: this.props.actionWidth, display: this.props.renderActions ? undefined : 'none' }}
                            body={(rowData, column) => {
                                return (
                                    <div>

                                        {this.props.renderEdit &&
                                            <Link to={`${editLink}${rowData.id}`}>
                                                <Button outline color='white' className='btn btn-warning btn-sm'
                                                    onClick={() => onEdit(rowData.id)}
                                                    style={{ marginRight: '5px' }}>
                                                    <i className='fas fa-pencil-alt' />
                                                </Button>
                                            </Link>
                                        }

                                        {this.props.renderDelete &&
                                            <Delete registro={rowData} remove={onRemove}
                                                removed={reducer.removed} indexFieldMsgDelete={indexFieldMsgDelete} />
                                        }
                                    </div>
                                );
                            }} />
                    }
                </DataTable>
                }
            </>
        )

    }
}

TableReducer.defaultProps = {
    keyInput: 'tableSearchLazy',
    renderActions: true,
    renderEdit: true,
    renderDelete: true,
    actionWidth: '15%',
    scrollable: false,
    indexFieldMsgDelete: 0,
    onPage: () => { },
    onRemove: () => { },
    onEdit: () => { },
    init: () => { },
};

export { TableReducer };