import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { createProdutoInsumoCadastro, updateProdutoInsumoCadastro } from '../service';
import {
  CREATE_PRODUTO_INSUMO_CADASTRO,
  CREATE_PRODUTO_INSUMO_CADASTRO_PENDING,
  CREATE_PRODUTO_INSUMO_CADASTRO_SUCCESS,
  CREATE_PRODUTO_INSUMO_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateProdutoInsumoCadastro(action) {
  yield put({ type: CREATE_PRODUTO_INSUMO_CADASTRO_PENDING })

  try {
    if (action.data.grupo !== 'SEMENTE_E_MUDA') {
      action.data = {
        ...action.data,
        tipoEmbalagem: null,
        qtdSaca: 0,
        unidadeVenda: null,
        unidadePopulacao: null,
        populacao: null,
      }
    }
    if (action.data.grupo !== 'ACARICIDA'
      && action.data.grupo !== 'ADJUVANTE'
      && action.data.grupo !== 'ADUBO_FOLIAR'
      && action.data.grupo !== 'DESSECANTE'
      && action.data.grupo !== 'FUNGICIDA'
      && action.data.grupo !== 'HERBICIDA'
      && action.data.grupo !== 'INSETICIDA'
      && action.data.grupo !== 'MEDICAMENTO'
      && action.data.grupo !== 'FUNGICIDA_TS'
      && action.data.grupo !== 'HERBICIDA_FOLHA_LARGA'
      && action.data.grupo !== 'HERBICIDA_FOLHA_ESTREITA'
      && action.data.grupo !== 'INSETICIDA_FISIOLOGICO'
      && action.data.grupo !== 'INSETICIDA_PERCEVEJO_MOSCA_BRANCA'
      && action.data.grupo !== 'OLEO_MINERAL'
      && action.data.grupo !== 'OLEO_VEGETAL'
      && action.data.grupo !== 'INOCULANTE'
      && action.data.grupo !== 'INOCULANTE_LIQUIDO'
      && action.data.grupo !== 'INOCULANTE_TURFOSO'
      && action.data.grupo !== 'INDUTOR_RESISTENCIA'
      && action.data.grupo !== 'DESALOJANTE'
      && action.data.grupo !== 'PROTETOR'
      && action.data.grupo !== 'BIOESTIMULANTE') {
      action.data = {
        ...action.data,
        dosagemHa: 0,
      }
    }
    if (
      action.data.grupo === 'COMBUSTIVEL'
      || action.data.grupo === 'GRAOS'
      || action.data.grupo === 'MEDICAMENTO'
      || action.data.grupo === 'PRODUTO_VETERINARIO'
      || action.data.grupo === 'ITEM_MANUTENCAO'
      || action.data.grupo === 'MATERIAL_EPI'
      || action.data.grupo === 'RACAO'
      || action.data.grupo === 'SAL_MINERAL'
      || action.data.grupo === 'SUPRIMENTO_MINERAL'
    ) {
      action.data = {
        ...action.data,
        formulacao: '',
        aplicacaoEstagio: null,
      }
    }
    if (!action.data.configLancAgricultura) {
      action.data = {
        ...action.data,
        configLancAgriculturaTipo: null,
      }
    }
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createProdutoInsumoCadastro, action.data);
    } else {
      yield call(updateProdutoInsumoCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_PRODUTO_INSUMO_CADASTRO_SUCCESS, registro: action.data })

    if(!action.isCadastroRapido){
      swal({
        title: 'Produto salvo com sucesso!',
        icon: 'success',
        buttons: {
          cancel: false,
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: 'btn-primary',
            closeModal: true
          }
        }
      }).then(() => {
        history.go(0)
      });
    }else{
      AlertSuccess('Produto salvo com sucesso!');
    }

  } catch (error) {
    yield put({ type: CREATE_PRODUTO_INSUMO_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateProdutoInsumoCadastro() {
  yield takeLatest(CREATE_PRODUTO_INSUMO_CADASTRO, sagaCreateProdutoInsumoCadastro)
}
