import {
    NEW_COMPRA_ANIMAL_SUCCESS,
    TABLE_COMPRA_ANIMAL_PENDING,
    TABLE_COMPRA_ANIMAL_SUCCESS,
    TABLE_COMPRA_ANIMAL_FAILURE,
    LIST_COMPRA_ANIMAL_PENDING,
    LIST_COMPRA_ANIMAL_SUCCESS,
    LIST_COMPRA_ANIMAL_FAILURE,
    FIND_COMPRA_ANIMAL_PENDING,
    FIND_COMPRA_ANIMAL_SUCCESS,
    FIND_COMPRA_ANIMAL_FAILURE,
    DELETE_COMPRA_ANIMAL_PENDING,
    DELETE_COMPRA_ANIMAL_SUCCESS,
    DELETE_COMPRA_ANIMAL_FAILURE,
    CREATE_COMPRA_ANIMAL_PENDING,
    CREATE_COMPRA_ANIMAL_SUCCESS,
    CREATE_COMPRA_ANIMAL_FAILURE,
} from './actionTypes';


const initialState = { list: null, loading: false }


function compraAnimalReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_COMPRA_ANIMAL_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case LIST_COMPRA_ANIMAL_PENDING:
        case FIND_COMPRA_ANIMAL_PENDING:
        case DELETE_COMPRA_ANIMAL_PENDING:
        case CREATE_COMPRA_ANIMAL_PENDING:
        case TABLE_COMPRA_ANIMAL_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_COMPRA_ANIMAL_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_COMPRA_ANIMAL_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_COMPRA_ANIMAL_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_COMPRA_ANIMAL_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_COMPRA_ANIMAL_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_COMPRA_ANIMAL_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_COMPRA_ANIMAL_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_COMPRA_ANIMAL_SUCCESS:
        case CREATE_COMPRA_ANIMAL_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case DELETE_COMPRA_ANIMAL_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { compraAnimalReducer };