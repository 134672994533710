import { takeLatest, put, call } from 'redux-saga/effects';

import { findProdutoInsumoCadastro, importarProdutoWhiteLabel } from '../service';
import {
  IMPORTAR_PRODUTO_WHITE_LABEL,
  IMPORTAR_PRODUTO_WHITE_LABEL_PENDING,
  IMPORTAR_PRODUTO_WHITE_LABEL_SUCCESS,
  IMPORTAR_PRODUTO_WHITE_LABEL_FAILURE
} from '../actionTypes';
import { AlertError, AlertSuccess } from '@components/common/AlertToast';

function* sagaImportarProdutoWhiteLabel(action) {
  yield put({ type: IMPORTAR_PRODUTO_WHITE_LABEL_PENDING, id: action.id })

  try {
    yield call(importarProdutoWhiteLabel, action.id);

    yield put({
      type: IMPORTAR_PRODUTO_WHITE_LABEL_SUCCESS
    })

    AlertSuccess('Produto importado com sucesso');
  } catch (error) {
    yield put({ type: IMPORTAR_PRODUTO_WHITE_LABEL_FAILURE })

    AlertError('Falha ao importar produto do catálago. Tente novamente...');
  }
}

export default function* watchImportarProdutoWhiteLabel() {
  yield takeLatest(IMPORTAR_PRODUTO_WHITE_LABEL, sagaImportarProdutoWhiteLabel)
}
