import watchFindConfigGeral from './saga/find';
import watchUpdateConfigGeral from './saga/update';

export const configGeralSaga = [
    watchFindConfigGeral(),
    watchUpdateConfigGeral(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
