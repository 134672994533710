

import watchNovaContaAgrare from './saga/new';
import watchFindContaAgrare from './saga/find';
import watchUpdateContaAgrare from './saga/update';
import watchListContaAgrare from './saga/listTable';
import watchNewFilterContaAgrare from './saga/newFilter';
import watchNewFilterNextPageContaAgrare from './saga/newFilterNextPage';

export const contaAgrareSaga = [
    watchNovaContaAgrare(),
    watchFindContaAgrare(),
    watchUpdateContaAgrare(),
    watchListContaAgrare(),
    watchNewFilterContaAgrare(),
    watchNewFilterNextPageContaAgrare(),
]

export * from './actionTypes';
export * from './reducer';
export * from './entity';