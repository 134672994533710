
export const NEW_CONTA_CAIXA_CADASTRO = 'NEW_CONTA_CAIXA_CADASTRO'
export const NEW_CONTA_CAIXA_CADASTRO_SUCCESS = 'NEW_CONTA_CAIXA_CADASTRO_SUCCESS'

export const CREATE_CONTA_CAIXA_CADASTRO = 'CREATE_CONTA_CAIXA_CADASTRO'
export const CREATE_CONTA_CAIXA_CADASTRO_PENDING = 'CREATE_CONTA_CAIXA_CADASTRO_PENDING'
export const CREATE_CONTA_CAIXA_CADASTRO_SUCCESS = 'CREATE_CONTA_CAIXA_CADASTRO_SUCCESS'
export const CREATE_CONTA_CAIXA_CADASTRO_FAILURE = 'CREATE_CONTA_CAIXA_CADASTRO_FAILURE'

export const CANCEL_CONTA_CAIXA_CADASTRO_RAPIDO = 'CANCEL_CONTA_CAIXA_CADASTRO_RAPIDO'
export const CANCEL_CONTA_CAIXA_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_CONTA_CAIXA_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_CONTA_CAIXA_CADASTRO = 'TABLE_CONTA_CAIXA_CADASTRO'
export const TABLE_CONTA_CAIXA_CADASTRO_PENDING = 'TABLE_CONTA_CAIXA_CADASTRO_PENDING'
export const TABLE_CONTA_CAIXA_CADASTRO_SUCCESS = 'TABLE_CONTA_CAIXA_CADASTRO_SUCCESS'
export const TABLE_CONTA_CAIXA_CADASTRO_FAILURE = 'TABLE_CONTA_CAIXA_CADASTRO_FAILURE'

export const FIND_CONTA_CAIXA_CADASTRO = 'FIND_CONTA_CAIXA_CADASTRO'
export const FIND_CONTA_CAIXA_CADASTRO_PENDING = 'FIND_CONTA_CAIXA_CADASTRO_PENDING'
export const FIND_CONTA_CAIXA_CADASTRO_SUCCESS = 'FIND_CONTA_CAIXA_CADASTRO_SUCCESS'
export const FIND_CONTA_CAIXA_CADASTRO_FAILURE = 'FIND_CONTA_CAIXA_CADASTRO_FAILURE'

export const DELETE_CONTA_CAIXA_CADASTRO = 'DELETE_CONTA_CAIXA_CADASTRO'
export const DELETE_CONTA_CAIXA_CADASTRO_PENDING = 'DELETE_CONTA_CAIXA_CADASTRO_PENDING'
export const DELETE_CONTA_CAIXA_CADASTRO_SUCCESS = 'DELETE_CONTA_CAIXA_CADASTRO_SUCCESS'
export const DELETE_CONTA_CAIXA_CADASTRO_FAILURE = 'DELETE_CONTA_CAIXA_CADASTRO_FAILURE'
