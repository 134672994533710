import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_MOTORISTA_CADASTRO_RAPIDO,
    CANCEL_MOTORISTA_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelMotoristaCadastroRapido() {
  yield put({ type: CANCEL_MOTORISTA_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelMotoristaCadastroRapido() {
  yield takeLatest(CANCEL_MOTORISTA_CADASTRO_RAPIDO, sagaCancelMotoristaCadastroRapido)
}