import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createImplantacaoAnimal, updateImplantacaoAnimal } from '../service';
import {
  CREATE_IMPLANTACAO_ANIMAL,
  CREATE_IMPLANTACAO_ANIMAL_PENDING,
  CREATE_IMPLANTACAO_ANIMAL_SUCCESS,
  CREATE_IMPLANTACAO_ANIMAL_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { formatDateDDMMYYYY, isDateValidVerifyDateBetween } from '@components/common/format'

function* sagaCreateImplantacaoAnimal(action) {
  yield put({ type: CREATE_IMPLANTACAO_ANIMAL_PENDING })

  try {
    const anoPecuarioAtivo = JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo;
    var dateFrom = anoPecuarioAtivo.dataInicial;
    var dateTo = anoPecuarioAtivo.dataFinal;
    var dateCheck = action.data.data;

    if (!isDateValidVerifyDateBetween(dateFrom, dateTo, dateCheck)) {
      yield put({ type: CREATE_IMPLANTACAO_ANIMAL_FAILURE, registro: action.data })

      AlertWarning('Data informada fora do período do ano pecuário ativo. Período deve ser entre '
        + formatDateDDMMYYYY(dateFrom) + ' até ' + formatDateDDMMYYYY(dateTo) + '!');
    } else {
      delete action.data.totalDuplicatas;
      delete action.data.nroAnimais;

      if (action.data.id === undefined || action.data.id === null) {
        yield call(createImplantacaoAnimal, {
          ...action.data,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          },
        });
      } else {
        yield call(updateImplantacaoAnimal,
          {
            ...action.data,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_IMPLANTACAO_ANIMAL_SUCCESS, registro: action.data })

      history.push('/pecuaria/movimentacao/implantacao');

      AlertSuccess('Implantação registrada com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_IMPLANTACAO_ANIMAL_FAILURE, registro: action.data })

    AlertError('Falha ao salvar a implantação! Tente novamente...');
  }
}

export default function* watchCreateImplantacaoAnimal() {
  yield takeLatest(CREATE_IMPLANTACAO_ANIMAL, sagaCreateImplantacaoAnimal)
}
