


import watchNewPropriedadeCadastro from './saga/new';
import watchFindPropriedadeCadastro from './saga/find';
import watchCreatePropriedadeCadastro from './saga/create';
import watchListAllPropriedadeCadastro from './saga/listAll';
import watchListPropriedadeCadastro from './saga/listTable';
import watchDeletePropriedadeCadastro from './saga/delete';

export const propriedadeCadastroSaga = [
    watchNewPropriedadeCadastro(),
    watchFindPropriedadeCadastro(),
    watchCreatePropriedadeCadastro(),
    watchListAllPropriedadeCadastro(),
    watchListPropriedadeCadastro(),
    watchDeletePropriedadeCadastro(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
