import { takeLatest, put, call } from 'redux-saga/effects';

import { findCatalagoProdutoWhiteLabel } from '../service';
import {
  FIND_CATALAGO_PRODUTO_WHITE_LABEL,
  FIND_CATALAGO_PRODUTO_WHITE_LABEL_PENDING,
  FIND_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS,
  FIND_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE
} from '../actionTypes';

function* sagaFindCatalagoProdutoWhiteLabel(action) {
  yield put({ type: FIND_CATALAGO_PRODUTO_WHITE_LABEL_PENDING, id: action.id })

  try {

    var registro = yield call(findCatalagoProdutoWhiteLabel, action.id);
    if (registro.unidade === undefined) {
      registro.unidade = null;
    }
    
    yield put({ type: FIND_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindCatalagoProdutoWhiteLabel() {
  yield takeLatest(FIND_CATALAGO_PRODUTO_WHITE_LABEL, sagaFindCatalagoProdutoWhiteLabel)
}
