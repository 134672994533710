export const NEW_OUTRAS_SAIDA_ANIMAL = 'NEW_OUTRAS_SAIDA_ANIMAL'
export const NEW_OUTRAS_SAIDA_ANIMAL_SUCCESS = 'NEW_OUTRAS_SAIDA_ANIMAL_SUCCESS'

export const CREATE_OUTRAS_SAIDA_ANIMAL = 'CREATE_OUTRAS_SAIDA_ANIMAL'
export const CREATE_OUTRAS_SAIDA_ANIMAL_PENDING = 'CREATE_OUTRAS_SAIDA_ANIMAL_PENDING'
export const CREATE_OUTRAS_SAIDA_ANIMAL_SUCCESS = 'CREATE_OUTRAS_SAIDA_ANIMAL_SUCCESS'
export const CREATE_OUTRAS_SAIDA_ANIMAL_FAILURE = 'CREATE_OUTRAS_SAIDA_ANIMAL_FAILURE'

export const TABLE_OUTRAS_SAIDA_ANIMAL = 'TABLE_OUTRAS_SAIDA_ANIMAL'
export const TABLE_OUTRAS_SAIDA_ANIMAL_PENDING = 'TABLE_OUTRAS_SAIDA_ANIMAL_PENDING'
export const TABLE_OUTRAS_SAIDA_ANIMAL_SUCCESS = 'TABLE_OUTRAS_SAIDA_ANIMAL_SUCCESS'
export const TABLE_OUTRAS_SAIDA_ANIMAL_FAILURE = 'TABLE_OUTRAS_SAIDA_ANIMAL_FAILURE'

export const LIST_OUTRAS_SAIDA_ANIMAL = 'LIST_OUTRAS_SAIDA_ANIMAL'
export const LIST_OUTRAS_SAIDA_ANIMAL_PENDING = 'LIST_OUTRAS_SAIDA_ANIMAL_PENDING'
export const LIST_OUTRAS_SAIDA_ANIMAL_SUCCESS = 'LIST_OUTRAS_SAIDA_ANIMAL_SUCCESS'
export const LIST_OUTRAS_SAIDA_ANIMAL_FAILURE = 'LIST_OUTRAS_SAIDA_ANIMAL_FAILURE'

export const FIND_OUTRAS_SAIDA_ANIMAL = 'FIND_OUTRAS_SAIDA_ANIMAL'
export const FIND_OUTRAS_SAIDA_ANIMAL_PENDING = 'FIND_OUTRAS_SAIDA_ANIMAL_PENDING'
export const FIND_OUTRAS_SAIDA_ANIMAL_SUCCESS = 'FIND_OUTRAS_SAIDA_ANIMAL_SUCCESS'
export const FIND_OUTRAS_SAIDA_ANIMAL_FAILURE = 'FIND_OUTRAS_SAIDA_ANIMAL_FAILURE'

export const DELETE_OUTRAS_SAIDA_ANIMAL = 'DELETE_OUTRAS_SAIDA_ANIMAL'
export const DELETE_OUTRAS_SAIDA_ANIMAL_PENDING = 'DELETE_OUTRAS_SAIDA_ANIMAL_PENDING'
export const DELETE_OUTRAS_SAIDA_ANIMAL_SUCCESS = 'DELETE_OUTRAS_SAIDA_ANIMAL_SUCCESS'
export const DELETE_OUTRAS_SAIDA_ANIMAL_FAILURE = 'DELETE_OUTRAS_SAIDA_ANIMAL_FAILURE'