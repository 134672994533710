import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { storage } from '@commons/storage';

import { createContaPagar, updateContaPagar } from '../service';
import {
  CREATE_CONTA_PAGAR,
  CREATE_CONTA_PAGAR_PENDING,
  CREATE_CONTA_PAGAR_SUCCESS,
  CREATE_CONTA_PAGAR_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';
import uuid from 'react-uuid';
import { createContaPagarListAll } from '..';

function* sagaCreateContaPagar(action) {
  yield put({ type: CREATE_CONTA_PAGAR_PENDING })

  try {
    // NOVA CONTA
    if (action.data.id === undefined || action.data.id === null) {
      // PROCESSA PARCELA UNICA
      if (action.data.tipoOcorrencia === 'UNICA') {
        delete action.data.parcelas;
        delete action.data.dataPrimeiraParcela;
        delete action.data.numeroParcelas;

        if (!action.data.vincularPatrimonio) {
          action.data.patrimonio = null;
        }

        if (action.data.data < action.data.dataEmissao) {
          AlertWarning('Data de Vencimento menor que Data de Emissão. Verifique...');
          yield put({ type: CREATE_CONTA_PAGAR_FAILURE, registro: action.data })
        } else {
          if (action.data.contaPaga === true) {
            let pagamentos = [];

            let registroPgto = {
              automatico: false,
              data: action.data.dataPgto,
              total: {
                valorCapital: action.data.valor,
                valorDesconto: 0.0,
                valorJuro: 0.0,
                valorMulta: 0.0,
                valorOutrasDespesas: 0.0,
                valorTotal: action.data.valor,
              }
            }

            if (action.data.tipoPagamento === 'DINHEIRO') {
              registroPgto = {
                ...registroPgto,
                tipoPagamento: "DINHEIRO",
                pagamentoDinheiro: {
                  contaCaixa: {
                    id: action.data.contaCaixa.id
                  }
                }
              }
            }

            if (action.data.tipoPagamento === 'BOLETO_BANCARIO' || action.data.tipoPagamento === 'PIX' || action.data.tipoPagamento === 'TRANSF_BANCARIA' ||
              action.data.tipoPagamento === 'CHEQUE_A_VISTA' ||
              action.data.tipoPagamento === 'CHEQUE_PRE_DATADO' ||
              action.data.tipoPagamento === 'DEBITO_EM_CONTA' ||
              action.data.tipoPagamento === 'CREDITO_EM_CONTA' ||
              action.data.tipoPagamento === 'CARTAO_CREDITO' ||
              action.data.tipoPagamento === 'CARTAO_DEBITO' ||
              action.data.tipoPagamento === 'CARNE' ||
              action.data.tipoPagamento === 'GUIA') {
              registroPgto = {
                ...registroPgto,
                tipoPagamento: action.data.tipoPagamento,
                pagamentoBanco: {
                  contaBanco: {
                    id: action.data.contaBanco.id
                  }
                }
              }
            }

            // if (action.data.tipoPagamento === 'CARTAO_CREDITO' || action.data.tipoPagamento === 'CARTAO_DEBITO' || action.data.tipoPagamento === 'CARTAO_VOUCHER') {
            //   registroPgto = formatJsonRemoveEmptyOrNull(
            //     {
            //       ...registroPgto,
            //       tipoPagamento: action.data.tipoPagamento,
            //       pagamentoCartao: {
            //         bandeira: action.data.cartaoBandeira,
            //         nsu: action.data.cartaoNsu,
            //         contaCartao: {
            //           id: action.data.conta.id
            //         }
            //       }
            //     }
            //   )
            // }

            // if (action.data.tipoPagamento === 'CHEQUE_A_VISTA' || action.data.tipoPagamento === 'CHEQUE_PRE_DATADO') {
            //   registroPgto = formatJsonRemoveEmptyOrNull(
            //     {
            //       ...registroPgto,
            //       tipoPagamento: action.data.tipoPagamento,
            //       pagamentoCheque: {
            //         dataPrevisao: action.data.chequeData,
            //         emitente: action.data.chequeEmitente,
            //         numeroCheque: action.data.chequeNro,
            //         banco: action.data.chequeBanco,
            //         numeroAgencia: action.data.chequeNroAg,
            //         dvAgencia: action.data.chequeDvAg,
            //         contaCorrente: action.data.chequeNroCc,
            //         dvContaCorrente: action.data.chequeDvCc,
            //         contaCheque: {
            //           id: action.data.conta.id
            //         }
            //       }
            //     }
            //   )
            // }

            pagamentos.push(registroPgto)

            action.data = {
              ...action.data,
              valorPendente: action.data.valor,
              pagamentos: pagamentos
            }
          }
          delete action.data.contaPaga;
          delete action.data.tipoPagamento;
          delete action.data.contaCaixa;
          delete action.data.contaBanco;

          yield call(createContaPagar, formatJsonRemoveEmptyOrNull(
            {
              ...action.data,
              propriedade: {
                id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
              }
            }
          ));

          yield put({ type: CREATE_CONTA_PAGAR_SUCCESS, registro: action.data })

          history.push('/financeiro/contaPagar');

          AlertSuccess('Conta salva com sucesso!');
        }
      } else {
        // PROCESSA PARCELAS

        let idParcelasVinc = uuid();
        let parcelasASalvar = []

        for (let index = 0; index < action.data.parcelas.length; index++) {
          if (!action.data.vincularPatrimonio) {
            action.data.patrimonio = null;
          }

          let parcela = {
            ...action.data
          };

          parcela.data = action.data.parcelas[index].dataVencimento;
          parcela.valor = action.data.parcelas[index].valorTotal;
          parcela.historico = action.data.historico + ' - Parc. ' + (index + 1) + '/' + action.data.parcelas.length;

          delete parcela.parcelas;
          delete parcela.dataPrimeiraParcela;
          delete parcela.numeroParcelas;


          if (action.data.parcelas[index].parcelaPaga === true) {
            let pagamentos = [];

            let registroPgto = {
              automatico: false,
              data: action.data.parcelas[index].dataPgto,
              total: {
                valorCapital: parcela.valor,
                valorDesconto: 0.0,
                valorJuro: 0.0,
                valorMulta: 0.0,
                valorOutrasDespesas: 0.0,
                valorTotal: parcela.valor,
              }
            }

            if (action.data.parcelas[index].tipoPagamento === 'DINHEIRO') {
              registroPgto = {
                ...registroPgto,
                tipoPagamento: "DINHEIRO",
                pagamentoDinheiro: {
                  contaCaixa: {
                    id: action.data.parcelas[index].contaCaixa.id
                  }
                }
              }
            }

            if (action.data.parcelas[index].tipoPagamento === 'BOLETO_BANCARIO' || action.data.parcelas[index].tipoPagamento === 'PIX' ||
              action.data.parcelas[index].tipoPagamento === 'TRANSF_BANCARIA' ||
              action.data.parcelas[index].tipoPagamento === 'CHEQUE_A_VISTA' ||
              action.data.parcelas[index].tipoPagamento === 'CHEQUE_PRE_DATADO' ||
              action.data.parcelas[index].tipoPagamento === 'DEBITO_EM_CONTA' ||
              action.data.parcelas[index].tipoPagamento === 'CREDITO_EM_CONTA' ||
              action.data.parcelas[index].tipoPagamento === 'CARTAO_CREDITO' ||
              action.data.parcelas[index].tipoPagamento === 'CARTAO_DEBITO' ||
              action.data.parcelas[index].tipoPagamento === 'CARNE' ||
              action.data.parcelas[index].tipoPagamento === 'GUIA') {
              registroPgto = {
                ...registroPgto,
                tipoPagamento: action.data.parcelas[index].tipoPagamento,
                pagamentoBanco: {
                  contaBanco: {
                    id: action.data.parcelas[index].contaBanco.id
                  }
                }
              }
            }

            // if (action.data.parcelas[index].tipoPagamento === 'CARTAO_CREDITO' || action.data.parcelas[index].tipoPagamento === 'CARTAO_DEBITO' || action.data.parcelas[index].tipoPagamento === 'CARTAO_VOUCHER') {
            //   registroPgto = formatJsonRemoveEmptyOrNull(
            //     {
            //       ...registroPgto,
            //       tipoPagamento: action.data.parcelas[index].tipoPagamento,
            //       pagamentoCartao: {
            //         bandeira: action.data.parcelas[index].cartaoBandeira,
            //         nsu: action.data.parcelas[index].cartaoNsu,
            //         contaCartao: {
            //           id: action.data.parcelas[index].conta.id
            //         }
            //       }
            //     }
            //   )
            // }

            // if (action.data.parcelas[index].tipoPagamento === 'CHEQUE_A_VISTA' || action.data.parcelas[index].tipoPagamento === 'CHEQUE_PRE_DATADO') {
            //   registroPgto = formatJsonRemoveEmptyOrNull(
            //     {
            //       ...registroPgto,
            //       tipoPagamento: action.data.parcelas[index].tipoPagamento,
            //       pagamentoCheque: {
            //         dataPrevisao: action.data.parcelas[index].chequeData,
            //         emitente: action.data.parcelas[index].chequeEmitente,
            //         numeroCheque: action.data.parcelas[index].chequeNro,
            //         banco: action.data.parcelas[index].chequeBanco,
            //         numeroAgencia: action.data.parcelas[index].chequeNroAg,
            //         dvAgencia: action.data.parcelas[index].chequeDvAg,
            //         contaCorrente: action.data.parcelas[index].chequeNroCc,
            //         dvContaCorrente: action.data.parcelas[index].chequeDvCc,
            //         contaCheque: {
            //           id: action.data.parcelas[index].conta.id
            //         }
            //       }
            //     }
            //   )
            // }

            pagamentos.push(registroPgto)

            parcela = {
              ...parcela,
              valorPendente: parcela.valor,
              pagamentos: pagamentos
            }
          }
          delete parcela.contaPaga;
          delete parcela.tipoPagamento;
          delete parcela.contaCaixa;
          delete parcela.contaBanco;

          parcelasASalvar.push(formatJsonRemoveEmptyOrNull(
            {
              ...parcela,
              idParcelasVinculadas: idParcelasVinc,
              propriedade: {
                id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
              }
            }
          ));

          // yield call(createContaPagar, formatJsonRemoveEmptyOrNull(
          //   {
          //     ...parcela,
          //     idParcelasVinculadas: idParcelasVinc,
          //     propriedade: {
          //       id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          //     }
          //   }
          // ));
        }

        yield call(createContaPagarListAll, parcelasASalvar);

        yield put({ type: CREATE_CONTA_PAGAR_SUCCESS, registro: action.data })

        history.push('/financeiro/contaPagar');

        AlertSuccess('Parcelas salvas com sucesso!');

      }
    } else {
      // EDITANDO CONTA
      if (action.data.data < action.data.dataEmissao) {
        AlertWarning('Data de Vencimento menor que Data de Emissão. Verifique...');
        yield put({ type: CREATE_CONTA_PAGAR_FAILURE, registro: action.data })
      } else {
        yield call(updateContaPagar,
          formatJsonRemoveEmptyOrNull({
            ...action.data,
            valorPendente: action.data.valor,
            id: action.data.id
          })
        );

        yield put({ type: CREATE_CONTA_PAGAR_SUCCESS, registro: action.data })

        history.push('/financeiro/contaPagar');

        AlertSuccess('Conta salva com sucesso!');
      }
    }
  } catch (error) {
    yield put({ type: CREATE_CONTA_PAGAR_FAILURE, registro: action.data })

    if (error.response === undefined || error.response.status === undefined || error.response.status !== 422) {
      AlertError('Falha ao salvar a conta! Tente novamente...');
    } else {
      AlertWarning(error.response.data.text);
    }
  }

}

export default function* watchCreateContaPagar() {
  yield takeLatest(CREATE_CONTA_PAGAR, sagaCreateContaPagar)
}
