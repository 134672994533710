import React from 'react';

// See more loading icons here:
// https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons
const PageLoader = ({ textAlign = 'center', iconColor = '#6c757d' }) => (
    <div className="page-loader" style={{ textAlign }}>
    <em className="fas fa-circle-notch fa-spin fa-2x" style={{ color: iconColor }}></em>
    </div>
);

export default PageLoader;