
export const NEW_CONTROLE_CONTA_BANCO = 'NEW_CONTROLE_CONTA_BANCO'
export const NEW_CONTROLE_CONTA_BANCO_SUCCESS = 'NEW_CONTROLE_CONTA_BANCO_SUCCESS'

export const NEW_FILTER_CONTROLE_CONTA_BANCO = 'NEW_FILTER_CONTROLE_CONTA_BANCO'
export const NEW_FILTER_CONTROLE_CONTA_BANCO_PEDING = 'NEW_FILTER_CONTROLE_CONTA_BANCO_PEDING'
export const NEW_FILTER_CONTROLE_CONTA_BANCO_SUCCESS = 'NEW_FILTER_CONTROLE_CONTA_BANCO_SUCCESS'
export const NEW_FILTER_CONTROLE_CONTA_BANCO_FAILURE = 'NEW_FILTER_CONTROLE_CONTA_BANCO_FAILURE'
export const NEW_FILTER_CONTROLE_CONTA_BANCO_SUCCESS_PDF = 'NEW_FILTER_CONTROLE_CONTA_BANCO_SUCCESS_PDF'

export const NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO = 'NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO'
export const NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO_PENDING = 'NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO_PENDING'
export const NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO_SUCCESS = 'NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO_FAILURE = 'NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO_FAILURE'

export const GET_TOTAIS_CONTROLE_CONTA_BANCO_PENDING = 'GET_TOTAIS_CONTROLE_CONTA_BANCO_PENDING'
export const GET_TOTAIS_CONTROLE_CONTA_BANCO_SUCCESS = 'GET_TOTAIS_CONTROLE_CONTA_BANCO_SUCCESS'
export const GET_TOTAIS_CONTROLE_CONTA_BANCO_FAILURE = 'GET_TOTAIS_CONTROLE_CONTA_BANCO_FAILURE'

export const CREATE_CONTROLE_CONTA_BANCO = 'CREATE_CONTROLE_CONTA_BANCO'
export const CREATE_CONTROLE_CONTA_BANCO_PENDING = 'CREATE_CONTROLE_CONTA_BANCO_PENDING'
export const CREATE_CONTROLE_CONTA_BANCO_SUCCESS = 'CREATE_CONTROLE_CONTA_BANCO_SUCCESS'
export const CREATE_CONTROLE_CONTA_BANCO_FAILURE = 'CREATE_CONTROLE_CONTA_BANCO_FAILURE'

export const FIND_CONTROLE_CONTA_BANCO = 'FIND_CONTROLE_CONTA_BANCO'
export const FIND_CONTROLE_CONTA_BANCO_PENDING = 'FIND_CONTROLE_CONTA_BANCO_PENDING'
export const FIND_CONTROLE_CONTA_BANCO_SUCCESS = 'FIND_CONTROLE_CONTA_BANCO_SUCCESS'
export const FIND_CONTROLE_CONTA_BANCO_FAILURE = 'FIND_CONTROLE_CONTA_BANCO_FAILURE'

export const DELETE_CONTROLE_CONTA_BANCO = 'DELETE_CONTROLE_CONTA_BANCO'
export const DELETE_CONTROLE_CONTA_BANCO_PENDING = 'DELETE_CONTROLE_CONTA_BANCO_PENDING'
export const DELETE_CONTROLE_CONTA_BANCO_SUCCESS = 'DELETE_CONTROLE_CONTA_BANCO_SUCCESS'
export const DELETE_CONTROLE_CONTA_BANCO_FAILURE = 'DELETE_CONTROLE_CONTA_BANCO_FAILURE'
