export const UNIDADE_COMPRA_ANIMAL_ENUM = [
    {
        id: 'KG',
        descricao: "Kg",
    },
    {
        id: 'ARROBA',
        descricao: "Arroba (Boi Vivo)",
    },
    {
        id: 'CABECA',
        descricao: "Cabeça",
    },
];