import { takeLatest, put, call } from 'redux-saga/effects'

import { httpAuthService } from '@commons';
import { history } from '@commons';
import swal from 'sweetalert';

import {
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_PENDING,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_FAILURE
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

const recoverPassword = async (values) => {
  await httpAuthService.recoverPassword(values);
}

function* sagaRecoverPassword(action) {
  yield put({ type: RECOVER_PASSWORD_PENDING })

  try {

    yield call(recoverPassword, action.data);

    yield put({ type: RECOVER_PASSWORD_SUCCESS });

    swal({
      title: 'E-mail enviado com sucesso! Verifique a caixa de entrada do e-mail informado para realizar a alteração da nova senha.',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.push('/login');
    });
  } catch (error) {
    yield put({ type: RECOVER_PASSWORD_FAILURE });

    AlertError(error.text);
  }

}

export default function* watchRecoverPassword() {
  yield takeLatest(RECOVER_PASSWORD, sagaRecoverPassword);
}
