
export const NEW_TIPO_EMBALAGEM_CADASTRO = 'NEW_TIPO_EMBALAGEM_CADASTRO'
export const NEW_TIPO_EMBALAGEM_CADASTRO_SUCCESS = 'NEW_TIPO_EMBALAGEM_CADASTRO_SUCCESS'

export const CREATE_TIPO_EMBALAGEM_CADASTRO = 'CREATE_TIPO_EMBALAGEM_CADASTRO'
export const CREATE_TIPO_EMBALAGEM_CADASTRO_PENDING = 'CREATE_TIPO_EMBALAGEM_CADASTRO_PENDING'
export const CREATE_TIPO_EMBALAGEM_CADASTRO_SUCCESS = 'CREATE_TIPO_EMBALAGEM_CADASTRO_SUCCESS'
export const CREATE_TIPO_EMBALAGEM_CADASTRO_FAILURE = 'CREATE_TIPO_EMBALAGEM_CADASTRO_FAILURE'

export const CANCEL_TIPO_EMBALAGEM_CADASTRO_RAPIDO = 'CANCEL_TIPO_EMBALAGEM_CADASTRO_RAPIDO'
export const CANCEL_TIPO_EMBALAGEM_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_TIPO_EMBALAGEM_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_TIPO_EMBALAGEM_CADASTRO = 'TABLE_TIPO_EMBALAGEM_CADASTRO'
export const TABLE_TIPO_EMBALAGEM_CADASTRO_PENDING = 'TABLE_TIPO_EMBALAGEM_CADASTRO_PENDING'
export const TABLE_TIPO_EMBALAGEM_CADASTRO_SUCCESS = 'TABLE_TIPO_EMBALAGEM_CADASTRO_SUCCESS'
export const TABLE_TIPO_EMBALAGEM_CADASTRO_FAILURE = 'TABLE_TIPO_EMBALAGEM_CADASTRO_FAILURE'

export const LIST_TIPO_EMBALAGEM_CADASTRO = 'LIST_TIPO_EMBALAGEM_CADASTRO'
export const LIST_TIPO_EMBALAGEM_CADASTRO_PENDING = 'LIST_TIPO_EMBALAGEM_CADASTRO_PENDING'
export const LIST_TIPO_EMBALAGEM_CADASTRO_SUCCESS = 'LIST_TIPO_EMBALAGEM_CADASTRO_SUCCESS'
export const LIST_TIPO_EMBALAGEM_CADASTRO_FAILURE = 'LIST_TIPO_EMBALAGEM_CADASTRO_FAILURE'

export const FIND_TIPO_EMBALAGEM_CADASTRO = 'FIND_TIPO_EMBALAGEM_CADASTRO'
export const FIND_TIPO_EMBALAGEM_CADASTRO_PENDING = 'FIND_TIPO_EMBALAGEM_CADASTRO_PENDING'
export const FIND_TIPO_EMBALAGEM_CADASTRO_SUCCESS = 'FIND_TIPO_EMBALAGEM_CADASTRO_SUCCESS'
export const FIND_TIPO_EMBALAGEM_CADASTRO_FAILURE = 'FIND_TIPO_EMBALAGEM_CADASTRO_FAILURE'

export const DELETE_TIPO_EMBALAGEM_CADASTRO = 'DELETE_TIPO_EMBALAGEM_CADASTRO'
export const DELETE_TIPO_EMBALAGEM_CADASTRO_PENDING = 'DELETE_TIPO_EMBALAGEM_CADASTRO_PENDING'
export const DELETE_TIPO_EMBALAGEM_CADASTRO_SUCCESS = 'DELETE_TIPO_EMBALAGEM_CADASTRO_SUCCESS'
export const DELETE_TIPO_EMBALAGEM_CADASTRO_FAILURE = 'DELETE_TIPO_EMBALAGEM_CADASTRO_FAILURE'
