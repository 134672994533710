import { takeLatest, put, call } from 'redux-saga/effects';

import { listAnimalCategoriaCadastro } from '../service';
import {
    TABLE_ANIMAL_CATEGORIA_CADASTRO,
    TABLE_ANIMAL_CATEGORIA_CADASTRO_PENDING,
    TABLE_ANIMAL_CATEGORIA_CADASTRO_SUCCESS,
    TABLE_ANIMAL_CATEGORIA_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAnimalCategoriaCadastro(action) {
  yield put({ type: TABLE_ANIMAL_CATEGORIA_CADASTRO_PENDING })

  try {
    const table = yield call(listAnimalCategoriaCadastro, action.args)
    yield put({ type: TABLE_ANIMAL_CATEGORIA_CADASTRO_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_ANIMAL_CATEGORIA_CADASTRO_FAILURE })
  }
}

export default function* watchListAnimalCategoriaCadastro() {
  yield takeLatest(TABLE_ANIMAL_CATEGORIA_CADASTRO, sagaListAnimalCategoriaCadastro)
}