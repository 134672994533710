import watchNewAnimalCategoriaCadastro from './saga/new';
import watchFindAnimalCategoriaCadastro from './saga/find';
import watchCreateAnimalCategoriaCadastro from './saga/create';
import watchListAnimalCategoriaCadastro from './saga/listTable';
import watchDeleteAnimalCategoriaCadastro from './saga/delete';
import watchCancelAnimalCategoriaCadastroRapido from './saga/cancel';

export const animalCategoriaCadastroSaga = [
    watchNewAnimalCategoriaCadastro(),
    watchFindAnimalCategoriaCadastro(),
    watchCreateAnimalCategoriaCadastro(),
    watchListAnimalCategoriaCadastro(),
    watchDeleteAnimalCategoriaCadastro(),
    watchCancelAnimalCategoriaCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
