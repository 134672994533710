
export const NEW_EMITENTE_CADASTRO = 'NEW_EMITENTE_CADASTRO'
export const NEW_EMITENTE_CADASTRO_SUCCESS = 'NEW_EMITENTE_CADASTRO_SUCCESS'

export const CREATE_EMITENTE_CADASTRO = 'CREATE_EMITENTE_CADASTRO'
export const CREATE_EMITENTE_CADASTRO_PENDING = 'CREATE_EMITENTE_CADASTRO_PENDING'
export const CREATE_EMITENTE_CADASTRO_SUCCESS = 'CREATE_EMITENTE_CADASTRO_SUCCESS'
export const CREATE_EMITENTE_CADASTRO_FAILURE = 'CREATE_EMITENTE_CADASTRO_FAILURE'

export const CANCEL_EMITENTE_CADASTRO_RAPIDO = 'CANCEL_EMITENTE_CADASTRO_RAPIDO'
export const CANCEL_EMITENTE_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_EMITENTE_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_EMITENTE_CADASTRO = 'TABLE_EMITENTE_CADASTRO'
export const TABLE_EMITENTE_CADASTRO_PENDING = 'TABLE_EMITENTE_CADASTRO_PENDING'
export const TABLE_EMITENTE_CADASTRO_SUCCESS = 'TABLE_EMITENTE_CADASTRO_SUCCESS'
export const TABLE_EMITENTE_CADASTRO_FAILURE = 'TABLE_EMITENTE_CADASTRO_FAILURE'

export const LIST_EMITENTE_CADASTRO = 'LIST_EMITENTE_CADASTRO'
export const LIST_EMITENTE_CADASTRO_PENDING = 'LIST_EMITENTE_CADASTRO_PENDING'
export const LIST_EMITENTE_CADASTRO_SUCCESS = 'LIST_EMITENTE_CADASTRO_SUCCESS'
export const LIST_EMITENTE_CADASTRO_FAILURE = 'LIST_EMITENTE_CADASTRO_FAILURE'

export const FIND_EMITENTE_CADASTRO = 'FIND_EMITENTE_CADASTRO'
export const FIND_EMITENTE_CADASTRO_PENDING = 'FIND_EMITENTE_CADASTRO_PENDING'
export const FIND_EMITENTE_CADASTRO_SUCCESS = 'FIND_EMITENTE_CADASTRO_SUCCESS'
export const FIND_EMITENTE_CADASTRO_FAILURE = 'FIND_EMITENTE_CADASTRO_FAILURE'

export const DELETE_EMITENTE_CADASTRO = 'DELETE_EMITENTE_CADASTRO'
export const DELETE_EMITENTE_CADASTRO_PENDING = 'DELETE_EMITENTE_CADASTRO_PENDING'
export const DELETE_EMITENTE_CADASTRO_SUCCESS = 'DELETE_EMITENTE_CADASTRO_SUCCESS'
export const DELETE_EMITENTE_CADASTRO_FAILURE = 'DELETE_EMITENTE_CADASTRO_FAILURE'
