


import watchNewIdentificadorCadastro from './saga/new';
import watchFindIdentificadorCadastro from './saga/find';
import watchCreateIdentificadorCadastro from './saga/create';
import watchListAllIdentificadorCadastro from './saga/listAll';
import watchListIdentificadorCadastro from './saga/listTable';
import watchDeleteIdentificadorCadastro from './saga/delete';
import watchCancelIdentificadorCadastroRapido from './saga/cancel';

export const identificadorCadastroSaga = [
    watchNewIdentificadorCadastro(),
    watchFindIdentificadorCadastro(),
    watchCreateIdentificadorCadastro(),
    watchListAllIdentificadorCadastro(),
    watchListIdentificadorCadastro(),
    watchDeleteIdentificadorCadastro(),
    watchCancelIdentificadorCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
