import {
    TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_PENDING,
    TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_SUCCESS,
    TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_FAILURE,
} from './actionTypes';

const filter = {
    totais: {
        "totalProdutores": 0,
        "totalFazendas": 0,
        "totalHectares": 0,
    }
}

const initialState = { filter: filter, loading: false, }

function dashboardCorporateWhiteLabelReducer(state = initialState, action) {

    switch (action.type) {
        case TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_PENDING:
            return {
                ...state,
                loading: true,
            }
        case TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_SUCCESS:
        case TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_FAILURE:
            return {
                ...state,
                filter: action.filter,
                loading: false
            }
        default:
            return state
    }
}

export { dashboardCorporateWhiteLabelReducer };