


import watchNewContaCaixaCadastro from './saga/new';
import watchFindContaCaixaCadastro from './saga/find';
import watchCreateContaCaixaCadastro from './saga/create';
import watchListContaCaixaCadastro from './saga/listTable';
import watchDeleteContaCaixaCadastro from './saga/delete';
import watchCancelContaCaixaCadastroRapido from './saga/cancel';

export const contaCaixaCadastroSaga = [
    watchNewContaCaixaCadastro(),
    watchFindContaCaixaCadastro(),
    watchCreateContaCaixaCadastro(),
    watchListContaCaixaCadastro(),
    watchDeleteContaCaixaCadastro(),
    watchCancelContaCaixaCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
