import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
  CONTA_AGRARE_PENDING,
  CONTA_AGRARE_SUCCESS,
  CONTA_AGRARE_FAILURE,
  TABLE_CONTA_AGRARE_PENDING,
  TABLE_CONTA_AGRARE_SUCCESS,
  TABLE_CONTA_AGRARE_FAILURE,
  FIND_CONTA_AGRARE_PENDING,
  FIND_CONTA_AGRARE_SUCCESS,
  FIND_CONTA_AGRARE_FAILURE,
  UPDATE_CONTA_AGRARE_PENDING,
  UPDATE_CONTA_AGRARE_SUCCESS,
  UPDATE_CONTA_AGRARE_FAILURE,
  NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_PENDING,
  NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_SUCCESS,
  NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_FAILURE,
  NEW_FILTER_CONTA_AGRARE_PEDING,
  NEW_FILTER_CONTA_AGRARE_SUCCESS,
  NEW_FILTER_CONTA_AGRARE_FAILURE
} from './actionTypes';

const initialState = {
  list: null,
  loading: false,
  filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
}

function contaAgrareReducer(state = initialState, action) {

  switch (action.type) {
    case TABLE_CONTA_AGRARE_PENDING:
    case FIND_CONTA_AGRARE_PENDING:
    case UPDATE_CONTA_AGRARE_PENDING:
    case CONTA_AGRARE_PENDING:
    case NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_PENDING:
    case NEW_FILTER_CONTA_AGRARE_PEDING:
      return {
        ...state,
        loading: true
      }
    case UPDATE_CONTA_AGRARE_SUCCESS:
    case CONTA_AGRARE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case UPDATE_CONTA_AGRARE_FAILURE:
    case CONTA_AGRARE_FAILURE:
      return {
        ...state,
        loading: false
      }
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    case NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_SUCCESS:
      return {
        ...state,
        list: action.list,
        filter: action.filter,
        loading: false
      }
    case NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_FAILURE:
      return {
        ...state,
        list: null,
        loading: false
      }
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    case NEW_FILTER_CONTA_AGRARE_SUCCESS: {
      return {
        ...state,
        list: action.list,
        filter: action.filter,
        loading: false
      }
    }
    case NEW_FILTER_CONTA_AGRARE_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    case TABLE_CONTA_AGRARE_SUCCESS:
      return {
        ...state,
        table: action.table,
        loading: false
      }
    case TABLE_CONTA_AGRARE_FAILURE:
      return {
        ...state,
        table: null,
        loading: false
      }
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    case FIND_CONTA_AGRARE_SUCCESS:
      return {
        ...state,
        registro: action.registro,
        loading: false
      }
    case FIND_CONTA_AGRARE_FAILURE:
      return {
        ...state,
        registro: null,
        loading: false
      }
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    default:
      return { ...state }
  }
}

export { contaAgrareReducer };
