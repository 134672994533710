
export const NEW_NATUREZA_OPERACAO_CADASTRO = 'NEW_NATUREZA_OPERACAO_CADASTRO'
export const NEW_NATUREZA_OPERACAO_CADASTRO_SUCCESS = 'NEW_NATUREZA_OPERACAO_CADASTRO_SUCCESS'

export const CREATE_NATUREZA_OPERACAO_CADASTRO = 'CREATE_NATUREZA_OPERACAO_CADASTRO'
export const CREATE_NATUREZA_OPERACAO_CADASTRO_PENDING = 'CREATE_NATUREZA_OPERACAO_CADASTRO_PENDING'
export const CREATE_NATUREZA_OPERACAO_CADASTRO_SUCCESS = 'CREATE_NATUREZA_OPERACAO_CADASTRO_SUCCESS'
export const CREATE_NATUREZA_OPERACAO_CADASTRO_FAILURE = 'CREATE_NATUREZA_OPERACAO_CADASTRO_FAILURE'

export const CANCEL_NATUREZA_OPERACAO_CADASTRO_RAPIDO = 'CANCEL_NATUREZA_OPERACAO_CADASTRO_RAPIDO'
export const CANCEL_NATUREZA_OPERACAO_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_NATUREZA_OPERACAO_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_NATUREZA_OPERACAO_CADASTRO = 'TABLE_NATUREZA_OPERACAO_CADASTRO'
export const TABLE_NATUREZA_OPERACAO_CADASTRO_PENDING = 'TABLE_NATUREZA_OPERACAO_CADASTRO_PENDING'
export const TABLE_NATUREZA_OPERACAO_CADASTRO_SUCCESS = 'TABLE_NATUREZA_OPERACAO_CADASTRO_SUCCESS'
export const TABLE_NATUREZA_OPERACAO_CADASTRO_FAILURE = 'TABLE_NATUREZA_OPERACAO_CADASTRO_FAILURE'

export const LIST_NATUREZA_OPERACAO_CADASTRO = 'LIST_NATUREZA_OPERACAO_CADASTRO'
export const LIST_NATUREZA_OPERACAO_CADASTRO_PENDING = 'LIST_NATUREZA_OPERACAO_CADASTRO_PENDING'
export const LIST_NATUREZA_OPERACAO_CADASTRO_SUCCESS = 'LIST_NATUREZA_OPERACAO_CADASTRO_SUCCESS'
export const LIST_NATUREZA_OPERACAO_CADASTRO_FAILURE = 'LIST_NATUREZA_OPERACAO_CADASTRO_FAILURE'

export const FIND_NATUREZA_OPERACAO_CADASTRO = 'FIND_NATUREZA_OPERACAO_CADASTRO'
export const FIND_NATUREZA_OPERACAO_CADASTRO_PENDING = 'FIND_NATUREZA_OPERACAO_CADASTRO_PENDING'
export const FIND_NATUREZA_OPERACAO_CADASTRO_SUCCESS = 'FIND_NATUREZA_OPERACAO_CADASTRO_SUCCESS'
export const FIND_NATUREZA_OPERACAO_CADASTRO_FAILURE = 'FIND_NATUREZA_OPERACAO_CADASTRO_FAILURE'

export const DELETE_NATUREZA_OPERACAO_CADASTRO = 'DELETE_NATUREZA_OPERACAO_CADASTRO'
export const DELETE_NATUREZA_OPERACAO_CADASTRO_PENDING = 'DELETE_NATUREZA_OPERACAO_CADASTRO_PENDING'
export const DELETE_NATUREZA_OPERACAO_CADASTRO_SUCCESS = 'DELETE_NATUREZA_OPERACAO_CADASTRO_SUCCESS'
export const DELETE_NATUREZA_OPERACAO_CADASTRO_FAILURE = 'DELETE_NATUREZA_OPERACAO_CADASTRO_FAILURE'
