import { takeLatest, put, call } from 'redux-saga/effects';

import { path, storage } from '@commons';
import { getUsersWhiteLabel } from '../service';
import {
  TABLE_USUARIO_WHITE_LABEL,
  TABLE_USUARIO_WHITE_LABEL_PENDING,
  TABLE_USUARIO_WHITE_LABEL_SUCCESS,
  TABLE_USUARIO_WHITE_LABEL_FAILURE
} from '../actionTypes';

function* sagaListUsuarioWhiteLabel(action) {
  yield put({ type: TABLE_USUARIO_WHITE_LABEL_PENDING })

  try {
    let user = JSON.parse(storage.get(path.sessionUser));

    const table = yield call(getUsersWhiteLabel, action.args, user.identificationCodeWhiteLabel)
    
    yield put({ type: TABLE_USUARIO_WHITE_LABEL_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_USUARIO_WHITE_LABEL_FAILURE })
  }
}

export default function* watchListUsuarioWhiteLabel() {
  yield takeLatest(TABLE_USUARIO_WHITE_LABEL, sagaListUsuarioWhiteLabel)
}