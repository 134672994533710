


import watchNewPerfil from './saga/new';
import watchFindPerfil from './saga/find';
import watchCreatePerfil from './saga/create';
import watchListPerfil from './saga/list';
import watchDeletePerfil from './saga/delete';
import watchListAllPerfil from './saga/listAll';
import watchCancelPerfilCadastroRapido from './saga/cancel';

export const perfilSaga = [
    watchNewPerfil(),
    watchFindPerfil(),
    watchCreatePerfil(),
    watchListPerfil(),
    watchDeletePerfil(),
    watchListAllPerfil(),
    watchCancelPerfilCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
