import { takeLatest, put, call } from 'redux-saga/effects';

import { listOutrasSaidaAnimal } from '../service';
import {
  TABLE_OUTRAS_SAIDA_ANIMAL,
  TABLE_OUTRAS_SAIDA_ANIMAL_PENDING,
  TABLE_OUTRAS_SAIDA_ANIMAL_SUCCESS,
  TABLE_OUTRAS_SAIDA_ANIMAL_FAILURE
} from '../actionTypes';

function* sagaListOutrasSaidaAnimal(action) {
  yield put({ type: TABLE_OUTRAS_SAIDA_ANIMAL_PENDING })

  try {
    const table = yield call(listOutrasSaidaAnimal, action.args)
    yield put({ type: TABLE_OUTRAS_SAIDA_ANIMAL_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_OUTRAS_SAIDA_ANIMAL_FAILURE })
  }
}

export default function* watchListOutrasSaidaAnimal() {
  yield takeLatest(TABLE_OUTRAS_SAIDA_ANIMAL, sagaListOutrasSaidaAnimal)
}