
export const NEW_USUARIO_WHITE_LABEL = 'NEW_USUARIO_WHITE_LABEL'
export const NEW_USUARIO_WHITE_LABEL_SUCCESS = 'NEW_USUARIO_WHITE_LABEL_SUCCESS'

export const CREATE_USUARIO_WHITE_LABEL = 'CREATE_USUARIO_WHITE_LABEL'
export const CREATE_USUARIO_WHITE_LABEL_PENDING = 'CREATE_USUARIO_WHITE_LABEL_PENDING'
export const CREATE_USUARIO_WHITE_LABEL_SUCCESS = 'CREATE_USUARIO_WHITE_LABEL_SUCCESS'
export const CREATE_USUARIO_WHITE_LABEL_FAILURE = 'CREATE_USUARIO_WHITE_LABEL_FAILURE'

export const TABLE_USUARIO_WHITE_LABEL = 'TABLE_USUARIO_WHITE_LABEL'
export const TABLE_USUARIO_WHITE_LABEL_PENDING = 'TABLE_USUARIO_WHITE_LABEL_PENDING'
export const TABLE_USUARIO_WHITE_LABEL_SUCCESS = 'TABLE_USUARIO_WHITE_LABEL_SUCCESS'
export const TABLE_USUARIO_WHITE_LABEL_FAILURE = 'TABLE_USUARIO_WHITE_LABEL_FAILURE'

export const LIST_USUARIO_WHITE_LABEL = 'LIST_USUARIO_WHITE_LABEL'
export const LIST_USUARIO_WHITE_LABEL_PENDING = 'LIST_USUARIO_WHITE_LABEL_PENDING'
export const LIST_USUARIO_WHITE_LABEL_SUCCESS = 'LIST_USUARIO_WHITE_LABEL_SUCCESS'
export const LIST_USUARIO_WHITE_LABEL_FAILURE = 'LIST_USUARIO_WHITE_LABEL_FAILURE'

export const DELETE_USUARIO_WHITE_LABEL = 'DELETE_USUARIO_WHITE_LABEL'
export const DELETE_USUARIO_WHITE_LABEL_PENDING = 'DELETE_USUARIO_WHITE_LABEL_PENDING'
export const DELETE_USUARIO_WHITE_LABEL_SUCCESS = 'DELETE_USUARIO_WHITE_LABEL_SUCCESS'
export const DELETE_USUARIO_WHITE_LABEL_FAILURE = 'DELETE_USUARIO_WHITE_LABEL_FAILURE'

export const FIND_USUARIO_WHITE_LABEL = 'FIND_USUARIO_WHITE_LABEL'
export const FIND_USUARIO_WHITE_LABEL_PENDING = 'FIND_USUARIO_WHITE_LABEL_PENDING'
export const FIND_USUARIO_WHITE_LABEL_SUCCESS = 'FIND_USUARIO_WHITE_LABEL_SUCCESS'
export const FIND_USUARIO_WHITE_LABEL_FAILURE = 'FIND_USUARIO_WHITE_LABEL_FAILURE'

export const ACTIVE_INACTIVE_USUARIO_WHITE_LABEL = 'ACTIVE_INACTIVE_USUARIO_WHITE_LABEL'
export const ACTIVE_INACTIVE_USUARIO_WHITE_LABEL_PENDING = 'ACTIVE_INACTIVE_USUARIO_WHITE_LABEL_PENDING'
export const ACTIVE_INACTIVE_USUARIO_WHITE_LABEL_SUCCESS = 'ACTIVE_INACTIVE_USUARIO_WHITE_LABEL_SUCCESS'
export const ACTIVE_INACTIVE_USUARIO_WHITE_LABEL_FAILURE = 'ACTIVE_INACTIVE_USUARIO_WHITE_LABEL_FAILURE'
