export const TIPO_CLIENTE_AGRARE_ATIVIDADE_AGRICOLA_ENUM = [
    {
        id: 'CULTIVO_GRAOS',
        descricao: "Cultivo de Grãos"
    },
    // {
    //     id: 'CAFEICULTURA',
    //     descricao: "Cafeícultura"
    // },
    {
        id: 'CULTIVO_CANA',
        descricao: "Cultivo de Cana"
    },
    // {
    //     id: 'CITRICULTURA',
    //     descricao: "Citricultura"
    // },
    // {
    //     id: 'FRUTICULTURA',
    //     descricao: "Fruticultura"
    // },
    {
        id: 'HORTICULTURA',
        descricao: "Horticultura"
    },
    // {
    //     id: 'GRAOS_PECUARIA',
    //     descricao: "Grãos e Pecuária"
    // },
    // {
    //     id: 'PECUARIA',
    //     descricao: "Pecuária"
    // },
    {
        id: 'OUTRAS_CULTURAS',
        descricao: "Outras Culturas"
    },
];

export function getTipoAtividadeAgricolaDescricaoById(id) {
    const tipo = TIPO_CLIENTE_AGRARE_ATIVIDADE_AGRICOLA_ENUM.find(item => item.id === id);
    return tipo ? tipo.descricao : null;
}