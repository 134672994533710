import { takeLatest, put } from 'redux-saga/effects';

import {
  TOGGLE_HELP_DRAWER,
  TOGGLE_HELP_DRAWER_SUCCESS
} from '../actionTypes';

function* sagaHelpDrawerToggle(action) {
    yield put({ type: TOGGLE_HELP_DRAWER_SUCCESS });
}

export default function* watchHelpDrawerToggle() {
  yield takeLatest(TOGGLE_HELP_DRAWER, sagaHelpDrawerToggle);
}
