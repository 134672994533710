import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    NEW_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS,
    TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING,
    TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS,
    TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE,
    LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING,
    LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS,
    LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE,
    FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING,
    FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS,
    FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE,
    DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING,
    DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS,
    DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE,
    CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING,
    CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS,
    CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE,
    NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING,
    NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS,
    NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE,
    NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PEDING,
    NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS,
    NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE,
} from './actionTypes';


const initialState = {
    list: null,
    loading: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
}


function baixaManualEstoqueProducaoReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING:
        case FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING:
        case DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING:
        case CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING:
        case TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING:
        case NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING:
        case NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PEDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS:
        case CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { baixaManualEstoqueProducaoReducer };