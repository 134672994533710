import React from 'react';
import { Button, Collapse } from 'reactstrap';
import './styles.css';

const CollapseMenu = ({ data, isOpen, toggle }) => {
  function HtmlContentRenderer({ htmlString }) {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} style={{ marginBottom: '10px' }} />;
  }

  return (
    <div>
      <Button className={`collapse-header ${isOpen && "open"}`} onClick={toggle}>
        <h5>{data.title}</h5>
        {isOpen ? <i className="fa fa-minus-circle" /> : <i className="fa fa-plus-circle" />}
      </Button>

      <Collapse isOpen={isOpen}>
        <div className="collapse-body">
          <HtmlContentRenderer htmlString={data.description} />
          {data.video && (
            <iframe
              style={{
                width: '100%',
                height: '400px',
                border: 'none'
              }}
              className="embed-responsive-item"
              src={data.video.url}
              title={data.video.title}
              allowFullScreen
            />
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default CollapseMenu;
