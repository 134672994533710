import watchNewFilterRelatorioMovimentacaoAnimal from './saga/newFilter';
import watchListRelatorioMovimentacaoAnimal from './saga/list';

export const relatorioMovimentacaoAnimalSaga = [
    watchNewFilterRelatorioMovimentacaoAnimal(),
    watchListRelatorioMovimentacaoAnimal(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
