import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

class Breadcrumb extends React.Component {
    render() {
        const { startSessionReducer } = this.props;

        return (
            <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                    {startSessionReducer.configSession == null ?
                        <Link to="/">Página Inicial</Link> :
                        <Link to="/">{startSessionReducer.configSession.contaAgrare.accountName + " - " + startSessionReducer.configSession.fazendaSelecionada.nome}</Link>}
                </li>
                {this.props.children}
            </ol>
        );
    }
}

export function mapStateToProps(state) {
    const { startSessionReducer } = state.entities;
    return { startSessionReducer };
};

function mapDispatchToProps(dispatch) {
    return {}
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Breadcrumb))