import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllNaturezaOperacaoCadastro } from '../service';
import {
  LIST_NATUREZA_OPERACAO_CADASTRO,
  LIST_NATUREZA_OPERACAO_CADASTRO_PENDING,
  LIST_NATUREZA_OPERACAO_CADASTRO_SUCCESS,
  LIST_NATUREZA_OPERACAO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAllNaturezaOperacaoCadastro(action) {
  yield put({ type: LIST_NATUREZA_OPERACAO_CADASTRO_PENDING })

  try {
    const list = yield call(findAllNaturezaOperacaoCadastro)
    
    yield put({ type: LIST_NATUREZA_OPERACAO_CADASTRO_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_NATUREZA_OPERACAO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllNaturezaOperacaoCadastro() {
  yield takeLatest(LIST_NATUREZA_OPERACAO_CADASTRO, sagaListAllNaturezaOperacaoCadastro)
}