import { takeLatest, put, call } from 'redux-saga/effects';

import { history, storage } from '@commons';

import { createPlanejamento, createPlanejamentoMulti, updatePlanejamento } from '../service';
import {
  CONFIG_SESSION,
  CREATE_PLANEJAMENTO,
  CREATE_PLANEJAMENTO_PENDING,
  CREATE_PLANEJAMENTO_SUCCESS,
  CREATE_PLANEJAMENTO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

import { formatDateDDMMYYYY, formatJsonRemoveEmptyOrNull, isDateValidVerifyDateBetween } from '@components/common/format'

function* sagaCreatePlanejamento(action) {
  yield put({ type: CREATE_PLANEJAMENTO_PENDING })

  try {
    const safraAtiva = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva;
    var dateFrom = safraAtiva.dataInicial;
    var dateTo = safraAtiva.dataFinal;
    var dateCheck = action.data.data;

    if (!isDateValidVerifyDateBetween(dateFrom, dateTo, dateCheck)) {
      yield put({ type: CREATE_PLANEJAMENTO_FAILURE, registro: action.data })

      AlertWarning('Data informada fora do período da safra ativa. Período deve ser entre ' 
      + formatDateDDMMYYYY(dateFrom) + ' até ' + formatDateDDMMYYYY(dateTo) + '!');
    } else {
      if (action.data.replicarLancamento) {
        let procedimentosASalvar = []

        for (let index = 0; index < action.data.talhoesList.length; index++) {
          let procedimentoTalhao = {
            ...action.data,
            'forJson': 'AtivPlanejamento',
            'sync': false,
            propriedade: {
              id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
            }
          };
          procedimentoTalhao.talhao = { ...action.data.talhoesList[index] }

          if (procedimentoTalhao.talhao.talhao !== undefined && procedimentoTalhao.talhao.talhao !== null) {
            procedimentoTalhao.talhao = procedimentoTalhao.talhao.talhao;
          }

          procedimentoTalhao.aplicacoes = [...action.data.aplicacoes]

          let custoTotalProdutosInsumos = 0;
          for (let indexAplic = 0; indexAplic < procedimentoTalhao.aplicacoes.length; indexAplic++) {
            let areaTalhao = 0;
            let custoTotalHa = 0;
            let custoTotalTalhao = 0;
            let estoqueABaixar = 0;

            areaTalhao = procedimentoTalhao.talhao.area

            let formaLanc = '';
            if (!procedimentoTalhao.aplicacoes[indexAplic].configLancAgricultura) {
              if (safraAtiva.cultura.formaLancPlantio === 'POR_HA') {
                formaLanc = 'POR_HA';
              } else {
                formaLanc = 'POR_APLI';
              }
            } else {
              if (procedimentoTalhao.aplicacoes[indexAplic].configLancAgriculturaTipo === 'POR_HA') {
                formaLanc = 'POR_HA';
              } else {
                formaLanc = 'POR_APLI';
              }
            }

            if (formaLanc === 'POR_HA') {
              custoTotalHa = (procedimentoTalhao.aplicacoes[indexAplic].dosagemHa * procedimentoTalhao.aplicacoes[indexAplic].qtdConversao) * procedimentoTalhao.aplicacoes[indexAplic].custoProduto;
              custoTotalTalhao = procedimentoTalhao.aplicacoes[indexAplic].custoTotalHa * (areaTalhao ?? 0);
            } else {
              custoTotalTalhao = (procedimentoTalhao.aplicacoes[indexAplic].dosagemHa * procedimentoTalhao.aplicacoes[indexAplic].qtdConversao) * procedimentoTalhao.aplicacoes[indexAplic].custoProduto;
              custoTotalHa = procedimentoTalhao.aplicacoes[indexAplic].custoTotalTalhao / (areaTalhao ?? 0);
            }

            procedimentoTalhao.aplicacoes[indexAplic].estoqueBaixado = false;
            if (procedimentoTalhao.aplicacoes[indexAplic].movimentaEstoque) {
              if (formaLanc === 'POR_HA') {
                estoqueABaixar = procedimentoTalhao.aplicacoes[indexAplic].dosagemHa * (areaTalhao ?? 0);
              } else {
                estoqueABaixar = procedimentoTalhao.aplicacoes[indexAplic].dosagemHa;
              }
            } else {
              estoqueABaixar = 0;
            }

            procedimentoTalhao.aplicacoes[indexAplic] = {
              ...procedimentoTalhao.aplicacoes[indexAplic],
              areaTalhao: areaTalhao,
              custoTotalHa: custoTotalHa,
              custoTotalTalhao: custoTotalTalhao,
              estoqueABaixar: estoqueABaixar,
            }

            custoTotalProdutosInsumos += custoTotalTalhao;
          }
          procedimentoTalhao.custoTotalProdutosInsumos = custoTotalProdutosInsumos;

          procedimentosASalvar.push(procedimentoTalhao)
        }

        for (let index = 0; index < procedimentosASalvar.length; index++) {
          delete procedimentosASalvar[index].replicarLancamento;
          delete procedimentosASalvar[index].talhoesList;

          procedimentosASalvar[index].custoTotalTalhao = (procedimentosASalvar[index].custoTotalProdutosInsumos + procedimentosASalvar[index].custoTotalMaquinarios + procedimentosASalvar[index].custoTotalFuncionarios);
          procedimentosASalvar[index].custoTotalHa = (procedimentosASalvar[index].custoTotalTalhao / (procedimentosASalvar[index].talhao?.area ?? 0));
        }
        
        yield call(createPlanejamentoMulti, procedimentosASalvar);

        yield put({ type: CREATE_PLANEJAMENTO_SUCCESS })

        history.push('/agricultura/planejamento');

        AlertSuccess('Registro salvo com sucesso!');
      } else {
        var idAplicacao;
  
        if (action.data.talhao.talhao !== undefined && action.data.talhao.talhao !== null){
          action.data.talhao = action.data.talhao.talhao;
        }

        delete action.data.replicarLancamento;
        delete action.data.talhoesList;
        
        if (action.data.id === undefined || action.data.id === null) {
          var saved = yield call(createPlanejamento, formatJsonRemoveEmptyOrNull({
            ...action.data,
            'forJson': 'AtivPlanejamento',
            'sync': false,
            propriedade: {
              id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
            },
          }));
          idAplicacao = saved.data.id;
        } else {
          idAplicacao = action.data.id;
          yield call(updatePlanejamento,
            {
              ...action.data,
              id: action.data.id
            }
          );
        }
  
        yield put({ type: CREATE_PLANEJAMENTO_SUCCESS, registro: action.data })
  
        history.push('/agricultura/planejamento');
  
        AlertSuccess('Registro salvo com sucesso!');
      }
    }
  } catch (error) {
    yield put({ type: CREATE_PLANEJAMENTO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

export default function* watchCreatePlanejamento() {
  yield takeLatest(CREATE_PLANEJAMENTO, sagaCreatePlanejamento)
}
