import { path, httpService } from '@commons';

///////////////////////////////////////////////////////////////////////////////
const args = { ...path };
///////////////////////////////////////////////////////////////////////////////

export function getTotalNroItens() {
    return httpService.get({ ...args, path: '/patrimonio/totalNroItens' });
}

export function getTotalPatrimonios() {
    return httpService.get({ ...args, path: '/patrimonio/totalPatrimonios' });
}

export function getTotalHorasTrabalhadas(propriedade, inicio, fim, where) {
    return httpService.get({ ...args, path: '/patrimonio/totalHorasTrabalhadas/' + propriedade + '?inicio=' + inicio + '&fim=' + fim + where });
}

export function getTotalCustoDiretoPatrimonios(propriedade, inicio, fim, where) {
    return httpService.get({ ...args, path: '/custoDireto/totalCustoDiretoPatrimonios/' + propriedade + '?inicio=' + inicio + '&fim=' + fim + where });
}

export function getTotalDespesasPatrimonios(propriedade, inicio, fim, where) {
    return httpService.get({ ...args, path: '/contas/pagar/totalDespesasPatrimonios/' + propriedade + '?inicio=' + inicio + '&fim=' + fim + where });
}

export function getTotalDespesasEmAbertoPatrimonios(propriedade, inicio, fim, where) {
    return httpService.get({ ...args, path: '/contas/pagar/totalDespesasEmAbertoPatrimonios/' + propriedade + '?inicio=' + inicio + '&fim=' + fim + where });
}

export function graficoTotalDespesasPatrimoniosPorContaGerencial(propriedade, inicio, fim, where) {
    return httpService.get({ ...args, path: '/contas/pagar/graficoTotalDespesasPatrimoniosPorContaGerencial/' + propriedade + '?inicio=' + inicio + '&fim=' + fim + where });
}