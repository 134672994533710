import watchNewAnoPecuarioCadastro from './saga/new';
import watchFindAnoPecuarioCadastro from './saga/find';
import watchCreateAnoPecuarioCadastro from './saga/create';
import watchListAnoPecuarioCadastro from './saga/listTable';
import watchDeleteAnoPecuarioCadastro from './saga/delete';
import watchCancelAnoPecuarioCadastroRapido from './saga/cancel';

export const anoPecuarioCadastroSaga = [
    watchNewAnoPecuarioCadastro(),
    watchFindAnoPecuarioCadastro(),
    watchCreateAnoPecuarioCadastro(),
    watchListAnoPecuarioCadastro(),
    watchDeleteAnoPecuarioCadastro(),
    watchCancelAnoPecuarioCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
