import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    status: true,
    dataInicial: '',
    dataFinal: '',
    obs: '',
    campos: [],
    allCampos: false
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(100, 'Campo deve ter menos do que 100 caracteres')
        .required('Este campo é obrigatório'),
    dataInicial: Yup.date()
        .required('Este campo é obrigatório'),
    dataFinal: Yup.date()
        .required('Este campo é obrigatório'),
});

export const anoPecuarioCadastro = {
    newRegistro,
    validation
}