import {
    CHANGE_MAP_MODE_PENDING,
    CHANGE_MAP_MODE_SUCCESS,
    CHANGE_MAP_MODE_FAILURE,
} from './actionTypes';

const initialState = {
    mode: "STANDARD",
    loading: false
};

function mapReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_MAP_MODE_PENDING:
            return {
                ...state,
                loading: true,
            }
        case CHANGE_MAP_MODE_SUCCESS:
            return {
                ...state,
                mode: action.mode,
                loading: false
            }
        case CHANGE_MAP_MODE_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        default:
            return state
    }
}

export { mapReducer };
