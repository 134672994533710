import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/pecuaria/movimentacao/outrasSaida' };
/////////////////////////////////////////////////////////////////////////////////

export function createOutrasSaidaAnimal(payload) {
  return httpService.post(args, payload);
}

export function updateOutrasSaidaAnimal(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listOutrasSaidaAnimal(param) {
  return httpService.get({ ...args, args: param });
}

export function findOutrasSaidaAnimal(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllOutrasSaidaAnimal() {
  return httpService.get({ ...args, path: '/pecuaria/movimentacao/outrasSaida/all' });
}

export function deleteOutrasSaidaAnimal(id) {
  return httpService.del({ ...args, args: `/${id}` });
}