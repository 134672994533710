import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  NEW_FILTER_PLANEJAMENTO,
  NEW_FILTER_PLANEJAMENTO_PEDING,
  NEW_FILTER_PLANEJAMENTO_SUCCESS,
  NEW_FILTER_PLANEJAMENTO_FAILURE,
  GET_TOTAIS_PLANEJAMENTO_PENDING,
  GET_TOTAIS_PLANEJAMENTO_SUCCESS
} from '@handler';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';
import { getCustoTotalSafra, listPlanejamento } from '../service';
import { CONST_FILTER_PLANEJAMENTO, CONST_ROWS_PER_PAGE } from '@commons/consts';

function* sagaNewFilterPlanejamento(action) {
  try {
    yield put({ type: NEW_FILTER_PLANEJAMENTO_PEDING })

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    const idSafraAtiva = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva?.id ?? -1;

    if (idSafraAtiva !== -1) {
      let datas = '';
      let dataInicial = '';
      let dataFinal = '';
      var date = new Date();
      let where = '';
      let newFilter = {
        talhaoSelecionado: null,
        procedimento: null,
        dataInicial: '',
        dataFinal: '',
        rows: CONST_ROWS_PER_PAGE,
        page: 0,
        where: ''
      };

      let filterSaved = sessionStorage.getItem(CONST_FILTER_PLANEJAMENTO) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_PLANEJAMENTO)) : null;

      if (filterSaved === null || filterSaved === undefined) {
        // seta data da safra ou do mes na primeira vez que entra na pagina
        dataInicial = formatDateYYYYMMDD(JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva?.dataInicial ?? new Date(date.getFullYear(), date.getMonth(), 1)),
          dataFinal = formatDateYYYYMMDD(JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva?.dataFinal ?? new Date(date.getFullYear(), date.getMonth() + 1, 0)),

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);

        newFilter = {
          ...newFilter,
          dataInicial,
          dataFinal,
        };
      } else {
        // se seta filtro manual
        if (action.newFilter) {
          // seta filtro indicado
          // typePeriodo = 0 -> periodo manual
          // typePeriodo = 1 -> hoje
          // typePeriodo = 2 -> esta semana
          // typePeriodo = 3 -> mes passado
          // typePeriodo = 4 -> este mes
          // typePeriodo = 5 -> proximo mes
          // typePeriodo = 6 -> este ano
          if (action.typePeriodo === undefined || action.typePeriodo === 0) {
            dataInicial = action.args.dataInicial;
            dataFinal = action.args.dataFinal;

            datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
          } else if (action.typePeriodo === 1) {
            dataInicial = formatDateYYYYMMDD(new Date(date));
            dataFinal = formatDateYYYYMMDD(new Date(date));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          }
          else if (action.typePeriodo === 2) {
            const primeiro = date.getDate() - date.getDay();

            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          } else if (action.typePeriodo === 3) {
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          } else if (action.typePeriodo === 4) {
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          } else if (action.typePeriodo === 5) {
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          } else if (action.typePeriodo === 6) {
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          }

          if (action.args.talhaoSelecionado !== null && action.args.talhaoSelecionado !== undefined) {
            where = where + '&talhao=' + action.args.talhaoSelecionado.talhao.id;
          }
          if (action.args.procedimento !== null && action.args.procedimento !== undefined) {
            where = where + '&procedimento=' + action.args.procedimento.id;
          }
          if (action.args.despDireta !== null && action.args.despDireta !== undefined) {
            where = where + '&despesaDireta=' + action.args.despDireta.id;
          }

          newFilter = {
            ...newFilter,
            ...action.args,
            dataInicial,
            dataFinal,
            where: where
          };
        } else {
          // busca filtro salvo
          datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

          if (filterSaved.talhaoSelecionado !== null && filterSaved.talhaoSelecionado !== undefined) {
            where = where + '&talhao=' + filterSaved.talhaoSelecionado.talhao.id;
          }
          if (filterSaved.procedimento !== null && filterSaved.procedimento !== undefined) {
            where = where + '&procedimento=' + filterSaved.procedimento.id;
          }

          newFilter = {
            ...filterSaved,
            rows: CONST_ROWS_PER_PAGE,
            page: 0,
            where: where
          };
        }
      }

      // busca registros
      let list = yield call(listPlanejamento, idFazenda, idSafraAtiva, datas, `&page=0&size=${CONST_ROWS_PER_PAGE}${where}`);

      sessionStorage.setItem(CONST_FILTER_PLANEJAMENTO, JSON.stringify(newFilter));

      yield put({
        type: NEW_FILTER_PLANEJAMENTO_SUCCESS, list: list, filter: newFilter
      });

      // busca totais
      yield put({ type: GET_TOTAIS_PLANEJAMENTO_PENDING })

      const totais = yield call(getCustoTotalSafra, idFazenda, idSafraAtiva, datas, where);

      yield put({
        type: GET_TOTAIS_PLANEJAMENTO_SUCCESS, totais: totais
      });
    } else {
      yield put({ type: NEW_FILTER_PLANEJAMENTO_FAILURE })
    }
  } catch (e) {
    yield put({ type: NEW_FILTER_PLANEJAMENTO_FAILURE })
    // yield put({ type: GET_TOTAIS_PLANEJAMENTO_FAILURE })
  }
}

export default function* watchNewFilterPlanejamento() {
  yield takeLatest(NEW_FILTER_PLANEJAMENTO, sagaNewFilterPlanejamento)
}
