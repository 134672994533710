
export const path = {
    // PRODUCAO AZURE
    // base:'https://api.sbsonfarm.com:8443/agrare-ws-central-2.0',
    // baseManager:'https://api.sbsonfarm.com:8443/agrare-ws-manager-1.0',
    // PRODUCAO AZURE
    
    // SERVER HOMOLOGACAO
    base:'https://agrare.com.br/agrare-ws-central-2.0',
    baseManager:'https://agrare.com.br/agrare-ws-manager-1.0',
    // SERVER HOMOLOGACAO

    // DEV LOCAL
    // base:'http://localhost:8080/agrare-ws-central-2.0',
    // baseManager:'http://localhost:8080/agrare-ws-manager-1.0',
    // DEV LOCAL
    
    token: 'agrare-security-token',
    sessionUser: 'agrare-security-user',
}