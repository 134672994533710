import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllOutrasSaidaAnimal } from '../service';
import {
  LIST_OUTRAS_SAIDA_ANIMAL,
  LIST_OUTRAS_SAIDA_ANIMAL_PENDING,
  LIST_OUTRAS_SAIDA_ANIMAL_SUCCESS,
  LIST_OUTRAS_SAIDA_ANIMAL_FAILURE
} from '../actionTypes';

function* sagaListAllOutrasSaidaAnimal(action) {
  yield put({ type: LIST_OUTRAS_SAIDA_ANIMAL_PENDING })

  try {
    const list = yield call(findAllOutrasSaidaAnimal)
    
    yield put({ type: LIST_OUTRAS_SAIDA_ANIMAL_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_OUTRAS_SAIDA_ANIMAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllOutrasSaidaAnimal() {
  yield takeLatest(LIST_OUTRAS_SAIDA_ANIMAL, sagaListAllOutrasSaidaAnimal)
}