import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import { getTotais } from '../service';
import { getGraficoCustoProducaoPorTalhao } from '../service';
import { getGraficoCustoTipoInsumo } from '../service';
import { getTotalAreaAtivaNaSafra } from '../../safraCadastro/service';
import {
    getListCustoProcedimentos,
    getListCustoDespesaDireta,
    getListCustoInsumos,
    getListCustoMaquinarios,
    getListCustoFuncionarios,
} from '../service';
import {
    CONFIG_SESSION,
    TOTAIS_DASHBOARD,
    TOTAIS_DASHBOARD_PENDING,
    TOTAIS_DASHBOARD_SUCCESS,
    TOTAIS_DASHBOARD_FAILURE,
    GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_PENDING,
    GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_SUCCESS,
    GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_FAILURE,
    GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_PENDING,
    GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_SUCCESS,
    GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_FAILURE,
    CUSTO_LIST_TABLE_DASHBOARD_PENDING,
    CUSTO_LIST_TABLE_DASHBOARD_SUCCESS,
    CUSTO_LIST_TABLE_DASHBOARD_FAILURE
} from '@handler';
import { formatDateDDMMYYYY } from '@components/common/format';

function* sagaTotais(action) {
    // GET TOTAIS

    yield put({ type: TOTAIS_DASHBOARD_PENDING })

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    const idSafra = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id;

    try {
        const totalAreaAtivaNaSafra = yield call(getTotalAreaAtivaNaSafra, idSafra);

        const totais = yield call(getTotais,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        const datas = formatDateDDMMYYYY(action.dataInicial) + '_' + formatDateDDMMYYYY(action.dataFinal);

        yield put({
            type: TOTAIS_DASHBOARD_SUCCESS, totais: {
                ...totais,
                totalAreaAtivaNaSafra
            }
        })

    } catch (error) {
        yield put({ type: TOTAIS_DASHBOARD_FAILURE })

    }



    // GET GRAFICO TALHAO

    yield put({ type: GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_PENDING })

    try {
        const dadosGraficoCustoProducaoPorTalhao = yield call(getGraficoCustoProducaoPorTalhao,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        let chartData = [['', 'Custo']];
        for (let i = 0; i < dadosGraficoCustoProducaoPorTalhao.length; i += 1) {
            chartData.push([dadosGraficoCustoProducaoPorTalhao[i].nomeTalhao, dadosGraficoCustoProducaoPorTalhao[i].custo])
        }

        yield put({ type: GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_SUCCESS, dadosGraficoCustoProducaoPorTalhao: chartData })

    } catch (error) {
        yield put({ type: GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_FAILURE })

    }



    // GET GRAFICO INSUMO

    yield put({ type: GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_PENDING })

    try {
        const dadosGraficoCustoTipoInsumo = yield call(getGraficoCustoTipoInsumo,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        let chartData = [['Grupo', 'Total']];
        for (let i = 0; i < dadosGraficoCustoTipoInsumo.length; i += 1) {
            chartData.push([dadosGraficoCustoTipoInsumo[i].text, dadosGraficoCustoTipoInsumo[i].value])
        }

        yield put({ type: GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_SUCCESS, dadosGraficoCustoTipoInsumo: chartData })

    } catch (error) {
        yield put({ type: GRAFICO_CUSTO_TIPO_INSUMO_DASHBOARD_FAILURE })

    }



    // GET LIST CUSTOS

    yield put({ type: CUSTO_LIST_TABLE_DASHBOARD_PENDING })

    try {
        const dadosListCustosProcedimentos = yield call(getListCustoProcedimentos,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        const dadosListCustosDespesasDireta = yield call(getListCustoDespesaDireta,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        const dadosListCustosInsumos = yield call(getListCustoInsumos,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        const dadosListCustosMaquinarios = yield call(getListCustoMaquinarios,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        const dadosListCustosFuncionarios = yield call(getListCustoFuncionarios,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        yield put({
            type: CUSTO_LIST_TABLE_DASHBOARD_SUCCESS, dataList: {
                dadosListCustosProcedimentos,
                dadosListCustosDespesasDireta,
                dadosListCustosInsumos,
                dadosListCustosMaquinarios,
                dadosListCustosFuncionarios,
            }
        })

    } catch (error) {
        yield put({ type: CUSTO_LIST_TABLE_DASHBOARD_FAILURE })

    }

}

export default function* watchTotais() {
    yield takeLatest(TOTAIS_DASHBOARD, sagaTotais)
}
