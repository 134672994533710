import watchNewFilterMonitoramentoNFeCompra from './saga/newFilter';
import watchNewFilterNextPageMonitoramentoNFeCompra from './saga/newFilterNextPage';
import watchImportarNotaEntrada from './saga/importarNotaEntrada';
import watchVisualizarNotaEntrada from './saga/visualizarNotaEntrada';

export const monitoramentoNFeCompraSaga = [
    watchNewFilterMonitoramentoNFeCompra(),
    watchNewFilterNextPageMonitoramentoNFeCompra(),
    watchImportarNotaEntrada(),
    watchVisualizarNotaEntrada(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
