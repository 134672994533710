import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_PARTNER_WHITE_LABEL_RAPIDO,
    CANCEL_PARTNER_WHITE_LABEL_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelPartnerWhiteLabelRapido() {
  yield put({ type: CANCEL_PARTNER_WHITE_LABEL_RAPIDO_SUCCESS})
}

export default function* watchCancelPartnerWhiteLabelRapido() {
  yield takeLatest(CANCEL_PARTNER_WHITE_LABEL_RAPIDO, sagaCancelPartnerWhiteLabelRapido)
}