


import watchNewOutrasSaidaAnimal from './saga/new';
import watchFindOutrasSaidaAnimal from './saga/find';
import watchCreateOutrasSaidaAnimal from './saga/create';
import watchListAllOutrasSaidaAnimal from './saga/listAll';
import watchListOutrasSaidaAnimal from './saga/listTable';
import watchDeleteOutrasSaidaAnimal from './saga/delete';

export const outrasSaidaAnimalSaga = [
    watchNewOutrasSaidaAnimal(),
    watchFindOutrasSaidaAnimal(),
    watchCreateOutrasSaidaAnimal(),
    watchListAllOutrasSaidaAnimal(),
    watchListOutrasSaidaAnimal(),
    watchDeleteOutrasSaidaAnimal(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
