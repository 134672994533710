import axios from 'axios';

import { httpAuthService } from './http.auth.service';
import { storage } from '@commons/storage';

import { path as pathCommons } from '@commons';
import { CONFIG_SESSION } from '@handler';

export const httpService = {
    post,
    postFile,
    put,
    get,
    getPdf,
    getFileExcel,
    del,
    getApiManager,
    postApiManager,
    putApiManager,
    delApiManager
};  

async function post(path, body) {
    return await req({ method: 'post', path: path, data: body });
}

async function postFile(path, body) {
    return await reqFile({ method: 'post', path: path, data: body });
}

async function put(path, body) {
    const response = await req({ method: 'put', path: path, data: body });
    return await response.data;
}

// return body only ok
async function get(path) {
    const response = await req({ method: 'get', path: path });
    return await response.data;
}

async function getFileExcel(path) {
    await reqExcel({ path: path });
}

async function getApiManager(paramsUrl, useAccessToken) {
    var headers = {};
    if (useAccessToken !== undefined && useAccessToken !== null && useAccessToken === true) {
        headers = {
            'Authorization': 'Bearer ' + await storage.get(pathCommons.token)
        };
    }

    var response = await axios({
        url: paramsUrl,
        method: 'GET',
        baseURL: pathCommons.baseManager,
        timeout: 30000,
        headers: headers,
    });
    return response.data;
}

async function postApiManager(paramsUrl, payload) {
    var headers = {
        'Authorization': 'Bearer ' + await storage.get(pathCommons.token)
    };

    var response = await axios({
        url: paramsUrl,
        method: 'POST',
        baseURL: pathCommons.baseManager,
        timeout: 30000,
        headers: headers,
        data: payload
    });
    return response.data;
}

async function delApiManager(paramsUrl) {
    var headers = {
        'Authorization': 'Bearer ' + await storage.get(pathCommons.token)
    };

    var response = await axios({
        url: paramsUrl,
        method: 'delete',
        baseURL: pathCommons.baseManager,
        timeout: 30000,
        headers: headers
    });
    return response.data;
}

async function putApiManager(paramsUrl, payload) {
    var headers = {
        'Authorization': 'Bearer ' + await storage.get(pathCommons.token)
    };

    var response = await axios({
        url: paramsUrl,
        method: 'PUT',
        baseURL: pathCommons.baseManager,
        timeout: 30000,
        headers: headers,
        data: payload
    });
    return response.data;
}

// get pdf
async function getPdf(path) {
    await reqPdf({ path: path });
}

async function del(path) {
    return await req({ method: 'delete', path: path });
}

/////////////////////////////////////////////////////////////////////////////////
async function req({ method, path, data }) {
    await httpAuthService.tokenByRefresh(path);

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;

    let url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    var response = await axios({
        method: method,
        url: url,
        data: data
    });
    return response;
}

// req file
async function reqFile({ method, path, data }) {
    await httpAuthService.tokenByRefresh(path);

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;

    let url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    var response = await axios({
        method: method,
        url: url,
        headers: { "Content-Type": `multipart/form-data; boundary=${data._boundary}` },
        data: data
    });
    return response;
}

// abrir pdf
async function reqPdf({ path }) {
    await httpAuthService.tokenByRefresh(path);

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;

    let url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const report = new Blob([response.data],
            { type: 'application/pdf;base64' })
        const reportUrl = window.URL.createObjectURL(report)
        window.open(reportUrl, "_blank")
    });
}

//abrir excel
async function reqExcel({ path }) {
    await httpAuthService.tokenByRefresh(path);

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;

    let url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', 'planilha.xlsx'); 
        link.click();
        window.URL.revokeObjectURL(blobUrl);
    });
}
/////////////////////////////////////////////////////////////////////////////////