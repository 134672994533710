import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllImplantacaoEstoque } from '../service';
import {
  LIST_IMPLANTACAO_ESTOQUE,
  LIST_IMPLANTACAO_ESTOQUE_PENDING,
  LIST_IMPLANTACAO_ESTOQUE_SUCCESS,
  LIST_IMPLANTACAO_ESTOQUE_FAILURE
} from '../actionTypes';

function* sagaListAllImplantacaoEstoque(action) {
  yield put({ type: LIST_IMPLANTACAO_ESTOQUE_PENDING })

  try {
    const list = yield call(findAllImplantacaoEstoque)
    
    yield put({ type: LIST_IMPLANTACAO_ESTOQUE_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_IMPLANTACAO_ESTOQUE_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllImplantacaoEstoque() {
  yield takeLatest(LIST_IMPLANTACAO_ESTOQUE, sagaListAllImplantacaoEstoque)
}