import watchNewAnimalLoteCadastro from './saga/new';
import watchFindAnimalLoteCadastro from './saga/find';
import watchCreateAnimalLoteCadastro from './saga/create';
import watchListAnimalLoteCadastro from './saga/listTable';
import watchDeleteAnimalLoteCadastro from './saga/delete';
import watchCancelAnimalLoteCadastroRapido from './saga/cancel';

export const animalLoteCadastroSaga = [
    watchNewAnimalLoteCadastro(),
    watchFindAnimalLoteCadastro(),
    watchCreateAnimalLoteCadastro(),
    watchListAnimalLoteCadastro(),
    watchDeleteAnimalLoteCadastro(),
    watchCancelAnimalLoteCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
