
export const NEW_LOCAL_ESTOQUE_CADASTRO = 'NEW_LOCAL_ESTOQUE_CADASTRO'
export const NEW_LOCAL_ESTOQUE_CADASTRO_SUCCESS = 'NEW_LOCAL_ESTOQUE_CADASTRO_SUCCESS'

export const CREATE_LOCAL_ESTOQUE_CADASTRO = 'CREATE_LOCAL_ESTOQUE_CADASTRO'
export const CREATE_LOCAL_ESTOQUE_CADASTRO_PENDING = 'CREATE_LOCAL_ESTOQUE_CADASTRO_PENDING'
export const CREATE_LOCAL_ESTOQUE_CADASTRO_SUCCESS = 'CREATE_LOCAL_ESTOQUE_CADASTRO_SUCCESS'
export const CREATE_LOCAL_ESTOQUE_CADASTRO_FAILURE = 'CREATE_LOCAL_ESTOQUE_CADASTRO_FAILURE'

export const CANCEL_LOCAL_ESTOQUE_CADASTRO_RAPIDO = 'CANCEL_LOCAL_ESTOQUE_CADASTRO_RAPIDO'
export const CANCEL_LOCAL_ESTOQUE_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_LOCAL_ESTOQUE_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_LOCAL_ESTOQUE_CADASTRO = 'TABLE_LOCAL_ESTOQUE_CADASTRO'
export const TABLE_LOCAL_ESTOQUE_CADASTRO_PENDING = 'TABLE_LOCAL_ESTOQUE_CADASTRO_PENDING'
export const TABLE_LOCAL_ESTOQUE_CADASTRO_SUCCESS = 'TABLE_LOCAL_ESTOQUE_CADASTRO_SUCCESS'
export const TABLE_LOCAL_ESTOQUE_CADASTRO_FAILURE = 'TABLE_LOCAL_ESTOQUE_CADASTRO_FAILURE'

export const LIST_LOCAL_ESTOQUE_CADASTRO = 'LIST_LOCAL_ESTOQUE_CADASTRO'
export const LIST_LOCAL_ESTOQUE_CADASTRO_PENDING = 'LIST_LOCAL_ESTOQUE_CADASTRO_PENDING'
export const LIST_LOCAL_ESTOQUE_CADASTRO_SUCCESS = 'LIST_LOCAL_ESTOQUE_CADASTRO_SUCCESS'
export const LIST_LOCAL_ESTOQUE_CADASTRO_FAILURE = 'LIST_LOCAL_ESTOQUE_CADASTRO_FAILURE'

export const FIND_LOCAL_ESTOQUE_CADASTRO = 'FIND_LOCAL_ESTOQUE_CADASTRO'
export const FIND_LOCAL_ESTOQUE_CADASTRO_PENDING = 'FIND_LOCAL_ESTOQUE_CADASTRO_PENDING'
export const FIND_LOCAL_ESTOQUE_CADASTRO_SUCCESS = 'FIND_LOCAL_ESTOQUE_CADASTRO_SUCCESS'
export const FIND_LOCAL_ESTOQUE_CADASTRO_FAILURE = 'FIND_LOCAL_ESTOQUE_CADASTRO_FAILURE'

export const DELETE_LOCAL_ESTOQUE_CADASTRO = 'DELETE_LOCAL_ESTOQUE_CADASTRO'
export const DELETE_LOCAL_ESTOQUE_CADASTRO_PENDING = 'DELETE_LOCAL_ESTOQUE_CADASTRO_PENDING'
export const DELETE_LOCAL_ESTOQUE_CADASTRO_SUCCESS = 'DELETE_LOCAL_ESTOQUE_CADASTRO_SUCCESS'
export const DELETE_LOCAL_ESTOQUE_CADASTRO_FAILURE = 'DELETE_LOCAL_ESTOQUE_CADASTRO_FAILURE'
