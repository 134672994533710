import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    NEW_PLANEJAMENTO_SUCCESS,
    TABLE_PLANEJAMENTO_PENDING,
    TABLE_PLANEJAMENTO_SUCCESS,
    TABLE_PLANEJAMENTO_FAILURE,
    LIST_PLANEJAMENTO_PENDING,
    LIST_PLANEJAMENTO_SUCCESS,
    LIST_PLANEJAMENTO_FAILURE,
    FIND_PLANEJAMENTO_PENDING,
    FIND_PLANEJAMENTO_SUCCESS,
    FIND_PLANEJAMENTO_FAILURE,
    DELETE_PLANEJAMENTO_PENDING,
    DELETE_PLANEJAMENTO_SUCCESS,
    DELETE_PLANEJAMENTO_FAILURE,
    CREATE_PLANEJAMENTO_PENDING,
    CREATE_PLANEJAMENTO_SUCCESS,
    CREATE_PLANEJAMENTO_FAILURE,
    NEW_FILTER_NEXT_PAGE_PLANEJAMENTO_PENDING,
    NEW_FILTER_NEXT_PAGE_PLANEJAMENTO_SUCCESS,
    NEW_FILTER_NEXT_PAGE_PLANEJAMENTO_FAILURE,
    NEW_FILTER_PLANEJAMENTO_PEDING,
    NEW_FILTER_PLANEJAMENTO_SUCCESS,
    NEW_FILTER_PLANEJAMENTO_FAILURE,
    GET_TOTAIS_PLANEJAMENTO_PENDING,
    GET_TOTAIS_PLANEJAMENTO_SUCCESS,
    GET_TOTAIS_PLANEJAMENTO_FAILURE,
    GET_LOADING_PLANEJAMENTO_SUCCESS,
    GET_LOADING_PLANEJAMENTO_PENDING
} from './actionTypes';


const initialState = {
    list: null,
    loading: false,
    loadingTotais: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
    totais: {
        custoTotal: 0,
        custoTotalHa: 0
    }
}


function planejamentoReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_PLANEJAMENTO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                plantiosRealizados: null,
            }
        case LIST_PLANEJAMENTO_PENDING:
        case FIND_PLANEJAMENTO_PENDING:
        case DELETE_PLANEJAMENTO_PENDING:
        case CREATE_PLANEJAMENTO_PENDING:
        case TABLE_PLANEJAMENTO_PENDING:
        case NEW_FILTER_NEXT_PAGE_PLANEJAMENTO_PENDING:
        case NEW_FILTER_PLANEJAMENTO_PEDING:
        case GET_LOADING_PLANEJAMENTO_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_LOADING_PLANEJAMENTO_SUCCESS:
            return {
                ...state,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_PLANEJAMENTO_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_PLANEJAMENTO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_PLANEJAMENTO_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_PLANEJAMENTO_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_PLANEJAMENTO_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                plantiosRealizados: action.plantiosRealizados,
                loading: false
            }
        case FIND_PLANEJAMENTO_FAILURE:
            return {
                ...state,
                registro: null,
                plantiosRealizados: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_PLANEJAMENTO_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_PLANEJAMENTO_SUCCESS:
        case CREATE_PLANEJAMENTO_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case DELETE_PLANEJAMENTO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_PLANEJAMENTO_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_PLANEJAMENTO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_PLANEJAMENTO_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_PLANEJAMENTO_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_TOTAIS_PLANEJAMENTO_PENDING:
            return {
                ...state,
                loadingTotais: true,
            }
        case GET_TOTAIS_PLANEJAMENTO_SUCCESS:
            return {
                ...state,
                totais: action.totais,
                loadingTotais: false
            }
        case GET_TOTAIS_PLANEJAMENTO_FAILURE:
            return {
                ...state,
                totais: {
                    custoTotal: 0,
                    custoTotalHa: 0
                },
                loadingTotais: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { planejamentoReducer };