
export const NEW_BAIXAR_MANUAL_ESTOQUE_PRODUCAO = 'NEW_BAIXAR_MANUAL_ESTOQUE_PRODUCAO'
export const NEW_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS = 'NEW_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS'

export const CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO = 'CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO'
export const CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING = 'CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING'
export const CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS = 'CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS'
export const CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE = 'CREATE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE'

export const TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO = 'TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO'
export const TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING = 'TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING'
export const TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS = 'TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS'
export const TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE = 'TABLE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE'

export const LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO = 'LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO'
export const LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING = 'LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING'
export const LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS = 'LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS'
export const LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE = 'LIST_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE'

export const FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO = 'FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO'
export const FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING = 'FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING'
export const FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS = 'FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS'
export const FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE = 'FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE'

export const DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO = 'DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO'
export const DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING = 'DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING'
export const DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS = 'DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS'
export const DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE = 'DELETE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE'

export const NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO = 'NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO'
export const NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PEDING = 'NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PEDING'
export const NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS = 'NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS'
export const NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE = 'NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE'

export const NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO = 'NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO'
export const NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING = 'NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING'
export const NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS = 'NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE = 'NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE'
