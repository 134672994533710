import { takeLatest, put, call } from 'redux-saga/effects';

import { history, storage } from '@commons';

import { createColheita, updateColheita } from '../service';
import {
  CONFIG_SESSION,
  CREATE_COLHEITA,
  CREATE_COLHEITA_PENDING,
  CREATE_COLHEITA_SUCCESS,
  CREATE_COLHEITA_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

import { formatDateDDMMYYYY, isDateValidVerifyDateBetween } from '@components/common/format'

function* sagaCreateColheita(action) {
  yield put({ type: CREATE_COLHEITA_PENDING })

  try {
    const safraAtiva = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva;
    var dateFrom = safraAtiva.dataInicial;
    var dateTo = safraAtiva.dataFinal;
    var dateCheck = action.data.data;

    if (!isDateValidVerifyDateBetween(dateFrom, dateTo, dateCheck)) {
      yield put({ type: CREATE_COLHEITA_FAILURE, registro: action.data })

      AlertWarning('Data informada fora do período da safra ativa. Período deve ser entre ' 
      + formatDateDDMMYYYY(dateFrom) + ' até ' + formatDateDDMMYYYY(dateTo) + '!');
    } else {

      if (action.data.talhao.talhao !== undefined && action.data.talhao.talhao !== null) {
        action.data.talhao = action.data.talhao.talhao;
      }
      if (action.data.impureza === undefined || action.data.impureza === null) {
        action.data.impureza = 0;
      }
      if (action.data.umidade === undefined || action.data.umidade === null) {
        action.data.umidade = 0;
      }

      if (action.data.id === undefined || action.data.id === null) {
        yield call(createColheita, {
          ...action.data,
          'sync': false,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          },
          safra: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id
          }
        });
      } else {
        yield call(updateColheita,
          {
            ...action.data,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_COLHEITA_SUCCESS, registro: action.data })

      history.push('/agricultura/colheita');

      AlertSuccess('Registro salvo com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_COLHEITA_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

export default function* watchCreateColheita() {
  yield takeLatest(CREATE_COLHEITA, sagaCreateColheita)
}
