import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { NEW_COLHEITA, TABLE_COLHEITA } from "@handler";
import { Button, Card, CardBody, CardHeader, ListGroup, ListGroupItem } from "reactstrap";
import { formatNumber, formatDateDDMMYYYY } from '@components/common/format';
import IconTextInfoNoRegisterComponent from "@components/common/IconTextInfoNoRegisterComponent";

function HarvestTab({
    area,
}) {
    const dispatch = useDispatch();

    const startSessionReducer = useSelector((state) => state.entities.startSessionReducer);
    const { fazendaSelecionada, safraAtiva } = startSessionReducer.configSession;
    const colheitaReducer = useSelector((state) => state.entities.colheitaReducer);

    useEffect(() => {
        dispatch({
            type: TABLE_COLHEITA,
            args: `?propriedade=${fazendaSelecionada.id}&safra=${safraAtiva.id}&status=0&talhao=${area.id}`
        });
    }, [area]);

    const renderItem = (leftText, rightText) => {
        return (
            <div className="harvest-card-total">
                <span>{leftText}</span>
                <span>{rightText}</span>
            </div>
        );
    }

    function renderProducao(value) {
        if ((value.culturaFormaLancColheita !== undefined && value.culturaFormaLancColheita !== null && value.culturaFormaLancColheita === 'SACAS') ||
            (value.culturaFormaLancColheita !== undefined && value.culturaFormaLancColheita !== null && value.culturaFormaLancColheita === 'KG' && value.culturaUnidadeColheitaPossuiConversao === true))
            return <React.Fragment>{formatNumber(value.producaoConvertida, 2)} <br /> <span style={{ fontStyle: 'italic', fontSize: '12px' }}>{`${value.culturaTipoEmbalagem.sigla} de ${formatNumber(value.culturaQtdEmbalagem, 2)} ${value.culturaUnidadeEmbalagem.sigla}`}</span></React.Fragment>

        return formatNumber(value.producaoConvertida, 2) + ' ' + value.culturaUnidadeColheita.sigla;
    };

    function renderMediaColhida(value) {
        if ((value.culturaFormaLancColheita !== undefined && value.culturaFormaLancColheita !== null && value.culturaFormaLancColheita === 'SACAS') ||
            (value.culturaFormaLancColheita !== undefined && value.culturaFormaLancColheita !== null && value.culturaFormaLancColheita === 'KG' && value.culturaUnidadeColheitaPossuiConversao === true))
            return <React.Fragment>{formatNumber(value.mediaColhida, 2)} <br /> <span style={{ fontStyle: 'italic', fontSize: '12px' }}>{`${value.culturaTipoEmbalagem.sigla} de ${formatNumber(value.culturaQtdEmbalagem, 2)} ${value.culturaUnidadeEmbalagem.sigla} ${(startSessionReducer.configSession.fazendaSelecionada.unidadeArea === undefined || startSessionReducer.configSession.fazendaSelecionada.unidadeArea === null ? '/ ha' : '/ ' + startSessionReducer.configSession.fazendaSelecionada.unidadeArea.sigla)}`}</span></React.Fragment>

        return formatNumber(value.mediaColhida, 2) + ' ' + value.culturaUnidadeColheita.sigla + (startSessionReducer.configSession.fazendaSelecionada.unidadeArea === undefined || startSessionReducer.configSession.fazendaSelecionada.unidadeArea === null ? '/ ha' : '/ ' + startSessionReducer.configSession.fazendaSelecionada.unidadeArea.sigla);
    };

    const renderCard = (item) => {
        return (
            <div className="card-map">
                <Card key={`activity_${item.id}`}>
                    <CardHeader />
                    <CardBody>
                        <ListGroup flush>
                            <ListGroupItem id="activity-group-item">
                                <h5>{item.cultura.nome}</h5>
                                <div>Data: {formatDateDDMMYYYY(item.data)}</div>
                                <div>Talhão: {item.talhao.nome}</div>
                                {/* <div>Cultura: {item.cultura.nome}</div> */}
                                <div>Local: {item.localEstoque.nome}</div>
                                <div>Romaneio: {item.romaneio}</div>
                                <div>NFe: {item.nroNfe}</div>
                            </ListGroupItem>
                            <ListGroupItem id="activity-group-item">
                                {renderItem(
                                    "Produção",
                                    renderProducao(item)
                                )}
                                <div style={{marginTop: '4px'}}>
                                {renderItem(
                                    "Produtividade",
                                    renderMediaColhida(item)
                                )}
                                </div>
                            </ListGroupItem>
                        </ListGroup>
                    </CardBody>
                </Card>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Link to='/agricultura/colheita/novo' title='Novo'>
                <Button
                    onClick={() => {
                        dispatch({ type: NEW_COLHEITA, talhao: area });
                    }}
                    style={{ width: '100%' }}
                >
                    Nova Colheita
                </Button>
            </Link>

            <div className="mt-2">
                {(colheitaReducer && colheitaReducer.table?.content?.length > 0) ?
                    colheitaReducer.table.content.map((item) => (
                        renderCard(item)
                    )) : <IconTextInfoNoRegisterComponent title='Nenhuma colheita encontrada' />
                }
            </div>
        </React.Fragment>
    );
}

export default React.memo(HarvestTab);
