


import watchNewControleContaCaixa from './saga/new';
import watchNewFilterControleContaCaixa from './saga/newFilter';
import watchNewFilterNextPageControleContaCaixa from './saga/newFilterNextPage';
import watchFindControleContaCaixa from './saga/find';
import watchCreateControleContaCaixa from './saga/create';
import watchDeleteControleContaCaixa from './saga/delete';

export const controleContaCaixaSaga = [
    watchNewControleContaCaixa(),
    watchNewFilterControleContaCaixa(),
    watchNewFilterNextPageControleContaCaixa(),
    watchFindControleContaCaixa(),
    watchCreateControleContaCaixa(),
    watchDeleteControleContaCaixa(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
