export const NEW_VENDA_ANIMAL = 'NEW_VENDA_ANIMAL'
export const NEW_VENDA_ANIMAL_SUCCESS = 'NEW_VENDA_ANIMAL_SUCCESS'

export const CREATE_VENDA_ANIMAL = 'CREATE_VENDA_ANIMAL'
export const CREATE_VENDA_ANIMAL_PENDING = 'CREATE_VENDA_ANIMAL_PENDING'
export const CREATE_VENDA_ANIMAL_SUCCESS = 'CREATE_VENDA_ANIMAL_SUCCESS'
export const CREATE_VENDA_ANIMAL_FAILURE = 'CREATE_VENDA_ANIMAL_FAILURE'

export const TABLE_VENDA_ANIMAL = 'TABLE_VENDA_ANIMAL'
export const TABLE_VENDA_ANIMAL_PENDING = 'TABLE_VENDA_ANIMAL_PENDING'
export const TABLE_VENDA_ANIMAL_SUCCESS = 'TABLE_VENDA_ANIMAL_SUCCESS'
export const TABLE_VENDA_ANIMAL_FAILURE = 'TABLE_VENDA_ANIMAL_FAILURE'

export const LIST_VENDA_ANIMAL = 'LIST_VENDA_ANIMAL'
export const LIST_VENDA_ANIMAL_PENDING = 'LIST_VENDA_ANIMAL_PENDING'
export const LIST_VENDA_ANIMAL_SUCCESS = 'LIST_VENDA_ANIMAL_SUCCESS'
export const LIST_VENDA_ANIMAL_FAILURE = 'LIST_VENDA_ANIMAL_FAILURE'

export const FIND_VENDA_ANIMAL = 'FIND_VENDA_ANIMAL'
export const FIND_VENDA_ANIMAL_PENDING = 'FIND_VENDA_ANIMAL_PENDING'
export const FIND_VENDA_ANIMAL_SUCCESS = 'FIND_VENDA_ANIMAL_SUCCESS'
export const FIND_VENDA_ANIMAL_FAILURE = 'FIND_VENDA_ANIMAL_FAILURE'

export const DELETE_VENDA_ANIMAL = 'DELETE_VENDA_ANIMAL'
export const DELETE_VENDA_ANIMAL_PENDING = 'DELETE_VENDA_ANIMAL_PENDING'
export const DELETE_VENDA_ANIMAL_SUCCESS = 'DELETE_VENDA_ANIMAL_SUCCESS'
export const DELETE_VENDA_ANIMAL_FAILURE = 'DELETE_VENDA_ANIMAL_FAILURE'