import { takeLatest, put, call } from 'redux-saga/effects';

import { listAnimalLoteCadastro } from '../service';
import {
    TABLE_ANIMAL_LOTE_CADASTRO,
    TABLE_ANIMAL_LOTE_CADASTRO_PENDING,
    TABLE_ANIMAL_LOTE_CADASTRO_SUCCESS,
    TABLE_ANIMAL_LOTE_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAnimalLoteCadastro(action) {
  yield put({ type: TABLE_ANIMAL_LOTE_CADASTRO_PENDING })

  try {
    const table = yield call(listAnimalLoteCadastro, action.args)
    yield put({ type: TABLE_ANIMAL_LOTE_CADASTRO_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_ANIMAL_LOTE_CADASTRO_FAILURE })
  }
}

export default function* watchListAnimalLoteCadastro() {
  yield takeLatest(TABLE_ANIMAL_LOTE_CADASTRO, sagaListAnimalLoteCadastro)
}