import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    obs: '',
    status: true,
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(100, 'Campo deve ter menos do que 100 caracteres')
        .required('Este campo é obrigatório'),
    obs: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
});

export const procedimentoPecuarioCadastro = {
    newRegistro,
    validation
}