import { CANCEL_TIPO_EMBALAGEM_WHITE_LABEL_RAPIDO, CANCEL_TIPO_EMBALAGEM_WHITE_LABEL_RAPIDO_SUCCESS } from '@handler';
import {
    NEW_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS,
    TABLE_TIPO_EMBALAGEM_WHITE_LABEL_PENDING,
    TABLE_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS,
    TABLE_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE,
    FIND_TIPO_EMBALAGEM_WHITE_LABEL_PENDING,
    FIND_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS,
    FIND_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE,
    DELETE_TIPO_EMBALAGEM_WHITE_LABEL_PENDING,
    DELETE_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS,
    DELETE_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE,
    CREATE_TIPO_EMBALAGEM_WHITE_LABEL_PENDING,
    CREATE_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS,
    CREATE_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE,
} from './actionTypes';


const initialState = { list: null, loading: false }


function tipoEmbalagemWhiteLabelReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                isCadastroRapido: action.isCadastroRapido ?? false,
            }
        case FIND_TIPO_EMBALAGEM_WHITE_LABEL_PENDING:
        case DELETE_TIPO_EMBALAGEM_WHITE_LABEL_PENDING:
        case CREATE_TIPO_EMBALAGEM_WHITE_LABEL_PENDING:
        case TABLE_TIPO_EMBALAGEM_WHITE_LABEL_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CANCEL_TIPO_EMBALAGEM_WHITE_LABEL_RAPIDO:
        case CANCEL_TIPO_EMBALAGEM_WHITE_LABEL_RAPIDO_SUCCESS:
            return {
                ...state,
                isCadastroRapido: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS:
        case CREATE_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                isCadastroRapido: false,
            }
        case DELETE_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { tipoEmbalagemWhiteLabelReducer };