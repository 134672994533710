import { takeLatest, put } from 'redux-saga/effects';

import {
  contaCaixaCadastro,
  NEW_CONTA_CAIXA_CADASTRO,
  NEW_CONTA_CAIXA_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewContaCaixaCadastro(action) {

  yield put({ 
    type: NEW_CONTA_CAIXA_CADASTRO_SUCCESS, 
    registro: contaCaixaCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido, 
  });
}

export default function* watchNewContaCaixaCadastro() {
  yield takeLatest(NEW_CONTA_CAIXA_CADASTRO, sagaNewContaCaixaCadastro)
}
