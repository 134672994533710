import { takeLatest, put, call } from 'redux-saga/effects';

import { findConfigGeral } from '../service';
import {
  FIND_CONFIG_GERAL,
  FIND_CONFIG_GERAL_PENDING,
  FIND_CONFIG_GERAL_SUCCESS,
  FIND_CONFIG_GERAL_FAILURE
} from '@handler';

function* sagaFindConfigGeral() {
  yield put({ type: FIND_CONFIG_GERAL_PENDING })

  try {
    const registro = yield call(findConfigGeral);

    yield put({ type: FIND_CONFIG_GERAL_SUCCESS, registro: registro })

  } catch (error) {
    yield put({ type: FIND_CONFIG_GERAL_FAILURE })

  }
}

export default function* watchFindConfigGeral() {
  yield takeLatest(FIND_CONFIG_GERAL, sagaFindConfigGeral)
}
