


import watchNewPlanejamento from './saga/new';
import watchFindPlanejamento from './saga/find';
import watchCreatePlanejamento from './saga/create';
import watchListPlanejamento from './saga/listTable';
import watchDeletePlanejamento from './saga/delete';
import watchRelatorioAtividadesTalhaoSinteticoPlanejamento from './saga/relatorioAtividadesTalhaoSintetico';
import watchRelatorioAtividadesTalhaoAnaliticoPlanejamento from './saga/relatorioAtividadesTalhaoAnalitico';
import watchNewFilterPlanejamento from './saga/newFilter';
import watchNewFilterNextPagePlanejamento from './saga/newFilterNextPage';

export const planejamentoSaga = [
    watchNewPlanejamento(),
    watchFindPlanejamento(),
    watchCreatePlanejamento(),
    watchListPlanejamento(),
    watchDeletePlanejamento(),
    watchRelatorioAtividadesTalhaoSinteticoPlanejamento(),
    watchRelatorioAtividadesTalhaoAnaliticoPlanejamento(),
    watchNewFilterPlanejamento(),
    watchNewFilterNextPagePlanejamento(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
export * from './statusPlanejamentoEnum';
