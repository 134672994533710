import { takeLatest, put, call } from 'redux-saga/effects';

import { history, storage } from '@commons';

import { createAnoPecuarioCadastro, findAllAnoPecuariosByPropriedade, updateAnoPecuarioCadastro } from '../service';
import {
  CONFIG_SESSION,
  CREATE_ANO_PECUARIO_CADASTRO,
  CREATE_ANO_PECUARIO_CADASTRO_PENDING,
  CREATE_ANO_PECUARIO_CADASTRO_SUCCESS,
  CREATE_ANO_PECUARIO_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertWarning, AlertError } from '@components/common/AlertToast'

function* sagaCreateAnoPecuarioCadastro(action) {
  yield put({ type: CREATE_ANO_PECUARIO_CADASTRO_PENDING })

  try {
    if (action.data.dataFinal < action.data.dataInicial) {
      AlertWarning('Data Inicial maior que Data Final. Verifique...');
      yield put({ type: CREATE_ANO_PECUARIO_CADASTRO_FAILURE, registro: action.data })
    } else {
      const registro = {
        nome: action.data.nome,
        status: action.data.status,
        dataInicial: action.data.dataInicial,
        dataFinal: action.data.dataFinal,
        obs: action.data.obs,
        propriedade: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
        },
        anoPecuarioCampoList: action.data.anoPecuarioCampoList
      }

      if (action.data.id === undefined || action.data.id === null) {
        yield call(createAnoPecuarioCadastro, registro);
      } else {
        yield call(updateAnoPecuarioCadastro,
          {
            ...registro,
            id: action.data.id
          }
        );
      }

      // ATUALIZA ANO PECUARIO NA SESSAO
      const anosPecuario = yield call(findAllAnoPecuariosByPropriedade, registro.propriedade.id);

      const configSessionAtual = JSON.parse(storage.get(CONFIG_SESSION));
      let anoSelecionado = null;

      if (anosPecuario.content.length === 1) {
        anoSelecionado = anosPecuario.content[0];
      } else {
        if (configSessionAtual.anoPecuarioAtivo !== null) {
          if (action.data.id === undefined || action.data.id === null) {
            anoSelecionado = configSessionAtual.anoPecuarioAtivo;
          } else {
            if (configSessionAtual.anoPecuarioAtivo.id === action.data.id) {
              anoSelecionado = {
                ...registro,
                id: action.data.id
              };
            } else {
              anoSelecionado = configSessionAtual.anoPecuarioAtivo;
            }
          }
        }
      }

      const configSession = {
        ...configSessionAtual,
        anosPecuario: anosPecuario.content,
        anoPecuarioAtivo: anoSelecionado
      }

      yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));

      yield put({ type: CREATE_ANO_PECUARIO_CADASTRO_SUCCESS })

      if (!action.isCadastroRapido) {
        history.push('/pecuaria/anoPecuarioCadastro');
      }

      AlertSuccess('Registro salvo com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_ANO_PECUARIO_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateAnoPecuarioCadastro() {
  yield takeLatest(CREATE_ANO_PECUARIO_CADASTRO, sagaCreateAnoPecuarioCadastro)
}
