
export const NEW_TRANSF_CONTA_BANCO_CAIXA = 'NEW_TRANSF_CONTA_BANCO_CAIXA'
export const NEW_TRANSF_CONTA_BANCO_CAIXA_SUCCESS = 'NEW_TRANSF_CONTA_BANCO_CAIXA_SUCCESS'

export const NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA = 'NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA'
export const NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA_PEDING = 'NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA_PEDING'
export const NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA_SUCCESS = 'NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA_SUCCESS'

export const CREATE_TRANSF_CONTA_BANCO_CAIXA = 'CREATE_TRANSF_CONTA_BANCO_CAIXA'
export const CREATE_TRANSF_CONTA_BANCO_CAIXA_PENDING = 'CREATE_TRANSF_CONTA_BANCO_CAIXA_PENDING'
export const CREATE_TRANSF_CONTA_BANCO_CAIXA_SUCCESS = 'CREATE_TRANSF_CONTA_BANCO_CAIXA_SUCCESS'
export const CREATE_TRANSF_CONTA_BANCO_CAIXA_FAILURE = 'CREATE_TRANSF_CONTA_BANCO_CAIXA_FAILURE'

export const LIST_TRANSF_CONTA_BANCO_CAIXA = 'LIST_TRANSF_CONTA_BANCO_CAIXA'
export const LIST_TRANSF_CONTA_BANCO_CAIXA_PENDING = 'LIST_TRANSF_CONTA_BANCO_CAIXA_PENDING'
export const LIST_TRANSF_CONTA_BANCO_CAIXA_SUCCESS = 'LIST_TRANSF_CONTA_BANCO_CAIXA_SUCCESS'
export const LIST_TRANSF_CONTA_BANCO_CAIXA_FAILURE = 'LIST_TRANSF_CONTA_BANCO_CAIXA_FAILURE'

export const FIND_TRANSF_CONTA_BANCO_CAIXA = 'FIND_TRANSF_CONTA_BANCO_CAIXA'
export const FIND_TRANSF_CONTA_BANCO_CAIXA_PENDING = 'FIND_TRANSF_CONTA_BANCO_CAIXA_PENDING'
export const FIND_TRANSF_CONTA_BANCO_CAIXA_SUCCESS = 'FIND_TRANSF_CONTA_BANCO_CAIXA_SUCCESS'
export const FIND_TRANSF_CONTA_BANCO_CAIXA_FAILURE = 'FIND_TRANSF_CONTA_BANCO_CAIXA_FAILURE'

export const DELETE_TRANSF_CONTA_BANCO_CAIXA = 'DELETE_TRANSF_CONTA_BANCO_CAIXA'
export const DELETE_TRANSF_CONTA_BANCO_CAIXA_PENDING = 'DELETE_TRANSF_CONTA_BANCO_CAIXA_PENDING'
export const DELETE_TRANSF_CONTA_BANCO_CAIXA_SUCCESS = 'DELETE_TRANSF_CONTA_BANCO_CAIXA_SUCCESS'
export const DELETE_TRANSF_CONTA_BANCO_CAIXA_FAILURE = 'DELETE_TRANSF_CONTA_BANCO_CAIXA_FAILURE'
