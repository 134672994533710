import { takeLatest, put } from 'redux-saga/effects';

import {
  procedimentoPecuarioCadastro,
  NEW_PROCEDIMENTO_PECUARIO_CADASTRO,
  NEW_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewProcedimentoPecuarioCadastro(action) {

  yield put({ 
    type: NEW_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS, 
    registro: procedimentoPecuarioCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido,  
  });
}

export default function* watchNewProcedimentoPecuarioCadastro() {
  yield takeLatest(NEW_PROCEDIMENTO_PECUARIO_CADASTRO, sagaNewProcedimentoPecuarioCadastro)
}
