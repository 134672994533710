


import watchNewCatalagoProdutoWhiteLabel from './saga/new';
import watchFindCatalagoProdutoWhiteLabel from './saga/find';
import watchCreateCatalagoProdutoWhiteLabel from './saga/create';
import watchListCatalagoProdutoWhiteLabel from './saga/listTable';
import watchDeleteCatalagoProdutoWhiteLabel from './saga/delete';
import watchCancelCatalagoProdutoWhiteLabelRapido from './saga/cancel';

export const catalagoProdutoWhiteLabelSaga = [
    watchNewCatalagoProdutoWhiteLabel(),
    watchFindCatalagoProdutoWhiteLabel(),
    watchCreateCatalagoProdutoWhiteLabel(),
    watchListCatalagoProdutoWhiteLabel(),
    watchDeleteCatalagoProdutoWhiteLabel(),
    watchCancelCatalagoProdutoWhiteLabelRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
