import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createImplantacaoEstoqueProducao, updateImplantacaoEstoqueProducao } from '../service';
import {
  CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO,
  CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING,
  CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS,
  CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateImplantacaoEstoqueProducao(action) {
  yield put({ type: CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createImplantacaoEstoqueProducao, {
        ...action.data,
        propriedade: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
        },
        safra: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id
        }
      });
    } else {
      yield call(updateImplantacaoEstoqueProducao,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS, registro: action.data })

    history.push('/agricultura/producao/implantacao');

    AlertSuccess('Registro salvo com sucesso!');
  } catch (error) {
    yield put({ type: CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

export default function* watchCreateImplantacaoEstoqueProducao() {
  yield takeLatest(CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO, sagaCreateImplantacaoEstoqueProducao)
}
