import { takeLatest, put, call } from 'redux-saga/effects';

import { storage } from '@commons';

import { findAnoPecuarioCadastro } from '@handler/pecuaria/anoPecuarioCadastro/service';
import {
  CONFIG_SESSION,
  SELECT_ANO_PECUARIO_FAZENDA,
  SELECT_START_SESSION_PENDING,
  SELECT_START_SESSION_SUCCESS,
  SELECT_START_SESSION_FAILURE
} from '../actionTypes';

function* sagaSelectAnoPecuarioFazenda(action) {
  yield put({ type: SELECT_START_SESSION_PENDING })

  try {
    const anoPecuarioSelecionado = yield call(findAnoPecuarioCadastro, action.id);

    const configSessionAtual = JSON.parse(storage.get(CONFIG_SESSION));

    const configSession = {
      ...configSessionAtual,
      anoPecuarioAtivo: anoPecuarioSelecionado
    }
    
    yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));
    
    yield put({ type: SELECT_START_SESSION_SUCCESS })

    window.location.reload();
  } catch (error) {
    yield put({ type: SELECT_START_SESSION_FAILURE })

  }
}

export default function* watchSelectAnoPecuarioFazenda() {
  yield takeLatest(SELECT_ANO_PECUARIO_FAZENDA, sagaSelectAnoPecuarioFazenda)
}
