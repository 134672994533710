import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    apelido: '',
    status: true,
    tipoPessoa: 'FISICA',
    cpf: '',
    dataNascimento: '',
    cnpj: '',
    indicadorInscrEstadual: '',
    inscricaoEstadual: '',
    email: '',
    celular: '',
    telefone: '',
    endereco: {
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        estado: null,
        cidade: null,
    },
    cienteBaixaNotas: false,
    nomeSocioResponsavel: '',
    cpfSocioResponsavel: '',
    certificadoDigital: {
        certificadoCarregado: true,
        certificado: null,
        senhaCertificadoTemp: ''
    }
};

const validation = Yup.object().shape({
    tipoPessoa: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    apelido: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    email: Yup.string()
        .email('E-mail inválido')
        .max(150, 'Campo deve ter menos do que 150 caracteres'),
    nomeSocioResponsavel: Yup.string().when('tipoPessoa', {
        is: (tipoPessoa) => tipoPessoa === 'JURIDICA',
        then: Yup.string()
            .min(2, 'O campo deve ter no mínimo 2 caracteres')
            .max(60, 'Campo deve ter menos do que 60 caracteres')
            .required('Este campo é obrigatório'),
    }).nullable(),
    endereco: Yup.object().shape({
        logradouro: Yup.string()
            .min(2, 'O campo deve ter no mínimo 2 caracteres!')
            .max(60, 'Campo deve ter menos do que 60 caracteres!')
            .required('Este campo é obrigatório'),
        numero: Yup.string()
            .min(2, 'O campo deve ter no mínimo 2 caracteres!')
            .max(60, 'Campo deve ter menos do que 60 caracteres!')
            .required('Este campo é obrigatório'),
        complemento: Yup.string()
            .max(60, 'Campo deve ter menos do que 60 caracteres!'),
        bairro: Yup.string()
            .min(2, 'O campo deve ter no mínimo 2 caracteres!')
            .max(60, 'Campo deve ter menos do que 60 caracteres!')
            .required('Este campo é obrigatório'),
        cep: Yup.string()
            .required('Este campo é obrigatório'),
        estado: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            }),
        cidade: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            }),
    }),
});

export const emitenteCadastro = {
    newRegistro,
    validation
}