import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/conta/cheque' };
/////////////////////////////////////////////////////////////////////////////////

export function createContaChequePreDatadoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateContaChequePreDatadoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listContaChequePreDatadoCadastro(param) {
  return httpService.get({ ...args, args: param });
}

export function findContaChequePreDatadoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllContaChequePreDatadoCadastro() {
  return httpService.get({ ...args, path: '/conta/cheque/all' });
}

export function deleteContaChequePreDatadoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

