import { takeLatest, put, call } from 'redux-saga/effects';

import { findCampoCadastro } from '../service';
import { listAllTalhaoAtivo } from '@handler/common/talhaoCadastro/service';
import { storage } from '@commons/storage';
import {
  CONFIG_SESSION,
  FIND_CAMPO_CADASTRO,
  FIND_CAMPO_CADASTRO_PENDING,
  FIND_CAMPO_CADASTRO_SUCCESS,
  FIND_CAMPO_CADASTRO_FAILURE
} from '@handler';

function* sagaFindCampoCadastro(action) {
  yield put({ type: FIND_CAMPO_CADASTRO_PENDING, id: action.id })

  try {
    
    const talhoesCadastro = yield call(listAllTalhaoAtivo, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id);

    let campoTalhaoList = []
    talhoesCadastro.content.forEach(
      (talhao) => {
        campoTalhaoList = campoTalhaoList.concat({
          talhao: talhao,
          status: false,
          // area: talhao.area
        })
      });

    const registro = yield call(findCampoCadastro, action.id);

    let talhoesInativo = []
    for (let index = 0; index < registro.campoTalhaoList.length; index++) {
      const elementSafraTalhao = registro.campoTalhaoList[index];

      let talhaoEncontrado = false;
      for (let indexTalhoes = 0; indexTalhoes < campoTalhaoList.length; indexTalhoes++) {
        const elementTalhao = campoTalhaoList[indexTalhoes];

        if (elementSafraTalhao.talhao.id === elementTalhao.talhao.id) {
          elementTalhao.status = elementSafraTalhao.status;
          // elementTalhao.area = elementSafraTalhao.area;

          talhaoEncontrado = true;
        }
      }

      if (!talhaoEncontrado) {
        talhoesInativo = talhoesInativo.concat({
          talhao: elementSafraTalhao.talhao,
          status: elementSafraTalhao.status,
          // area: elementSafraTalhao.talhao.area
        })
      }
    }

    talhoesInativo.forEach(
      (valor) => {
        valor.talhao.nome = valor.talhao.nome + ' (talhão inativo)'
        campoTalhaoList = campoTalhaoList.concat({
          talhao: valor.talhao,
          status: valor.status,
          // area: valor.talhao.area
        })
      });

    yield put({
      type: FIND_CAMPO_CADASTRO_SUCCESS, registro: {
        realizaMapeamento: false,
        ...registro,
        campoTalhaoList,
        allTalhoes: false
      }
    })

    // const registro = yield call(findCampoCadastro, action.id);
    // yield put({ type: FIND_CAMPO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {
    yield put({ type: FIND_CAMPO_CADASTRO_FAILURE })
  }
}

export default function* watchFindCampoCadastro() {
  yield takeLatest(FIND_CAMPO_CADASTRO, sagaFindCampoCadastro)
}
