import { takeLatest, put, call } from 'redux-saga/effects';

import { findProcedimentoPecuarioCadastro } from '../service';
import {
  FIND_PROCEDIMENTO_PECUARIO_CADASTRO,
  FIND_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING,
  FIND_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS,
  FIND_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindProcedimentoPecuarioCadastro(action) {
  yield put({ type: FIND_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING, id: action.id })

  try {
    const registro = yield call(findProcedimentoPecuarioCadastro, action.id);
    yield put({ type: FIND_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS, registro: registro })
  } catch (error) {
    yield put({ type: FIND_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE })
  }
}

export default function* watchFindProcedimentoPecuarioCadastro() {
  yield takeLatest(FIND_PROCEDIMENTO_PECUARIO_CADASTRO, sagaFindProcedimentoPecuarioCadastro)
}
