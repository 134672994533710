import { takeLatest, put, call } from 'redux-saga/effects';

import { listPluviometroDigital } from '../service';
import {
  TABLE_PLUVIOMETRO_DIGITAL,
  TABLE_PLUVIOMETRO_DIGITAL_PENDING,
  TABLE_PLUVIOMETRO_DIGITAL_SUCCESS,
  TABLE_PLUVIOMETRO_DIGITAL_FAILURE
} from '../actionTypes';

function* sagaListPluviometroDigital(action) {
  yield put({ type: TABLE_PLUVIOMETRO_DIGITAL_PENDING })

  try {
    const table = yield call(listPluviometroDigital, action.args)
    yield put({ type: TABLE_PLUVIOMETRO_DIGITAL_SUCCESS, table: table })

  } catch (error) {

    yield put({ type: TABLE_PLUVIOMETRO_DIGITAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListPluviometroDigital() {
  yield takeLatest(TABLE_PLUVIOMETRO_DIGITAL, sagaListPluviometroDigital)
}