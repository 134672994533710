import watchNewNaturezaOperacaoCadastro from './saga/new';
import watchFindNaturezaOperacaoCadastro from './saga/find';
import watchCreateNaturezaOperacaoCadastro from './saga/create';
import watchListAllNaturezaOperacaoCadastro from './saga/listAll';
import watchListNaturezaOperacaoCadastro from './saga/listTable';
import watchDeleteNaturezaOperacaoCadastro from './saga/delete';
import watchCancelNaturezaOperacaoCadastroRapido from './saga/cancel';

export const naturezaOperacaoCadastroSaga = [
    watchNewNaturezaOperacaoCadastro(),
    watchFindNaturezaOperacaoCadastro(),
    watchCreateNaturezaOperacaoCadastro(),
    watchListAllNaturezaOperacaoCadastro(),
    watchListNaturezaOperacaoCadastro(),
    watchDeleteNaturezaOperacaoCadastro(),
    watchCancelNaturezaOperacaoCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
