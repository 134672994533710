import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/naturezaoperacao' };
/////////////////////////////////////////////////////////////////////////////////

export function createNaturezaOperacaoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateNaturezaOperacaoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listNaturezaOperacaoCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findNaturezaOperacaoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllNaturezaOperacaoCadastro() {
  return httpService.get({ ...args, path: '/naturezaoperacao/all' });
}

export function deleteNaturezaOperacaoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

