import { takeLatest, put, call } from 'redux-saga/effects';

import { findSafraCadastro } from '../service';
import { listAllTalhaoAtivo } from '@handler/common/talhaoCadastro/service';
import { storage } from '@commons/storage';
import {
  CONFIG_SESSION,
  FIND_SAFRA_CADASTRO,
  FIND_SAFRA_CADASTRO_PENDING,
  FIND_SAFRA_CADASTRO_SUCCESS,
  FIND_SAFRA_CADASTRO_FAILURE
} from '@handler';

function* sagaFindSafraCadastro(action) {
  yield put({ type: FIND_SAFRA_CADASTRO_PENDING, id: action.id })

  try {

    const talhoesCadastro = yield call(listAllTalhaoAtivo, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id);

    let safraTalhaoList = []
    talhoesCadastro.content.forEach(
      (talhao) => {
        safraTalhaoList = safraTalhaoList.concat({
          talhao: talhao,
          ativo: false,
          area: talhao.area
        })
      });

    const registro = yield call(findSafraCadastro, action.id);

    let talhoesInativo = []
    for (let index = 0; index < registro.safraTalhaoList.length; index++) {
      const elementSafraTalhao = registro.safraTalhaoList[index];

      let talhaoEncontrado = false;
      for (let indexTalhoes = 0; indexTalhoes < safraTalhaoList.length; indexTalhoes++) {
        const elementTalhao = safraTalhaoList[indexTalhoes];

        if (elementSafraTalhao.talhao.id === elementTalhao.talhao.id) {
          elementTalhao.ativo = elementSafraTalhao.ativo;
          elementTalhao.area = elementSafraTalhao.area;

          talhaoEncontrado = true;
        }
      }

      if (!talhaoEncontrado) {
        talhoesInativo = talhoesInativo.concat({
          talhao: elementSafraTalhao.talhao,
          ativo: elementSafraTalhao.ativo,
          area: elementSafraTalhao.talhao.area
        })
      }
    }

    talhoesInativo.forEach(
      (valor) => {
        valor.talhao.nome = valor.talhao.nome + ' (talhão inativo)'
        safraTalhaoList = safraTalhaoList.concat({
          talhao: valor.talhao,
          ativo: valor.ativo,
          area: valor.talhao.area
        })
      });

    yield put({
      type: FIND_SAFRA_CADASTRO_SUCCESS, registro: {
        ...registro,
        safraTalhaoList,
        allTalhoes: false
      }
    })

  } catch (error) {
    yield put({ type: FIND_SAFRA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line
  }
}

export default function* watchFindSafraCadastro() {
  yield takeLatest(FIND_SAFRA_CADASTRO, sagaFindSafraCadastro)
}
