import { takeLatest, put, call } from 'redux-saga/effects';

import { findTransferenciaAnimal } from '../service';
import {
  FIND_TRANSFERENCIA_ANIMAL,
  FIND_TRANSFERENCIA_ANIMAL_PENDING,
  FIND_TRANSFERENCIA_ANIMAL_SUCCESS,
  FIND_TRANSFERENCIA_ANIMAL_FAILURE
} from '../actionTypes';

function* sagaFindTransferenciaAnimal(action) {
  yield put({ type: FIND_TRANSFERENCIA_ANIMAL_PENDING, id: action.id })

  try {

    const registro = yield call(findTransferenciaAnimal, action.id);

    yield put({ type: FIND_TRANSFERENCIA_ANIMAL_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_TRANSFERENCIA_ANIMAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindTransferenciaAnimal() {
  yield takeLatest(FIND_TRANSFERENCIA_ANIMAL, sagaFindTransferenciaAnimal)
}
