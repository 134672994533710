import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deleteNaturezaOperacaoCadastro } from '../service';
import {
  DELETE_NATUREZA_OPERACAO_CADASTRO,
  DELETE_NATUREZA_OPERACAO_CADASTRO_PENDING,
  DELETE_NATUREZA_OPERACAO_CADASTRO_SUCCESS,
  DELETE_NATUREZA_OPERACAO_CADASTRO_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaDeleteNaturezaOperacaoCadastro(action) {
  yield put({ type: DELETE_NATUREZA_OPERACAO_CADASTRO_PENDING, id: action.id })
  
  try {
    const a = yield call(deleteNaturezaOperacaoCadastro, action.id);

    if (a.status !== 204) {
      throw new Error('API delete request failed');
    }
    yield put({ type: DELETE_NATUREZA_OPERACAO_CADASTRO_SUCCESS, id: action.id });
  
    swal({
      title: 'Excluído!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });
  } catch (_) {
    yield put({ type: DELETE_NATUREZA_OPERACAO_CADASTRO_FAILURE });

    AlertError('Falha ao excluir o registro! Verifique seu local não possuí lançamentos vinculados e tente novamente...');
  }
}

export default function* watchDeleteNaturezaOperacaoCadastro() {
  yield takeLatest(DELETE_NATUREZA_OPERACAO_CADASTRO, sagaDeleteNaturezaOperacaoCadastro)
}
