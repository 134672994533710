import watchNewAnimalPelagemCadastro from './saga/new';
import watchFindAnimalPelagemCadastro from './saga/find';
import watchCreateAnimalPelagemCadastro from './saga/create';
import watchListAnimalPelagemCadastro from './saga/listTable';
import watchDeleteAnimalPelagemCadastro from './saga/delete';
import watchCancelAnimalPelagemCadastroRapido from './saga/cancel';

export const animalPelagemCadastroSaga = [
    watchNewAnimalPelagemCadastro(),
    watchFindAnimalPelagemCadastro(),
    watchCreateAnimalPelagemCadastro(),
    watchListAnimalPelagemCadastro(),
    watchDeleteAnimalPelagemCadastro(),
    watchCancelAnimalPelagemCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
