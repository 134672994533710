import { takeLatest, put } from 'redux-saga/effects';

import {
  partnerWhiteLabel,
  NEW_PARTNER_WHITE_LABEL,
  NEW_PARTNER_WHITE_LABEL_SUCCESS,
} from '@handler';

function* sagaNewPartnerWhiteLabel(action) {

  yield put({
    type: NEW_PARTNER_WHITE_LABEL_SUCCESS,
    registro: partnerWhiteLabel.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewPartnerWhiteLabel() {
  yield takeLatest(NEW_PARTNER_WHITE_LABEL, sagaNewPartnerWhiteLabel)
}
