import * as Yup from 'yup';

const newRegistro = {
    replicarLancamento: false,
    data: '',
    obs: '',
    status: "PLANEJADO",
    talhao: null,
    talhoesList: [],
    procedimento: null,
    custoTotalProdutosInsumos: 0,
    custoTotalMaquinarios: 0,
    custoTotalFuncionarios: 0,
    custoTotalTalhao: 0,
    custoTotalHa: 0,
    utilizaProdutoInsumo: false,
    utilizaMaquinario: false,
    utilizaFuncionario: false,
    aplicacoes: [],
    alocacaoFuncionarios: [],
    utilizacaoMaquinarios: [],
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    talhao: Yup.object().when(['replicarLancamento'], {
        is: (replicarLancamento) => (
            replicarLancamento == false),
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            }),
    }).nullable(),
    talhoesList: Yup.array().when(['replicarLancamento'], {
        is: (replicarLancamento) => (
            replicarLancamento == true),
        then: Yup.array().min(1, 'Este campo é obrigatório'),
    }).nullable(),
    procedimento: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    obs: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
});

export const planejamento = {
    newRegistro,
    validation
}