import { takeLatest, put } from 'redux-saga/effects';

import {
  contaCartaoCadastro,
  NEW_CONTA_CARTAO_CADASTRO,
  NEW_CONTA_CARTAO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewContaCartaoCadastro(action) {

  yield put({ type: NEW_CONTA_CARTAO_CADASTRO_SUCCESS, registro: contaCartaoCadastro.newRegistro });
}

export default function* watchNewContaCartaoCadastro() {
  yield takeLatest(NEW_CONTA_CARTAO_CADASTRO, sagaNewContaCartaoCadastro)
}
