import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/fluxoCaixa' };
/////////////////////////////////////////////////////////////////////////////////

export function getRelatorioFluxoCaixa(idPropriedade, data) {
  return httpService.post({ ...args, args: `/gerar-relatorio/${idPropriedade}` }, data);
}

export function getGraficoFluxoCaixa(idPropriedade, data) {
  return httpService.post({ ...args, args: `/gerar-grafico/${idPropriedade}` }, data);
}
