import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  baixaConta,
  BAIXAR_CONTA_PAGAMENTO_PENDING,
  NEW_BAIXAR_CONTA,
  NEW_BAIXAR_CONTA_SUCCESS,
} from '@handler';

function* sagaNewBaixarConta(action) {
  yield put({ type: BAIXAR_CONTA_PAGAMENTO_PENDING })

  yield put({
    type: NEW_BAIXAR_CONTA_SUCCESS, contaSelecionada: action.conta, registro:
    {
      ...baixaConta.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      tipoPagamento: 'DINHEIRO',
      valorCapital: action.conta.valorPendente,
      valorTotal: action.conta.valorPendente,
    }
  });
}

export default function* watchNewBaixarConta() {
  yield takeLatest(NEW_BAIXAR_CONTA, sagaNewBaixarConta)
}
