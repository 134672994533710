import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    status: true,
    armazenaInsumo: true,
    armazenaSilo: false,
    armazenaPeca: false,
    armazenaCombustivel: false,
    obs: ''
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    obs: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
});

export const localEstoqueCadastro = {
    newRegistro,
    validation
}