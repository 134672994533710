
export const NEW_CONTA_RECEBER = 'NEW_CONTA_RECEBER'
export const NEW_CONTA_RECEBER_SUCCESS = 'NEW_CONTA_RECEBER_SUCCESS'

export const NEW_FILTER_CONTA_RECEBER = 'NEW_FILTER_CONTA_RECEBER'
export const NEW_FILTER_CONTA_RECEBER_PEDING = 'NEW_FILTER_CONTA_RECEBER_PEDING'
export const NEW_FILTER_CONTA_RECEBER_SUCCESS = 'NEW_FILTER_CONTA_RECEBER_SUCCESS'
export const NEW_FILTER_CONTA_RECEBER_SUCCESS_PDF = 'NEW_FILTER_CONTA_RECEBER_SUCCESS_PDF'
export const NEW_FILTER_CONTA_RECEBER_FAILURE = 'NEW_FILTER_CONTA_RECEBER_FAILURE'

export const NEW_FILTER_NEXT_PAGE_CONTA_RECEBER = 'NEW_FILTER_NEXT_PAGE_CONTA_RECEBER'
export const NEW_FILTER_NEXT_PAGE_CONTA_RECEBER_PENDING = 'NEW_FILTER_NEXT_PAGE_CONTA_RECEBER_PENDING'
export const NEW_FILTER_NEXT_PAGE_CONTA_RECEBER_SUCCESS = 'NEW_FILTER_NEXT_PAGE_CONTA_RECEBER_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_CONTA_RECEBER_FAILURE = 'NEW_FILTER_NEXT_PAGE_CONTA_RECEBER_FAILURE'

export const GET_TOTAIS_CONTA_RECEBER_PENDING = 'GET_TOTAIS_CONTA_RECEBER_PENDING'
export const GET_TOTAIS_CONTA_RECEBER_SUCCESS = 'GET_TOTAIS_CONTA_RECEBER_SUCCESS'
export const GET_TOTAIS_CONTA_RECEBER_FAILURE = 'GET_TOTAIS_CONTA_RECEBER_FAILURE'

export const CREATE_CONTA_RECEBER = 'CREATE_CONTA_RECEBER'
export const CREATE_CONTA_RECEBER_PENDING = 'CREATE_CONTA_RECEBER_PENDING'
export const CREATE_CONTA_RECEBER_SUCCESS = 'CREATE_CONTA_RECEBER_SUCCESS'
export const CREATE_CONTA_RECEBER_FAILURE = 'CREATE_CONTA_RECEBER_FAILURE'

export const FIND_CONTA_RECEBER = 'FIND_CONTA_RECEBER'
export const FIND_CONTA_RECEBER_PENDING = 'FIND_CONTA_RECEBER_PENDING'
export const FIND_CONTA_RECEBER_SUCCESS = 'FIND_CONTA_RECEBER_SUCCESS'
export const FIND_CONTA_RECEBER_FAILURE = 'FIND_CONTA_RECEBER_FAILURE'

export const FIND_CONTA_RECEBER_PAGAMENTOS = 'FIND_CONTA_RECEBER_PAGAMENTOS'
export const FIND_CONTA_RECEBER_PAGAMENTOS_PENDING = 'FIND_CONTA_RECEBER_PAGAMENTOS_PENDING'
export const FIND_CONTA_RECEBER_PAGAMENTOS_SUCCESS = 'FIND_CONTA_RECEBER_PAGAMENTOS_SUCCESS'
export const FIND_CONTA_RECEBER_PAGAMENTOS_FAILURE = 'FIND_CONTA_RECEBER_PAGAMENTOS_FAILURE'

export const DELETE_CONTA_RECEBER = 'DELETE_CONTA_RECEBER'
export const DELETE_CONTA_RECEBER_PENDING = 'DELETE_CONTA_RECEBER_PENDING'
export const DELETE_CONTA_RECEBER_SUCCESS = 'DELETE_CONTA_RECEBER_SUCCESS'
export const DELETE_CONTA_RECEBER_FAILURE = 'DELETE_CONTA_RECEBER_FAILURE'

export const CONTA_RECEBER_ESTORNAR_PAGAMENTO = 'CONTA_RECEBER_ESTORNAR_PAGAMENTO'
export const CONTA_RECEBER_ESTORNAR_PAGAMENTO_PENDING = 'CONTA_RECEBER_ESTORNAR_PAGAMENTO_PENDING'
export const CONTA_RECEBER_ESTORNAR_PAGAMENTO_SUCCESS = 'CONTA_RECEBER_ESTORNAR_PAGAMENTO_SUCCESS'
export const CONTA_RECEBER_ESTORNAR_PAGAMENTO_FAILURE = 'CONTA_RECEBER_ESTORNAR_PAGAMENTO_FAILURE'
