import { takeLatest, put } from 'redux-saga/effects';

import {
  emitenteCadastro,
  NEW_EMITENTE_CADASTRO,
  NEW_EMITENTE_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewEmitenteCadastro(action) {
  yield put({
    type: NEW_EMITENTE_CADASTRO_SUCCESS, registro: {
      ...emitenteCadastro.newRegistro,
      endereco: {
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        estado: null,
        cidade: null,
      },
      cienteBaixaNotas: false,
      nomeSocioResponsavel: '',
      cpfSocioResponsavel: '',
      certificadoDigital: {
        certificadoCarregado: true,
        certificado: null,
        senhaCertificadoTemp: ''
      }
    },
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewEmitenteCadastro() {
  yield takeLatest(NEW_EMITENTE_CADASTRO, sagaNewEmitenteCadastro)
}
