import { UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_FAILURE, UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_PENDING, UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_SUCCESS } from '@handler';
import {
    NEW_PRODUTO_INSUMO_CADASTRO_SUCCESS,
    CANCEL_PRODUTO_INSUMO_CADASTRO_RAPIDO,
    CANCEL_PRODUTO_INSUMO_CADASTRO_RAPIDO_SUCCESS,
    NEW_PRODUTO_INSUMO_CADASTRO_PENDING,
    TABLE_PRODUTO_INSUMO_CADASTRO_PENDING,
    TABLE_PRODUTO_INSUMO_CADASTRO_SUCCESS,
    TABLE_PRODUTO_INSUMO_CADASTRO_FAILURE,
    LIST_PRODUTO_INSUMO_CADASTRO_PENDING,
    LIST_PRODUTO_INSUMO_CADASTRO_SUCCESS,
    LIST_PRODUTO_INSUMO_CADASTRO_FAILURE,
    FIND_PRODUTO_INSUMO_CADASTRO_PENDING,
    FIND_PRODUTO_INSUMO_CADASTRO_SUCCESS,
    FIND_PRODUTO_INSUMO_CADASTRO_FAILURE,
    DELETE_PRODUTO_INSUMO_CADASTRO_PENDING,
    DELETE_PRODUTO_INSUMO_CADASTRO_SUCCESS,
    DELETE_PRODUTO_INSUMO_CADASTRO_FAILURE,
    CREATE_PRODUTO_INSUMO_CADASTRO_PENDING,
    CREATE_PRODUTO_INSUMO_CADASTRO_SUCCESS,
    CREATE_PRODUTO_INSUMO_CADASTRO_FAILURE,
    NEW_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_SUCCESS,
    UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_PENDING,
    UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_SUCCESS,
    UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_FAILURE,
    IMPORTAR_PRODUTO_WHITE_LABEL_PENDING,
    IMPORTAR_PRODUTO_WHITE_LABEL_SUCCESS,
    IMPORTAR_PRODUTO_WHITE_LABEL_FAILURE
} from './actionTypes';


const initialState = { list: null, loading: false }


function produtoInsumoCadastroReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_SUCCESS:
            return {
                ...state,
                registroVincularFornecedor: action.registroVincularFornecedor,
            }
        case NEW_PRODUTO_INSUMO_CADASTRO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                isCadastroRapido: action.isCadastroRapido ?? false,
            }
        case LIST_PRODUTO_INSUMO_CADASTRO_PENDING:
        case FIND_PRODUTO_INSUMO_CADASTRO_PENDING:
        case DELETE_PRODUTO_INSUMO_CADASTRO_PENDING:
        case CREATE_PRODUTO_INSUMO_CADASTRO_PENDING:
        case TABLE_PRODUTO_INSUMO_CADASTRO_PENDING:
        case NEW_PRODUTO_INSUMO_CADASTRO_PENDING:
        case UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_PENDING:
        case UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_PENDING:
        case IMPORTAR_PRODUTO_WHITE_LABEL_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CANCEL_PRODUTO_INSUMO_CADASTRO_RAPIDO:
        case CANCEL_PRODUTO_INSUMO_CADASTRO_RAPIDO_SUCCESS:
            return {
                ...state,
                isCadastroRapido: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_PRODUTO_INSUMO_CADASTRO_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_PRODUTO_INSUMO_CADASTRO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_PRODUTO_INSUMO_CADASTRO_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_PRODUTO_INSUMO_CADASTRO_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_PRODUTO_INSUMO_CADASTRO_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_PRODUTO_INSUMO_CADASTRO_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_PRODUTO_INSUMO_CADASTRO_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_PRODUTO_INSUMO_CADASTRO_SUCCESS:
        case CREATE_PRODUTO_INSUMO_CADASTRO_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                isCadastroRapido: false,
            }
        case DELETE_PRODUTO_INSUMO_CADASTRO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_SUCCESS:
        case UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_FAILURE:
        case UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_SUCCESS:
        case UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_FAILURE:
        case IMPORTAR_PRODUTO_WHITE_LABEL_SUCCESS:
        case IMPORTAR_PRODUTO_WHITE_LABEL_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { produtoInsumoCadastroReducer };