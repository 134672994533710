import React from 'react';

import { DataTable } from 'primereact/datatable';
import {
    Button,
} from 'reactstrap';
import './Table.css'
import { CONST_ROWS_PER_PAGE } from '@commons/consts';


class TableNoLazy extends React.Component {

    constructor(props) {
        super(props);

        this.state = {rows: props.rows };
    }

    render() {

        const refDt = React.createRef();
        const { paginator, lazy, showBtnExport } = this.props;

        const paginatorLeft = <React.Fragment />;
            // showBtnExport ? ((this.props.list === undefined || this.props.list === null || this.props.list.empty) ? <React.Fragment /> : 
            
            //     <Button type="button" className="btn btn-dark" onClick={() => { refDt.current.exportCSV(); }}>
            //     <i className='fas fa-file-export' /> Exportar (.csv)
            // </Button>
            
            // ) : <div></div>;

        return (
            <>
                {this.props.list &&
                    <DataTable ref={refDt} value={this.props.list} paginator={paginator} lazy={lazy} paginatorLeft={paginatorLeft}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Total de registros: {totalRecords}" rows={this.state.rows} rowsPerPageOptions={[10, 50, 100, 500]}
                        className='table table-striped my-4 w-100 p-datatable-sm' totalRecords={this.props.list.length}
                        first={0} onPage={this.onPage} emptyMessage="Nenhum registro encontrado" autoLayout={true} csvSeparator=';'
                        scrollable={this.props.scrollable} 
                    >
                        {this.props.children}

                    </DataTable>
                }
            </>
        )

    }
}

TableNoLazy.defaultProps = {
    paginator: true,
    lazy: false,
    auto: true,
    showBtnExport: false,
    scrollable: false,
    rows: CONST_ROWS_PER_PAGE,
    onPage: () => { },
};

export { TableNoLazy };