import { takeLatest, put, call } from 'redux-saga/effects';

import { history, path } from '@commons';
import { findUserAuth, updateUserAuth } from '../service';
import {
  UPDATE_MEU_PERFIL_USUARIO,
  UPDATE_MEU_PERFIL_USUARIO_PENDING,
  UPDATE_MEU_PERFIL_USUARIO_SUCCESS,
  UPDATE_MEU_PERFIL_USUARIO_FAILURE
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'
import { httpAuthService } from '@commons';
import swal from 'sweetalert';

function* sagaUpdateMeuPerfil(action) {
  yield put({ type: UPDATE_MEU_PERFIL_USUARIO_PENDING })

  try {
    var userAuth = yield call(findUserAuth, action.data.id);
    var newData = {
      ...userAuth,
      "name": action.data.name,
      "whatsApp": action.data.whatsApp,
      "passwordTemp": action.data.passwordTemp,

    }
    yield call(updateUserAuth, newData);

    yield put({ type: UPDATE_MEU_PERFIL_USUARIO_SUCCESS, registro: null });

    swal({
      title: 'Perfil atualizado com sucesso! Faça o login novamente...',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      httpAuthService.logout({ path })
      history.push('/');
    });

  } catch (error) {
    yield put({ type: UPDATE_MEU_PERFIL_USUARIO_FAILURE, registro: action.data })

    AlertError('Falha ao atualizar o perfil! Verifique se o e-mail não está cadastrado em outro usuário e tente novamente...');
  }

}

export default function* watchUpdateMeuPerfil() {
  yield takeLatest(UPDATE_MEU_PERFIL_USUARIO, sagaUpdateMeuPerfil)
}
