import * as Yup from 'yup';

const newRegistro = {
    name: '',
    roles: [],
    showPartnerProfile: false,
    permissionRoleWlRegisterUser: false,
    permissionRoleWlRegisterUserProfile: false,
    permissionRoleWlRegisterProduct: false,
    permissionRoleWlRegisterProductView: false,
    permissionRoleWlRegisterPartner: false,
    permissionRoleWlRegisterFarmer: false,
    permissionRoleWlRegisterGroupFarmer: false,
    permissionRoleWlRegisterKindPacking: false,
};

const validation = Yup.object().shape({
    name: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres!')
        .max(100, 'Campo deve ter menos do que 100 caracteres!')
        .required('Este campo é obrigatório'),
    roles: Yup.array()
        .of(
            Yup.object()
        ),
});

export const perfilUsuarioWhiteLabel = {
    newRegistro,
    validation
}