import * as Yup from 'yup';

const newRegistro = {
    active: true,
    name: '',
    email: '',
};

const validation = Yup.object().shape({
    name: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    email: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres!')
        .max(150, 'Campo deve ter menos do que 150 caracteres!')
        .email('Favor, formato de e-mail inválido!')
        .required('Este campo é obrigatório'),
});

export const partnerWhiteLabel = {
    newRegistro,
    validation
}