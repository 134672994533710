import { takeLatest, put, call } from 'redux-saga/effects';

import { listAnimalPelagemCadastro } from '../service';
import {
    TABLE_ANIMAL_PELAGEM_CADASTRO,
    TABLE_ANIMAL_PELAGEM_CADASTRO_PENDING,
    TABLE_ANIMAL_PELAGEM_CADASTRO_SUCCESS,
    TABLE_ANIMAL_PELAGEM_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAnimalPelagemCadastro(action) {
  yield put({ type: TABLE_ANIMAL_PELAGEM_CADASTRO_PENDING })

  try {
    const table = yield call(listAnimalPelagemCadastro, action.args)
    yield put({ type: TABLE_ANIMAL_PELAGEM_CADASTRO_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_ANIMAL_PELAGEM_CADASTRO_FAILURE })
  }
}

export default function* watchListAnimalPelagemCadastro() {
  yield takeLatest(TABLE_ANIMAL_PELAGEM_CADASTRO, sagaListAnimalPelagemCadastro)
}