import { takeLatest, put, call } from 'redux-saga/effects';

import { path, history, storage } from '@commons';

import { createCatalagoProdutoWhiteLabel, updateCatalagoProdutoWhiteLabel } from '../service';
import {
  CREATE_CATALAGO_PRODUTO_WHITE_LABEL,
  CREATE_CATALAGO_PRODUTO_WHITE_LABEL_PENDING,
  CREATE_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS,
  CREATE_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
function* sagaCreateCatalagoProdutoWhiteLabel(action) {
  yield put({ type: CREATE_CATALAGO_PRODUTO_WHITE_LABEL_PENDING })

  try {
    let user = JSON.parse(storage.get(path.sessionUser));

    if (action.data.grupo !== 'ACARICIDA'
      && action.data.grupo !== 'ADJUVANTE'
      && action.data.grupo !== 'ADUBO_FOLIAR'
      && action.data.grupo !== 'DESSECANTE'
      && action.data.grupo !== 'FUNGICIDA'
      && action.data.grupo !== 'HERBICIDA'
      && action.data.grupo !== 'INSETICIDA'
      && action.data.grupo !== 'MEDICAMENTO'
      && action.data.grupo !== 'FUNGICIDA_TS'
      && action.data.grupo !== 'HERBICIDA_FOLHA_LARGA'
      && action.data.grupo !== 'HERBICIDA_FOLHA_ESTREITA'
      && action.data.grupo !== 'INSETICIDA_FISIOLOGICO'
      && action.data.grupo !== 'INSETICIDA_PERCEVEJO_MOSCA_BRANCA'
      && action.data.grupo !== 'OLEO_MINERAL'
      && action.data.grupo !== 'OLEO_VEGETAL'
      && action.data.grupo !== 'INOCULANTE'
      && action.data.grupo !== 'INOCULANTE_LIQUIDO'
      && action.data.grupo !== 'INOCULANTE_TURFOSO'
      && action.data.grupo !== 'INDUTOR_RESISTENCIA'
      && action.data.grupo !== 'DESALOJANTE'
      && action.data.grupo !== 'PROTETOR'
      && action.data.grupo !== 'BIOESTIMULANTE') {
      action.data = {
        ...action.data,
        dosagemHa: 0,
      }
    }

    if (
      action.data.grupo === 'COMBUSTIVEL'
      || action.data.grupo === 'GRAOS'
      || action.data.grupo === 'MEDICAMENTO'
      || action.data.grupo === 'PRODUTO_VETERINARIO'
      || action.data.grupo === 'ITEM_MANUTENCAO'
      || action.data.grupo === 'MATERIAL_EPI'
      || action.data.grupo === 'RACAO'
      || action.data.grupo === 'SAL_MINERAL'
      || action.data.grupo === 'SUPRIMENTO_MINERAL'
    ) {
      action.data = {
        ...action.data,
        formulacao: '',
      }
    }
    var newCatalagoProduto = {
      ...action.data,
      "identificationCodeWhiteLabel": user.identificationCodeWhiteLabel,
    }

    if (action.data.id === undefined || action.data.id === null) {
      yield call(createCatalagoProdutoWhiteLabel, newCatalagoProduto);
    } else {
      yield call(updateCatalagoProdutoWhiteLabel,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/corporate/catalagoProdutos');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateCatalagoProdutoWhiteLabel() {
  yield takeLatest(CREATE_CATALAGO_PRODUTO_WHITE_LABEL, sagaCreateCatalagoProdutoWhiteLabel)
}
