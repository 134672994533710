
export const NEW_FUNCIONARIO_CADASTRO = 'NEW_FUNCIONARIO_CADASTRO'
export const NEW_FUNCIONARIO_CADASTRO_SUCCESS = 'NEW_FUNCIONARIO_CADASTRO_SUCCESS'

export const CREATE_FUNCIONARIO_CADASTRO = 'CREATE_FUNCIONARIO_CADASTRO'
export const CREATE_FUNCIONARIO_CADASTRO_PENDING = 'CREATE_FUNCIONARIO_CADASTRO_PENDING'
export const CREATE_FUNCIONARIO_CADASTRO_SUCCESS = 'CREATE_FUNCIONARIO_CADASTRO_SUCCESS'
export const CREATE_FUNCIONARIO_CADASTRO_FAILURE = 'CREATE_FUNCIONARIO_CADASTRO_FAILURE'

export const CANCEL_FUNCIONARIO_CADASTRO_RAPIDO = 'CANCEL_FUNCIONARIO_CADASTRO_RAPIDO'
export const CANCEL_FUNCIONARIO_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_FUNCIONARIO_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_FUNCIONARIO_CADASTRO = 'TABLE_FUNCIONARIO_CADASTRO'
export const TABLE_FUNCIONARIO_CADASTRO_PENDING = 'TABLE_FUNCIONARIO_CADASTRO_PENDING'
export const TABLE_FUNCIONARIO_CADASTRO_SUCCESS = 'TABLE_FUNCIONARIO_CADASTRO_SUCCESS'
export const TABLE_FUNCIONARIO_CADASTRO_FAILURE = 'TABLE_FUNCIONARIO_CADASTRO_FAILURE'

export const LIST_FUNCIONARIO_CADASTRO = 'LIST_FUNCIONARIO_CADASTRO'
export const LIST_FUNCIONARIO_CADASTRO_PENDING = 'LIST_FUNCIONARIO_CADASTRO_PENDING'
export const LIST_FUNCIONARIO_CADASTRO_SUCCESS = 'LIST_FUNCIONARIO_CADASTRO_SUCCESS'
export const LIST_FUNCIONARIO_CADASTRO_FAILURE = 'LIST_FUNCIONARIO_CADASTRO_FAILURE'

export const FIND_FUNCIONARIO_CADASTRO = 'FIND_FUNCIONARIO_CADASTRO'
export const FIND_FUNCIONARIO_CADASTRO_PENDING = 'FIND_FUNCIONARIO_CADASTRO_PENDING'
export const FIND_FUNCIONARIO_CADASTRO_SUCCESS = 'FIND_FUNCIONARIO_CADASTRO_SUCCESS'
export const FIND_FUNCIONARIO_CADASTRO_FAILURE = 'FIND_FUNCIONARIO_CADASTRO_FAILURE'

export const DELETE_FUNCIONARIO_CADASTRO = 'DELETE_FUNCIONARIO_CADASTRO'
export const DELETE_FUNCIONARIO_CADASTRO_PENDING = 'DELETE_FUNCIONARIO_CADASTRO_PENDING'
export const DELETE_FUNCIONARIO_CADASTRO_SUCCESS = 'DELETE_FUNCIONARIO_CADASTRO_SUCCESS'
export const DELETE_FUNCIONARIO_CADASTRO_FAILURE = 'DELETE_FUNCIONARIO_CADASTRO_FAILURE'

export const FUNCIONARIO_CADASTRO_CALC_CUSTO = 'FUNCIONARIO_CADASTRO_CALC_CUSTO'
export const FUNCIONARIO_CADASTRO_CALC_CUSTO_PENDING = 'FUNCIONARIO_CADASTRO_CALC_CUSTO_PENDING'
export const FUNCIONARIO_CADASTRO_CALC_CUSTO_SUCCESS = 'FUNCIONARIO_CADASTRO_CALC_CUSTO_SUCCESS'
export const FUNCIONARIO_CADASTRO_CALC_CUSTO_FAILURE = 'FUNCIONARIO_CADASTRO_CALC_CUSTO_FAILURE'
