import { takeLatest, put, call } from 'redux-saga/effects';

import {
  SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE,
  SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_PENDING,
  SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_SUCCESS,
  SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_FAILURE
} from '../../actionTypes';
import { AlertError } from '@components/common/AlertToast';
import { findProdutoInsumoCadastro } from '@handler';

function* sagaSelectProductItemXmlEntradaEstoque(action) {
  yield put({ type: SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_PENDING })

  try {
    const produto = yield call(findProdutoInsumoCadastro, action.produto.id);

    action.data.itens[action.itemNf].xmlCompraProdutoSelecionado = true;
    action.data.itens[action.itemNf].produto = produto;
    action.data.itens[action.itemNf].unidadeAplicacao = produto.unidadeAplicacao;
    action.data.itens[action.itemNf].unidadeCompra = produto.unidadeCompra;
    action.data.itens[action.itemNf].qtdConversao = produto.qtdConversaoCompra ?? 1;

    action.data.itens[action.itemNf].possuiConversaoCompra = false;
    action.data.itens[action.itemNf].tipoEmbalagem = null;
    action.data.itens[action.itemNf].qtdeEmbalagem = null;

    if (action.vinculoFornecedor === null) {
      for (let index = 0; index < produto.produtoInsumoFornecedores.length; index++) {
        const prodForn = produto.produtoInsumoFornecedores[index];

        if (prodForn.fornecedor.id === action.data.pessoa.id) {
          action.data.itens[action.itemNf].possuiConversaoCompra = prodForn.possuiConversaoCompra ?? false;
          if (prodForn.possuiConversaoCompra) {
            action.data.itens[action.itemNf].tipoEmbalagem = prodForn.tipoEmbalagem.sigla;
            action.data.itens[action.itemNf].qtdeEmbalagem = prodForn.qtdeEmbalagem;
          } else {
            action.data.itens[action.itemNf].tipoEmbalagem = null;
            action.data.itens[action.itemNf].qtdeEmbalagem = null;
          }
        }
      }
    } else {
      action.data.itens[action.itemNf].possuiConversaoCompra = action.vinculoFornecedor.possuiConversaoCompra ?? false;
      if (action.vinculoFornecedor.possuiConversaoCompra) {
        action.data.itens[action.itemNf].tipoEmbalagem = action.vinculoFornecedor.tipoEmbalagem.sigla;
        action.data.itens[action.itemNf].qtdeEmbalagem = action.vinculoFornecedor.qtdeEmbalagem;
      } else {
        action.data.itens[action.itemNf].tipoEmbalagem = null;
        action.data.itens[action.itemNf].qtdeEmbalagem = null;
      }
    }

    action.data.itens[action.itemNf].movimentaEstoque = false;
    for (let index = 0; index < produto.produtoInsumoEstoques.length; index++) {
      const est = produto.produtoInsumoEstoques[index];
      if (est.propriedade.id === action.data.propriedade.id) {
        action.data.itens[action.itemNf].movimentaEstoque = est.controlaEstoque;
      }
    }

    if (action.data.itens[action.itemNf].possuiConversaoCompra) {
      action.data.itens[action.itemNf].quantidadeNotaConv = action.data.itens[action.itemNf].quantidadeNota * action.data.itens[action.itemNf].qtdeEmbalagem;
      action.data.itens[action.itemNf].valorUnitario = Number((action.data.itens[action.itemNf].valorUnitarioNota / action.data.itens[action.itemNf].qtdeEmbalagem).toFixed(4));
    } else {
      action.data.itens[action.itemNf].quantidadeNotaConv = action.data.itens[action.itemNf].quantidadeNota;
      action.data.itens[action.itemNf].valorUnitario = action.data.itens[action.itemNf].valorUnitarioNota;
    }

    action.data.itens[action.itemNf].quantidade = action.data.itens[action.itemNf].quantidadeNotaConv * action.data.itens[action.itemNf].qtdConversao;

    yield put({ type: SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_SUCCESS, xmlReaded: action.data })

  } catch (error) {
    yield put({ type: SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_FAILURE })

    AlertError('Falha ao selecionar produto! Tente novamente...');

  }
}

export default function* watchSelectProductItemXmlEntradaEstoque() {
  yield takeLatest(SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE, sagaSelectProductItemXmlEntradaEstoque)
}
