import { takeLatest, put } from 'redux-saga/effects';

import {
  usuarioWhiteLabel,
  NEW_USUARIO_WHITE_LABEL,
  NEW_USUARIO_WHITE_LABEL_SUCCESS,
} from '@handler';

function* sagaNewUsuarioWhiteLabel(action) {
  yield put({ type: NEW_USUARIO_WHITE_LABEL_SUCCESS, registro: usuarioWhiteLabel.newRegistro });
}

export default function* watchNewUsuarioWhiteLabel() {
  yield takeLatest(NEW_USUARIO_WHITE_LABEL, sagaNewUsuarioWhiteLabel)
}
