import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';
import { alterarStatusContaRecorrente } from '../service';
import {
  ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE,
  ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_PENDING,
  ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_SUCCESS,
  ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_FAILURE
} from '../actionTypes';
import { AlertError } from '@components/common/AlertToast';

function* sagaAlterarStatusContaPagarRecorrente(action) {
  yield put({ type: ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_PENDING, id: action.id })

  try {
    yield call(alterarStatusContaRecorrente, action.id);

    yield put({ type: ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_SUCCESS })

    swal({
      title: 'Conta atualizada com sucesso!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });
  } catch (error) {
    yield put({ type: ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE_FAILURE });

    AlertError('Falha ao atualizar status da conta! Verifique se as configurações da conta estão corretas e válidas, após tente novamente...');
  }
}

export default function* watchAlterarStatusContaPagarRecorrente() {
  yield takeLatest(ALTERAR_STATUS_CONTA_PAGAR_RECORRENTE, sagaAlterarStatusContaPagarRecorrente)
}
