import axios from 'axios';

export const httpServiceApiDfe = {
    post,
    postFile,
    put,
    get,
    del
};

async function post(pathApiDfe, body) {
    return await req({ method: 'post', pathApiDfe: pathApiDfe, data: body });
}

async function postFile(pathApiDfe, body) {
    return await reqFile({ method: 'post', pathApiDfe: pathApiDfe, data: body });
}

async function put(pathApiDfe, body) {
    const response = await req({ method: 'put', pathApiDfe: pathApiDfe, data: body });
    return await response.data;
}

// return body only ok
async function get(pathApiDfe) {
    const response = await req({ method: 'get', pathApiDfe: pathApiDfe });
    return await response.data;
}

async function del(pathApiDfe) {
    return await req({ method: 'delete', pathApiDfe: pathApiDfe });
}

/////////////////////////////////////////////////////////////////////////////////
async function req({ method, pathApiDfe, data }) {
    axios.defaults.headers.common['Authorization'] = 'Basic c3Vwb3J0ZUBhZ3JhcmUuY29tLmJyOipBZ3IxOCpf';
    
    let url = pathApiDfe.argsApiDfe === undefined ? pathApiDfe.base + '/' + pathApiDfe.pathApiDfe : pathApiDfe.base + '/' + pathApiDfe.pathApiDfe + pathApiDfe.argsApiDfe;

    var response = await axios({
        method: method,
        url: url,
        data: data
    });
    return response;
}

// req file
async function reqFile({ method, pathApiDfe, data }) {
    axios.defaults.headers.common['Authorization'] = 'Basic c3Vwb3J0ZUBhZ3JhcmUuY29tLmJyOipBZ3IxOCpf';

    let url = pathApiDfe.argsApiDfe === undefined ? pathApiDfe.base + '/' + pathApiDfe.pathApiDfe : pathApiDfe.base + '/' + pathApiDfe.pathApiDfe + pathApiDfe.argsApiDfe;

    var response = await axios({
        method: method,
        url: url,
        headers: { "Content-Type": `multipart/form-data; boundary=${data._boundary}` },
        data: data
    });
    return response;
}
/////////////////////////////////////////////////////////////////////////////////