import { takeLatest, put, call } from 'redux-saga/effects';
import { history } from '@commons';
import { listAllTalhaoAtivo } from '@handler/common/talhaoCadastro/service';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  safraCadastro,
  NEW_SAFRA_CADASTRO,
  NEW_SAFRA_CADASTRO_PENDING,
  NEW_SAFRA_CADASTRO_SUCCESS,
  NEW_SAFRA_CADASTRO_FAILURE
} from '@handler';

import { AlertError } from '@components/common/AlertToast'

function* sagaNewSafraCadastro(action) {
  yield put({ type: NEW_SAFRA_CADASTRO_PENDING });

  try {
    const talhoes = yield call(listAllTalhaoAtivo, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id);

    let safraTalhaoList = []
    talhoes.content.forEach(
      (talhao) => {
        safraTalhaoList = safraTalhaoList.concat({
          talhao: talhao,
          ativo: false,
          area: talhao.area
        })
      });
    yield put({
      type: NEW_SAFRA_CADASTRO_SUCCESS, registro: {
        ...safraCadastro.newRegistro,
        safraTalhaoList
      }
    });
  } catch (error) {
    yield put({ type: NEW_SAFRA_CADASTRO_FAILURE })

    AlertError('Falha ao gerar novo cadastro! Tente novamente...');

    history.push('/safraCadastro');
  }
}

export default function* watchNewSafraCadastro() {
  yield takeLatest(NEW_SAFRA_CADASTRO, sagaNewSafraCadastro)
}
