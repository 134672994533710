import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createEmitenteCadastro, updateEmitenteCadastro } from '../service';
import {
  CREATE_EMITENTE_CADASTRO,
  CREATE_EMITENTE_CADASTRO_PENDING,
  CREATE_EMITENTE_CADASTRO_SUCCESS,
  CREATE_EMITENTE_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

function* sagaCreateEmitenteCadastro(action) {
  yield put({ type: CREATE_EMITENTE_CADASTRO_PENDING })

  try {
    if (action.data.tipoPessoa === 'FISICA') {
      action.data.indicadorInscrEstadual = 'NAOCONTRIBUINTE';
    } else {
      if (action.data.indicadorInscrEstadual === null || action.data.indicadorInscrEstadual === undefined || action.data.indicadorInscrEstadual === '') {
        action.data.indicadorInscrEstadual = 'CONTRIBUINTEISENTO';
      }
    }

    delete action.data.cidadeUf;
    if (action.data.certificadoDigital.certificadoCarregado) {
      if (action.data.certificadoDigital.senhaCertificadoTemp == '') {
        delete action.data.certificadoDigital.senhaCertificadoTemp;
      }
    } else {
      action.data.certificadoDigital.dataValidade = null;
      action.data.certificadoDigital.certificado = null;
      action.data.certificadoDigital.nomeArquivo = '';
      action.data.certificadoDigital.senhaCertificadoTemp = '';
      action.data.cienteBaixaNotas = false;
    }

    if (action.data.id === undefined || action.data.id === null) {
      yield call(createEmitenteCadastro, {
        ...action.data
      });
    } else {
      yield call(updateEmitenteCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_EMITENTE_CADASTRO_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/fiscal/emitente');
    }

    AlertSuccess('Registro salvo com sucesso!');
  } catch (error) {
    yield put({ type: CREATE_EMITENTE_CADASTRO_FAILURE, registro: action.data })

    if (error.response.status == 403) {
      AlertWarning('Sua licença atingiu o número máximo de emitentes cadastrados. Para contratar mais emitentes, contate o comercial da plataforma.');
    } else {
      AlertError('Falha ao salvar o registro! Tente novamente...');
    }
  }

}

export default function* watchCreateEmitenteCadastro() {
  yield takeLatest(CREATE_EMITENTE_CADASTRO, sagaCreateEmitenteCadastro)
}
