


import watchNewTransportadoraCadastro from './saga/new';
import watchFindTransportadoraCadastro from './saga/find';
import watchCreateTransportadoraCadastro from './saga/create';
import watchListAllTransportadoraCadastro from './saga/listAll';
import watchListTransportadoraCadastro from './saga/listTable';
import watchDeleteTransportadoraCadastro from './saga/delete';
import watchCancelTransportadoraCadastroRapido from './saga/cancel';

export const transportadoraCadastroSaga = [
    watchNewTransportadoraCadastro(),
    watchFindTransportadoraCadastro(),
    watchCreateTransportadoraCadastro(),
    watchListAllTransportadoraCadastro(),
    watchListTransportadoraCadastro(),
    watchDeleteTransportadoraCadastro(),
    watchCancelTransportadoraCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
