


import watchNewPluviometroDigital from './saga/new';
import watchFindPluviometroDigital from './saga/find';
import watchCreatePluviometroDigital from './saga/create';
import watchListPluviometroDigital from './saga/listTable';
import watchDeletePluviometroDigital from './saga/delete';

export const pluviometroDigitalSaga = [
    watchNewPluviometroDigital(),
    watchFindPluviometroDigital(),
    watchCreatePluviometroDigital(),
    watchListPluviometroDigital(),
    watchDeletePluviometroDigital(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
