import watchNewCampoCadastro from './saga/new';
import watchFindCampoCadastro from './saga/find';
import watchCreateCampoCadastro from './saga/create';
import watchListCampoCadastro from './saga/listTable';
import watchDeleteCampoCadastro from './saga/delete';
import watchCancelCampoCadastroRapido from './saga/cancel';

export const campoCadastroSaga = [
    watchNewCampoCadastro(),
    watchFindCampoCadastro(),
    watchCreateCampoCadastro(),
    watchListCampoCadastro(),
    watchDeleteCampoCadastro(),
    watchCancelCampoCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
