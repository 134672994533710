import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    NEW_FILTER_FLUXO_CAIXA_PENDING,
    NEW_FILTER_FLUXO_CAIXA_SUCCESS,
    NEW_FILTER_FLUXO_CAIXA_FAILURE,
    NEW_FILTER_FLUXO_CAIXA_SUCCESS_PDF,
    NEW_FILTER_GRAFICO_FLUXO_CAIXA_PENDING,
    NEW_FILTER_GRAFICO_FLUXO_CAIXA_SUCCESS,
    NEW_FILTER_GRAFICO_FLUXO_CAIXA_FAILURE,
} from './actionTypes';


const initialState = {
    list: null,
    loading: false,
    loadingGrafico: false,
    loadingTotais: false,
    totais: {
        saldoInicial: 0.0,
        totalEntradas: 0.0,
        totalImplantacao: 0.0,
        totalSaidas: 0.0,
        totalTransferenciaEntrada: 0.0,
        totalTransferenciaSaida: 0.0,
        saldoFinal: 0.0,
    },
    grafico: [
        ['Período', 'Total Implantação Saldo Inicial', 'Total Receitas', 'Total Despesas', 'Total Transf. Entrada', 'Total Transf. Saída', 'Saldo Final'],
    ],
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: null },
}

function fluxoCaixaReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_FILTER_FLUXO_CAIXA_PENDING:
            return {
                ...state,
                loading: true,
            }
        case NEW_FILTER_GRAFICO_FLUXO_CAIXA_PENDING:
            return {
                ...state,
                loadingGrafico: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_FLUXO_CAIXA_SUCCESS: {
            return {
                ...state,
                list: action.list,
                totais: action.totais,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_FLUXO_CAIXA_FAILURE: {
            return {
                ...state,
                loading: false,
                totais: {
                    saldoInicial: 0.0,
                    totalEntradas: 0.0,
                    totalImplantacao: 0.0,
                    totalSaidas: 0.0,
                    totalTransferenciaEntrada: 0.0,
                    totalTransferenciaSaida: 0.0,
                    saldoFinal: 0.0,
                },
                list: null
            }
        }
        case NEW_FILTER_FLUXO_CAIXA_SUCCESS_PDF:
            return {
                ...state,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_GRAFICO_FLUXO_CAIXA_SUCCESS: {
            return {
                ...state,
                grafico: action.grafico,
                loadingGrafico: false
            }
        }
        case NEW_FILTER_GRAFICO_FLUXO_CAIXA_FAILURE: {
            return {
                ...state,
                grafico: [
                    ['Período', 'Total Implantação Saldo Inicial', 'Total Receitas', 'Total Despesas', 'Total Transf. Entrada', 'Total Transf. Saída', 'Saldo Final'],
                ],
                loadingGrafico: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { fluxoCaixaReducer };