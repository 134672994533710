import * as Yup from 'yup';

const newRegistro = {
    ativo: true,
    descricao: '',
    nomeBanco: '',
    limite: 0,
    agencia: {
        numero: '',
        digito: ''
    },
    contaCorrente: {
        numero: '',
        digito: ''
    },
    saldoInicialImplantado: false,
    saldoInicial: {
        data: '',
        valor: 0
    },
    configBoletoBool: false
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(100, 'Campo deve ter menos do que 100 caracteres')
        .required('Este campo é obrigatório'),
    nomeBanco: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(100, 'Campo deve ter menos do que 100 caracteres')
        .required('Este campo é obrigatório'),
    limite: Yup.number()
        .required('Este campo é obrigatório'),
    saldoInicial: Yup.object().when('saldoInicialImplantado', {
        is: (saldoInicialImplantado) => saldoInicialImplantado === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                valor: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .positive('Por Favor, informe um valor maior que zero')
                    .transform(value => (isNaN(value) ? 0.0 : value))
                    .required("Este campo é obrigatório"),
                data: Yup.date()
                    .typeError('Este campo é obrigatório')
                    .required('Este campo é obrigatório'),
            })
    }).nullable(),
    agencia: Yup.object()
        .shape({
            numero: Yup.string()
                .max(10, 'Campo deve ter menos do que 10 caracteres'),
            digito: Yup.string()
                .max(5, 'Campo deve ter menos do que 5 caracteres'),
        }),
    contaCorrente: Yup.object()
        .shape({
            numero: Yup.string()
                .max(10, 'Campo deve ter menos do que 10 caracteres'),
            digito: Yup.string()
                .max(5, 'Campo deve ter menos do que 5 caracteres'),
        }),
});

export const contaBancoCadastro = {
    newRegistro,
    validation
}