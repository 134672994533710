import { takeLatest, put, call } from 'redux-saga/effects';

import { findPerfilUsuarioWhiteLabel } from '../service';
import {
  FIND_PERFIL_USUARIO_WHITE_LABEL,
  FIND_PERFIL_USUARIO_WHITE_LABEL_PENDING,
  FIND_PERFIL_USUARIO_WHITE_LABEL_SUCCESS,
  FIND_PERFIL_USUARIO_WHITE_LABEL_FAILURE
} from '../actionTypes';
import { getJsonPerfilWl } from '../../../common/perfil/service';

function* sagaFindPerfilUsuarioWhiteLabel(action) {
  yield put({ type: FIND_PERFIL_USUARIO_WHITE_LABEL_PENDING, id: action.id })

  try {
    var registro = yield call(findPerfilUsuarioWhiteLabel, action.id);

    registro = getJsonPerfilWl(registro);

    yield put({
      type: FIND_PERFIL_USUARIO_WHITE_LABEL_SUCCESS, registro: {
        ...registro,
      }
    })
  } catch (error) {
    yield put({ type: FIND_PERFIL_USUARIO_WHITE_LABEL_FAILURE })
  }
}

export default function* watchFindPerfilUsuarioWhiteLabel() {
  yield takeLatest(FIND_PERFIL_USUARIO_WHITE_LABEL, sagaFindPerfilUsuarioWhiteLabel)
}
