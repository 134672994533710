


import watchNewImplantacaoAnimal from './saga/new';
import watchFindImplantacaoAnimal from './saga/find';
import watchCreateImplantacaoAnimal from './saga/create';
import watchListAllImplantacaoAnimal from './saga/listAll';
import watchListImplantacaoAnimal from './saga/listTable';
import watchDeleteImplantacaoAnimal from './saga/delete';

export const implantacaoAnimalSaga = [
    watchNewImplantacaoAnimal(),
    watchFindImplantacaoAnimal(),
    watchCreateImplantacaoAnimal(),
    watchListAllImplantacaoAnimal(),
    watchListImplantacaoAnimal(),
    watchDeleteImplantacaoAnimal(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
