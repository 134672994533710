import { takeLatest, put, call } from 'redux-saga/effects';

import {
  produtoInsumoVincularFornecedor,
  NEW_PRODUTO_INSUMO_VINCULAR_FORNECEDOR,
  NEW_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_SUCCESS,
} from '@handler';

function* sagaNewProdutoInsumoVincularFornecedor(action) {

  yield put({
    type: NEW_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_SUCCESS, 
    registroVincularFornecedor: {
      ...produtoInsumoVincularFornecedor.newRegistroVincularFornecedor,
    },
  });
}

export default function* watchNewProdutoInsumoVincularFornecedor() {
  yield takeLatest(NEW_PRODUTO_INSUMO_VINCULAR_FORNECEDOR, sagaNewProdutoInsumoVincularFornecedor)
}
