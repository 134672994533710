import * as Yup from 'yup';

const newRegistro = {
    status: true,
    identificador: '',
    tipoIdentificador: 'COLHEITA',
};

const validation = Yup.object().shape({
    identificador: Yup.string()
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    tipoIdentificador: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
});

export const identificadorCadastro = {
    newRegistro,
    validation
}