import { takeLatest, put, call } from 'redux-saga/effects';

import { findTransferenciaEstoque } from '../service';
import {
  FIND_TRANSFERENCIA_ESTOQUE,
  FIND_TRANSFERENCIA_ESTOQUE_PENDING,
  FIND_TRANSFERENCIA_ESTOQUE_SUCCESS,
  FIND_TRANSFERENCIA_ESTOQUE_FAILURE
} from '../actionTypes';

function* sagaFindTransferenciaEstoque(action) {
  yield put({ type: FIND_TRANSFERENCIA_ESTOQUE_PENDING, id: action.id })

  try {

    const registro = yield call(findTransferenciaEstoque, action.id);

    yield put({ type: FIND_TRANSFERENCIA_ESTOQUE_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_TRANSFERENCIA_ESTOQUE_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindTransferenciaEstoque() {
  yield takeLatest(FIND_TRANSFERENCIA_ESTOQUE, sagaFindTransferenciaEstoque)
}
