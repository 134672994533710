import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/config/geral' };
/////////////////////////////////////////////////////////////////////////////////

export function updateConfigGeral(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function findConfigGeral() {
  return httpService.get({ ...args, args: `/atual` });
}
