import { takeLatest, put, call } from 'redux-saga/effects';

import { path, history, storage } from '@commons';

import { findFazendaCadastro } from '@handler/common/fazendaCadastro/service';
import { findAllSafrasByPropriedade } from '@handler/agricultura/safraCadastro/service';
import {
  CONFIG_SESSION,
  SELECT_FAZENDA,
  SELECT_START_SESSION_PENDING,
  SELECT_START_SESSION_SUCCESS,
  SELECT_START_SESSION_FAILURE
} from '../actionTypes';
import { findUsuarioByUserAuthId } from '@handler/common/usuario';
// import { findAllAnoPecuariosByPropriedade } from '@handler/pecuaria/anoPecuarioCadastro';

function* sagaSelectFazenda(action) {
  yield put({ type: SELECT_START_SESSION_PENDING })

  try {
    const configSessionAtual = JSON.parse(storage.get(CONFIG_SESSION));

    var configSession = {
      ...configSessionAtual,
      schemaSelecionado: action.contaAgrare.accountScheme,
      contaAgrare: action.contaAgrare
    }

    yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));

    const fazendaSelecionada = yield call(findFazendaCadastro, action.idFazenda);

    let userAuth = JSON.parse(storage.get(path.sessionUser));

    var usuarioFazenda = yield call(findUsuarioByUserAuthId, userAuth.id);

    // if (action.module == 'AGRICULTURA') {
    const safras = yield call(findAllSafrasByPropriedade, fazendaSelecionada.id);

    let safraAtiva = null;

    if (safras.content.length === 1) {
      safraAtiva = safras.content[0]
    }

    configSession = {
      ...configSession,
      fazendaSelecionada,
      safras: safras.content,
      safraAtiva,
      // moduloAtivo: action.module
      moduloAtivo: 'AGRICULTURA',
      perfilUser: usuarioFazenda.perfilPermissao
    }

    yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));

    // } else if (action.module == 'PECUARIA') {
    //   const anosPecuario = yield call(findAllAnoPecuariosByPropriedade, fazendaSelecionada.id);

    //   let anoPecuarioAtivo = null;

    //   if (anosPecuario.content.length === 1) {
    //     anoPecuarioAtivo = anosPecuario.content[0]
    //   }

    //   const configSession = {
    //     ...configSessionAtual,
    //     fazendaSelecionada,
    //     anosPecuario: anosPecuario.content,
    //     anoPecuarioAtivo,
    //     moduloAtivo: action.module
    //   }
    //   yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));
    // }

    yield put({ type: SELECT_START_SESSION_SUCCESS })

    history.push('/dashboard');
    if (action.refreshPage === true) {
      window.location.reload();
    }
  } catch (error) {
    yield put({ type: SELECT_START_SESSION_FAILURE })

  }
}

export default function* watchSelectFazenda() {
  yield takeLatest(SELECT_FAZENDA, sagaSelectFazenda)
}
