import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    localidade: "",
    latitude: null,
    longitude: null,
    areaTotal: 0,
    unidadeArea: {
        "id": 1,
        "descricao": "Hectares",
        "sigla": "ha",
        "conversaoHa": 1
    },
    tipoAreaPropriedade: 'AREA_PROPRIA'
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    localidade: Yup.string()
        .required('Este campo é obrigatório'),
    areaTotal: Yup.number()
        .positive('Por Favor, informe um valor maior que zero')
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
    unidadeArea: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    tipoAreaPropriedade: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
});

export const propriedadeCadastro = {
    newRegistro,
    validation
}