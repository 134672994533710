import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import { storage } from '@commons';

import {
  CONFIG_SESSION,
  transferenciaEstoqueProducao,
  NEW_TRANSFERENCIA_ESTOQUE_PRODUCAO,
  NEW_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS,
} from '@handler';

function* sagaNewTransferenciaEstoqueProducao(action) {

  yield put({ type: NEW_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS, registro: 
    {
      ...transferenciaEstoqueProducao.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      cultura: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura,
      culturaFormaLancColheita: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.formaLancColheita,
      culturaUnidadeColheita: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.unidadeColheita,
      culturaUnidadeColheitaPossuiConversao: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.unidadeColheitaPossuiConversao,
      culturaTipoEmbalagem: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.tipoEmbalagem,
      culturaQtdEmbalagem: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.qtdEmbalagem,
      culturaUnidadeEmbalagem: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.unidadeEmbalagem,
    } });
}

export default function* watchNewTransferenciaEstoqueProducao() {
  yield takeLatest(NEW_TRANSFERENCIA_ESTOQUE_PRODUCAO, sagaNewTransferenciaEstoqueProducao)
}
