import {
    LIST_RELATORIO_ESTOQUE_ATUAL_PENDING,
    LIST_RELATORIO_ESTOQUE_ATUAL_SUCCESS,
    LIST_RELATORIO_ESTOQUE_ATUAL_FAILURE,
    LIST_RELATORIO_MOVIMENTACOES_PRODUTO_PENDING,
    LIST_RELATORIO_MOVIMENTACOES_PRODUTO_SUCCESS,
    LIST_RELATORIO_MOVIMENTACOES_PRODUTO_FAILURE,
    LIST_RELATORIO_ESTOQUE_ATUAL_PDF_SUCCESS
} from './actionTypes';


const initialState = { estoqueAtual: null, loading: false }


function relatorioEstoqueAtualReducer(state = initialState, action) {

    switch (action.type) {
        case LIST_RELATORIO_ESTOQUE_ATUAL_PENDING:
            return {
                ...state,
                loading: true,
            }
        case LIST_RELATORIO_MOVIMENTACOES_PRODUTO_PENDING:
            return {
                ...state,
                loadingModal: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_RELATORIO_ESTOQUE_ATUAL_SUCCESS:
            return {
                ...state,
                estoqueAtual: action.estoqueAtual,
                loading: false
            }
            case LIST_RELATORIO_ESTOQUE_ATUAL_PDF_SUCCESS:
                return {
                    ...state,
                    loading: false
                }
        case LIST_RELATORIO_ESTOQUE_ATUAL_FAILURE:
            return {
                ...state,
                estoqueAtual: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_RELATORIO_MOVIMENTACOES_PRODUTO_SUCCESS:
            return {
                ...state,
                movimentacoesEstoqueProduto: action.movimentacoesEstoqueProduto,
                modalDescProduto: action.descProduto,
                modalEstoque: action.estoque,
                modalUnidade: action.unidade,
                loadingModal: false
            }
        case LIST_RELATORIO_MOVIMENTACOES_PRODUTO_FAILURE:
            return {
                ...state,
                movimentacoesEstoqueProduto: null,
                modalDescProduto: '',
                modalEstoque: '',
                modalUnidade: '',
                loadingModal: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { relatorioEstoqueAtualReducer };