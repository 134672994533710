


import watchNewDespesaDiretaCadastro from './saga/new';
import watchFindDespesaDiretaCadastro from './saga/find';
import watchCreateDespesaDiretaCadastro from './saga/create';
import watchListDespesaDiretaCadastro from './saga/listTable';
import watchDeleteDespesaDiretaCadastro from './saga/delete';
import watchCancelDespesaDiretaCadastroRapido from './saga/cancel';

export const despesaDiretaCadastroSaga = [
    watchNewDespesaDiretaCadastro(),
    watchFindDespesaDiretaCadastro(),
    watchCreateDespesaDiretaCadastro(),
    watchListDespesaDiretaCadastro(),
    watchDeleteDespesaDiretaCadastro(),
    watchCancelDespesaDiretaCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
