import { takeLatest, put, call } from 'redux-saga/effects';

import { listVendaAnimal } from '../service';
import {
  TABLE_VENDA_ANIMAL,
  TABLE_VENDA_ANIMAL_PENDING,
  TABLE_VENDA_ANIMAL_SUCCESS,
  TABLE_VENDA_ANIMAL_FAILURE
} from '../actionTypes';

function* sagaListVendaAnimal(action) {
  yield put({ type: TABLE_VENDA_ANIMAL_PENDING })

  try {
    const table = yield call(listVendaAnimal, action.args)
    yield put({ type: TABLE_VENDA_ANIMAL_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_VENDA_ANIMAL_FAILURE })
  }
}

export default function* watchListVendaAnimal() {
  yield takeLatest(TABLE_VENDA_ANIMAL, sagaListVendaAnimal)
}