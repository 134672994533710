import { takeLatest, put, call } from 'redux-saga/effects';

import swal from 'sweetalert';
import {
  UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER,
  UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_PENDING,
  UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_SUCCESS,
  UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_FAILURE
} from '../actionTypes';
import { AlertError } from '@components/common/AlertToast'
import { updateNumeroDoc } from '../service';

function* sagaUpdateNroDoc(action) {
  yield put({ type: UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_PENDING })

  try {
    let urlTipoConta = '';
    if (action.urlTipoConta === 'pagar') {
      urlTipoConta = '/contas/pagar';
    } else {
      urlTipoConta = '/contas/receber';
    }

    yield call(updateNumeroDoc, urlTipoConta, action.idConta, {
      numeroDoc: action.data.numeroDoc
    });

    yield put({ type: UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_SUCCESS })

    swal({
      title: 'Número Documento atualizado com sucesso!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      window.location.reload();
    });

  } catch (error) {
    yield put({ type: UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_FAILURE })

    AlertError('Falha ao atualizar o número do documento. Tente novamente.');
  }
}

export default function* watchUpdateNroDoc() {
  yield takeLatest(UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER, sagaUpdateNroDoc)
}
