import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_MANEJO_EXECUCAO_CADASTRO_RAPIDO,
    CANCEL_MANEJO_EXECUCAO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelManejoExecucaoCadastroRapido() {
  yield put({ type: CANCEL_MANEJO_EXECUCAO_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelManejoExecucaoCadastroRapido() {
  yield takeLatest(CANCEL_MANEJO_EXECUCAO_CADASTRO_RAPIDO, sagaCancelManejoExecucaoCadastroRapido)
}