


import watchNewUsuario from './saga/new';
import watchFindUsuario from './saga/find';
import watchFindUsuarioResumed from './saga/findResumed';
import watchCreateUsuario from './saga/create';
import watchUpdateMeuPerfil from './saga/updateMeuPerfil';
import watchListUsuario from './saga/list';
import watchDeleteUsuario from './saga/delete';
import watchAtivoInativoUsuario from './saga/activeInactive';
import watchUpdatePerfilPermissaoUsuario from './saga/updatePerfilPermissao';

export const usuarioSaga = [
    watchNewUsuario(),
    watchFindUsuario(),
    watchFindUsuarioResumed(),
    watchCreateUsuario(),
    watchUpdateMeuPerfil(),
    watchListUsuario(),
    watchDeleteUsuario(),
    watchAtivoInativoUsuario(),
    watchUpdatePerfilPermissaoUsuario(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
