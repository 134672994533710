import React from 'react';

import {
    Col,
    FormGroup,
} from 'reactstrap';

import { Field } from 'formik';

import { InfoTooltip } from '@components/form';

import { CheckboxWithLabel } from 'formik-material-ui';

import './Check.css'

export const Check = (props) => {

    const idComponent = props.id === undefined ? 'id_' + props.name : 'id_' + props.id;
    return (
        <Col md={props.col}>
            <FormGroup>
                <div className='form-control' style={{ border: 'none', marginTop: '20px' }}>
                    <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name={props.name}
                        Label={{ label: props.label }}
                        onClick={() => props.onClick()}
                        disabled={props.disabled === undefined ? false : props.disabled}
                    />
                    {props.showInfo ? <InfoTooltip id={idComponent} title={props.infoTitle} msg={props.infoMsg} /> : <React.Fragment />}
                </div>
            </FormGroup>
        </Col>
    )

};

Check.defaultProps = {
    col: 12,
    showInfo: false,
    infoTitle: '',
    infoMsg: '',
    onClick: () => { }
};