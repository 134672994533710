import { takeLatest, put, call } from 'redux-saga/effects';

import { findAnimalEspecieCadastro } from '../service';
import {
  FIND_ANIMAL_ESPECIE_CADASTRO,
  FIND_ANIMAL_ESPECIE_CADASTRO_PENDING,
  FIND_ANIMAL_ESPECIE_CADASTRO_SUCCESS,
  FIND_ANIMAL_ESPECIE_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindAnimalEspecieCadastro(action) {
  yield put({ type: FIND_ANIMAL_ESPECIE_CADASTRO_PENDING, id: action.id })

  try {
    const registro = yield call(findAnimalEspecieCadastro, action.id);
    yield put({ type: FIND_ANIMAL_ESPECIE_CADASTRO_SUCCESS, registro: registro })
  } catch (error) {
    yield put({ type: FIND_ANIMAL_ESPECIE_CADASTRO_FAILURE })
  }
}

export default function* watchFindAnimalEspecieCadastro() {
  yield takeLatest(FIND_ANIMAL_ESPECIE_CADASTRO, sagaFindAnimalEspecieCadastro)
}
