import watchNewBaixaManualEstoque from './saga/new';
import watchFindBaixaManualEstoque from './saga/find';
import watchCreateBaixaManualEstoque from './saga/create';
import watchListAllBaixaManualEstoque from './saga/listAll';
import watchListBaixaManualEstoque from './saga/listTable';
import watchDeleteBaixaManualEstoque from './saga/delete';

export const baixarManualEstoqueSaga = [
    watchNewBaixaManualEstoque(),
    watchFindBaixaManualEstoque(),
    watchCreateBaixaManualEstoque(),
    watchListAllBaixaManualEstoque(),
    watchListBaixaManualEstoque(),
    watchDeleteBaixaManualEstoque(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
