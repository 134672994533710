import { takeLatest, put } from 'redux-saga/effects';

import {
  CANCEL_FORNECEDOR_CADASTRO_RAPIDO,
  CANCEL_FORNECEDOR_CADASTRO_RAPIDO_SUCCESS,
} from '../actionTypes';

function* sagaCancelFornecedorCadastroRapido() {
  yield put({ type: CANCEL_FORNECEDOR_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelFornecedorCadastroRapido() {
  yield takeLatest(CANCEL_FORNECEDOR_CADASTRO_RAPIDO, sagaCancelFornecedorCadastroRapido)
}