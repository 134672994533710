


import watchNewContaBancoCadastro from './saga/new';
import watchFindContaBancoCadastro from './saga/find';
import watchCreateContaBancoCadastro from './saga/create';
import watchListContaBancoCadastro from './saga/listTable';
import watchDeleteContaBancoCadastro from './saga/delete';
import watchCancelContaBancoCadastroRapido from './saga/cancel';

export const contaBancoCadastroSaga = [
    watchNewContaBancoCadastro(),
    watchFindContaBancoCadastro(),
    watchCreateContaBancoCadastro(),
    watchListContaBancoCadastro(),
    watchDeleteContaBancoCadastro(),
    watchCancelContaBancoCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
