export const NEW_VENDA_PRODUCAO_AGRICOLA = 'NEW_VENDA_PRODUCAO_AGRICOLA'
export const NEW_VENDA_PRODUCAO_AGRICOLA_SUCCESS = 'NEW_VENDA_PRODUCAO_AGRICOLA_SUCCESS'

export const CREATE_VENDA_PRODUCAO_AGRICOLA = 'CREATE_VENDA_PRODUCAO_AGRICOLA'
export const CREATE_VENDA_PRODUCAO_AGRICOLA_PENDING = 'CREATE_VENDA_PRODUCAO_AGRICOLA_PENDING'
export const CREATE_VENDA_PRODUCAO_AGRICOLA_SUCCESS = 'CREATE_VENDA_PRODUCAO_AGRICOLA_SUCCESS'
export const CREATE_VENDA_PRODUCAO_AGRICOLA_FAILURE = 'CREATE_VENDA_PRODUCAO_AGRICOLA_FAILURE'

export const TABLE_VENDA_PRODUCAO_AGRICOLA = 'TABLE_VENDA_PRODUCAO_AGRICOLA'
export const TABLE_VENDA_PRODUCAO_AGRICOLA_PENDING = 'TABLE_VENDA_PRODUCAO_AGRICOLA_PENDING'
export const TABLE_VENDA_PRODUCAO_AGRICOLA_SUCCESS = 'TABLE_VENDA_PRODUCAO_AGRICOLA_SUCCESS'
export const TABLE_VENDA_PRODUCAO_AGRICOLA_FAILURE = 'TABLE_VENDA_PRODUCAO_AGRICOLA_FAILURE'

export const LIST_VENDA_PRODUCAO_AGRICOLA = 'LIST_VENDA_PRODUCAO_AGRICOLA'
export const LIST_VENDA_PRODUCAO_AGRICOLA_PENDING = 'LIST_VENDA_PRODUCAO_AGRICOLA_PENDING'
export const LIST_VENDA_PRODUCAO_AGRICOLA_SUCCESS = 'LIST_VENDA_PRODUCAO_AGRICOLA_SUCCESS'
export const LIST_VENDA_PRODUCAO_AGRICOLA_FAILURE = 'LIST_VENDA_PRODUCAO_AGRICOLA_FAILURE'

export const FIND_VENDA_PRODUCAO_AGRICOLA = 'FIND_VENDA_PRODUCAO_AGRICOLA'
export const FIND_VENDA_PRODUCAO_AGRICOLA_PENDING = 'FIND_VENDA_PRODUCAO_AGRICOLA_PENDING'
export const FIND_VENDA_PRODUCAO_AGRICOLA_SUCCESS = 'FIND_VENDA_PRODUCAO_AGRICOLA_SUCCESS'
export const FIND_VENDA_PRODUCAO_AGRICOLA_FAILURE = 'FIND_VENDA_PRODUCAO_AGRICOLA_FAILURE'

export const DELETE_VENDA_PRODUCAO_AGRICOLA = 'DELETE_VENDA_PRODUCAO_AGRICOLA'
export const DELETE_VENDA_PRODUCAO_AGRICOLA_PENDING = 'DELETE_VENDA_PRODUCAO_AGRICOLA_PENDING'
export const DELETE_VENDA_PRODUCAO_AGRICOLA_SUCCESS = 'DELETE_VENDA_PRODUCAO_AGRICOLA_SUCCESS'
export const DELETE_VENDA_PRODUCAO_AGRICOLA_FAILURE = 'DELETE_VENDA_PRODUCAO_AGRICOLA_FAILURE'

export const LIST_VENDA_PRODUCAO_AGRICOLA_SUCCESS_PDF = 'LIST_VENDA_PRODUCAO_AGRICOLA_SUCCESS_PDF'