
export const NEW_PLANTIO_MANEJO = 'NEW_PLANTIO_MANEJO'
export const NEW_PLANTIO_MANEJO_SUCCESS = 'NEW_PLANTIO_MANEJO_SUCCESS'

export const NEW_PLANTIO_MANEJO_BY_PLANEJAMENTO = 'NEW_PLANTIO_MANEJO_BY_PLANEJAMENTO'
export const NEW_PLANTIO_MANEJO_BY_PLANEJAMENTO_PENDING = 'NEW_PLANTIO_MANEJO_BY_PLANEJAMENTO_PENDING'

export const NEW_PLANTIO_MANEJO_CUSTO_DIRETO = 'NEW_PLANTIO_MANEJO_CUSTO_DIRETO'
export const NEW_PLANTIO_MANEJO_CUSTO_DIRETO_SUCCESS = 'NEW_PLANTIO_MANEJO_CUSTO_DIRETO_SUCCESS'

export const CREATE_PLANTIO_MANEJO = 'CREATE_PLANTIO_MANEJO'
export const CREATE_PLANTIO_MANEJO_PENDING = 'CREATE_PLANTIO_MANEJO_PENDING'
export const CREATE_PLANTIO_MANEJO_SUCCESS = 'CREATE_PLANTIO_MANEJO_SUCCESS'
export const CREATE_PLANTIO_MANEJO_FAILURE = 'CREATE_PLANTIO_MANEJO_FAILURE'

export const CREATE_PLANTIO_MANEJO_CUSTO_DIRETO = 'CREATE_PLANTIO_MANEJO_CUSTO_DIRETO'

export const TABLE_PLANTIO_MANEJO = 'TABLE_PLANTIO_MANEJO'
export const TABLE_PLANTIO_MANEJO_PENDING = 'TABLE_PLANTIO_MANEJO_PENDING'
export const TABLE_PLANTIO_MANEJO_SUCCESS = 'TABLE_PLANTIO_MANEJO_SUCCESS'
export const TABLE_PLANTIO_MANEJO_FAILURE = 'TABLE_PLANTIO_MANEJO_FAILURE'

export const LIST_PLANTIO_MANEJO = 'LIST_PLANTIO_MANEJO'
export const LIST_PLANTIO_MANEJO_PENDING = 'LIST_PLANTIO_MANEJO_PENDING'
export const LIST_PLANTIO_MANEJO_SUCCESS = 'LIST_PLANTIO_MANEJO_SUCCESS'
export const LIST_PLANTIO_MANEJO_FAILURE = 'LIST_PLANTIO_MANEJO_FAILURE'

export const FIND_PLANTIO_MANEJO = 'FIND_PLANTIO_MANEJO'
export const FIND_PLANTIO_MANEJO_PENDING = 'FIND_PLANTIO_MANEJO_PENDING'
export const FIND_PLANTIO_MANEJO_SUCCESS = 'FIND_PLANTIO_MANEJO_SUCCESS'
export const FIND_PLANTIO_MANEJO_FAILURE = 'FIND_PLANTIO_MANEJO_FAILURE'

export const FIND_PLANTIO_MANEJO_CUSTO_DIRETO = 'FIND_PLANTIO_MANEJO_CUSTO_DIRETO'

export const DELETE_PLANTIO_MANEJO = 'DELETE_PLANTIO_MANEJO'
export const DELETE_PLANTIO_MANEJO_PENDING = 'DELETE_PLANTIO_MANEJO_PENDING'
export const DELETE_PLANTIO_MANEJO_SUCCESS = 'DELETE_PLANTIO_MANEJO_SUCCESS'
export const DELETE_PLANTIO_MANEJO_FAILURE = 'DELETE_PLANTIO_MANEJO_FAILURE'

export const DELETE_PLANTIO_MANEJO_CUSTO_DIRETO = 'DELETE_PLANTIO_MANEJO_CUSTO_DIRETO'

export const RELATORIO_ATIVIDADES_TALHAO_SINTETICO = 'RELATORIO_ATIVIDADES_TALHAO_SINTETICO'
export const RELATORIO_ATIVIDADES_TALHAO_ANALITICO = 'RELATORIO_ATIVIDADES_TALHAO_ANALITICO'

export const GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE = 'GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE'
export const GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_PENDING = 'GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_PENDING'
export const GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_SUCCESS = 'GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_SUCCESS'
export const GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_FAILURE = 'GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_FAILURE'

export const REPROCESSAR_ESTOQUE_AGRICULTURA = 'REPROCESSAR_ESTOQUE_AGRICULTURA'
export const REPROCESSAR_ESTOQUE_AGRICULTURA_PENDING = 'REPROCESSAR_ESTOQUE_AGRICULTURA_PENDING'
export const REPROCESSAR_ESTOQUE_AGRICULTURA_SUCCESS = 'REPROCESSAR_ESTOQUE_AGRICULTURA_SUCCESS'
export const REPROCESSAR_ESTOQUE_AGRICULTURA_FAILURE = 'REPROCESSAR_ESTOQUE_AGRICULTURA_FAILURE'

export const NEW_FILTER_PLANTIO_MANEJO = 'NEW_FILTER_PLANTIO_MANEJO'
export const NEW_FILTER_PLANTIO_MANEJO_PEDING = 'NEW_FILTER_PLANTIO_MANEJO_PEDING'
export const NEW_FILTER_PLANTIO_MANEJO_SUCCESS = 'NEW_FILTER_PLANTIO_MANEJO_SUCCESS'
export const NEW_FILTER_PLANTIO_MANEJO_FAILURE = 'NEW_FILTER_PLANTIO_MANEJO_FAILURE'

export const NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO = 'NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO'
export const NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO_PENDING = 'NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO_PENDING'
export const NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO_SUCCESS = 'NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO_FAILURE = 'NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO_FAILURE'

export const GET_TOTAIS_PLANTIO_MANEJO_PENDING = 'GET_TOTAIS_PLANTIO_MANEJO_PENDING'
export const GET_TOTAIS_PLANTIO_MANEJO_SUCCESS = 'GET_TOTAIS_PLANTIO_MANEJO_SUCCESS'
export const GET_TOTAIS_PLANTIO_MANEJO_FAILURE = 'GET_TOTAIS_PLANTIO_MANEJO_FAILURE'

export const GET_LOADING_PLANTIO_MANEJO_SUCCESS = 'GET_LOADING_PLANTIO_MANEJO_SUCCESS'
export const GET_LOADING_PLANTIO_MANEJO_PENDING = 'GET_LOADING_PLANTIO_MANEJO_PENDING'
