import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createImplantacaoEstoque, updateImplantacaoEstoque, entradaPodeSerEditadaExcluida } from '../service';
import {
  CREATE_IMPLANTACAO_ESTOQUE,
  CREATE_IMPLANTACAO_ESTOQUE_PENDING,
  CREATE_IMPLANTACAO_ESTOQUE_SUCCESS,
  CREATE_IMPLANTACAO_ESTOQUE_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaCreateImplantacaoEstoque(action) {
  yield put({ type: CREATE_IMPLANTACAO_ESTOQUE_PENDING })

  var processar = true;
  if (action.data.id !== undefined && action.data.id !== null) {
    processar = yield call(entradaPodeSerEditadaExcluida, action.data.id);
  }

  if (processar) {
    try {
      if (action.data.id === undefined || action.data.id === null) {
        yield call(createImplantacaoEstoque, formatJsonRemoveEmptyOrNull({
          ...action.data,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          }
        }));
      } else {
        yield call(updateImplantacaoEstoque,
          {
            ...action.data,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_IMPLANTACAO_ESTOQUE_SUCCESS, registro: action.data })

      history.push('/estoque/implantacao');

      AlertSuccess('Registro salvo com sucesso!');
    } catch (error) {
      yield put({ type: CREATE_IMPLANTACAO_ESTOQUE_FAILURE, registro: action.data })

      AlertError('Falha ao salvar o registro! Tente novamente...');
    }
  } else {
    yield put({ type: CREATE_IMPLANTACAO_ESTOQUE_FAILURE, registro: action.data })

    AlertWarning('Implantação não pode ser editada, pois já houve movimentação no estoque');
  }
}

export default function* watchCreateImplantacaoEstoque() {
  yield takeLatest(CREATE_IMPLANTACAO_ESTOQUE, sagaCreateImplantacaoEstoque)
}
