import { takeLatest, put } from 'redux-saga/effects';

import {
  planoContaCadastro,
  NEW_PLANO_CONTA_CADASTRO,
  NEW_PLANO_CONTA_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewPlanoContaCadastro(action) {

  yield put({ 
    type: NEW_PLANO_CONTA_CADASTRO_SUCCESS, 
    registro: planoContaCadastro.newRegistro,  
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewPlanoContaCadastro() {
  yield takeLatest(NEW_PLANO_CONTA_CADASTRO, sagaNewPlanoContaCadastro)
}
