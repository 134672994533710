


import watchNewGrupoContaAgrareWhiteLabel from './saga/new';
import watchFindGrupoContaAgrareWhiteLabel from './saga/find';
import watchCreateGrupoContaAgrareWhiteLabel from './saga/create';
import watchListGrupoContaAgrareWhiteLabel from './saga/listTable';
import watchDeleteGrupoContaAgrareWhiteLabel from './saga/delete';
import watchCancelGrupoContaAgrareWhiteLabelRapido from './saga/cancel';

export const grupoContaAgrareWhiteLabelSaga = [
    watchNewGrupoContaAgrareWhiteLabel(),
    watchFindGrupoContaAgrareWhiteLabel(),
    watchCreateGrupoContaAgrareWhiteLabel(),
    watchListGrupoContaAgrareWhiteLabel(),
    watchDeleteGrupoContaAgrareWhiteLabel(),
    watchCancelGrupoContaAgrareWhiteLabelRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
