import { takeLatest, put, call } from 'redux-saga/effects';
import moment from "moment";

import {
  plantioManejo,
  NEW_PLANTIO_MANEJO_BY_PLANEJAMENTO,
  NEW_PLANTIO_MANEJO_SUCCESS,
  importPlanejamento,
  NEW_PLANTIO_MANEJO_BY_PLANEJAMENTO_PENDING,
} from '@handler';

function* sagaNewPlantioManejoByPlanejamento(action) {
  yield put({ type: NEW_PLANTIO_MANEJO_BY_PLANEJAMENTO_PENDING })

  const planejamento = yield call(importPlanejamento, action.id);

  yield put({
    type: NEW_PLANTIO_MANEJO_SUCCESS, registro: {
      ...plantioManejo.newRegistro,
      ...planejamento,
      data: moment().startOf('day').format('YYYY-MM-DD'),
    }
  });

}

export default function* watchNewPlantioManejoByPlanejamento() {
  yield takeLatest(NEW_PLANTIO_MANEJO_BY_PLANEJAMENTO, sagaNewPlantioManejoByPlanejamento)
}
