import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/lancamentoContaBanco' };
/////////////////////////////////////////////////////////////////////////////////

export function createControleContaBanco(payload) {
  return httpService.post(args, payload);
}

export function updateControleContaBanco(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function findControleContaBanco(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deleteControleContaBanco(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function getLancamentos(idPropriedade, idContaBanco, datas, where) {
  return httpService.get({ ...args, args: `/extratoMovimentacoes?propriedade=${idPropriedade}&datas=${datas}&conta_id=${idContaBanco}${where}` });
}

export function getTotaisExtrato(idPropriedade, idContaBanco, datas, where) {
  return httpService.get({ ...args, args: `/totaisExtrato?propriedade=${idPropriedade}&datas=${datas}&conta_id=${idContaBanco}${where}` });
}

// transferencia
export function createControleContaBancoTransf(payload) {
  return httpService.post({...args, path: "/lancamentoContaBanco/transferencia"}, payload);
}

export function updateControleContaBancoTransf(payload) {
  const { id } = payload

  return httpService.put({ ...args, path: `/lancamentoContaBanco/transferencia/${id}` }, payload);
}

export function getIdTransfLancContaBanco(id) {
  return httpService.get({ ...args, path: `/lancamentoContaBanco/transferencia/getIdTransfLancContaBanco/${id}` });
}

export function findControleContaBancoTransf(id) {
  return httpService.get({ ...args, path: `/lancamentoContaBanco/transferencia/${id}` });
}

export function deleteControleContaBancoTransf(id) {
  return httpService.del({ ...args, path: `/lancamentoContaBanco/transferencia/${id}` });
}

export function getRelatorioControleBanco(idPropriedade, idContaBanco, datas, where) {
  return httpService.getPdf({ ...args, args: `/relatorioControleBanco/pdf?propriedade=${idPropriedade}&conta_id=${idContaBanco}&datas=${datas}${where}` });
}