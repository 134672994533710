import { takeLatest, put, call } from 'redux-saga/effects';

import { findContaReceber } from '../service';
import {
  FIND_CONTA_RECEBER,
  FIND_CONTA_RECEBER_PENDING,
  FIND_CONTA_RECEBER_SUCCESS,
  FIND_CONTA_RECEBER_FAILURE
} from '../actionTypes';

function* sagaFindContaReceber(action) {
  yield put({ type: FIND_CONTA_RECEBER_PENDING, id: action.id })

  try {

    const registro = yield call(findContaReceber, action.id);

    yield put({ type: FIND_CONTA_RECEBER_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_CONTA_RECEBER_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindContaReceber() {
  yield takeLatest(FIND_CONTA_RECEBER, sagaFindContaReceber)
}
