import { takeLatest, put } from 'redux-saga/effects';
import moment from "moment";

import { storage } from '@commons';

import {
  custoDireto,
  CONFIG_SESSION,
  CREATE_PLANTIO_MANEJO_PENDING,
  NEW_PLANTIO_MANEJO_CUSTO_DIRETO,
  NEW_PLANTIO_MANEJO_CUSTO_DIRETO_SUCCESS,
} from '@handler';

function* sagaNewPlantioManejoCustoDireto(action) {
  yield put({ type: CREATE_PLANTIO_MANEJO_PENDING })

  const talhoesSafra = yield JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.safraTalhaoList;

  let custoDiretoTalhao = [];

  for(var i=0; i< talhoesSafra.length; i++) {
    if (talhoesSafra[i].ativo){
      custoDiretoTalhao.push({
        talhao: talhoesSafra[i].talhao,
        ativo: false
      })
    }
  }

  custoDiretoTalhao.sort((a, b) => a.talhao.nome.localeCompare(b.talhao.nome));
  
  yield put({
    type: NEW_PLANTIO_MANEJO_CUSTO_DIRETO_SUCCESS, registro: {
      ...custoDireto.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      custoDiretoTalhao: custoDiretoTalhao
    }
  });

}

export default function* watchNewPlantioManejoCustoDireto() {
  yield takeLatest(NEW_PLANTIO_MANEJO_CUSTO_DIRETO, sagaNewPlantioManejoCustoDireto)
}
