import { path, httpService } from '@commons';

export function getTotaisIndicadorDashCorporate(user, params) {
    if (params !== undefined && params !== null && params !== '') {
        // Remova os parâmetros 'size' e 'page'
        params = params.replace(/&?size=\d+/g, '').replace(/&?page=\d+/g, '');

        // Se após a remoção, o primeiro caractere ainda for '&', remova-o
        if (params.startsWith('&')) {
            params = params.substring(1);
        }
    }
    var where = params ? `${params}&userAuth=${user.id}&clienteWhiteLabel_identificationCode=${user.identificationCodeWhiteLabel}`
                       : `?userAuth=${user.id}&clienteWhiteLabel_identificationCode=${user.identificationCodeWhiteLabel}`;

    return httpService.getApiManager(`/dashboardCorporate/totaisIndicadorDashCorporate${where}`, true);
}
