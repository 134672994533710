import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    roles: [],
    perfilPropriedades: [],
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres!')
        .max(100, 'Campo deve ter menos do que 100 caracteres!')
        .required('Este campo é obrigatório'),
    roles: Yup.array()
        .of(
            Yup.object()
        ),
    perfilPropriedades: Yup.array()
        .of(
            Yup.object()
        )
});

export const perfil = {
    newRegistro,
    validation
}