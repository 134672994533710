import {
    LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_PENDING,
    LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_SUCCESS,
    LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_FAILURE,
    LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_PDF_SUCCESS
} from './actionTypes';


const initialState = { estoqueProducaoPorPeriodo: null, loading: false }


function relatorioEstoqueProducaoPorPeriodoReducer(state = initialState, action) {

    switch (action.type) {
        case LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_SUCCESS:
            return {
                ...state,
                estoqueProducaoPorPeriodo: action.estoqueProducaoPorPeriodo,
                tipoRelatorio: action.tipoRelatorio,
                loading: false
            }
            case LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_PDF_SUCCESS:
                return {
                    ...state,
                    loading: false
                }
        case LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_FAILURE:
            return {
                ...state,
                estoqueProducaoPorPeriodo: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { relatorioEstoqueProducaoPorPeriodoReducer };