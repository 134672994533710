import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deleteUsuario } from '../service';
import {
  DELETE_USUARIO,
  DELETE_USUARIO_PENDING,
  DELETE_USUARIO_SUCCESS,
  DELETE_USUARIO_FAILURE
} from '../actionTypes';
import { AlertWarning } from '@components/common/AlertToast';

function* sagaDeleteUsuario(action) {
  yield put({ type: DELETE_USUARIO_PENDING, id: action.id })

  try {
    const a = yield call(deleteUsuario, action.id);

    if (a.status !== 204) {
      throw new Error('API delete request failed');
    }
    yield put({ type: DELETE_USUARIO_SUCCESS, removed: true });

    swal({
      title: 'Usuário Excluído!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });
  } catch (error) {
    yield put({ type: DELETE_USUARIO_FAILURE, removed: false });

    AlertWarning('Usuário não pode ser excluído pois já realizou movimentação no sistema. Para remover acesso ao sistema, você deve Inativar Usuário');
  }
}

export default function* watchDeleteUsuario() {
  yield takeLatest(DELETE_USUARIO, sagaDeleteUsuario)
}
