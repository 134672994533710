export const TOTAIS_DASHBOARD_PECUARIA = 'TOTAIS_DASHBOARD_PECUARIA'
export const TOTAIS_DASHBOARD_PECUARIA_PENDING = 'TOTAIS_DASHBOARD_PECUARIA_PENDING'
export const TOTAIS_DASHBOARD_PECUARIA_SUCCESS = 'TOTAIS_DASHBOARD_PECUARIA_SUCCESS'
export const TOTAIS_DASHBOARD_PECUARIA_FAILURE = 'TOTAIS_DASHBOARD_PECUARIA_FAILURE'

export const PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE = 'PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE'
export const PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_PENDING = 'PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_PENDING'
export const PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_SUCCESS = 'PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_SUCCESS'
export const PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_FAILURE = 'PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_FAILURE'

export const PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA = 'PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA'
export const PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_PENDING = 'PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_PENDING'
export const PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_SUCCESS = 'PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_SUCCESS'
export const PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_FAILURE = 'PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_FAILURE'

export const PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO = 'PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO'
export const PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_PENDING = 'PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_PENDING'
export const PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_SUCCESS = 'PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_SUCCESS'
export const PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_FAILURE = 'PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_FAILURE'

export const PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE = 'PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE'
export const PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_PENDING = 'PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_PENDING'
export const PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_SUCCESS = 'PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_SUCCESS'
export const PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_FAILURE = 'PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_FAILURE'