import { takeLatest, put } from 'redux-saga/effects'

import { history, storage } from '@commons';
import {
  HAS_AUTHORITY,
  HAS_AUTHORITY_PENDING,
  HAS_AUTHORITY_SUCCESS,
  HAS_AUTHORITY_FAILURE
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { CHANGE_MAP_MODE } from '@handler/common/map';

function* sagaHasAuthority(action) {
  yield put({ type: HAS_AUTHORITY_PENDING })

  try {
    const configSession = JSON.parse(storage.get(CONFIG_SESSION));

    if (action.license != undefined && action.license.startsWith('MODULO_AGRICULTURA') &&
      !configSession.contaAgrare.moduloAgricultura) {
      yield put({ type: HAS_AUTHORITY_FAILURE });
      history.push('/noLicense');
    } else if (action.license != undefined && action.license.startsWith('MODULO_FINANCEIRO') &&
      !configSession.contaAgrare.moduloFinanceiro) {
      yield put({ type: HAS_AUTHORITY_FAILURE });
      history.push('/noLicense');
    } else if (action.license != undefined && action.license.startsWith('MODULO_ESTOQUE') &&
      !configSession.contaAgrare.moduloEstoque) {
      yield put({ type: HAS_AUTHORITY_FAILURE });
      history.push('/noLicense');
    } else if (action.license != undefined && action.license.startsWith('MODULO_PLUVIOMETRO') &&
      !configSession.contaAgrare.moduloPluviometro) {
      yield put({ type: HAS_AUTHORITY_FAILURE });
      history.push('/noLicense');
    } else if (action.license != undefined && action.license.startsWith('MODULO_MONITORAMENTO_FISCAL') &&
      !configSession.contaAgrare.moduloMonitoramentoFiscal) {
      yield put({ type: HAS_AUTHORITY_FAILURE });
      history.push('/noLicense');
    } else if (action.license != undefined && action.license.startsWith('MODULO_MAPA') &&
      !configSession.contaAgrare.moduloMapa) {
      yield put({ type: HAS_AUTHORITY_FAILURE });
      history.push('/noLicense');
    } else if (action.license != undefined && action.license.startsWith('MODULO_NDVI') &&
      !configSession.contaAgrare.moduloNdvi) {
      yield put({ type: CHANGE_MAP_MODE, args: "STANDARD" }),
      yield put({ type: HAS_AUTHORITY_FAILURE });
      history.push('/noLicense');
    } else if (configSession.perfilUser !== null) {
      let hasAuthorityProp = false;

      if (configSession.perfilUser.id == 1) {
        hasAuthorityProp = true;
      } else {
        for (let i = 0; i < configSession.perfilUser.perfilPropriedades.length; i++) {
          if (configSession.perfilUser.perfilPropriedades[i].propriedade.id === configSession.fazendaSelecionada.id && configSession.perfilUser.perfilPropriedades[i].permiteAcesso) {
            hasAuthorityProp = true;
          }
        }
      }
      if (!hasAuthorityProp) {
        yield put({ type: HAS_AUTHORITY_FAILURE });
        history.push('/semPermissao');
      } else {
        let hasAuthority = false;
        if (configSession.perfilUser.id == 1) {
          hasAuthority = true;
        } else {
          for (let i = 0; i < configSession.perfilUser.roles.length; i++) {
            if (configSession.perfilUser.roles[i].roleEnum.literal === action.role && configSession.perfilUser.roles[i].ativo) {
              hasAuthority = true;
            }
          }
        }
        if (hasAuthority) {
          yield put({ type: HAS_AUTHORITY_SUCCESS });
        } else {
          yield put({ type: HAS_AUTHORITY_FAILURE });
          history.push('/semPermissao');
        }
      }
    } else {
      yield put({ type: HAS_AUTHORITY_FAILURE });
      history.push('/semPermissao');
    }

  } catch (error) {
    yield put({ type: HAS_AUTHORITY_FAILURE });
    history.push('/semPermissao');
  }
}

export default function* watchHasAuthority() {
  yield takeLatest(HAS_AUTHORITY, sagaHasAuthority);
}
