import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  implantacaoAnimal,
  NEW_IMPLANTACAO_ANIMAL,
  NEW_IMPLANTACAO_ANIMAL_SUCCESS,
} from '@handler';

function* sagaNewImplantacaoAnimal(action) {

  yield put({
    type: NEW_IMPLANTACAO_ANIMAL_SUCCESS, registro:
    {
      ...implantacaoAnimal.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      anoPecuario: JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo,
    }
  });
}

export default function* watchNewImplantacaoAnimal() {
  yield takeLatest(NEW_IMPLANTACAO_ANIMAL, sagaNewImplantacaoAnimal)
}
