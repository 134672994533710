


import watchNewMotoristaCadastro from './saga/new';
import watchFindMotoristaCadastro from './saga/find';
import watchCreateMotoristaCadastro from './saga/create';
import watchListAllMotoristaCadastro from './saga/listAll';
import watchListMotoristaCadastro from './saga/listTable';
import watchDeleteMotoristaCadastro from './saga/delete';
import watchCancelMotoristaCadastroRapido from './saga/cancel';

export const motoristaCadastroSaga = [
    watchNewMotoristaCadastro(),
    watchFindMotoristaCadastro(),
    watchCreateMotoristaCadastro(),
    watchListAllMotoristaCadastro(),
    watchListMotoristaCadastro(),
    watchDeleteMotoristaCadastro(),
    watchCancelMotoristaCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
