import { takeLatest, put, call } from 'redux-saga/effects';

import { storage, path } from '@commons';
import {
  NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA,
  NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_PENDING,
  NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_SUCCESS,
  NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_FAILURE,
} from '../actionTypes';
import { CONST_FILTER_MONITORAMENTO_NFE_COMPRA } from '@commons/consts';
import { findAllNotasCompras } from '../service';
import { CONFIG_SESSION } from '@handler/common/startSession';

function* sagaNewFilterNextPageMonitoramentoNFeCompra(action) {
  yield put({ type: NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_PENDING })

  try {
    let filterSaved = sessionStorage.getItem(CONST_FILTER_MONITORAMENTO_NFE_COMPRA) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_MONITORAMENTO_NFE_COMPRA)) : null;
    const user = JSON.parse(storage.get(path.sessionUser));

    let newFilter = {
      ...filterSaved,
      rows: action.rows,
      page: action.page,
    };

    var dominio = JSON.parse(storage.get(CONFIG_SESSION)).schemaSelecionado;

    const list = yield call(findAllNotasCompras, dominio, filterSaved.dataInicial, filterSaved.dataFinal, `&page=${newFilter.page}&size=${newFilter.rows}${newFilter.where}`);

    sessionStorage.setItem(CONST_FILTER_MONITORAMENTO_NFE_COMPRA, JSON.stringify(newFilter));

    yield put({
      type: NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_SUCCESS, list: list, filter: newFilter
    });
  } catch (error) {

    yield put({ type: NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_FAILURE })
    // console.error(error) // eslint-disable-line
  }
}

export default function* watchNewFilterNextPageMonitoramentoNFeCompra() {
  yield takeLatest(NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA, sagaNewFilterNextPageMonitoramentoNFeCompra)
}