import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_VEICULO_CADASTRO_RAPIDO,
    CANCEL_VEICULO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelVeiculoCadastroRapido() {
  yield put({ type: CANCEL_VEICULO_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelVeiculoCadastroRapido() {
  yield takeLatest(CANCEL_VEICULO_CADASTRO_RAPIDO, sagaCancelVeiculoCadastroRapido)
}