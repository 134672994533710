import { takeLatest, put } from 'redux-saga/effects';

import {
  animalEspecieCadastro,
  NEW_ANIMAL_ESPECIE_CADASTRO,
  NEW_ANIMAL_ESPECIE_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewAnimalEspecieCadastro(action) {

  yield put({ 
    type: NEW_ANIMAL_ESPECIE_CADASTRO_SUCCESS, 
    registro: animalEspecieCadastro.newRegistro, 
    isCadastroRapido: action.isCadastroRapido, 
  });
}

export default function* watchNewAnimalEspecieCadastro() {
  yield takeLatest(NEW_ANIMAL_ESPECIE_CADASTRO, sagaNewAnimalEspecieCadastro)
}
