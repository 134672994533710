import { takeLatest, put, call } from 'redux-saga/effects';

import { path, storage } from '@commons';
import {
  LIST_SCHEMES,
  LIST_SCHEMES_PENDING,
  LIST_SCHEMES_SUCCESS,
  LIST_SCHEMES_FAILURE
} from '../actionTypes';
import { findSchemesByUserAuth } from '@handler/common/usuario';
import { sagaTotaisCorporateWhiteLabel } from '@handler/intranetWhiteLabel/dashboardCorporateWl/saga/totais';
import { TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_PENDING } from '@handler/intranetWhiteLabel/dashboardCorporateWl';

function* sagaListSchemes(action) {
  yield put({ type: LIST_SCHEMES_PENDING })
  yield put({ type: TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_PENDING })

  try {
    let user = JSON.parse(storage.get(path.sessionUser));

    const table = yield call(findSchemesByUserAuth, user, action.args)

    yield put({ type: LIST_SCHEMES_SUCCESS, table: table })

    yield call(sagaTotaisCorporateWhiteLabel, action);

  } catch (error) {
    yield put({ type: LIST_SCHEMES_FAILURE })
  }
}

export default function* watchListSchemes() {
  yield takeLatest(LIST_SCHEMES, sagaListSchemes)
}