import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createPerfil, updatePerfil } from '../service';
import {
  CREATE_PERFIL,
  CREATE_PERFIL_PENDING,
  CREATE_PERFIL_SUCCESS,
  CREATE_PERFIL_FAILURE
} from '../actionTypes';

import { AlertSuccess, AlertWarning, AlertError } from '@components/common/AlertToast'

function* sagaCreatePerfil(action) {
  yield put({ type: CREATE_PERFIL_PENDING })

  try {
    if (action.data.roles === null || action.data.roles === undefined || action.data.roles.lenght === 0) {
      yield put({ type: CREATE_PERFIL_FAILURE, registro: action.data })

      AlertWarning('Nenhuma permissão selecionada para o perfil');
    } else {
      var regraNaoSelecionada = true

      for (var role in action.data.roles) {
        if (action.data.roles[role].ativo) {
          regraNaoSelecionada = false;
        }
      }

      if (regraNaoSelecionada) {
        yield put({ type: CREATE_PERFIL_FAILURE, registro: action.data })

        AlertWarning('Nenhuma permissão selecionada para o perfil');
      } else {
        var propriedadeNaoSelecionada = true

        for (var prop in action.data.perfilPropriedades) {
          if (action.data.perfilPropriedades[prop].permiteAcesso) {
            propriedadeNaoSelecionada = false;
          }
        }

        if (propriedadeNaoSelecionada) {
          yield put({ type: CREATE_PERFIL_FAILURE, registro: action.data })

          AlertWarning('Nenhuma propriedade selecionada para o perfil');
        } else {
          if (action.data.id === undefined || action.data.id === null) {
            yield call(createPerfil, action.data)
          } else {
            yield call(updatePerfil, action.data)
          }

          yield put({ type: CREATE_PERFIL_SUCCESS, registro: action.data });

          if (!action.isCadastroRapido) {
            history.push('/seguranca/perfilUsuario');

            AlertSuccess('Perfil atualizado com sucesso! Faça o login novamente para atualizar as permissões...');
          } else {
            AlertSuccess('Perfil salvo com sucesso!');
          }
        }
      }
    }

  } catch (error) {
    yield put({ type: CREATE_PERFIL_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreatePerfil() {
  yield takeLatest(CREATE_PERFIL, sagaCreatePerfil)
}
