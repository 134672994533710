
export const NEW_CLIENTE_CADASTRO = 'NEW_CLIENTE_CADASTRO'
export const NEW_CLIENTE_CADASTRO_SUCCESS = 'NEW_CLIENTE_CADASTRO_SUCCESS'

export const CREATE_CLIENTE_CADASTRO = 'CREATE_CLIENTE_CADASTRO'
export const CREATE_CLIENTE_CADASTRO_PENDING = 'CREATE_CLIENTE_CADASTRO_PENDING'
export const CREATE_CLIENTE_CADASTRO_SUCCESS = 'CREATE_CLIENTE_CADASTRO_SUCCESS'
export const CREATE_CLIENTE_CADASTRO_FAILURE = 'CREATE_CLIENTE_CADASTRO_FAILURE'

export const CANCEL_CLIENTE_CADASTRO_RAPIDO = 'CANCEL_CLIENTE_CADASTRO_RAPIDO'
export const CANCEL_CLIENTE_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_CLIENTE_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_CLIENTE_CADASTRO = 'TABLE_CLIENTE_CADASTRO'
export const TABLE_CLIENTE_CADASTRO_PENDING = 'TABLE_CLIENTE_CADASTRO_PENDING'
export const TABLE_CLIENTE_CADASTRO_SUCCESS = 'TABLE_CLIENTE_CADASTRO_SUCCESS'
export const TABLE_CLIENTE_CADASTRO_FAILURE = 'TABLE_CLIENTE_CADASTRO_FAILURE'

export const LIST_CLIENTE_CADASTRO = 'LIST_CLIENTE_CADASTRO'
export const LIST_CLIENTE_CADASTRO_PENDING = 'LIST_CLIENTE_CADASTRO_PENDING'
export const LIST_CLIENTE_CADASTRO_SUCCESS = 'LIST_CLIENTE_CADASTRO_SUCCESS'
export const LIST_CLIENTE_CADASTRO_FAILURE = 'LIST_CLIENTE_CADASTRO_FAILURE'

export const FIND_CLIENTE_CADASTRO = 'FIND_CLIENTE_CADASTRO'
export const FIND_CLIENTE_CADASTRO_PENDING = 'FIND_CLIENTE_CADASTRO_PENDING'
export const FIND_CLIENTE_CADASTRO_SUCCESS = 'FIND_CLIENTE_CADASTRO_SUCCESS'
export const FIND_CLIENTE_CADASTRO_FAILURE = 'FIND_CLIENTE_CADASTRO_FAILURE'

export const DELETE_CLIENTE_CADASTRO = 'DELETE_CLIENTE_CADASTRO'
export const DELETE_CLIENTE_CADASTRO_PENDING = 'DELETE_CLIENTE_CADASTRO_PENDING'
export const DELETE_CLIENTE_CADASTRO_SUCCESS = 'DELETE_CLIENTE_CADASTRO_SUCCESS'
export const DELETE_CLIENTE_CADASTRO_FAILURE = 'DELETE_CLIENTE_CADASTRO_FAILURE'
