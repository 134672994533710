import watchNewCulturaCadastro from './saga/new';
import watchFindCulturaCadastro from './saga/find';
import watchCreateCulturaCadastro from './saga/create';
import watchListAllCulturaCadastro from './saga/listAll';
import watchListCulturaCadastro from './saga/listTable';
import watchDeleteCulturaCadastro from './saga/delete';
import watchCancelCulturaCadastroRapido from './saga/cancel';

export const culturaCadastroSaga = [
    watchNewCulturaCadastro(),
    watchFindCulturaCadastro(),
    watchCreateCulturaCadastro(),
    watchListAllCulturaCadastro(),
    watchListCulturaCadastro(),
    watchDeleteCulturaCadastro(),
    watchCancelCulturaCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
