import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  vendaProducaoAgricola,
  NEW_VENDA_PRODUCAO_AGRICOLA,
  NEW_VENDA_PRODUCAO_AGRICOLA_SUCCESS,
} from '@handler';

function* sagaNewVendaProducaoAgricola(action) {

  yield put({ type: NEW_VENDA_PRODUCAO_AGRICOLA_SUCCESS, registro: 
    {
      ...vendaProducaoAgricola.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
    } });
}

export default function* watchNewVendaProducaoAgricola() {
  yield takeLatest(NEW_VENDA_PRODUCAO_AGRICOLA, sagaNewVendaProducaoAgricola)
}
