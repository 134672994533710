import { takeLatest, put } from 'redux-saga/effects';

import {
  estagioAplicacaoCadastro,
  NEW_ESTAGIO_APLICACAO_CADASTRO,
  NEW_ESTAGIO_APLICACAO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewEstagioAplicacaoCadastro(action) {
  yield put({ 
    type: NEW_ESTAGIO_APLICACAO_CADASTRO_SUCCESS,
    registro: estagioAplicacaoCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewEstagioAplicacaoCadastro() {
  yield takeLatest(NEW_ESTAGIO_APLICACAO_CADASTRO, sagaNewEstagioAplicacaoCadastro);
}
