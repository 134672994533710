import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';
import { gerarContaAgoraContaRecorrente } from '../service';
import {
  GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE,
  GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_PENDING,
  GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_SUCCESS,
  GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_FAILURE
} from '../actionTypes';
import { AlertError } from '@components/common/AlertToast';

function* sagaGerarContaAgoraContaPagarRecorrente(action) {
  yield put({ type: GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_PENDING, id: action.id })

  try {
    yield call(gerarContaAgoraContaRecorrente, action.id);

    yield put({ type: GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_SUCCESS })

    swal({
      title: 'Próximo vencimento da conta gerado com sucesso!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });
  } catch (error) {
    yield put({ type: GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE_FAILURE });

    AlertError('Falha ao gerar próximo vencimento da conta! Tente novamente...');
  }
}

export default function* watchGerarContaAgoraContaPagarRecorrente() {
  yield takeLatest(GERAR_CONTA_AGORA_CONTA_PAGAR_RECORRENTE, sagaGerarContaAgoraContaPagarRecorrente)
}
