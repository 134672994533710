
export const NEW_ANIMAL_RACA_CADASTRO = 'NEW_ANIMAL_RACA_CADASTRO'
export const NEW_ANIMAL_RACA_CADASTRO_PENDING = 'NEW_ANIMAL_RACA_CADASTRO_PENDING'
export const NEW_ANIMAL_RACA_CADASTRO_SUCCESS = 'NEW_ANIMAL_RACA_CADASTRO_SUCCESS'
export const NEW_ANIMAL_RACA_CADASTRO_FAILURE = 'NEW_ANIMAL_RACA_CADASTRO_FAILURE'

export const CREATE_ANIMAL_RACA_CADASTRO = 'CREATE_ANIMAL_RACA_CADASTRO'
export const CREATE_ANIMAL_RACA_CADASTRO_PENDING = 'CREATE_ANIMAL_RACA_CADASTRO_PENDING'
export const CREATE_ANIMAL_RACA_CADASTRO_SUCCESS = 'CREATE_ANIMAL_RACA_CADASTRO_SUCCESS'
export const CREATE_ANIMAL_RACA_CADASTRO_FAILURE = 'CREATE_ANIMAL_RACA_CADASTRO_FAILURE'

export const CANCEL_ANIMAL_RACA_CADASTRO_RAPIDO = 'CANCEL_ANIMAL_RACA_CADASTRO_RAPIDO'
export const CANCEL_ANIMAL_RACA_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_ANIMAL_RACA_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_ANIMAL_RACA_CADASTRO = 'TABLE_ANIMAL_RACA_CADASTRO'
export const TABLE_ANIMAL_RACA_CADASTRO_PENDING = 'TABLE_ANIMAL_RACA_CADASTRO_PENDING'
export const TABLE_ANIMAL_RACA_CADASTRO_SUCCESS = 'TABLE_ANIMAL_RACA_CADASTRO_SUCCESS'
export const TABLE_ANIMAL_RACA_CADASTRO_FAILURE = 'TABLE_ANIMAL_RACA_CADASTRO_FAILURE'

export const LIST_ANIMAL_RACA_CADASTRO = 'LIST_ANIMAL_RACA_CADASTRO'
export const LIST_ANIMAL_RACA_CADASTRO_PENDING = 'LIST_ANIMAL_RACA_CADASTRO_PENDING'
export const LIST_ANIMAL_RACA_CADASTRO_SUCCESS = 'LIST_ANIMAL_RACA_CADASTRO_SUCCESS'
export const LIST_ANIMAL_RACA_CADASTRO_FAILURE = 'LIST_ANIMAL_RACA_CADASTRO_FAILURE'

export const FIND_ANIMAL_RACA_CADASTRO = 'FIND_ANIMAL_RACA_CADASTRO'
export const FIND_ANIMAL_RACA_CADASTRO_PENDING = 'FIND_ANIMAL_RACA_CADASTRO_PENDING'
export const FIND_ANIMAL_RACA_CADASTRO_SUCCESS = 'FIND_ANIMAL_RACA_CADASTRO_SUCCESS'
export const FIND_ANIMAL_RACA_CADASTRO_FAILURE = 'FIND_ANIMAL_RACA_CADASTRO_FAILURE'

export const DELETE_ANIMAL_RACA_CADASTRO = 'DELETE_ANIMAL_RACA_CADASTRO'
export const DELETE_ANIMAL_RACA_CADASTRO_PENDING = 'DELETE_ANIMAL_RACA_CADASTRO_PENDING'
export const DELETE_ANIMAL_RACA_CADASTRO_SUCCESS = 'DELETE_ANIMAL_RACA_CADASTRO_SUCCESS'
export const DELETE_ANIMAL_RACA_CADASTRO_FAILURE = 'DELETE_ANIMAL_RACA_CADASTRO_FAILURE'
