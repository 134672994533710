import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/lancamentoContaCaixa' };
/////////////////////////////////////////////////////////////////////////////////

export function createControleContaCaixa(payload) {
  return httpService.post(args, payload);
}

export function updateControleContaCaixa(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function findControleContaCaixa(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deleteControleContaCaixa(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function getLancamentos(idPropriedade, idContaCaixa, datas, where) {
  return httpService.get({ ...args, args: `/extratoMovimentacoes?propriedade=${idPropriedade}&datas=${datas}&conta_id=${idContaCaixa}${where}` });
}

export function getTotaisExtrato(idPropriedade, idContaCaixa, datas, where) {
  return httpService.get({ ...args, args: `/totaisExtrato?propriedade=${idPropriedade}&datas=${datas}&conta_id=${idContaCaixa}${where}` });
}

// transferencia
export function createControleContaCaixaTransf(payload) {
  return httpService.post({...args, path: "/lancamentoContaCaixa/transferencia"}, payload);
}

export function updateControleContaCaixaTransf(payload) {
  const { id } = payload

  return httpService.put({ ...args, path: `/lancamentoContaCaixa/transferencia/${id}` }, payload);
}

export function getIdTransfLancContaCaixa(id) {
  return httpService.get({ ...args, path: `/lancamentoContaCaixa/transferencia/getIdTransfLancContaCaixa/${id}` });
}

export function findControleContaCaixaTransf(id) {
  return httpService.get({ ...args, path: `/lancamentoContaCaixa/transferencia/${id}` });
}

export function deleteControleContaCaixaTransf(id) {
  return httpService.del({ ...args, path: `/lancamentoContaCaixa/transferencia/${id}` });
}

export function getRelatorioControleCaixa(idPropriedade, idContaCaixa, datas, where) {
  return httpService.getPdf({ ...args, args: `/relatorioControleCaixa/pdf?propriedade=${idPropriedade}&conta_id=${idContaCaixa}&datas=${datas}${where}` });
}