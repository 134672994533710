export const TABLE_NDVI = 'TABLE_NDVI'
export const TABLE_NDVI_PENDING = 'TABLE_NDVI_PENDING'
export const TABLE_NDVI_SUCCESS = 'TABLE_NDVI_SUCCESS'
export const TABLE_NDVI_FAILURE = 'TABLE_NDVI_FAILURE'

export const LIST_NDVI = 'LIST_NDVI'
export const LIST_NDVI_PENDING = 'LIST_NDVI_PENDING'
export const LIST_NDVI_SUCCESS = 'LIST_NDVI_SUCCESS'
export const LIST_NDVI_FAILURE = 'LIST_NDVI_FAILURE'

export const LIST_ALL_LATEST_NDVI = 'LIST_ALL_LATEST_NDVI'
export const LIST_ALL_LATEST_NDVI_PENDING = 'LIST_ALL_LATEST_NDVI_PENDING'
export const LIST_ALL_LATEST_NDVI_SUCCESS = 'LIST_ALL_LATEST_NDVI_SUCCESS'
export const LIST_ALL_LATEST_NDVI_FAILURE = 'LIST_ALL_LATEST_NDVI_FAILURE'

export const FIND_NDVI = 'FIND_NDVI'
export const FIND_NDVI_PENDING = 'FIND_NDVI_PENDING'
export const FIND_NDVI_SUCCESS = 'FIND_NDVI_SUCCESS'
export const FIND_NDVI_FAILURE = 'FIND_NDVI_FAILURE'

export const SELECT_NDVI = 'SELECT_NDVI'
export const SELECT_NDVI_PENDING = 'SELECT_NDVI_PENDING'
export const SELECT_NDVI_SUCCESS = 'SELECT_NDVI_SUCCESS'
export const SELECT_NDVI_FAILURE = 'SELECT_NDVI_FAILURE'
