import React, { Component } from "react";
import { Router } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { connect } from "react-redux";
import { toast } from 'react-toastify';

// Import Routes
import Routes from './routes';
import { history } from './routes/history';

import { LoadScript } from "@react-google-maps/api";

// Import scss
import "./assets/scss/theme.scss";

import 'react-toastify/dist/ReactToastify.css';

import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import 'react-dropzone-uploader/dist/styles.css'

import './firebase.config'

class App extends Component {
  constructor(props) {
    super(props);

    toast.configure();
  }

  render() {
    const apiKey = "AIzaSyCGVnIWcgOkJohf7EDLyK2mh1tlwqxj_Wo";
    const libraries = ["drawing", "geometry", "places", "localContext", "visualization"]; //"drawing" | "geometry" | "localContext" | "places" | "visualization"

    return (
      <LoadScript
        id="script-loader"
        googleMapsApiKey={apiKey} // O cara usa process.env.REACT_APP_GOOGLE_MAPS_API_KEY
        libraries={libraries}
        language="pt-br"
        region="br"
      >
        <Router history={history}>
          <HttpsRedirect>
            <Routes />
          </HttpsRedirect>
        </Router>
      </LoadScript>
    );
  }
}

export default connect(null, null)(App);
