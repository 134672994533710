import {
    TOTAIS_DASHBOARD_PATRIMONIO_PENDING,
    TOTAIS_DASHBOARD_PATRIMONIO_SUCCESS,
    TOTAIS_DASHBOARD_PATRIMONIO_FAILURE,
    GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_SUCCESS,
    GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_FAILURE,
    GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_PENDING,
    TOTAIS_DASHBOARD_PATRIMONIO_FINISH,
} from './actionTypes';

const filter = {
    todosPatrimonios: true,
    totais: {
        "totalPatrimonios": 0,
        "totalNroItens": 0,
        "totalHorasTrabalhadasNaSafra": "0,0",
        "totalHorasTrabalhadasNaFazenda": "0,0",
        "totalCustoDiretoPatrimonios": 0,
        "totalCustoDiretoPatrimoniosNaSafra": 0,
        "totalDespesasPatrimonios": 0,
        "totalDespesasEmAbertoPatrimonios": 0,
    }
}

const initialState = { filter: filter, loading: false, dadosGraficoTotalDespesasPatrimoniosPorContaGerencial: [['Conta Gerencial', 'Total']] }

function dashboardPatrimonioReducer(state = initialState, action) {

    switch (action.type) {
        case TOTAIS_DASHBOARD_PATRIMONIO_PENDING:
        case GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_PENDING:
            return {
                ...state,
                loading: true,
                dadosGraficoTotalDespesasPatrimoniosPorContaGerencial: [['Conta Gerencial', 'Total']],
            }
        case TOTAIS_DASHBOARD_PATRIMONIO_FINISH:
            return {
                ...state,
                loading: false,
            }
        case TOTAIS_DASHBOARD_PATRIMONIO_SUCCESS:
            return {
                ...state,
                filter: action.filter,
                // loading: false
            }
        case TOTAIS_DASHBOARD_PATRIMONIO_FAILURE:
            return {
                ...state,
                // loading: false
            }
        case GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_SUCCESS:
            return {
                ...state,
                dadosGraficoTotalDespesasPatrimoniosPorContaGerencial: action.dadosGraficoTotalDespesasPatrimoniosPorContaGerencial,
                // loading: false
            }
        case GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_FAILURE:
            return {
                ...state,
                dadosGraficoTotalDespesasPatrimoniosPorContaGerencial: [['Conta Gerencial', 'Total']],
                // loading: false
            }
        default:
            return state
    }
}

export { dashboardPatrimonioReducer };