import { takeLatest, put } from 'redux-saga/effects';

import {
  CANCEL_EMITENTE_CADASTRO_RAPIDO,
  CANCEL_EMITENTE_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelEmitenteCadastroRapido() {
  yield put({ type: CANCEL_EMITENTE_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelEmitenteCadastroRapido() {
  yield takeLatest(CANCEL_EMITENTE_CADASTRO_RAPIDO, sagaCancelEmitenteCadastroRapido)
}