import { takeLatest, put, call } from 'redux-saga/effects';
import { httpService } from '@commons';

import {
  FIND_CONTA_AGRARE,
  FIND_CONTA_AGRARE_PENDING,
  FIND_CONTA_AGRARE_SUCCESS,
  FIND_CONTA_AGRARE_FAILURE
} from '../actionTypes';

const findContaAgrare = async (id) => {
  return httpService.getApiManager(`/clienteAgrare/${id}`, true);
}

function* sagaFindContaAgrare(action) {
  yield put({ type: FIND_CONTA_AGRARE_PENDING, id: action.id })

  try {

    const registro = yield call(findContaAgrare, action.id);

    yield put({ type: FIND_CONTA_AGRARE_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_CONTA_AGRARE_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindContaAgrare() {
  yield takeLatest(FIND_CONTA_AGRARE, sagaFindContaAgrare)
}
