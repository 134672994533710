import { takeLatest, put, call } from 'redux-saga/effects';

import { findEvolucaoAnimal } from '../service';
import {
  FIND_EVOLUCAO_ANIMAL,
  FIND_EVOLUCAO_ANIMAL_PENDING,
  FIND_EVOLUCAO_ANIMAL_SUCCESS,
  FIND_EVOLUCAO_ANIMAL_FAILURE
} from '../actionTypes';

function* sagaFindEvolucaoAnimal(action) {
  yield put({ type: FIND_EVOLUCAO_ANIMAL_PENDING, id: action.id })

  try {

    const registro = yield call(findEvolucaoAnimal, action.id);

    yield put({ type: FIND_EVOLUCAO_ANIMAL_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_EVOLUCAO_ANIMAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindEvolucaoAnimal() {
  yield takeLatest(FIND_EVOLUCAO_ANIMAL, sagaFindEvolucaoAnimal)
}
