
export const NEW_PLUVIOMETRO_DIGITAL = 'NEW_PLUVIOMETRO_DIGITAL'
export const NEW_PLUVIOMETRO_DIGITAL_SUCCESS = 'NEW_PLUVIOMETRO_DIGITAL_SUCCESS'

export const CREATE_PLUVIOMETRO_DIGITAL = 'CREATE_PLUVIOMETRO_DIGITAL'
export const CREATE_PLUVIOMETRO_DIGITAL_PENDING = 'CREATE_PLUVIOMETRO_DIGITAL_PENDING'
export const CREATE_PLUVIOMETRO_DIGITAL_SUCCESS = 'CREATE_PLUVIOMETRO_DIGITAL_SUCCESS'
export const CREATE_PLUVIOMETRO_DIGITAL_FAILURE = 'CREATE_PLUVIOMETRO_DIGITAL_FAILURE'

export const TABLE_PLUVIOMETRO_DIGITAL = 'TABLE_PLUVIOMETRO_DIGITAL'
export const TABLE_PLUVIOMETRO_DIGITAL_PENDING = 'TABLE_PLUVIOMETRO_DIGITAL_PENDING'
export const TABLE_PLUVIOMETRO_DIGITAL_SUCCESS = 'TABLE_PLUVIOMETRO_DIGITAL_SUCCESS'
export const TABLE_PLUVIOMETRO_DIGITAL_FAILURE = 'TABLE_PLUVIOMETRO_DIGITAL_FAILURE'

export const LIST_PLUVIOMETRO_DIGITAL = 'LIST_PLUVIOMETRO_DIGITAL'
export const LIST_PLUVIOMETRO_DIGITAL_PENDING = 'LIST_PLUVIOMETRO_DIGITAL_PENDING'
export const LIST_PLUVIOMETRO_DIGITAL_SUCCESS = 'LIST_PLUVIOMETRO_DIGITAL_SUCCESS'
export const LIST_PLUVIOMETRO_DIGITAL_FAILURE = 'LIST_PLUVIOMETRO_DIGITAL_FAILURE'

export const FIND_PLUVIOMETRO_DIGITAL = 'FIND_PLUVIOMETRO_DIGITAL'
export const FIND_PLUVIOMETRO_DIGITAL_PENDING = 'FIND_PLUVIOMETRO_DIGITAL_PENDING'
export const FIND_PLUVIOMETRO_DIGITAL_SUCCESS = 'FIND_PLUVIOMETRO_DIGITAL_SUCCESS'
export const FIND_PLUVIOMETRO_DIGITAL_FAILURE = 'FIND_PLUVIOMETRO_DIGITAL_FAILURE'

export const DELETE_PLUVIOMETRO_DIGITAL = 'DELETE_PLUVIOMETRO_DIGITAL'
export const DELETE_PLUVIOMETRO_DIGITAL_PENDING = 'DELETE_PLUVIOMETRO_DIGITAL_PENDING'
export const DELETE_PLUVIOMETRO_DIGITAL_SUCCESS = 'DELETE_PLUVIOMETRO_DIGITAL_SUCCESS'
export const DELETE_PLUVIOMETRO_DIGITAL_FAILURE = 'DELETE_PLUVIOMETRO_DIGITAL_FAILURE'
