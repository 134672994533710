import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  transferenciaEstoque,
  NEW_TRANSFERENCIA_ESTOQUE,
  NEW_TRANSFERENCIA_ESTOQUE_SUCCESS,
} from '@handler';

function* sagaNewTransferenciaEstoque(action) {

  yield put({
    type: NEW_TRANSFERENCIA_ESTOQUE_SUCCESS, registro:
    {
      ...transferenciaEstoque.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
    }
  });
}

export default function* watchNewTransferenciaEstoque() {
  yield takeLatest(NEW_TRANSFERENCIA_ESTOQUE, sagaNewTransferenciaEstoque)
}
