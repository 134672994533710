import { takeLatest, put, call } from 'redux-saga/effects';

import { listAllLatestNdvi } from '../service';
import {
  LIST_ALL_LATEST_NDVI,
  LIST_ALL_LATEST_NDVI_PENDING,
  LIST_ALL_LATEST_NDVI_SUCCESS,
  LIST_ALL_LATEST_NDVI_FAILURE
} from '../actionTypes';

function* sagaListAllLatestNdvi(action) {
  yield put({ type: LIST_ALL_LATEST_NDVI_PENDING });

  try {
    const allLatest = yield call(listAllLatestNdvi, action.args);
    yield put({ type: LIST_ALL_LATEST_NDVI_SUCCESS, allLatest: allLatest });
  } catch (error) {
    yield put({ type: LIST_ALL_LATEST_NDVI_FAILURE });
  }
}

export default function* watchListAllLatestNdvi() {
  yield takeLatest(LIST_ALL_LATEST_NDVI, sagaListAllLatestNdvi);
}