import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createAnimalCadastro, updateAnimalCadastro } from '../service';
import {
    CREATE_ANIMAL_CADASTRO,
    CREATE_ANIMAL_CADASTRO_PENDING,
    CREATE_ANIMAL_CADASTRO_SUCCESS,
    CREATE_ANIMAL_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateAnimalCadastro(action) {
  yield put({ type: CREATE_ANIMAL_CADASTRO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createAnimalCadastro, action.data);
    } else {
      yield call(updateAnimalCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_ANIMAL_CADASTRO_SUCCESS, registro: action.data })

    if(!action.isCadastroRapido){
      history.push('/pecuaria/animalCadastro');
    }  

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_ANIMAL_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateAnimalCadastro() {
  yield takeLatest(CREATE_ANIMAL_CADASTRO, sagaCreateAnimalCadastro)
}
