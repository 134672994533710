import React from 'react';

import './IconTextInfoNoRegisterComponent.css'

function IconTextInfoNoRegisterComponent(props) {
    return (
        <div className="icon-text-container">
            <i className="fa fa-times-circle icon-style"></i>
            <h6 className="text-muted">{props.title ?? 'Nenhum registro encontrado'}</h6>
        </div>
    );
}

export default IconTextInfoNoRegisterComponent;
