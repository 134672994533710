import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/estoque/movimentacao' };
/////////////////////////////////////////////////////////////////////////////////

export function getEstoqueAtual(idPropriedade, filter) {
  var where = "";
  if (filter !== undefined && filter !== null && filter.produto !== undefined && filter.produto !== null) {
    where = where + '&produto=' + filter.produto.id
  }
  if (filter !== undefined && filter !== null && filter.localEstoque !== undefined && filter.localEstoque !== null) {
    where = where + '&localEstoque=' + filter.localEstoque.id
  }
  return httpService.get({ ...args, path: '/estoque/movimentacao/estoqueAtual?propriedade=' + idPropriedade + where });
}

export function getMovimentacoesEstoqueProduto(idPropriedade, idProduto, idLocalEstoque) {
  var where = "";
  if (idLocalEstoque !== undefined && idLocalEstoque !== null){
    where = '&localEstoque=' + idLocalEstoque;
  }
  return httpService.get({ ...args, path: '/estoque/movimentacao/lancamentosProduto?propriedade=' + idPropriedade + "&produto=" + idProduto + where });
}

export function getEstoqueAtualPdf(idPropriedade, filter) {
  var where = "";
  if (filter !== undefined && filter !== null && filter.produto !== undefined && filter.produto !== null) {
    where = where + '&produto=' + filter.produto.id
  }
  if (filter !== undefined && filter !== null && filter.localEstoque !== undefined && filter.localEstoque !== null) {
    where = where + '&localEstoque=' + filter.localEstoque.id
  }
  return httpService.getPdf({ ...args, path: '/estoque/movimentacao/estoqueAtual/pdf?propriedade=' + idPropriedade + where });
}
