import React from 'react';
import PageLoader from '@components/common/PageLoader';

import { withRouter, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardBody,
  Button
} from 'reactstrap';
import { PageLoaderScreen } from '@components/common/PageLoaderScreen';

import { Formik, Form } from 'formik'

import { CPF_CNPJInput, Check, InputText } from '@components/form';
import {
  contaAgrareCadastro,
  UPDATE_CONTA_AGRARE,
} from '@handler';
import HorizontalLayout from "@components/layout/HorizontalClean";
import { SelectEnum } from '@components/search/enumSelect';
import { TIPO_CLIENTE_AGRARE_ATIVIDADE_AGRICOLA_ENUM } from '@handler/contaAgrare/tipoClienteAgrareAtividadeAgricolaEnum';
import { TIPO_CLIENTE_AGRARE_LOCAL_TRABALHO_ENUM } from '@handler/contaAgrare/tipoClienteAgrareLocalTrabalhoEnum';
import { InputMask } from '@components/form/InputMask';
import { SelectGrupoContaAgrareWhiteLabel } from '@components/search/intranetWhiteLabel/grupoContaAgrareWl';
import { CallWhatsApp } from '@components/common/CallWhatsApp';

class ContaAgrareForm extends React.Component {

  state = {
    keySelectGrupo: 1,
  }

  // /////////////////////////////////////////////////////////////////////////////
  onSubmit = async (values) => {
    await this.props.updateContaAgrare(values);
  }
  // /////////////////////////////////////////////////////////////////////////////

  render() {

    const { contaAgrareReducer, initialValues } = this.props;

    return (
      <HorizontalLayout>
        <PageLoaderScreen loading={contaAgrareReducer.loading} />

        <div className="container-fluid">
          {(contaAgrareReducer.isCadastroRapido ? <React.Fragment /> :
            <Row className="align-items-center">
              <Col sm={12}>
                <div className="page-title-box">
                  <h4 className="font-size-18">{this.props.title}</h4>
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={12}>
              {contaAgrareReducer.loading ? <PageLoader /> :
                (contaAgrareReducer.registro === undefined ? <Redirect to='/corporate/contaProdutor' /> :

                  contaAgrareReducer.registro &&
                  <>
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={contaAgrareCadastro.validationContaAgrareCadastro}
                      onSubmit={(values) => this.onSubmit(values)}
                    >
                      {({ errors, touched, values }) => (
                        <Form>
                          {/* // ///////////////////////////////////////////////////////////// */}
                          <Row>
                            <Col md={12}>
                              <Card className='card-default'>
                                <CardBody>
                                  <Row>
                                    <InputText name='nomeConta'
                                      label='Nome da Conta (Identificação na plataforma)'
                                      placeholder='Informe o nome da conta'
                                      col={5}
                                    />
                                    <React.Fragment key={'fragFO_col_' + this.state.keySelectGrupo}>
                                      <SelectGrupoContaAgrareWhiteLabel
                                        name='clienteAgrareGrupo'
                                        label='Grupo de Produtor'
                                        placeholder='Selecione o grupo'
                                        col={4}
                                        refresh={() =>
                                          this.setState({
                                            keySelectGrupo: (this.state.keySelectGrupo + 1)
                                          })
                                        }
                                      />
                                    </React.Fragment>
                                    <Check name='status'
                                      label='Conta Ativa?'
                                      col={3}
                                    />
                                  </Row>
                                  <Row>
                                    <InputText name='nomeResponsavelConta'
                                      label='Nome Produtor Responsável'
                                      placeholder='Informe o nome'
                                      col={8}
                                    />
                                    <CPF_CNPJInput
                                      name='cpfCnpjResponsavelConta'
                                      label='CPF/CNPJ Produtor'
                                      placeholder='Informe o CPF/CNPJ'
                                      col={4}
                                    />
                                  </Row>
                                  <Row>
                                    <InputText name='emailPrincipal'
                                      label='E-mail Produtor'
                                      placeholder='Informe o e-mail'
                                      col={6}
                                    />
                                    <InputMask name='whatsApp'
                                      label='WhatsApp Produtor'
                                      placeholder='Informe o celular'
                                      mask='(99) 9 9999-9999'
                                      col={6}
                                      suffix={CallWhatsApp(values.whatsApp)}
                                    />
                                  </Row>
                                  <Row>
                                    <SelectEnum
                                      name='localTrabalho'
                                      label='Local de Trabalho'
                                      placeholder='Selecione o Local de Trabalho'
                                      list={TIPO_CLIENTE_AGRARE_LOCAL_TRABALHO_ENUM}
                                      col={6}
                                    />
                                    <SelectEnum
                                      name='atividadeAgricola'
                                      label='Principal Atividade Agrícola'
                                      placeholder='Selecione a Principal Atividade Agrícola'
                                      list={TIPO_CLIENTE_AGRARE_ATIVIDADE_AGRICOLA_ENUM}
                                      col={6}
                                    />
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          {/* // ///////////////////////////////////////////////////////////// */}
                          <Row>
                            <Col md={12}>
                              <Card className='card-default'>
                                <CardBody>
                                  <Button className="btn-primary" onClick={() => { }} type='submit'>
                                    <i className='fa fa-check' /> Salvar
                                  </Button>

                                  <Link to='/corporate/contaProdutor' title='Voltar' style={{ marginLeft: '10px' }}>
                                    Voltar
                                  </Link>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </>)}
            </Col>
          </Row>
        </div>
      </HorizontalLayout>
    );
  }
}

export function mapStateToProps(state) {
  const { contaAgrareReducer } = state.entities;

  return { contaAgrareReducer, initialValues: contaAgrareReducer.registro };
};

function mapDispatchToProps(dispatch) {
  return {
    updateContaAgrare: (data) => dispatch({ type: UPDATE_CONTA_AGRARE, data }),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ContaAgrareForm))
