import { takeLatest, put } from 'redux-saga/effects';

import {
  despesaDiretaCadastro,
  NEW_DESPESA_DIRETA_CADASTRO,
  NEW_DESPESA_DIRETA_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewDespesaDiretaCadastro(action) {
  yield put({
    type: NEW_DESPESA_DIRETA_CADASTRO_SUCCESS,
    registro: despesaDiretaCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewDespesaDiretaCadastro() {
  yield takeLatest(NEW_DESPESA_DIRETA_CADASTRO, sagaNewDespesaDiretaCadastro)
}
