import watchTotaisPecuaria from './saga/totais';

export const dashboardPecuariaSaga = [
    watchTotaisPecuaria(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
