import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/pecuaria/cadastro/campo' };
/////////////////////////////////////////////////////////////////////////////////

export function createCampoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateCampoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listCampoCadastro(param) {
  return httpService.get({...args, args: param});
}

export function listAllCampoAtivo(idPropriedade) {
  return httpService.get({...args, args: `?size=2000&page=0&propriedade=${idPropriedade}&status=true`});
}

export function findCampoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllCamposByPropriedade(propriedade) {
  return httpService.get({ ...args, path: '/cadastro/campo?size=20000&page=0&status=true&propriedade=' + propriedade });
}

export function deleteCampoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

