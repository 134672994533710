import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  compraAnimal,
  NEW_COMPRA_ANIMAL,
  NEW_COMPRA_ANIMAL_SUCCESS,
} from '@handler';

function* sagaNewCompraAnimal(action) {

  yield put({ type: NEW_COMPRA_ANIMAL_SUCCESS, registro: 
    {
      ...compraAnimal.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      anoPecuario: JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo,
    } });
}

export default function* watchNewCompraAnimal() {
  yield takeLatest(NEW_COMPRA_ANIMAL, sagaNewCompraAnimal)
}
