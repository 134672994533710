import React from "react";

import { AsyncPaginate } from "react-select-async-paginate";

import {
    Col,
    FormGroup,
    Label,
} from 'reactstrap';

import { Field } from 'formik';
import { InfoTooltip } from "@components/form";

export const SelectEnum = (props) => {

    const { col, name, label, placeholder, list, renderInfoTooltip, titleInfoTooltip, msgInfoTooltip } = props;

    return (
        <Col md={col}>
            <FormGroup>
                <Label htmlFor={'id_' + name} >{label} {" "}
                    {
                        renderInfoTooltip ?
                            <InfoTooltip
                                id={'idtool_' + name.split('.').shift() }
                                title={titleInfoTooltip}
                                msg={msgInfoTooltip}
                            />
                            : <React.Fragment />
                    }
                </Label>
                <Field name={name} >
                    {({ field, form, meta }) => (
                        <>
                            <AsyncPaginate
                                {...field}
                                required={true}
                                id={'id_' + name}
                                options={[]}
                                value={list.filter(function (k) {
                                    return field.value === k.id;
                                })}
                                loadOptions={async (search) => {
                                    const registros = list;

                                    let filteredOptions;
                                    if (!search) {
                                        filteredOptions = registros;
                                    } else {
                                        const searchLower = search.toLowerCase();

                                        filteredOptions = registros.filter(({ descricao }) =>
                                            descricao.toLowerCase().includes(searchLower)
                                        );
                                    }

                                    return {
                                        options: filteredOptions,
                                        hasMore: false
                                    };
                                }}
                                onChange={(e) => {
                                    if (e === null) {
                                        form.setFieldValue(field.name, null);
                                    } else {
                                        form.setFieldValue(field.name, e.id);
                                    }
                                    props.plusChange(e);
                                }}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.descricao}
                                placeholder={placeholder}
                                isClearable={true}
                                isSearchable={true}
                                escapeClearsValue={true}
                                autoload={false}
                                loadOptionsOnMenuOpen={true}
                                loadingMessage={() => 'Carregando...'}
                                noOptionsMessage={() => 'Nenhum registro encontrado'}
                                isValidNewOption={meta.error && meta.touched}
                                menuPosition={'fixed'}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#e0dede',
                                        primary50: '#e0dede',
                                        primary: '#a6a4a4',

                                        neutral20: meta.touched && meta.error ? 'red' : '#CCC'
                                    },
                                })}
                            />
                            {meta.touched && (meta.error && <p style={{ color: "red", fontSize: "12px", padding: "2px" }}>{meta.error}</p>)}
                        </>
                    )}
                </Field>
            </FormGroup>
        </Col>
    );
};

SelectEnum.defaultProps = {
    col: 12,
    renderInfoTooltip: false,
    titleInfoTooltip: 'Informação',
    plusChange: () => { },
};