import * as Yup from 'yup';

const newRegistro = {
    data: '',
    tipoSaida: null,
    motivo: '',
    obs: '',
    itens: [],
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    tipoSaida: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    motivo: Yup.string()
        .max(2000, 'Campo deve ter menos do que 2000 caracteres'),
    obs: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
});

export const outrasSaidaAnimal = {
    newRegistro,
    validation
}