import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/usuario' };
/////////////////////////////////////////////////////////////////////////////////

export function listUsuario(param) {
  return httpService.get({ ...args, args: param });
}

export function findUsuarioByUserAuthId(idUserAuth) {
  return httpService.get({ ...args, args: `/findByUserAuthId/${idUserAuth}` });
}

export function deleteUsuario(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function convidarUsuario(payload) {
  return httpService.put({ ...args, args: '/adicionarUsuarioSemConvite' }, payload);
}

export function ativarUsuario(id) {
  return httpService.put({ ...args, args: `/ativar/${id}` });
}

export function desativarUsuario(id) {
  return httpService.put({ ...args, args: `/desativar/${id}` });
}

export function trocarPermissao(idUsuario, idNovoPerfilPermissao) {
  return httpService.put({ ...args, args: `/trocarPermissao/${idUsuario}/${idNovoPerfilPermissao}` });
}


export function updateUserAuth(payload) {
  const { id } = payload
  return httpService.putApiManager(`/userAuth/${id}`, payload);
}

export function findUserAuth(id) {
  return httpService.getApiManager(`/userAuth/${id}`, true);
}

export function findUserAuthResumed(id) {
  return httpService.getApiManager(`/userAuth/resumed/${id}`, true);
}

export function findSchemesByUserAuth(user, params) {
  var where = '';
  if (params !== undefined && params !== null && params !== '') {
    where = `${params}userAuth=${user.id}&clienteWhiteLabel_identificationCode=${user.identificationCodeWhiteLabel}`
  } else {
    where = `?userAuth=${user.id}&clienteWhiteLabel_identificationCode=${user.identificationCodeWhiteLabel}`
  }
  
  return httpService.getApiManager(`/userAuth/getSchemesByUser${where}`, true);
}