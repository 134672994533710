import { takeLatest, put, call } from 'redux-saga/effects';

import { listVendaProducaoAgricola, listVendaProducaoAgricolaPdf } from '../service';
import {
  TABLE_VENDA_PRODUCAO_AGRICOLA,
  TABLE_VENDA_PRODUCAO_AGRICOLA_PENDING,
  TABLE_VENDA_PRODUCAO_AGRICOLA_SUCCESS,
  TABLE_VENDA_PRODUCAO_AGRICOLA_FAILURE,
  LIST_VENDA_PRODUCAO_AGRICOLA_SUCCESS_PDF
} from '../actionTypes';

function* sagaListVendaProducaoAgricola(action) {
  yield put({ type: TABLE_VENDA_PRODUCAO_AGRICOLA_PENDING })

  try {
    if (action.pdf === undefined || action.pdf === null || action.pdf === false) {
      const table = yield call(listVendaProducaoAgricola, action.args)
      yield put({ type: TABLE_VENDA_PRODUCAO_AGRICOLA_SUCCESS, table: table })
    } else {
      yield call(listVendaProducaoAgricolaPdf, action.args)

      yield put({
        type: LIST_VENDA_PRODUCAO_AGRICOLA_SUCCESS_PDF
      })
    }

  } catch (error) {

    yield put({ type: TABLE_VENDA_PRODUCAO_AGRICOLA_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListVendaProducaoAgricola() {
  yield takeLatest(TABLE_VENDA_PRODUCAO_AGRICOLA, sagaListVendaProducaoAgricola)
}