import { takeLatest, put, call } from 'redux-saga/effects';

import { getRelatorioPlanoContas, listPlanoContaCadastro } from '../service';
import { storage } from '@commons/storage';
import {
  TABLE_PLANO_CONTA_CADASTRO,
  TABLE_PLANO_CONTA_CADASTRO_PENDING,
  TABLE_PLANO_CONTA_CADASTRO_SUCCESS,
  TABLE_PLANO_CONTA_CADASTRO_FAILURE
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';

function* sagaListPlanoContaCadastro(action) {

  try {
    if (action.pdf === undefined || action.pdf === null || action.pdf === false) {
      yield put({ type: TABLE_PLANO_CONTA_CADASTRO_PENDING })
      const table = yield call(listPlanoContaCadastro, action.args)
      yield put({ type: TABLE_PLANO_CONTA_CADASTRO_SUCCESS, table: table })
    } else {
      yield call(getRelatorioPlanoContas, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id)
    }

  } catch (error) {

    yield put({ type: TABLE_PLANO_CONTA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListPlanoContaCadastro() {
  yield takeLatest(TABLE_PLANO_CONTA_CADASTRO, sagaListPlanoContaCadastro)
}