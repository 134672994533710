import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history, storage } from '@commons';

import { deleteAnoPecuarioCadastro, findAllAnoPecuariosByPropriedade } from '../service';
import {
    DELETE_ANO_PECUARIO_CADASTRO,
  DELETE_ANO_PECUARIO_CADASTRO_PENDING,
  DELETE_ANO_PECUARIO_CADASTRO_SUCCESS,
  DELETE_ANO_PECUARIO_CADASTRO_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'
import { CONFIG_SESSION } from '@handler/common/startSession';

function* sagaDeleteAnoPecuarioCadastro(action) {
  yield put({ type: DELETE_ANO_PECUARIO_CADASTRO_PENDING, id: action.id })

  try {
    const a = yield call(deleteAnoPecuarioCadastro, action.id);

    if (a.status !== 204) {
      throw new Error('API delete request failed');
    }

    // ATUALIZA ANO PECUARIO NA SESSAO
    const anosPecuario = yield call(findAllAnoPecuariosByPropriedade, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id);

    const configSessionAtual = JSON.parse(storage.get(CONFIG_SESSION));
    let anoSelecionado = configSessionAtual.anoPecuarioAtivo;

    if (anosPecuario.content.length === 1) {
      anoSelecionado = anosPecuario.content[0];
    } else {
      if (configSessionAtual.anoPecuarioAtivo !== null) {
        if (configSessionAtual.anoPecuarioAtivo.id === action.id) {
          anoSelecionado = null;
        }
      }
    }

    const configSession = {
      ...configSessionAtual,
      anosPecuario: anosPecuario.content,
      anoPecuarioAtivo: anoSelecionado
    }

    yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));

    yield put({ type: DELETE_ANO_PECUARIO_CADASTRO_SUCCESS, id: action.id });

    swal({
      title: 'Excluído!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });

  } catch (error) {
    yield put({ type: DELETE_ANO_PECUARIO_CADASTRO_FAILURE });

    AlertError('Falha ao excluir o registro! Tente novamente...');
  }
}

export default function* watchDeleteAnoPecuarioCadastro() {
  yield takeLatest(DELETE_ANO_PECUARIO_CADASTRO, sagaDeleteAnoPecuarioCadastro)
}
