import { takeLatest, put, call } from 'redux-saga/effects';

import { listContaPagarRecorrente } from '../service';
import {
    TABLE_CONTA_PAGAR_RECORRENTE,
    TABLE_CONTA_PAGAR_RECORRENTE_PENDING,
    TABLE_CONTA_PAGAR_RECORRENTE_SUCCESS,
    TABLE_CONTA_PAGAR_RECORRENTE_FAILURE
} from '../actionTypes';

function* sagaListContaPagarRecorrente(action) {
  yield put({ type: TABLE_CONTA_PAGAR_RECORRENTE_PENDING })

  try {
    const table = yield call(listContaPagarRecorrente, action.args)
    yield put({ type: TABLE_CONTA_PAGAR_RECORRENTE_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_CONTA_PAGAR_RECORRENTE_FAILURE })
  }
}

export default function* watchListContaPagarRecorrente() {
  yield takeLatest(TABLE_CONTA_PAGAR_RECORRENTE, sagaListContaPagarRecorrente)
}