import { takeLatest, put, call } from 'redux-saga/effects';

import { findManejoPlanejamentoCadastro } from '../service';
import {
  FIND_MANEJO_PLANEJAMENTO_CADASTRO,
  FIND_MANEJO_PLANEJAMENTO_CADASTRO_PENDING,
  FIND_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS,
  FIND_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE
} from '../actionTypes';
import { getManejosByPlanejamento } from '@handler/pecuaria/manejoExecucaoCadastro';

function* sagaFindManejoPlanejamentoCadastro(action) {
  yield put({ type: FIND_MANEJO_PLANEJAMENTO_CADASTRO_PENDING, id: action.id })

  try {
    const registro = yield call(findManejoPlanejamentoCadastro, action.id);
    const manejosRealizados = yield call(getManejosByPlanejamento, action.id);

    yield put({ type: FIND_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS, registro: registro, manejosRealizados: manejosRealizados })
  } catch (error) {
    yield put({ type: FIND_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE })
  }
}

export default function* watchFindManejoPlanejamentoCadastro() {
  yield takeLatest(FIND_MANEJO_PLANEJAMENTO_CADASTRO, sagaFindManejoPlanejamentoCadastro)
}
