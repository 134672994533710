import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  implantacaoEstoque,
  NEW_IMPLANTACAO_ESTOQUE,
  NEW_IMPLANTACAO_ESTOQUE_SUCCESS,
} from '@handler';

function* sagaNewImplantacaoEstoque(action) {

  yield put({ type: NEW_IMPLANTACAO_ESTOQUE_SUCCESS, registro: 
    {
      ...implantacaoEstoque.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
    } });
}

export default function* watchNewImplantacaoEstoque() {
  yield takeLatest(NEW_IMPLANTACAO_ESTOQUE, sagaNewImplantacaoEstoque)
}
