import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  controleContaBanco,
  NEW_CONTROLE_CONTA_BANCO,
  NEW_CONTROLE_CONTA_BANCO_SUCCESS,
} from '@handler';

function* sagaNewControleContaBanco(action) {

  yield put({ type: NEW_CONTROLE_CONTA_BANCO_SUCCESS, registro: 
    {
      ...controleContaBanco.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      conta: action.contaBanco
    } });
}

export default function* watchNewControleContaBanco() {
  yield takeLatest(NEW_CONTROLE_CONTA_BANCO, sagaNewControleContaBanco)
}
