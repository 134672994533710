import * as Yup from 'yup';

const newRegistro = {
    data: '',
    numero: '',
    serie: '',
    pessoa: null,
    naturezaOperacao: null,
    totalAnimais: 0,
    totalFrete: 0,
    totalCompra: 0,
    obs: '',
    compraDup: {
        geraDuplicata: false,
        movimentaFinanceiro: false,
        tipoDuplicata: null,
        contaGerencial: null,
        historico: '',
        tipoPagamento: null,
        contaCaixa: null,
        contaBanco: null,
        numeroParcelas: null,
    },
    itens: [],
    parcelas: [],
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    pessoa: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    naturezaOperacao: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    compraDup: Yup.object()
        .shape({
            historico: Yup.string()
                .min(2, 'O campo deve ter no mínimo 2 caracteres')
                .max(255, 'Campo deve ter menos do que 255 caracteres'),
        }),
    parcelas: Yup.array()
        .of(
            Yup.object().shape({
                dataVencimento: Yup.date()
                    .required('Este campo é obrigatório'),
                valorTotal: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .positive('Por Favor, informe um valor maior que zero')
                    .required("Este campo é obrigatório"),
                historico: Yup.string()
                    .min(2, 'O campo deve ter no mínimo 2 caracteres')
                    .max(255, 'Campo deve ter menos do que 255 caracteres'),
            })
        ),
    obs: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
});

export const compraAnimal = {
    newRegistro,
    validation
}