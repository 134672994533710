import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Card, CardBody, CardFooter, CardHeader, ListGroup, ListGroupItem } from "reactstrap";
import { NEW_PLANTIO_MANEJO_BY_PLANEJAMENTO, TABLE_PLANEJAMENTO } from "@handler";
import { formatNumber, formatDateDDMMYYYY } from '@components/common/format';
import moment from "moment";
import './styles.css';
import IconTextInfoNoRegisterComponent from "@components/common/IconTextInfoNoRegisterComponent";

function ActivityWindow({
    onClose,
}) {
    const dispatch = useDispatch();

    const startSessionReducer = useSelector((state) => state.entities.startSessionReducer);
    const { fazendaSelecionada, safraAtiva } = startSessionReducer.configSession;
    const planejamentoReducer = useSelector((state) => state.entities.planejamentoReducer);

    useEffect(() => {
        dispatch({ type: TABLE_PLANEJAMENTO, args: `?propriedade=${fazendaSelecionada.id}&safra=${safraAtiva.id}&status=0` });
    }, []);

    function renderStatus(value) {
        return <React.Fragment>
            <span className={`badge badge-${value.status === 'CANCELADO' ? 'danger' : (value.status === 'REALIZADO' ? 'primary' : (
                value.status === 'REALIZADO_PARCIAL' ? 'secondary' : 'warning'
            ))}`}>{value.statusDescricao}</span>
            {' '}
            {value.status === 'CANCELADO' || value.status === 'REALIZADO' || value.status === 'REALIZADO_PARCIAL'
                ? <React.Fragment />
                :
                <span className={`badge badge-${moment().startOf('day').isAfter(value.data) ? 'danger' : (moment().startOf('day').isBefore(value.data) ? 'primary' : 'dark')}`}>
                    {moment().startOf('day').isAfter(value.data) ? 'Atrasado' : (moment().startOf('day').isBefore(value.data) ? 'À Fazer' : 'Para Hoje')}</span>}
        </React.Fragment>;
    };

    const renderItem = (leftText, rightText) => {
        return (
            <div className="activity-card-item">
                <span>{leftText}</span>
                <span>{rightText}</span>
            </div>
        );
    }

    const renderTotal = (label, total) => {
        return (
            <div className="activity-card-total">
                <span>{label}</span>
                <span>{'R$ ' + formatNumber(total, 2)}</span>
            </div>
        );
    }

    function renderDosagem(value) {
        if (!value.configLancAgricultura) {
            if (safraAtiva !== null && safraAtiva !== undefined && safraAtiva.cultura !== null && safraAtiva.cultura !== undefined) {
                return formatNumber(value.dosagemHa, 4) + ' ' + value.produtoInsumo.unidadeAplicacao.sigla + ((safraAtiva.cultura.formaLancPlantio === 'POR_HA') ? (fazendaSelecionada.unidadeArea === undefined || fazendaSelecionada.unidadeArea === null ? '/ha' : '/' + fazendaSelecionada.unidadeArea.sigla) : '');
            } else {
                return formatNumber(value.dosagemHa, 4) + ' ' + value.produtoInsumo.unidadeAplicacao.sigla;
            }
        } else {
            return formatNumber(value.dosagemHa, 4) + ' ' + value.produtoInsumo.unidadeAplicacao.sigla + ((value.configLancAgriculturaTipo === 'POR_HA') ? (fazendaSelecionada.unidadeArea === undefined || fazendaSelecionada.unidadeArea === null ? '/ha' : '/' + fazendaSelecionada.unidadeArea.sigla) : '');
        }
    };

    const renderCard = (item) => {
        return (
            <div className="card-map">
                <Card key={`activity_${item.id}`}>
                    <CardHeader>
                        {renderStatus(item)}
                    </CardHeader>
                    <CardBody>
                        <ListGroup flush>
                            <ListGroupItem id="activity-group-item">
                                <h4>{item.procedimento.descricao}</h4>
                                <div>Data: {formatDateDDMMYYYY(item.data)}</div>
                                <div>Talhão: {item.talhao.nome}</div>
                            </ListGroupItem>
                            {(item.utilizaProdutoInsumo || item.utilizaMaquinario || item.utilizaFuncionario) && (
                                <ListGroupItem id="activity-group-item">
                                    {item.aplicacoes.map((aplicacao) => (
                                        renderItem(
                                            aplicacao.produtoInsumo.grupoText,
                                            renderDosagem(aplicacao)
                                        )
                                    ))}
                                    {item.utilizacaoMaquinarios.map((maq) => (
                                        renderItem(
                                            maq.maquinario.nome,
                                            maq.horasQuilometrosTotais
                                        )
                                    ))}
                                    {item.alocacaoFuncionarios.map((aFunc) => (
                                        renderItem(
                                            aFunc.funcionario.nome,
                                            aFunc.horasTrabalhadas
                                        )
                                    ))}
                                </ListGroupItem>
                            )}
                            <ListGroupItem id="activity-group-item">
                                {renderTotal(
                                    "Custo Estimado Talhão",
                                    item.custoTotalTalhao
                                )}
                                {renderTotal(
                                    "Custo Estimado / ha",
                                    item.custoTotalHa
                                )}
                            </ListGroupItem>
                        </ListGroup>
                    </CardBody>
                    <CardFooter>
                        <Link to='/agricultura/execucao/novo' title='Registrar Realização'>
                            <Button outline color='white' className='btn btn-info btn-sm'
                                onClick={() => dispatch({ type: NEW_PLANTIO_MANEJO_BY_PLANEJAMENTO, id: item.id })}
                                style={{ backgroundColor: '#175B28', borderColor: '#175B28', marginRight: '3px' }}>
                                Registrar Realização
                            </Button>
                        </Link>
                    </CardFooter>
                </Card>
            </div>
        );
    }

    return (
        <div className="map-menu-activity-container">
            <div className="map-menu-weather-header">
                <h3>Planejamentos à Realizar</h3>
                <Button className="btn btn-light" onClick={onClose}>
                    <i className='fa fa-arrow-left' />
                </Button>
            </div>

            {(planejamentoReducer && planejamentoReducer.table?.content?.length > 0) ?
                planejamentoReducer.table.content.map((item) => (
                    renderCard(item)
                )) : <IconTextInfoNoRegisterComponent title='Nenhuma atividade encontrada' />
            }
        </div>
    );
}

export default React.memo(ActivityWindow);
