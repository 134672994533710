import watchNewLocalEstoqueCadastro from './saga/new';
import watchFindLocalEstoqueCadastro from './saga/find';
import watchCreateLocalEstoqueCadastro from './saga/create';
import watchListAllLocalEstoqueCadastro from './saga/listAll';
import watchListLocalEstoqueCadastro from './saga/listTable';
import watchDeleteLocalEstoqueCadastro from './saga/delete';
import watchCancelLocalEstoqueCadastroRapido from './saga/cancel';

export const localEstoqueCadastroSaga = [
    watchNewLocalEstoqueCadastro(),
    watchFindLocalEstoqueCadastro(),
    watchCreateLocalEstoqueCadastro(),
    watchListAllLocalEstoqueCadastro(),
    watchListLocalEstoqueCadastro(),
    watchDeleteLocalEstoqueCadastro(),
    watchCancelLocalEstoqueCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
