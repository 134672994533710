import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/ndvi' };
/////////////////////////////////////////////////////////////////////////////////

export function listNdvi(param) {
  return httpService.get({...args, args: param});
}

export function listAllLatestNdvi(propriedadeId) {
  return httpService.get({ ...args, args: `/all-latest?propriedade=${propriedadeId}` });
}

export function findNdvi(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllNdviByTalhao(propriedade) {
  return httpService.get({ ...args, path: '/all?size=20000&page=0&propriedade=' + propriedade });
}
