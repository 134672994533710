import { takeLatest, put, call } from 'redux-saga/effects';
import { httpService, history } from '@commons';

import {
  UPDATE_CONTA_AGRARE,
  UPDATE_CONTA_AGRARE_PENDING,
  UPDATE_CONTA_AGRARE_SUCCESS,
  UPDATE_CONTA_AGRARE_FAILURE
} from '../actionTypes';
import { AlertError, AlertSuccess } from '@components/common/AlertToast';

const updateContaAgrare = async (id, payload) => {
  return httpService.putApiManager(`/clienteAgrare/update/${id}`, payload);
}

function* sagaUpdateContaAgrare(action) {
  yield put({ type: UPDATE_CONTA_AGRARE_PENDING })

  try {
    let register = {
      "status": action.data.status ?? false,
      "nomeConta": action.data.nomeConta,
      "nomeResponsavelConta": action.data.nomeResponsavelConta,
      "cpfCnpjResponsavelConta": action.data.cpfCnpjResponsavelConta,
      "emailPrincipal": action.data.emailPrincipal,
      "whatsApp": action.data.whatsApp,
      "idClienteAgrareGrupo": action.data.clienteAgrareGrupo.id,
      "localTrabalho": action.data.localTrabalho,
      "atividadeAgricola": action.data.atividadeAgricola
    }

    yield call(updateContaAgrare, action.data.id, register);

    yield put({ type: UPDATE_CONTA_AGRARE_SUCCESS })

    history.push('/corporate/contaProdutor');

    AlertSuccess('Conta Produtor atualizada com sucesso!');
  } catch (error) {
    yield put({ type: UPDATE_CONTA_AGRARE_FAILURE })
    // console.error(error) // eslint-disable-line

    AlertError('Falha ao atualizar a Conta. Tente novamente...');
  }
}

export default function* watchUpdateContaAgrare() {
  yield takeLatest(UPDATE_CONTA_AGRARE, sagaUpdateContaAgrare)
}
