import { takeLatest, put, call } from 'redux-saga/effects';

import { findImplantacaoAnimal } from '../service';
import {
  FIND_IMPLANTACAO_ANIMAL,
  FIND_IMPLANTACAO_ANIMAL_PENDING,
  FIND_IMPLANTACAO_ANIMAL_SUCCESS,
  FIND_IMPLANTACAO_ANIMAL_FAILURE
} from '../actionTypes';

function* sagaFindImplantacaoAnimal(action) {
  yield put({ type: FIND_IMPLANTACAO_ANIMAL_PENDING, id: action.id })

  try {

    const registro = yield call(findImplantacaoAnimal, action.id);

    yield put({ type: FIND_IMPLANTACAO_ANIMAL_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_IMPLANTACAO_ANIMAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindImplantacaoAnimal() {
  yield takeLatest(FIND_IMPLANTACAO_ANIMAL, sagaFindImplantacaoAnimal)
}
