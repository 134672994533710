import { takeLatest, put, call } from 'redux-saga/effects';

import {
  GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE,
  GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_PENDING,
  GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_SUCCESS,
  GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_FAILURE
} from '../actionTypes';
import { getSafraPossuiPendenciaEstoque } from '..';

function* sagaGetSafraPossuiPendenciaEstoque(action) {
  yield put({ type: GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_PENDING, id: action.id })

  try {

    const registro = yield call(getSafraPossuiPendenciaEstoque, action.idPropriedade, action.idSafra);

    yield put({ type: GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_SUCCESS, safraPossuiPendenciaEstoque: registro ?? false })

  } catch (error) {

    yield put({ type: GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_FAILURE })

  }
}

export default function* watchGetSafraPossuiPendenciaEstoque() {
  yield takeLatest(GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE, sagaGetSafraPossuiPendenciaEstoque)
}
