import { takeLatest, put, call } from 'redux-saga/effects';

import { findUsuarioByUserAuthId } from '../service';
import {
  FIND_USUARIO,
  FIND_USUARIO_PENDING,
  FIND_USUARIO_SUCCESS,
  FIND_USUARIO_FAILURE
} from '../actionTypes';

function* sagaFindUsuario(action) {
  yield put({ type: FIND_USUARIO_PENDING })

  try {
    const registro = yield call(findUsuarioByUserAuthId, action.idUserAuth);

    yield put({ type: FIND_USUARIO_SUCCESS, registro: registro })
  } catch (error) {
    yield put({ type: FIND_USUARIO_FAILURE })
  }
}

export default function* watchFindUsuario() {
  yield takeLatest(FIND_USUARIO, sagaFindUsuario)
}
