import * as Yup from 'yup';

const newRegistro = {
    ativo: true,
    descricao: '',
    contaBanco: null
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(100, 'Campo deve ter menos do que 100 caracteres')
        .required('Este campo é obrigatório'),
    contaBanco: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
});

export const contaCartaoCadastro = {
    newRegistro,
    validation
}