
export const NEW_FORNECEDOR_CADASTRO = 'NEW_FORNECEDOR_CADASTRO'
export const NEW_FORNECEDOR_CADASTRO_SUCCESS = 'NEW_FORNECEDOR_CADASTRO_SUCCESS'

export const CREATE_FORNECEDOR_CADASTRO = 'CREATE_FORNECEDOR_CADASTRO'
export const CREATE_FORNECEDOR_CADASTRO_PENDING = 'CREATE_FORNECEDOR_CADASTRO_PENDING'
export const CREATE_FORNECEDOR_CADASTRO_SUCCESS = 'CREATE_FORNECEDOR_CADASTRO_SUCCESS'
export const CREATE_FORNECEDOR_CADASTRO_FAILURE = 'CREATE_FORNECEDOR_CADASTRO_FAILURE'

export const CANCEL_FORNECEDOR_CADASTRO_RAPIDO = 'CANCEL_FORNECEDOR_CADASTRO_RAPIDO'
export const CANCEL_FORNECEDOR_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_FORNECEDOR_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_FORNECEDOR_CADASTRO = 'TABLE_FORNECEDOR_CADASTRO'
export const TABLE_FORNECEDOR_CADASTRO_PENDING = 'TABLE_FORNECEDOR_CADASTRO_PENDING'
export const TABLE_FORNECEDOR_CADASTRO_SUCCESS = 'TABLE_FORNECEDOR_CADASTRO_SUCCESS'
export const TABLE_FORNECEDOR_CADASTRO_FAILURE = 'TABLE_FORNECEDOR_CADASTRO_FAILURE'

export const LIST_FORNECEDOR_CADASTRO = 'LIST_FORNECEDOR_CADASTRO'
export const LIST_FORNECEDOR_CADASTRO_PENDING = 'LIST_FORNECEDOR_CADASTRO_PENDING'
export const LIST_FORNECEDOR_CADASTRO_SUCCESS = 'LIST_FORNECEDOR_CADASTRO_SUCCESS'
export const LIST_FORNECEDOR_CADASTRO_FAILURE = 'LIST_FORNECEDOR_CADASTRO_FAILURE'

export const FIND_FORNECEDOR_CADASTRO = 'FIND_FORNECEDOR_CADASTRO'
export const FIND_FORNECEDOR_CADASTRO_PENDING = 'FIND_FORNECEDOR_CADASTRO_PENDING'
export const FIND_FORNECEDOR_CADASTRO_SUCCESS = 'FIND_FORNECEDOR_CADASTRO_SUCCESS'
export const FIND_FORNECEDOR_CADASTRO_FAILURE = 'FIND_FORNECEDOR_CADASTRO_FAILURE'

export const DELETE_FORNECEDOR_CADASTRO = 'DELETE_FORNECEDOR_CADASTRO'
export const DELETE_FORNECEDOR_CADASTRO_PENDING = 'DELETE_FORNECEDOR_CADASTRO_PENDING'
export const DELETE_FORNECEDOR_CADASTRO_SUCCESS = 'DELETE_FORNECEDOR_CADASTRO_SUCCESS'
export const DELETE_FORNECEDOR_CADASTRO_FAILURE = 'DELETE_FORNECEDOR_CADASTRO_FAILURE'
