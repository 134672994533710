import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  vendaAnimal,
  NEW_VENDA_ANIMAL,
  NEW_VENDA_ANIMAL_SUCCESS,
} from '@handler';

function* sagaNewVendaAnimal(action) {

  yield put({ type: NEW_VENDA_ANIMAL_SUCCESS, registro: 
    {
      ...vendaAnimal.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      anoPecuario: JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo,
    } });
}

export default function* watchNewVendaAnimal() {
  yield takeLatest(NEW_VENDA_ANIMAL, sagaNewVendaAnimal)
}
