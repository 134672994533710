import { takeLatest, put, call } from 'redux-saga/effects';

import { listEstagioAplicacaoCadastro } from '../service';
import {
  TABLE_ESTAGIO_APLICACAO_CADASTRO,
  TABLE_ESTAGIO_APLICACAO_CADASTRO_PENDING,
  TABLE_ESTAGIO_APLICACAO_CADASTRO_SUCCESS,
  TABLE_ESTAGIO_APLICACAO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListEstagioAplicacaoCadastro(action) {
  yield put({ type: TABLE_ESTAGIO_APLICACAO_CADASTRO_PENDING });

  try {
    const table = yield call(listEstagioAplicacaoCadastro, action.args);
    yield put({ type: TABLE_ESTAGIO_APLICACAO_CADASTRO_SUCCESS, table: table });
  } catch (error) {
    yield put({ type: TABLE_ESTAGIO_APLICACAO_CADASTRO_FAILURE });
  }
}

export default function* watchListEstagioAplicacaoCadastro() {
  yield takeLatest(TABLE_ESTAGIO_APLICACAO_CADASTRO, sagaListEstagioAplicacaoCadastro);
}