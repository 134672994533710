import { takeLatest, put } from 'redux-saga/effects';

import {
  animalCadastro,
  NEW_ANIMAL_CADASTRO,
  NEW_ANIMAL_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewAnimalCadastro(action) {

  yield put({ 
    type: NEW_ANIMAL_CADASTRO_SUCCESS, 
    registro: animalCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido, 
  });
}

export default function* watchNewAnimalCadastro() {
  yield takeLatest(NEW_ANIMAL_CADASTRO, sagaNewAnimalCadastro)
}
