import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_PRODUTO_INSUMO_CADASTRO_RAPIDO,
    CANCEL_PRODUTO_INSUMO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelProdutoInsumoCadastroRapido() {
  yield put({ type: CANCEL_PRODUTO_INSUMO_CADASTRO_RAPIDO_SUCCESS })
}

export default function* watchCancelProdutoInsumoCadastroRapido() {
  yield takeLatest(CANCEL_PRODUTO_INSUMO_CADASTRO_RAPIDO, sagaCancelProdutoInsumoCadastroRapido)
}