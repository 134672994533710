import watchNewAnimalCadastro from './saga/new';
import watchFindAnimalCadastro from './saga/find';
import watchCreateAnimalCadastro from './saga/create';
import watchListAnimalCadastro from './saga/listTable';
import watchDeleteAnimalCadastro from './saga/delete';
import watchCancelAnimalCadastroRapido from './saga/cancel';

export const animalCadastroSaga = [
    watchNewAnimalCadastro(),
    watchFindAnimalCadastro(),
    watchCreateAnimalCadastro(),
    watchListAnimalCadastro(),
    watchDeleteAnimalCadastro(),
    watchCancelAnimalCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
