import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllEmitenteCadastro } from '../service';
import {
  LIST_EMITENTE_CADASTRO,
  LIST_EMITENTE_CADASTRO_PENDING,
  LIST_EMITENTE_CADASTRO_SUCCESS,
  LIST_EMITENTE_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAllEmitenteCadastro(action) {
  yield put({ type: LIST_EMITENTE_CADASTRO_PENDING })

  try {
    const list = yield call(findAllEmitenteCadastro)
    
    yield put({ type: LIST_EMITENTE_CADASTRO_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_EMITENTE_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllEmitenteCadastro() {
  yield takeLatest(LIST_EMITENTE_CADASTRO, sagaListAllEmitenteCadastro)
}