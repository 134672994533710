import { takeLatest, put, call } from 'redux-saga/effects';

import { findMotoristaCadastro } from '../service';
import {
  FIND_MOTORISTA_CADASTRO,
  FIND_MOTORISTA_CADASTRO_PENDING,
  FIND_MOTORISTA_CADASTRO_SUCCESS,
  FIND_MOTORISTA_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindMotoristaCadastro(action) {
  yield put({ type: FIND_MOTORISTA_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findMotoristaCadastro, action.id);

    yield put({ type: FIND_MOTORISTA_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_MOTORISTA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindMotoristaCadastro() {
  yield takeLatest(FIND_MOTORISTA_CADASTRO, sagaFindMotoristaCadastro)
}
