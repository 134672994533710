import { takeLatest, put } from 'redux-saga/effects';

import {
  motoristaCadastro,
  NEW_MOTORISTA_CADASTRO,
  NEW_MOTORISTA_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewMotoristaCadastro(action) {

  yield put({
    type: NEW_MOTORISTA_CADASTRO_SUCCESS,
    registro: motoristaCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
    status: true
  });
}

export default function* watchNewMotoristaCadastro() {
  yield takeLatest(NEW_MOTORISTA_CADASTRO, sagaNewMotoristaCadastro)
}
