import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllPropriedadeCadastro } from '../service';
import {
  LIST_PROPRIEDADE_CADASTRO,
  LIST_PROPRIEDADE_CADASTRO_PENDING,
  LIST_PROPRIEDADE_CADASTRO_SUCCESS,
  LIST_PROPRIEDADE_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAllPropriedadeCadastro(action) {
  yield put({ type: LIST_PROPRIEDADE_CADASTRO_PENDING })

  try {
    const list = yield call(findAllPropriedadeCadastro)
    
    yield put({ type: LIST_PROPRIEDADE_CADASTRO_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_PROPRIEDADE_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllPropriedadeCadastro() {
  yield takeLatest(LIST_PROPRIEDADE_CADASTRO, sagaListAllPropriedadeCadastro)
}