import { takeLatest, put, call } from 'redux-saga/effects';

import {
  NEW_FILTER_NEXT_PAGE_CONTA_AGRARE,
  NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_PENDING,
  NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_SUCCESS,
  NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_FAILURE,
} from '../actionTypes';
import { CONST_FILTER_CONTA_AGRARE } from '@commons/consts';
import { listContaAgrare } from './listTable';
import { storage, path } from '@commons';

function* sagaNewFilterNextPageContaAgrare(action) {
  yield put({ type: NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_PENDING })

  try {
    let user = JSON.parse(storage.get(path.sessionUser));
    let filterSaved = sessionStorage.getItem(CONST_FILTER_CONTA_AGRARE) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_CONTA_AGRARE)) : null;

    let newFilter = {
      ...filterSaved,
      rows: action.rows,
      page: action.page,
    };

    let list = yield call(listContaAgrare, user.id, `${newFilter.where}&page=${newFilter.page}&size=${newFilter.rows}`);

    sessionStorage.setItem(CONST_FILTER_CONTA_AGRARE, JSON.stringify(newFilter));

    yield put({
      type: NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_SUCCESS, list: list, filter: newFilter
    });
  } catch (error) {

    yield put({ type: NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_FAILURE })
    // console.error(error) // eslint-disable-line
  }
}

export default function* watchNewFilterNextPageContaAgrare() {
  yield takeLatest(NEW_FILTER_NEXT_PAGE_CONTA_AGRARE, sagaNewFilterNextPageContaAgrare)
}