import { takeLatest, put, call } from 'redux-saga/effects';

import { path, history, storage } from '@commons';

import { createPerfilUsuarioWhiteLabel, updatePerfilUsuarioWhiteLabel } from '../service';
import {
  CREATE_PERFIL_USUARIO_WHITE_LABEL,
  CREATE_PERFIL_USUARIO_WHITE_LABEL_PENDING,
  CREATE_PERFIL_USUARIO_WHITE_LABEL_SUCCESS,
  CREATE_PERFIL_USUARIO_WHITE_LABEL_FAILURE
} from '../actionTypes';

import { AlertSuccess, AlertWarning, AlertError } from '@components/common/AlertToast'

function* sagaCreatePerfilUsuarioWhiteLabel(action) {
  yield put({ type: CREATE_PERFIL_USUARIO_WHITE_LABEL_PENDING })

  try {
    if (action.data.showPartnerProfile === true) {
      action.data.permissionRoleWlRegisterUserProfile = false;
      action.data.permissionRoleWlRegisterPartner = false;
      action.data.permissionRoleWlRegisterKindPacking = false;
    }

    if (action.data.roles === null || action.data.roles === undefined || action.data.roles.lenght === 0) {
      yield put({ type: CREATE_PERFIL_USUARIO_WHITE_LABEL_FAILURE, registro: action.data })

      AlertWarning('Nenhuma permissão selecionada para o perfil');
    } else {
      var regraNaoSelecionada = true

      for (var role in action.data.roles) {
        if (action.data.roles[role].active) {
          regraNaoSelecionada = false;
        }
      }

      if (regraNaoSelecionada) {
        yield put({ type: CREATE_PERFIL_USUARIO_WHITE_LABEL_FAILURE, registro: action.data })

        AlertWarning('Nenhuma permissão selecionada para o perfil');
      } else {
        let user = JSON.parse(storage.get(path.sessionUser));

        var newData = {
          ...action.data,
          "identificationCodeWhiteLabel": user.identificationCodeWhiteLabel,
        }

        if (action.data.id === undefined || action.data.id === null) {
          yield call(createPerfilUsuarioWhiteLabel, newData)
        } else {
          yield call(updatePerfilUsuarioWhiteLabel, action.data)
        }

        yield put({ type: CREATE_PERFIL_USUARIO_WHITE_LABEL_SUCCESS, registro: action.data });

        if (!action.isCadastroRapido) {
          history.push('/corporate/perfilAcesso');

          AlertSuccess('Perfil atualizado com sucesso! Faça o login novamente para atualizar as permissões...');
        } else {
          AlertSuccess('Perfil salvo com sucesso!');
        }
      }
    }

  } catch (error) {
    yield put({ type: CREATE_PERFIL_USUARIO_WHITE_LABEL_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreatePerfilUsuarioWhiteLabel() {
  yield takeLatest(CREATE_PERFIL_USUARIO_WHITE_LABEL, sagaCreatePerfilUsuarioWhiteLabel)
}
