import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllContaChequePreDatadoCadastro } from '../service';
import {
  LIST_CONTA_CHEQUE_PRE_DATADO_CADASTRO,
  LIST_CONTA_CHEQUE_PRE_DATADO_CADASTRO_PENDING,
  LIST_CONTA_CHEQUE_PRE_DATADO_CADASTRO_SUCCESS,
  LIST_CONTA_CHEQUE_PRE_DATADO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAllContaChequePreDatadoCadastro(action) {
  yield put({ type: LIST_CONTA_CHEQUE_PRE_DATADO_CADASTRO_PENDING })

  try {
    const list = yield call(findAllContaChequePreDatadoCadastro)
    
    yield put({ type: LIST_CONTA_CHEQUE_PRE_DATADO_CADASTRO_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_CONTA_CHEQUE_PRE_DATADO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllContaChequePreDatadoCadastro() {
  yield takeLatest(LIST_CONTA_CHEQUE_PRE_DATADO_CADASTRO, sagaListAllContaChequePreDatadoCadastro)
}