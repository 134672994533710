


import watchNewVeiculoCadastro from './saga/new';
import watchFindVeiculoCadastro from './saga/find';
import watchCreateVeiculoCadastro from './saga/create';
import watchListAllVeiculoCadastro from './saga/listAll';
import watchListVeiculoCadastro from './saga/listTable';
import watchDeleteVeiculoCadastro from './saga/delete';
import watchCancelVeiculoCadastroRapido from './saga/cancel';

export const veiculoCadastroSaga = [
    watchNewVeiculoCadastro(),
    watchFindVeiculoCadastro(),
    watchCreateVeiculoCadastro(),
    watchListAllVeiculoCadastro(),
    watchListVeiculoCadastro(),
    watchDeleteVeiculoCadastro(),
    watchCancelVeiculoCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
