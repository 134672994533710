import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllEntradaEstoque } from '../service';
import {
  LIST_ENTRADA_ESTOQUE,
  LIST_ENTRADA_ESTOQUE_PENDING,
  LIST_ENTRADA_ESTOQUE_SUCCESS,
  LIST_ENTRADA_ESTOQUE_FAILURE
} from '../actionTypes';

function* sagaListAllEntradaEstoque(action) {
  yield put({ type: LIST_ENTRADA_ESTOQUE_PENDING })

  try {
    const list = yield call(findAllEntradaEstoque)
    
    yield put({ type: LIST_ENTRADA_ESTOQUE_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_ENTRADA_ESTOQUE_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllEntradaEstoque() {
  yield takeLatest(LIST_ENTRADA_ESTOQUE, sagaListAllEntradaEstoque)
}