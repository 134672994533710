import { takeLatest, put } from 'redux-saga/effects'

import { storage } from '@commons';
import {
  HAS_AUTHORITY_PAINEL_AGRARE,
  HAS_AUTHORITY_PENDING_PAINEL_AGRARE,
  HAS_AUTHORITY_SUCCESS_PAINEL_AGRARE,
  HAS_AUTHORITY_FAILURE_PAINEL_AGRARE
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';

function* sagaHasAuthorityPainelAgrare(action) {
  yield put({ type: HAS_AUTHORITY_PENDING_PAINEL_AGRARE })

  try {
    const configSession = JSON.parse(storage.get(CONFIG_SESSION));

    if (configSession.perfilUser !== null) {
      let hasAuthorityPainelAgrare = {
        agricultura: getAuthority(configSession.perfilUser, 'PAINEL_AGRARE_VISAO_AGRIC', configSession),
        pecuaria: getAuthority(configSession.perfilUser, 'PAINEL_AGRARE_VISAO_PEC', configSession),
        financeiro: getAuthority(configSession.perfilUser, 'PAINEL_AGRARE_VISAO_FIN', configSession),
        patrimonio: getAuthority(configSession.perfilUser, 'PAINEL_AGRARE_VISAO_PAT', configSession),
      };

      yield put({ type: HAS_AUTHORITY_SUCCESS_PAINEL_AGRARE, hasAuthorityPainelAgrare: hasAuthorityPainelAgrare });
    } else {
      yield put({ type: HAS_AUTHORITY_FAILURE_PAINEL_AGRARE });
    }

  } catch (error) {
    yield put({ type: HAS_AUTHORITY_FAILURE_PAINEL_AGRARE });
  }

}

function getAuthority(perfilUser, role, configSession) {
  let hasAuthority = false;

  if (perfilUser.id == 1) {
    hasAuthority = true;
  } else {
    for (let i = 0; i < configSession.perfilUser.perfilPropriedades.length; i++) {
      if (configSession.perfilUser.perfilPropriedades[i].propriedade.id === configSession.fazendaSelecionada.id && configSession.perfilUser.perfilPropriedades[i].permiteAcesso) {
        hasAuthority = true;
      }
    }
    if (hasAuthority) {
      hasAuthority = false;
      for (let i = 0; i < perfilUser.roles.length; i++) {
        if (perfilUser.roles[i].roleEnum.literal === role && perfilUser.roles[i].ativo) {
          hasAuthority = true;
        }
      }
    }
  }
  return hasAuthority;
}

export default function* watchHasAuthorityPainelAgrare() {
  yield takeLatest(HAS_AUTHORITY_PAINEL_AGRARE, sagaHasAuthorityPainelAgrare);
}
