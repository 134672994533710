import watchListAllRelatorioEstoqueAtual from './saga/estoqueAtual';
import watchListAllRelatorioMovimentacoesProduto from './saga/movimentacoesProduto';

export const relatorioEstoqueAtualSaga = [
    watchListAllRelatorioEstoqueAtual(),
    watchListAllRelatorioMovimentacoesProduto(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
