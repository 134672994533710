import { path, httpService } from '@commons';

///////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/dash/fin' };
///////////////////////////////////////////////////////////////////////////////

export function getTotaisFinanceiro(propriedade, datas) {
    return httpService.get({ ...args, args: '/totais/' + propriedade + '?datas=' + datas });
}

export function getdadosGraficoSaldoContas(propriedade, inicio, fim) {
    return httpService.get({ ...args, args: '/graficoSaldoContas/' + propriedade + '?inicio=' + inicio + '&fim=' + fim });
}
