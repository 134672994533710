import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_ANIMAL_LOTE_CADASTRO_RAPIDO,
    CANCEL_ANIMAL_LOTE_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelAnimalLoteCadastroRapido() {
  yield put({ type: CANCEL_ANIMAL_LOTE_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelAnimalLoteCadastroRapido() {
  yield takeLatest(CANCEL_ANIMAL_LOTE_CADASTRO_RAPIDO, sagaCancelAnimalLoteCadastroRapido)
}