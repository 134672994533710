import { takeLatest, put, call } from 'redux-saga/effects';
import moment from "moment";

import {
  manejoExecucaoCadastro,
  importManejoPecuario,
  NEW_MANEJO_EXECUCAO_BY_PLANEJAMENTO,
  NEW_MANEJO_EXECUCAO_CADASTRO_SUCCESS,
  NEW_MANEJO_EXECUCAO_BY_PLANEJAMENTO_PENDING,
} from '@handler';

function* sagaNewPlantioManejoByPlanejamento(action) {
  yield put({ type: NEW_MANEJO_EXECUCAO_BY_PLANEJAMENTO_PENDING })

  const planejamento = yield call(importManejoPecuario, action.id);

  yield put({
    type: NEW_MANEJO_EXECUCAO_CADASTRO_SUCCESS, registro: {
      ...manejoExecucaoCadastro.newRegistro,
      ...planejamento,
      data: moment().startOf('day').format('YYYY-MM-DD'),
    }
  });

}

export default function* watchNewPlantioManejoByPlanejamento() {
  yield takeLatest(NEW_MANEJO_EXECUCAO_BY_PLANEJAMENTO, sagaNewPlantioManejoByPlanejamento)
}
