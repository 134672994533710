
import {
    NEW_USUARIO_SUCCESS,
    TABLE_USUARIO_PENDING,
    TABLE_USUARIO_SUCCESS,
    TABLE_USUARIO_FAILURE,
    LIST_USUARIO_PENDING,
    LIST_USUARIO_SUCCESS,
    LIST_USUARIO_FAILURE,
    FIND_USUARIO_PENDING,
    FIND_USUARIO_SUCCESS,
    FIND_USUARIO_FAILURE,
    FIND_USUARIO_RESUMED_PENDING,
    FIND_USUARIO_RESUMED_SUCCESS,
    FIND_USUARIO_RESUMED_FAILURE,
    DELETE_USUARIO_PENDING,
    DELETE_USUARIO_SUCCESS,
    DELETE_USUARIO_FAILURE,
    CREATE_USUARIO_PENDING,
    CREATE_USUARIO_SUCCESS,
    CREATE_USUARIO_FAILURE,
    ACTIVE_INACTIVE_USUARIO_FAILURE,
    ACTIVE_INACTIVE_USUARIO_SUCCESS,
    ACTIVE_INACTIVE_USUARIO_PENDING,
    UPDATE_PERFIL_PERMISSAO_USUARIO_FAILURE,
    UPDATE_PERFIL_PERMISSAO_USUARIO_SUCCESS,
    UPDATE_PERFIL_PERMISSAO_USUARIO_PENDING,
} from './actionTypes';


const initialState = { list: null, loading: false }


function usuarioReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_USUARIO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case LIST_USUARIO_PENDING:
        case FIND_USUARIO_PENDING:
        case FIND_USUARIO_RESUMED_PENDING:
        case DELETE_USUARIO_PENDING:
        case CREATE_USUARIO_PENDING:
        case TABLE_USUARIO_PENDING:
        case ACTIVE_INACTIVE_USUARIO_PENDING:
        case UPDATE_PERFIL_PERMISSAO_USUARIO_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_USUARIO_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_USUARIO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_USUARIO_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_USUARIO_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_USUARIO_SUCCESS:
        case FIND_USUARIO_RESUMED_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_USUARIO_FAILURE:
        case FIND_USUARIO_RESUMED_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_USUARIO_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_USUARIO_FAILURE:
        case CREATE_USUARIO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case DELETE_USUARIO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case ACTIVE_INACTIVE_USUARIO_FAILURE:
        case ACTIVE_INACTIVE_USUARIO_SUCCESS:
        case UPDATE_PERFIL_PERMISSAO_USUARIO_SUCCESS:
        case UPDATE_PERFIL_PERMISSAO_USUARIO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { usuarioReducer };