export const TOTAIS_DASHBOARD_PATRIMONIO = 'TOTAIS_DASHBOARD_PATRIMONIO'

export const TOTAIS_DASHBOARD_PATRIMONIO_PENDING = 'TOTAIS_DASHBOARD_PATRIMONIO_PENDING'
export const TOTAIS_DASHBOARD_PATRIMONIO_SUCCESS = 'TOTAIS_DASHBOARD_PATRIMONIO_SUCCESS'
export const TOTAIS_DASHBOARD_PATRIMONIO_FAILURE = 'TOTAIS_DASHBOARD_PATRIMONIO_FAILURE'

export const GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO = 'GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO'
export const GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_PENDING = 'GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_PENDING'
export const GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_SUCCESS = 'GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_SUCCESS'
export const GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_FAILURE = 'GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_FAILURE'

export const TOTAIS_DASHBOARD_PATRIMONIO_FINISH = 'TOTAIS_DASHBOARD_PATRIMONIO_FINISH'
