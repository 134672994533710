import * as Yup from 'yup';

const newRegistro = {
    status: true,
    nome: '',
    cpf: '',
    dataNascimento: '',
    celular: '',
    email: '',
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    email: Yup.string()
        .email('E-mail inválido')
        .max(150, 'Campo deve ter menos do que 150 caracteres'),
    celular: Yup.string()
        .max(20, 'Campo deve ter menos do que 20 caracteres!'),
});

export const motoristaCadastro = {
    newRegistro,
    validation
}