import { takeLatest, put, call } from 'redux-saga/effects';

import { listProcedimentoPecuarioCadastro } from '../service';
import {
    TABLE_PROCEDIMENTO_PECUARIO_CADASTRO,
    TABLE_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING,
    TABLE_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS,
    TABLE_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListProcedimentoPecuarioCadastro(action) {
  yield put({ type: TABLE_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING })

  try {
    const table = yield call(listProcedimentoPecuarioCadastro, action.args)
    yield put({ type: TABLE_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE })
  }
}

export default function* watchListProcedimentoPecuarioCadastro() {
  yield takeLatest(TABLE_PROCEDIMENTO_PECUARIO_CADASTRO, sagaListProcedimentoPecuarioCadastro)
}