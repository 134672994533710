import { takeLatest, put, call } from 'redux-saga/effects';

import { findContaChequePreDatadoCadastro } from '../service';
import {
  FIND_CONTA_CHEQUE_PRE_DATADO_CADASTRO,
  FIND_CONTA_CHEQUE_PRE_DATADO_CADASTRO_PENDING,
  FIND_CONTA_CHEQUE_PRE_DATADO_CADASTRO_SUCCESS,
  FIND_CONTA_CHEQUE_PRE_DATADO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindContaChequePreDatadoCadastro(action) {
  yield put({ type: FIND_CONTA_CHEQUE_PRE_DATADO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findContaChequePreDatadoCadastro, action.id);

    yield put({ type: FIND_CONTA_CHEQUE_PRE_DATADO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_CONTA_CHEQUE_PRE_DATADO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindContaChequePreDatadoCadastro() {
  yield takeLatest(FIND_CONTA_CHEQUE_PRE_DATADO_CADASTRO, sagaFindContaChequePreDatadoCadastro)
}
