
export const NEW_IDENTIFICADOR_CADASTRO = 'NEW_IDENTIFICADOR_CADASTRO'
export const NEW_IDENTIFICADOR_CADASTRO_SUCCESS = 'NEW_IDENTIFICADOR_CADASTRO_SUCCESS'

export const CREATE_IDENTIFICADOR_CADASTRO = 'CREATE_IDENTIFICADOR_CADASTRO'
export const CREATE_IDENTIFICADOR_CADASTRO_PENDING = 'CREATE_IDENTIFICADOR_CADASTRO_PENDING'
export const CREATE_IDENTIFICADOR_CADASTRO_SUCCESS = 'CREATE_IDENTIFICADOR_CADASTRO_SUCCESS'
export const CREATE_IDENTIFICADOR_CADASTRO_FAILURE = 'CREATE_IDENTIFICADOR_CADASTRO_FAILURE'

export const CANCEL_IDENTIFICADOR_CADASTRO_RAPIDO = 'CANCEL_IDENTIFICADOR_CADASTRO_RAPIDO'
export const CANCEL_IDENTIFICADOR_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_IDENTIFICADOR_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_IDENTIFICADOR_CADASTRO = 'TABLE_IDENTIFICADOR_CADASTRO'
export const TABLE_IDENTIFICADOR_CADASTRO_PENDING = 'TABLE_IDENTIFICADOR_CADASTRO_PENDING'
export const TABLE_IDENTIFICADOR_CADASTRO_SUCCESS = 'TABLE_IDENTIFICADOR_CADASTRO_SUCCESS'
export const TABLE_IDENTIFICADOR_CADASTRO_FAILURE = 'TABLE_IDENTIFICADOR_CADASTRO_FAILURE'

export const LIST_IDENTIFICADOR_CADASTRO = 'LIST_IDENTIFICADOR_CADASTRO'
export const LIST_IDENTIFICADOR_CADASTRO_PENDING = 'LIST_IDENTIFICADOR_CADASTRO_PENDING'
export const LIST_IDENTIFICADOR_CADASTRO_SUCCESS = 'LIST_IDENTIFICADOR_CADASTRO_SUCCESS'
export const LIST_IDENTIFICADOR_CADASTRO_FAILURE = 'LIST_IDENTIFICADOR_CADASTRO_FAILURE'

export const FIND_IDENTIFICADOR_CADASTRO = 'FIND_IDENTIFICADOR_CADASTRO'
export const FIND_IDENTIFICADOR_CADASTRO_PENDING = 'FIND_IDENTIFICADOR_CADASTRO_PENDING'
export const FIND_IDENTIFICADOR_CADASTRO_SUCCESS = 'FIND_IDENTIFICADOR_CADASTRO_SUCCESS'
export const FIND_IDENTIFICADOR_CADASTRO_FAILURE = 'FIND_IDENTIFICADOR_CADASTRO_FAILURE'

export const DELETE_IDENTIFICADOR_CADASTRO = 'DELETE_IDENTIFICADOR_CADASTRO'
export const DELETE_IDENTIFICADOR_CADASTRO_PENDING = 'DELETE_IDENTIFICADOR_CADASTRO_PENDING'
export const DELETE_IDENTIFICADOR_CADASTRO_SUCCESS = 'DELETE_IDENTIFICADOR_CADASTRO_SUCCESS'
export const DELETE_IDENTIFICADOR_CADASTRO_FAILURE = 'DELETE_IDENTIFICADOR_CADASTRO_FAILURE'
