import { takeLatest, put, call } from 'redux-saga/effects';

import {
  produtoInsumoCadastro,
  NEW_PRODUTO_INSUMO_CADASTRO,
  NEW_PRODUTO_INSUMO_CADASTRO_SUCCESS,
} from '@handler';
import { findAllPropriedadeCadastro } from '@handler/common/propriedadeCadastro';
import { NEW_PRODUTO_INSUMO_CADASTRO_PENDING } from '../actionTypes';

function* sagaNewProdutoInsumoCadastro(action) {
  yield put({
    type: NEW_PRODUTO_INSUMO_CADASTRO_PENDING
  });
  const listPropriedades = yield call(findAllPropriedadeCadastro);

  let estoqueInsumo = [];
  for (let index = 0; index < listPropriedades.length; index++) {
    const prop = listPropriedades[index];
    estoqueInsumo = estoqueInsumo.concat({
      propriedade: prop,
      custo: 0.0,
      controlaEstoque: (action.novoProdutoVinculoFornecedor !== undefined && action.novoProdutoVinculoFornecedor !== null) ? true : false,
      estoqueMinimo: 0.0,
      estoqueAtual: 0.0,
    })
  }

  let novoRegistro = {
    ...produtoInsumoCadastro.newRegistro,
    produtoInsumoEstoques: estoqueInsumo,
    produtoInsumoFornecedores: []
  };

  if (action.novoProdutoDescricao !== undefined && action.novoProdutoDescricao !== null) {
    novoRegistro = {
      ...novoRegistro,
      descricao: action.novoProdutoDescricao
    }
  }

  if (action.novoProdutoVinculoFornecedor !== undefined && action.novoProdutoVinculoFornecedor !== null) {
    novoRegistro.produtoInsumoFornecedores.push({
      ...action.novoProdutoVinculoFornecedor
    });
  }

  yield put({
    type: NEW_PRODUTO_INSUMO_CADASTRO_SUCCESS,
    registro: novoRegistro,
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewProdutoInsumoCadastro() {
  yield takeLatest(NEW_PRODUTO_INSUMO_CADASTRO, sagaNewProdutoInsumoCadastro)
}
