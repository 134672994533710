import {
    TOTAIS_DASHBOARD_FINANCEIRO_PENDING,
    TOTAIS_DASHBOARD_FINANCEIRO_SUCCESS,
    TOTAIS_DASHBOARD_FINANCEIRO_FAILURE,
    GRAFICO_SALDO_CONTAS_DASHBOARD_FINANCEIRO_PENDING,
    GRAFICO_SALDO_CONTAS_DASHBOARD_FINANCEIRO_SUCCESS,
    GRAFICO_SALDO_CONTAS_DASHBOARD_FINANCEIRO_FAILURE,
} from './actionTypes';

const totais = {
    "totalAPagar": 0,
    "totalAPagarPrevisto": 0,
    "totalAPagarPagoPercentual": 100,
    "totalAReceber": 0,
    "totalAReceberPrevisto": 0,
    "totalAReceberPagoPercentual": 100
}

const initialState = { totais: totais, dadosGraficoSaldoContas: [['', 'Saldo']], loading: false }

function dashboardFinanceiroReducer(state = initialState, action) {

    switch (action.type) {
        case TOTAIS_DASHBOARD_FINANCEIRO_PENDING:
        case GRAFICO_SALDO_CONTAS_DASHBOARD_FINANCEIRO_PENDING:
            return {
                ...state,
                loading: true,
            }
        case TOTAIS_DASHBOARD_FINANCEIRO_SUCCESS:
            return {
                ...state,
                totais: action.totais,
                // loading: false
            }
        case TOTAIS_DASHBOARD_FINANCEIRO_FAILURE:
            return {
                ...state,
                // loading: false
            }
        case GRAFICO_SALDO_CONTAS_DASHBOARD_FINANCEIRO_SUCCESS:
            return {
                ...state,
                dadosGraficoSaldoContas: action.dadosGraficoSaldoContas,
                // loading: false
            }
        case GRAFICO_SALDO_CONTAS_DASHBOARD_FINANCEIRO_FAILURE:
            return {
                ...state,
                dadosGraficoSaldoContas: [['', 'Saldo']],
                // loading: false
            }
        default:
            return state
    }
}

export { dashboardFinanceiroReducer };