import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deleteControleContaCaixa, deleteControleContaCaixaTransf } from '../service';
import {
  DELETE_CONTROLE_CONTA_CAIXA,
  DELETE_CONTROLE_CONTA_CAIXA_PENDING,
  DELETE_CONTROLE_CONTA_CAIXA_SUCCESS,
  DELETE_CONTROLE_CONTA_CAIXA_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaDeleteControleContaCaixa(action) {
  yield put({ type: DELETE_CONTROLE_CONTA_CAIXA_PENDING, id: action.id })

  try {
    let a;
    if (action.origemLancamento === 'TRANSFERENCIA') {
      a = yield call(deleteControleContaCaixaTransf, action.id);
    } else {
      a = yield call(deleteControleContaCaixa, action.id);
    }

    if (a.status !== 204) {
      throw new Error('API delete request failed');
    }
    yield put({ type: DELETE_CONTROLE_CONTA_CAIXA_SUCCESS, id: action.id });

    swal({
      title: 'Excluído!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });

  } catch (error) {

    yield put({ type: DELETE_CONTROLE_CONTA_CAIXA_FAILURE });

    AlertError('Falha ao excluir o registro! Verifique se a conta não possui vínculo com outra conta gerencial ou em lançamentos no financeiro.');
  }
}

export default function* watchDeleteControleContaCaixa() {
  yield takeLatest(DELETE_CONTROLE_CONTA_CAIXA, sagaDeleteControleContaCaixa)
}
