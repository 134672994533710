export const FINANCEIRO_TIPO_PAGAMENTO_ENUM = [
    {
        id: 'BOLETO_BANCARIO',
        descricao: "Boleto Bancário",
    },
    {
        id: 'CARNE',
        descricao: "Carnê",
    },
    {
        id: 'CARTAO_CREDITO',
        descricao: "Cartão de Crédito",
    },
    {
        id: 'CARTAO_DEBITO',
        descricao: "Cartão de Débito",
    },
    // {
    //     id: 'CARTAO_VOUCHER',
    //     descricao: "Cartão Voucher",
    // },
    {
        id: 'CHEQUE_A_VISTA',
        descricao: "Cheque",
    },
    // {
    //     id: 'CHEQUE_PRE_DATADO',
    //     descricao: "Cheque Pré-Datado",
    // },
    {
        id: 'CREDITO_EM_CONTA',
        descricao: "Crédito em Conta",
    },
    {
        id: 'DEBITO_EM_CONTA',
        descricao: "Débito em Conta",
    },
    {
        id: 'DINHEIRO',
        descricao: "Dinheiro",
    },
    {
        id: 'GUIA',
        descricao: "Guia",
    },
    {
        id: 'PIX',
        descricao: "PIX",
    },
    {
        id: 'TRANSF_BANCARIA',
        descricao: "Transferência Bancária",
    },
];