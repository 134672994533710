import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deleteCompraAnimal } from '../service';
import {
  DELETE_COMPRA_ANIMAL,
  DELETE_COMPRA_ANIMAL_PENDING,
  DELETE_COMPRA_ANIMAL_SUCCESS,
  DELETE_COMPRA_ANIMAL_FAILURE,
} from '../actionTypes';

import { AlertError, AlertWarning } from '@components/common/AlertToast'

function* sagaDeleteCompraAnimal(action) {
  yield put({ type: DELETE_COMPRA_ANIMAL_PENDING, id: action.id })

  try {
    const a = yield call(deleteCompraAnimal, action.id);

    if (a.status !== 204) {
      throw new Error('API delete request failed');
    }
    yield put({ type: DELETE_COMPRA_ANIMAL_SUCCESS, id: action.id });

    swal({
      title: 'Excluído!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });

  } catch (error) {

    yield put({ type: DELETE_COMPRA_ANIMAL_FAILURE });

    if (error.response.status === 403) {
      AlertWarning('Compra não pode ser excluída');
    } else {
      AlertError('Falha ao excluir a Compra! Tente novamente...');
    }
  }
}

export default function* watchDeleteCompraAnimal() {
  yield takeLatest(DELETE_COMPRA_ANIMAL, sagaDeleteCompraAnimal)
}
