import { takeLatest, put, call } from 'redux-saga/effects';

import { findContaPagar } from '../service';
import {
  FIND_CONTA_PAGAR,
  FIND_CONTA_PAGAR_PENDING,
  FIND_CONTA_PAGAR_SUCCESS,
  FIND_CONTA_PAGAR_FAILURE
} from '../actionTypes';

function* sagaFindContaPagar(action) {
  yield put({ type: FIND_CONTA_PAGAR_PENDING, id: action.id })

  try {

    const registro = yield call(findContaPagar, action.id);

    yield put({ type: FIND_CONTA_PAGAR_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_CONTA_PAGAR_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindContaPagar() {
  yield takeLatest(FIND_CONTA_PAGAR, sagaFindContaPagar)
}
