import { takeLatest, put, call } from 'redux-saga/effects';

import { findPlanejamento } from '../service';
import {
  FIND_PLANEJAMENTO,
  FIND_PLANEJAMENTO_PENDING,
  FIND_PLANEJAMENTO_SUCCESS,
  FIND_PLANEJAMENTO_FAILURE
} from '../actionTypes';
import { getPlantiosByPlanejamento } from '@handler';

function* sagaFindPlanejamento(action) {
  yield put({ type: FIND_PLANEJAMENTO_PENDING, id: action.id })

  try {

    const registro = yield call(findPlanejamento, action.id);
    const plantiosRealizados = yield call(getPlantiosByPlanejamento, action.id);

    yield put({ type: FIND_PLANEJAMENTO_SUCCESS, registro: {
      ...registro,
      replicarLancamento: false,
      talhoesList: []
    }, plantiosRealizados: plantiosRealizados })

  } catch (error) {

    yield put({ type: FIND_PLANEJAMENTO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindPlanejamento() {
  yield takeLatest(FIND_PLANEJAMENTO, sagaFindPlanejamento)
}
