import { takeLatest, put, call } from 'redux-saga/effects'

import { httpAuthService } from '@commons';
import { history } from '@commons';
import swal from 'sweetalert';

import {
  REGISTER,
  REGISTER_PENDING,
  REGISTER_SUCCESS,
  REGISTER_FAILURE
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'
import { TEXT_COMPANY_ONFARM } from '@commons/consts';

const register = async (values) => {
  await httpAuthService.register(values, false);
}

function* sagaRegister(action) {
  yield put({ type: REGISTER_PENDING })

  try {

    yield call(register, action.data);

    yield put({ type: REGISTER_SUCCESS });

    swal({
      title: `Cadastro efetuado com sucesso! Verifique a caixa de entrada do e-mail informado para confirmar sua conta ${TEXT_COMPANY_ONFARM}.`,
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.push('/login');
    });
  } catch (error) {
    yield put({ type: REGISTER_FAILURE });

    AlertError(error.text);
  }

}

export default function* watchRegister() {
  yield takeLatest(REGISTER, sagaRegister);
}
