import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { estornarPagamento } from '../service';
import {
  CONTA_PAGAR_ESTORNAR_PAGAMENTO,
  CONTA_PAGAR_ESTORNAR_PAGAMENTO_PENDING,
  CONTA_PAGAR_ESTORNAR_PAGAMENTO_SUCCESS,
  CONTA_PAGAR_ESTORNAR_PAGAMENTO_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaEstornarPagamentoContaPagar(action) {
  yield put({ type: CONTA_PAGAR_ESTORNAR_PAGAMENTO_PENDING, id: action.id })

  try {
    yield call(estornarPagamento, action.idConta, action.idPagamento);

    yield put({ type: CONTA_PAGAR_ESTORNAR_PAGAMENTO_SUCCESS });

    swal({
      title: 'Pagamento estornado com sucesso!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      window.location.reload();
    });

  } catch (error) {

    yield put({ type: CONTA_PAGAR_ESTORNAR_PAGAMENTO_FAILURE });

    AlertError('Falha ao excluir o registro! Verifique se a conta não possui vínculo com outra conta gerencial ou em lançamentos no financeiro.');
  }
}

export default function* watchEstornarPagamentoContaPagar() {
  yield takeLatest(CONTA_PAGAR_ESTORNAR_PAGAMENTO, sagaEstornarPagamentoContaPagar)
}
