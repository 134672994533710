
export const NEW_USUARIO = 'NEW_USUARIO'
export const NEW_USUARIO_SUCCESS = 'NEW_USUARIO_SUCCESS'

export const CREATE_USUARIO = 'CREATE_USUARIO'
export const CREATE_USUARIO_PENDING = 'CREATE_USUARIO_PENDING'
export const CREATE_USUARIO_SUCCESS = 'CREATE_USUARIO_SUCCESS'
export const CREATE_USUARIO_FAILURE = 'CREATE_USUARIO_FAILURE'

export const UPDATE_MEU_PERFIL_USUARIO = 'UPDATE_MEU_PERFIL_USUARIO'
export const UPDATE_MEU_PERFIL_USUARIO_PENDING = 'UPDATE_MEU_PERFIL_USUARIO_PENDING'
export const UPDATE_MEU_PERFIL_USUARIO_SUCCESS = 'UPDATE_MEU_PERFIL_USUARIO_SUCCESS'
export const UPDATE_MEU_PERFIL_USUARIO_FAILURE = 'UPDATE_MEU_PERFIL_USUARIO_FAILURE'

export const TABLE_USUARIO = 'TABLE_USUARIO'
export const TABLE_USUARIO_PENDING = 'TABLE_USUARIO_PENDING'
export const TABLE_USUARIO_SUCCESS = 'TABLE_USUARIO_SUCCESS'
export const TABLE_USUARIO_FAILURE = 'TABLE_USUARIO_FAILURE'

export const LIST_USUARIO = 'LIST_USUARIO'
export const LIST_USUARIO_PENDING = 'LIST_USUARIO_PENDING'
export const LIST_USUARIO_SUCCESS = 'LIST_USUARIO_SUCCESS'
export const LIST_USUARIO_FAILURE = 'LIST_USUARIO_FAILURE'

export const FIND_USUARIO = 'FIND_USUARIO'
export const FIND_USUARIO_PENDING = 'FIND_USUARIO_PENDING'
export const FIND_USUARIO_SUCCESS = 'FIND_USUARIO_SUCCESS'
export const FIND_USUARIO_FAILURE = 'FIND_USUARIO_FAILURE'

export const FIND_USUARIO_RESUMED = 'FIND_USUARIO_RESUMED'
export const FIND_USUARIO_RESUMED_PENDING = 'FIND_USUARIO_RESUMED_PENDING'
export const FIND_USUARIO_RESUMED_SUCCESS = 'FIND_USUARIO_RESUMED_SUCCESS'
export const FIND_USUARIO_RESUMED_FAILURE = 'FIND_USUARIO_RESUMED_FAILURE'

export const DELETE_USUARIO = 'DELETE_USUARIO'
export const DELETE_USUARIO_PENDING = 'DELETE_USUARIO_PENDING'
export const DELETE_USUARIO_SUCCESS = 'DELETE_USUARIO_SUCCESS'
export const DELETE_USUARIO_FAILURE = 'DELETE_USUARIO_FAILURE'

export const ACTIVE_INACTIVE_USUARIO = 'ACTIVE_INACTIVE_USUARIO'
export const ACTIVE_INACTIVE_USUARIO_PENDING = 'ACTIVE_INACTIVE_USUARIO_PENDING'
export const ACTIVE_INACTIVE_USUARIO_SUCCESS = 'ACTIVE_INACTIVE_USUARIO_SUCCESS'
export const ACTIVE_INACTIVE_USUARIO_FAILURE = 'ACTIVE_INACTIVE_USUARIO_FAILURE'

export const UPDATE_PERFIL_PERMISSAO_USUARIO = 'UPDATE_PERFIL_PERMISSAO_USUARIO'
export const UPDATE_PERFIL_PERMISSAO_USUARIO_PENDING = 'UPDATE_PERFIL_PERMISSAO_USUARIO_PENDING'
export const UPDATE_PERFIL_PERMISSAO_USUARIO_SUCCESS = 'UPDATE_PERFIL_PERMISSAO_USUARIO_SUCCESS'
export const UPDATE_PERFIL_PERMISSAO_USUARIO_FAILURE = 'UPDATE_PERFIL_PERMISSAO_USUARIO_FAILURE'