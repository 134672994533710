import { takeLatest, put, call } from 'redux-saga/effects';

import { filterPagamentos, getLancamentos } from '../service';
import { storage } from '@commons/storage';
import {
  NEW_FILTER_NEXT_PAGE_CONTA_PAGAR,
  NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_PENDING,
  NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_SUCCESS,
  NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_FAILURE,
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { formatDateDDMMYYYY } from '@components/common/format';
import { CONST_FILTER_CONTAS_PAGAR } from '@commons/consts';

function* sagaNewFilterNextPageContaPagar(action) {
  yield put({ type: NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_PENDING })

  try {
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

    let filterSaved = sessionStorage.getItem(CONST_FILTER_CONTAS_PAGAR) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_CONTAS_PAGAR)) : null;

    const datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

    let newFilter = {
      ...filterSaved,
      rows: action.rows,
      page: action.page,
    };

    let list = null;
    if (newFilter.tipoStatusSituacao === 'PAGAMENTOS') { 
      list = yield call(filterPagamentos, idFazenda, datas, `&page=${newFilter.page}&size=${newFilter.rows}${newFilter.where}`);
    } else {
      list = yield call(getLancamentos, idFazenda, datas, `&page=${newFilter.page}&size=${newFilter.rows}${newFilter.where}`);
    }

    sessionStorage.setItem(CONST_FILTER_CONTAS_PAGAR, JSON.stringify(newFilter));

    yield put({
      type: NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_SUCCESS, list: list, filter: newFilter
    });
  } catch (error) {

    yield put({ type: NEW_FILTER_NEXT_PAGE_CONTA_PAGAR_FAILURE })
    // console.error(error) // eslint-disable-line
  }
}

export default function* watchNewFilterNextPageContaPagar() {
  yield takeLatest(NEW_FILTER_NEXT_PAGE_CONTA_PAGAR, sagaNewFilterNextPageContaPagar)
}