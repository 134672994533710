import { takeLatest, put, call } from 'redux-saga/effects';

import { getRelacaoAnimaisAtivoAgrupadoPorCategoriaLote, listAnimalCadastro } from '../service';
import {
  TABLE_ANIMAL_CADASTRO,
  TABLE_ANIMAL_CADASTRO_PENDING,
  TABLE_ANIMAL_CADASTRO_SUCCESS,
  TABLE_ANIMAL_CADASTRO_FAILURE
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { storage } from '@commons/storage';

function* sagaListAnimalCadastro(action) {
  yield put({ type: TABLE_ANIMAL_CADASTRO_PENDING })

  try {
    if (action.agrupado == true) {
      const table = yield call(getRelacaoAnimaisAtivoAgrupadoPorCategoriaLote,
        JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id, JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo.id);
      yield put({ type: TABLE_ANIMAL_CADASTRO_SUCCESS, table: table })
    } else {
      const table = yield call(listAnimalCadastro, action.args)
      yield put({ type: TABLE_ANIMAL_CADASTRO_SUCCESS, table: table })
    }
  } catch (error) {
    yield put({ type: TABLE_ANIMAL_CADASTRO_FAILURE })
  }
}

export default function* watchListAnimalCadastro() {
  yield takeLatest(TABLE_ANIMAL_CADASTRO, sagaListAnimalCadastro)
}