import * as Yup from 'yup';

const newRegistro = {
    cliente: {
        cliente: true
    },
    fornecedor: {
        fornecedor: false
    },
    transportadora: {
        transportadora: false
    },
    nome: '',
    apelido: '',
    ativo: true,
    type: "PessoaFisica",
    tipoPessoa: 'FISICA',
    cpf: '',
    dataNascimento: '',
    cnpj: '',
    indicadorInscrEstadual: '',
    inscricaoEstadual: '',
    email: '',
    celular: '',
    telefone: '',
    endereco: {
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        estado: null,
        cidade: null,
    },
    contatos: []
};

const validation = Yup.object().shape({
    tipoPessoa: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    apelido: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    email: Yup.string()
        .email('E-mail inválido')
        .max(150, 'Campo deve ter menos do que 150 caracteres'),
    inscricaoEstadual: Yup.string()
        .max(40, 'Campo deve ter menos do que 40 caracteres'),
    contatos: Yup.array()
        .of(
            Yup.object().shape({
                nome: Yup.string()
                    .min(2, 'O campo deve ter no mínimo 2 caracteres!')
                    .max(60, 'Campo deve ter menos do que 60 caracteres!')
                    .required('Este campo é obrigatório'),
                setor: Yup.string()
                    .max(30, 'Campo deve ter menos do que 30 caracteres!'),
                email: Yup.string()
                    .email('E-mail inválido')
                    .max(100, 'Campo deve ter menos do que 100 caracteres'),
                celular: Yup.string()
                    .max(20, 'Campo deve ter menos do que 20 caracteres!'),
            })
        ),
    endereco: Yup.object().shape({
        logradouro: Yup.string()
            .max(60, 'Campo deve ter menos do que 60 caracteres'),
        numero: Yup.string()
            .max(60, 'Campo deve ter menos do que 60 caracteres'),
        complemento: Yup.string()
            .max(60, 'Campo deve ter menos do que 60 caracteres'),
        bairro: Yup.string()
            .max(60, 'Campo deve ter menos do que 60 caracteres'),
    }),
});

export const clienteCadastro = {
    newRegistro,
    validation
}