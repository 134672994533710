import { takeLatest, put, call } from 'redux-saga/effects';

import {
  UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML,
  UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_PENDING,
  UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_FAILURE,
  UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_SUCCESS,
  findProdutoInsumoCadastro,
} from '@handler';

import { updateProdutoInsumoCadastro } from '../service';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaUpdateProdutoInsumoVincularFornecedorXml(action) {
  yield put({ type: UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_PENDING })

  try {
    const produto = yield call(findProdutoInsumoCadastro, action.produto.id);

    if (produto.produtoInsumoFornecedores === undefined || produto.produtoInsumoFornecedores === null) {
      produto.produtoInsumoFornecedores = [];
    }
    if (action.data.id === undefined || action.data.id === null) {
      produto.produtoInsumoFornecedores.push({
        ...action.data,
        produto: action.produto,
        fornecedor: action.fornecedor
      });
    } else {
      for (let index = 0; index < produto.produtoInsumoFornecedores.length; index++) {
        const prodForn = produto.produtoInsumoFornecedores[index];

        if (prodForn.fornecedor.id === action.fornecedor.id) {
          prodForn = action.data;
        }
      }
    }

    yield call(updateProdutoInsumoCadastro,
      formatJsonRemoveEmptyOrNull(
        produto
      )
    );

    yield put({ type: UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_SUCCESS })

    AlertSuccess('Produto vinculado ao fornecedor com sucesso');

  } catch (error) {
    yield put({ type: UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_FAILURE })

    AlertError('Falha ao vincular fornecedor! Tente novamente...');
  }

}

export default function* watchUpdateProdutoInsumoVincularFornecedorXml() {
  yield takeLatest(UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML, sagaUpdateProdutoInsumoVincularFornecedorXml)
}
