
export const NEW_IMPLANTACAO_ESTOQUE = 'NEW_IMPLANTACAO_ESTOQUE'
export const NEW_IMPLANTACAO_ESTOQUE_SUCCESS = 'NEW_IMPLANTACAO_ESTOQUE_SUCCESS'

export const CREATE_IMPLANTACAO_ESTOQUE = 'CREATE_IMPLANTACAO_ESTOQUE'
export const CREATE_IMPLANTACAO_ESTOQUE_PENDING = 'CREATE_IMPLANTACAO_ESTOQUE_PENDING'
export const CREATE_IMPLANTACAO_ESTOQUE_SUCCESS = 'CREATE_IMPLANTACAO_ESTOQUE_SUCCESS'
export const CREATE_IMPLANTACAO_ESTOQUE_FAILURE = 'CREATE_IMPLANTACAO_ESTOQUE_FAILURE'

export const TABLE_IMPLANTACAO_ESTOQUE = 'TABLE_IMPLANTACAO_ESTOQUE'
export const TABLE_IMPLANTACAO_ESTOQUE_PENDING = 'TABLE_IMPLANTACAO_ESTOQUE_PENDING'
export const TABLE_IMPLANTACAO_ESTOQUE_SUCCESS = 'TABLE_IMPLANTACAO_ESTOQUE_SUCCESS'
export const TABLE_IMPLANTACAO_ESTOQUE_FAILURE = 'TABLE_IMPLANTACAO_ESTOQUE_FAILURE'

export const LIST_IMPLANTACAO_ESTOQUE = 'LIST_IMPLANTACAO_ESTOQUE'
export const LIST_IMPLANTACAO_ESTOQUE_PENDING = 'LIST_IMPLANTACAO_ESTOQUE_PENDING'
export const LIST_IMPLANTACAO_ESTOQUE_SUCCESS = 'LIST_IMPLANTACAO_ESTOQUE_SUCCESS'
export const LIST_IMPLANTACAO_ESTOQUE_FAILURE = 'LIST_IMPLANTACAO_ESTOQUE_FAILURE'

export const FIND_IMPLANTACAO_ESTOQUE = 'FIND_IMPLANTACAO_ESTOQUE'
export const FIND_IMPLANTACAO_ESTOQUE_PENDING = 'FIND_IMPLANTACAO_ESTOQUE_PENDING'
export const FIND_IMPLANTACAO_ESTOQUE_SUCCESS = 'FIND_IMPLANTACAO_ESTOQUE_SUCCESS'
export const FIND_IMPLANTACAO_ESTOQUE_FAILURE = 'FIND_IMPLANTACAO_ESTOQUE_FAILURE'

export const DELETE_IMPLANTACAO_ESTOQUE = 'DELETE_IMPLANTACAO_ESTOQUE'
export const DELETE_IMPLANTACAO_ESTOQUE_PENDING = 'DELETE_IMPLANTACAO_ESTOQUE_PENDING'
export const DELETE_IMPLANTACAO_ESTOQUE_SUCCESS = 'DELETE_IMPLANTACAO_ESTOQUE_SUCCESS'
export const DELETE_IMPLANTACAO_ESTOQUE_FAILURE = 'DELETE_IMPLANTACAO_ESTOQUE_FAILURE'
