import { takeLatest, put, call } from 'redux-saga/effects';

import { getEstoqueAtual, getEstoqueAtualPdf } from '../service';
import {
  LIST_RELATORIO_ESTOQUE_ATUAL,
  LIST_RELATORIO_ESTOQUE_ATUAL_PENDING,
  LIST_RELATORIO_ESTOQUE_ATUAL_SUCCESS,
  LIST_RELATORIO_ESTOQUE_ATUAL_FAILURE,
  LIST_RELATORIO_ESTOQUE_ATUAL_PDF_SUCCESS
} from '../actionTypes';
import { storage } from '@commons';
import { CONFIG_SESSION } from '@handler/common/startSession';

function* sagaListAllRelatorioEstoqueAtual(action) {
  yield put({ type: LIST_RELATORIO_ESTOQUE_ATUAL_PENDING })

  try {
    if (action.pdf === false) {
      const estoqueAtual = yield call(getEstoqueAtual, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id, action.args)

      yield put({ type: LIST_RELATORIO_ESTOQUE_ATUAL_SUCCESS, estoqueAtual: estoqueAtual })
    } else {
      yield call(getEstoqueAtualPdf, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id, action.args)

      yield put({ type: LIST_RELATORIO_ESTOQUE_ATUAL_PDF_SUCCESS })
    }
  } catch (error) {

    yield put({ type: LIST_RELATORIO_ESTOQUE_ATUAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllRelatorioEstoqueAtual() {
  yield takeLatest(LIST_RELATORIO_ESTOQUE_ATUAL, sagaListAllRelatorioEstoqueAtual)
}