import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import { getGraficoCustoProducaoPorTalhao } from '../service';
import {
    CONFIG_SESSION,
    GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD,
    GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_PENDING,
    GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_SUCCESS,
    GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_FAILURE
} from '@handler';
import { formatDateDDMMYYYY } from '@components/common/format';

function* sagaGraficoCustoProducaoPorTalhao(action) {
    yield put({ type: GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_PENDING })

    try {
        const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
        const idSafra = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id;

        const dadosGraficoCustoProducaoPorTalhao = yield call(getGraficoCustoProducaoPorTalhao,
            idFazenda,
            idSafra,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        let chartData = [['', 'Custo']];
        for (let i = 0; i < dadosGraficoCustoProducaoPorTalhao.length; i += 1) {
            chartData.push([dadosGraficoCustoProducaoPorTalhao[i].nomeTalhao, dadosGraficoCustoProducaoPorTalhao[i].custo])
        }

        yield put({ type: GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_SUCCESS, dadosGraficoCustoProducaoPorTalhao: chartData })

    } catch (error) {
        yield put({ type: GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD_FAILURE })

    }
}

export default function* watchGraficoCustoProducaoPorTalhao() {
    yield takeLatest(GRAFICO_CUSTO_PRODUCAO_TALHAO_DASHBOARD, sagaGraficoCustoProducaoPorTalhao)
}
