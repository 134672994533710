import { takeLatest, put, call } from 'redux-saga/effects';

import { getMovimentacoesEstoqueProduto } from '../service';
import {
  LIST_RELATORIO_MOVIMENTACOES_PRODUTO,
  LIST_RELATORIO_MOVIMENTACOES_PRODUTO_PENDING,
  LIST_RELATORIO_MOVIMENTACOES_PRODUTO_SUCCESS,
  LIST_RELATORIO_MOVIMENTACOES_PRODUTO_FAILURE
} from '../actionTypes';
import { storage } from '@commons';
import { CONFIG_SESSION } from '@handler/common/startSession';

function* sagaListAllRelatorioMovimentacoesProduto(action) {
  yield put({ type: LIST_RELATORIO_MOVIMENTACOES_PRODUTO_PENDING })

  try {
    const movimentacoesEstoqueProduto = yield call(getMovimentacoesEstoqueProduto, 
      JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id, action.idProduto, action.idLocalEstoque)

    yield put({
      type: LIST_RELATORIO_MOVIMENTACOES_PRODUTO_SUCCESS,
      movimentacoesEstoqueProduto: movimentacoesEstoqueProduto,
      descProduto: action.descProduto,
      estoque: action.estoque,
      unidade: action.unidade
    })
  } catch (error) {

    yield put({ type: LIST_RELATORIO_MOVIMENTACOES_PRODUTO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllRelatorioMovimentacoesProduto() {
  yield takeLatest(LIST_RELATORIO_MOVIMENTACOES_PRODUTO, sagaListAllRelatorioMovimentacoesProduto)
}