import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllCulturaCadastro } from '../service';
import {
  LIST_CULTURA_CADASTRO,
  LIST_CULTURA_CADASTRO_PENDING,
  LIST_CULTURA_CADASTRO_SUCCESS,
  LIST_CULTURA_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAllCulturaCadastro(action) {
  yield put({ type: LIST_CULTURA_CADASTRO_PENDING })

  try {
    const list = yield call(findAllCulturaCadastro)
    
    yield put({ type: LIST_CULTURA_CADASTRO_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_CULTURA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllCulturaCadastro() {
  yield takeLatest(LIST_CULTURA_CADASTRO, sagaListAllCulturaCadastro)
}