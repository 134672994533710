import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  transferenciaContaBancoCaixa,
  NEW_TRANSF_CONTA_BANCO_CAIXA,
  NEW_TRANSF_CONTA_BANCO_CAIXA_SUCCESS,
} from '@handler';

function* sagaNewTransferenciaContaBancoCaixa(action) {

  yield put({ type: NEW_TRANSF_CONTA_BANCO_CAIXA_SUCCESS, registro: 
    {
      ...transferenciaContaBancoCaixa.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
    } });
}

export default function* watchNewTransferenciaContaBancoCaixa() {
  yield takeLatest(NEW_TRANSF_CONTA_BANCO_CAIXA, sagaNewTransferenciaContaBancoCaixa)
}
