import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  baixarManualEstoque,
  NEW_BAIXAR_MANUAL_ESTOQUE,
  NEW_BAIXAR_MANUAL_ESTOQUE_SUCCESS,
} from '@handler';

function* sagaNewBaixaManualEstoque(action) {

  yield put({ type: NEW_BAIXAR_MANUAL_ESTOQUE_SUCCESS, registro: 
    {
      ...baixarManualEstoque.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
    } });
}

export default function* watchNewBaixaManualEstoque() {
  yield takeLatest(NEW_BAIXAR_MANUAL_ESTOQUE, sagaNewBaixaManualEstoque)
}
