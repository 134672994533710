export const NEW_TRANSFERENCIA_ANIMAL = 'NEW_TRANSFERENCIA_ANIMAL'
export const NEW_TRANSFERENCIA_ANIMAL_SUCCESS = 'NEW_TRANSFERENCIA_ANIMAL_SUCCESS'

export const CREATE_TRANSFERENCIA_ANIMAL = 'CREATE_TRANSFERENCIA_ANIMAL'
export const CREATE_TRANSFERENCIA_ANIMAL_PENDING = 'CREATE_TRANSFERENCIA_ANIMAL_PENDING'
export const CREATE_TRANSFERENCIA_ANIMAL_SUCCESS = 'CREATE_TRANSFERENCIA_ANIMAL_SUCCESS'
export const CREATE_TRANSFERENCIA_ANIMAL_FAILURE = 'CREATE_TRANSFERENCIA_ANIMAL_FAILURE'

export const TABLE_TRANSFERENCIA_ANIMAL = 'TABLE_TRANSFERENCIA_ANIMAL'
export const TABLE_TRANSFERENCIA_ANIMAL_PENDING = 'TABLE_TRANSFERENCIA_ANIMAL_PENDING'
export const TABLE_TRANSFERENCIA_ANIMAL_SUCCESS = 'TABLE_TRANSFERENCIA_ANIMAL_SUCCESS'
export const TABLE_TRANSFERENCIA_ANIMAL_FAILURE = 'TABLE_TRANSFERENCIA_ANIMAL_FAILURE'

export const LIST_TRANSFERENCIA_ANIMAL = 'LIST_TRANSFERENCIA_ANIMAL'
export const LIST_TRANSFERENCIA_ANIMAL_PENDING = 'LIST_TRANSFERENCIA_ANIMAL_PENDING'
export const LIST_TRANSFERENCIA_ANIMAL_SUCCESS = 'LIST_TRANSFERENCIA_ANIMAL_SUCCESS'
export const LIST_TRANSFERENCIA_ANIMAL_FAILURE = 'LIST_TRANSFERENCIA_ANIMAL_FAILURE'

export const FIND_TRANSFERENCIA_ANIMAL = 'FIND_TRANSFERENCIA_ANIMAL'
export const FIND_TRANSFERENCIA_ANIMAL_PENDING = 'FIND_TRANSFERENCIA_ANIMAL_PENDING'
export const FIND_TRANSFERENCIA_ANIMAL_SUCCESS = 'FIND_TRANSFERENCIA_ANIMAL_SUCCESS'
export const FIND_TRANSFERENCIA_ANIMAL_FAILURE = 'FIND_TRANSFERENCIA_ANIMAL_FAILURE'

export const DELETE_TRANSFERENCIA_ANIMAL = 'DELETE_TRANSFERENCIA_ANIMAL'
export const DELETE_TRANSFERENCIA_ANIMAL_PENDING = 'DELETE_TRANSFERENCIA_ANIMAL_PENDING'
export const DELETE_TRANSFERENCIA_ANIMAL_SUCCESS = 'DELETE_TRANSFERENCIA_ANIMAL_SUCCESS'
export const DELETE_TRANSFERENCIA_ANIMAL_FAILURE = 'DELETE_TRANSFERENCIA_ANIMAL_FAILURE'