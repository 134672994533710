import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/agricultura/estoque/transferencia' };
/////////////////////////////////////////////////////////////////////////////////

export function createTransferenciaEstoqueProducao(payload) {
  return httpService.post(args, payload);
}

export function updateTransferenciaEstoqueProducao(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listTransferenciaEstoqueProducao(param) {
  return httpService.get({ ...args, args: param });
}

export function findTransferenciaEstoqueProducao(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllTransferenciaEstoqueProducao() {
  return httpService.get({ ...args, path: '/agricultura/estoque/transferencia/all' });
}

export function deleteTransferenciaEstoqueProducao(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

