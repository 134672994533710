
export const NEW_IMPLANTACAO_ESTOQUE_PRODUCAO = 'NEW_IMPLANTACAO_ESTOQUE_PRODUCAO'
export const NEW_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS = 'NEW_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS'

export const CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO = 'CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO'
export const CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING = 'CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING'
export const CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS = 'CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS'
export const CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE = 'CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE'

export const TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO = 'TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO'
export const TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING = 'TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING'
export const TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS = 'TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS'
export const TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE = 'TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE'

export const LIST_IMPLANTACAO_ESTOQUE_PRODUCAO = 'LIST_IMPLANTACAO_ESTOQUE_PRODUCAO'
export const LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING = 'LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING'
export const LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS = 'LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS'
export const LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE = 'LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE'

export const FIND_IMPLANTACAO_ESTOQUE_PRODUCAO = 'FIND_IMPLANTACAO_ESTOQUE_PRODUCAO'
export const FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING = 'FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING'
export const FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS = 'FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS'
export const FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE = 'FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE'

export const DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO = 'DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO'
export const DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING = 'DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING'
export const DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS = 'DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS'
export const DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE = 'DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE'
