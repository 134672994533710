import { takeLatest, put, call } from 'redux-saga/effects';
import { path, storage } from '@commons';

import {
    TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL,
    TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_PENDING,
    TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_SUCCESS,
    TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_FAILURE,
} from '@handler';
import { getTotaisIndicadorDashCorporate } from '../service';

export function* sagaTotaisCorporateWhiteLabel(action) {
    yield put({ type: TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_PENDING })

    try {
        let user = JSON.parse(storage.get(path.sessionUser));
        const totaisDash = yield call(getTotaisIndicadorDashCorporate, user, action.args)

        yield put({
            type: TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_SUCCESS, filter: {
                totais: totaisDash
            }
        })
    } catch (error) {
        yield put({
            type: TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL_FAILURE, filter: {
                totais: {
                    "totalProdutores": 0,
                    "totalFazendas": 0,
                    "totalHectares": 0,
                }
            }
        })

    }
}

export default function* watchTotaisCorporateWhiteLabel() {
    yield takeLatest(TOTAIS_DASHBOARD_CORPORATE_WHITE_LABEL, sagaTotaisCorporateWhiteLabel)
}
