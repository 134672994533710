import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { storage } from '@commons/storage';

import { createControleContaBanco, createControleContaBancoTransf, updateControleContaBanco, updateControleContaBancoTransf } from '../service';
import {
  CREATE_CONTROLE_CONTA_BANCO,
  CREATE_CONTROLE_CONTA_BANCO_PENDING,
  CREATE_CONTROLE_CONTA_BANCO_SUCCESS,
  CREATE_CONTROLE_CONTA_BANCO_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaCreateControleContaBanco(action) {
  yield put({ type: CREATE_CONTROLE_CONTA_BANCO_PENDING })

  try {
    if (action.data.tipoMovimento === 'TRANSFERENCIA') {
      let transf = {
        ...action.data,
        contaOrigem: action.data.conta
      };

      delete transf.contaGerencial;
      delete transf.classificacao;
      delete transf.numeroDocumento;
      delete transf.pessoa;
      delete transf.automatico;
      delete transf.conta;

      if (transf.id === undefined || transf.id === null) {
        yield call(createControleContaBancoTransf, formatJsonRemoveEmptyOrNull(
          {
            ...transf,
            propriedade: {
              id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
            }
          }
        ));
      } else {
        yield call(updateControleContaBancoTransf,
          formatJsonRemoveEmptyOrNull({
            ...transf,
            id: transf.id
          })
        );
      }
    } else {
      action.data.classificacao = action.data.contaGerencial.classificacao;
      delete action.data.contaDestino;

      if (action.data.id === undefined || action.data.id === null) {
        yield call(createControleContaBanco, formatJsonRemoveEmptyOrNull(
          {
            ...action.data,
            propriedade: {
              id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
            }
          }
        ));
      } else {
        yield call(updateControleContaBanco,
          {
            ...action.data,
            id: action.data.id,
            fluxoRealizadoList: []
          }
        );
      }
    }

    yield put({ type: CREATE_CONTROLE_CONTA_BANCO_SUCCESS, registro: action.data })

    history.push('/financeiro/movimentacoesBancarias');

    AlertSuccess('Registro salvo com sucesso!');
  } catch (error) {
    yield put({ type: CREATE_CONTROLE_CONTA_BANCO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

export default function* watchCreateControleContaBanco() {
  yield takeLatest(CREATE_CONTROLE_CONTA_BANCO, sagaCreateControleContaBanco)
}
