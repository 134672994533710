import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createPluviometroDigital, updatePluviometroDigital } from '../service';
import {
  CREATE_PLUVIOMETRO_DIGITAL,
  CREATE_PLUVIOMETRO_DIGITAL_PENDING,
  CREATE_PLUVIOMETRO_DIGITAL_SUCCESS,
  CREATE_PLUVIOMETRO_DIGITAL_FAILURE,
  CONFIG_SESSION,
} from '@handler';
import { storage } from '@commons/storage';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreatePluviometroDigital(action) {
  yield put({ type: CREATE_PLUVIOMETRO_DIGITAL_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createPluviometroDigital, {
        ...action.data,
        propriedade: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
        }
      });
    } else {
      yield call(updatePluviometroDigital,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_PLUVIOMETRO_DIGITAL_SUCCESS, registro: action.data })

    history.push('/pluviometroDigital');

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_PLUVIOMETRO_DIGITAL_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreatePluviometroDigital() {
  yield takeLatest(CREATE_PLUVIOMETRO_DIGITAL, sagaCreatePluviometroDigital)
}
