import { takeLatest, put } from 'redux-saga/effects';

import {
  fornecedorCadastro,
  NEW_FORNECEDOR_CADASTRO,
  NEW_FORNECEDOR_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewFornecedorCadastro(action) {

  yield put({
    type: NEW_FORNECEDOR_CADASTRO_SUCCESS, registro: {
      ...fornecedorCadastro.newRegistro,
      endereco: {
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        estado: null,
        cidade: null,
      },
      cliente: {
        cliente: false
      },
      fornecedor: {
        fornecedor: true
      },
      transportadora: {
          transportadora: false
      },
    },
    isCadastroRapido: action.isCadastroRapido, 
  });
}

export default function* watchNewFornecedorCadastro() {
  yield takeLatest(NEW_FORNECEDOR_CADASTRO, sagaNewFornecedorCadastro)
}
