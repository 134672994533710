import { takeLatest, put, call } from 'redux-saga/effects';

import { listEntradaEstoque } from '../service';
import {
  TABLE_ENTRADA_ESTOQUE,
  TABLE_ENTRADA_ESTOQUE_PENDING,
  TABLE_ENTRADA_ESTOQUE_SUCCESS,
  TABLE_ENTRADA_ESTOQUE_FAILURE
} from '../actionTypes';

function* sagaListEntradaEstoque(action) {
  yield put({ type: TABLE_ENTRADA_ESTOQUE_PENDING })

  try {
    const table = yield call(listEntradaEstoque, action.args)
    yield put({ type: TABLE_ENTRADA_ESTOQUE_SUCCESS, table: table })

  } catch (error) {

    yield put({ type: TABLE_ENTRADA_ESTOQUE_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListEntradaEstoque() {
  yield takeLatest(TABLE_ENTRADA_ESTOQUE, sagaListEntradaEstoque)
}