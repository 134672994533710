import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { Button, FormGroup, Label } from "reactstrap";
import SimpleBar from "simplebar-react";
import CollapseMenu from '@components/common/CollapseMenu';
import { TOGGLE_HELP_DRAWER } from '@handler';


const HelpDrawer = ({}) => {
    const dispatch = useDispatch();
  const [selectedHelp, setSelectedHelp] = useState(null);
  const helpDrawerReducer = useSelector((state) => state.entities.helpDrawerReducer);
  const helpData = helpDrawerReducer.data;

  const initialCollapseStates = helpData
    ? helpData.menus.reduce((acc, menu) => {
        acc[menu.id] = false;
        return acc;
      }, {})
    : {};

  const [collapseStates, setCollapseStates] = useState(initialCollapseStates);

  useEffect(() => {
    // Atualizar o estado local quando helpDrawerReducer.data mudar
    setCollapseStates((prevState) => {
      const updatedCollapseStates = { ...prevState };
      if (helpData) {
        helpData.menus.forEach((menu) => {
          updatedCollapseStates[menu.id] = prevState[menu.id] || false;
        });
      }
      return updatedCollapseStates;
    });
  }, [helpData]);

  const handleHelpChange = (event) => {
    setSelectedHelp(event.target.value);
  };

  const toggleCollapse = (menuId) => {
    setCollapseStates((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId],
    }));
  };

    return (
        <div className={ helpDrawerReducer.isOpen ? 'right-bar-enabled' : ''}>
            <div className="right-bar">
                <SimpleBar style={{ height: "100%" }}>
                    { helpData && (
                        <div data-simplebar className="h-100">
                            <div className="rightbar-title px-3 py-4 d-flex justify-content-between align-items-center">
                                <h5 className="m-0">{helpData.title}</h5>
                                <Button color="light" onClick={() => dispatch({ type: TOGGLE_HELP_DRAWER })} style={{ padding: 0 }}>
                                    <i className='fa fa-times-circle' style={{ fontSize: '20px', color: "#5b626b"}} />
                                </Button>
                            </div>

                            <hr className="my-0" />

                            <div className="p-4">
                                <p>{helpData.description}</p>
                            </div>

                            <div className="d-flex align-items-center justify-content-center">
                                <Button className='btn btn-primary' onClick={()=>window.open('https://web.whatsapp.com/send?phone=5555984670848&text=Ol%C3%A1%20Suporte,%20gostaria%20de%20uma%20ajuda%20com%20o%20sistema%20On%Farm', '_blank')}>
                                    Chamar Suporte
                                </Button>
                                {/* <Button className='btn btn-secondary' onClick={()=>{}}>
                                    Iniciar Tour
                                </Button> */}
                            </div>

                            <div className="p-4">
                                {helpData.menus.map((menu) => (
                                    <CollapseMenu
                                        data={menu}
                                        isOpen={collapseStates[menu.id]}
                                        toggle={() => toggleCollapse(menu.id)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </SimpleBar>
            </div>

            {/* <div className="rightbar-overlay" /> */}
        </div>
    );
};

export default HelpDrawer;
