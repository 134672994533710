import { takeLatest, put, call } from 'redux-saga/effects';
import { httpService } from '@commons';

import {
  TABLE_CONTA_AGRARE,
  TABLE_CONTA_AGRARE_PENDING,
  TABLE_CONTA_AGRARE_SUCCESS,
  TABLE_CONTA_AGRARE_FAILURE
} from '../actionTypes';

export const listContaAgrare = async (idUser, param) => {
  return httpService.getApiManager(`/clienteAgrare/getContasClienteAgrareByUser/${idUser}${param ?? ''}`, true);
}

function* sagaListContaAgrare(action) {
  yield put({ type: TABLE_CONTA_AGRARE_PENDING })

  try {
    const table = yield call(listContaAgrare, action.args)

    yield put({ type: TABLE_CONTA_AGRARE_SUCCESS, table: table })

  } catch (error) {
    yield put({ type: TABLE_CONTA_AGRARE_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListContaAgrare() {
  yield takeLatest(TABLE_CONTA_AGRARE, sagaListContaAgrare)
}