import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createIdentificadorCadastro, updateIdentificadorCadastro } from '../service';
import {
  CREATE_IDENTIFICADOR_CADASTRO,
  CREATE_IDENTIFICADOR_CADASTRO_PENDING,
  CREATE_IDENTIFICADOR_CADASTRO_SUCCESS,
  CREATE_IDENTIFICADOR_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateIdentificadorCadastro(action) {
  yield put({ type: CREATE_IDENTIFICADOR_CADASTRO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createIdentificadorCadastro, action.data);
    } else {
      yield call(updateIdentificadorCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_IDENTIFICADOR_CADASTRO_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/identificadorCadastro');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_IDENTIFICADOR_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateIdentificadorCadastro() {
  yield takeLatest(CREATE_IDENTIFICADOR_CADASTRO, sagaCreateIdentificadorCadastro)
}
