import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_PROCEDIMENTO_PECUARIO_CADASTRO_RAPIDO,
    CANCEL_PROCEDIMENTO_PECUARIO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelProcedimentoPecuarioCadastroRapido() {
  yield put({ type: CANCEL_PROCEDIMENTO_PECUARIO_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelProcedimentoPecuarioCadastroRapido() {
  yield takeLatest(CANCEL_PROCEDIMENTO_PECUARIO_CADASTRO_RAPIDO, sagaCancelProcedimentoPecuarioCadastroRapido)
}