import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    status: true,
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(100, 'Campo deve ter menos do que 100 caracteres')
        .required('Este campo é obrigatório'),
});

export const animalPelagemCadastro = {
    newRegistro,
    validation
}