import {
    FIND_CONFIG_GERAL_PENDING,
    FIND_CONFIG_GERAL_SUCCESS,
    FIND_CONFIG_GERAL_FAILURE,
    UPDATE_CONFIG_GERAL_PENDING,
    UPDATE_CONFIG_GERAL_SUCCESS,
    UPDATE_CONFIG_GERAL_FAILURE,
} from './actionTypes';


const initialState = { loading: false }

function configGeralReducer(state = initialState, action) {

    switch (action.type) {
        case FIND_CONFIG_GERAL_PENDING:
        case UPDATE_CONFIG_GERAL_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_CONFIG_GERAL_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_CONFIG_GERAL_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case UPDATE_CONFIG_GERAL_SUCCESS:
        case UPDATE_CONFIG_GERAL_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { configGeralReducer };