import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { ativarUsuario, desativarUsuario } from '../service';
import {
  ACTIVE_INACTIVE_USUARIO,
  ACTIVE_INACTIVE_USUARIO_PENDING,
  ACTIVE_INACTIVE_USUARIO_SUCCESS,
  ACTIVE_INACTIVE_USUARIO_FAILURE
} from '../actionTypes';
import { AlertWarning } from '@components/common/AlertToast';

function* sagaAtivoInativoUsuario(action) {
  yield put({ type: ACTIVE_INACTIVE_USUARIO_PENDING })

  try {
    if (action.ativar) {
      yield call(ativarUsuario, action.id);
    } else {
      yield call(desativarUsuario, action.id);
    }

    swal({
      title: action.ativar ? 'Usuário ativado com sucesso...' : 'Usuário desativado com sucesso...',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      window.location.reload();
    });

    yield put({ type: ACTIVE_INACTIVE_USUARIO_SUCCESS })
  } catch (error) {
    yield put({ type: ACTIVE_INACTIVE_USUARIO_FAILURE });

    if (action.ativar) {
      AlertWarning('Falha ao ativar usuário. Tente novamente...');
    } else {
      AlertWarning('Falha ao desativar usuário. Tente novamente...');
    }
  }
}

export default function* watchAtivoInativoUsuario() {
  yield takeLatest(ACTIVE_INACTIVE_USUARIO, sagaAtivoInativoUsuario)
}
