import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  NEW_FILTER_RELATORIO_MOVIMENTACAO_ANIMAL,
  NEW_FILTER_RELATORIO_MOVIMENTACAO_ANIMAL_PEDING,
  NEW_FILTER_RELATORIO_MOVIMENTACAO_ANIMAL_SUCCESS,
} from '@handler';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';
import { getLancamentosLista } from '../service';
import { LIST_RELATORIO_MOVIMENTACAO_ANIMAL_SUCCESS } from '../actionTypes';

function* sagaNewFilterRelatorioMovimentacaoAnimal(action) {
  yield put({ type: NEW_FILTER_RELATORIO_MOVIMENTACAO_ANIMAL_PEDING })

  var date = new Date();
  let dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
  let dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
  let idAnoPecuario = -1;
  if (JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo !== undefined && JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo !== null) {
    idAnoPecuario = JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo.id;
  }

  const datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);

  const list = yield call(getLancamentosLista, idFazenda, idAnoPecuario, datas);

  let totalNroAnimais = 0;
  let totalPeso = 0;

  if (list !== null && list.length > 0) {
    totalNroAnimais = list[list.length - 1].quantidadeTotal;
    totalPeso = list[list.length - 1].pesoTotal;
  }

  yield put({
    type: LIST_RELATORIO_MOVIMENTACAO_ANIMAL_SUCCESS, list: list, newFilter: {
      dataInicial,
      dataFinal,
      totalNroAnimais,
      totalPeso,
    }
  })
}

export default function* watchNewFilterRelatorioMovimentacaoAnimal() {
  yield takeLatest(NEW_FILTER_RELATORIO_MOVIMENTACAO_ANIMAL, sagaNewFilterRelatorioMovimentacaoAnimal)
}
