import watchTotaisFinanceiro from './saga/totaisFinanceiro';

export const dashboardFinanceiroSaga = [
    watchTotaisFinanceiro(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
