export function encodePath(polygonPath) {
    return google.maps.geometry.encoding.encodePath(polygonPath);
}

export function decodePath(polygonPath) {
    return google.maps.geometry.encoding.decodePath(polygonPath);
}

export function getPolygonBounds(polygonPath) {
  if (polygonPath.length === 0) {
    return null;
  }

  let bounds = new google.maps.LatLngBounds();
  polygonPath.forEach((latlng) => {
    bounds.extend(latlng);
  });

  return bounds;
}

export function getPolygonCenter(listCood) {
    var bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < listCood.length; i++) {
      bounds.extend(listCood[i]);
    }
    return bounds.getCenter();
}

export function getPolygonPath(polygon) {
    var bounds = new google.maps.LatLngBounds();
    var listCood = polygon.getPath().getArray();
    for (let i = 0; i < listCood.length; i++) {
      bounds.extend(listCood[i]);
    }
}
