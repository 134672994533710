
export const NEW_VEICULO_CADASTRO = 'NEW_VEICULO_CADASTRO'
export const NEW_VEICULO_CADASTRO_SUCCESS = 'NEW_VEICULO_CADASTRO_SUCCESS'

export const CREATE_VEICULO_CADASTRO = 'CREATE_VEICULO_CADASTRO'
export const CREATE_VEICULO_CADASTRO_PENDING = 'CREATE_VEICULO_CADASTRO_PENDING'
export const CREATE_VEICULO_CADASTRO_SUCCESS = 'CREATE_VEICULO_CADASTRO_SUCCESS'
export const CREATE_VEICULO_CADASTRO_FAILURE = 'CREATE_VEICULO_CADASTRO_FAILURE'

export const CANCEL_VEICULO_CADASTRO_RAPIDO = 'CANCEL_VEICULO_CADASTRO_RAPIDO'
export const CANCEL_VEICULO_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_VEICULO_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_VEICULO_CADASTRO = 'TABLE_VEICULO_CADASTRO'
export const TABLE_VEICULO_CADASTRO_PENDING = 'TABLE_VEICULO_CADASTRO_PENDING'
export const TABLE_VEICULO_CADASTRO_SUCCESS = 'TABLE_VEICULO_CADASTRO_SUCCESS'
export const TABLE_VEICULO_CADASTRO_FAILURE = 'TABLE_VEICULO_CADASTRO_FAILURE'

export const LIST_VEICULO_CADASTRO = 'LIST_VEICULO_CADASTRO'
export const LIST_VEICULO_CADASTRO_PENDING = 'LIST_VEICULO_CADASTRO_PENDING'
export const LIST_VEICULO_CADASTRO_SUCCESS = 'LIST_VEICULO_CADASTRO_SUCCESS'
export const LIST_VEICULO_CADASTRO_FAILURE = 'LIST_VEICULO_CADASTRO_FAILURE'

export const FIND_VEICULO_CADASTRO = 'FIND_VEICULO_CADASTRO'
export const FIND_VEICULO_CADASTRO_PENDING = 'FIND_VEICULO_CADASTRO_PENDING'
export const FIND_VEICULO_CADASTRO_SUCCESS = 'FIND_VEICULO_CADASTRO_SUCCESS'
export const FIND_VEICULO_CADASTRO_FAILURE = 'FIND_VEICULO_CADASTRO_FAILURE'

export const DELETE_VEICULO_CADASTRO = 'DELETE_VEICULO_CADASTRO'
export const DELETE_VEICULO_CADASTRO_PENDING = 'DELETE_VEICULO_CADASTRO_PENDING'
export const DELETE_VEICULO_CADASTRO_SUCCESS = 'DELETE_VEICULO_CADASTRO_SUCCESS'
export const DELETE_VEICULO_CADASTRO_FAILURE = 'DELETE_VEICULO_CADASTRO_FAILURE'
