import * as Yup from 'yup';

const newRegistro = {
    data: '',
    tipoTransferencia: 'BANCO_PARA_CAIXA',
    contaBanco: null,
    contaCaixa: null,
    valor: 0,
    obs: '',
    historico: '',
};

const validation = Yup.object().shape({
    tipoTransferencia: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    data: Yup.date()
        .required('Este campo é obrigatório'),
    contaBanco: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    contaCaixa: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    valor: Yup.number()
        .typeError('Este campo é obrigatório')
        .positive('Por Favor, informe um valor maior que zero')
        .required("Este campo é obrigatório"),
    obs: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
    historico: Yup.string()
        .max(200, 'Campo deve ter menos do que 200 caracteres'),
});

export const transferenciaContaBancoCaixa = {
    newRegistro,
    validation
}