import { takeLatest, put, call } from 'redux-saga/effects';

import { findAnoPecuarioCadastro } from '../service';
import { listAllCampoAtivo } from '@handler/pecuaria/campoCadastro/service';
import { storage } from '@commons/storage';
import {
  CONFIG_SESSION,
  FIND_ANO_PECUARIO_CADASTRO,
  FIND_ANO_PECUARIO_CADASTRO_PENDING,
  FIND_ANO_PECUARIO_CADASTRO_SUCCESS,
  FIND_ANO_PECUARIO_CADASTRO_FAILURE
} from '@handler';

function* sagaFindAnoPecuarioCadastro(action) {
  yield put({ type: FIND_ANO_PECUARIO_CADASTRO_PENDING, id: action.id })

  try {
    
    const camposCadastro = yield call(listAllCampoAtivo, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id);

    let anoPecuarioCampoList = []
    camposCadastro.content.forEach(
      (campo) => {
        anoPecuarioCampoList = anoPecuarioCampoList.concat({
          campo: campo,
          status: false,
          // area: campo.area
        })
      });

    const registro = yield call(findAnoPecuarioCadastro, action.id);

    let camposInativo = []
    for (let index = 0; index < registro.anoPecuarioCampoList.length; index++) {
      const elementSafraCampo = registro.anoPecuarioCampoList[index];

      let campoEncontrado = false;
      for (let indexCampos = 0; indexCampos < anoPecuarioCampoList.length; indexCampos++) {
        const elementCampo = anoPecuarioCampoList[indexCampos];

        if (elementSafraCampo.campo.id === elementCampo.campo.id) {
          elementCampo.status = elementSafraCampo.status;
          // elementCampo.area = elementSafraCampo.area;

          campoEncontrado = true;
        }
      }

      if (!campoEncontrado) {
        camposInativo = camposInativo.concat({
          campo: elementSafraCampo.campo,
          status: elementSafraCampo.status,
          // area: elementSafraCampo.campo.area
        })
      }
    }

    camposInativo.forEach(
      (valor) => {
        valor.campo.nome = valor.campo.nome + ' (talhão inativo)'
        anoPecuarioCampoList = anoPecuarioCampoList.concat({
          campo: valor.campo,
          status: valor.status,
          // area: valor.campo.area
        })
      });

    yield put({
      type: FIND_ANO_PECUARIO_CADASTRO_SUCCESS, registro: {
        ...registro,
        anoPecuarioCampoList,
        allCampos: false
      }
    })




    // const registro = yield call(findAnoPecuarioCadastro, action.id);
    // yield put({ type: FIND_ANO_PECUARIO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {
    yield put({ type: FIND_ANO_PECUARIO_CADASTRO_FAILURE })
  }
}

export default function* watchFindAnoPecuarioCadastro() {
  yield takeLatest(FIND_ANO_PECUARIO_CADASTRO, sagaFindAnoPecuarioCadastro)
}
