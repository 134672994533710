import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createTransferenciaEstoque, transferenciaPodeSerEditadaExcluida, updateTransferenciaEstoque } from '../service';
import {
  CREATE_TRANSFERENCIA_ESTOQUE,
  CREATE_TRANSFERENCIA_ESTOQUE_PENDING,
  CREATE_TRANSFERENCIA_ESTOQUE_SUCCESS,
  CREATE_TRANSFERENCIA_ESTOQUE_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

function* sagaCreateTransferenciaEstoque(action) {
  yield put({ type: CREATE_TRANSFERENCIA_ESTOQUE_PENDING })

  var processar = true;
  if (action.data.id !== undefined && action.data.id !== null) {
    processar = yield call(transferenciaPodeSerEditadaExcluida, action.data.id);
  }

  if (processar) {
    try {
      if (action.data.id === undefined || action.data.id === null) {
        yield call(createTransferenciaEstoque, {
          ...action.data,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          },
        });
      } else {
        yield call(updateTransferenciaEstoque,
          {
            ...action.data,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_TRANSFERENCIA_ESTOQUE_SUCCESS, registro: action.data })

      history.push('/estoque/transferencia');

      AlertSuccess('Registro salvo com sucesso!');
    } catch (error) {
      if (error.response.status === 400) {
        AlertWarning('Transferência não processada! Verifique o estoque disponível no local de origem e tente novamente...');
      } else {
        AlertError('Falha ao salvar o registro! Tente novamente...');
      }

      yield put({ type: CREATE_TRANSFERENCIA_ESTOQUE_FAILURE, registro: action.data })
    }
  } else {
    yield put({ type: CREATE_TRANSFERENCIA_ESTOQUE_FAILURE, registro: action.data })

    AlertWarning('Transferência não pode ser editada, pois já houve movimentação no estoque');
  }
}

export default function* watchCreateTransferenciaEstoque() {
  yield takeLatest(CREATE_TRANSFERENCIA_ESTOQUE, sagaCreateTransferenciaEstoque)
}
