import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CHANGE_MAP_MODE } from '@handler';

const MapModeSwitcher = () => {
  const dispatch = useDispatch();
  const mapReducer = useSelector((state) => state.entities.mapReducer);

  const handleModeChange = (mode) => {
    dispatch({ type: CHANGE_MAP_MODE, args: mode });
  };

  return (
    <div className="map-mode-switcher">
      <button
        className={`mode-button ${mapReducer.mode === 'STANDARD' ? 'active' : ''} rounded-left`}
        onClick={() => handleModeChange('STANDARD')}
      >
        <span className="button-text">Padrão</span>
      </button>
      <button
        className={`mode-button ${mapReducer.mode === 'NDVI' ? 'active' : ''} rounded-right`}
        onClick={() => handleModeChange('NDVI')}
      >
        <span className="button-text">NDVI</span>
      </button>
    </div>
  );
};

export default MapModeSwitcher;
