import { takeLatest, put } from 'redux-saga/effects';

import {
  catalagoProdutoWhiteLabel,
  NEW_CATALAGO_PRODUTO_WHITE_LABEL,
  NEW_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS,
} from '@handler';

function* sagaNewCatalagoProdutoWhiteLabel(action) {

  yield put({
    type: NEW_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS,
    registro: catalagoProdutoWhiteLabel.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewCatalagoProdutoWhiteLabel() {
  yield takeLatest(NEW_CATALAGO_PRODUTO_WHITE_LABEL, sagaNewCatalagoProdutoWhiteLabel)
}
