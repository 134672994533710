import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  NEW_FILTER_CALENDARIO,
  NEW_FILTER_CALENDARIO_PENDING,
  NEW_FILTER_CALENDARIO_SUCCESS,
  NEW_FILTER_CALENDARIO_FAILURE,
  getCalendario,
} from '@handler';

function* sagaNewFilterCalendario(action) {
  try {
    yield put({ type: NEW_FILTER_CALENDARIO_PENDING })

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

    let newFilter = {
      dataInicial: action.dataInicial,
      dataFinal: action.dataFinal
    };

    // busca registros
    let list = yield call(getCalendario, idFazenda, newFilter);

    yield put({
      type: NEW_FILTER_CALENDARIO_SUCCESS, list: list.data,
    });

  } catch (e) {
    yield put({ type: NEW_FILTER_CALENDARIO_FAILURE })
  }
}

export default function* watchNewFilterCalendario() {
  yield takeLatest(NEW_FILTER_CALENDARIO, sagaNewFilterCalendario)
}
