import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deleteTipoEmbalagemWhiteLabel } from '../service';
import {
  DELETE_TIPO_EMBALAGEM_WHITE_LABEL,
  DELETE_TIPO_EMBALAGEM_WHITE_LABEL_PENDING,
  DELETE_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS,
  DELETE_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaDeleteTipoEmbalagemWhiteLabel(action) {
  yield put({ type: DELETE_TIPO_EMBALAGEM_WHITE_LABEL_PENDING, id: action.id })

  try {
    yield call(deleteTipoEmbalagemWhiteLabel, action.id);

    yield put({ type: DELETE_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS, id: action.id });

    swal({
      title: 'Excluído!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });

  } catch (error) {

    yield put({ type: DELETE_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE });

    AlertError('Falha ao excluir o registro! Tente novamente...');
  }
}

export default function* watchDeleteTipoEmbalagemWhiteLabel() {
  yield takeLatest(DELETE_TIPO_EMBALAGEM_WHITE_LABEL, sagaDeleteTipoEmbalagemWhiteLabel)
}
