export const FINANCEIRO_TIPO_STATUS_DUPLICATAS_ENUM = [
    {
        id: 'A_PAGAR',
        descricao: "Contas Em Aberto",
    },
    {
        id: 'PAGAS',
        descricao: "Contas Quitadas",
    },
    {
        id: 'TODAS',
        descricao: "Todas as Contas",
    },
    {
        id: 'PAGAMENTOS',
        descricao: "Pagamentos",
    },
];