import { takeLatest, put } from 'redux-saga/effects';

import {
  usuario,
  NEW_USUARIO,
  NEW_USUARIO_SUCCESS,
} from '@handler';

function* sagaNewUsuario(action) {
  yield put({ type: NEW_USUARIO_SUCCESS, registro: usuario.newRegistro });
}

export default function* watchNewUsuario() {
  yield takeLatest(NEW_USUARIO, sagaNewUsuario)
}
