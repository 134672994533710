


import watchNewCompraAnimal from './saga/new';
import watchFindCompraAnimal from './saga/find';
import watchCreateCompraAnimal from './saga/create';
import watchListAllCompraAnimal from './saga/listAll';
import watchListCompraAnimal from './saga/listTable';
import watchDeleteCompraAnimal from './saga/delete';

export const compraAnimalSaga = [
    watchNewCompraAnimal(),
    watchFindCompraAnimal(),
    watchCreateCompraAnimal(),
    watchListAllCompraAnimal(),
    watchListCompraAnimal(),
    watchDeleteCompraAnimal(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
