import { takeLatest, put } from 'redux-saga/effects';

import {
  CANCEL_LOCAL_ESTOQUE_CADASTRO_RAPIDO,
  CANCEL_LOCAL_ESTOQUE_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelLocalEstoqueCadastroRapido() {
  yield put({ type: CANCEL_LOCAL_ESTOQUE_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelLocalEstoqueCadastroRapido() {
  yield takeLatest(CANCEL_LOCAL_ESTOQUE_CADASTRO_RAPIDO, sagaCancelLocalEstoqueCadastroRapido)
}