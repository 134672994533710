import { takeLatest, put, call } from 'redux-saga/effects';

import {
  SELECT_NDVI,
  SELECT_NDVI_PENDING,
  SELECT_NDVI_SUCCESS,
  SELECT_NDVI_FAILURE
} from '../actionTypes';

function* sagaSelectNdvi(action) {
  yield put({ type: SELECT_NDVI_PENDING });

  try {
    yield put({ type: SELECT_NDVI_SUCCESS, item: action.args });
  } catch (error) {
    yield put({ type: SELECT_NDVI_FAILURE });
  }
}

export default function* watchSelectNdvi() {
  yield takeLatest(SELECT_NDVI, sagaSelectNdvi);
}