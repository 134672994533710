export function calculaCustoHora(horario, custo) {
    let [hora, minuto] = horario.split(':').map(v => parseInt(v));
    if (!minuto) {
        minuto = 0;
    }
    // soma toda a hora em minutos e multiplica por (custo por minuto)
    return (minuto + (hora * 60)) * (custo / 60);
}

export function calcularDiffHorasDoubleToHHMM(horaInicial, horaFinal) {
    var diff = (parseFloat(horaFinal) - parseFloat(horaInicial)).toFixed(1);
    var times = diff.split('.', 2)

    var horas = times[0];
    if (horas.length === 1) {
        horas = '0' + horas;
    }
    var segundos = times[1] * 6;

    var horasEmMinutos = horas * 60;

    var minutosTotais = horasEmMinutos + segundos;

    var result = time_convert(minutosTotais).toString().split(':');
    var resultHoraFinal = result[0];
    if (resultHoraFinal.length === 1) {
        resultHoraFinal = '0' + result[0];
    }
    var resultMinutoFinal = '00';
    if (result.length > 1) {
        if (result[1].length === 1) {
            resultMinutoFinal = '0' + result[1];
        } else {
            resultMinutoFinal = result[1];
        }
    }
    return resultHoraFinal + ':' + resultMinutoFinal;
}

function time_convert(num) {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    return `${hours}:${minutes}`;
}