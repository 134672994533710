import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createCompraAnimal, updateCompraAnimal } from '../service';
import {
  CREATE_COMPRA_ANIMAL,
  CREATE_COMPRA_ANIMAL_PENDING,
  CREATE_COMPRA_ANIMAL_SUCCESS,
  CREATE_COMPRA_ANIMAL_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { formatDateDDMMYYYY, isDateValidVerifyDateBetween } from '@components/common/format'

function* sagaCreateCompraAnimal(action) {
  yield put({ type: CREATE_COMPRA_ANIMAL_PENDING })

  try {
    const anoPecuarioAtivo = JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo;
    var dateFrom = anoPecuarioAtivo.dataInicial;
    var dateTo = anoPecuarioAtivo.dataFinal;
    var dateCheck = action.data.data;

    if (!isDateValidVerifyDateBetween(dateFrom, dateTo, dateCheck)) {
      yield put({ type: CREATE_COMPRA_ANIMAL_FAILURE, registro: action.data })

      AlertWarning('Data informada fora do período do ano pecuário ativo. Período deve ser entre '
        + formatDateDDMMYYYY(dateFrom) + ' até ' + formatDateDDMMYYYY(dateTo) + '!');
    } else {
      if (!action.data.compraDup.geraDuplicata) {
        action.data.compraDup.tipoDuplicata = null;
        action.data.compraDup.contaGerencial = null;
        action.data.compraDup.historico = '';
        action.data.compraDup.tipoPagamento = null;
        action.data.compraDup.contaCaixa = null;
        action.data.compraDup.contaBanco = null;
        action.data.compraDup.numeroParcelas = null;
        action.data.parcelas = [];
      } else if (action.data.compraDup.geraDuplicata) {
        if (action.data.compraDup.tipoDuplicata === 'A_VISTA') {
          action.data.compraDup.numeroParcelas = null;
          action.data.parcelas = [];
          if (action.data.compraDup.movimentaFinanceiro === false) {
            action.data.compraDup.contaGerencial = null;
            action.data.compraDup.historico = '';
            action.data.compraDup.tipoPagamento = null;
            action.data.compraDup.contaCaixa = null;
            action.data.compraDup.contaBanco = null;
          }
        } else if (action.data.compraDup.tipoDuplicata === 'PARCELADA' && action.data.compraDup.movimentaFinanceiro === false) {
          for (var i = 0; i < action.data.parcelas.length; i++) {
            action.data.parcelas[i].contaGerencial = null;
            action.data.parcelas[i].historico = '';
            action.data.parcelas[i].parcelaPaga = false;
            action.data.parcelas[i].tipoPagamento = null;
            action.data.parcelas[i].contaCaixa = null;
            action.data.parcelas[i].contaBanco = null;
          }
        }
      }

      delete action.data.totalDuplicatas;

      if (action.data.id === undefined || action.data.id === null) {
        yield call(createCompraAnimal, {
          ...action.data,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          },
        });
      } else {
        let lancamentosContaPagar = [];
        action.data.lancamentosContaPagar.forEach(element => {
          lancamentosContaPagar = [
            ...lancamentosContaPagar,
            {
              "forJson": "contapagar",
              "id": element.id,
              "valor": element.valor,
              "historico": element.historico,
              "propriedade": { "id": element.propriedade.id },
              "contaGerencial": {
                "id": element.contaGerencial.id,
                "tipoConta": "A"
              }
            }
          ]
        });

        yield call(updateCompraAnimal,
          {
            ...action.data,
            lancamentosContaPagar: lancamentosContaPagar,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_COMPRA_ANIMAL_SUCCESS, registro: action.data })

      history.push('/pecuaria/movimentacao/compra');

      AlertSuccess('Compra registrada com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_COMPRA_ANIMAL_FAILURE, registro: action.data })

    AlertError('Falha ao salvar a compra! Tente novamente...');
  }
}

export default function* watchCreateCompraAnimal() {
  yield takeLatest(CREATE_COMPRA_ANIMAL, sagaCreateCompraAnimal)
}
