import React, { useState } from 'react';
import { Col, FormGroup, Label, FormFeedback } from 'reactstrap';
import { Field } from 'formik';
import PlacesAutocomplete from 'react-places-autocomplete';
import './GoogleMapsLocationSelect.css';

export const GoogleMapsLocationSelect = (props) => {
    const { col, name, label, placeholder, latitude, longitude } = props;
    const [selected, setSelected] = useState(false);

    return (
        <Col md={col}>
            <FormGroup>
                <Label htmlFor={'id_' + name}>{label}</Label>
                <Field name={name}>
                    {({ field, form, meta }) => {
                        const isError = meta.error && meta.touched;

                        const handleSelect = value => {
                            setSelected(true);
                            const geocoder = new google.maps.Geocoder();

                            geocoder.geocode({ 'address': value }, (results, status) => {
                                if (status === google.maps.GeocoderStatus.OK) {
                                    const lat = results[0].geometry.location.lat();
                                    const lng = results[0].geometry.location.lng();

                                    form.setFieldValue(name, value);
                                    form.setFieldValue(latitude, lat);
                                    form.setFieldValue(longitude, lng);
                                } else {
                                    form.setFieldValue(name, '');
                                    form.setFieldValue(latitude, null);
                                    form.setFieldValue(longitude, null);
                                }
                            });
                        };

                        const handleBlur = () => {
                            if (!selected) {
                                form.setFieldValue(name, '');
                                form.setFieldValue(latitude, null);
                                form.setFieldValue(longitude, null);
                            }
                        }

                        return (
                            <PlacesAutocomplete
                                value={field.value}
                                onChange={value => {
                                    setSelected(false);
                                    form.setFieldValue(name, value);
                                }}
                                onSelect={handleSelect}
                                searchOptions={{
                                    types: ['(cities)']
                                }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div className="places-autocomplete-container">
                                        <input
                                            {...getInputProps({
                                                placeholder: placeholder,
                                                className: `form-control ${isError ? 'is-invalid' : ''}`,
                                                onBlur: handleBlur,
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div className='autocomplete-suggestion'>Carregando cidades...</div>}
                                            {suggestions.map(suggestion => (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className: 'autocomplete-suggestion',
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            ))}
                                        </div>
                                        {isError && <FormFeedback>{form.errors[name]}</FormFeedback>}
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        );
                    }}
                </Field>
            </FormGroup>
        </Col>
    );
};

GoogleMapsLocationSelect.defaultProps = {
    col: 12,
};
