import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_CATALAGO_PRODUTO_WHITE_LABEL_RAPIDO,
    CANCEL_CATALAGO_PRODUTO_WHITE_LABEL_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelCatalagoProdutoWhiteLabelRapido() {
  yield put({ type: CANCEL_CATALAGO_PRODUTO_WHITE_LABEL_RAPIDO_SUCCESS})
}

export default function* watchCancelCatalagoProdutoWhiteLabelRapido() {
  yield takeLatest(CANCEL_CATALAGO_PRODUTO_WHITE_LABEL_RAPIDO, sagaCancelCatalagoProdutoWhiteLabelRapido)
}