import { takeLatest, put, call } from 'redux-saga/effects';

import { listCompraAnimal } from '../service';
import {
  TABLE_COMPRA_ANIMAL,
  TABLE_COMPRA_ANIMAL_PENDING,
  TABLE_COMPRA_ANIMAL_SUCCESS,
  TABLE_COMPRA_ANIMAL_FAILURE
} from '../actionTypes';

function* sagaListCompraAnimal(action) {
  yield put({ type: TABLE_COMPRA_ANIMAL_PENDING })

  try {
    const table = yield call(listCompraAnimal, action.args)
    yield put({ type: TABLE_COMPRA_ANIMAL_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_COMPRA_ANIMAL_FAILURE })
  }
}

export default function* watchListCompraAnimal() {
  yield takeLatest(TABLE_COMPRA_ANIMAL, sagaListCompraAnimal)
}