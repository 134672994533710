import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import { getGraficoAnimaisAtivoPesoPorLote, getGraficoAnimaisAtivoPorCategoria, getGraficoAnimaisAtivoPorLote, getGraficoAnimaisAtivoPorSexo, getTotaisPecuaria } from '../service';
import {
    CONFIG_SESSION,
    TOTAIS_DASHBOARD_PECUARIA,
    TOTAIS_DASHBOARD_PECUARIA_PENDING,
    TOTAIS_DASHBOARD_PECUARIA_SUCCESS,
    TOTAIS_DASHBOARD_PECUARIA_FAILURE,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_PENDING,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_SUCCESS,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_FAILURE,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_PENDING,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_SUCCESS,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_FAILURE,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_PENDING,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_SUCCESS,
    PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_FAILURE,
    PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_PENDING,
    PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_SUCCESS,
    PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_FAILURE,
} from '@handler';
import { formatDateDDMMYYYY } from '@components/common/format';

function* sagaTotaisPecuaria(action) {
    // GET TOTAIS

    yield put({ type: TOTAIS_DASHBOARD_PECUARIA_PENDING })

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    const idAnoPecuario = JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo.id;

    try {
        const totais = yield call(getTotaisPecuaria,
            idFazenda,
            idAnoPecuario,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        yield put({
            type: TOTAIS_DASHBOARD_PECUARIA_SUCCESS, totais: totais
        })

    } catch (error) {
        yield put({ type: TOTAIS_DASHBOARD_PECUARIA_FAILURE })

    }



    // getGraficoAnimaisAtivoPorLote

    yield put({ type: PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_PENDING })

    try {
        const dadosGraficoAnimaisAtivoPorLote = yield call(getGraficoAnimaisAtivoPorLote,
            idFazenda,
            idAnoPecuario,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        let chartData = [['Lote', 'Animais']];
        for (let i = 0; i < dadosGraficoAnimaisAtivoPorLote.length; i += 1) {
            chartData.push([dadosGraficoAnimaisAtivoPorLote[i].text, dadosGraficoAnimaisAtivoPorLote[i].value])
        }

        yield put({ type: PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_SUCCESS, dadosGraficoAnimaisAtivoPorLote: chartData })

    } catch (error) {
        yield put({ type: PEC_GRAFICO_ANIMAIS_ATIVO_POR_LOTE_FAILURE })

    }



    // getGraficoAnimaisAtivoPorCategoria

    yield put({ type: PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_PENDING })

    try {
        const dadosGraficoAnimaisAtivoPorCategoria = yield call(getGraficoAnimaisAtivoPorCategoria,
            idFazenda,
            idAnoPecuario,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        let chartData = [['Categoria', 'Animais']];
        for (let i = 0; i < dadosGraficoAnimaisAtivoPorCategoria.length; i += 1) {
            chartData.push([dadosGraficoAnimaisAtivoPorCategoria[i].text, dadosGraficoAnimaisAtivoPorCategoria[i].value])
        }

        yield put({ type: PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_SUCCESS, dadosGraficoAnimaisAtivoPorCategoria: chartData })

    } catch (error) {
        yield put({ type: PEC_GRAFICO_ANIMAIS_ATIVO_POR_CATEGORIA_FAILURE })

    }



    // getGraficoAnimaisAtivoPorSexo

    yield put({ type: PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_PENDING })

    try {
        const dadosGraficoAnimaisAtivoPorSexo = yield call(getGraficoAnimaisAtivoPorSexo,
            idFazenda,
            idAnoPecuario,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        let chartData = [['Sexo', 'Animais']];
        for (let i = 0; i < dadosGraficoAnimaisAtivoPorSexo.length; i += 1) {
            chartData.push([dadosGraficoAnimaisAtivoPorSexo[i].text, dadosGraficoAnimaisAtivoPorSexo[i].value])
        }

        yield put({ type: PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_SUCCESS, dadosGraficoAnimaisAtivoPorSexo: chartData })

    } catch (error) {
        yield put({ type: PEC_GRAFICO_ANIMAIS_ATIVO_POR_SEXO_FAILURE })

    }



    // getGraficoAnimaisAtivoPesoPorLote

    yield put({ type: PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_PENDING })

    try {
        const dadosGraficoAnimaisAtivoPesoPorLote = yield call(getGraficoAnimaisAtivoPesoPorLote,
            idFazenda,
            idAnoPecuario,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal));

        let chartData = [['Lote', 'Animais']];
        for (let i = 0; i < dadosGraficoAnimaisAtivoPesoPorLote.length; i += 1) {
            chartData.push([dadosGraficoAnimaisAtivoPesoPorLote[i].text, dadosGraficoAnimaisAtivoPesoPorLote[i].value])
        }

        yield put({ type: PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_SUCCESS, dadosGraficoAnimaisAtivoPesoPorLote: chartData })

    } catch (error) {
        yield put({ type: PEC_GRAFICO_ANIMAIS_ATIVO_PESO_POR_LOTE_FAILURE })

    }


}

export default function* watchTotaisPecuaria() {
    yield takeLatest(TOTAIS_DASHBOARD_PECUARIA, sagaTotaisPecuaria)
}
