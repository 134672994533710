import { takeLatest, put } from 'redux-saga/effects';

import {
  naturezaOperacaoCadastro,
  NEW_NATUREZA_OPERACAO_CADASTRO,
  NEW_NATUREZA_OPERACAO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewNaturezaOperacaoCadastro(action) {

  yield put({ 
    type: NEW_NATUREZA_OPERACAO_CADASTRO_SUCCESS, 
    registro: naturezaOperacaoCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido, 
  });
}

export default function* watchNewNaturezaOperacaoCadastro() {
  yield takeLatest(NEW_NATUREZA_OPERACAO_CADASTRO, sagaNewNaturezaOperacaoCadastro)
}
