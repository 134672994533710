import { takeLatest, put, call } from 'redux-saga/effects';

import { listColheitaTable } from '../service';
import {
  TABLE_COLHEITA,
  TABLE_COLHEITA_PENDING,
  TABLE_COLHEITA_SUCCESS,
  TABLE_COLHEITA_FAILURE
} from '../actionTypes';

function* sagaListColheita(action) {
  yield put({ type: TABLE_COLHEITA_PENDING })

  try {
    const table = yield call(listColheitaTable, action.args)
    yield put({ type: TABLE_COLHEITA_SUCCESS, table: table })

  } catch (error) {

    yield put({ type: TABLE_COLHEITA_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListColheita() {
  yield takeLatest(TABLE_COLHEITA, sagaListColheita)
}