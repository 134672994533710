import { takeLatest, put, call } from 'redux-saga/effects';

import { listAnimalEspecieCadastro } from '../service';
import {
    TABLE_ANIMAL_ESPECIE_CADASTRO,
    TABLE_ANIMAL_ESPECIE_CADASTRO_PENDING,
    TABLE_ANIMAL_ESPECIE_CADASTRO_SUCCESS,
    TABLE_ANIMAL_ESPECIE_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAnimalEspecieCadastro(action) {
  yield put({ type: TABLE_ANIMAL_ESPECIE_CADASTRO_PENDING })

  try {
    const table = yield call(listAnimalEspecieCadastro, action.args)
    yield put({ type: TABLE_ANIMAL_ESPECIE_CADASTRO_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_ANIMAL_ESPECIE_CADASTRO_FAILURE })
  }
}

export default function* watchListAnimalEspecieCadastro() {
  yield takeLatest(TABLE_ANIMAL_ESPECIE_CADASTRO, sagaListAnimalEspecieCadastro)
}