import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/conta/caixa' };
/////////////////////////////////////////////////////////////////////////////////

export function createContaCaixaCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateContaCaixaCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listContaCaixaCadastro(param) {
  return httpService.get({ ...args, args: param });
}

export function findContaCaixaCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllContaCaixaCadastro(propriedade) {
  return httpService.get({ ...args, path: `/conta/caixa/all/${propriedade}` });
}

export function deleteContaCaixaCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

