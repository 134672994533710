
export const NEW_PATRIMONIO_CADASTRO = 'NEW_PATRIMONIO_CADASTRO'
export const NEW_PATRIMONIO_CADASTRO_SUCCESS = 'NEW_PATRIMONIO_CADASTRO_SUCCESS'

export const CREATE_PATRIMONIO_CADASTRO = 'CREATE_PATRIMONIO_CADASTRO'
export const CREATE_PATRIMONIO_CADASTRO_PENDING = 'CREATE_PATRIMONIO_CADASTRO_PENDING'
export const CREATE_PATRIMONIO_CADASTRO_SUCCESS = 'CREATE_PATRIMONIO_CADASTRO_SUCCESS'
export const CREATE_PATRIMONIO_CADASTRO_FAILURE = 'CREATE_PATRIMONIO_CADASTRO_FAILURE'

export const CANCEL_PATRIMONIO_CADASTRO_RAPIDO = 'CANCEL_PATRIMONIO_CADASTRO_RAPIDO'
export const CANCEL_PATRIMONIO_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_PATRIMONIO_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_PATRIMONIO_CADASTRO = 'TABLE_PATRIMONIO_CADASTRO'
export const TABLE_PATRIMONIO_CADASTRO_PENDING = 'TABLE_PATRIMONIO_CADASTRO_PENDING'
export const TABLE_PATRIMONIO_CADASTRO_SUCCESS = 'TABLE_PATRIMONIO_CADASTRO_SUCCESS'
export const TABLE_PATRIMONIO_CADASTRO_FAILURE = 'TABLE_PATRIMONIO_CADASTRO_FAILURE'

export const LIST_PATRIMONIO_CADASTRO = 'LIST_PATRIMONIO_CADASTRO'
export const LIST_PATRIMONIO_CADASTRO_PENDING = 'LIST_PATRIMONIO_CADASTRO_PENDING'
export const LIST_PATRIMONIO_CADASTRO_SUCCESS = 'LIST_PATRIMONIO_CADASTRO_SUCCESS'
export const LIST_PATRIMONIO_CADASTRO_FAILURE = 'LIST_PATRIMONIO_CADASTRO_FAILURE'

export const FIND_PATRIMONIO_CADASTRO = 'FIND_PATRIMONIO_CADASTRO'
export const FIND_PATRIMONIO_CADASTRO_PENDING = 'FIND_PATRIMONIO_CADASTRO_PENDING'
export const FIND_PATRIMONIO_CADASTRO_SUCCESS = 'FIND_PATRIMONIO_CADASTRO_SUCCESS'
export const FIND_PATRIMONIO_CADASTRO_FAILURE = 'FIND_PATRIMONIO_CADASTRO_FAILURE'

export const DELETE_PATRIMONIO_CADASTRO = 'DELETE_PATRIMONIO_CADASTRO'
export const DELETE_PATRIMONIO_CADASTRO_PENDING = 'DELETE_PATRIMONIO_CADASTRO_PENDING'
export const DELETE_PATRIMONIO_CADASTRO_SUCCESS = 'DELETE_PATRIMONIO_CADASTRO_SUCCESS'
export const DELETE_PATRIMONIO_CADASTRO_FAILURE = 'DELETE_PATRIMONIO_CADASTRO_FAILURE'
