import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import { NEW_PLANTIO_MANEJO_BY_PLANEJAMENTO_PENDING } from '@handler';
import {
    NEW_PLANTIO_MANEJO_SUCCESS,
    NEW_PLANTIO_MANEJO_CUSTO_DIRETO_SUCCESS,
    TABLE_PLANTIO_MANEJO_PENDING,
    TABLE_PLANTIO_MANEJO_SUCCESS,
    TABLE_PLANTIO_MANEJO_FAILURE,
    LIST_PLANTIO_MANEJO_PENDING,
    LIST_PLANTIO_MANEJO_SUCCESS,
    LIST_PLANTIO_MANEJO_FAILURE,
    FIND_PLANTIO_MANEJO_PENDING,
    FIND_PLANTIO_MANEJO_SUCCESS,
    FIND_PLANTIO_MANEJO_FAILURE,
    DELETE_PLANTIO_MANEJO_PENDING,
    DELETE_PLANTIO_MANEJO_SUCCESS,
    DELETE_PLANTIO_MANEJO_FAILURE,
    CREATE_PLANTIO_MANEJO_PENDING,
    CREATE_PLANTIO_MANEJO_SUCCESS,
    CREATE_PLANTIO_MANEJO_FAILURE,
    GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_PENDING,
    GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_SUCCESS,
    GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_FAILURE,
    REPROCESSAR_ESTOQUE_AGRICULTURA_PENDING,
    REPROCESSAR_ESTOQUE_AGRICULTURA_SUCCESS,
    REPROCESSAR_ESTOQUE_AGRICULTURA_FAILURE,
    NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO_PENDING,
    NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO_SUCCESS,
    NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO_FAILURE,
    NEW_FILTER_PLANTIO_MANEJO_PEDING,
    NEW_FILTER_PLANTIO_MANEJO_SUCCESS,
    NEW_FILTER_PLANTIO_MANEJO_FAILURE,
    GET_TOTAIS_PLANTIO_MANEJO_PENDING,
    GET_TOTAIS_PLANTIO_MANEJO_SUCCESS,
    GET_TOTAIS_PLANTIO_MANEJO_FAILURE,
    GET_LOADING_PLANTIO_MANEJO_SUCCESS,
    GET_LOADING_PLANTIO_MANEJO_PENDING
} from './actionTypes';


const initialState = {
    list: null,
    loading: false,
    loadingTotais: false,
    loadingReprocessarEstoque: false,
    safraPossuiPendenciaEstoque: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
    totais: {
        custoTotal: 0,
        custoTotalHa: 0
    }
}


function plantioManejoReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_PLANTIO_MANEJO_SUCCESS:
        case NEW_PLANTIO_MANEJO_CUSTO_DIRETO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case LIST_PLANTIO_MANEJO_PENDING:
        case FIND_PLANTIO_MANEJO_PENDING:
        case DELETE_PLANTIO_MANEJO_PENDING:
        case CREATE_PLANTIO_MANEJO_PENDING:
        case TABLE_PLANTIO_MANEJO_PENDING:
        case NEW_PLANTIO_MANEJO_BY_PLANEJAMENTO_PENDING:
        case GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_PENDING:
        case NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO_PENDING:
        case NEW_FILTER_PLANTIO_MANEJO_PEDING:
        case GET_LOADING_PLANTIO_MANEJO_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_LOADING_PLANTIO_MANEJO_SUCCESS:
            return {
                ...state,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_PLANTIO_MANEJO_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_PLANTIO_MANEJO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_PLANTIO_MANEJO_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_PLANTIO_MANEJO_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_PLANTIO_MANEJO_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_PLANTIO_MANEJO_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_PLANTIO_MANEJO_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_PLANTIO_MANEJO_SUCCESS:
        case CREATE_PLANTIO_MANEJO_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case DELETE_PLANTIO_MANEJO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_SUCCESS:
            return {
                ...state,
                safraPossuiPendenciaEstoque: action.safraPossuiPendenciaEstoque,
                loading: false
            }
        case GET_SAFRA_POSSUI_PENDENCIA_ESTOQUE_FAILURE:
            return {
                ...state,
                safraPossuiPendenciaEstoque: false,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case REPROCESSAR_ESTOQUE_AGRICULTURA_PENDING:
            return {
                ...state,
                loadingReprocessarEstoque: true
            }
        case REPROCESSAR_ESTOQUE_AGRICULTURA_SUCCESS:
        case REPROCESSAR_ESTOQUE_AGRICULTURA_FAILURE:
            return {
                ...state,
                loadingReprocessarEstoque: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_PLANTIO_MANEJO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_PLANTIO_MANEJO_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_PLANTIO_MANEJO_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_TOTAIS_PLANTIO_MANEJO_PENDING:
            return {
                ...state,
                loadingTotais: true,
            }
        case GET_TOTAIS_PLANTIO_MANEJO_SUCCESS:
            return {
                ...state,
                totais: action.totais,
                loadingTotais: false
            }
        case GET_TOTAIS_PLANTIO_MANEJO_FAILURE:
            return {
                ...state,
                totais: {
                    custoTotal: 0,
                    custoTotalHa: 0
                },
                loadingTotais: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { plantioManejoReducer };