


import watchNewTransferenciaAnimal from './saga/new';
import watchFindTransferenciaAnimal from './saga/find';
import watchCreateTransferenciaAnimal from './saga/create';
import watchListAllTransferenciaAnimal from './saga/listAll';
import watchListTransferenciaAnimal from './saga/listTable';
import watchDeleteTransferenciaAnimal from './saga/delete';

export const transferenciaAnimalSaga = [
    watchNewTransferenciaAnimal(),
    watchFindTransferenciaAnimal(),
    watchCreateTransferenciaAnimal(),
    watchListAllTransferenciaAnimal(),
    watchListTransferenciaAnimal(),
    watchDeleteTransferenciaAnimal(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
