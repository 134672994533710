import { takeLatest, put } from 'redux-saga/effects';

import {
  contaChequePreDatadoCadastro,
  NEW_CONTA_CHEQUE_PRE_DATADO_CADASTRO,
  NEW_CONTA_CHEQUE_PRE_DATADO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewContaChequePreDatadoCadastro(action) {

  yield put({ type: NEW_CONTA_CHEQUE_PRE_DATADO_CADASTRO_SUCCESS, registro: contaChequePreDatadoCadastro.newRegistro });
}

export default function* watchNewContaChequePreDatadoCadastro() {
  yield takeLatest(NEW_CONTA_CHEQUE_PRE_DATADO_CADASTRO, sagaNewContaChequePreDatadoCadastro)
}
