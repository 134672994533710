import { takeLatest, put, call } from 'redux-saga/effects';
import { history } from '@commons';

import { listAllTalhaoAtivo } from '@handler/common/talhaoCadastro/service';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  campoCadastro,
  NEW_CAMPO_CADASTRO,
  NEW_CAMPO_CADASTRO_PENDING,
  NEW_CAMPO_CADASTRO_SUCCESS,
  NEW_CAMPO_CADASTRO_FAILURE
} from '@handler';

import { AlertError } from '@components/common/AlertToast'


function* sagaNewCampoCadastro(action) {
  yield put({ type: NEW_CAMPO_CADASTRO_PENDING });

  try {
    const talhoes = yield call(listAllTalhaoAtivo, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id);

    let campoTalhaoList = []
    talhoes.content.forEach(
      (talhao) => {
        campoTalhaoList = campoTalhaoList.concat({
          talhao: talhao,
          status: false,
          // area: talhao.area
        })
      });
    yield put({
      type: NEW_CAMPO_CADASTRO_SUCCESS, registro: {
        ...campoCadastro.newRegistro,
        campoTalhaoList
      },
      isCadastroRapido: action.isCadastroRapido, 
    });
  } catch (error) {
    yield put({ type: NEW_CAMPO_CADASTRO_FAILURE })

    AlertError('Falha ao gerar novo cadastro! Tente novamente...');

    history.push('/pecuaria/campoCadastro');
  }





  // yield put({ 
  //   type: NEW_CAMPO_CADASTRO_SUCCESS, 
  //   registro: campoCadastro.newRegistro,
  //   isCadastroRapido: action.isCadastroRapido, 
  // });
}

export default function* watchNewCampoCadastro() {
  yield takeLatest(NEW_CAMPO_CADASTRO, sagaNewCampoCadastro)
}
