export const NEW_EVOLUCAO_ANIMAL = 'NEW_EVOLUCAO_ANIMAL'
export const NEW_EVOLUCAO_ANIMAL_SUCCESS = 'NEW_EVOLUCAO_ANIMAL_SUCCESS'

export const CREATE_EVOLUCAO_ANIMAL = 'CREATE_EVOLUCAO_ANIMAL'
export const CREATE_EVOLUCAO_ANIMAL_PENDING = 'CREATE_EVOLUCAO_ANIMAL_PENDING'
export const CREATE_EVOLUCAO_ANIMAL_SUCCESS = 'CREATE_EVOLUCAO_ANIMAL_SUCCESS'
export const CREATE_EVOLUCAO_ANIMAL_FAILURE = 'CREATE_EVOLUCAO_ANIMAL_FAILURE'

export const TABLE_EVOLUCAO_ANIMAL = 'TABLE_EVOLUCAO_ANIMAL'
export const TABLE_EVOLUCAO_ANIMAL_PENDING = 'TABLE_EVOLUCAO_ANIMAL_PENDING'
export const TABLE_EVOLUCAO_ANIMAL_SUCCESS = 'TABLE_EVOLUCAO_ANIMAL_SUCCESS'
export const TABLE_EVOLUCAO_ANIMAL_FAILURE = 'TABLE_EVOLUCAO_ANIMAL_FAILURE'

export const LIST_EVOLUCAO_ANIMAL = 'LIST_EVOLUCAO_ANIMAL'
export const LIST_EVOLUCAO_ANIMAL_PENDING = 'LIST_EVOLUCAO_ANIMAL_PENDING'
export const LIST_EVOLUCAO_ANIMAL_SUCCESS = 'LIST_EVOLUCAO_ANIMAL_SUCCESS'
export const LIST_EVOLUCAO_ANIMAL_FAILURE = 'LIST_EVOLUCAO_ANIMAL_FAILURE'

export const FIND_EVOLUCAO_ANIMAL = 'FIND_EVOLUCAO_ANIMAL'
export const FIND_EVOLUCAO_ANIMAL_PENDING = 'FIND_EVOLUCAO_ANIMAL_PENDING'
export const FIND_EVOLUCAO_ANIMAL_SUCCESS = 'FIND_EVOLUCAO_ANIMAL_SUCCESS'
export const FIND_EVOLUCAO_ANIMAL_FAILURE = 'FIND_EVOLUCAO_ANIMAL_FAILURE'

export const DELETE_EVOLUCAO_ANIMAL = 'DELETE_EVOLUCAO_ANIMAL'
export const DELETE_EVOLUCAO_ANIMAL_PENDING = 'DELETE_EVOLUCAO_ANIMAL_PENDING'
export const DELETE_EVOLUCAO_ANIMAL_SUCCESS = 'DELETE_EVOLUCAO_ANIMAL_SUCCESS'
export const DELETE_EVOLUCAO_ANIMAL_FAILURE = 'DELETE_EVOLUCAO_ANIMAL_FAILURE'