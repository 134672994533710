import { takeLatest, put, call } from 'redux-saga/effects';

import { listTransportadoraCadastro } from '../service';
import {
  TABLE_TRANSPORTADORA_CADASTRO,
  TABLE_TRANSPORTADORA_CADASTRO_PENDING,
  TABLE_TRANSPORTADORA_CADASTRO_SUCCESS,
  TABLE_TRANSPORTADORA_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListTransportadoraCadastro(action) {
  yield put({ type: TABLE_TRANSPORTADORA_CADASTRO_PENDING })

  try {
    const table = yield call(listTransportadoraCadastro, action.args)
    yield put({ type: TABLE_TRANSPORTADORA_CADASTRO_SUCCESS, table: table })

  } catch (error) {

    yield put({ type: TABLE_TRANSPORTADORA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListTransportadoraCadastro() {
  yield takeLatest(TABLE_TRANSPORTADORA_CADASTRO, sagaListTransportadoraCadastro)
}