import watchNewAnimalRacaCadastro from './saga/new';
import watchFindAnimalRacaCadastro from './saga/find';
import watchCreateAnimalRacaCadastro from './saga/create';
import watchListAnimalRacaCadastro from './saga/listTable';
import watchDeleteAnimalRacaCadastro from './saga/delete';
import watchCancelAnimalRacaCadastroRapido from './saga/cancel';

export const animalRacaCadastroSaga = [
    watchNewAnimalRacaCadastro(),
    watchFindAnimalRacaCadastro(),
    watchCreateAnimalRacaCadastro(),
    watchListAnimalRacaCadastro(),
    watchDeleteAnimalRacaCadastro(),
    watchCancelAnimalRacaCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
