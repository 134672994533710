import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/pecuaria/manejo/planejamento' };
/////////////////////////////////////////////////////////////////////////////////

export function createManejoPlanejamentoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateManejoPlanejamentoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listManejoPlanejamentoCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findManejoPlanejamentoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllManejoPlanejamentosByPropriedade(propriedade) {
  return httpService.get({ ...args, path: '/manejo/planejamento?size=20000&page=0&status=true&propriedade=' + propriedade });
}

export function deleteManejoPlanejamentoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}