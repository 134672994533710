import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/agricultura/estoque/implantacao' };
/////////////////////////////////////////////////////////////////////////////////

export function createImplantacaoEstoqueProducao(payload) {
  return httpService.post(args, payload);
}

export function updateImplantacaoEstoqueProducao(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listImplantacaoEstoqueProducao(param) {
  return httpService.get({ ...args, args: param });
}

export function findImplantacaoEstoqueProducao(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllImplantacaoEstoqueProducao() {
  return httpService.get({ ...args, path: '/agricultura/estoque/implantacao/all' });
}

export function deleteImplantacaoEstoqueProducao(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

