import { takeLatest, put, call } from 'redux-saga/effects';

import { findPlantioManejo } from '../service';
import { storage } from '@commons';
import {
  CONFIG_SESSION,
  FIND_PLANTIO_MANEJO_CUSTO_DIRETO,
  FIND_PLANTIO_MANEJO_PENDING,
  FIND_PLANTIO_MANEJO_SUCCESS,
  FIND_PLANTIO_MANEJO_FAILURE
} from '@handler';

function* sagaFindPlantioManejoCustoDireto(action) {
  yield put({ type: FIND_PLANTIO_MANEJO_PENDING, id: action.id })

  try {

    const talhoesSafra = yield JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.safraTalhaoList;

    talhoesSafra.sort((a, b) => a.talhao.nome.localeCompare(b.talhao.nome));

    var custoDiretoTalhao = new Map();
    talhoesSafra.map(safraTalhao => {
      return custoDiretoTalhao.set(safraTalhao.talhao.id, {
        id: safraTalhao.id,
        talhao: safraTalhao.talhao,
        ativo: false
      });
    });

    const registro = yield call(findPlantioManejo, action.id);

    let talhoes = []
    registro.custoDireto.custoDiretoTalhao.forEach((custoDireto, key) => {
      
      custoDiretoTalhao.get(custoDireto.talhao.id).ativo = true;
      talhoes = talhoes.concat(`${custoDireto.talhao.id}`)
    })
    
    yield put({
      type: FIND_PLANTIO_MANEJO_SUCCESS, registro: {
        ...registro.custoDireto,
        custoDiretoTalhao: custoDiretoTalhao,
        talhoes: talhoes
      }
    })
  } catch (error) {
    yield put({ type: FIND_PLANTIO_MANEJO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindPlantioManejoCustoDireto() {
  yield takeLatest(FIND_PLANTIO_MANEJO_CUSTO_DIRETO, sagaFindPlantioManejoCustoDireto)
}
