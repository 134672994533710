import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deletePerfilUsuarioWhiteLabel } from '../service';
import {
  DELETE_PERFIL_USUARIO_WHITE_LABEL,
  DELETE_PERFIL_USUARIO_WHITE_LABEL_PENDING,
  DELETE_PERFIL_USUARIO_WHITE_LABEL_SUCCESS,
  DELETE_PERFIL_USUARIO_WHITE_LABEL_FAILURE
} from '../actionTypes';
import { AlertError } from '@components/common/AlertToast';

function* sagaDeletePerfilUsuarioWhiteLabel(action) {
  yield put({ type: DELETE_PERFIL_USUARIO_WHITE_LABEL_PENDING, id: action.id })

  try {

    yield call(deletePerfilUsuarioWhiteLabel, action.id);

    yield put({ type: DELETE_PERFIL_USUARIO_WHITE_LABEL_SUCCESS, removed: true });

    swal({
      title: 'Perfil Excluído com Sucesso!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });
  } catch (error) {
    AlertError('Falha ao deletar o registro! Tente novamente...');

    yield put({ type: DELETE_PERFIL_USUARIO_WHITE_LABEL_FAILURE, removed: false });
  }
}

export default function* watchDeletePerfilUsuarioWhiteLabel() {
  yield takeLatest(DELETE_PERFIL_USUARIO_WHITE_LABEL, sagaDeletePerfilUsuarioWhiteLabel)
}
