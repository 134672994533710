import { takeLatest, put, call } from 'redux-saga/effects';

import { listBaixaManualEstoqueProducaoFilter } from '../service';
import { storage } from '@commons/storage';
import {
  NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO,
  NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING,
  NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS,
  NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE,
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { formatDateDDMMYYYY } from '@components/common/format';
import { CONST_FILTER_AGRIC_EST_BAIXA_MANUAL } from '@commons/consts';

function* sagaNewFilterNextPageBaixaManualEstoqueProducao(action) {
  yield put({ type: NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING })

  try {
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    const idSafraAtiva = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva?.id ?? -1;

    let filterSaved = sessionStorage.getItem(CONST_FILTER_AGRIC_EST_BAIXA_MANUAL) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_AGRIC_EST_BAIXA_MANUAL)) : null;

    const datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

    let newFilter = {
      ...filterSaved,
      rows: action.rows,
      page: action.page,
    };

    let list = yield call(listBaixaManualEstoqueProducaoFilter, idFazenda, idSafraAtiva, datas, `&page=${newFilter.page}&size=${newFilter.rows}${newFilter.where}`);

    sessionStorage.setItem(CONST_FILTER_AGRIC_EST_BAIXA_MANUAL, JSON.stringify(newFilter));

    yield put({
      type: NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS, list: list, filter: newFilter
    });
  } catch (error) {

    yield put({ type: NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE })
    // console.error(error) // eslint-disable-line
  }
}

export default function* watchNewFilterNextPageBaixaManualEstoqueProducao() {
  yield takeLatest(NEW_FILTER_NEXT_PAGE_BAIXAR_MANUAL_ESTOQUE_PRODUCAO, sagaNewFilterNextPageBaixaManualEstoqueProducao)
}