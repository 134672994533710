import { takeLatest, put, call } from 'redux-saga/effects';

import { findUsuarioWhiteLabel } from '../service';
import {
  FIND_USUARIO_WHITE_LABEL,
  FIND_USUARIO_WHITE_LABEL_PENDING,
  FIND_USUARIO_WHITE_LABEL_SUCCESS,
  FIND_USUARIO_WHITE_LABEL_FAILURE
} from '../actionTypes';

function* sagaFindUsuarioWhiteLabel(action) {
  yield put({ type: FIND_USUARIO_WHITE_LABEL_PENDING, id: action.id })

  try {
    var registro = yield call(findUsuarioWhiteLabel, action.id);

    yield put({
      type: FIND_USUARIO_WHITE_LABEL_SUCCESS, registro: {
        ...registro,
      }
    })
  } catch (error) {
    yield put({ type: FIND_USUARIO_WHITE_LABEL_FAILURE })
  }
}

export default function* watchFindUsuarioWhiteLabel() {
  yield takeLatest(FIND_USUARIO_WHITE_LABEL, sagaFindUsuarioWhiteLabel)
}
