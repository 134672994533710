import * as Yup from 'yup';

const newRegistro = {
    controleCustoProdutoEnum: null,
    formaLancMaquinario: null,
};

const validation = Yup.object().shape({
    controleCustoProdutoEnum: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    formaLancMaquinario: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
});

export const configGeral = {
    newRegistro,
    validation
}