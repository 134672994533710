import { takeLatest, put } from 'redux-saga/effects';

import {
  CANCEL_CLIENTE_CADASTRO_RAPIDO,
  CANCEL_CLIENTE_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelClienteCadastroRapido() {
  yield put({ type: CANCEL_CLIENTE_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelClienteCadastroRapido() {
  yield takeLatest(CANCEL_CLIENTE_CADASTRO_RAPIDO, sagaCancelClienteCadastroRapido)
}