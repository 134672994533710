export function convertSquareMetersToHectares(areaInSquareMeters) {
    const areaInHectares = (areaInSquareMeters / 10000).toFixed(2);
    return parseFloat(areaInHectares);
}

export function convertSquareMetersToAcres(areaInSquareMeters) {
    const areaInAcres = (areaInSquareMeters / 4046.86).toFixed(2);
    return parseFloat(areaInAcres);
}

export function convertSquareMetersToAlqueires(areaInSquareMeters) {
    const areaInAlqueires = (areaInSquareMeters / 24200).toFixed(2);
    return parseFloat(areaInAlqueires);
}
