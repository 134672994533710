import { takeLatest, put, call } from 'redux-saga/effects';

import { listNdvi } from '../service';
import {
  TABLE_NDVI,
  TABLE_NDVI_PENDING,
  TABLE_NDVI_SUCCESS,
  TABLE_NDVI_FAILURE
} from '../actionTypes';

function* sagaListNdvi(action) {
  yield put({ type: TABLE_NDVI_PENDING });

  try {
    const table = yield call(listNdvi, action.args);
    yield put({ type: TABLE_NDVI_SUCCESS, table: table });
  } catch (error) {
    yield put({ type: TABLE_NDVI_FAILURE });
  }
}

export default function* watchListNdvi() {
  yield takeLatest(TABLE_NDVI, sagaListNdvi);
}