import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllIdentificadorCadastro } from '../service';
import {
  LIST_IDENTIFICADOR_CADASTRO,
  LIST_IDENTIFICADOR_CADASTRO_PENDING,
  LIST_IDENTIFICADOR_CADASTRO_SUCCESS,
  LIST_IDENTIFICADOR_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAllIdentificadorCadastro(action) {
  yield put({ type: LIST_IDENTIFICADOR_CADASTRO_PENDING })

  try {
    const list = yield call(findAllIdentificadorCadastro)
    
    yield put({ type: LIST_IDENTIFICADOR_CADASTRO_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_IDENTIFICADOR_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllIdentificadorCadastro() {
  yield takeLatest(LIST_IDENTIFICADOR_CADASTRO, sagaListAllIdentificadorCadastro)
}