import { takeLatest, put } from 'redux-saga/effects';
import moment from "moment";

import { storage } from '@commons';

import {
  planejamento,
  NEW_PLANEJAMENTO,
  NEW_PLANEJAMENTO_SUCCESS,
  CONFIG_SESSION,
} from '@handler';

function* sagaNewPlanejamento(action) {

  yield put({
    type: NEW_PLANEJAMENTO_SUCCESS, registro: {
      ...planejamento.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      talhao: action.talhao,
      safra: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva,
      replicarLancamento: false,
      talhoesList: []
    }
  });

}

export default function* watchNewPlanejamento() {
  yield takeLatest(NEW_PLANEJAMENTO, sagaNewPlanejamento)
}
