import * as Yup from 'yup';

const newRegistro = {
    name: "",
    email: "",
    passwordTemp: "",
    whatsApp: "",
    perfil: null,
    perfilPermissao: null
};

const phoneRegExp1 = /\(\d{2}\)\s\d\s\d{4}-\d{4}/;

const validation = Yup.object().shape({
    name: Yup.string()
        .matches(
            /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
            'Nome pode conter somente letras'
        )
        .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Informe o Nome e Sobrenome')
        .max(60, 'Campo deve ter menos do que 60 caracteres!')
        .required('Este campo é obrigatório'),
    passwordTemp: Yup.string()
        .min(6, 'O campo deve ter no mínimo 6 caracteres!')
        .max(20, 'Campo deve ter menos do que 20 caracteres!')
        .required('Este campo é obrigatório'),
    whatsApp: Yup.string()
        .matches(phoneRegExp1, "Celular inválido")
        .required('Este campo é obrigatório'),
    email: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres!')
        .max(150, 'Campo deve ter menos do que 150 caracteres!')
        .email('Favor, formato de e-mail inválido!')
        .required('Este campo é obrigatório'),
    perfil: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    perfilPermissao: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
});

const validationMeuPerfil = Yup.object().shape({
    name: Yup.string()
        .matches(
            /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
            'Nome pode conter somente letras'
        )
        .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Informe o Nome e Sobrenome')
        .max(60, 'Campo deve ter menos do que 60 caracteres!')
        .required('Este campo é obrigatório'),
    passwordTemp: Yup.string()
        .min(6, 'O campo deve ter no mínimo 6 caracteres!')
        .max(20, 'Campo deve ter menos do que 20 caracteres!'),
    whatsApp: Yup.string()
        .matches(phoneRegExp1, "Celular inválido")
        .required('Este campo é obrigatório'),
    perfil: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),

});

export const usuario = {
    newRegistro,
    validation,
    validationMeuPerfil
}