import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { storage } from '@commons/storage';

import { createContaBancoCadastro, updateContaBancoCadastro } from '../service';
import {
  CREATE_CONTA_BANCO_CADASTRO,
  CREATE_CONTA_BANCO_CADASTRO_PENDING,
  CREATE_CONTA_BANCO_CADASTRO_SUCCESS,
  CREATE_CONTA_BANCO_CADASTRO_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

function* sagaCreateContaBancoCadastro(action) {
  yield put({ type: CREATE_CONTA_BANCO_CADASTRO_PENDING })

  try {
    if (!action.data.saldoInicialImplantado) {
      action.data.saldoInicial = {
        data: '',
        valor: 0
      }
    }
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createContaBancoCadastro, {
        ...action.data,
        propriedade: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
        }
      });
    } else {
      yield call(updateContaBancoCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_CONTA_BANCO_CADASTRO_SUCCESS, registro: action.data })

    if(!action.isCadastroRapido){
      history.push('/financeiro/contaBancoCadastro');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_CONTA_BANCO_CADASTRO_FAILURE, registro: action.data })

    if (error.response.status === 422) {
      AlertWarning(error.response.data.text);
    } else {
      AlertError('Falha ao salvar o registro! Tente novamente...');
    }
  }

}

export default function* watchCreateContaBancoCadastro() {
  yield takeLatest(CREATE_CONTA_BANCO_CADASTRO, sagaCreateContaBancoCadastro)
}
