import { takeLatest, put, call } from 'redux-saga/effects';

import { findAnimalCategoriaCadastro } from '../service';
import {
  FIND_ANIMAL_CATEGORIA_CADASTRO,
  FIND_ANIMAL_CATEGORIA_CADASTRO_PENDING,
  FIND_ANIMAL_CATEGORIA_CADASTRO_SUCCESS,
  FIND_ANIMAL_CATEGORIA_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindAnimalCategoriaCadastro(action) {
  yield put({ type: FIND_ANIMAL_CATEGORIA_CADASTRO_PENDING, id: action.id })

  try {
    const registro = yield call(findAnimalCategoriaCadastro, action.id);
    yield put({ type: FIND_ANIMAL_CATEGORIA_CADASTRO_SUCCESS, registro: registro })
  } catch (error) {
    yield put({ type: FIND_ANIMAL_CATEGORIA_CADASTRO_FAILURE })
  }
}

export default function* watchFindAnimalCategoriaCadastro() {
  yield takeLatest(FIND_ANIMAL_CATEGORIA_CADASTRO, sagaFindAnimalCategoriaCadastro)
}
