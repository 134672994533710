export const INDICADOR_IE_ENUM = [
    {
        id: 'CONTRIBUINTEICMS',
        descricao: "Contribuinte ICMS"
    },
    {
        id: 'CONTRIBUINTEISENTO',
        descricao: "Contribuinte Isento"
    },
    {
        id: 'NAOCONTRIBUINTE',
        descricao: "Não Contribuinte"
    },
];