import { takeLatest, put } from 'redux-saga/effects';

import {
  CANCEL_ESTAGIO_APLICACAO_CADASTRO_RAPIDO,
  CANCEL_ESTAGIO_APLICACAO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelEstagioAplicacaoCadastroRapido() {
  yield put({ type: CANCEL_ESTAGIO_APLICACAO_CADASTRO_RAPIDO_SUCCESS});
}

export default function* watchCancelEstagioAplicacaoCadastroRapido() {
  yield takeLatest(CANCEL_ESTAGIO_APLICACAO_CADASTRO_RAPIDO, sagaCancelEstagioAplicacaoCadastroRapido);
}