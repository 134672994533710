import { takeLatest, put, call } from 'redux-saga/effects';
import moment from "moment";
import { history } from '@commons';

import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  manejoPlanejamentoCadastro,
  NEW_MANEJO_PLANEJAMENTO_CADASTRO,
  NEW_MANEJO_PLANEJAMENTO_CADASTRO_PENDING,
  NEW_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS,
  NEW_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE
} from '@handler';

import { AlertError } from '@components/common/AlertToast'


function* sagaNewManejoPlanejamentoCadastro(action) {
  yield put({ type: NEW_MANEJO_PLANEJAMENTO_CADASTRO_PENDING });

  try {
    yield put({
      type: NEW_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS, registro: {
        ...manejoPlanejamentoCadastro.newRegistro,
        data: moment().startOf('day').format('YYYY-MM-DD'),
        anoPecuario: JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo,
        anoPecuarioTipoControle: JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo.tipoControle,
        
      },
      isCadastroRapido: action.isCadastroRapido, 
    });
  } catch (error) {
    yield put({ type: NEW_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE })

    AlertError('Falha ao gerar novo cadastro! Tente novamente...');

    history.push('pecuaria/manejo/planejamento');
  }
}

export default function* watchNewManejoPlanejamentoCadastro() {
  yield takeLatest(NEW_MANEJO_PLANEJAMENTO_CADASTRO, sagaNewManejoPlanejamentoCadastro)
}
