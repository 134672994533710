import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { storage } from '@commons/storage';

import { createTransferenciaContaBancoCaixa, updateTransferenciaContaBancoCaixa } from '../service';
import {
  CREATE_TRANSF_CONTA_BANCO_CAIXA,
  CREATE_TRANSF_CONTA_BANCO_CAIXA_PENDING,
  CREATE_TRANSF_CONTA_BANCO_CAIXA_SUCCESS,
  CREATE_TRANSF_CONTA_BANCO_CAIXA_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaCreateTransferenciaContaBancoCaixa(action) {
  yield put({ type: CREATE_TRANSF_CONTA_BANCO_CAIXA_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createTransferenciaContaBancoCaixa, formatJsonRemoveEmptyOrNull(
        {
          ...action.data,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          }
        }
      ));
    } else {
      yield call(updateTransferenciaContaBancoCaixa,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_TRANSF_CONTA_BANCO_CAIXA_SUCCESS, registro: action.data })

    history.push('/financeiro/transferenciaContaBancoCaixa');

    AlertSuccess('Registro salvo com sucesso!');
  } catch (error) {
    yield put({ type: CREATE_TRANSF_CONTA_BANCO_CAIXA_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

export default function* watchCreateTransferenciaContaBancoCaixa() {
  yield takeLatest(CREATE_TRANSF_CONTA_BANCO_CAIXA, sagaCreateTransferenciaContaBancoCaixa)
}
