import { takeLatest, put } from 'redux-saga/effects';

import {
  clienteCadastro,
  NEW_CLIENTE_CADASTRO,
  NEW_CLIENTE_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewClienteCadastro(action) {
  yield put({
    type: NEW_CLIENTE_CADASTRO_SUCCESS, registro: {
      ...clienteCadastro.newRegistro,
      endereco: {
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        estado: null,
        cidade: null,
      },
      cliente: {
        cliente: true
      },
      fornecedor: {
        fornecedor: false
      },
      transportadora: {
          transportadora: false
      },
    }, 
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewClienteCadastro() {
  yield takeLatest(NEW_CLIENTE_CADASTRO, sagaNewClienteCadastro)
}
