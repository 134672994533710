import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_IDENTIFICADOR_CADASTRO_RAPIDO,
    CANCEL_IDENTIFICADOR_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelIdentificadorCadastroRapido() {
  yield put({ type: CANCEL_IDENTIFICADOR_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelIdentificadorCadastroRapido() {
  yield takeLatest(CANCEL_IDENTIFICADOR_CADASTRO_RAPIDO, sagaCancelIdentificadorCadastroRapido)
}