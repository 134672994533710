import * as Yup from 'yup';

const newRegistroVincularFornecedor = { 
    codigoProdutoFornecedor: '', 
    possuiConversaoCompra: false, 
    tipoEmbalagem: null, 
    qtdeEmbalagem: null
};

const validationVincularFornecedor = Yup.object().shape({
    codigoProdutoFornecedor: Yup.string()
      .max(50, 'Campo deve ter menos do que 50 caracteres!'),
    tipoEmbalagem: Yup.object().when('possuiConversaoCompra', {
      is: (possuiConversaoCompra) => possuiConversaoCompra === true,
      then: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
          id: Yup.number()
            .typeError('Este campo é obrigatório')
            .required("Este campo é obrigatório"),
        })
    }).nullable(),
    qtdeEmbalagem: Yup.number().when('possuiConversaoCompra', {
      is: (possuiConversaoCompra) => possuiConversaoCompra === true,
      then: Yup.number()
        .typeError('Este campo é obrigatório')
        .positive('Por Favor, informe um valor maior que zero')
        .required("Este campo é obrigatório")
    }).nullable(),
  });

export const produtoInsumoVincularFornecedor = {
    newRegistroVincularFornecedor,
    validationVincularFornecedor
}