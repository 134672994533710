import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/pecuaria/animal' };
/////////////////////////////////////////////////////////////////////////////////

export function createAnimalCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateAnimalCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listAnimalCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findAnimalCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deleteAnimalCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function getEstoqueAtualPorCategoriaLote(propriedade, anoPecuario, categoria, lote) {
  return httpService.get({ ...args, args: '/estoqueAtualPorCategoriaLote/' + propriedade + '/' + anoPecuario + '/' + categoria + '/' + lote });
}

export function getRelacaoAnimaisAtivoAgrupadoPorCategoriaLote(propriedade, anoPecuario) {
  return httpService.get({ ...args, args: '/relacaoAnimaisAtivoAgrupadoPorCategoriaLote/' + propriedade + '/' + anoPecuario });
}