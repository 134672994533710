import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { storage } from '@commons/storage';

import { createContaChequePreDatadoCadastro, updateContaChequePreDatadoCadastro } from '../service';
import {
  CREATE_CONTA_CHEQUE_PRE_DATADO_CADASTRO,
  CREATE_CONTA_CHEQUE_PRE_DATADO_CADASTRO_PENDING,
  CREATE_CONTA_CHEQUE_PRE_DATADO_CADASTRO_SUCCESS,
  CREATE_CONTA_CHEQUE_PRE_DATADO_CADASTRO_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

function* sagaCreateContaChequePreDatadoCadastro(action) {
  yield put({ type: CREATE_CONTA_CHEQUE_PRE_DATADO_CADASTRO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createContaChequePreDatadoCadastro, {
        ...action.data,
        propriedade: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
        }
      });
    } else {
      yield call(updateContaChequePreDatadoCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_CONTA_CHEQUE_PRE_DATADO_CADASTRO_SUCCESS, registro: action.data })

    history.push('/financeiro/contaChequePreDatadoCadastro');

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_CONTA_CHEQUE_PRE_DATADO_CADASTRO_FAILURE, registro: action.data })

    if (error.response.status === 422) {
      AlertWarning(error.response.data.text);
    } else {
      AlertError('Falha ao salvar o registro! Tente novamente...');
    }
  }

}

export default function* watchCreateContaChequePreDatadoCadastro() {
  yield takeLatest(CREATE_CONTA_CHEQUE_PRE_DATADO_CADASTRO, sagaCreateContaChequePreDatadoCadastro)
}
