import { takeLatest, put, call } from 'redux-saga/effects';

import { findBaixaManualEstoqueProducao } from '../service';
import {
  FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO,
  FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING,
  FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS,
  FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE
} from '../actionTypes';

function* sagaFindBaixaManualEstoqueProducao(action) {
  yield put({ type: FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PENDING, id: action.id })

  try {

    const registro = yield call(findBaixaManualEstoqueProducao, action.id);

    yield put({ type: FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindBaixaManualEstoqueProducao() {
  yield takeLatest(FIND_BAIXAR_MANUAL_ESTOQUE_PRODUCAO, sagaFindBaixaManualEstoqueProducao)
}
