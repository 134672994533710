import { takeLatest, put, call } from 'redux-saga/effects';

import { findImplantacaoEstoqueProducao } from '../service';
import {
  FIND_IMPLANTACAO_ESTOQUE_PRODUCAO,
  FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING,
  FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS,
  FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE
} from '../actionTypes';

function* sagaFindImplantacaoEstoqueProducao(action) {
  yield put({ type: FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING, id: action.id })

  try {

    const registro = yield call(findImplantacaoEstoqueProducao, action.id);

    yield put({ type: FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindImplantacaoEstoqueProducao() {
  yield takeLatest(FIND_IMPLANTACAO_ESTOQUE_PRODUCAO, sagaFindImplantacaoEstoqueProducao)
}
