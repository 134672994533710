import { takeLatest, put, call } from 'redux-saga/effects';

import {
  getEstoqueProducaoPorPeriodoAnalitico, getEstoqueProducaoPorPeriodoSintetico,
  getEstoqueProducaoPorPeriodoAnaliticoPdf, getEstoqueProducaoPorPeriodoSinteticoPdf
} from '../service';
import {
  LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO,
  LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_PENDING,
  LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_SUCCESS,
  LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_FAILURE,
  LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_PDF_SUCCESS
} from '../actionTypes';
import { storage } from '@commons';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { formatDateDDMMYYYY } from '@components/common/format';

function* sagaListAllRelatorioEstoqueProducaoPorPeriodo(action) {
  yield put({ type: LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_PENDING })

  const datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);

  try {
    if (action.pdf === false) {

      if (action.args.tipoRelatorio === 'ANALITICO') {
        const estoqueProducaoPorPeriodo = yield call(getEstoqueProducaoPorPeriodoAnalitico, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id, datas, action.args)
        yield put({ type: LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_SUCCESS, estoqueProducaoPorPeriodo: estoqueProducaoPorPeriodo, tipoRelatorio: action.args.tipoRelatorio });
      } else {
        const estoqueProducaoPorPeriodo = yield call(getEstoqueProducaoPorPeriodoSintetico, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id, datas, action.args)
        yield put({ type: LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_SUCCESS, estoqueProducaoPorPeriodo: estoqueProducaoPorPeriodo, tipoRelatorio: action.args.tipoRelatorio });
      }

    } else {

      if (action.args.tipoRelatorio === 'ANALITICO') {
        yield call(getEstoqueProducaoPorPeriodoAnaliticoPdf, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id, datas, action.args)
      } else {
        yield call(getEstoqueProducaoPorPeriodoSinteticoPdf, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id, datas, action.args)
      }

      yield put({ type: LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_PDF_SUCCESS })
    }
  } catch (error) {
    yield put({ type: LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllRelatorioEstoqueProducaoPorPeriodo() {
  yield takeLatest(LIST_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO, sagaListAllRelatorioEstoqueProducaoPorPeriodo)
}