
export const NEW_PLANO_CONTA_CADASTRO = 'NEW_PLANO_CONTA_CADASTRO'
export const NEW_PLANO_CONTA_CADASTRO_SUCCESS = 'NEW_PLANO_CONTA_CADASTRO_SUCCESS'

export const CREATE_PLANO_CONTA_CADASTRO = 'CREATE_PLANO_CONTA_CADASTRO'
export const CREATE_PLANO_CONTA_CADASTRO_PENDING = 'CREATE_PLANO_CONTA_CADASTRO_PENDING'
export const CREATE_PLANO_CONTA_CADASTRO_SUCCESS = 'CREATE_PLANO_CONTA_CADASTRO_SUCCESS'
export const CREATE_PLANO_CONTA_CADASTRO_FAILURE = 'CREATE_PLANO_CONTA_CADASTRO_FAILURE'

export const CANCEL_PLANO_CONTA_CADASTRO_RAPIDO = 'CANCEL_PLANO_CONTA_CADASTRO_RAPIDO'
export const CANCEL_PLANO_CONTA_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_PLANO_CONTA_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_PLANO_CONTA_CADASTRO = 'TABLE_PLANO_CONTA_CADASTRO'
export const TABLE_PLANO_CONTA_CADASTRO_PENDING = 'TABLE_PLANO_CONTA_CADASTRO_PENDING'
export const TABLE_PLANO_CONTA_CADASTRO_SUCCESS = 'TABLE_PLANO_CONTA_CADASTRO_SUCCESS'
export const TABLE_PLANO_CONTA_CADASTRO_FAILURE = 'TABLE_PLANO_CONTA_CADASTRO_FAILURE'

export const LIST_PLANO_CONTA_CADASTRO = 'LIST_PLANO_CONTA_CADASTRO'
export const LIST_PLANO_CONTA_CADASTRO_PENDING = 'LIST_PLANO_CONTA_CADASTRO_PENDING'
export const LIST_PLANO_CONTA_CADASTRO_SUCCESS = 'LIST_PLANO_CONTA_CADASTRO_SUCCESS'
export const LIST_PLANO_CONTA_CADASTRO_FAILURE = 'LIST_PLANO_CONTA_CADASTRO_FAILURE'

export const FIND_PLANO_CONTA_CADASTRO = 'FIND_PLANO_CONTA_CADASTRO'
export const FIND_PLANO_CONTA_CADASTRO_PENDING = 'FIND_PLANO_CONTA_CADASTRO_PENDING'
export const FIND_PLANO_CONTA_CADASTRO_SUCCESS = 'FIND_PLANO_CONTA_CADASTRO_SUCCESS'
export const FIND_PLANO_CONTA_CADASTRO_FAILURE = 'FIND_PLANO_CONTA_CADASTRO_FAILURE'

export const DELETE_PLANO_CONTA_CADASTRO = 'DELETE_PLANO_CONTA_CADASTRO'
export const DELETE_PLANO_CONTA_CADASTRO_PENDING = 'DELETE_PLANO_CONTA_CADASTRO_PENDING'
export const DELETE_PLANO_CONTA_CADASTRO_SUCCESS = 'DELETE_PLANO_CONTA_CADASTRO_SUCCESS'
export const DELETE_PLANO_CONTA_CADASTRO_FAILURE = 'DELETE_PLANO_CONTA_CADASTRO_FAILURE'
