import { takeLatest, put, call } from 'redux-saga/effects';
import { storage, path } from '@commons';

import {
  NEW_FILTER_CONTA_AGRARE,
  NEW_FILTER_CONTA_AGRARE_PEDING,
  NEW_FILTER_CONTA_AGRARE_SUCCESS,
  NEW_FILTER_CONTA_AGRARE_FAILURE,
} from '@handler';
import { CONST_FILTER_CONTA_AGRARE, CONST_ROWS_PER_PAGE } from '@commons/consts';
import { listContaAgrare } from './listTable';

function* sagaNewFilterContaAgrare(action) {
  try {
    yield put({ type: NEW_FILTER_CONTA_AGRARE_PEDING })

    let user = JSON.parse(storage.get(path.sessionUser));

    let where = `?clienteWhiteLabel_identificationCode=${user.identificationCodeWhiteLabel}${user.idPartnerWhiteLabel == null ? '' : `&partnerWhiteLabel=${user.idPartnerWhiteLabel}`}`;
    let newFilter = {
      nomeConta: '',
      nomeResponsavelConta: '',
      clienteAgrareGrupo: null,
      status: "S",
      rows: CONST_ROWS_PER_PAGE,
      page: 0,
      where: where
    };

    let filterSaved = sessionStorage.getItem(CONST_FILTER_CONTA_AGRARE) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_CONTA_AGRARE)) : null;

    if (filterSaved === null || filterSaved === undefined) {
      where += '&status=true';

      newFilter = {
        ...newFilter,
        where: where
      };
    } else {
      // se seta filtro manual
      if (action.newFilter) {
        where += `&status=${action.args.status === 'S' ? true : false}`;

        if (action.args.nomeConta !== null && action.args.nomeConta !== undefined && action.args.nomeConta !== '') {
          where = where + '&nomeConta=' + action.args.nomeConta;
        }
        if (action.args.nomeResponsavelConta !== null && action.args.nomeResponsavelConta !== undefined && action.args.nomeResponsavelConta !== '') {
          where = where + '&nomeResponsavelConta=' + action.args.nomeResponsavelConta;
        }
        if (action.args.clienteAgrareGrupo !== null && action.args.clienteAgrareGrupo !== undefined && action.args.clienteAgrareGrupo !== '') {
          where = where + '&clienteAgrareGrupo=' + action.args.clienteAgrareGrupo.id;
        }

        newFilter = {
          ...newFilter,
          ...action.args,
          where: where
        };
      } else {
        // busca filtro salvo
        where += `&status=${filterSaved.status === 'S' ? true : false}`;

        if (filterSaved.nomeConta !== null && filterSaved.nomeConta !== undefined && filterSaved.nomeConta !== '') {
          where = where + '&nomeConta=' + filterSaved.nomeConta;
        }
        if (filterSaved.nomeResponsavelConta !== null && filterSaved.nomeResponsavelConta !== undefined && filterSaved.nomeResponsavelConta !== '') {
          where = where + '&nomeResponsavelConta=' + filterSaved.nomeResponsavelConta;
        }
        if (filterSaved.clienteAgrareGrupo !== null && filterSaved.clienteAgrareGrupo !== undefined && filterSaved.clienteAgrareGrupo !== '') {
          where = where + '&clienteAgrareGrupo=' + filterSaved.clienteAgrareGrupo.id;
        }

        newFilter = {
          ...filterSaved,
          rows: CONST_ROWS_PER_PAGE,
          page: 0,
          where: where
        };
      }
    }

    let list = yield call(listContaAgrare, user.id, `${where}&page=0&size=${CONST_ROWS_PER_PAGE}`);
    sessionStorage.setItem(CONST_FILTER_CONTA_AGRARE, JSON.stringify(newFilter));

    yield put({
      type: NEW_FILTER_CONTA_AGRARE_SUCCESS, list: list, filter: newFilter
    });

  } catch (e) {
    yield put({ type: NEW_FILTER_CONTA_AGRARE_FAILURE })
  }
}

export default function* watchNewFilterContaAgrare() {
  yield takeLatest(NEW_FILTER_CONTA_AGRARE, sagaNewFilterContaAgrare)
}
