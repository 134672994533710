import watchNewManejoExecucaoCadastro from './saga/new';
import watchNewPlantioManejoByPlanejamento from './saga/newByPlanejamento';
import watchFindManejoExecucaoCadastro from './saga/find';
import watchCreateManejoExecucaoCadastro from './saga/create';
import watchListManejoExecucaoCadastro from './saga/listTable';
import watchDeleteManejoExecucaoCadastro from './saga/delete';
import watchCancelManejoExecucaoCadastroRapido from './saga/cancel';

export const manejoExecucaoCadastroSaga = [
    watchNewManejoExecucaoCadastro(),
    watchNewPlantioManejoByPlanejamento(),
    watchFindManejoExecucaoCadastro(),
    watchCreateManejoExecucaoCadastro(),
    watchListManejoExecucaoCadastro(),
    watchDeleteManejoExecucaoCadastro(),
    watchCancelManejoExecucaoCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
