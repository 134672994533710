import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { ativarUsuario, desativarUsuario, trocarPermissao } from '../service';
import {
  UPDATE_PERFIL_PERMISSAO_USUARIO,
  UPDATE_PERFIL_PERMISSAO_USUARIO_PENDING,
  UPDATE_PERFIL_PERMISSAO_USUARIO_SUCCESS,
  UPDATE_PERFIL_PERMISSAO_USUARIO_FAILURE
} from '../actionTypes';
import { AlertSuccess, AlertWarning } from '@components/common/AlertToast';

function* sagaUpdatePerfilPermissaoUsuario(action) {
  yield put({ type: UPDATE_PERFIL_PERMISSAO_USUARIO_PENDING })

  try {
    yield call(trocarPermissao, action.idUsuario, action.idNovoPerfilPermissao);

    AlertSuccess('Perfil de acesso atualizado com sucesso...');

    history.push('/seguranca/usuario');

    yield put({ type: UPDATE_PERFIL_PERMISSAO_USUARIO_SUCCESS })
  } catch (error) {
    yield put({ type: UPDATE_PERFIL_PERMISSAO_USUARIO_FAILURE });

    AlertWarning('Falha ao trocar perfil de acesso do usuário. Tente novamente...');
  }
}

export default function* watchUpdatePerfilPermissaoUsuario() {
  yield takeLatest(UPDATE_PERFIL_PERMISSAO_USUARIO, sagaUpdatePerfilPermissaoUsuario)
}
