import { takeLatest, put, call } from 'redux-saga/effects';

import { listEvolucaoAnimal } from '../service';
import {
  TABLE_EVOLUCAO_ANIMAL,
  TABLE_EVOLUCAO_ANIMAL_PENDING,
  TABLE_EVOLUCAO_ANIMAL_SUCCESS,
  TABLE_EVOLUCAO_ANIMAL_FAILURE
} from '../actionTypes';

function* sagaListEvolucaoAnimal(action) {
  yield put({ type: TABLE_EVOLUCAO_ANIMAL_PENDING })

  try {
    const table = yield call(listEvolucaoAnimal, action.args)
    yield put({ type: TABLE_EVOLUCAO_ANIMAL_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_EVOLUCAO_ANIMAL_FAILURE })
  }
}

export default function* watchListEvolucaoAnimal() {
  yield takeLatest(TABLE_EVOLUCAO_ANIMAL, sagaListEvolucaoAnimal)
}