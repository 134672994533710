
export const NEW_PROPRIEDADE_CADASTRO = 'NEW_PROPRIEDADE_CADASTRO'
export const NEW_PROPRIEDADE_CADASTRO_SUCCESS = 'NEW_PROPRIEDADE_CADASTRO_SUCCESS'

export const CREATE_PROPRIEDADE_CADASTRO = 'CREATE_PROPRIEDADE_CADASTRO'
export const CREATE_PROPRIEDADE_CADASTRO_PENDING = 'CREATE_PROPRIEDADE_CADASTRO_PENDING'
export const CREATE_PROPRIEDADE_CADASTRO_SUCCESS = 'CREATE_PROPRIEDADE_CADASTRO_SUCCESS'
export const CREATE_PROPRIEDADE_CADASTRO_FAILURE = 'CREATE_PROPRIEDADE_CADASTRO_FAILURE'

export const TABLE_PROPRIEDADE_CADASTRO = 'TABLE_PROPRIEDADE_CADASTRO'
export const TABLE_PROPRIEDADE_CADASTRO_PENDING = 'TABLE_PROPRIEDADE_CADASTRO_PENDING'
export const TABLE_PROPRIEDADE_CADASTRO_SUCCESS = 'TABLE_PROPRIEDADE_CADASTRO_SUCCESS'
export const TABLE_PROPRIEDADE_CADASTRO_FAILURE = 'TABLE_PROPRIEDADE_CADASTRO_FAILURE'

export const LIST_PROPRIEDADE_CADASTRO = 'LIST_PROPRIEDADE_CADASTRO'
export const LIST_PROPRIEDADE_CADASTRO_PENDING = 'LIST_PROPRIEDADE_CADASTRO_PENDING'
export const LIST_PROPRIEDADE_CADASTRO_SUCCESS = 'LIST_PROPRIEDADE_CADASTRO_SUCCESS'
export const LIST_PROPRIEDADE_CADASTRO_FAILURE = 'LIST_PROPRIEDADE_CADASTRO_FAILURE'

export const FIND_PROPRIEDADE_CADASTRO = 'FIND_PROPRIEDADE_CADASTRO'
export const FIND_PROPRIEDADE_CADASTRO_PENDING = 'FIND_PROPRIEDADE_CADASTRO_PENDING'
export const FIND_PROPRIEDADE_CADASTRO_SUCCESS = 'FIND_PROPRIEDADE_CADASTRO_SUCCESS'
export const FIND_PROPRIEDADE_CADASTRO_FAILURE = 'FIND_PROPRIEDADE_CADASTRO_FAILURE'

export const DELETE_PROPRIEDADE_CADASTRO = 'DELETE_PROPRIEDADE_CADASTRO'
export const DELETE_PROPRIEDADE_CADASTRO_PENDING = 'DELETE_PROPRIEDADE_CADASTRO_PENDING'
export const DELETE_PROPRIEDADE_CADASTRO_SUCCESS = 'DELETE_PROPRIEDADE_CADASTRO_SUCCESS'
export const DELETE_PROPRIEDADE_CADASTRO_FAILURE = 'DELETE_PROPRIEDADE_CADASTRO_FAILURE'
