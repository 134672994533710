import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_TIPO_EMBALAGEM_CADASTRO_RAPIDO,
    CANCEL_TIPO_EMBALAGEM_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelTipoEmbalagemCadastroRapido() {
  yield put({ type: CANCEL_TIPO_EMBALAGEM_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelTipoEmbalagemCadastroRapido() {
  yield takeLatest(CANCEL_TIPO_EMBALAGEM_CADASTRO_RAPIDO, sagaCancelTipoEmbalagemCadastroRapido)
}