import { takeLatest, put } from 'redux-saga/effects';

import {
  propriedadeCadastro,
  NEW_PROPRIEDADE_CADASTRO,
  NEW_PROPRIEDADE_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewPropriedadeCadastro(action) {

  yield put({ type: NEW_PROPRIEDADE_CADASTRO_SUCCESS, registro: propriedadeCadastro.newRegistro });
}

export default function* watchNewPropriedadeCadastro() {
  yield takeLatest(NEW_PROPRIEDADE_CADASTRO, sagaNewPropriedadeCadastro)
}
