import { takeLatest, put, call } from 'redux-saga/effects';

import { findContaCaixaCadastro } from '../service';
import {
  FIND_CONTA_CAIXA_CADASTRO,
  FIND_CONTA_CAIXA_CADASTRO_PENDING,
  FIND_CONTA_CAIXA_CADASTRO_SUCCESS,
  FIND_CONTA_CAIXA_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindContaCaixaCadastro(action) {
  yield put({ type: FIND_CONTA_CAIXA_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findContaCaixaCadastro, action.id);

    if (!registro.saldoInicialImplantado){
      registro.saldoInicial = {
        data: '',
        valor: 0
      }
    }

    yield put({ type: FIND_CONTA_CAIXA_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_CONTA_CAIXA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindContaCaixaCadastro() {
  yield takeLatest(FIND_CONTA_CAIXA_CADASTRO, sagaFindContaCaixaCadastro)
}
