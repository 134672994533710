import { takeLatest, put, call } from 'redux-saga/effects';

import { path, history, storage } from '@commons';

import { createTipoEmbalagemWhiteLabel, updateTipoEmbalagemWhiteLabel } from '../service';
import {
  CREATE_TIPO_EMBALAGEM_WHITE_LABEL,
  CREATE_TIPO_EMBALAGEM_WHITE_LABEL_PENDING,
  CREATE_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS,
  CREATE_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
function* sagaCreateTipoEmbalagemWhiteLabel(action) {
  yield put({ type: CREATE_TIPO_EMBALAGEM_WHITE_LABEL_PENDING })

  try {
    let user = JSON.parse(storage.get(path.sessionUser));

    var newTipoEmbalagem = {
      ...action.data,
      "identificationCodeWhiteLabel": user.identificationCodeWhiteLabel,
    }

    if (action.data.id === undefined || action.data.id === null) {
      yield call(createTipoEmbalagemWhiteLabel, newTipoEmbalagem);
    } else {
      yield call(updateTipoEmbalagemWhiteLabel,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/corporate/tipoEmbalagem');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateTipoEmbalagemWhiteLabel() {
  yield takeLatest(CREATE_TIPO_EMBALAGEM_WHITE_LABEL, sagaCreateTipoEmbalagemWhiteLabel)
}
