import { takeLatest, put, call } from 'redux-saga/effects';

import { findEstagioAplicacaoCadastro } from '../service';
import {
  FIND_ESTAGIO_APLICACAO_CADASTRO,
  FIND_ESTAGIO_APLICACAO_CADASTRO_PENDING,
  FIND_ESTAGIO_APLICACAO_CADASTRO_SUCCESS,
  FIND_ESTAGIO_APLICACAO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindEstagioAplicacaoCadastro(action) {
  yield put({ type: FIND_ESTAGIO_APLICACAO_CADASTRO_PENDING, id: action.id });

  try {
    const registro = yield call(findEstagioAplicacaoCadastro, action.id);

    yield put({ type: FIND_ESTAGIO_APLICACAO_CADASTRO_SUCCESS, registro });
  } catch (error) {
    yield put({ type: FIND_ESTAGIO_APLICACAO_CADASTRO_FAILURE });
  }
}

export default function* watchFindEstagioAplicacaoCadastro() {
  yield takeLatest(FIND_ESTAGIO_APLICACAO_CADASTRO, sagaFindEstagioAplicacaoCadastro);
}
