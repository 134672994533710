import { takeLatest, put } from 'redux-saga/effects';

import {
  transportadoraCadastro,
  NEW_TRANSPORTADORA_CADASTRO,
  NEW_TRANSPORTADORA_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewTransportadoraCadastro(action) {

  yield put({
    type: NEW_TRANSPORTADORA_CADASTRO_SUCCESS, registro: {
      ...transportadoraCadastro.newRegistro,
      endereco: {
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        estado: null,
        cidade: null,
      },
      cliente: {
        cliente: false
      },
      fornecedor: {
        fornecedor: false
      },
      transportadora: {
          transportadora: true
      },
    },
    isCadastroRapido: action.isCadastroRapido, 
  });
}

export default function* watchNewTransportadoraCadastro() {
  yield takeLatest(NEW_TRANSPORTADORA_CADASTRO, sagaNewTransportadoraCadastro)
}
