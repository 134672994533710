import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/safra' };
/////////////////////////////////////////////////////////////////////////////////

export function createSafraCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateSafraCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listSafraCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findSafraCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllSafrasByPropriedade(propriedade) {
  return httpService.get({ ...args, path: '/safra?size=20000&page=0&status=true&propriedade=' + propriedade });
}

export function deleteSafraCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function getTotalAreaAtivaNaSafra(idSafra) {
  return httpService.get({ ...args, path: '/safra/totalAreaAtiva/' + idSafra });
}
