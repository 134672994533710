import { takeLatest, put } from 'redux-saga/effects';
import moment from "moment";

import { storage } from '@commons';

import {
  plantioManejo,
  NEW_PLANTIO_MANEJO,
  NEW_PLANTIO_MANEJO_SUCCESS,
  CONFIG_SESSION,
} from '@handler';

function* sagaNewPlantioManejo(action) {

  yield put({
    type: NEW_PLANTIO_MANEJO_SUCCESS, registro: {
      ...plantioManejo.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      talhao: action.talhao,
      safra: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva,
      replicarLancamento: false,
      talhoesList: []
    }
  });

}

export default function* watchNewPlantioManejo() {
  yield takeLatest(NEW_PLANTIO_MANEJO, sagaNewPlantioManejo)
}
