import { takeLatest, put, call } from 'redux-saga/effects';

import { listManejoExecucaoCadastro } from '../service';
import {
    TABLE_MANEJO_EXECUCAO_CADASTRO,
    TABLE_MANEJO_EXECUCAO_CADASTRO_PENDING,
    TABLE_MANEJO_EXECUCAO_CADASTRO_SUCCESS,
    TABLE_MANEJO_EXECUCAO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListManejoExecucaoCadastro(action) {
  yield put({ type: TABLE_MANEJO_EXECUCAO_CADASTRO_PENDING })

  try {
    const table = yield call(listManejoExecucaoCadastro, action.args)

    yield put({ type: TABLE_MANEJO_EXECUCAO_CADASTRO_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_MANEJO_EXECUCAO_CADASTRO_FAILURE })
  }
}

export default function* watchListManejoExecucaoCadastro() {
  yield takeLatest(TABLE_MANEJO_EXECUCAO_CADASTRO, sagaListManejoExecucaoCadastro)
}