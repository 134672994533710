import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  contaPagarRecorrente,
  NEW_CONTA_PAGAR_RECORRENTE,
  NEW_CONTA_PAGAR_RECORRENTE_SUCCESS,
} from '@handler';

function* sagaNewContaPagarRecorrente(action) {

  yield put({ type: NEW_CONTA_PAGAR_RECORRENTE_SUCCESS, registro: contaPagarRecorrente.newRegistro});
}

export default function* watchNewContaPagarRecorrente() {
  yield takeLatest(NEW_CONTA_PAGAR_RECORRENTE, sagaNewContaPagarRecorrente)
}
