import { path, httpService } from '@commons';
import { httpServiceApiDfe } from '@commons/http.service.api.dfe'; 
import { pathApiDfe } from '@commons/path.api.dfe';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/fiscal/emitente' };
const argsApiDfe = { ...pathApiDfe };
/////////////////////////////////////////////////////////////////////////////////

export function createEmitenteCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateEmitenteCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listEmitenteCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findEmitenteCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllEmitenteCadastro() {
  return httpService.get({ ...args, path: '/fiscal/emitente/all' });
}

export function deleteEmitenteCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function findCnpj(cnpj) {
  return httpService.get({ ...args, args: `/find/dataByCnpj/${cnpj}` });
}

export function findCep(cep) {
  return httpService.get({ ...args, args: `/find/addressByCep/${cep}` });
}

export function validarCertificado(certificado, senha) {
  var bodyFormData = new FormData();
  bodyFormData.append('certificado', certificado);
  
  return httpServiceApiDfe.postFile({ ...argsApiDfe, pathApiDfe: 'empresa/validarCertificado/' + senha }, bodyFormData);
}

export function getNroCpfCnpjMonitoradosNFeCompra() {
  return httpService.get({ ...args, args: `/nroCpfCnpjMonitoradosNFeCompra` });
}