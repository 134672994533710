import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  NEW_FILTER_FLUXO_CAIXA,
  NEW_FILTER_FLUXO_CAIXA_PENDING,
  NEW_FILTER_FLUXO_CAIXA_SUCCESS,
  NEW_FILTER_FLUXO_CAIXA_SUCCESS_PDF,
  NEW_FILTER_FLUXO_CAIXA_FAILURE,
  NEW_FILTER_GRAFICO_FLUXO_CAIXA_PENDING,
  NEW_FILTER_GRAFICO_FLUXO_CAIXA_SUCCESS,
  NEW_FILTER_GRAFICO_FLUXO_CAIXA_FAILURE,
  getRelatorioFluxoCaixa,
  getGraficoFluxoCaixa,
} from '@handler';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';
import { CONST_FILTER_FLUXO_CAIXA, CONST_ROWS_PER_PAGE } from '@commons/consts';

function* sagaNewFilterFluxoCaixa(action) {
  try {
    yield put({ type: NEW_FILTER_FLUXO_CAIXA_PENDING })
    yield put({ type: NEW_FILTER_GRAFICO_FLUXO_CAIXA_PENDING })

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    let datas = '';
    let dataInicial = '';
    let dataFinal = '';
    var date = new Date();
    let where = '';
    let newFilter = {
      tipoRelatorio: 'FLUXO_CAIXA_REALIZADO',
      tipoAnaliseRelatorio: 'FLUXO_CAIXA_ANALISE_PERSONALIZADA',
      dataInicioPeriodoSelecionado: '',
      periodoSemanaFormatado: '',
      tipoContaBancoCaixa: 'TODAS_CONTAS',
      contaBancoCaixa: null,
      nivelDetalhamento: 'TODAS_CONTAS',
      dataInicial: '',
      dataFinal: '',
      rows: CONST_ROWS_PER_PAGE,
      page: 0,
      where: null
    };

    let filterSaved = sessionStorage.getItem(CONST_FILTER_FLUXO_CAIXA) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_FLUXO_CAIXA)) : null;

    if (filterSaved === null || filterSaved === undefined) {
      // seta data do mes na primeira vez que entra na pagina
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 12, 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);

      where = {
        "tipoRelatorio": newFilter.tipoRelatorio,
        "tipoAnaliseRelatorio": newFilter.tipoAnaliseRelatorio,
        "dataInicial": dataInicial,
        "dataFinal": dataFinal,
        "tipoContaBancoCaixa": newFilter.tipoContaBancoCaixa,
        "contaBancoCaixa": null,
        "nivelDetalhamento": newFilter.nivelDetalhamento
      }

      newFilter = {
        ...newFilter,
        dataInicial,
        dataFinal,
        where: where
      };
    } else {
      // se seta filtro manual
      if (action.newFilter) {
        // seta filtro indicado
        // typePeriodo = 0 -> periodo manual
        // typePeriodo = 1 -> hoje
        // typePeriodo = 2 -> esta semana
        // typePeriodo = 3 -> mes passado
        // typePeriodo = 4 -> este mes
        // typePeriodo = 5 -> proximo mes
        // typePeriodo = 6 -> este ano
        if (action.typePeriodo === undefined || action.typePeriodo === 0) {
          dataInicial = action.args.dataInicial;
          dataFinal = action.args.dataFinal;

          datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
        } else if (action.typePeriodo === 1) {
          dataInicial = formatDateYYYYMMDD(new Date(date));
          dataFinal = formatDateYYYYMMDD(new Date(date));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }
        else if (action.typePeriodo === 2) {
          const primeiro = date.getDate() - date.getDay();

          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 3) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 4) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 5) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 6) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }

        where = {
          "tipoRelatorio": action.args.tipoRelatorio,
          "tipoAnaliseRelatorio": action.args.tipoAnaliseRelatorio,
          "dataInicial": dataInicial,
          "dataFinal": dataFinal,
          "tipoContaBancoCaixa": action.args.tipoContaBancoCaixa,
          "contaBancoCaixa": action.args.contaBancoCaixa === null ? null : action.args.contaBancoCaixa.id,
          "nivelDetalhamento": action.args.nivelDetalhamento
        }

        newFilter = {
          ...newFilter,
          ...action.args,
          dataInicial,
          dataFinal,
          where: where
        };
      } else {
        // busca filtro salvo
        datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

        where = {
          "tipoRelatorio": filterSaved.tipoRelatorio,
          "tipoAnaliseRelatorio": filterSaved.tipoAnaliseRelatorio,
          "dataInicial": filterSaved.dataInicial,
          "dataFinal": filterSaved.dataFinal,
          "tipoContaBancoCaixa": filterSaved.tipoContaBancoCaixa,
          "contaBancoCaixa": filterSaved.contaBancoCaixa === null ? null : filterSaved.contaBancoCaixa.id,
          "nivelDetalhamento": filterSaved.nivelDetalhamento
        }

        newFilter = {
          ...filterSaved,
          rows: CONST_ROWS_PER_PAGE,
          page: 0,
          where: where
        };
      }
    }

    if (newFilter.dataInicioPeriodoSelecionado !== undefined && newFilter.dataInicioPeriodoSelecionado !== null && newFilter.dataInicioPeriodoSelecionado !== '') {
      newFilter = {
        ...newFilter,
        dataInicioPeriodoSelecionado: new Date(newFilter.dataInicioPeriodoSelecionado)
      }
    }

    if (action.pdf === undefined || !action.pdf) {
      // busca registros
      let list = yield call(getRelatorioFluxoCaixa, idFazenda, newFilter.where);

      sessionStorage.setItem(CONST_FILTER_FLUXO_CAIXA, JSON.stringify(newFilter));

      var totais = {
        saldoInicial: 0.0,
        totalEntradas: 0.0,
        totalSaidas: 0.0,
        saldoFinal: 0.0,
      }

      if (list.data.length > 0) {
        totais.saldoInicial = list.data[0].totalNoPeriodo;

        totais.saldoFinal = list.data[list.data.length - 1].totalNoPeriodo;

        totais.totalEntradas = list.data
          .filter(item => item.categoria === '3 - RECEITAS')
          .reduce((acc, curr) => acc + curr.totalNoPeriodo, 0);

        totais.totalImplantacao = list.data
          .filter(item => item.categoria === 'Implantação Saldo Inicial')
          .reduce((acc, curr) => acc + curr.totalNoPeriodo, 0);

        totais.totalTransferenciaEntrada = list.data
          .filter(item => item.categoria === 'Transferência de Entrada')
          .reduce((acc, curr) => acc + curr.totalNoPeriodo, 0);

        totais.totalTransferenciaSaida = list.data
          .filter(item => item.categoria === 'Transferência de Saída')
          .reduce((acc, curr) => acc + curr.totalNoPeriodo, 0);

        totais.totalSaidas = list.data
          .filter(item => item.categoria === '4 - DESPESAS')
          .reduce((acc, curr) => acc + curr.totalNoPeriodo, 0);
      }

      yield put({
        type: NEW_FILTER_FLUXO_CAIXA_SUCCESS, list: list.data, totais: totais, filter: newFilter
      });

      // gera grafico
      let graficoData = yield call(getGraficoFluxoCaixa, idFazenda, newFilter.where);

      const graficoChart = [
        ['Período', 'Total Implantação Saldo Inicial', 'Total Receitas', 'Total Despesas', 'Total Transf. Entrada', 'Total Transf. Saída', 'Saldo Final'],
        ...graficoData.data.map(item => [item.periodo, item.totalImplantacao, item.totalReceitas, -item.totalDespesas, item.totalTransferenciaEntrada, -item.totalTransferenciaSaida, item.saldoFinal])
      ];

      yield put({
        type: NEW_FILTER_GRAFICO_FLUXO_CAIXA_SUCCESS, grafico: graficoChart
      });
    } else {
      // gera pdf

      yield put({
        type: NEW_FILTER_FLUXO_CAIXA_SUCCESS_PDF
      })
    }
  } catch (e) {
    yield put({ type: NEW_FILTER_FLUXO_CAIXA_FAILURE })
    yield put({ type: NEW_FILTER_GRAFICO_FLUXO_CAIXA_FAILURE })
  }
}

export default function* watchNewFilterFluxoCaixa() {
  yield takeLatest(NEW_FILTER_FLUXO_CAIXA, sagaNewFilterFluxoCaixa)
}
