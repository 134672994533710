


import watchNewContaChequePreDatadoCadastro from './saga/new';
import watchFindContaChequePreDatadoCadastro from './saga/find';
import watchCreateContaChequePreDatadoCadastro from './saga/create';
import watchListAllContaChequePreDatadoCadastro from './saga/listAll';
import watchListContaChequePreDatadoCadastro from './saga/listTable';
import watchDeleteContaChequePreDatadoCadastro from './saga/delete';

export const contaChequePreDatadoCadastroSaga = [
    watchNewContaChequePreDatadoCadastro(),
    watchFindContaChequePreDatadoCadastro(),
    watchCreateContaChequePreDatadoCadastro(),
    watchListAllContaChequePreDatadoCadastro(),
    watchListContaChequePreDatadoCadastro(),
    watchDeleteContaChequePreDatadoCadastro(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
