import * as Yup from 'yup';

const newRegistro = {
    descricao: '',
    sigla: '',
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(50, 'Campo deve ter menos do que 50 caracteres')
        .required('Este campo é obrigatório'),
    sigla: Yup.string()
        .max(6, 'Campo deve ter menos do que 6 caracteres')
        .required('Este campo é obrigatório'),
});

export const tipoEmbalagemCadastro = {
    newRegistro,
    validation
}