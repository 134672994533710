import { takeLatest, put } from 'redux-saga/effects';

import {
  animalRacaCadastro,
  NEW_ANIMAL_RACA_CADASTRO,
  NEW_ANIMAL_RACA_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewAnimalRacaCadastro(action) {

  yield put({ 
    type: NEW_ANIMAL_RACA_CADASTRO_SUCCESS, 
    registro: animalRacaCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido, 
  });
}

export default function* watchNewAnimalRacaCadastro() {
  yield takeLatest(NEW_ANIMAL_RACA_CADASTRO, sagaNewAnimalRacaCadastro)
}
