import { takeLatest, put, call } from 'redux-saga/effects';

import { getJsonPerfil, novoPerfil } from '../service';
import {
  NEW_PERFIL,
  NEW_PERFIL_PENDING,
  NEW_PERFIL_SUCCESS,
  NEW_PERFIL_FAILURE,
  findAllPropriedadeCadastro,
} from '@handler';

function* sagaNewPerfil(action) {
  yield put({ type: NEW_PERFIL_PENDING })

  try {
    var registro = yield call(novoPerfil, action.id);

    registro = getJsonPerfil(registro);


    const listPropriedades = yield call(findAllPropriedadeCadastro);

    let propriedades = [];
    for (let index = 0; index < listPropriedades.length; index++) {
      const prop = listPropriedades[index];
      propriedades = propriedades.concat({
        propriedade: prop,
        permiteAcesso: true,
      })
    }

    yield put({
      type: NEW_PERFIL_SUCCESS,
      registro: {
        ...registro,
        perfilPropriedades: propriedades,
      },
      isCadastroRapido: action.isCadastroRapido,
    });
  } catch (error) {
    yield put({ type: NEW_PERFIL_FAILURE });
  }
}

export default function* watchNewPerfil() {
  yield takeLatest(NEW_PERFIL, sagaNewPerfil)
}
