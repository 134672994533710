
export const NEW_TRANSFERENCIA_ESTOQUE_PRODUCAO = 'NEW_TRANSFERENCIA_ESTOQUE_PRODUCAO'
export const NEW_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS = 'NEW_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS'

export const CREATE_TRANSFERENCIA_ESTOQUE_PRODUCAO = 'CREATE_TRANSFERENCIA_ESTOQUE_PRODUCAO'
export const CREATE_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING = 'CREATE_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING'
export const CREATE_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS = 'CREATE_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS'
export const CREATE_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE = 'CREATE_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE'

export const TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO = 'TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO'
export const TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING = 'TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING'
export const TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS = 'TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS'
export const TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE = 'TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE'

export const LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO = 'LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO'
export const LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING = 'LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING'
export const LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS = 'LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS'
export const LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE = 'LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE'

export const FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO = 'FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO'
export const FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING = 'FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING'
export const FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS = 'FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS'
export const FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE = 'FIND_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE'

export const DELETE_TRANSFERENCIA_ESTOQUE_PRODUCAO = 'DELETE_TRANSFERENCIA_ESTOQUE_PRODUCAO'
export const DELETE_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING = 'DELETE_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING'
export const DELETE_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS = 'DELETE_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS'
export const DELETE_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE = 'DELETE_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE'
