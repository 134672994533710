import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllTransferenciaEstoqueProducao } from '../service';
import {
  LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO,
  LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING,
  LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS,
  LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE
} from '../actionTypes';

function* sagaListAllTransferenciaEstoqueProducao(action) {
  yield put({ type: LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING })

  try {
    const list = yield call(findAllTransferenciaEstoqueProducao)
    
    yield put({ type: LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllTransferenciaEstoqueProducao() {
  yield takeLatest(LIST_TRANSFERENCIA_ESTOQUE_PRODUCAO, sagaListAllTransferenciaEstoqueProducao)
}