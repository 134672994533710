
export const NEW_CULTURA_CADASTRO = 'NEW_CULTURA_CADASTRO'
export const NEW_CULTURA_CADASTRO_SUCCESS = 'NEW_CULTURA_CADASTRO_SUCCESS'

export const CREATE_CULTURA_CADASTRO = 'CREATE_CULTURA_CADASTRO'
export const CREATE_CULTURA_CADASTRO_PENDING = 'CREATE_CULTURA_CADASTRO_PENDING'
export const CREATE_CULTURA_CADASTRO_SUCCESS = 'CREATE_CULTURA_CADASTRO_SUCCESS'
export const CREATE_CULTURA_CADASTRO_FAILURE = 'CREATE_CULTURA_CADASTRO_FAILURE'

export const CANCEL_CULTURA_CADASTRO_RAPIDO = 'CANCEL_CULTURA_CADASTRO_RAPIDO'
export const CANCEL_CULTURA_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_CULTURA_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_CULTURA_CADASTRO = 'TABLE_CULTURA_CADASTRO'
export const TABLE_CULTURA_CADASTRO_PENDING = 'TABLE_CULTURA_CADASTRO_PENDING'
export const TABLE_CULTURA_CADASTRO_SUCCESS = 'TABLE_CULTURA_CADASTRO_SUCCESS'
export const TABLE_CULTURA_CADASTRO_FAILURE = 'TABLE_CULTURA_CADASTRO_FAILURE'

export const LIST_CULTURA_CADASTRO = 'LIST_CULTURA_CADASTRO'
export const LIST_CULTURA_CADASTRO_PENDING = 'LIST_CULTURA_CADASTRO_PENDING'
export const LIST_CULTURA_CADASTRO_SUCCESS = 'LIST_CULTURA_CADASTRO_SUCCESS'
export const LIST_CULTURA_CADASTRO_FAILURE = 'LIST_CULTURA_CADASTRO_FAILURE'

export const FIND_CULTURA_CADASTRO = 'FIND_CULTURA_CADASTRO'
export const FIND_CULTURA_CADASTRO_PENDING = 'FIND_CULTURA_CADASTRO_PENDING'
export const FIND_CULTURA_CADASTRO_SUCCESS = 'FIND_CULTURA_CADASTRO_SUCCESS'
export const FIND_CULTURA_CADASTRO_FAILURE = 'FIND_CULTURA_CADASTRO_FAILURE'

export const DELETE_CULTURA_CADASTRO = 'DELETE_CULTURA_CADASTRO'
export const DELETE_CULTURA_CADASTRO_PENDING = 'DELETE_CULTURA_CADASTRO_PENDING'
export const DELETE_CULTURA_CADASTRO_SUCCESS = 'DELETE_CULTURA_CADASTRO_SUCCESS'
export const DELETE_CULTURA_CADASTRO_FAILURE = 'DELETE_CULTURA_CADASTRO_FAILURE'
