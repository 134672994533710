import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardHeader,
  ButtonGroup,
} from 'reactstrap';
import { Column } from 'primereact/column';
import { InputText, TableReducer } from '@components/form';
import HorizontalLayout from "@components/layout/HorizontalClean";

import { PageLoaderScreen } from '@components/common/PageLoaderScreen';
import { Formik, Form } from 'formik'
import * as Yup from 'yup';

import {
  FIND_CONTA_AGRARE,
  HAS_AUTHORITY_CORPORATE,
  NEW_FILTER_CONTA_AGRARE,
  NEW_FILTER_NEXT_PAGE_CONTA_AGRARE,
} from '@handler';
import { formatCheck } from '@components/common/format';
import { getTipoAtividadeAgricolaDescricaoById } from '@handler/contaAgrare/tipoClienteAgrareAtividadeAgricolaEnum';
import { getTipoLocalTrabalhoDescricaoById } from '@handler/contaAgrare/tipoClienteAgrareLocalTrabalhoEnum';
import { SelectEnum } from '@components/search/enumSelect';
import { TIPO_SIM_NAO_ENUM } from '@handler/common/enums/tipoSimNaoEnum';
import BreadcrumbOnFarmCorporate from '@components/common/BreadcrumbOnFarmCorporate';
import { SelectGrupoContaAgrareWhiteLabel } from '@components/search/intranetWhiteLabel/grupoContaAgrareWl';

class ContaAgrare extends React.Component {

  state = {
    keyDatable: 1,
  }
  
  async componentDidMount() {
    await this.props.hasAuthorityCorporate('PERM_ROLE_WL_REGISTER_FARMER');
    await this.props.filter(false)
  }
  remove = async (registro) => {
    await this.props.deleteContaAgrare(registro.id);
  }

  onSearch = async (values, handleSubmit, isValid) => {
    handleSubmit()
    if (isValid) {
      await this.props.filter(true, values);
      this.setState({ keyDatable: this.state.keyDatable + 1 })
    }
  }

  render() {

    const { contaAgrareReducer, loginReducer } = this.props;

    function renderColumnAtivo(value) {
      return formatCheck(value.status);
    };

    function renderAtividadeAgricola(value) {
      return getTipoAtividadeAgricolaDescricaoById(value.atividadeAgricola);
    }

    function renderLocalTrabalho(value) {
      return getTipoLocalTrabalhoDescricaoById(value.localTrabalho);
    }

    function renderNomeConta(value) {
      return value.nomeConta;
      // if (contaAgrare.partnerName === undefined || contaAgrare.partnerName === null){
      //     return <span className={`badge badge-secondary`} style={{ fontSize: '12px' }}>Empresa: {contaAgrare.whiteLabelAccount}</span>
      // }
      // return <span className={`badge badge-primary`} style={{ fontSize: '12px' }}>Parceiro: {contaAgrare.partnerName} </span>
    };

    return (
      <HorizontalLayout>
        <PageLoaderScreen loading={contaAgrareReducer.loading} />

        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={12}>
              <div className="page-title-box">
                <h4 className="font-size-18">Cadastro de Conta Produtor</h4>
                <BreadcrumbOnFarmCorporate>
                  <React.Fragment>
                    <li className="breadcrumb-item">
                      <Link to="#">On Farm Coporate</Link>
                    </li>
                    <li className="breadcrumb-item active">Conta Produtor</li>
                  </React.Fragment>
                </BreadcrumbOnFarmCorporate>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card className='card-default'>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Link to='/corporate/contaProdutor/novo' title='Adicionar Nova Conta'>
                        <Button className="btn-primary" onClick={() => { }}>
                          <i className='fas fa-plus' /> Adicionar Nova Conta
                        </Button>
                      </Link>

                    </Col>
                  </Row>

                  <br />

                  <CardHeader>
                    <Row>
                      <Col md={12}>
                        <Formik
                          enableReinitialize
                          initialValues={contaAgrareReducer.filter}
                          validationSchema={Yup.object().shape({
                            status: Yup.string()
                              .typeError('Este campo é obrigatório')
                              .required('Este campo é obrigatório'),
                          })}
                          onSubmit={() => { }}
                        >
                          {({ errors, touched, values, isValid, handleSubmit }) => (
                            <Form id='filterForm' >
                              <Row>
                                <SelectEnum
                                  name='status'
                                  label='Conta Ativa'
                                  placeholder='Status Conta'
                                  list={TIPO_SIM_NAO_ENUM}
                                  col={3}
                                />
                                <SelectGrupoContaAgrareWhiteLabel
                                  name='clienteAgrareGrupo'
                                  label='Grupo de Produtor'
                                  placeholder='Selecione o grupo'
                                  col={4}
                                />
                                <InputText
                                  name='nomeConta'
                                  label='Nome da Conta'
                                  placeholder='Pesquise pelo nome da conta'
                                  col={5}
                                />
                              </Row>
                              <Row>
                                <InputText
                                  name='nomeResponsavelConta'
                                  label='Nome Responsável'
                                  placeholder='Pesquise pelo responsável'
                                  col={8}
                                />
                                <Col md={2}>
                                  <ButtonGroup style={{ marginTop: '28px' }}>
                                    <Button color="primary"
                                      onClick={() => { this.onSearch(values, handleSubmit, isValid) }}
                                    >
                                      <i className='fa fa-refresh' /> Atualizar</Button>
                                  </ButtonGroup>
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </Formik>
                      </Col>
                    </Row>
                  </CardHeader>

                  <div key={`tbl_${this.state.keyDatable}`}>
                    <TableReducer reducer={contaAgrareReducer} table={contaAgrareReducer.list} renderActions={false}
                      filterNextPage={this.props.filterNextPage} filter={contaAgrareReducer.filter}
                    >
                      <Column field='status' header='Ativo' body={renderColumnAtivo} style={{ textAlign: 'center', width: '10%' }} />

                      <Column field='nomeConta' header='Nome da Conta' body={renderNomeConta} />

                      <Column field='clienteAgrareGrupo.nome' header='Grupo Produtor' />

                      <Column field='nomeResponsavelConta' header='Nome Responsável' />

                      <Column field='localTrabalho' header='Local de Trabalho' body={renderLocalTrabalho} />
                      <Column field='atividadeAgricola' header='Atividade Agrícola' body={renderAtividadeAgricola} />

                      {(loginReducer.user.idPartnerWhiteLabel === undefined || loginReducer.user.idPartnerWhiteLabel === null) ? (
                        <Column field='partnerWhiteLabel.name' header='Parceiro' />
                      ) : null}

                      <Column style={{ textAlign: 'center' }}
                        body={(rowData, column) => {
                          return (
                            <>
                              <Link to={`/corporate/contaProdutor/editar/${rowData.id}`}>
                                <Button outline color='white' className='btn btn-warning btn-sm'
                                  onClick={() => this.props.findContaAgrare(rowData.id)}
                                  style={{ marginRight: '5px' }}>
                                  <i className='fas fa-pencil-alt' />
                                </Button>
                              </Link>
                            </>
                          );
                        }} />
                    </TableReducer>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </HorizontalLayout>
    );
  }
}

export function mapStateToProps(state) {
  const { contaAgrareReducer, loginReducer } = state.entities;
  return { contaAgrareReducer, loginReducer };
};

function mapDispatchToProps(dispatch) {
  return {
    hasAuthorityCorporate: (role) => dispatch({ type: HAS_AUTHORITY_CORPORATE, role }),
    findContaAgrare: (id) => dispatch({ type: FIND_CONTA_AGRARE, id }),
    filter: (newFilter, args) => dispatch({ type: NEW_FILTER_CONTA_AGRARE, newFilter, args }),
    filterNextPage: (rows, page) => dispatch({ type: NEW_FILTER_NEXT_PAGE_CONTA_AGRARE, rows, page }),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ContaAgrare))

