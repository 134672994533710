import { takeLatest, put } from 'redux-saga/effects';
import moment from "moment";

import { storage } from '@commons';

import {
  colheita,
  CONFIG_SESSION,
  NEW_COLHEITA,
  NEW_COLHEITA_SUCCESS,
} from '@handler';

function* sagaNewColheita(action) {

  yield put({
    type: NEW_COLHEITA_SUCCESS, registro: {
      ...colheita.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      // talhao: action.talhao,
      cultura: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura,
      culturaFormaLancColheita: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.formaLancColheita,
      culturaUnidadeColheita: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.unidadeColheita,
      culturaUnidadeColheitaPossuiConversao: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.unidadeColheitaPossuiConversao,
      culturaTipoEmbalagem: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.tipoEmbalagem,
      culturaQtdEmbalagem: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.qtdEmbalagem,
      culturaUnidadeEmbalagem: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.unidadeEmbalagem,
      culturaQtdConversaoUndColheitaUndEmb: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura.qtdConversaoUndColheitaUndEmb,
      // areaColhida: action.talhao === null ? 0 : action.talhao.area
    }
  });

}

export default function* watchNewColheita() {
  yield takeLatest(NEW_COLHEITA, sagaNewColheita)
}
