import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/planoconta' };
/////////////////////////////////////////////////////////////////////////////////

export function createPlanoContaCadastro(payload) {
  return httpService.post(args, payload);
}

export function updatePlanoContaCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listPlanoContaCadastro(param) {
  return httpService.get({ ...args, args: param });
}

export function findPlanoContaCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllPlanoContaCadastro() {
  return httpService.get({ ...args, path: '/planoconta/all' });
}

export function deletePlanoContaCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function getRelatorioPlanoContas(idPropriedade) {
  return httpService.getPdf({ ...args, args: `/relatorioPlanoContas/pdf?propriedade=${idPropriedade}` });
}

