import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createClienteCadastro, updateClienteCadastro } from '../service';
import {
  CREATE_CLIENTE_CADASTRO,
  CREATE_CLIENTE_CADASTRO_PENDING,
  CREATE_CLIENTE_CADASTRO_SUCCESS,
  CREATE_CLIENTE_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaCreateClienteCadastro(action) {
  yield put({ type: CREATE_CLIENTE_CADASTRO_PENDING })

  try {
    let type = '';
    if (action.data.tipoPessoa === 'FISICA') {
      type = 'PessoaFisica';
      action.data.indicadorInscrEstadual = 'NAOCONTRIBUINTE';
    } else {
      type = 'PessoaJuridica';
      if(action.data.indicadorInscrEstadual === null || action.data.indicadorInscrEstadual === undefined || action.data.indicadorInscrEstadual === '') {
        action.data.indicadorInscrEstadual = 'CONTRIBUINTEISENTO';
      }
    }
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createClienteCadastro, formatJsonRemoveEmptyOrNull({
        ...action.data,
        type: type
      }));
    } else {
      yield call(updateClienteCadastro,
        formatJsonRemoveEmptyOrNull({
          ...action.data,
          id: action.data.id,
          type: type
        })
      );
    }

    yield put({ type: CREATE_CLIENTE_CADASTRO_SUCCESS, registro: action.data })

    if(!action.isCadastroRapido){
      history.push('/clienteCadastro');
    }
    
    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_CLIENTE_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateClienteCadastro() {
  yield takeLatest(CREATE_CLIENTE_CADASTRO, sagaCreateClienteCadastro)
}
