import * as Yup from 'yup';

const newRegistro = {
    status: true,
    placa: '',
    veiculoProprio: false,
    patrimonio: null,
    transportadora: null,
};

const validation = Yup.object().shape({
    placa: Yup.string()
        .max(15, 'Campo deve ter menos do que 15 caracteres')
        .required('Este campo é obrigatório'),
});

export const veiculoCadastro = {
    newRegistro,
    validation
}