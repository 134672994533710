import * as Yup from 'yup';

const newRegistro = {
    data: '',
    quantidade: 1,
    tipoSaidaAnimal: 'CATEGORIA_LOTE',
    lote: null,
    categoria: null,
    animal: null,
    campoOrigem: null,
    campoDestino: null,
    obs: '',
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    quantidade: Yup.number()
        .positive('Por Favor, informe um valor maior que zero')
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
    tipoSaidaAnimal: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    campoOrigem: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    campoDestino: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    categoria: Yup.object().when(['tipoSaidaAnimal'], {
        is: (tipoSaidaAnimal) => (
            tipoSaidaAnimal == 'CATEGORIA_LOTE'),
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    lote: Yup.object().when(['tipoSaidaAnimal'], {
        is: (tipoSaidaAnimal) => (
            tipoSaidaAnimal == 'CATEGORIA_LOTE'),
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    animal: Yup.object().when(['tipoSaidaAnimal'], {
        is: (tipoSaidaAnimal) => (
            tipoSaidaAnimal == 'ANIMAL_IDENTIFICADO'),
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    obs: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
});

export const transferenciaAnimal = {
    newRegistro,
    validation
}