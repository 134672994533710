


import watchNewContaCartaoCadastro from './saga/new';
import watchFindContaCartaoCadastro from './saga/find';
import watchCreateContaCartaoCadastro from './saga/create';
import watchListAllContaCartaoCadastro from './saga/listAll';
import watchListContaCartaoCadastro from './saga/listTable';
import watchDeleteContaCartaoCadastro from './saga/delete';

export const contaCartaoCadastroSaga = [
    watchNewContaCartaoCadastro(),
    watchFindContaCartaoCadastro(),
    watchCreateContaCartaoCadastro(),
    watchListAllContaCartaoCadastro(),
    watchListContaCartaoCadastro(),
    watchDeleteContaCartaoCadastro(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
