import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/conta/cartao' };
/////////////////////////////////////////////////////////////////////////////////

export function createContaCartaoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateContaCartaoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listContaCartaoCadastro(param) {
  return httpService.get({ ...args, args: param });
}

export function findContaCartaoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllContaCartaoCadastro() {
  return httpService.get({ ...args, path: '/conta/cartao/all' });
}

export function deleteContaCartaoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

