import { takeLatest, put, call } from 'redux-saga/effects';

import { findControleContaCaixa, findControleContaCaixaTransf } from '../service';
import {
  FIND_CONTROLE_CONTA_CAIXA,
  FIND_CONTROLE_CONTA_CAIXA_PENDING,
  FIND_CONTROLE_CONTA_CAIXA_SUCCESS,
  FIND_CONTROLE_CONTA_CAIXA_FAILURE
} from '../actionTypes';
import { history } from '@commons';

function* sagaFindControleContaCaixa(action) {
  yield put({ type: FIND_CONTROLE_CONTA_CAIXA_PENDING, id: action.id })

  try {
    if (action.origemLancamento === 'TRANSFERENCIA') {
      const registro = yield call(findControleContaCaixaTransf, action.id);
      
      let newRegistro = {
        ...registro,
        conta: registro.contaOrigem,
        tipoMovimento: 'TRANSFERENCIA'
      }

      yield put({ type: FIND_CONTROLE_CONTA_CAIXA_SUCCESS, registro: newRegistro })

      history.push('/financeiro/movimentacoesCaixa/editar/' + newRegistro.id );
    } else {
      const registro = yield call(findControleContaCaixa, action.id);

      yield put({ type: FIND_CONTROLE_CONTA_CAIXA_SUCCESS, registro: registro })

      history.push('/financeiro/movimentacoesCaixa/editar/' + registro.id);
    }
  } catch (error) {

    yield put({ type: FIND_CONTROLE_CONTA_CAIXA_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindControleContaCaixa() {
  yield takeLatest(FIND_CONTROLE_CONTA_CAIXA, sagaFindControleContaCaixa)
}
