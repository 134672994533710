export const STATUS_PLANEJAMENTO_ENUM = [
    {
        id: 'PLANEJADO',
        descricao: "Planejado",
        idBD: 0
    },
    {
        id: 'REALIZADO',
        descricao: "Realizado",
        idBD: 1
    },
    {
        id: 'REALIZADO_PARCIAL',
        descricao: "Realizado Parcial",
        idBD: 2
    },
    // {
    //     id: 'CANCELADO',
    //     descricao: "Cancelado",
    //     idBD: 3
    // }
];