import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/pecuaria/manejo/execucao' };
/////////////////////////////////////////////////////////////////////////////////

export function createManejoExecucaoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateManejoExecucaoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listManejoExecucaoCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findManejoExecucaoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllManejoExecucaosByPropriedade(propriedade) {
  return httpService.get({ ...args, path: '/manejo/execucao?size=20000&page=0&status=true&propriedade=' + propriedade });
}

export function deleteManejoExecucaoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function importManejoPecuario(id) {
  return httpService.get({ ...args, args: `/realizarPlanejamento/${id}` });
}

export function getManejosByPlanejamento(id) {
  return httpService.get({ ...args, args: `/manejosByPlanejamento/${id}` });
}


