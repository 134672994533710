


import watchNewEntradaEstoque from './saga/new';
import watchFindEntradaEstoque from './saga/find';
import watchCreateEntradaEstoque from './saga/create';
import watchListAllEntradaEstoque from './saga/listAll';
import watchListEntradaEstoque from './saga/listTable';
import watchDeleteEntradaEstoque from './saga/delete';
import watchReadXmlEntradaEstoque from './saga/xml/readXml';
import watchSelectProductItemXmlEntradaEstoque from './saga/xml/selectProductItemXml';

export const entradaEstoqueSaga = [
    watchNewEntradaEstoque(),
    watchFindEntradaEstoque(),
    watchCreateEntradaEstoque(),
    watchListAllEntradaEstoque(),
    watchListEntradaEstoque(),
    watchDeleteEntradaEstoque(),
    watchReadXmlEntradaEstoque(),
    watchSelectProductItemXmlEntradaEstoque(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
export * from './tipoDuplicataEnum';
