import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import {
  DELETE_USUARIO_WHITE_LABEL,
  DELETE_USUARIO_WHITE_LABEL_PENDING,
  DELETE_USUARIO_WHITE_LABEL_SUCCESS,
  DELETE_USUARIO_WHITE_LABEL_FAILURE
} from '../actionTypes';
import { AlertWarning } from '@components/common/AlertToast';
import { deleteUsuarioWhiteLabel } from '../service';

function* sagaDeleteUsuarioWhiteLabel(action) {
  yield put({ type: DELETE_USUARIO_WHITE_LABEL_PENDING, id: action.id })

  try {
    yield call(deleteUsuarioWhiteLabel, action.id);

    yield put({ type: DELETE_USUARIO_WHITE_LABEL_SUCCESS, removed: true });

    swal({
      title: 'Usuário Excluído!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });
  } catch (error) {
    yield put({ type: DELETE_USUARIO_WHITE_LABEL_FAILURE, removed: false });

    AlertWarning('Usuário não pode ser excluído pois já realizou movimentação no sistema. Para remover acesso ao sistema, você deve Inativar Usuário no cadastro');
  }
}

export default function* watchDeleteUsuarioWhiteLabel() {
  yield takeLatest(DELETE_USUARIO_WHITE_LABEL, sagaDeleteUsuarioWhiteLabel)
}
