import { takeLatest, put, call } from 'redux-saga/effects';

import { findTipoEmbalagemWhiteLabel } from '../service';
import {
  FIND_TIPO_EMBALAGEM_WHITE_LABEL,
  FIND_TIPO_EMBALAGEM_WHITE_LABEL_PENDING,
  FIND_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS,
  FIND_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE
} from '../actionTypes';

function* sagaFindTipoEmbalagemWhiteLabel(action) {
  yield put({ type: FIND_TIPO_EMBALAGEM_WHITE_LABEL_PENDING, id: action.id })

  try {

    const registro = yield call(findTipoEmbalagemWhiteLabel, action.id);

    yield put({ type: FIND_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindTipoEmbalagemWhiteLabel() {
  yield takeLatest(FIND_TIPO_EMBALAGEM_WHITE_LABEL, sagaFindTipoEmbalagemWhiteLabel)
}
