import { takeLatest, put, call } from 'redux-saga/effects'

import { httpAuthService } from '@commons';
import { history, path, storage } from '@commons';
import swal from 'sweetalert';

import {
  CONTA_AGRARE,
  CONTA_AGRARE_PENDING,
  CONTA_AGRARE_SUCCESS,
  CONTA_AGRARE_FAILURE
} from '../actionTypes';

import { AlertError, AlertWarning } from '@components/common/AlertToast'

import { CREATE_NEW_ACCOUNT_FAILURE, CREATE_NEW_ACCOUNT_PENDING, CREATE_NEW_ACCOUNT_SUCCESS, UPDATE_SESSION_USER, findUserAuthResumed } from '@handler';

const cadastrarNovaConta = async (values) => {
  await httpAuthService.registerNewScheme(values);
}

const atualizaUsuarioSchemas = async (id) => {
  let user = await findUserAuthResumed(id)
  httpAuthService.updateSesssionUser(user);
  return user;
}

function* sagaNovaContaAgrare(action) {
  yield put({ type: CONTA_AGRARE_PENDING })
  yield put({ type: CREATE_NEW_ACCOUNT_PENDING })

  try {
    let user = JSON.parse(storage.get(path.sessionUser));

    let idClienteAgrareGrupo = action.data.clienteAgrareGrupo.id;
    delete action.data.clienteAgrareGrupo;
    delete action.data.confirm;

    action.data = {
      ...action.data,
      "idClienteAgrareGrupo": idClienteAgrareGrupo,
      'tipoCliente': 'PRODUTOR',
      'criarDominio': true,
      'idUserAdmin': user.id,
      'emailUserAdmin': user.email,
      "identificationCodeWhiteLabel": user.identificationCodeWhiteLabel,
      "idPartnerWhiteLabel": user.idPartnerWhiteLabel,
    }

    yield put({ type: CREATE_NEW_ACCOUNT_PENDING })

    yield call(cadastrarNovaConta, action.data);

    var newUser = yield call(atualizaUsuarioSchemas, user.id);

    yield put({ type: UPDATE_SESSION_USER, user: newUser });
    yield put({ type: CREATE_NEW_ACCOUNT_SUCCESS })
    yield put({ type: CONTA_AGRARE_SUCCESS });

    swal({
      title: 'Conta criada com sucesso!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.push('/corporate/contaProdutor');
    });
  } catch (error) {
    yield put({ type: CONTA_AGRARE_FAILURE });
    yield put({ type: CREATE_NEW_ACCOUNT_FAILURE })

    if (error.text !== undefined && error.text !== null && error.text !== '') {
      AlertWarning(error.text);
    } else {
      AlertError('Falha ao criar nova conta. Tente novamente em instantes...');
    }

    history.push('/corporate/contaProdutor');
  }

}

export default function* watchNovaContaAgrare() {
  yield takeLatest(CONTA_AGRARE, sagaNovaContaAgrare);
}
