import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/lancamentoContaBancoCaixa/transferencia' };
/////////////////////////////////////////////////////////////////////////////////

export function createTransferenciaContaBancoCaixa(payload) {
  return httpService.post(args, payload);
}

export function updateTransferenciaContaBancoCaixa(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function findTransferenciaContaBancoCaixa(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deleteTransferenciaContaBancoCaixa(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function getLancamentos(idPropriedade, datas) {
  return httpService.get({ ...args, args: `?filterableList&propriedade=${idPropriedade}&datas=${datas}` });
}