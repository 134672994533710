// @flow

import { storage } from '@commons/storage';
import { path } from '@commons/path';

import {
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_PENDING,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  RECOVER_PASSWORD_PENDING,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_FAILURE,
  HAS_AUTHORITY_PENDING,
  HAS_AUTHORITY_SUCCESS,
  HAS_AUTHORITY_FAILURE,
  HAS_AUTHORITY_CORPORATE_PENDING,
  HAS_AUTHORITY_CORPORATE_SUCCESS,
  HAS_AUTHORITY_CORPORATE_FAILURE,
  HAS_AUTHORITY_PENDING_PAINEL_AGRARE,
  HAS_AUTHORITY_SUCCESS_PAINEL_AGRARE,
  HAS_AUTHORITY_FAILURE_PAINEL_AGRARE,
  UPDATE_SESSION_USER
} from './actionTypes';

let hasAuthorityPainelAgrare = {
  agricultura: false,
  pecuaria: false,
  financeiro: false,
  patrimonio: false,
};

function loginReducer(state = {
  loading: false,
  hasAuthority: false,
  hasAuthorityCorporate: false,
  hasAuthorityPainelAgrare: hasAuthorityPainelAgrare
}, action) {

  const user = storage.getJson(path.sessionUser);

  switch (action.type) {
    case HAS_AUTHORITY_PENDING:
    case HAS_AUTHORITY_CORPORATE_PENDING:
    case HAS_AUTHORITY_PENDING_PAINEL_AGRARE:
    case REGISTER_PENDING:
    case RECOVER_PASSWORD_PENDING:
    case LOGIN_PENDING:
      return {
        ...state,
        user,
        loading: true
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        user,
        loading: false
      }
    case LOGIN_FAILURE:
    case REGISTER_FAILURE:
    case RECOVER_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false
      }
    case REGISTER_SUCCESS:
    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case HAS_AUTHORITY_SUCCESS:
      return {
        ...state,
        user,
        hasAuthority: true,
        loading: false
      }
    case HAS_AUTHORITY_FAILURE:
      return {
        ...state,
        user,
        hasAuthority: false,
        loading: false
      }
    case HAS_AUTHORITY_CORPORATE_SUCCESS:
      return {
        ...state,
        user,
        hasAuthorityCorporate: true,
        loading: false
      }
    case HAS_AUTHORITY_CORPORATE_FAILURE:
      return {
        ...state,
        user,
        hasAuthorityCorporate: false,
        loading: false
      }
    case HAS_AUTHORITY_SUCCESS_PAINEL_AGRARE:
      return {
        ...state,
        user,
        hasAuthorityPainelAgrare: action.hasAuthorityPainelAgrare,
        loading: false
      }
    case HAS_AUTHORITY_FAILURE_PAINEL_AGRARE:
      return {
        ...state,
        user,
        hasAuthorityPainelAgrare: hasAuthorityPainelAgrare,
        loading: false
      }
    case UPDATE_SESSION_USER:
      return {
        ...state,
        user: action.user,
        loading: false
      }
    default:
      return { ...state, user }
  }
}

export { loginReducer };
