import { takeLatest, put, call } from 'redux-saga/effects';

import { storage, history } from '@commons';

import {
  IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA,
  IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING,
  IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS,
  IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE
} from '../actionTypes';
import { AlertError } from '@components/common/AlertToast';
import { CONFIG_SESSION, READ_XML_ENTRADA_ESTOQUE_SUCCESS, READ_XML_ENTRADA_ESTOQUE_PENDING } from '@handler';
import { readXmlFile } from '../service';

function* sagaImportarNotaEntrada(action) {
  yield put({ type: READ_XML_ENTRADA_ESTOQUE_PENDING })
  yield put({ type: IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING })

  try {
    delete action.notaEntradaApiDfe.empresa;
    
      const nota = yield call(readXmlFile, action.notaEntradaApiDfe, action.naturezaOperacao, action.localEstoque, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id);
      
      yield put({ type: READ_XML_ENTRADA_ESTOQUE_SUCCESS, xmlReaded: nota.data })
      yield put({ type: IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS })

      history.push('/estoque/entrada/importacaoxml');
  } catch (error) {
    yield put({ type: IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE })

      AlertError('Falha ao importar nota! Tente novamente...');
  }
}

export default function* watchImportarNotaEntrada() {
  yield takeLatest(IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA, sagaImportarNotaEntrada)
}
