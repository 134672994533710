import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_FAILURE,
    GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_PENDING,
    GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_SUCCESS,
    NEW_FILTER_MONITORAMENTO_NFE_COMPRA_FAILURE,
    NEW_FILTER_MONITORAMENTO_NFE_COMPRA_PENDING,
    NEW_FILTER_MONITORAMENTO_NFE_COMPRA_SUCCESS,
    NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_FAILURE,
    NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_PENDING,
    NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_SUCCESS,
    IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING,
    IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS,
    IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE,
    VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING,
    VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS,
    VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE,
} from './actionTypes';

const initialState = {
    list: null,
    loading: false,
    loadingTotais: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
    totais: {
        nroCpfCnpjMonitorados: 0,
        nroNotasCompras: 0,
        totalNotasCompras: 0,
    }
}

function monitoramentoNFeCompraReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_FILTER_MONITORAMENTO_NFE_COMPRA_PENDING:
        case NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_PENDING:
        case IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING:
        case VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING:
            return {
                ...state,
                loading: true,
            }
        case NEW_FILTER_MONITORAMENTO_NFE_COMPRA_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE:
        case IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS:
        case VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE:
        case VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS:
        case NEW_FILTER_MONITORAMENTO_NFE_COMPRA_FAILURE:
            return {
                ...state,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_MONITORAMENTO_NFE_COMPRA_FAILURE:
            return {
                ...state,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_PENDING:
            return {
                ...state,
                loadingTotais: true,
            }
        case GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_SUCCESS:
            return {
                ...state,
                totais: action.totais,
                loadingTotais: false
            }
        case GET_TOTAIS_MONITORAMENTO_NFE_COMPRA_FAILURE:
            return {
                ...state,
                totais: {
                    nroCpfCnpjMonitorados: 0,
                    nroNotasCompras: 0,
                    totalNotasCompras: 0,
                },
                loadingTotais: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { monitoramentoNFeCompraReducer };