


import watchNewContaPagarRecorrente from './saga/new';
import watchFindContaPagarRecorrente from './saga/find';
import watchCreateContaPagarRecorrente from './saga/create';
import watchDeleteContaPagarRecorrente from './saga/delete';
import watchListContaPagarRecorrente from './saga/listTable';
import watchAlterarStatusContaPagarRecorrente from './saga/alterarStatus';
import watchGerarContaAgoraContaPagarRecorrente from './saga/gerarContaAgora';

export const contaPagarRecorrenteSaga = [
    watchNewContaPagarRecorrente(),
    watchFindContaPagarRecorrente(),
    watchCreateContaPagarRecorrente(),
    watchDeleteContaPagarRecorrente(),
    watchListContaPagarRecorrente(),
    watchAlterarStatusContaPagarRecorrente(),
    watchGerarContaAgoraContaPagarRecorrente(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
