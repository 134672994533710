// Firebase
import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBp7On6OihTakWWIX1zoeVFo13sAdngIJ8",
  authDomain: "agrare-bc3b1.firebaseapp.com",
  projectId: "agrare-bc3b1",
  storageBucket: "agrare-bc3b1.appspot.com",
  messagingSenderId: "794578185168",
  appId: "1:794578185168:web:973de5734b0ba15794e7d5",
  measurementId: "G-2HP05DQKCY"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const db =  firebase.firestore();

export default db;