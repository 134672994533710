import React from 'react';
import './styles.css';

const NdviScale = () => {
  return (
    <div className="ndvi-legend">
      <h5>Índice de Vegetação</h5>
      <div className="legend-description">
        Baixo
        <div className="legend-gradient">
          <div className="gradient-color" style={{ background: 'darkred' }} />
          <div className="gradient-color" style={{ background: 'red' }} />
          <div className="gradient-color" style={{ background: 'yellow' }} />
          <div className="gradient-color" style={{ background: 'green' }} />
          <div className="gradient-color" style={{ background: 'darkgreen' }} />
        </div>
        Alto
      </div>
    </div>
  );
};

export default NdviScale;
