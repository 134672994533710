import { takeLatest, put } from 'redux-saga/effects';

import {
  CANCEL_TRANSPORTADORA_CADASTRO_RAPIDO,
  CANCEL_TRANSPORTADORA_CADASTRO_RAPIDO_SUCCESS,
} from '../actionTypes';

function* sagaCancelTransportadoraCadastroRapido() {
  yield put({ type: CANCEL_TRANSPORTADORA_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelTransportadoraCadastroRapido() {
  yield takeLatest(CANCEL_TRANSPORTADORA_CADASTRO_RAPIDO, sagaCancelTransportadoraCadastroRapido)
}