import { takeLatest, put, call } from 'redux-saga/effects';

import { storage } from '@commons';

import { readXmlFile } from '../../service';
import {
  READ_XML_ENTRADA_ESTOQUE,
  READ_XML_ENTRADA_ESTOQUE_PENDING,
  READ_XML_ENTRADA_ESTOQUE_SUCCESS,
  READ_XML_ENTRADA_ESTOQUE_FAILURE
} from '../../actionTypes';
import { AlertError } from '@components/common/AlertToast';
import { CONFIG_SESSION, readXmlVerificarArquivoEFornecedor } from '@handler';

function* sagaReadXmlEntradaEstoque(action) {
  yield put({ type: READ_XML_ENTRADA_ESTOQUE_PENDING })

  try {
    const fornecedor = yield call(readXmlVerificarArquivoEFornecedor, action.xml.file);

    if (fornecedor.data === false) {
      AlertError('Falha ao vincular fornecedor ou XML não é uma NFe válida');
    } else {
      const nota = yield call(readXmlFile, action.xml.file, action.naturezaOperacao, action.localEstoque, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id);
      
      yield put({ type: READ_XML_ENTRADA_ESTOQUE_SUCCESS, xmlReaded: nota.data })
    }
  } catch (error) {

    yield put({ type: READ_XML_ENTRADA_ESTOQUE_FAILURE })

    if (error.response.status === 406) {
      AlertError('Falha ao vincular fornecedor ou XML não é uma NFe válida');
    } else {
      AlertError('Falha ao ler xml da NFe! Tente novamente...');
    }

  }
}

export default function* watchReadXmlEntradaEstoque() {
  yield takeLatest(READ_XML_ENTRADA_ESTOQUE, sagaReadXmlEntradaEstoque)
}
