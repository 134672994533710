import { takeLatest, put } from 'redux-saga/effects';

import {
  tipoEmbalagemWhiteLabel,
  NEW_TIPO_EMBALAGEM_WHITE_LABEL,
  NEW_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS,
} from '@handler';

function* sagaNewTipoEmbalagemWhiteLabel(action) {

  yield put({
    type: NEW_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS,
    registro: tipoEmbalagemWhiteLabel.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewTipoEmbalagemWhiteLabel() {
  yield takeLatest(NEW_TIPO_EMBALAGEM_WHITE_LABEL, sagaNewTipoEmbalagemWhiteLabel)
}
