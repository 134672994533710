import { takeLatest, put } from 'redux-saga/effects';
import moment from "moment";

import {
  pluviometroDigital,
  NEW_PLUVIOMETRO_DIGITAL,
  NEW_PLUVIOMETRO_DIGITAL_SUCCESS,
} from '@handler';

function* sagaNewPluviometroDigital(action) {

  yield put({ type: NEW_PLUVIOMETRO_DIGITAL_SUCCESS, registro: {
    ...pluviometroDigital.newRegistro,
    data: moment().startOf('day').format('YYYY-MM-DD'),
    estacaoMeteorologica: action.estacaoMeteorologica
  }
 });
}

export default function* watchNewPluviometroDigital() {
  yield takeLatest(NEW_PLUVIOMETRO_DIGITAL, sagaNewPluviometroDigital)
}
