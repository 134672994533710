import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/cliente' };
/////////////////////////////////////////////////////////////////////////////////

export function createClienteCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateClienteCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listClienteCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findClienteCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllClienteCadastro() {
  return httpService.get({ ...args, path: '/cliente/all' });
}

export function deleteClienteCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function findCnpj(cnpj) {
  return httpService.get({ ...args, args: `/find/dataByCnpj/${cnpj}` });
}

export function findCep(cep) {
  return httpService.get({ ...args, args: `/find/addressByCep/${cep}` });
}