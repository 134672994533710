import { takeLatest, put, call } from 'redux-saga/effects';

import { findPluviometroDigital } from '../service';
import {
  FIND_PLUVIOMETRO_DIGITAL,
  FIND_PLUVIOMETRO_DIGITAL_PENDING,
  FIND_PLUVIOMETRO_DIGITAL_SUCCESS,
  FIND_PLUVIOMETRO_DIGITAL_FAILURE
} from '../actionTypes';

function* sagaFindPluviometroDigital(action) {
  yield put({ type: FIND_PLUVIOMETRO_DIGITAL_PENDING, id: action.id })

  try {

    const registro = yield call(findPluviometroDigital, action.id);

    yield put({ type: FIND_PLUVIOMETRO_DIGITAL_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_PLUVIOMETRO_DIGITAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindPluviometroDigital() {
  yield takeLatest(FIND_PLUVIOMETRO_DIGITAL, sagaFindPluviometroDigital)
}
