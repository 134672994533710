export const NEW_PRODUTO_INSUMO_CADASTRO = 'NEW_PRODUTO_INSUMO_CADASTRO'
export const NEW_PRODUTO_INSUMO_CADASTRO_SUCCESS = 'NEW_PRODUTO_INSUMO_CADASTRO_SUCCESS'
export const NEW_PRODUTO_INSUMO_CADASTRO_PENDING = 'NEW_PRODUTO_INSUMO_CADASTRO_PENDING'

export const CREATE_PRODUTO_INSUMO_CADASTRO = 'CREATE_PRODUTO_INSUMO_CADASTRO'
export const CREATE_PRODUTO_INSUMO_CADASTRO_PENDING = 'CREATE_PRODUTO_INSUMO_CADASTRO_PENDING'
export const CREATE_PRODUTO_INSUMO_CADASTRO_SUCCESS = 'CREATE_PRODUTO_INSUMO_CADASTRO_SUCCESS'
export const CREATE_PRODUTO_INSUMO_CADASTRO_FAILURE = 'CREATE_PRODUTO_INSUMO_CADASTRO_FAILURE'

export const CANCEL_PRODUTO_INSUMO_CADASTRO_RAPIDO = 'CANCEL_PRODUTO_INSUMO_CADASTRO_RAPIDO'
export const CANCEL_PRODUTO_INSUMO_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_PRODUTO_INSUMO_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_PRODUTO_INSUMO_CADASTRO = 'TABLE_PRODUTO_INSUMO_CADASTRO'
export const TABLE_PRODUTO_INSUMO_CADASTRO_PENDING = 'TABLE_PRODUTO_INSUMO_CADASTRO_PENDING'
export const TABLE_PRODUTO_INSUMO_CADASTRO_SUCCESS = 'TABLE_PRODUTO_INSUMO_CADASTRO_SUCCESS'
export const TABLE_PRODUTO_INSUMO_CADASTRO_FAILURE = 'TABLE_PRODUTO_INSUMO_CADASTRO_FAILURE'

export const LIST_PRODUTO_INSUMO_CADASTRO = 'LIST_PRODUTO_INSUMO_CADASTRO'
export const LIST_PRODUTO_INSUMO_CADASTRO_PENDING = 'LIST_PRODUTO_INSUMO_CADASTRO_PENDING'
export const LIST_PRODUTO_INSUMO_CADASTRO_SUCCESS = 'LIST_PRODUTO_INSUMO_CADASTRO_SUCCESS'
export const LIST_PRODUTO_INSUMO_CADASTRO_FAILURE = 'LIST_PRODUTO_INSUMO_CADASTRO_FAILURE'

export const FIND_PRODUTO_INSUMO_CADASTRO = 'FIND_PRODUTO_INSUMO_CADASTRO'
export const FIND_PRODUTO_INSUMO_CADASTRO_PENDING = 'FIND_PRODUTO_INSUMO_CADASTRO_PENDING'
export const FIND_PRODUTO_INSUMO_CADASTRO_SUCCESS = 'FIND_PRODUTO_INSUMO_CADASTRO_SUCCESS'
export const FIND_PRODUTO_INSUMO_CADASTRO_FAILURE = 'FIND_PRODUTO_INSUMO_CADASTRO_FAILURE'

export const DELETE_PRODUTO_INSUMO_CADASTRO = 'DELETE_PRODUTO_INSUMO_CADASTRO'
export const DELETE_PRODUTO_INSUMO_CADASTRO_PENDING = 'DELETE_PRODUTO_INSUMO_CADASTRO_PENDING'
export const DELETE_PRODUTO_INSUMO_CADASTRO_SUCCESS = 'DELETE_PRODUTO_INSUMO_CADASTRO_SUCCESS'
export const DELETE_PRODUTO_INSUMO_CADASTRO_FAILURE = 'DELETE_PRODUTO_INSUMO_CADASTRO_FAILURE'

export const NEW_PRODUTO_INSUMO_VINCULAR_FORNECEDOR = 'NEW_PRODUTO_INSUMO_VINCULAR_FORNECEDOR'
export const NEW_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_SUCCESS = 'NEW_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_SUCCESS'

export const UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR = 'UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR'
export const UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_PENDING = 'UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_PENDING'
export const UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_SUCCESS = 'UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_SUCCESS'
export const UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_FAILURE = 'UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_FAILURE'

export const UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML = 'UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML'
export const UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_PENDING = 'UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_PENDING'
export const UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_SUCCESS = 'UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_SUCCESS'
export const UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_FAILURE = 'UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_XML_FAILURE'

export const IMPORTAR_PRODUTO_WHITE_LABEL = 'IMPORTAR_PRODUTO_WHITE_LABEL'
export const IMPORTAR_PRODUTO_WHITE_LABEL_PENDING = 'IMPORTAR_PRODUTO_WHITE_LABEL_PENDING'
export const IMPORTAR_PRODUTO_WHITE_LABEL_SUCCESS = 'IMPORTAR_PRODUTO_WHITE_LABEL_SUCCESS'
export const IMPORTAR_PRODUTO_WHITE_LABEL_FAILURE = 'IMPORTAR_PRODUTO_WHITE_LABEL_FAILURE'