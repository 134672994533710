
export const NEW_CATALAGO_PRODUTO_WHITE_LABEL = 'NEW_CATALAGO_PRODUTO_WHITE_LABEL'
export const NEW_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS = 'NEW_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS'

export const CREATE_CATALAGO_PRODUTO_WHITE_LABEL = 'CREATE_CATALAGO_PRODUTO_WHITE_LABEL'
export const CREATE_CATALAGO_PRODUTO_WHITE_LABEL_PENDING = 'CREATE_CATALAGO_PRODUTO_WHITE_LABEL_PENDING'
export const CREATE_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS = 'CREATE_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS'
export const CREATE_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE = 'CREATE_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE'

export const CANCEL_CATALAGO_PRODUTO_WHITE_LABEL_RAPIDO = 'CANCEL_CATALAGO_PRODUTO_WHITE_LABEL_RAPIDO'
export const CANCEL_CATALAGO_PRODUTO_WHITE_LABEL_RAPIDO_SUCCESS = 'CANCEL_CATALAGO_PRODUTO_WHITE_LABEL_RAPIDO_SUCCESS'

export const TABLE_CATALAGO_PRODUTO_WHITE_LABEL = 'TABLE_CATALAGO_PRODUTO_WHITE_LABEL'
export const TABLE_CATALAGO_PRODUTO_WHITE_LABEL_PENDING = 'TABLE_CATALAGO_PRODUTO_WHITE_LABEL_PENDING'
export const TABLE_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS = 'TABLE_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS'
export const TABLE_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE = 'TABLE_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE'

export const FIND_CATALAGO_PRODUTO_WHITE_LABEL = 'FIND_CATALAGO_PRODUTO_WHITE_LABEL'
export const FIND_CATALAGO_PRODUTO_WHITE_LABEL_PENDING = 'FIND_CATALAGO_PRODUTO_WHITE_LABEL_PENDING'
export const FIND_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS = 'FIND_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS'
export const FIND_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE = 'FIND_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE'

export const DELETE_CATALAGO_PRODUTO_WHITE_LABEL = 'DELETE_CATALAGO_PRODUTO_WHITE_LABEL'
export const DELETE_CATALAGO_PRODUTO_WHITE_LABEL_PENDING = 'DELETE_CATALAGO_PRODUTO_WHITE_LABEL_PENDING'
export const DELETE_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS = 'DELETE_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS'
export const DELETE_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE = 'DELETE_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE'
