import { takeLatest, put, call } from 'redux-saga/effects';

import { path, history, storage } from '@commons';

import { createGrupoContaAgrareWhiteLabel, updateGrupoContaAgrareWhiteLabel } from '../service';
import {
  CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL,
  CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL_PENDING,
  CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS,
  CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
function* sagaCreateGrupoContaAgrareWhiteLabel(action) {
  yield put({ type: CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL_PENDING })

  try {
    let user = JSON.parse(storage.get(path.sessionUser));

    var newGrupoContaAgrare = {
      ...action.data,
      "identificationCodeWhiteLabel": user.identificationCodeWhiteLabel,
    }

    if (user.idPartnerWhiteLabel !== undefined && user.idPartnerWhiteLabel !== null) {
      newGrupoContaAgrare = {
        ...newGrupoContaAgrare,
        "partnerWhiteLabel": {
          "id": user.idPartnerWhiteLabel
        },
      }
    }
    
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createGrupoContaAgrareWhiteLabel, newGrupoContaAgrare);
    } else {
      yield call(updateGrupoContaAgrareWhiteLabel,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/corporate/grupoContaProdutor');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateGrupoContaAgrareWhiteLabel() {
  yield takeLatest(CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL, sagaCreateGrupoContaAgrareWhiteLabel)
}
