import * as Yup from 'yup';

const newRegistro = {
    ativo: true,
    descricao: '',
    saldoInicialImplantado: false,
    saldoInicial: {
        data: '',
        valor: 0
    }
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(100, 'Campo deve ter menos do que 100 caracteres')
        .required('Este campo é obrigatório'),
    saldoInicial: Yup.object().when('saldoInicialImplantado', {
        is: (saldoInicialImplantado) => saldoInicialImplantado === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                valor: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .positive('Por Favor, informe um valor maior que zero')
                    .transform(value => (isNaN(value) ? 0.0 : value))
                    .required("Este campo é obrigatório"),
                data: Yup.date()
                .typeError('Este campo é obrigatório')
                    .required('Este campo é obrigatório'),
            })
    }).nullable(),
});

export const contaCaixaCadastro = {
    newRegistro,
    validation
}