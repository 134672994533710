export const FIELD_UNIDADE_AREA = [
    {
        id: 1,
        descricao: "Hectares",
        sigla: "ha"
    },
    {
        id: 2,
        descricao: "Alqueires (2,42 ha)",
        sigla: "alq"
    },
    {
        id: 3,
        descricao: "Acres",
        sigla: "ac"
    },
];

export function getUnidadeAreaSiglaById(id) {
    const unidade = FIELD_UNIDADE_AREA.find(item => item.id === id);
    return unidade ? unidade.sigla : "";
}