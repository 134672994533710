import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    status: true,
    formaLancPlantio: null,
    formaLancColheita: null,
    unidadeColheita: null,
    unidadeColheitaPossuiConversao: false,
    tipoEmbalagem: null,
    qtdEmbalagem: null,
    unidadeEmbalagem: null,
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(100, 'Campo deve ter menos do que 100 caracteres')
        .required('Este campo é obrigatório'),
    formaLancPlantio: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    formaLancColheita: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    unidadeColheita: Yup.object().when('formaLancColheita', {
        is: (formaLancColheita) => formaLancColheita === 'KG',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    tipoEmbalagem: Yup.object().when('formaLancColheita', {
        is: (formaLancColheita) => formaLancColheita === 'SACAS',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    qtdEmbalagem: Yup.number().when('formaLancColheita', {
        is: (formaLancColheita) => formaLancColheita === 'SACAS',
        then: Yup.number()
            .typeError('Este campo é obrigatório')
            .positive('Por Favor, informe um valor maior que zero')
            .required("Este campo é obrigatório"),
    }).nullable(),
    unidadeEmbalagem: Yup.object().when('formaLancColheita', {
        is: (formaLancColheita) => formaLancColheita === 'SACAS',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),


});

export const culturaCadastro = {
    newRegistro,
    validation
}