import { takeLatest, put, call } from 'redux-saga/effects';

import { path, history, storage, httpAuthService } from '@commons';

import { convidarUsuario } from '../service';
import {
  CREATE_USUARIO,
  CREATE_USUARIO_PENDING,
  CREATE_USUARIO_SUCCESS,
  CREATE_USUARIO_FAILURE
} from '../actionTypes';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { CONFIG_SESSION } from '@handler/common/startSession';

const register = async (values) => {
  await httpAuthService.register(values, true);
}

function* sagaCreateUsuario(action) {
  yield put({ type: CREATE_USUARIO_PENDING })

  try {
    let user = JSON.parse(storage.get(path.sessionUser));

    var newUser = {
      "name": action.data.name,
      "email": action.data.email,
      "passwordTemp": action.data.passwordTemp,
      "whatsApp": action.data.whatsApp,
      "perfil": action.data.perfil,
      "identificationCodeWhiteLabel": user.identificationCodeWhiteLabel,
    }

    yield call(register, newUser);

    var invite = {
      "nomeConta": JSON.parse(storage.get(CONFIG_SESSION)).contaAgrare.accountName,
      "email": action.data.email,
      "idPerfil": action.data.perfilPermissao.id,
    }

    yield call(convidarUsuario, invite);

    yield put({ type: CREATE_USUARIO_SUCCESS, registro: action.data });

    history.push('/seguranca/usuario');

    AlertSuccess('Usuário adicionado com sucesso!');
  } catch (error) {
    yield put({ type: CREATE_USUARIO_FAILURE, registro: action.data })

    if (error.response.status == 403) {
      AlertWarning('Sua licença atingiu o número máximo de usuários cadastrados. Para contratar mais usuários, contate o comercial da plataforma.');
    } else {
      if (action.data.tipoProcesso === 'CRIAR_CONTA') {
        AlertError('Falha ao criar conta! Verifique se o e-mail não está cadastrado em outro usuário e tente novamente...');
      } else {
        if (error.response.status === 400 && error.response.data !== undefined) {
          AlertError(error.response.data ?? 'Falha ao processar envio. Tente novamente...');
        } else {
          AlertError('Falha ao enviar convite! Verifique se o e-mail está cadastrado na plataforma e tente novamente...');
        }
      }
    }
  }

}

export default function* watchCreateUsuario() {
  yield takeLatest(CREATE_USUARIO, sagaCreateUsuario)
}
