import { takeLatest, put, call } from 'redux-saga/effects';

import { listTransferenciaEstoqueProducao } from '../service';
import {
  TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO,
  TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING,
  TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS,
  TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE
} from '../actionTypes';

function* sagaListTransferenciaEstoqueProducao(action) {
  yield put({ type: TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO_PENDING })

  try {
    const table = yield call(listTransferenciaEstoqueProducao, action.args)
    yield put({ type: TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO_SUCCESS, table: table })

  } catch (error) {

    yield put({ type: TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListTransferenciaEstoqueProducao() {
  yield takeLatest(TABLE_TRANSFERENCIA_ESTOQUE_PRODUCAO, sagaListTransferenciaEstoqueProducao)
}