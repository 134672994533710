import watchNewImplantacaoEstoqueProducao from './saga/new';
import watchFindImplantacaoEstoqueProducao from './saga/find';
import watchCreateImplantacaoEstoqueProducao from './saga/create';
import watchListAllImplantacaoEstoqueProducao from './saga/listAll';
import watchListImplantacaoEstoqueProducao from './saga/listTable';
import watchDeleteImplantacaoEstoqueProducao from './saga/delete';

export const implantacaoEstoqueProducaoSaga = [
    watchNewImplantacaoEstoqueProducao(),
    watchFindImplantacaoEstoqueProducao(),
    watchCreateImplantacaoEstoqueProducao(),
    watchListAllImplantacaoEstoqueProducao(),
    watchListImplantacaoEstoqueProducao(),
    watchDeleteImplantacaoEstoqueProducao(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
