import watchNewManejoPlanejamentoCadastro from './saga/new';
import watchFindManejoPlanejamentoCadastro from './saga/find';
import watchCreateManejoPlanejamentoCadastro from './saga/create';
import watchListManejoPlanejamentoCadastro from './saga/listTable';
import watchDeleteManejoPlanejamentoCadastro from './saga/delete';
import watchCancelManejoPlanejamentoCadastroRapido from './saga/cancel';

export const manejoPlanejamentoCadastroSaga = [
    watchNewManejoPlanejamentoCadastro(),
    watchFindManejoPlanejamentoCadastro(),
    watchCreateManejoPlanejamentoCadastro(),
    watchListManejoPlanejamentoCadastro(),
    watchDeleteManejoPlanejamentoCadastro(),
    watchCancelManejoPlanejamentoCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
