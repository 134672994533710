import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_CONTA_CAIXA_CADASTRO_RAPIDO,
    CANCEL_CONTA_CAIXA_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelContaCaixaCadastroRapido() {
  yield put({ type: CANCEL_CONTA_CAIXA_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelContaCaixaCadastroRapido() {
  yield takeLatest(CANCEL_CONTA_CAIXA_CADASTRO_RAPIDO, sagaCancelContaCaixaCadastroRapido)
}