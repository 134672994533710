import { takeLatest, put } from 'redux-saga/effects';

import {
  CANCEL_CULTURA_CADASTRO_RAPIDO,
  CANCEL_CULTURA_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelCulturaCadastroRapido() {
  yield put({ type: CANCEL_CULTURA_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelCulturaCadastroRapido() {
  yield takeLatest(CANCEL_CULTURA_CADASTRO_RAPIDO, sagaCancelCulturaCadastroRapido)
}