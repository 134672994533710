import {
    NEW_FILTER_CALENDARIO_PENDING,
    NEW_FILTER_CALENDARIO_SUCCESS,
    NEW_FILTER_CALENDARIO_FAILURE,
} from './actionTypes';


const initialState = {
    list: [],
    loading: false,
}

function calendarioReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_FILTER_CALENDARIO_PENDING:
            return {
                ...state,
                loading: true,
            }
        case NEW_FILTER_CALENDARIO_SUCCESS: {
            return {
                ...state,
                list: action.list,
                loading: false
            }
        }
        case NEW_FILTER_CALENDARIO_FAILURE: {
            return {
                ...state,
                loading: false,
                list: []
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { calendarioReducer };