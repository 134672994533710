


import watchNewVendaAnimal from './saga/new';
import watchFindVendaAnimal from './saga/find';
import watchCreateVendaAnimal from './saga/create';
import watchListAllVendaAnimal from './saga/listAll';
import watchListVendaAnimal from './saga/listTable';
import watchDeleteVendaAnimal from './saga/delete';

export const vendaAnimalSaga = [
    watchNewVendaAnimal(),
    watchFindVendaAnimal(),
    watchCreateVendaAnimal(),
    watchListAllVendaAnimal(),
    watchListVendaAnimal(),
    watchDeleteVendaAnimal(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
