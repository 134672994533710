


import watchNewTransferenciaContaBancoCaixa from './saga/new';
import watchNewFilterTransferenciaContaBancoCaixa from './saga/newFilter';
import watchFindTransferenciaContaBancoCaixa from './saga/find';
import watchCreateTransferenciaContaBancoCaixa from './saga/create';
import watchListTransferenciaContaBancoCaixa from './saga/list';
import watchDeleteTransferenciaContaBancoCaixa from './saga/delete';

export const transferenciaContaBancoCaixaSaga = [
    watchNewTransferenciaContaBancoCaixa(),
    watchNewFilterTransferenciaContaBancoCaixa(),
    watchFindTransferenciaContaBancoCaixa(),
    watchCreateTransferenciaContaBancoCaixa(),
    watchListTransferenciaContaBancoCaixa(),
    watchDeleteTransferenciaContaBancoCaixa(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
