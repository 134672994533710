import { httpService } from '@commons';


export function createCatalagoProdutoWhiteLabel(payload) {
  return httpService.postApiManager(`/catalagoProdutoCorporate`, payload);
}

export function updateCatalagoProdutoWhiteLabel(payload) {
  const { id } = payload

  return httpService.putApiManager(`/catalagoProdutoCorporate/${id}`, payload);
}

export function findCatalagoProdutoWhiteLabel(id) {
  return httpService.getApiManager(`/catalagoProdutoCorporate/${id}`, true);
}

export function listCatalagoProdutoWhiteLabel(param) {
  return httpService.getApiManager(`/catalagoProdutoCorporate/${param ?? ''}`, true);
}

export function deleteCatalagoProdutoWhiteLabel(id) {
  return httpService.delApiManager(`/catalagoProdutoCorporate/${id}`);
}