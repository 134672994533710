import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_PLANO_CONTA_CADASTRO_RAPIDO,
    CANCEL_PLANO_CONTA_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelPlanoContaCadastroRapido() {
  yield put({ type: CANCEL_PLANO_CONTA_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelPlanoContaCadastroRapido() {
  yield takeLatest(CANCEL_PLANO_CONTA_CADASTRO_RAPIDO, sagaCancelPlanoContaCadastroRapido)
}