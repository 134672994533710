import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deleteEntradaEstoque } from '../service';
import {
  DELETE_ENTRADA_ESTOQUE,
  DELETE_ENTRADA_ESTOQUE_PENDING,
  DELETE_ENTRADA_ESTOQUE_SUCCESS,
  DELETE_ENTRADA_ESTOQUE_FAILURE,
} from '../actionTypes';

import { AlertError, AlertWarning } from '@components/common/AlertToast'

function* sagaDeleteEntradaEstoque(action) {
  yield put({ type: DELETE_ENTRADA_ESTOQUE_PENDING, id: action.id })

  try {
    const a = yield call(deleteEntradaEstoque, action.id);

    if (a.status !== 204) {
      throw new Error('API delete request failed');
    }
    yield put({ type: DELETE_ENTRADA_ESTOQUE_SUCCESS, id: action.id });

    swal({
      title: 'Excluído!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });

  } catch (error) {

    yield put({ type: DELETE_ENTRADA_ESTOQUE_FAILURE });

    if (error.response.status === 403) {
      AlertWarning('Entrada não pode ser excluída, pois já houve movimentação no estoque');
    } else {
      AlertError('Falha ao excluir o registro! Tente novamente...');
    }
  }
}

export default function* watchDeleteEntradaEstoque() {
  yield takeLatest(DELETE_ENTRADA_ESTOQUE, sagaDeleteEntradaEstoque)
}
