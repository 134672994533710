import watchNewBaixaManualEstoqueProducao from './saga/new';
import watchFindBaixaManualEstoqueProducao from './saga/find';
import watchCreateBaixaManualEstoqueProducao from './saga/create';
import watchListAllBaixaManualEstoqueProducao from './saga/listAll';
import watchListBaixaManualEstoqueProducao from './saga/listTable';
import watchDeleteBaixaManualEstoqueProducao from './saga/delete';
import watchNewFilterBaixaManualEstoqueProducao from './saga/newFilter';
import watchNewFilterNextPageBaixaManualEstoqueProducao from './saga/newFilterNextPage';

export const baixaManualEstoqueProducaoSaga = [
    watchNewBaixaManualEstoqueProducao(),
    watchFindBaixaManualEstoqueProducao(),
    watchCreateBaixaManualEstoqueProducao(),
    watchListAllBaixaManualEstoqueProducao(),
    watchListBaixaManualEstoqueProducao(),
    watchDeleteBaixaManualEstoqueProducao(),
    watchNewFilterBaixaManualEstoqueProducao(),
    watchNewFilterNextPageBaixaManualEstoqueProducao(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
