


import watchNewVendaProducaoAgricola from './saga/new';
import watchFindVendaProducaoAgricola from './saga/find';
import watchCreateVendaProducaoAgricola from './saga/create';
import watchListAllVendaProducaoAgricola from './saga/listAll';
import watchListVendaProducaoAgricola from './saga/listTable';
import watchDeleteVendaProducaoAgricola from './saga/delete';

export const vendaProducaoAgricolaSaga = [
    watchNewVendaProducaoAgricola(),
    watchFindVendaProducaoAgricola(),
    watchCreateVendaProducaoAgricola(),
    watchListAllVendaProducaoAgricola(),
    watchListVendaProducaoAgricola(),
    watchDeleteVendaProducaoAgricola(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
