import * as Yup from 'yup';

const newRegistro = {
    data: '',
    estacaoMeteorologica: null,
    funcionario: null,
    precipitacao: 0,
    temperatura: 0,
    umidadeRelativa: 0,
    pressaoAtmosferica: 0,
    temperaturaMin: 0,
    temperaturaMax: 0,
    obs: ''
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    estacaoMeteorologica: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    precipitacao: Yup.number()
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
    temperatura: Yup.number()
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
    umidadeRelativa: Yup.number()
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
    pressaoAtmosferica: Yup.number()
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
    temperaturaMin: Yup.number()
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
    temperaturaMax: Yup.number()
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
    obs: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
});

export const pluviometroDigital = {
    newRegistro,
    validation
}