import {
    TOGGLE_HELP_DRAWER_SUCCESS,
    CHANGE_HELP_DRAWER_DATA_SUCCESS
} from './actionTypes';

const initialState = { isOpen: false, data: null }

function helpDrawerReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_HELP_DRAWER_SUCCESS:
            return {
                ...state,
                isOpen: !state.isOpen,
            }
        case CHANGE_HELP_DRAWER_DATA_SUCCESS:
            return {
                ...state,
                data: action.data,
            }
        default:
            return state
    }
}

export { helpDrawerReducer };
