import {
    CREATE_START_SESSION_PENDING,
    CREATE_START_SESSION_SUCCESS,
    CREATE_START_SESSION_FAILURE,
    SELECT_START_SESSION_PENDING,
    SELECT_START_SESSION_SUCCESS,
    SELECT_START_SESSION_FAILURE,
    CONFIG_SESSION,
    CREATE_NEW_ACCOUNT_PENDING,
    CREATE_NEW_ACCOUNT_SUCCESS,
    CREATE_NEW_ACCOUNT_FAILURE,
    LIST_SCHEMES_PENDING,
    LIST_SCHEMES_SUCCESS,
    LIST_SCHEMES_FAILURE
} from './actionTypes';

import { storage } from '@commons/storage';

const initialState = { list: null, loading: false, configCarregada: false }

function startSessionReducer(state = initialState, action) {

    const configSession = JSON.parse(storage.get(CONFIG_SESSION));

    switch (action.type) {
        case CREATE_START_SESSION_PENDING:
        case SELECT_START_SESSION_PENDING:
            return {
                ...state,
                configSession,
                configCarregada: false,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_START_SESSION_FAILURE:
        case SELECT_START_SESSION_SUCCESS:
        case SELECT_START_SESSION_FAILURE:
            return {
                ...state,
                configSession,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_START_SESSION_SUCCESS:
            return {
                ...state,
                configSession,
                configCarregada: true,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_NEW_ACCOUNT_PENDING:
            return {
                ...state,
                loading: true
            }
        case CREATE_NEW_ACCOUNT_SUCCESS:
        case CREATE_NEW_ACCOUNT_FAILURE:
            return {
                ...state,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_SCHEMES_PENDING:
            return {
                ...state,
                table: [],
                loading: true
            }
        case LIST_SCHEMES_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case LIST_SCHEMES_FAILURE:
            return {
                ...state,
                table: [],
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return { ...state, configSession }
    }
}

export { startSessionReducer };