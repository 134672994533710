import { httpService } from '@commons';


export function createPerfilUsuarioWhiteLabel(payload) {
  return httpService.postApiManager(`/userAuthWhiteLabelOnFarmProfile`, payload);
}

export function updatePerfilUsuarioWhiteLabel(payload) {
  const { id } = payload

  return httpService.putApiManager(`/userAuthWhiteLabelOnFarmProfile/${id}`, payload);
}

export function findPerfilUsuarioWhiteLabel(id) {
  return httpService.getApiManager(`/userAuthWhiteLabelOnFarmProfile/${id}`, true);
}

export function listPerfilUsuarioWhiteLabel(param) {
  return httpService.getApiManager(`/userAuthWhiteLabelOnFarmProfile/${param ?? ''}`, true);
}

export function novoPerfilUsuarioWhiteLabel() {
  return httpService.getApiManager(`/userAuthWhiteLabelOnFarmProfile/novo`, true);
}

export function deletePerfilUsuarioWhiteLabel(id) {
  return httpService.delApiManager(`/userAuthWhiteLabelOnFarmProfile/${id}`);
}