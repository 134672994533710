
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { httpAuthService } from '@commons';
import { path } from '@commons/path';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }


    render() {
        const { loginReducer, startSessionReducer } = this.props;

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <i className="mdi mdi-cowboy font-size-17 primary-color" style={{ marginRight: '5px' }} />
                        <span className="primary-color">
                            {startSessionReducer.configSession == null || startSessionReducer.configSession.fazendaSelecionada == null ?
                                loginReducer.user.name.split(' ').shift() + "  " :
                                loginReducer.user.name.split(' ').shift() + " - " + startSessionReducer.configSession.fazendaSelecionada.nome + "  "
                            }
                        </span>
                        <i className="fa fa-angle-down primary-color" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <React.Fragment>
                            <DropdownItem tag="a" href="/home"><i className="fa fa-home font-size-17 align-middle mr-1"></i>Página Inicial On Farm</DropdownItem>
                            <div className="dropdown-divider"></div>
                        </React.Fragment>

                        <DropdownItem tag="a" href="/minhasFazendas"><i className="mdi mdi-wallet font-size-17 align-middle mr-1"></i>Fazendas</DropdownItem>
                        <DropdownItem tag="a" href="/seguranca/usuario"><i className="mdi mdi-account-multiple font-size-17 align-middle mr-1"></i>Usuários</DropdownItem>
                        <hr />
                        <DropdownItem tag="a" href="/config"><i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>Configurações</DropdownItem>
                        {/* <DropdownItem tag="a" href="/fiscal/emitente"><i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>Configuração Fiscal</DropdownItem> */}
                        <hr />
                        {/* <DropdownItem tag="a" href="https://help.agrare.com.br" target='_blank'><i className="mdi mdi-help-circle font-size-17 align-middle mr-1"></i>Ajuda Online</DropdownItem> */}
                        <DropdownItem tag="a" href="https://forms.gle/pmP8qsG1MDYyLaCk8" target='_blank'><i className="mdi mdi-comment-question-outline font-size-17 align-middle mr-1"></i>Deixe seu Feedback</DropdownItem>
                        <DropdownItem tag="a" href="https://forms.gle/zJtvoW8VJi89Vetp9" target='_blank'><i className="mdi mdi-lightbulb font-size-17 align-middle mr-1"></i>Sugira uma Funcionalidade</DropdownItem>
                        <DropdownItem tag="a" href="https://agrare.com.br/termos" target='_blank'><i className="mdi mdi-alert font-size-17 align-middle mr-1"></i>Termos de Uso</DropdownItem>
                        <div className="dropdown-divider"></div>
                        <Link
                            to="/login"
                            onClick={() => httpAuthService.logout({ path })}
                            className="dropdown-item">
                            <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
                            <span>Sair do Sistema</span>
                        </Link>

                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export function mapStateToProps(state) {
    const { loginReducer, startSessionReducer } = state.entities;
    return { loginReducer, startSessionReducer };
};

function mapDispatchToProps(dispatch) {
    return {}
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileMenu))




