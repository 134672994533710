
import {
    NEW_PERFIL_SUCCESS,
    NEW_PERFIL_PENDING,
    TABLE_PERFIL_PENDING,
    TABLE_PERFIL_SUCCESS,
    TABLE_PERFIL_FAILURE,
    LIST_PERFIL_PENDING,
    LIST_PERFIL_SUCCESS,
    LIST_PERFIL_FAILURE,
    FIND_PERFIL_PENDING,
    FIND_PERFIL_SUCCESS,
    FIND_PERFIL_FAILURE,
    DELETE_PERFIL_PENDING,
    DELETE_PERFIL_SUCCESS,
    DELETE_PERFIL_FAILURE,
    CREATE_PERFIL_PENDING,
    CREATE_PERFIL_SUCCESS,
    CREATE_PERFIL_FAILURE,
    CANCEL_PERFIL_CADASTRO_RAPIDO_SUCCESS,
} from './actionTypes';


const initialState = { list: null, loading: false }


function perfilReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_PERFIL_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                isCadastroRapido: action.isCadastroRapido ?? false,
            }
        case LIST_PERFIL_PENDING:
        case FIND_PERFIL_PENDING:
        case DELETE_PERFIL_PENDING:
        case CREATE_PERFIL_PENDING:
        case TABLE_PERFIL_PENDING:
        case NEW_PERFIL_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CANCEL_PERFIL_CADASTRO_RAPIDO_SUCCESS:
            return {
                ...state,
                isCadastroRapido: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_PERFIL_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_PERFIL_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_PERFIL_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_PERFIL_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_PERFIL_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_PERFIL_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_PERFIL_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_PERFIL_FAILURE:
        case CREATE_PERFIL_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                isCadastroRapido: false,
            }
        case DELETE_PERFIL_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { perfilReducer };