import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createCampoCadastro, updateCampoCadastro } from '../service';
import {
  CONFIG_SESSION,
  CREATE_CAMPO_CADASTRO,
  CREATE_CAMPO_CADASTRO_PENDING,
  CREATE_CAMPO_CADASTRO_SUCCESS,
  CREATE_CAMPO_CADASTRO_FAILURE,
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateCampoCadastro(action) {
  yield put({ type: CREATE_CAMPO_CADASTRO_PENDING })

  try {

    const registro = {
      nome: action.data.nome,
      status: action.data.status,
      area: action.data.area,
      integracaoLavoura: action.data.integracaoLavoura,
      obs: action.data.obs,
      propriedade: {
        id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
      },
      realizaMapeamento: action.data.realizaMapeamento,
      mapaPolygonPath: action.data.mapaPolygonPath,
      campoTalhaoList: action.data.campoTalhaoList
    }

    if (action.data.id === undefined || action.data.id === null) {
      yield call(createCampoCadastro, registro);
    } else {
      yield call(updateCampoCadastro,
        {
          ...registro,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_CAMPO_CADASTRO_SUCCESS })

    if (!action.isCadastroRapido) {
      history.push('/pecuaria/campoCadastro');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_CAMPO_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateCampoCadastro() {
  yield takeLatest(CREATE_CAMPO_CADASTRO, sagaCreateCampoCadastro)
}
