import { takeLatest, put } from 'redux-saga/effects';

import {
  animalPelagemCadastro,
  NEW_ANIMAL_PELAGEM_CADASTRO,
  NEW_ANIMAL_PELAGEM_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewAnimalPelagemCadastro(action) {

  yield put({ 
    type: NEW_ANIMAL_PELAGEM_CADASTRO_SUCCESS, 
    registro: animalPelagemCadastro.newRegistro, 
    isCadastroRapido: action.isCadastroRapido, 
  });
}

export default function* watchNewAnimalPelagemCadastro() {
  yield takeLatest(NEW_ANIMAL_PELAGEM_CADASTRO, sagaNewAnimalPelagemCadastro)
}
