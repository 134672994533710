


import watchNewFuncionarioCadastro from './saga/new';
import watchFindFuncionarioCadastro from './saga/find';
import watchCreateFuncionarioCadastro from './saga/create';
import watchListFuncionarioCadastro from './saga/listTable';
import watchDeleteFuncionarioCadastro from './saga/delete';
import watchCalcCustoHomemHora from './saga/calcCustoHomemHora';
import watchCancelFuncionarioCadastroRapido from './saga/cancel';

export const funcionarioCadastroSaga = [
    watchNewFuncionarioCadastro(),
    watchFindFuncionarioCadastro(),
    watchCreateFuncionarioCadastro(),
    watchListFuncionarioCadastro(),
    watchDeleteFuncionarioCadastro(),
    watchCalcCustoHomemHora(),
    watchCancelFuncionarioCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
