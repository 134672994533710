export const TIPO_AREA_FAZENDA = [
    {
        id: 'AREA_ARRENDADA',
        descricao: "Área Arrendada"
    },
    {
        id: 'AREA_PROPRIA',
        descricao: "Área Própria"
    },
    {
        id: 'AREA_MISTA',
        descricao: "Área Mista"
    },
];

export function getTipoAreaFazendaDescricaoById(id) {
    const tipo = TIPO_AREA_FAZENDA.find(item => item.id === id);
    return tipo ? tipo.descricao : null;
}