import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createSafraCadastro, updateSafraCadastro, findAllSafrasByPropriedade } from '../service';

import {
  CONFIG_SESSION,
  CREATE_SAFRA_CADASTRO,
  CREATE_SAFRA_CADASTRO_PENDING,
  CREATE_SAFRA_CADASTRO_SUCCESS,
  CREATE_SAFRA_CADASTRO_FAILURE
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertWarning, AlertError } from '@components/common/AlertToast'


function* sagaCreateSafraCadastro(action) {
  yield put({ type: CREATE_SAFRA_CADASTRO_PENDING })

  try {
    if (action.data.dataFinal < action.data.dataInicial) {
      AlertWarning('Data Inicial maior que Data Final. Verifique...');
      yield put({ type: CREATE_SAFRA_CADASTRO_FAILURE, registro: action.data });
    } else {
      const registro = {
        nome: action.data.nome,
        dataInicial: action.data.dataInicial,
        dataFinal: action.data.dataFinal,
        status: action.data.status,
        cultura: action.data.cultura,
        propriedade: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
        },
        safraTalhaoList: action.data.safraTalhaoList
      }

      if (action.data.id === undefined || action.data.id === null) {
        yield call(createSafraCadastro, registro);
      } else {
        yield call(updateSafraCadastro,
          {
            ...registro,
            id: action.data.id
          }
        );
      }

      // ATUALIZA SAFRA NA SESSAO
      const safras = yield call(findAllSafrasByPropriedade, registro.propriedade.id);

      const configSessionAtual = JSON.parse(storage.get(CONFIG_SESSION));
      let safraSelecionada = null;

      if (safras.content.length === 1) {
        safraSelecionada = safras.content[0];
      } else {
        if (configSessionAtual.safraAtiva !== null) {
          if (action.data.id === undefined || action.data.id === null) {
            safraSelecionada = configSessionAtual.safraAtiva;
          } else {
            if (configSessionAtual.safraAtiva.id === action.data.id) {
              safraSelecionada = {
                ...registro,
                id: action.data.id
              };
            } else {
              safraSelecionada = configSessionAtual.safraAtiva;
            }
          }
        }
      }

      const configSession = {
        ...configSessionAtual,
        safras: safras.content,
        safraAtiva: safraSelecionada
      }

      yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));

      yield put({ type: CREATE_SAFRA_CADASTRO_SUCCESS });

      history.push('/safraCadastro');

      AlertSuccess('Registro salvo com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_SAFRA_CADASTRO_FAILURE, registro: action.data })

    if (error.response?.data?.text) {
      AlertWarning(error.response.data.text);
    } else {
      AlertError('Falha ao salvar o registro! Tente novamente...');
    }
  }

}

export default function* watchCreateSafraCadastro() {
  yield takeLatest(CREATE_SAFRA_CADASTRO, sagaCreateSafraCadastro)
}
