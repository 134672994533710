


import watchNewPatrimonioCadastro from './saga/new';
import watchFindPatrimonioCadastro from './saga/find';
import watchCreatePatrimonioCadastro from './saga/create';
import watchListPatrimonioCadastro from './saga/listTable';
import watchDeletePatrimonioCadastro from './saga/delete';
import watchCancelPatrimonioCadastroRapido from './saga/cancel';

export const patrimonioCadastroSaga = [
    watchNewPatrimonioCadastro(),
    watchFindPatrimonioCadastro(),
    watchCreatePatrimonioCadastro(),
    watchListPatrimonioCadastro(),
    watchDeletePatrimonioCadastro(),
    watchCancelPatrimonioCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
export * from './patrimonioTipoEnum';
export * from './patrimonioTipoMaquinaEnum';
