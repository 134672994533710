import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_CONTA_BANCO_CADASTRO_RAPIDO,
    CANCEL_CONTA_BANCO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelContaBancoCadastroRapido() {
  yield put({ type: CANCEL_CONTA_BANCO_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelContaBancoCadastroRapido() {
  yield takeLatest(CANCEL_CONTA_BANCO_CADASTRO_RAPIDO, sagaCancelContaBancoCadastroRapido)
}