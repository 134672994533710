import { takeLatest, put } from 'redux-saga/effects';

import {
  culturaCadastro,
  NEW_CULTURA_CADASTRO,
  NEW_CULTURA_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewCulturaCadastro(action) {

  yield put({ 
    type: NEW_CULTURA_CADASTRO_SUCCESS, 
    registro: culturaCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewCulturaCadastro() {
  yield takeLatest(NEW_CULTURA_CADASTRO, sagaNewCulturaCadastro)
}
