import { takeLatest, put, call } from 'redux-saga/effects';

import { findManejoExecucaoCadastro } from '../service';
import {
  FIND_MANEJO_EXECUCAO_CADASTRO,
  FIND_MANEJO_EXECUCAO_CADASTRO_PENDING,
  FIND_MANEJO_EXECUCAO_CADASTRO_SUCCESS,
  FIND_MANEJO_EXECUCAO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindManejoExecucaoCadastro(action) {
  yield put({ type: FIND_MANEJO_EXECUCAO_CADASTRO_PENDING, id: action.id })

  try {
    const registro = yield call(findManejoExecucaoCadastro, action.id);
    yield put({ type: FIND_MANEJO_EXECUCAO_CADASTRO_SUCCESS, registro: registro })
  } catch (error) {
    yield put({ type: FIND_MANEJO_EXECUCAO_CADASTRO_FAILURE })
  }
}

export default function* watchFindManejoExecucaoCadastro() {
  yield takeLatest(FIND_MANEJO_EXECUCAO_CADASTRO, sagaFindManejoExecucaoCadastro)
}
