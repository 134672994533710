
export const NEW_PERFIL = 'NEW_PERFIL'
export const NEW_PERFIL_PENDING = 'NEW_PERFIL_PENDING'
export const NEW_PERFIL_SUCCESS = 'NEW_PERFIL_SUCCESS'
export const NEW_PERFIL_FAILURE = 'NEW_PERFIL_FAILURE'

export const CREATE_PERFIL = 'CREATE_PERFIL'
export const CREATE_PERFIL_PENDING = 'CREATE_PERFIL_PENDING'
export const CREATE_PERFIL_SUCCESS = 'CREATE_PERFIL_SUCCESS'
export const CREATE_PERFIL_FAILURE = 'CREATE_PERFIL_FAILURE'

export const TABLE_PERFIL = 'TABLE_PERFIL'
export const TABLE_PERFIL_PENDING = 'TABLE_PERFIL_PENDING'
export const TABLE_PERFIL_SUCCESS = 'TABLE_PERFIL_SUCCESS'
export const TABLE_PERFIL_FAILURE = 'TABLE_PERFIL_FAILURE'

export const LIST_PERFIL = 'LIST_PERFIL'
export const LIST_PERFIL_PENDING = 'LIST_PERFIL_PENDING'
export const LIST_PERFIL_SUCCESS = 'LIST_PERFIL_SUCCESS'
export const LIST_PERFIL_FAILURE = 'LIST_PERFIL_FAILURE'

export const FIND_PERFIL = 'FIND_PERFIL'
export const FIND_PERFIL_PENDING = 'FIND_PERFIL_PENDING'
export const FIND_PERFIL_SUCCESS = 'FIND_PERFIL_SUCCESS'
export const FIND_PERFIL_FAILURE = 'FIND_PERFIL_FAILURE'

export const DELETE_PERFIL = 'DELETE_PERFIL'
export const DELETE_PERFIL_PENDING = 'DELETE_PERFIL_PENDING'
export const DELETE_PERFIL_SUCCESS = 'DELETE_PERFIL_SUCCESS'
export const DELETE_PERFIL_FAILURE = 'DELETE_PERFIL_FAILURE'

export const TABLE_ALL_PERFIL = 'TABLE_ALL_PERFIL'

export const CANCEL_PERFIL_CADASTRO_RAPIDO = 'CANCEL_PERFIL_CADASTRO_RAPIDO'
export const CANCEL_PERFIL_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_PERFIL_CADASTRO_RAPIDO_SUCCESS'