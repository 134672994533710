import { takeLatest, put, call } from 'redux-saga/effects';

import { findAnimalLoteCadastro } from '../service';
import {
  FIND_ANIMAL_LOTE_CADASTRO,
  FIND_ANIMAL_LOTE_CADASTRO_PENDING,
  FIND_ANIMAL_LOTE_CADASTRO_SUCCESS,
  FIND_ANIMAL_LOTE_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindAnimalLoteCadastro(action) {
  yield put({ type: FIND_ANIMAL_LOTE_CADASTRO_PENDING, id: action.id })

  try {
    const registro = yield call(findAnimalLoteCadastro, action.id);
    yield put({ type: FIND_ANIMAL_LOTE_CADASTRO_SUCCESS, registro: registro })
  } catch (error) {
    yield put({ type: FIND_ANIMAL_LOTE_CADASTRO_FAILURE })
  }
}

export default function* watchFindAnimalLoteCadastro() {
  yield takeLatest(FIND_ANIMAL_LOTE_CADASTRO, sagaFindAnimalLoteCadastro)
}
