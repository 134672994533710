import React from 'react';

import LoadingScreen from 'react-loading-screen';

export const PageLoaderScreen = (props) => {

    const { loading, textLoading } = props;

    return (
        <LoadingScreen
            loading={loading === undefined ? false : loading}
            bgColor='rgba(0, 0, 0, 0.7)'
            spinnerColor='#FFF'
            textColor='#FFF'
            text={textLoading === undefined ? 'Aguarde! Processando...' : textLoading}
        >
            <div></div>
        </LoadingScreen>
    )
}