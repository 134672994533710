export const NEW_IMPLANTACAO_ANIMAL = 'NEW_IMPLANTACAO_ANIMAL'
export const NEW_IMPLANTACAO_ANIMAL_SUCCESS = 'NEW_IMPLANTACAO_ANIMAL_SUCCESS'

export const CREATE_IMPLANTACAO_ANIMAL = 'CREATE_IMPLANTACAO_ANIMAL'
export const CREATE_IMPLANTACAO_ANIMAL_PENDING = 'CREATE_IMPLANTACAO_ANIMAL_PENDING'
export const CREATE_IMPLANTACAO_ANIMAL_SUCCESS = 'CREATE_IMPLANTACAO_ANIMAL_SUCCESS'
export const CREATE_IMPLANTACAO_ANIMAL_FAILURE = 'CREATE_IMPLANTACAO_ANIMAL_FAILURE'

export const TABLE_IMPLANTACAO_ANIMAL = 'TABLE_IMPLANTACAO_ANIMAL'
export const TABLE_IMPLANTACAO_ANIMAL_PENDING = 'TABLE_IMPLANTACAO_ANIMAL_PENDING'
export const TABLE_IMPLANTACAO_ANIMAL_SUCCESS = 'TABLE_IMPLANTACAO_ANIMAL_SUCCESS'
export const TABLE_IMPLANTACAO_ANIMAL_FAILURE = 'TABLE_IMPLANTACAO_ANIMAL_FAILURE'

export const LIST_IMPLANTACAO_ANIMAL = 'LIST_IMPLANTACAO_ANIMAL'
export const LIST_IMPLANTACAO_ANIMAL_PENDING = 'LIST_IMPLANTACAO_ANIMAL_PENDING'
export const LIST_IMPLANTACAO_ANIMAL_SUCCESS = 'LIST_IMPLANTACAO_ANIMAL_SUCCESS'
export const LIST_IMPLANTACAO_ANIMAL_FAILURE = 'LIST_IMPLANTACAO_ANIMAL_FAILURE'

export const FIND_IMPLANTACAO_ANIMAL = 'FIND_IMPLANTACAO_ANIMAL'
export const FIND_IMPLANTACAO_ANIMAL_PENDING = 'FIND_IMPLANTACAO_ANIMAL_PENDING'
export const FIND_IMPLANTACAO_ANIMAL_SUCCESS = 'FIND_IMPLANTACAO_ANIMAL_SUCCESS'
export const FIND_IMPLANTACAO_ANIMAL_FAILURE = 'FIND_IMPLANTACAO_ANIMAL_FAILURE'

export const DELETE_IMPLANTACAO_ANIMAL = 'DELETE_IMPLANTACAO_ANIMAL'
export const DELETE_IMPLANTACAO_ANIMAL_PENDING = 'DELETE_IMPLANTACAO_ANIMAL_PENDING'
export const DELETE_IMPLANTACAO_ANIMAL_SUCCESS = 'DELETE_IMPLANTACAO_ANIMAL_SUCCESS'
export const DELETE_IMPLANTACAO_ANIMAL_FAILURE = 'DELETE_IMPLANTACAO_ANIMAL_FAILURE'