import * as Yup from 'yup';

const newRegistro = {
    data: '',
    procedimento: null,
    descricaoManejo: '',
    nroAnimais: null,
    custoTotalProdutosInsumos: 0,
    custoTotalFuncionarios: 0,
    custoTotal: 0,
    utilizaProdutoInsumo: true,
    utilizaFuncionario: true,
    utilizaAnimal: true,
    animais: [],
    produtos: [],
    funcionarios: [],
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    procedimento: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
});

export const manejoExecucaoCadastro = {
    newRegistro,
    validation
}