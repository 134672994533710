import React from 'react';
import {
    Button
} from 'reactstrap';
import { formatOnlyNumber } from './format';

export function CallWhatsApp(number) {
    if (number === null || number === undefined) {
        return <React.Fragment />
    } else {
        const onlyNumber = formatOnlyNumber(number);
        if (onlyNumber.length >= 10) {
            return <Button className="btn btn-outline-secondary" data-toggle="tooltip" data-placement="bottom" title="Enviar mensagem WhatsApp" onClick={() => {
                window.open(`https://wa.me/55${onlyNumber.replace(/\D/g, '')}`, "_blank");
            }} style={{zIndex: '0'}}>
                <i className='fab fa-whatsapp' style={{ color: 'white' }} />
            </Button>
        }
        return <React.Fragment />
    }
};