import { takeLatest, put, call } from 'redux-saga/effects';

import { history, storage } from '@commons';

import { createPropriedadeCadastro, updatePropriedadeCadastro } from '../service';
import {
  CREATE_PROPRIEDADE_CADASTRO,
  CREATE_PROPRIEDADE_CADASTRO_PENDING,
  CREATE_PROPRIEDADE_CADASTRO_SUCCESS,
  CREATE_PROPRIEDADE_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { CONFIG_SESSION } from '@handler/common/startSession';
import { findAllFazendaCadastro } from '@handler/common/fazendaCadastro';

function* sagaCreatePropriedadeCadastro(action) {
  yield put({ type: CREATE_PROPRIEDADE_CADASTRO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createPropriedadeCadastro, action.data);
    } else {
      yield call(updatePropriedadeCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );

      // ATUALIZA SAFRA NA SESSAO
      const fazendas = yield call(findAllFazendaCadastro);

      const configSessionAtual = JSON.parse(storage.get(CONFIG_SESSION));

      if (fazendas.length === 1 || configSessionAtual.fazendaSelecionada.id === action.data.id) {
        const configSession = {
          ...configSessionAtual,
          fazendaSelecionada: {
            ...action.data,
            id: action.data.id
          }
        }

        yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));
      }
    }

    yield put({ type: CREATE_PROPRIEDADE_CADASTRO_SUCCESS, registro: action.data })

    history.push('/minhasFazendas');

    AlertSuccess('Registro salvo com sucesso!');
  } catch (error) {
    yield put({ type: CREATE_PROPRIEDADE_CADASTRO_FAILURE, registro: action.data })

    if (error.response.status == 422) {
      AlertWarning(error.response.data.text);
    } else {
      AlertError('Falha ao salvar o registro! Tente novamente...');
    }
  }

}

export default function* watchCreatePropriedadeCadastro() {
  yield takeLatest(CREATE_PROPRIEDADE_CADASTRO, sagaCreatePropriedadeCadastro)
}
