import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/agricultura/estoque/baixaManual' };
/////////////////////////////////////////////////////////////////////////////////

export function createBaixaManualEstoqueProducao(payload) {
  return httpService.post(args, payload);
}

export function updateBaixaManualEstoqueProducao(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listBaixaManualEstoqueProducaoFilter(idPropriedade, idSafraAtiva, datas, where) {
  return httpService.get({ ...args, args: `?propriedade=${idPropriedade}&safra=${idSafraAtiva}&datas=${datas}${where}` });
}

export function listBaixaManualEstoqueProducao(param) {
  return httpService.get({ ...args, args: param });
}

export function findBaixaManualEstoqueProducao(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllBaixaManualEstoqueProducao() {
  return httpService.get({ ...args, path: '/agricultura/estoque/baixaManual/all' });
}

export function deleteBaixaManualEstoqueProducao(id) {
  return httpService.del({ ...args, args: `/${id}` });
}
