import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/patrimonio' };
/////////////////////////////////////////////////////////////////////////////////

export function createPatrimonioCadastro(payload) {
  return httpService.post(args, payload);
}

export function updatePatrimonioCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listPatrimonioCadastro(param) {
  return httpService.get({...args, args: param});
}

export function listPatrimonioMaquinario() {
  return httpService.get({...args, args: '/maquinarios'});
}

export function listEstacaoMeteorologica() {
  return httpService.get({...args, args: '/estacaoMeteorologica'});
}

export function findPatrimonioCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deletePatrimonioCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

