import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createTransferenciaAnimal, updateTransferenciaAnimal } from '../service';
import {
  CREATE_TRANSFERENCIA_ANIMAL,
  CREATE_TRANSFERENCIA_ANIMAL_PENDING,
  CREATE_TRANSFERENCIA_ANIMAL_SUCCESS,
  CREATE_TRANSFERENCIA_ANIMAL_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { formatDateDDMMYYYY, isDateValidVerifyDateBetween } from '@components/common/format'

function* sagaCreateTransferenciaAnimal(action) {
  yield put({ type: CREATE_TRANSFERENCIA_ANIMAL_PENDING })

  try {
    const anoPecuarioAtivo = JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo;
    var dateFrom = anoPecuarioAtivo.dataInicial;
    var dateTo = anoPecuarioAtivo.dataFinal;
    var dateCheck = action.data.data;

    if (!isDateValidVerifyDateBetween(dateFrom, dateTo, dateCheck)) {
      yield put({ type: CREATE_TRANSFERENCIA_ANIMAL_FAILURE, registro: action.data })

      AlertWarning('Data informada fora do período do ano pecuário ativo. Período deve ser entre '
        + formatDateDDMMYYYY(dateFrom) + ' até ' + formatDateDDMMYYYY(dateTo) + '!');
    } else {
      if (action.data.id === undefined || action.data.id === null) {
        yield call(createTransferenciaAnimal, {
          ...action.data,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          },
        });
      } else {
        yield call(updateTransferenciaAnimal,
          {
            ...action.data,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_TRANSFERENCIA_ANIMAL_SUCCESS, registro: action.data })

      history.push('/pecuaria/transferencia');

      AlertSuccess('Transferência registrada com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_TRANSFERENCIA_ANIMAL_FAILURE, registro: action.data })

    AlertError('Falha ao salvar a transferência! Tente novamente...');
  }
}

export default function* watchCreateTransferenciaAnimal() {
  yield takeLatest(CREATE_TRANSFERENCIA_ANIMAL, sagaCreateTransferenciaAnimal)
}
