import { takeLatest, put, call } from 'redux-saga/effects';

import { findEntradaEstoque } from '../service';
import {
  FIND_ENTRADA_ESTOQUE,
  FIND_ENTRADA_ESTOQUE_PENDING,
  FIND_ENTRADA_ESTOQUE_SUCCESS,
  FIND_ENTRADA_ESTOQUE_FAILURE
} from '../actionTypes';

function* sagaFindEntradaEstoque(action) {
  yield put({ type: FIND_ENTRADA_ESTOQUE_PENDING, id: action.id })

  try {

    const registro = yield call(findEntradaEstoque, action.id);

    yield put({ type: FIND_ENTRADA_ESTOQUE_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_ENTRADA_ESTOQUE_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindEntradaEstoque() {
  yield takeLatest(FIND_ENTRADA_ESTOQUE, sagaFindEntradaEstoque)
}
