import { takeLatest, put, call } from 'redux-saga/effects';

import { findContaPagarRecorrente } from '../service';
import {
  FIND_CONTA_PAGAR_RECORRENTE,
  FIND_CONTA_PAGAR_RECORRENTE_PENDING,
  FIND_CONTA_PAGAR_RECORRENTE_SUCCESS,
  FIND_CONTA_PAGAR_RECORRENTE_FAILURE
} from '../actionTypes';

function* sagaFindContaPagarRecorrente(action) {
  yield put({ type: FIND_CONTA_PAGAR_RECORRENTE_PENDING, id: action.id })

  try {
    const registro = yield call(findContaPagarRecorrente, action.id);
    yield put({
      type: FIND_CONTA_PAGAR_RECORRENTE_SUCCESS, registro: {
        ...registro,
        gerarProximaFaturaAgora: true
      }
    })
  } catch (error) {
    yield put({ type: FIND_CONTA_PAGAR_RECORRENTE_FAILURE });
  }
}

export default function* watchFindContaPagarRecorrente() {
  yield takeLatest(FIND_CONTA_PAGAR_RECORRENTE, sagaFindContaPagarRecorrente)
}
