import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/conta/banco' };
/////////////////////////////////////////////////////////////////////////////////

export function createContaBancoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateContaBancoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listContaBancoCadastro(param) {
  return httpService.get({ ...args, args: param });
}

export function findContaBancoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllContaBancoCadastro(propriedade) {
  return httpService.get({ ...args, path: `/conta/banco/all/${propriedade}` });
}

export function deleteContaBancoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

