


import watchNewContaReceber from './saga/new';
import watchNewFilterContaReceber from './saga/newFilter';
import watchFindContaReceber from './saga/find';
import watchFindPagamentos from './saga/findPagametos';
import watchCreateContaReceber from './saga/create';
import watchNewFilterNextPageContaReceber from './saga/newFilterNextPage';
import watchDeleteContaReceber from './saga/delete';
import watchEstornarPagamentoContaReceber from './saga/estornarPagamento';

export const contaReceberSaga = [
    watchNewContaReceber(),
    watchNewFilterContaReceber(),
    watchFindContaReceber(),
    watchFindPagamentos(),
    watchCreateContaReceber(),
    watchNewFilterNextPageContaReceber(),
    watchDeleteContaReceber(),
    watchEstornarPagamentoContaReceber(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
