import { takeLatest, put, call } from 'redux-saga/effects';

import { updateConfigGeral } from '../service';
import {
  UPDATE_CONFIG_GERAL,
  UPDATE_CONFIG_GERAL_PENDING,
  UPDATE_CONFIG_GERAL_SUCCESS,
  UPDATE_CONFIG_GERAL_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaUpdateConfigGeral(action) {
  yield put({ type: UPDATE_CONFIG_GERAL_PENDING })

  try {
    yield call(updateConfigGeral, action.data)

    yield put({ type: UPDATE_CONFIG_GERAL_SUCCESS, registro: action.data })

    AlertSuccess('Configuração atualizada com sucesso!');

  } catch (error) {
    yield put({ type: UPDATE_CONFIG_GERAL_FAILURE, registro: action.data })

    AlertError('Falha ao atualizar configuração! Tente novamente...');
  }

}

export default function* watchUpdateConfigGeral() {
  yield takeLatest(UPDATE_CONFIG_GERAL, sagaUpdateConfigGeral)
}
