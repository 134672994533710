import { takeLatest, put, call } from 'redux-saga/effects';

import { getLancamentos, getLancamentosLista, getLancamentosTotaisEntradaOuSaida, getRelatorioControleBanco } from '../service';
import { storage } from '@commons/storage';
import {
  LIST_RELATORIO_MOVIMENTACAO_ANIMAL,
  LIST_RELATORIO_MOVIMENTACAO_ANIMAL_PENDING,
  LIST_RELATORIO_MOVIMENTACAO_ANIMAL_SUCCESS,
  LIST_RELATORIO_MOVIMENTACAO_ANIMAL_FAILURE
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';

function* sagaListRelatorioMovimentacaoAnimal(action) {
  try {
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    let idAnoPecuario = -1;
    if (JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo !== undefined && JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo !== null) {
      idAnoPecuario = JSON.parse(storage.get(CONFIG_SESSION)).anoPecuarioAtivo.id;
    }

    // typePeriodo = 0 -> periodo manual
    // typePeriodo = 1 -> hoje
    // typePeriodo = 2 -> esta semana
    // typePeriodo = 3 -> mes passado
    // typePeriodo = 4 -> este mes
    // typePeriodo = 5 -> proximo mes
    // typePeriodo = 6 -> este ano
    let datas = '';
    let dataInicial = '';
    let dataFinal = '';
    var date = new Date();
    if (action.typePeriodo === 0) {
      dataInicial = action.args.dataInicial;
      dataFinal = action.args.dataFinal;

      datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
    } else if (action.typePeriodo === 1) {
      dataInicial = formatDateYYYYMMDD(new Date(date));
      dataFinal = formatDateYYYYMMDD(new Date(date));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
    }
    else if (action.typePeriodo === 2) {
      const primeiro = date.getDate() - date.getDay();

      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
    } else if (action.typePeriodo === 3) {
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
    } else if (action.typePeriodo === 4) {
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
    } else if (action.typePeriodo === 5) {
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
    } else if (action.typePeriodo === 6) {
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
    }


    if (action.pdf === false) {
      yield put({ type: LIST_RELATORIO_MOVIMENTACAO_ANIMAL_PENDING })

      const list = yield call(getLancamentosLista, idFazenda, idAnoPecuario, datas)

      let totalNroAnimais = 0;
      let totalPeso = 0;

      if (list !== null && list.length > 0) {
        totalNroAnimais = list[list.length - 1].quantidadeTotal;
        totalPeso = list[list.length - 1].pesoTotal;
      }

      yield put({
        type: LIST_RELATORIO_MOVIMENTACAO_ANIMAL_SUCCESS, list: list, newFilter: {
          dataInicial,
          dataFinal,
          totalNroAnimais,
          totalPeso,
        }
      })
    } else {
      // yield call(getRelatorioControleBanco, idFazenda, action.args.contaBanco.id, datas)
    }
  } catch (error) {

    yield put({ type: LIST_RELATORIO_MOVIMENTACAO_ANIMAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListRelatorioMovimentacaoAnimal() {
  yield takeLatest(LIST_RELATORIO_MOVIMENTACAO_ANIMAL, sagaListRelatorioMovimentacaoAnimal)
}