import {
    NEW_TRANSF_CONTA_BANCO_CAIXA_SUCCESS,
    LIST_TRANSF_CONTA_BANCO_CAIXA_PENDING,
    LIST_TRANSF_CONTA_BANCO_CAIXA_SUCCESS,
    LIST_TRANSF_CONTA_BANCO_CAIXA_FAILURE,
    FIND_TRANSF_CONTA_BANCO_CAIXA_PENDING,
    FIND_TRANSF_CONTA_BANCO_CAIXA_SUCCESS,
    FIND_TRANSF_CONTA_BANCO_CAIXA_FAILURE,
    DELETE_TRANSF_CONTA_BANCO_CAIXA_PENDING,
    DELETE_TRANSF_CONTA_BANCO_CAIXA_SUCCESS,
    DELETE_TRANSF_CONTA_BANCO_CAIXA_FAILURE,
    CREATE_TRANSF_CONTA_BANCO_CAIXA_PENDING,
    CREATE_TRANSF_CONTA_BANCO_CAIXA_SUCCESS,
    CREATE_TRANSF_CONTA_BANCO_CAIXA_FAILURE,
    NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA_PEDING,
    NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA_SUCCESS,
} from './actionTypes';


const initialState = { list: null, loading: false }


function transferenciaContaBancoCaixaReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_TRANSF_CONTA_BANCO_CAIXA_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case LIST_TRANSF_CONTA_BANCO_CAIXA_PENDING:
        case FIND_TRANSF_CONTA_BANCO_CAIXA_PENDING:
        case DELETE_TRANSF_CONTA_BANCO_CAIXA_PENDING:
        case CREATE_TRANSF_CONTA_BANCO_CAIXA_PENDING:
        case NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA_PEDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_TRANSF_CONTA_BANCO_CAIXA_SUCCESS:
            return {
                ...state,
                list: action.list,
                newFilter: action.newFilter,
                loading: false
            }
        case LIST_TRANSF_CONTA_BANCO_CAIXA_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_TRANSF_CONTA_BANCO_CAIXA_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_TRANSF_CONTA_BANCO_CAIXA_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_TRANSF_CONTA_BANCO_CAIXA_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_TRANSF_CONTA_BANCO_CAIXA_SUCCESS: {
            return {
                ...state,
                newFilter: action.newFilter,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_TRANSF_CONTA_BANCO_CAIXA_SUCCESS:
        case CREATE_TRANSF_CONTA_BANCO_CAIXA_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case DELETE_TRANSF_CONTA_BANCO_CAIXA_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { transferenciaContaBancoCaixaReducer };