


import watchUpdateNroDoc from './saga/updateNroDoc';

export const contaPagarReceberAbsSaga = [
    watchUpdateNroDoc(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
