
export const NEW_ESTAGIO_APLICACAO_CADASTRO = 'NEW_ESTAGIO_APLICACAO_CADASTRO';
export const NEW_ESTAGIO_APLICACAO_CADASTRO_SUCCESS = 'NEW_ESTAGIO_APLICACAO_CADASTRO_SUCCESS';

export const CREATE_ESTAGIO_APLICACAO_CADASTRO = 'CREATE_ESTAGIO_APLICACAO_CADASTRO';
export const CREATE_ESTAGIO_APLICACAO_CADASTRO_PENDING = 'CREATE_ESTAGIO_APLICACAO_CADASTRO_PENDING';
export const CREATE_ESTAGIO_APLICACAO_CADASTRO_SUCCESS = 'CREATE_ESTAGIO_APLICACAO_CADASTRO_SUCCESS';
export const CREATE_ESTAGIO_APLICACAO_CADASTRO_FAILURE = 'CREATE_ESTAGIO_APLICACAO_CADASTRO_FAILURE';

export const CANCEL_ESTAGIO_APLICACAO_CADASTRO_RAPIDO = 'CANCEL_ESTAGIO_APLICACAO_CADASTRO_RAPIDO';
export const CANCEL_ESTAGIO_APLICACAO_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_ESTAGIO_APLICACAO_CADASTRO_RAPIDO_SUCCESS';

export const TABLE_ESTAGIO_APLICACAO_CADASTRO = 'TABLE_ESTAGIO_APLICACAO_CADASTRO';
export const TABLE_ESTAGIO_APLICACAO_CADASTRO_PENDING = 'TABLE_ESTAGIO_APLICACAO_CADASTRO_PENDING';
export const TABLE_ESTAGIO_APLICACAO_CADASTRO_SUCCESS = 'TABLE_ESTAGIO_APLICACAO_CADASTRO_SUCCESS';
export const TABLE_ESTAGIO_APLICACAO_CADASTRO_FAILURE = 'TABLE_ESTAGIO_APLICACAO_CADASTRO_FAILURE';

export const LIST_ESTAGIO_APLICACAO_CADASTRO = 'LIST_ESTAGIO_APLICACAO_CADASTRO';
export const LIST_ESTAGIO_APLICACAO_CADASTRO_PENDING = 'LIST_ESTAGIO_APLICACAO_CADASTRO_PENDING';
export const LIST_ESTAGIO_APLICACAO_CADASTRO_SUCCESS = 'LIST_ESTAGIO_APLICACAO_CADASTRO_SUCCESS';
export const LIST_ESTAGIO_APLICACAO_CADASTRO_FAILURE = 'LIST_ESTAGIO_APLICACAO_CADASTRO_FAILURE';

export const FIND_ESTAGIO_APLICACAO_CADASTRO = 'FIND_ESTAGIO_APLICACAO_CADASTRO';
export const FIND_ESTAGIO_APLICACAO_CADASTRO_PENDING = 'FIND_ESTAGIO_APLICACAO_CADASTRO_PENDING';
export const FIND_ESTAGIO_APLICACAO_CADASTRO_SUCCESS = 'FIND_ESTAGIO_APLICACAO_CADASTRO_SUCCESS';
export const FIND_ESTAGIO_APLICACAO_CADASTRO_FAILURE = 'FIND_ESTAGIO_APLICACAO_CADASTRO_FAILURE';

export const DELETE_ESTAGIO_APLICACAO_CADASTRO = 'DELETE_ESTAGIO_APLICACAO_CADASTRO';
export const DELETE_ESTAGIO_APLICACAO_CADASTRO_PENDING = 'DELETE_ESTAGIO_APLICACAO_CADASTRO_PENDING';
export const DELETE_ESTAGIO_APLICACAO_CADASTRO_SUCCESS = 'DELETE_ESTAGIO_APLICACAO_CADASTRO_SUCCESS';
export const DELETE_ESTAGIO_APLICACAO_CADASTRO_FAILURE = 'DELETE_ESTAGIO_APLICACAO_CADASTRO_FAILURE';
