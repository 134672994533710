import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    descricaoGeral: '',
    status: true,
    formulacao: '',
    codigoEmpresa: '',
    grupo: null,
    dosagemHa: 0,
    controlePorEmbalagem: false,
    unidade: null,
    precoVenda: 0,
    ultimaAtualizacao: null,
    embalagens: [],
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(120, 'Campo deve ter menos do que 120 caracteres')
        .required('Este campo é obrigatório'),
    descricaoGeral: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(5000, 'Campo deve ter menos do que 5000 caracteres'),
    formulacao: Yup.string()
        .max(250, 'Campo deve ter menos do que 5000 caracteres'),
    codigoEmpresa: Yup.string()
        .max(60, 'Campo deve ter menos do que 60 caracteres'),
    grupo: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    unidade: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    // unidade: Yup.object().when('controlePorEmbalagem', {
    //     is: (controlePorEmbalagem) => controlePorEmbalagem === false,
    //     then: Yup.object()
    //         .typeError('Este campo é obrigatório')
    //         .shape({
    //             id: Yup.number()
    //                 .typeError('Este campo é obrigatório')
    //                 .required("Este campo é obrigatório"),
    //         })
    // }).nullable(),
    precoVenda: Yup.number()
        .typeError('Este campo é obrigatório')
        .min(0, 'Por Favor, informe um valor válido'),
    embalagens: Yup.array()
        .of(
            Yup.object().shape({
                // unidade: Yup.object()
                //     .typeError('Este campo é obrigatório')
                //     .shape({
                //         id: Yup.number()
                //             .typeError('Este campo é obrigatório')
                //             .required("Este campo é obrigatório"),
                //     }),
                tipoEmbalagem: Yup.object()
                    .typeError('Este campo é obrigatório')
                    .shape({
                        id: Yup.number()
                            .typeError('Este campo é obrigatório')
                            .required("Este campo é obrigatório"),
                    }),
                qtdeEmbalagem: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .positive('Por Favor, informe um valor maior que zero')
                    .required("Este campo é obrigatório"),
                precoVenda: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .min(0, 'Por Favor, informe um valor válido'),
                codigoEmpresa: Yup.string()
                    .max(60, 'Campo deve ter menos do que 60 caracteres'),
            }),
        ),
});

export const catalagoProdutoWhiteLabel = {
    newRegistro,
    validation
}