
export const NEW_CAMPO_CADASTRO = 'NEW_CAMPO_CADASTRO'
export const NEW_CAMPO_CADASTRO_PENDING = 'NEW_CAMPO_CADASTRO_PENDING'
export const NEW_CAMPO_CADASTRO_SUCCESS = 'NEW_CAMPO_CADASTRO_SUCCESS'
export const NEW_CAMPO_CADASTRO_FAILURE = 'NEW_CAMPO_CADASTRO_FAILURE'

export const CREATE_CAMPO_CADASTRO = 'CREATE_CAMPO_CADASTRO'
export const CREATE_CAMPO_CADASTRO_PENDING = 'CREATE_CAMPO_CADASTRO_PENDING'
export const CREATE_CAMPO_CADASTRO_SUCCESS = 'CREATE_CAMPO_CADASTRO_SUCCESS'
export const CREATE_CAMPO_CADASTRO_FAILURE = 'CREATE_CAMPO_CADASTRO_FAILURE'

export const CANCEL_CAMPO_CADASTRO_RAPIDO = 'CANCEL_CAMPO_CADASTRO_RAPIDO'
export const CANCEL_CAMPO_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_CAMPO_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_CAMPO_CADASTRO = 'TABLE_CAMPO_CADASTRO'
export const TABLE_CAMPO_CADASTRO_PENDING = 'TABLE_CAMPO_CADASTRO_PENDING'
export const TABLE_CAMPO_CADASTRO_SUCCESS = 'TABLE_CAMPO_CADASTRO_SUCCESS'
export const TABLE_CAMPO_CADASTRO_FAILURE = 'TABLE_CAMPO_CADASTRO_FAILURE'

export const LIST_CAMPO_CADASTRO = 'LIST_CAMPO_CADASTRO'
export const LIST_CAMPO_CADASTRO_PENDING = 'LIST_CAMPO_CADASTRO_PENDING'
export const LIST_CAMPO_CADASTRO_SUCCESS = 'LIST_CAMPO_CADASTRO_SUCCESS'
export const LIST_CAMPO_CADASTRO_FAILURE = 'LIST_CAMPO_CADASTRO_FAILURE'

export const FIND_CAMPO_CADASTRO = 'FIND_CAMPO_CADASTRO'
export const FIND_CAMPO_CADASTRO_PENDING = 'FIND_CAMPO_CADASTRO_PENDING'
export const FIND_CAMPO_CADASTRO_SUCCESS = 'FIND_CAMPO_CADASTRO_SUCCESS'
export const FIND_CAMPO_CADASTRO_FAILURE = 'FIND_CAMPO_CADASTRO_FAILURE'

export const DELETE_CAMPO_CADASTRO = 'DELETE_CAMPO_CADASTRO'
export const DELETE_CAMPO_CADASTRO_PENDING = 'DELETE_CAMPO_CADASTRO_PENDING'
export const DELETE_CAMPO_CADASTRO_SUCCESS = 'DELETE_CAMPO_CADASTRO_SUCCESS'
export const DELETE_CAMPO_CADASTRO_FAILURE = 'DELETE_CAMPO_CADASTRO_FAILURE'
