import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    status: true,
    area: '',
    obs: '',
    realizaMapeamento: false,
    mapaPolygonPath: null,
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(255, 'Campo deve ter menos do que 255 caracteres')
        .required('Este campo é obrigatório'),
    area: Yup.number()
        .positive('Por Favor, informe um valor maior que zero')
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
    obs: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
});

export const talhaoCadastro = {
    newRegistro,
    validation
}