import watchNewEmitenteCadastro from './saga/new';
import watchFindEmitenteCadastro from './saga/find';
import watchCreateEmitenteCadastro from './saga/create';
import watchListAllEmitenteCadastro from './saga/listAll';
import watchListEmitenteCadastro from './saga/listTable';
import watchDeleteEmitenteCadastro from './saga/delete';
import watchCancelEmitenteCadastroRapido from './saga/cancel';

export const emitenteCadastroSaga = [
    watchNewEmitenteCadastro(),
    watchFindEmitenteCadastro(),
    watchCreateEmitenteCadastro(),
    watchListAllEmitenteCadastro(),
    watchListEmitenteCadastro(),
    watchDeleteEmitenteCadastro(),
    watchCancelEmitenteCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
