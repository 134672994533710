import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    status: true,
    area: '',
    integracaoLavoura: false,
    obs: '',
    realizaMapeamento: false,
    mapaPolygonPath: null,
    talhoes: [],
    allTalhoes: false
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(100, 'Campo deve ter menos do que 100 caracteres')
        .required('Este campo é obrigatório'),
    area: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
});

export const campoCadastro = {
    newRegistro,
    validation
}