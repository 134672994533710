import { takeLatest, put, call } from 'redux-saga/effects';

import { findTransferenciaContaBancoCaixa } from '../service';
import {
  FIND_TRANSF_CONTA_BANCO_CAIXA,
  FIND_TRANSF_CONTA_BANCO_CAIXA_PENDING,
  FIND_TRANSF_CONTA_BANCO_CAIXA_SUCCESS,
  FIND_TRANSF_CONTA_BANCO_CAIXA_FAILURE
} from '../actionTypes';
import { history } from '@commons';

function* sagaFindTransferenciaContaBancoCaixa(action) {
  yield put({ type: FIND_TRANSF_CONTA_BANCO_CAIXA_PENDING, id: action.id })

  try {
    const registro = yield call(findTransferenciaContaBancoCaixa, action.id);

    yield put({ type: FIND_TRANSF_CONTA_BANCO_CAIXA_SUCCESS, registro: registro })

    history.push('/financeiro/transferenciaContaBancoCaixa/editar/' + registro.id);
  } catch (error) {

    yield put({ type: FIND_TRANSF_CONTA_BANCO_CAIXA_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindTransferenciaContaBancoCaixa() {
  yield takeLatest(FIND_TRANSF_CONTA_BANCO_CAIXA, sagaFindTransferenciaContaBancoCaixa)
}
