import { takeLatest, put, call } from 'redux-saga/effects';

import { listUsuario } from '../service';
import {
  TABLE_USUARIO,
  TABLE_USUARIO_PENDING,
  TABLE_USUARIO_SUCCESS,
  TABLE_USUARIO_FAILURE
} from '../actionTypes';

function* sagaListUsuario(action) {
  yield put({ type: TABLE_USUARIO_PENDING })

  try {
    const table = yield call(listUsuario, action.args)
    
    yield put({ type: TABLE_USUARIO_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_USUARIO_FAILURE })
  }
}

export default function* watchListUsuario() {
  yield takeLatest(TABLE_USUARIO, sagaListUsuario)
}