import { httpService } from '@commons';


export function createPartnerWhiteLabel(payload) {
  return httpService.postApiManager(`/partnerWhiteLabel/new`, payload);
}

export function updatePartnerWhiteLabel(payload) {
  const { id } = payload

  return httpService.putApiManager(`/partnerWhiteLabel/${id}`, payload);
}

export function findPartnerWhiteLabel(id) {
  return httpService.getApiManager(`/partnerWhiteLabel/${id}`, true);
}

export function listPartnerWhiteLabel(param) {
  return httpService.getApiManager(`/partnerWhiteLabel/${param ?? ''}`, true);
}

export function deletePartnerWhiteLabel(id) {
  return httpService.delApiManager(`/partnerWhiteLabel/${id}`);
}