
export const NEW_TRANSPORTADORA_CADASTRO = 'NEW_TRANSPORTADORA_CADASTRO'
export const NEW_TRANSPORTADORA_CADASTRO_SUCCESS = 'NEW_TRANSPORTADORA_CADASTRO_SUCCESS'

export const CREATE_TRANSPORTADORA_CADASTRO = 'CREATE_TRANSPORTADORA_CADASTRO'
export const CREATE_TRANSPORTADORA_CADASTRO_PENDING = 'CREATE_TRANSPORTADORA_CADASTRO_PENDING'
export const CREATE_TRANSPORTADORA_CADASTRO_SUCCESS = 'CREATE_TRANSPORTADORA_CADASTRO_SUCCESS'
export const CREATE_TRANSPORTADORA_CADASTRO_FAILURE = 'CREATE_TRANSPORTADORA_CADASTRO_FAILURE'

export const CANCEL_TRANSPORTADORA_CADASTRO_RAPIDO = 'CANCEL_TRANSPORTADORA_CADASTRO_RAPIDO'
export const CANCEL_TRANSPORTADORA_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_TRANSPORTADORA_CADASTRO_RAPIDO_SUCCESS'

export const TABLE_TRANSPORTADORA_CADASTRO = 'TABLE_TRANSPORTADORA_CADASTRO'
export const TABLE_TRANSPORTADORA_CADASTRO_PENDING = 'TABLE_TRANSPORTADORA_CADASTRO_PENDING'
export const TABLE_TRANSPORTADORA_CADASTRO_SUCCESS = 'TABLE_TRANSPORTADORA_CADASTRO_SUCCESS'
export const TABLE_TRANSPORTADORA_CADASTRO_FAILURE = 'TABLE_TRANSPORTADORA_CADASTRO_FAILURE'

export const LIST_TRANSPORTADORA_CADASTRO = 'LIST_TRANSPORTADORA_CADASTRO'
export const LIST_TRANSPORTADORA_CADASTRO_PENDING = 'LIST_TRANSPORTADORA_CADASTRO_PENDING'
export const LIST_TRANSPORTADORA_CADASTRO_SUCCESS = 'LIST_TRANSPORTADORA_CADASTRO_SUCCESS'
export const LIST_TRANSPORTADORA_CADASTRO_FAILURE = 'LIST_TRANSPORTADORA_CADASTRO_FAILURE'

export const FIND_TRANSPORTADORA_CADASTRO = 'FIND_TRANSPORTADORA_CADASTRO'
export const FIND_TRANSPORTADORA_CADASTRO_PENDING = 'FIND_TRANSPORTADORA_CADASTRO_PENDING'
export const FIND_TRANSPORTADORA_CADASTRO_SUCCESS = 'FIND_TRANSPORTADORA_CADASTRO_SUCCESS'
export const FIND_TRANSPORTADORA_CADASTRO_FAILURE = 'FIND_TRANSPORTADORA_CADASTRO_FAILURE'

export const DELETE_TRANSPORTADORA_CADASTRO = 'DELETE_TRANSPORTADORA_CADASTRO'
export const DELETE_TRANSPORTADORA_CADASTRO_PENDING = 'DELETE_TRANSPORTADORA_CADASTRO_PENDING'
export const DELETE_TRANSPORTADORA_CADASTRO_SUCCESS = 'DELETE_TRANSPORTADORA_CADASTRO_SUCCESS'
export const DELETE_TRANSPORTADORA_CADASTRO_FAILURE = 'DELETE_TRANSPORTADORA_CADASTRO_FAILURE'
