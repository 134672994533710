import { httpService } from '@commons';


export function createGrupoContaAgrareWhiteLabel(payload) {
  return httpService.postApiManager(`/clienteAgrareGrupo`, payload);
}

export function updateGrupoContaAgrareWhiteLabel(payload) {
  const { id } = payload

  return httpService.putApiManager(`/clienteAgrareGrupo/${id}`, payload);
}

export function findGrupoContaAgrareWhiteLabel(id) {
  return httpService.getApiManager(`/clienteAgrareGrupo/${id}`, true);
}

export function listGrupoContaAgrareWhiteLabel(param) {
  return httpService.getApiManager(`/clienteAgrareGrupo/${param ?? ''}`, true);
}

export function deleteGrupoContaAgrareWhiteLabel(id) {
  return httpService.delApiManager(`/clienteAgrareGrupo/${id}`);
}