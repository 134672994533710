import * as Yup from 'yup';

const newRegistro = {
    descricao: '',
    geraFinanceiro: false,
    contaGerencial: null,
    historico: '',
    entraNoCalculoDoCustoProducao: true,
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(100, 'Campo deve ter menos do que 100 caracteres')
        .required('Este campo é obrigatório'),
    historico: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(255, 'Campo deve ter menos do que 255 caracteres')
});

export const despesaDiretaCadastro = {
    newRegistro,
    validation
}