import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/pecuaria/cadastro/anopecuario' };
/////////////////////////////////////////////////////////////////////////////////

export function createAnoPecuarioCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateAnoPecuarioCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listAnoPecuarioCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findAnoPecuarioCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllAnoPecuariosByPropriedade(propriedade) {
  return httpService.get({ ...args, path: '/pecuaria/cadastro/anopecuario?size=20000&page=0&status=true&propriedade=' + propriedade });
}

export function deleteAnoPecuarioCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

