import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_TIPO_EMBALAGEM_WHITE_LABEL_RAPIDO,
    CANCEL_TIPO_EMBALAGEM_WHITE_LABEL_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelTipoEmbalagemWhiteLabelRapido() {
  yield put({ type: CANCEL_TIPO_EMBALAGEM_WHITE_LABEL_RAPIDO_SUCCESS})
}

export default function* watchCancelTipoEmbalagemWhiteLabelRapido() {
  yield takeLatest(CANCEL_TIPO_EMBALAGEM_WHITE_LABEL_RAPIDO, sagaCancelTipoEmbalagemWhiteLabelRapido)
}