import { takeLatest, put, call } from 'redux-saga/effects';

import {
    CHANGE_MAP_MODE,
    CHANGE_MAP_MODE_PENDING,
    CHANGE_MAP_MODE_SUCCESS,
    CHANGE_MAP_MODE_FAILURE,
} from '../actionTypes';

function* sagaModeChange(action) {
  yield put({ type: CHANGE_MAP_MODE_PENDING });

  try {
    yield put({ type: CHANGE_MAP_MODE_SUCCESS, mode: action.args });
  } catch (error) {
    yield put({ type: CHANGE_MAP_MODE_FAILURE });
  }
}

export default function* watchModeChange() {
  yield takeLatest(CHANGE_MAP_MODE, sagaModeChange);
}