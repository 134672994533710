import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CardHeader,
    FormGroup,
} from 'reactstrap';
import { CPF_CNPJInput, InputDecimal, GoogleMapsLocationSelect } from '@components/form';
import { Formik, Form, FieldArray } from 'formik'
import { Check, InputText } from '@components/form';

import { PageLoaderScreen } from '@components/common/PageLoaderScreen';

import HorizontalLayout from "@components/layout/HorizontalClean";

import {
    CONTA_AGRARE, contaAgrareCadastro, HAS_AUTHORITY_CORPORATE
} from '@handler';
import { SelectEnum } from '@components/search/enumSelect';
import { TIPO_CLIENTE_AGRARE_LOCAL_TRABALHO_ENUM } from '@handler/contaAgrare/tipoClienteAgrareLocalTrabalhoEnum';
import { TIPO_CLIENTE_AGRARE_ATIVIDADE_AGRICOLA_ENUM } from '@handler/contaAgrare/tipoClienteAgrareAtividadeAgricolaEnum';
import { FIELD_UNIDADE_AREA, getUnidadeAreaSiglaById } from '@handler/contaAgrare/fieldUnidadeArea';
import { InputMask } from '@components/form/InputMask';
import { AlertWarning } from '@components/common/AlertToast';
import { TIPO_AREA_FAZENDA } from '@handler/contaAgrare/tipoAreaPropriedade';
import { SelectGrupoContaAgrareWhiteLabel } from '@components/search/intranetWhiteLabel/grupoContaAgrareWl';

class NovaContaAgrare extends React.Component {

    state = {
        keySelectGrupo: 1,
    }

    async componentDidMount() {
        await this.props.hasAuthorityCorporate('PERM_ROLE_WL_REGISTER_FARMER');
    }

    onSubmit = async (values) => {
        let canSave = true;
        if (!values.confirm) {
            AlertWarning('Para criação da conta você deve autorizar a criação da conta na nossa plataforma')
            canSave = false;
        }

        if (canSave) {
            await this.props.criarConta(values);
        }
    }

    render() {
        const { contaAgrareReducer } = this.props;

        return (
            <HorizontalLayout>
                <PageLoaderScreen loading={contaAgrareReducer.loading} textLoading="Criando sua nova conta. Este processo pode levar alguns minutos. Por favor, permaneça nesta página e aguarde..." />

                <div className="container-fluid">
                    <Row >
                        <Col sm={12}>
                            <div className="page-title-box">
                                <h2>Nova Conta Produtor</h2>
                                <h5>Siga os passos abaixo para criar sua nova conta</h5>
                            </div>

                            <Row>
                                <Col md={12}>
                                    <Formik
                                        enableReinitialize
                                        initialValues={contaAgrareCadastro.newRegistro}
                                        validationSchema={contaAgrareCadastro.validation}
                                        onSubmit={(values) => this.onSubmit(values)}
                                    >
                                        {({ errors, touched, values }) => (
                                            <Form>
                                                <Row>
                                                    <Col md={12}>
                                                        <Card className='card-default'>
                                                            <CardHeader>
                                                                <h5>Dados da Conta (Produtor)</h5>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <Col md={12}>
                                                                    <Row>
                                                                        <InputText name='nomeConta'
                                                                            label='Nome da Conta (Identificação na plataforma)'
                                                                            placeholder='Informe o nome da conta'
                                                                            col={7}
                                                                        />
                                                                        <React.Fragment key={'fragFO_col_' + this.state.keySelectGrupo}>
                                                                            <SelectGrupoContaAgrareWhiteLabel
                                                                                name='clienteAgrareGrupo'
                                                                                label='Grupo de Produtor'
                                                                                placeholder='Selecione o grupo'
                                                                                col={5}
                                                                                refresh={() =>
                                                                                    this.setState({
                                                                                        keySelectGrupo: (this.state.keySelectGrupo + 1)
                                                                                    })
                                                                                }
                                                                            />
                                                                        </React.Fragment>
                                                                    </Row>
                                                                    <Row>
                                                                        <InputText name='nomeResponsavelConta'
                                                                            label='Nome Produtor Responsável'
                                                                            placeholder='Informe o nome'
                                                                            col={8}
                                                                        />
                                                                        <CPF_CNPJInput
                                                                            name='cpfCnpjResponsavelConta'
                                                                            label='CPF/CNPJ Produtor'
                                                                            placeholder='Informe o CPF/CNPJ'
                                                                            col={4}
                                                                        />
                                                                    </Row>
                                                                    <Row>
                                                                        <InputText name='emailPrincipal'
                                                                            label='E-mail Produtor'
                                                                            placeholder='Informe o e-mail'
                                                                            col={6}
                                                                        />
                                                                        <InputMask name='whatsApp'
                                                                            label='WhatsApp Produtor'
                                                                            placeholder='Informe o celular'
                                                                            mask='(99) 9 9999-9999'
                                                                            col={6}
                                                                        />
                                                                    </Row>
                                                                    <Row>
                                                                        <SelectEnum
                                                                            name='localTrabalho'
                                                                            label='Local de Trabalho'
                                                                            placeholder='Selecione o Local de Trabalho'
                                                                            list={TIPO_CLIENTE_AGRARE_LOCAL_TRABALHO_ENUM}
                                                                            col={4}
                                                                        />
                                                                        <SelectEnum
                                                                            name='atividadeAgricola'
                                                                            label='Principal Atividade Agrícola'
                                                                            placeholder='Selecione a Principal Atividade Agrícola'
                                                                            list={TIPO_CLIENTE_AGRARE_ATIVIDADE_AGRICOLA_ENUM}
                                                                            col={4}
                                                                        />
                                                                        <SelectEnum
                                                                            name={`areaUnidade`}
                                                                            label='Unidade Área Fazenda'
                                                                            placeholder='Selecione a Unidade da Área'
                                                                            list={FIELD_UNIDADE_AREA}
                                                                            col={4}
                                                                        />
                                                                    </Row>
                                                                </Col>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={12}>
                                                        <Card className='card-default'>
                                                            <CardHeader>
                                                                <h5>Dados da Fazenda</h5>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <FieldArray
                                                                    name='fazendas'
                                                                    render={arrayHelpers => (
                                                                        <div>
                                                                            {values.fazendas && values.fazendas.length > 0 &&
                                                                                values.fazendas.map((doc, index) => (
                                                                                    <Col key={index} md={12}>
                                                                                        <Row>
                                                                                            <InputText name={`fazendas[${index}].nomeFazenda`}
                                                                                                label='Nome da Fazenda'
                                                                                                placeholder='Informe o nome da fazenda'
                                                                                                col={7}
                                                                                            />
                                                                                            <GoogleMapsLocationSelect
                                                                                                name={`fazendas[${index}].localidade`}
                                                                                                latitude={`fazendas[${index}].latitude`}
                                                                                                longitude={`fazendas[${index}].longitude`}
                                                                                                label='Cidade'
                                                                                                placeholder='Informe a cidade da fazenda'
                                                                                                col={5}
                                                                                            />
                                                                                        </Row>

                                                                                        <Row>
                                                                                            <InputDecimal
                                                                                                name={`fazendas[${index}].areaTotal`}
                                                                                                label='Área Total'
                                                                                                placeholder='Informe a Área Total'
                                                                                                col={4}
                                                                                                suffix={values.areaUnidade == null ? '' : getUnidadeAreaSiglaById(values.areaUnidade)}
                                                                                            />
                                                                                            <SelectEnum
                                                                                                name={`fazendas[${index}].tipoAreaPropriedade`}
                                                                                                label='Tipo de Área'
                                                                                                placeholder='Selecione o tipo'
                                                                                                list={TIPO_AREA_FAZENDA}
                                                                                                col={3}
                                                                                            />
                                                                                        </Row>
                                                                                        {values.fazendas.length === 1 ? <></> : <Col md={1}>
                                                                                            <FormGroup>
                                                                                                <label style={{ color: 'transparent' }} >.</label>
                                                                                                <Button color="danger" className='form-control' onClick={() => arrayHelpers.remove(index)}>
                                                                                                    <i className='fas fa-trash' />
                                                                                                </Button>
                                                                                            </FormGroup>
                                                                                        </Col>}
                                                                                        <Col md={12}>
                                                                                            <br /><hr /><br />
                                                                                        </Col>
                                                                                    </Col>
                                                                                ))
                                                                            }
                                                                            <Button className="btn-primary" color="primary"
                                                                                onClick={() => arrayHelpers.push(
                                                                                    {
                                                                                        nomeFazenda: "",
                                                                                        tipoAreaPropriedade: "AREA_PROPRIA",
                                                                                        localidade: "",
                                                                                        latitude: null,
                                                                                        longitude: null,
                                                                                        areaTotal: 0,
                                                                                    }
                                                                                )}
                                                                            >
                                                                                <i className='fas fa-plus' /> Nova Fazenda
                                                                            </Button>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                {
                                                    values.confirm !== undefined ? <Row>
                                                        <Col md={12}>
                                                            <Card className='card-default'>
                                                                <CardHeader>
                                                                    <h5>Confirmação da Conta</h5>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Col md={12}>
                                                                        <Row>
                                                                            <Check name='confirm'
                                                                                label='Marque esta caixa para confirmar a criação da conta em nossa plataforma.'
                                                                                col={12}
                                                                            />
                                                                        </Row>
                                                                    </Col>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row> : <></>
                                                }

                                                <Row>
                                                    <Col md={12}>
                                                        <Card className='card-default'>
                                                            <CardBody>
                                                                <Button className='btn-primary' onClick={() => { }} type='submit'>
                                                                    <i className='fa fa-check' /> Criar Conta
                                                                </Button>

                                                                <Link to='/corporate/contaProdutor' title='Voltar' style={{ marginLeft: '10px' }}>
                                                                    Voltar
                                                                </Link>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </HorizontalLayout>
        );
    }
}

export function mapStateToProps(state) {
    const { contaAgrareReducer } = state.entities;
    return { contaAgrareReducer };
};

function mapDispatchToProps(dispatch) {
    return {
        hasAuthorityCorporate: (role) => dispatch({ type: HAS_AUTHORITY_CORPORATE, role }),
        criarConta: (data) => dispatch({ type: CONTA_AGRARE, data })
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NovaContaAgrare))

