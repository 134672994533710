import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_CAMPO_CADASTRO_RAPIDO,
    CANCEL_CAMPO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelCampoCadastroRapido() {
  yield put({ type: CANCEL_CAMPO_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelCampoCadastroRapido() {
  yield takeLatest(CANCEL_CAMPO_CADASTRO_RAPIDO, sagaCancelCampoCadastroRapido)
}