
export const NEW_PERFIL_USUARIO_WHITE_LABEL = 'NEW_PERFIL_USUARIO_WHITE_LABEL'
export const NEW_PERFIL_USUARIO_WHITE_LABEL_PENDING = 'NEW_PERFIL_USUARIO_WHITE_LABEL_PENDING'
export const NEW_PERFIL_USUARIO_WHITE_LABEL_SUCCESS = 'NEW_PERFIL_USUARIO_WHITE_LABEL_SUCCESS'
export const NEW_PERFIL_USUARIO_WHITE_LABEL_FAILURE = 'NEW_PERFIL_USUARIO_WHITE_LABEL_FAILURE'

export const CREATE_PERFIL_USUARIO_WHITE_LABEL = 'CREATE_PERFIL_USUARIO_WHITE_LABEL'
export const CREATE_PERFIL_USUARIO_WHITE_LABEL_PENDING = 'CREATE_PERFIL_USUARIO_WHITE_LABEL_PENDING'
export const CREATE_PERFIL_USUARIO_WHITE_LABEL_SUCCESS = 'CREATE_PERFIL_USUARIO_WHITE_LABEL_SUCCESS'
export const CREATE_PERFIL_USUARIO_WHITE_LABEL_FAILURE = 'CREATE_PERFIL_USUARIO_WHITE_LABEL_FAILURE'

export const TABLE_PERFIL_USUARIO_WHITE_LABEL = 'TABLE_PERFIL_USUARIO_WHITE_LABEL'
export const TABLE_PERFIL_USUARIO_WHITE_LABEL_PENDING = 'TABLE_PERFIL_USUARIO_WHITE_LABEL_PENDING'
export const TABLE_PERFIL_USUARIO_WHITE_LABEL_SUCCESS = 'TABLE_PERFIL_USUARIO_WHITE_LABEL_SUCCESS'
export const TABLE_PERFIL_USUARIO_WHITE_LABEL_FAILURE = 'TABLE_PERFIL_USUARIO_WHITE_LABEL_FAILURE'

export const LIST_PERFIL_USUARIO_WHITE_LABEL = 'LIST_PERFIL_USUARIO_WHITE_LABEL'
export const LIST_PERFIL_USUARIO_WHITE_LABEL_PENDING = 'LIST_PERFIL_USUARIO_WHITE_LABEL_PENDING'
export const LIST_PERFIL_USUARIO_WHITE_LABEL_SUCCESS = 'LIST_PERFIL_USUARIO_WHITE_LABEL_SUCCESS'
export const LIST_PERFIL_USUARIO_WHITE_LABEL_FAILURE = 'LIST_PERFIL_USUARIO_WHITE_LABEL_FAILURE'

export const FIND_PERFIL_USUARIO_WHITE_LABEL = 'FIND_PERFIL_USUARIO_WHITE_LABEL'
export const FIND_PERFIL_USUARIO_WHITE_LABEL_PENDING = 'FIND_PERFIL_USUARIO_WHITE_LABEL_PENDING'
export const FIND_PERFIL_USUARIO_WHITE_LABEL_SUCCESS = 'FIND_PERFIL_USUARIO_WHITE_LABEL_SUCCESS'
export const FIND_PERFIL_USUARIO_WHITE_LABEL_FAILURE = 'FIND_PERFIL_USUARIO_WHITE_LABEL_FAILURE'

export const DELETE_PERFIL_USUARIO_WHITE_LABEL = 'DELETE_PERFIL_USUARIO_WHITE_LABEL'
export const DELETE_PERFIL_USUARIO_WHITE_LABEL_PENDING = 'DELETE_PERFIL_USUARIO_WHITE_LABEL_PENDING'
export const DELETE_PERFIL_USUARIO_WHITE_LABEL_SUCCESS = 'DELETE_PERFIL_USUARIO_WHITE_LABEL_SUCCESS'
export const DELETE_PERFIL_USUARIO_WHITE_LABEL_FAILURE = 'DELETE_PERFIL_USUARIO_WHITE_LABEL_FAILURE'

export const TABLE_ALL_PERFIL_USUARIO_WHITE_LABEL = 'TABLE_ALL_PERFIL_USUARIO_WHITE_LABEL'

export const CANCEL_PERFIL_USUARIO_WHITE_LABEL_CADASTRO_RAPIDO = 'CANCEL_PERFIL_USUARIO_WHITE_LABEL_CADASTRO_RAPIDO'
export const CANCEL_PERFIL_USUARIO_WHITE_LABEL_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_PERFIL_USUARIO_WHITE_LABEL_CADASTRO_RAPIDO_SUCCESS'