import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_ANIMAL_RACA_CADASTRO_RAPIDO,
    CANCEL_ANIMAL_RACA_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelAnimalRacaCadastroRapido() {
  yield put({ type: CANCEL_ANIMAL_RACA_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelAnimalRacaCadastroRapido() {
  yield takeLatest(CANCEL_ANIMAL_RACA_CADASTRO_RAPIDO, sagaCancelAnimalRacaCadastroRapido)
}