import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deleteManejoPlanejamentoCadastro } from '../service';
import {
    DELETE_MANEJO_PLANEJAMENTO_CADASTRO,
  DELETE_MANEJO_PLANEJAMENTO_CADASTRO_PENDING,
  DELETE_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS,
  DELETE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaDeleteManejoPlanejamentoCadastro(action) {
  yield put({ type: DELETE_MANEJO_PLANEJAMENTO_CADASTRO_PENDING, id: action.id })

  try {
    const a = yield call(deleteManejoPlanejamentoCadastro, action.id);

    if (a.status !== 204) {
      throw new Error('API delete request failed');
    }
    yield put({ type: DELETE_MANEJO_PLANEJAMENTO_CADASTRO_SUCCESS, id: action.id });

    swal({
      title: 'Excluído!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });

  } catch (error) {

    yield put({ type: DELETE_MANEJO_PLANEJAMENTO_CADASTRO_FAILURE });

    AlertError('Falha ao excluir o manejo! Tente novamente...');
  }
}

export default function* watchDeleteManejoPlanejamentoCadastro() {
  yield takeLatest(DELETE_MANEJO_PLANEJAMENTO_CADASTRO, sagaDeleteManejoPlanejamentoCadastro)
}
