import watchNewPlanoContaCadastro from './saga/new';
import watchFindPlanoContaCadastro from './saga/find';
import watchCreatePlanoContaCadastro from './saga/create';
import watchListAllPlanoContaCadastro from './saga/listAll';
import watchListPlanoContaCadastro from './saga/listTable';
import watchDeletePlanoContaCadastro from './saga/delete';
import watchCancelPlanoContaCadastroRapido from './saga/cancel';

export const planoContaCadastroSaga = [
    watchNewPlanoContaCadastro(),
    watchFindPlanoContaCadastro(),
    watchCreatePlanoContaCadastro(),
    watchListAllPlanoContaCadastro(),
    watchListPlanoContaCadastro(),
    watchDeletePlanoContaCadastro(),
    watchCancelPlanoContaCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
export * from './tipoGrupoEnum';
export * from './tipoContaEnum';
