import { takeLatest, put, call } from 'redux-saga/effects';

import { findPlanoContaCadastro } from '../service';
import {
  FIND_PLANO_CONTA_CADASTRO,
  FIND_PLANO_CONTA_CADASTRO_PENDING,
  FIND_PLANO_CONTA_CADASTRO_SUCCESS,
  FIND_PLANO_CONTA_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindPlanoContaCadastro(action) {
  yield put({ type: FIND_PLANO_CONTA_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findPlanoContaCadastro, action.id);

    yield put({ type: FIND_PLANO_CONTA_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_PLANO_CONTA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindPlanoContaCadastro() {
  yield takeLatest(FIND_PLANO_CONTA_CADASTRO, sagaFindPlanoContaCadastro)
}
