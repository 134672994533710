
export const NEW_GRUPO_CONTA_AGRARE_WHITE_LABEL = 'NEW_GRUPO_CONTA_AGRARE_WHITE_LABEL'
export const NEW_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS = 'NEW_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS'

export const CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL = 'CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL'
export const CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL_PENDING = 'CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL_PENDING'
export const CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS = 'CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS'
export const CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL_FAILURE = 'CREATE_GRUPO_CONTA_AGRARE_WHITE_LABEL_FAILURE'

export const CANCEL_GRUPO_CONTA_AGRARE_WHITE_LABEL_RAPIDO = 'CANCEL_GRUPO_CONTA_AGRARE_WHITE_LABEL_RAPIDO'
export const CANCEL_GRUPO_CONTA_AGRARE_WHITE_LABEL_RAPIDO_SUCCESS = 'CANCEL_GRUPO_CONTA_AGRARE_WHITE_LABEL_RAPIDO_SUCCESS'

export const TABLE_GRUPO_CONTA_AGRARE_WHITE_LABEL = 'TABLE_GRUPO_CONTA_AGRARE_WHITE_LABEL'
export const TABLE_GRUPO_CONTA_AGRARE_WHITE_LABEL_PENDING = 'TABLE_GRUPO_CONTA_AGRARE_WHITE_LABEL_PENDING'
export const TABLE_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS = 'TABLE_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS'
export const TABLE_GRUPO_CONTA_AGRARE_WHITE_LABEL_FAILURE = 'TABLE_GRUPO_CONTA_AGRARE_WHITE_LABEL_FAILURE'

export const FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL = 'FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL'
export const FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL_PENDING = 'FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL_PENDING'
export const FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS = 'FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS'
export const FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL_FAILURE = 'FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL_FAILURE'

export const DELETE_GRUPO_CONTA_AGRARE_WHITE_LABEL = 'DELETE_GRUPO_CONTA_AGRARE_WHITE_LABEL'
export const DELETE_GRUPO_CONTA_AGRARE_WHITE_LABEL_PENDING = 'DELETE_GRUPO_CONTA_AGRARE_WHITE_LABEL_PENDING'
export const DELETE_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS = 'DELETE_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS'
export const DELETE_GRUPO_CONTA_AGRARE_WHITE_LABEL_FAILURE = 'DELETE_GRUPO_CONTA_AGRARE_WHITE_LABEL_FAILURE'
