import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createNaturezaOperacaoCadastro, updateNaturezaOperacaoCadastro } from '../service';
import {
  CREATE_NATUREZA_OPERACAO_CADASTRO,
  CREATE_NATUREZA_OPERACAO_CADASTRO_PENDING,
  CREATE_NATUREZA_OPERACAO_CADASTRO_SUCCESS,
  CREATE_NATUREZA_OPERACAO_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateNaturezaOperacaoCadastro(action) {
  yield put({ type: CREATE_NATUREZA_OPERACAO_CADASTRO_PENDING })

  try {
    if (action.data.geraDuplicata === false || action.data.movimentaFinanceiro === false) {
      action.data.movimentaFinanceiro = false;
      action.data.contaGerencial = null;
    }
    if (action.data.lancaProduto === false) {
      action.data.movimentaEstoque = false;
    }
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createNaturezaOperacaoCadastro, {
        ...action.data
      });
    } else {
      yield call(updateNaturezaOperacaoCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_NATUREZA_OPERACAO_CADASTRO_SUCCESS, registro: action.data })

    if(!action.isCadastroRapido){
      history.push('/tributario/naturezaOperacaoCadastro');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_NATUREZA_OPERACAO_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateNaturezaOperacaoCadastro() {
  yield takeLatest(CREATE_NATUREZA_OPERACAO_CADASTRO, sagaCreateNaturezaOperacaoCadastro)
}
