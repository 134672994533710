import { takeLatest, put, call } from 'redux-saga/effects';

import { findCompraAnimal } from '../service';
import {
  FIND_COMPRA_ANIMAL,
  FIND_COMPRA_ANIMAL_PENDING,
  FIND_COMPRA_ANIMAL_SUCCESS,
  FIND_COMPRA_ANIMAL_FAILURE
} from '../actionTypes';

function* sagaFindCompraAnimal(action) {
  yield put({ type: FIND_COMPRA_ANIMAL_PENDING, id: action.id })

  try {

    const registro = yield call(findCompraAnimal, action.id);

    yield put({ type: FIND_COMPRA_ANIMAL_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_COMPRA_ANIMAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindCompraAnimal() {
  yield takeLatest(FIND_COMPRA_ANIMAL, sagaFindCompraAnimal)
}
