import React from 'react';

import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import ContaAgrareForm from './form';

import { FIND_CONTA_AGRARE, } from '@handler';
import PageLoader from '@components/common/PageLoader';

class ContaAgrareEdit extends React.Component {

  // ///////////////////////////////////////////////////////////////////////////////
  componentDidMount() {

    const { registro } = this.props.contaAgrareReducer;

    const id = this.props.match.params.id;

    if (registro !== undefined && registro.id !== id) {
      this.props.findContaAgrare(id)
    }

  }
  // ///////////////////////////////////////////////////////////////////////////////

  render() {

    const { contaAgrareReducer } = this.props;

    return (
      <>
        {
          contaAgrareReducer.loading ? <PageLoader /> :
            ((contaAgrareReducer.registro === undefined) ?
              <Redirect to='/corporate/contaProdutor' />
              : this.props.contaAgrareReducer.registro && <ContaAgrareForm title='Editar Conta Produtor' />)
        }
      </>
    );
  }

}

export function mapStateToProps(state) {
  const { contaAgrareReducer } = state.entities;
  return { contaAgrareReducer };
};

function mapDispatchToProps(dispatch) {
  return {
    findContaAgrare: (id) => dispatch({ type: FIND_CONTA_AGRARE, id }),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ContaAgrareEdit))
