import { takeLatest, put, call } from 'redux-saga/effects';
import { history } from '@commons';

import { listAllCampoAtivo } from '@handler/pecuaria/campoCadastro/service';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  anoPecuarioCadastro,
  NEW_ANO_PECUARIO_CADASTRO,
  NEW_ANO_PECUARIO_CADASTRO_PENDING,
  NEW_ANO_PECUARIO_CADASTRO_SUCCESS,
  NEW_ANO_PECUARIO_CADASTRO_FAILURE
} from '@handler';

import { AlertError } from '@components/common/AlertToast'


function* sagaNewAnoPecuarioCadastro(action) {
  yield put({ type: NEW_ANO_PECUARIO_CADASTRO_PENDING });

  try {
    const campos = yield call(listAllCampoAtivo, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id);

    let anoPecuarioCampoList = []
    campos.content.forEach(
      (campo) => {
        anoPecuarioCampoList = anoPecuarioCampoList.concat({
          campo: campo,
          status: false,
        })
      });
    yield put({
      type: NEW_ANO_PECUARIO_CADASTRO_SUCCESS, registro: {
        ...anoPecuarioCadastro.newRegistro,
        anoPecuarioCampoList
      },
      isCadastroRapido: action.isCadastroRapido, 
    });
  } catch (error) {
    yield put({ type: NEW_ANO_PECUARIO_CADASTRO_FAILURE })

    AlertError('Falha ao gerar novo cadastro! Tente novamente...');

    history.push('/pecuaria/anoPecuarioCadastro');
  }

}

export default function* watchNewAnoPecuarioCadastro() {
  yield takeLatest(NEW_ANO_PECUARIO_CADASTRO, sagaNewAnoPecuarioCadastro)
}
