import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO,
  NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PEDING,
  NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS,
  NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE,
} from '@handler';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';
import { listBaixaManualEstoqueProducaoFilter } from '../service';
import { CONST_FILTER_AGRIC_EST_BAIXA_MANUAL, CONST_ROWS_PER_PAGE } from '@commons/consts';

function* sagaNewFilterBaixaManualEstoqueProducao(action) {
  try {
    yield put({ type: NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_PEDING })

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    const idSafraAtiva = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva?.id ?? -1;

    if (idSafraAtiva !== -1) {
      let datas = '';
      let dataInicial = '';
      let dataFinal = '';
      var date = new Date();
      let where = '';
      let newFilter = {
        localEstoque: null,
        cultivar: null,
        identificador: null,
        motivo: '',
        dataInicial: '',
        dataFinal: '',
        rows: CONST_ROWS_PER_PAGE,
        page: 0,
        where: ''
      };

      let filterSaved = sessionStorage.getItem(CONST_FILTER_AGRIC_EST_BAIXA_MANUAL) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_AGRIC_EST_BAIXA_MANUAL)) : null;

      if (filterSaved === null || filterSaved === undefined) {
        // seta data da safra ou do mes na primeira vez que entra na pagina
        dataInicial = formatDateYYYYMMDD(JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva?.dataInicial ?? new Date(date.getFullYear(), date.getMonth(), 1)),
          dataFinal = formatDateYYYYMMDD(JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva?.dataFinal ?? new Date(date.getFullYear(), date.getMonth() + 1, 0)),

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);

        newFilter = {
          ...newFilter,
          dataInicial,
          dataFinal,
        };
      } else {
        // se seta filtro manual
        if (action.newFilter) {
          // seta filtro indicado
          // typePeriodo = 0 -> periodo manual
          // typePeriodo = 1 -> hoje
          // typePeriodo = 2 -> esta semana
          // typePeriodo = 3 -> mes passado
          // typePeriodo = 4 -> este mes
          // typePeriodo = 5 -> proximo mes
          // typePeriodo = 6 -> este ano
          if (action.typePeriodo === undefined || action.typePeriodo === 0) {
            dataInicial = action.args.dataInicial;
            dataFinal = action.args.dataFinal;

            datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
          } else if (action.typePeriodo === 1) {
            dataInicial = formatDateYYYYMMDD(new Date(date));
            dataFinal = formatDateYYYYMMDD(new Date(date));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          }
          else if (action.typePeriodo === 2) {
            const primeiro = date.getDate() - date.getDay();

            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          } else if (action.typePeriodo === 3) {
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          } else if (action.typePeriodo === 4) {
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          } else if (action.typePeriodo === 5) {
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          } else if (action.typePeriodo === 6) {
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
          }

          if (action.args.localEstoque !== null && action.args.localEstoque !== undefined) {
            where = where + '&localEstoque=' + action.args.localEstoque.id;
          }
          if (action.args.cultivar !== null && action.args.cultivar !== undefined) {
            where = where + '&cultivar=' + action.args.cultivar.id;
          }
          if (action.args.identificador !== null && action.args.identificador !== undefined) {
            where = where + '&identificador=' + action.args.identificador.id;
          }
          if (action.args.motivo !== null && action.args.motivo !== undefined && action.args.motivo !== '') {
            where = where + '&motivo=' + action.args.motivo;
          }

          newFilter = {
            ...newFilter,
            ...action.args,
            dataInicial,
            dataFinal,
            where: where
          };
        } else {
          // busca filtro salvo
          datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

          if (filterSaved.localEstoque !== null && filterSaved.localEstoque !== undefined) {
            where = where + '&localEstoque=' + filterSaved.localEstoque.id;
          }
          if (filterSaved.cultivar !== null && filterSaved.cultivar !== undefined) {
            where = where + '&cultivar=' + filterSaved.cultivar.id;
          }
          if (filterSaved.identificador !== null && filterSaved.identificador !== undefined) {
            where = where + '&identificador=' + filterSaved.identificador.id;
          }
          if (filterSaved.motivo !== null && filterSaved.motivo !== undefined && filterSaved.motivo !== '') {
            where = where + '&motivo=' + filterSaved.motivo;
          }

          newFilter = {
            ...filterSaved,
            rows: CONST_ROWS_PER_PAGE,
            page: 0,
            where: where
          };
        }
      }

      // busca registros
      let list = yield call(listBaixaManualEstoqueProducaoFilter, idFazenda, idSafraAtiva, datas, `&page=0&size=${CONST_ROWS_PER_PAGE}${where}`);

      sessionStorage.setItem(CONST_FILTER_AGRIC_EST_BAIXA_MANUAL, JSON.stringify(newFilter));

      yield put({
        type: NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_SUCCESS, list: list, filter: newFilter
      });
    } else {
      yield put({ type: NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE })
    }
  } catch (e) {
    yield put({ type: NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE })
    // yield put({ type: GET_TOTAIS_BAIXAR_MANUAL_ESTOQUE_PRODUCAO_FAILURE })
  }
}

export default function* watchNewFilterBaixaManualEstoqueProducao() {
  yield takeLatest(NEW_FILTER_BAIXAR_MANUAL_ESTOQUE_PRODUCAO, sagaNewFilterBaixaManualEstoqueProducao)
}
