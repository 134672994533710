import {
    NEW_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS,
    TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING,
    TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS,
    TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE,
    LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING,
    LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS,
    LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE,
    FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING,
    FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS,
    FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE,
    DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING,
    DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS,
    DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE,
    CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING,
    CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS,
    CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE,
} from './actionTypes';


const initialState = { list: null, loading: false }


function implantacaoEstoqueProducaoReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING:
        case FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING:
        case DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING:
        case CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING:
        case TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS:
        case CREATE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case DELETE_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { implantacaoEstoqueProducaoReducer };