import { takeLatest, put, call } from 'redux-saga/effects';

import { listPerfil } from '../service';
import {
  TABLE_PERFIL,
  TABLE_PERFIL_PENDING,
  TABLE_PERFIL_SUCCESS,
  TABLE_PERFIL_FAILURE
} from '../actionTypes';

function* sagaListPerfil(action) {
  yield put({ type: TABLE_PERFIL_PENDING });

  try {
    const table = yield call(listPerfil, action.args);
    
    yield put({ type: TABLE_PERFIL_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_PERFIL_FAILURE });
  }
}

export default function* watchListPerfil() {
  yield takeLatest(TABLE_PERFIL, sagaListPerfil)
}