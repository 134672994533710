import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllTransferenciaAnimal } from '../service';
import {
  LIST_TRANSFERENCIA_ANIMAL,
  LIST_TRANSFERENCIA_ANIMAL_PENDING,
  LIST_TRANSFERENCIA_ANIMAL_SUCCESS,
  LIST_TRANSFERENCIA_ANIMAL_FAILURE
} from '../actionTypes';

function* sagaListAllTransferenciaAnimal(action) {
  yield put({ type: LIST_TRANSFERENCIA_ANIMAL_PENDING })

  try {
    const list = yield call(findAllTransferenciaAnimal)
    
    yield put({ type: LIST_TRANSFERENCIA_ANIMAL_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_TRANSFERENCIA_ANIMAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllTransferenciaAnimal() {
  yield takeLatest(LIST_TRANSFERENCIA_ANIMAL, sagaListAllTransferenciaAnimal)
}