
export const NEW_PARTNER_WHITE_LABEL = 'NEW_PARTNER_WHITE_LABEL'
export const NEW_PARTNER_WHITE_LABEL_SUCCESS = 'NEW_PARTNER_WHITE_LABEL_SUCCESS'

export const CREATE_PARTNER_WHITE_LABEL = 'CREATE_PARTNER_WHITE_LABEL'
export const CREATE_PARTNER_WHITE_LABEL_PENDING = 'CREATE_PARTNER_WHITE_LABEL_PENDING'
export const CREATE_PARTNER_WHITE_LABEL_SUCCESS = 'CREATE_PARTNER_WHITE_LABEL_SUCCESS'
export const CREATE_PARTNER_WHITE_LABEL_FAILURE = 'CREATE_PARTNER_WHITE_LABEL_FAILURE'

export const CANCEL_PARTNER_WHITE_LABEL_RAPIDO = 'CANCEL_PARTNER_WHITE_LABEL_RAPIDO'
export const CANCEL_PARTNER_WHITE_LABEL_RAPIDO_SUCCESS = 'CANCEL_PARTNER_WHITE_LABEL_RAPIDO_SUCCESS'

export const TABLE_PARTNER_WHITE_LABEL = 'TABLE_PARTNER_WHITE_LABEL'
export const TABLE_PARTNER_WHITE_LABEL_PENDING = 'TABLE_PARTNER_WHITE_LABEL_PENDING'
export const TABLE_PARTNER_WHITE_LABEL_SUCCESS = 'TABLE_PARTNER_WHITE_LABEL_SUCCESS'
export const TABLE_PARTNER_WHITE_LABEL_FAILURE = 'TABLE_PARTNER_WHITE_LABEL_FAILURE'

export const FIND_PARTNER_WHITE_LABEL = 'FIND_PARTNER_WHITE_LABEL'
export const FIND_PARTNER_WHITE_LABEL_PENDING = 'FIND_PARTNER_WHITE_LABEL_PENDING'
export const FIND_PARTNER_WHITE_LABEL_SUCCESS = 'FIND_PARTNER_WHITE_LABEL_SUCCESS'
export const FIND_PARTNER_WHITE_LABEL_FAILURE = 'FIND_PARTNER_WHITE_LABEL_FAILURE'

export const DELETE_PARTNER_WHITE_LABEL = 'DELETE_PARTNER_WHITE_LABEL'
export const DELETE_PARTNER_WHITE_LABEL_PENDING = 'DELETE_PARTNER_WHITE_LABEL_PENDING'
export const DELETE_PARTNER_WHITE_LABEL_SUCCESS = 'DELETE_PARTNER_WHITE_LABEL_SUCCESS'
export const DELETE_PARTNER_WHITE_LABEL_FAILURE = 'DELETE_PARTNER_WHITE_LABEL_FAILURE'
