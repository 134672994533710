import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { activateUsuarioWhiteLabel, disableUsuarioWhiteLabel } from '../service';
import {
  ACTIVE_INACTIVE_USUARIO_WHITE_LABEL,
  ACTIVE_INACTIVE_USUARIO_WHITE_LABEL_PENDING,
  ACTIVE_INACTIVE_USUARIO_WHITE_LABEL_SUCCESS,
  ACTIVE_INACTIVE_USUARIO_WHITE_LABEL_FAILURE
} from '../actionTypes';
import { AlertWarning } from '@components/common/AlertToast';

function* sagaAtivoInativoUsuarioWhiteLabel(action) {
  yield put({ type: ACTIVE_INACTIVE_USUARIO_WHITE_LABEL_PENDING })

  try {
    if (action.ativar) {
      yield call(activateUsuarioWhiteLabel, action.id);
    } else {
      yield call(disableUsuarioWhiteLabel, action.id);
    }

    swal({
      title: action.ativar ? 'Usuário ativado com sucesso...' : 'Usuário desativado com sucesso...',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      window.location.reload();
    });

    yield put({ type: ACTIVE_INACTIVE_USUARIO_WHITE_LABEL_SUCCESS })
  } catch (error) {
    yield put({ type: ACTIVE_INACTIVE_USUARIO_WHITE_LABEL_FAILURE });

    if (action.ativar) {
      AlertWarning('Falha ao ativar usuário. Tente novamente...');
    } else {
      AlertWarning('Falha ao desativar usuário. Tente novamente...');
    }
  }
}

export default function* watchAtivoInativoUsuarioWhiteLabel() {
  yield takeLatest(ACTIVE_INACTIVE_USUARIO_WHITE_LABEL, sagaAtivoInativoUsuarioWhiteLabel)
}
