import * as Yup from 'yup';
import { path, storage } from '@commons';
import { cnpj as cnpjValidator, cpf as cpfValidator } from 'cpf-cnpj-validator';

let user = JSON.parse(storage.get(path.sessionUser));

const newRegistro = {
    tipoCliente: "PRODUTOR",
    nomeConta: "",
    nomeSchema: "",
    clienteAgrareGrupo: null,
    nomeResponsavelConta: "",
    cpfCnpjResponsavelConta: "",
    idUserAdmin: null,
    emailPrincipal: user?.email ?? '',
    whatsApp: user?.whatsApp ?? '',
    fazendas: [{
        nomeFazenda: "",
        tipoAreaPropriedade: "AREA_PROPRIA",
        localidade: "",
        latitude: null,
        longitude: null,
        areaTotal: 0,
    }],
    areaUnidade: 1,
    localTrabalho: "PROPRIEDADE_RURAL",
    atividadeAgricola: "CULTIVO_GRAOS",
    criarDominio: true,
    confirm: false
};

const phoneRegExp1 = /\(\d{2}\)\s\d\s\d{4}-\d{4}/;

Yup.addMethod(Yup.string, 'cpfCnpjResponsavelConta', function () {
    return this.test('cpfCnpjResponsavelConta', 'CPF ou CNPJ inválido', function (value) {
        const { path, createError } = this;

        if (!value) return true; // se o campo for opcional

        const cleanValue = value.replace(/[^\d]/g, ""); // remove a máscara

        const isValid = (cleanValue.length === 11 && cpfValidator.isValid(cleanValue)) ||
            (cleanValue.length === 14 && cnpjValidator.isValid(cleanValue));

        return isValid || createError({ path, message: cleanValue.length <= 11 ? 'CPF inválido' : 'CNPJ inválido' });
    });
});



const validation = Yup.object().shape({
    nomeConta: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    nomeResponsavelConta: Yup.string()
        .matches(
            /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
            'Nome pode conter somente letras'
        )
        .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Informe o Nome e Sobrenome')
        .max(60, 'Campo deve ter menos do que 60 caracteres!')
        .required('Este campo é obrigatório'),
    cpfCnpjResponsavelConta: Yup.string()
        .cpfCnpjResponsavelConta()
        .required('CPF/CNPJ é obrigatório'),
    emailPrincipal: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres!')
        .max(150, 'Campo deve ter menos do que 150 caracteres!')
        .email('Favor, formato de e-mail inválido!')
        .required('Este campo é obrigatório'),
    whatsApp: Yup.string()
        .matches(phoneRegExp1, "Celular inválido")
        .required('Este campo é obrigatório'),
    localTrabalho: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    atividadeAgricola: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    areaUnidade: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    fazendas: Yup.array()
        .of(
            Yup.object().shape({
                nomeFazenda: Yup.string()
                    .min(2, 'O campo deve ter no mínimo 2 caracteres')
                    .max(60, 'Campo deve ter menos do que 60 caracteres')
                    .required('Este campo é obrigatório'),
                tipoAreaPropriedade: Yup.string()
                    .typeError('Este campo é obrigatório')
                    .required('Este campo é obrigatório'),
                localidade: Yup.string()
                    .required('Este campo é obrigatório'),
                areaTotal: Yup.number()
                    .positive('Por Favor, informe um valor maior que zero')
                    .transform(value => (isNaN(value) ? 0.0 : value))
                    .required('Este campo é obrigatório'),
            })
        ),
    clienteAgrareGrupo: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
});

const validationContaAgrareCadastro = Yup.object().shape({
    nomeConta: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    emailPrincipal: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres!')
        .max(150, 'Campo deve ter menos do que 150 caracteres!')
        .email('Favor, formato de e-mail inválido!')
        .required('Este campo é obrigatório'),
    whatsApp: Yup.string()
        .matches(phoneRegExp1, "Celular inválido")
        .required('Este campo é obrigatório'),
    localTrabalho: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    atividadeAgricola: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    clienteAgrareGrupo: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
});


export const contaAgrareCadastro = {
    newRegistro,
    validation,
    validationContaAgrareCadastro
}