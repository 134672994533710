import { takeLatest, put, call } from 'redux-saga/effects';

import { listPartnerWhiteLabel } from '../service';
import {
  TABLE_PARTNER_WHITE_LABEL,
  TABLE_PARTNER_WHITE_LABEL_PENDING,
  TABLE_PARTNER_WHITE_LABEL_SUCCESS,
  TABLE_PARTNER_WHITE_LABEL_FAILURE
} from '../actionTypes';

function* sagaListPartnerWhiteLabel(action) {
  yield put({ type: TABLE_PARTNER_WHITE_LABEL_PENDING })

  try {
    const table = yield call(listPartnerWhiteLabel, action.args)
    yield put({ type: TABLE_PARTNER_WHITE_LABEL_SUCCESS, table: table })

  } catch (error) {

    yield put({ type: TABLE_PARTNER_WHITE_LABEL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListPartnerWhiteLabel() {
  yield takeLatest(TABLE_PARTNER_WHITE_LABEL, sagaListPartnerWhiteLabel)
}