import { takeLatest, put, call } from 'redux-saga/effects';

import { findControleContaBanco, findControleContaBancoTransf } from '../service';
import {
  FIND_CONTROLE_CONTA_BANCO,
  FIND_CONTROLE_CONTA_BANCO_PENDING,
  FIND_CONTROLE_CONTA_BANCO_SUCCESS,
  FIND_CONTROLE_CONTA_BANCO_FAILURE
} from '../actionTypes';
import { history } from '@commons';

function* sagaFindControleContaBanco(action) {
  yield put({ type: FIND_CONTROLE_CONTA_BANCO_PENDING, id: action.id })

  try {
    if (action.origemLancamento === 'TRANSFERENCIA') {
      const registro = yield call(findControleContaBancoTransf, action.id);
      
      let newRegistro = {
        ...registro,
        conta: registro.contaOrigem,
        tipoMovimento: 'TRANSFERENCIA'
      }

      yield put({ type: FIND_CONTROLE_CONTA_BANCO_SUCCESS, registro: newRegistro })

      history.push('/financeiro/movimentacoesBancarias/editar/' + newRegistro.id );
    } else {
      const registro = yield call(findControleContaBanco, action.id);

      yield put({ type: FIND_CONTROLE_CONTA_BANCO_SUCCESS, registro: registro })

      history.push('/financeiro/movimentacoesBancarias/editar/' + registro.id);
    }
  } catch (error) {

    yield put({ type: FIND_CONTROLE_CONTA_BANCO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindControleContaBanco() {
  yield takeLatest(FIND_CONTROLE_CONTA_BANCO, sagaFindControleContaBanco)
}
