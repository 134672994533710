import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    NEW_CONTA_RECEBER_SUCCESS,
    NEW_FILTER_NEXT_PAGE_CONTA_RECEBER_PENDING,
    NEW_FILTER_NEXT_PAGE_CONTA_RECEBER_SUCCESS,
    NEW_FILTER_NEXT_PAGE_CONTA_RECEBER_FAILURE,
    FIND_CONTA_RECEBER_PENDING,
    FIND_CONTA_RECEBER_SUCCESS,
    FIND_CONTA_RECEBER_FAILURE,
    DELETE_CONTA_RECEBER_PENDING,
    DELETE_CONTA_RECEBER_SUCCESS,
    DELETE_CONTA_RECEBER_FAILURE,
    CREATE_CONTA_RECEBER_PENDING,
    CREATE_CONTA_RECEBER_SUCCESS,
    CREATE_CONTA_RECEBER_FAILURE,
    NEW_FILTER_CONTA_RECEBER_PEDING,
    NEW_FILTER_CONTA_RECEBER_SUCCESS,
    FIND_CONTA_RECEBER_PAGAMENTOS_PENDING,
    FIND_CONTA_RECEBER_PAGAMENTOS_SUCCESS,
    FIND_CONTA_RECEBER_PAGAMENTOS_FAILURE,
    CONTA_RECEBER_ESTORNAR_PAGAMENTO_PENDING,
    CONTA_RECEBER_ESTORNAR_PAGAMENTO_SUCCESS,
    CONTA_RECEBER_ESTORNAR_PAGAMENTO_FAILURE,
    GET_TOTAIS_CONTA_RECEBER_PENDING,
    GET_TOTAIS_CONTA_RECEBER_SUCCESS,
    GET_TOTAIS_CONTA_RECEBER_FAILURE,
    NEW_FILTER_CONTA_RECEBER_SUCCESS_PDF,
    NEW_FILTER_CONTA_RECEBER_FAILURE
} from './actionTypes';


const initialState = {
    list: null,
    pagamentos: null,
    loading: false,
    loadingTotais: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
    totais: {
        totalQuitadas: 0,
        totalVencidas: 0,
        totalParaHoje: 0,
        totalAVencer: 0
    }
}


function contaReceberReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_CONTA_RECEBER_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case NEW_FILTER_NEXT_PAGE_CONTA_RECEBER_PENDING:
        case FIND_CONTA_RECEBER_PENDING:
        case DELETE_CONTA_RECEBER_PENDING:
        case CREATE_CONTA_RECEBER_PENDING:
        case NEW_FILTER_CONTA_RECEBER_PEDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_TOTAIS_CONTA_RECEBER_PENDING:
            return {
                ...state,
                loadingTotais: true,
            }
        case GET_TOTAIS_CONTA_RECEBER_SUCCESS:
            return {
                ...state,
                totais: action.totais,
                loadingTotais: false
            }
        case GET_TOTAIS_CONTA_RECEBER_FAILURE:
            return {
                ...state,
                totais: {
                    totalQuitadas: 0,
                    totalVencidas: 0,
                    totalParaHoje: 0,
                    totalAVencer: 0
                },
                loadingTotais: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_CONTA_RECEBER_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_CONTA_RECEBER_SUCCESS_PDF:
            return {
                ...state,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_CONTA_RECEBER_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_CONTA_RECEBER_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_CONTA_RECEBER_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_CONTA_RECEBER_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_CONTA_RECEBER_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_CONTA_RECEBER_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_CONTA_RECEBER_SUCCESS:
        case CREATE_CONTA_RECEBER_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case DELETE_CONTA_RECEBER_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_CONTA_RECEBER_PAGAMENTOS_PENDING:
            return {
                ...state,
                loadingPagamentos: true,
            }
        case FIND_CONTA_RECEBER_PAGAMENTOS_SUCCESS:
            return {
                ...state,
                pagamentos: action.pagamentos,
                loadingPagamentos: false
            }
        case FIND_CONTA_RECEBER_PAGAMENTOS_FAILURE:
            return {
                ...state,
                pagamentos: null,
                loadingPagamentos: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CONTA_RECEBER_ESTORNAR_PAGAMENTO_PENDING:
            return {
                ...state,
                loadingPagamentos: true,
            }
        case CONTA_RECEBER_ESTORNAR_PAGAMENTO_SUCCESS:
            return {
                ...state,
                pagamentos: null,
                loadingPagamentos: false
            }
        case CONTA_RECEBER_ESTORNAR_PAGAMENTO_FAILURE:
            return {
                ...state,
                pagamentos: null,
                loadingPagamentos: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { contaReceberReducer };