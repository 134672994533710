
export const NEW_BAIXAR_MANUAL_ESTOQUE = 'NEW_BAIXAR_MANUAL_ESTOQUE'
export const NEW_BAIXAR_MANUAL_ESTOQUE_SUCCESS = 'NEW_BAIXAR_MANUAL_ESTOQUE_SUCCESS'

export const CREATE_BAIXAR_MANUAL_ESTOQUE = 'CREATE_BAIXAR_MANUAL_ESTOQUE'
export const CREATE_BAIXAR_MANUAL_ESTOQUE_PENDING = 'CREATE_BAIXAR_MANUAL_ESTOQUE_PENDING'
export const CREATE_BAIXAR_MANUAL_ESTOQUE_SUCCESS = 'CREATE_BAIXAR_MANUAL_ESTOQUE_SUCCESS'
export const CREATE_BAIXAR_MANUAL_ESTOQUE_FAILURE = 'CREATE_BAIXAR_MANUAL_ESTOQUE_FAILURE'

export const TABLE_BAIXAR_MANUAL_ESTOQUE = 'TABLE_BAIXAR_MANUAL_ESTOQUE'
export const TABLE_BAIXAR_MANUAL_ESTOQUE_PENDING = 'TABLE_BAIXAR_MANUAL_ESTOQUE_PENDING'
export const TABLE_BAIXAR_MANUAL_ESTOQUE_SUCCESS = 'TABLE_BAIXAR_MANUAL_ESTOQUE_SUCCESS'
export const TABLE_BAIXAR_MANUAL_ESTOQUE_FAILURE = 'TABLE_BAIXAR_MANUAL_ESTOQUE_FAILURE'

export const LIST_BAIXAR_MANUAL_ESTOQUE = 'LIST_BAIXAR_MANUAL_ESTOQUE'
export const LIST_BAIXAR_MANUAL_ESTOQUE_PENDING = 'LIST_BAIXAR_MANUAL_ESTOQUE_PENDING'
export const LIST_BAIXAR_MANUAL_ESTOQUE_SUCCESS = 'LIST_BAIXAR_MANUAL_ESTOQUE_SUCCESS'
export const LIST_BAIXAR_MANUAL_ESTOQUE_FAILURE = 'LIST_BAIXAR_MANUAL_ESTOQUE_FAILURE'

export const FIND_BAIXAR_MANUAL_ESTOQUE = 'FIND_BAIXAR_MANUAL_ESTOQUE'
export const FIND_BAIXAR_MANUAL_ESTOQUE_PENDING = 'FIND_BAIXAR_MANUAL_ESTOQUE_PENDING'
export const FIND_BAIXAR_MANUAL_ESTOQUE_SUCCESS = 'FIND_BAIXAR_MANUAL_ESTOQUE_SUCCESS'
export const FIND_BAIXAR_MANUAL_ESTOQUE_FAILURE = 'FIND_BAIXAR_MANUAL_ESTOQUE_FAILURE'

export const DELETE_BAIXAR_MANUAL_ESTOQUE = 'DELETE_BAIXAR_MANUAL_ESTOQUE'
export const DELETE_BAIXAR_MANUAL_ESTOQUE_PENDING = 'DELETE_BAIXAR_MANUAL_ESTOQUE_PENDING'
export const DELETE_BAIXAR_MANUAL_ESTOQUE_SUCCESS = 'DELETE_BAIXAR_MANUAL_ESTOQUE_SUCCESS'
export const DELETE_BAIXAR_MANUAL_ESTOQUE_FAILURE = 'DELETE_BAIXAR_MANUAL_ESTOQUE_FAILURE'
