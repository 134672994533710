


import watchNewPerfilUsuarioWhiteLabel from './saga/new';
import watchFindPerfilUsuarioWhiteLabel from './saga/find';
import watchCreatePerfilUsuarioWhiteLabel from './saga/create';
import watchListPerfilUsuarioWhiteLabel from './saga/list';
import watchDeletePerfilUsuarioWhiteLabel from './saga/delete';

export const perfilUsuarioWhiteLabelSaga = [
    watchNewPerfilUsuarioWhiteLabel(),
    watchFindPerfilUsuarioWhiteLabel(),
    watchCreatePerfilUsuarioWhiteLabel(),
    watchListPerfilUsuarioWhiteLabel(),
    watchDeletePerfilUsuarioWhiteLabel(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
