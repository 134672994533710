import { takeLatest, put, call } from 'redux-saga/effects';

import { listAllPerfil } from '../service';
import {
  LIST_PERFIL,
  LIST_PERFIL_PENDING,
  LIST_PERFIL_SUCCESS,
  LIST_PERFIL_FAILURE
} from '../actionTypes';

function* sagaListAllPerfil(action) {
  yield put({ type: LIST_PERFIL_PENDING })

  try {
    const list = yield call(listAllPerfil)
    
    yield put({ type: LIST_PERFIL_SUCCESS, list: list })
  } catch (error) {
    yield put({ type: LIST_PERFIL_FAILURE })
  }
}

export default function* watchListAllPerfil() {
  yield takeLatest(LIST_PERFIL, sagaListAllPerfil)
}