import {
    LIST_RELATORIO_MOVIMENTACAO_ANIMAL_PENDING,
    LIST_RELATORIO_MOVIMENTACAO_ANIMAL_SUCCESS,
    LIST_RELATORIO_MOVIMENTACAO_ANIMAL_FAILURE,
    NEW_FILTER_RELATORIO_MOVIMENTACAO_ANIMAL_PEDING,
    NEW_FILTER_RELATORIO_MOVIMENTACAO_ANIMAL_SUCCESS,
} from './actionTypes';


const initialState = { list: null, loading: false }


function relatorioMovimentacaoAnimalReducer(state = initialState, action) {

    switch (action.type) {
        case LIST_RELATORIO_MOVIMENTACAO_ANIMAL_PENDING:
        case NEW_FILTER_RELATORIO_MOVIMENTACAO_ANIMAL_PEDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_RELATORIO_MOVIMENTACAO_ANIMAL_SUCCESS:
            return {
                ...state,
                list: action.list,
                newFilter: action.newFilter,
                loading: false
            }
        case LIST_RELATORIO_MOVIMENTACAO_ANIMAL_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_RELATORIO_MOVIMENTACAO_ANIMAL_SUCCESS: {
            return {
                ...state,
                newFilter: action.newFilter,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { relatorioMovimentacaoAnimalReducer };