import { takeLatest, put, call } from 'redux-saga/effects';

import { findPartnerWhiteLabel } from '../service';
import {
  FIND_PARTNER_WHITE_LABEL,
  FIND_PARTNER_WHITE_LABEL_PENDING,
  FIND_PARTNER_WHITE_LABEL_SUCCESS,
  FIND_PARTNER_WHITE_LABEL_FAILURE
} from '../actionTypes';

function* sagaFindPartnerWhiteLabel(action) {
  yield put({ type: FIND_PARTNER_WHITE_LABEL_PENDING, id: action.id })

  try {

    const registro = yield call(findPartnerWhiteLabel, action.id);

    yield put({ type: FIND_PARTNER_WHITE_LABEL_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_PARTNER_WHITE_LABEL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindPartnerWhiteLabel() {
  yield takeLatest(FIND_PARTNER_WHITE_LABEL, sagaFindPartnerWhiteLabel)
}
