import { takeLatest, put } from 'redux-saga/effects'

import { history, storage, path } from '@commons';
import {
  HAS_AUTHORITY_CORPORATE,
  HAS_AUTHORITY_CORPORATE_PENDING,
  HAS_AUTHORITY_CORPORATE_SUCCESS,
  HAS_AUTHORITY_CORPORATE_FAILURE
} from '../actionTypes';

function* sagaHasAuthorityCorporate(action) {
  yield put({ type: HAS_AUTHORITY_CORPORATE_PENDING })

  try {
    let user = JSON.parse(storage.get(path.sessionUser));

    if (user !== null) {
      let hasAuthority = false;

      if (user.perfil === 'SUPORTE_AGRARE') {
        hasAuthority = true;
      } else {
        if (user.perfil === 'EMPRESA_WHITE_LABEL_ADMIN' ||
          user.perfil === 'EMPRESA_WHITE_LABEL_FUNC' ||
          user.perfil === 'PARCEIRO_ADMIN' ||
          user.perfil === 'PARCEIRO_REPRESENTANTE') {

          if (user.perfil === 'EMPRESA_WHITE_LABEL_ADMIN' ||
            user.perfil === 'EMPRESA_WHITE_LABEL_FUNC') {
            if ((action.role === 'PERM_ROLE_WL_REGISTER_USER_PROFILE') && (user.permissionRoleWlRegisterUserProfile)) {
              hasAuthority = true;
            } else if ((action.role === 'PERM_ROLE_WL_REGISTER_PARTNER') && (user.permissionRoleWlRegisterPartner)) {
              hasAuthority = true;
            } else if ((action.role === 'PERM_ROLE_WL_REGISTER_KIND_PACKING') && (user.permissionRoleWlRegisterKindPacking)) {
              hasAuthority = true;
            }
          }

          if ((action.role === 'PERM_ROLE_WL_REGISTER_PRODUCT') && (user.permissionRoleWlRegisterProduct)) {
            hasAuthority = true;
          } else if ((action.role === 'PERM_ROLE_WL_REGISTER_PRODUCT_VIEW') && (user.permissionRoleWlRegisterProductView)) {
            hasAuthority = true;
          } else if ((action.role === 'PERM_ROLE_WL_REGISTER_USER') && (user.permissionRoleWlRegisterUser)) {
            hasAuthority = true;
          } else if ((action.role === 'PERM_ROLE_WL_REGISTER_FARMER') && (user.permissionRoleWlRegisterFarmer)) {
            hasAuthority = true;
          } else if ((action.role === 'PERM_ROLE_WL_REGISTER_GROUP_FARMER') && (user.permissionRoleWlRegisterGroupFarmer)) {
            hasAuthority = true;
          }
        }
      }

      if (hasAuthority) {
        yield put({ type: HAS_AUTHORITY_CORPORATE_SUCCESS });
      } else {
        yield put({ type: HAS_AUTHORITY_CORPORATE_FAILURE });
        history.push('/corporate/semPermissao');
      }
    } else {
      yield put({ type: HAS_AUTHORITY_CORPORATE_FAILURE });
      history.push('/corporate/semPermissao');
    }

  } catch (error) {
    yield put({ type: HAS_AUTHORITY_CORPORATE_FAILURE });
    history.push('/corporate/semPermissao');
  }
}

export default function* watchHasAuthorityCorporate() {
  yield takeLatest(HAS_AUTHORITY_CORPORATE, sagaHasAuthorityCorporate);
}
