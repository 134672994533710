import watchNewTransferenciaEstoqueProducao from './saga/new';
import watchFindTransferenciaEstoqueProducao from './saga/find';
import watchCreateTransferenciaEstoqueProducao from './saga/create';
import watchListAllTransferenciaEstoqueProducao from './saga/listAll';
import watchListTransferenciaEstoqueProducao from './saga/listTable';
import watchDeleteTransferenciaEstoqueProducao from './saga/delete';

export const transferenciaEstoqueProducaoSaga = [
    watchNewTransferenciaEstoqueProducao(),
    watchFindTransferenciaEstoqueProducao(),
    watchCreateTransferenciaEstoqueProducao(),
    watchListAllTransferenciaEstoqueProducao(),
    watchListTransferenciaEstoqueProducao(),
    watchDeleteTransferenciaEstoqueProducao(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
