import { takeLatest, put, call } from 'redux-saga/effects';

import { findClienteCadastro } from '../service';
import {
  FIND_CLIENTE_CADASTRO,
  FIND_CLIENTE_CADASTRO_PENDING,
  FIND_CLIENTE_CADASTRO_SUCCESS,
  FIND_CLIENTE_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindClienteCadastro(action) {
  yield put({ type: FIND_CLIENTE_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findClienteCadastro, action.id);

    yield put({ type: FIND_CLIENTE_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_CLIENTE_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindClienteCadastro() {
  yield takeLatest(FIND_CLIENTE_CADASTRO, sagaFindClienteCadastro)
}
