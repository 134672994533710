
export const CONST_FILTER_CONTAS_PAGAR = 'FILTER_CONTAS_PAGAR';
export const CONST_FILTER_CONTAS_RECEBER = 'FILTER_CONTAS_RECEBER';
export const CONST_FILTER_FLUXO_CAIXA = 'CONST_FILTER_FLUXO_CAIXA';
export const CONST_FILTER_MOVTO_BANCO = 'CONST_FILTER_MOVTO_BANCO';
export const CONST_FILTER_MOVTO_CAIXA = 'CONST_FILTER_MOVTO_CAIXA';

export const CONST_FILTER_MONITORAMENTO_NFE_COMPRA = 'FILTER_MONITORAMENTO_NFE_COMPRA';

export const CONST_FILTER_PLANTIO_MANEJO = 'CONST_FILTER_PLANTIO_MANEJO';
export const CONST_FILTER_PLANEJAMENTO = 'CONST_FILTER_PLANEJAMENTO';
export const CONST_FILTER_COLHEITA = 'CONST_FILTER_COLHEITA';
export const CONST_FILTER_AGRIC_EST_BAIXA_MANUAL = 'CONST_FILTER_AGRIC_EST_BAIXA_MANUAL';

export const CONST_FILTER_CONTA_AGRARE = 'CONST_FILTER_CONTA_AGRARE';

export const CONST_ROWS_PER_PAGE = 50;

export const IDENTIFICATION_CODE_WL_AGRARE = '959dc425-0995-41e2-85b6-e3a66182c077';


export const TEXT_COMPANY_SLOGAN = 'Agrare - Tecnologia e Gestão no Campo';
export const TEXT_COMPANY_NAME = 'Agrare';
export const TEXT_COMPANY_ONFARM = 'Agrare';
// export const TEXT_COMPANY_SLOGAN = 'SBS Connect - Conectando Você ao Futuro!';
// export const TEXT_COMPANY_NAME = 'SBS Connect';
// export const TEXT_COMPANY_ONFARM = 'SBS On Farm';


export function clearFilters() {
    sessionStorage.setItem(CONST_FILTER_CONTAS_PAGAR, null);
    sessionStorage.setItem(CONST_FILTER_CONTAS_RECEBER, null);
    sessionStorage.setItem(CONST_FILTER_FLUXO_CAIXA, null);
    sessionStorage.setItem(CONST_FILTER_MOVTO_BANCO, null);
    sessionStorage.setItem(CONST_FILTER_MOVTO_CAIXA, null);
    sessionStorage.setItem(CONST_FILTER_MONITORAMENTO_NFE_COMPRA, null);
    sessionStorage.setItem(CONST_FILTER_PLANTIO_MANEJO, null);
    sessionStorage.setItem(CONST_FILTER_PLANEJAMENTO, null);
    sessionStorage.setItem(CONST_FILTER_COLHEITA, null);
    sessionStorage.setItem(CONST_FILTER_AGRIC_EST_BAIXA_MANUAL, null);
    sessionStorage.setItem(CONST_FILTER_CONTA_AGRARE, null);
}