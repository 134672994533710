import { takeLatest, put, call } from 'redux-saga/effects';

import { findContaCartaoCadastro } from '../service';
import {
  FIND_CONTA_CARTAO_CADASTRO,
  FIND_CONTA_CARTAO_CADASTRO_PENDING,
  FIND_CONTA_CARTAO_CADASTRO_SUCCESS,
  FIND_CONTA_CARTAO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindContaCartaoCadastro(action) {
  yield put({ type: FIND_CONTA_CARTAO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findContaCartaoCadastro, action.id);

    yield put({ type: FIND_CONTA_CARTAO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_CONTA_CARTAO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindContaCartaoCadastro() {
  yield takeLatest(FIND_CONTA_CARTAO_CADASTRO, sagaFindContaCartaoCadastro)
}
