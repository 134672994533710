import * as Yup from 'yup';

const newRegistro = {
    data: '',
    localEstoqueOrigem: null,
    localEstoqueDestino: null,
    produto: null,
    unidadeAplicacao: null,
    quantidade: 0,
    obs: '',
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    localEstoqueOrigem: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    localEstoqueDestino: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    produto: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    quantidade: Yup.number()
        .typeError('Este campo é obrigatório')
        .positive('Por Favor, informe um valor maior que zero')
        .required("Este campo é obrigatório"),
    obs: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
});

export const transferenciaEstoque = {
    newRegistro,
    validation
}