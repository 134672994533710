import * as Yup from 'yup';

const newRegistro = {
    status: true,
    tipoGrupo: null,
    tipoConta: null,
    classificacao: '',
    descricao: '',
    operacaoConta: 'D',
    tipoRegimeContabil: 'RCAIXA_E_RCOMPETENCIA',
    disponivelDre: true,
};

const validation = Yup.object().shape({
    tipoGrupo: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    tipoConta: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    // classificacao: Yup.string()
    //     .typeError('Este campo é obrigatório')
    //     .required('Este campo é obrigatório'),
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(100, 'Campo deve ter menos do que 100 caracteres')
        .required('Este campo é obrigatório'),
});

export const planoContaCadastro = {
    newRegistro,
    validation
}