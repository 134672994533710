import { takeLatest, put, call } from 'redux-saga/effects';

import { path, storage, history } from '@commons';

import {
  CONFIG_SESSION,
  CREATE_START_SESSION,
  CREATE_START_SESSION_PENDING,
  CREATE_START_SESSION_SUCCESS,
  LIST_SCHEMES_SUCCESS
} from '../actionTypes';

import { clearFilters } from '@commons/consts';
import { findSchemesByUserAuth } from '@handler/common/usuario';

function* sagaCreateStartSession(action) {
  yield put({ type: CREATE_START_SESSION_PENDING })

  // limpa filtros salvos
  clearFilters();

  let user = JSON.parse(storage.get(path.sessionUser));

  const table = yield call(findSchemesByUserAuth, user, action.args)

  yield put({ type: LIST_SCHEMES_SUCCESS, table: table })

  let configSession = {
    schemaSelecionado: null,
    perfilUser: null
  }
  yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));

  yield put({ type: CREATE_START_SESSION_SUCCESS });
}

export default function* watchCreateStartSession() {
  yield takeLatest(CREATE_START_SESSION, sagaCreateStartSession)
}
