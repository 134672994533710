import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/pecuaria/cadastro/procedimento' };
/////////////////////////////////////////////////////////////////////////////////

export function createProcedimentoPecuarioCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateProcedimentoPecuarioCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listProcedimentoPecuarioCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findProcedimentoPecuarioCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deleteProcedimentoPecuarioCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

