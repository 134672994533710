import { takeLatest, put, call } from 'redux-saga/effects';

import { getLancamentos } from '../service';
import { storage } from '@commons/storage';
import {
  LIST_TRANSF_CONTA_BANCO_CAIXA,
  LIST_TRANSF_CONTA_BANCO_CAIXA_PENDING,
  LIST_TRANSF_CONTA_BANCO_CAIXA_SUCCESS,
  LIST_TRANSF_CONTA_BANCO_CAIXA_FAILURE
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';

function* sagaListTransferenciaContaBancoCaixa(action) {
  try {
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

    // typePeriodo = 0 -> periodo manual
    // typePeriodo = 1 -> hoje
    // typePeriodo = 2 -> esta semana
    // typePeriodo = 3 -> mes passado
    // typePeriodo = 4 -> este mes
    // typePeriodo = 5 -> proximo mes
    // typePeriodo = 6 -> este ano
    let datas = '';
    let dataInicial = '';
    let dataFinal = '';
    var date = new Date();
    if (action.typePeriodo === 0) {
      dataInicial = action.args.dataInicial;
      dataFinal = action.args.dataFinal;

      datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
    } else if (action.typePeriodo === 1) {
      dataInicial = formatDateYYYYMMDD(new Date(date));
      dataFinal = formatDateYYYYMMDD(new Date(date));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
    }
    else if (action.typePeriodo === 2) {
      const primeiro = date.getDate() - date.getDay();

      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
    } else if (action.typePeriodo === 3) {
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
    } else if (action.typePeriodo === 4) {
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
    } else if (action.typePeriodo === 5) {
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
    } else if (action.typePeriodo === 6) {
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
    }

    yield put({ type: LIST_TRANSF_CONTA_BANCO_CAIXA_PENDING })

    const list = yield call(getLancamentos, idFazenda, datas)

    yield put({
      type: LIST_TRANSF_CONTA_BANCO_CAIXA_SUCCESS, list: list, newFilter: {
        ...action.args,
        dataInicial,
        dataFinal,
      }
    })
  } catch (error) {

    yield put({ type: LIST_TRANSF_CONTA_BANCO_CAIXA_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListTransferenciaContaBancoCaixa() {
  yield takeLatest(LIST_TRANSF_CONTA_BANCO_CAIXA, sagaListTransferenciaContaBancoCaixa)
}