import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

class BreadcrumbOnFarmCorporate extends React.Component {
    render() {
        const { loginReducer } = this.props;

        return (
            <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                    {loginReducer.user == null ?
                        <Link to="/home">Página Inicial</Link> :
                        (loginReducer.user.namePartnerWhiteLabel === undefined || loginReducer.user.namePartnerWhiteLabel === null || loginReducer.user.namePartnerWhiteLabel === ''
                            ? <Link to="/">{loginReducer.user.accountNameWhiteLabel ?? ''}</Link>
                            : <Link to="/home">{loginReducer.user.accountNameWhiteLabel + " - " + loginReducer.user.namePartnerWhiteLabel}</Link>)}
                </li>
                {this.props.children}
            </ol>
        );
    }
}

export function mapStateToProps(state) {
    const { loginReducer } = state.entities;
    return { loginReducer };
};

function mapDispatchToProps(dispatch) {
    return {}
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BreadcrumbOnFarmCorporate))