
export const LOGIN = 'LOGIN';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const UPDATE_SESSION_USER = 'UPDATE_SESSION_USER';

export const REGISTER = 'REGISTER';
export const REGISTER_PENDING = 'REGISTER_PENDING';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_PENDING = 'RECOVER_PASSWORD_PENDING';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';

export const HAS_AUTHORITY = 'HAS_AUTHORITY';
export const HAS_AUTHORITY_PENDING = 'HAS_AUTHORITY_PENDING';
export const HAS_AUTHORITY_SUCCESS = 'HAS_AUTHORITY_SUCCESS';
export const HAS_AUTHORITY_FAILURE = 'HAS_AUTHORITY_FAILURE';

export const HAS_AUTHORITY_CORPORATE = 'HAS_AUTHORITY_CORPORATE';
export const HAS_AUTHORITY_CORPORATE_PENDING = 'HAS_AUTHORITY_CORPORATE_PENDING';
export const HAS_AUTHORITY_CORPORATE_SUCCESS = 'HAS_AUTHORITY_CORPORATE_SUCCESS';
export const HAS_AUTHORITY_CORPORATE_FAILURE = 'HAS_AUTHORITY_CORPORATE_FAILURE';

export const HAS_AUTHORITY_PAINEL_AGRARE = 'HAS_AUTHORITY_PAINEL_AGRARE';
export const HAS_AUTHORITY_PENDING_PAINEL_AGRARE = 'HAS_AUTHORITY_PENDING_PAINEL_AGRARE';
export const HAS_AUTHORITY_SUCCESS_PAINEL_AGRARE = 'HAS_AUTHORITY_SUCCESS_PAINEL_AGRARE';
export const HAS_AUTHORITY_FAILURE_PAINEL_AGRARE = 'HAS_AUTHORITY_FAILURE_PAINEL_AGRARE';