import { takeLatest, put, call } from 'redux-saga/effects';

import { listTipoEmbalagemWhiteLabel } from '../service';
import {
  TABLE_TIPO_EMBALAGEM_WHITE_LABEL,
  TABLE_TIPO_EMBALAGEM_WHITE_LABEL_PENDING,
  TABLE_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS,
  TABLE_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE
} from '../actionTypes';

function* sagaListTipoEmbalagemWhiteLabel(action) {
  yield put({ type: TABLE_TIPO_EMBALAGEM_WHITE_LABEL_PENDING })

  try {
    const table = yield call(listTipoEmbalagemWhiteLabel, action.args)
    yield put({ type: TABLE_TIPO_EMBALAGEM_WHITE_LABEL_SUCCESS, table: table })

  } catch (error) {

    yield put({ type: TABLE_TIPO_EMBALAGEM_WHITE_LABEL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListTipoEmbalagemWhiteLabel() {
  yield takeLatest(TABLE_TIPO_EMBALAGEM_WHITE_LABEL, sagaListTipoEmbalagemWhiteLabel)
}