


import watchNewContaPagar from './saga/new';
import watchNewFilterContaPagar from './saga/newFilter';
import watchFindContaPagar from './saga/find';
import watchFindPagamentos from './saga/findPagametos';
import watchCreateContaPagar from './saga/create';
import watchNewFilterNextPageContaPagar from './saga/newFilterNextPage';
import watchDeleteContaPagar from './saga/delete';
import watchEstornarPagamentoContaPagar from './saga/estornarPagamento';

export const contaPagarSaga = [
    watchNewContaPagar(),
    watchNewFilterContaPagar(),
    watchFindContaPagar(),
    watchFindPagamentos(),
    watchCreateContaPagar(),
    watchNewFilterNextPageContaPagar(),
    watchDeleteContaPagar(),
    watchEstornarPagamentoContaPagar(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
