import { takeLatest, put, call } from 'redux-saga/effects';

import { findOutrasSaidaAnimal } from '../service';
import {
  FIND_OUTRAS_SAIDA_ANIMAL,
  FIND_OUTRAS_SAIDA_ANIMAL_PENDING,
  FIND_OUTRAS_SAIDA_ANIMAL_SUCCESS,
  FIND_OUTRAS_SAIDA_ANIMAL_FAILURE
} from '../actionTypes';

function* sagaFindOutrasSaidaAnimal(action) {
  yield put({ type: FIND_OUTRAS_SAIDA_ANIMAL_PENDING, id: action.id })

  try {

    const registro = yield call(findOutrasSaidaAnimal, action.id);

    yield put({ type: FIND_OUTRAS_SAIDA_ANIMAL_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_OUTRAS_SAIDA_ANIMAL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindOutrasSaidaAnimal() {
  yield takeLatest(FIND_OUTRAS_SAIDA_ANIMAL, sagaFindOutrasSaidaAnimal)
}
