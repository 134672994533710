


import watchNewPlantioManejo from './saga/new';
import watchNewPlantioManejoByPlanejamento from './saga/newByPlanejamento';
import watchNewPlantioManejoCustoDireto from './saga/newCustoDireto';
import watchFindPlantioManejo from './saga/find';
import watchFindPlantioManejoCustoDireto from './saga/findCustoDireto';
import watchCreatePlantioManejo from './saga/create';
import watchCreatePlantioManejoCustoDireto from './saga/createCustoDireto';
import watchListPlantioManejo from './saga/listTable';
import watchDeletePlantioManejo from './saga/delete';
import watchDeletePlantioManejoCustoDireto from './saga/deleteCustoDireto';
import watchRelatorioAtividadesTalhaoSinteticoPlantioManejo from './saga/relatorioAtividadesTalhaoSintetico';
import watchRelatorioAtividadesTalhaoAnaliticoPlantioManejo from './saga/relatorioAtividadesTalhaoAnalitico';
import watchSafraPossuiPendenciaEstoque from './saga/getSafraPossuiPendenciaEstoque';
import watchReprocessarEstoquePendente from './saga/reprocessarEstoquePendente';
import watchNewFilterPlantioManejo from './saga/newFilter';
import watchNewFilterNextPagePlantioManejo from './saga/newFilterNextPage';

export const plantioManejoSaga = [
    watchNewPlantioManejo(),
    watchNewPlantioManejoByPlanejamento(),
    watchNewPlantioManejoCustoDireto(),
    watchFindPlantioManejo(),
    watchFindPlantioManejoCustoDireto(),
    watchCreatePlantioManejo(),
    watchCreatePlantioManejoCustoDireto(),
    watchListPlantioManejo(),
    watchDeletePlantioManejo(),
    watchDeletePlantioManejoCustoDireto(),
    watchRelatorioAtividadesTalhaoSinteticoPlantioManejo(),
    watchRelatorioAtividadesTalhaoAnaliticoPlantioManejo(),
    watchSafraPossuiPendenciaEstoque(),
    watchReprocessarEstoquePendente(),
    watchNewFilterPlantioManejo(),
    watchNewFilterNextPagePlantioManejo(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
export * from './entityCustoDireto';
