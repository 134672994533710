import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_ANIMAL_ESPECIE_CADASTRO_RAPIDO,
    CANCEL_ANIMAL_ESPECIE_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelAnimalEspecieCadastroRapido() {
  yield put({ type: CANCEL_ANIMAL_ESPECIE_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelAnimalEspecieCadastroRapido() {
  yield takeLatest(CANCEL_ANIMAL_ESPECIE_CADASTRO_RAPIDO, sagaCancelAnimalEspecieCadastroRapido)
}