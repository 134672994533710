


import watchNewPartnerWhiteLabel from './saga/new';
import watchFindPartnerWhiteLabel from './saga/find';
import watchCreatePartnerWhiteLabel from './saga/create';
import watchListPartnerWhiteLabel from './saga/listTable';
import watchDeletePartnerWhiteLabel from './saga/delete';
import watchCancelPartnerWhiteLabelRapido from './saga/cancel';

export const partnerWhiteLabelSaga = [
    watchNewPartnerWhiteLabel(),
    watchFindPartnerWhiteLabel(),
    watchCreatePartnerWhiteLabel(),
    watchListPartnerWhiteLabel(),
    watchDeletePartnerWhiteLabel(),
    watchCancelPartnerWhiteLabelRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
