import watchCreateStartSession from './saga/create';
import watchSelectFazenda from './saga/select';
import watchSelectSafraFazenda from './saga/selectSafra';
import watchSelectAnoPecuarioFazenda from './saga/selectAnoPecuario';
import watchListSchemes from './saga/listSchemes';

export const startSessionSaga = [
    watchCreateStartSession(),
    watchSelectFazenda(),
    watchSelectSafraFazenda(),
    watchSelectAnoPecuarioFazenda(),
    watchListSchemes()
]

export * from './actionTypes';
export * from './reducer';
