import * as Yup from 'yup';

const newRegistro = {
    descricao: '',
    status: true,
    grupo: null,
    custo: 0,
    dosagemHa: 0,
    unidadeCompra: null,
    unidadeAplicacao: null,
    unidadePopulacao: null,
    formulacao: '',
    aplicacaoEstagio: null,
    populacao: null,
    cultura: null,
    produtoInsumoEstoques: [],
    produtoInsumoFornecedores: [],
    configLancAgricultura: false,
    configLancAgriculturaTipo: null,
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(120, 'Campo deve ter menos do que 120 caracteres')
        .required('Este campo é obrigatório'),
    formulacao: Yup.string()
        .max(250, 'Campo deve ter menos do que 5000 caracteres'),
    grupo: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    unidadeCompra: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    unidadeAplicacao: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    custo: Yup.number()
        .typeError('Este campo é obrigatório')
        .min(0, 'Por Favor, informe um valor válido'),
    // CAMPO DOSAGEM
    dosagemHa: Yup.number().when('grupo', {
        is: (grupo) => (
            grupo === 'ACARICIDA'
            || grupo === 'ADJUVANTE'
            || grupo === 'ADUBO_FOLIAR'
            || grupo === 'DESSECANTE'
            || grupo === 'FUNGICIDA'
            || grupo === 'HERBICIDA'
            || grupo === 'INSETICIDA'
            || grupo === 'MEDICAMENTO'
            || grupo === 'FUNGICIDA_TS'
            || grupo === 'HERBICIDA_FOLHA_LARGA'
            || grupo === 'HERBICIDA_FOLHA_ESTREITA'
            || grupo === 'INSETICIDA_FISIOLOGICO'
            || grupo === 'INSETICIDA_PERCEVEJO_MOSCA_BRANCA'
            || grupo === 'OLEO_MINERAL'
            || grupo === 'OLEO_VEGETAL'
            || grupo === 'INOCULANTE'
            || grupo === 'INOCULANTE_LIQUIDO'
            || grupo === 'INOCULANTE_TURFOSO'
            || grupo === 'INDUTOR_RESISTENCIA'
            || grupo === 'DESALOJANTE'
            || grupo === 'PROTETOR'
            || grupo === 'BIOESTIMULANTE'),
        then: Yup.number()
            .typeError('Este campo é obrigatório')
            .required("Este campo é obrigatório"),
    }),
    // SEMENTE E MUDA
    cultura: Yup.object().when('grupo', {
        is: (grupo) => grupo === 'SEMENTE_E_MUDA',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    // CONFIG LANC AGRICULTURA
    configLancAgriculturaTipo: Yup.string().when('configLancAgricultura', {
        is: (config) => (
            config === true),
        then: Yup.string()
            .typeError('Este campo é obrigatório')
            .required('Este campo é obrigatório'),
    }).nullable(),
    produtoInsumoFornecedores: Yup.array()
        .of(
            Yup.object().shape({
                fornecedor: Yup.object()
                    .typeError('Este campo é obrigatório')
                    .shape({
                        id: Yup.number()
                            .typeError('Este campo é obrigatório')
                            .required("Este campo é obrigatório"),
                    }),
                codigoProdutoFornecedor: Yup.string()
                    .max(50, 'Campo deve ter menos do que 50 caracteres!'),
                tipoEmbalagem: Yup.object().when('possuiConversaoCompra', {
                    is: (possuiConversaoCompra) => possuiConversaoCompra === true,
                    then: Yup.object()
                        .typeError('Este campo é obrigatório')
                        .shape({
                            id: Yup.number()
                                .typeError('Este campo é obrigatório')
                                .required("Este campo é obrigatório"),
                        })
                }).nullable(),
                qtdeEmbalagem: Yup.number().when('possuiConversaoCompra', {
                    is: (possuiConversaoCompra) => possuiConversaoCompra === true,
                    then: Yup.number()
                        .typeError('Este campo é obrigatório')
                        .positive('Por Favor, inlae um valor maior que zero')
                        .required("Este campo é obrigatório")
                }).nullable(),
            })
        ),
});

export const produtoInsumoCadastro = {
    newRegistro,
    validation
}