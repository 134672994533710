import { takeLatest, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';

import { history } from '@commons';

import { deleteCatalagoProdutoWhiteLabel } from '../service';
import {
  DELETE_CATALAGO_PRODUTO_WHITE_LABEL,
  DELETE_CATALAGO_PRODUTO_WHITE_LABEL_PENDING,
  DELETE_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS,
  DELETE_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaDeleteCatalagoProdutoWhiteLabel(action) {
  yield put({ type: DELETE_CATALAGO_PRODUTO_WHITE_LABEL_PENDING, id: action.id })

  try {
    yield call(deleteCatalagoProdutoWhiteLabel, action.id);

    yield put({ type: DELETE_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS, id: action.id });

    swal({
      title: 'Excluído!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      history.go(0)
    });

  } catch (error) {

    yield put({ type: DELETE_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE });

    AlertError('Falha ao excluir o registro! Tente novamente...');
  }
}

export default function* watchDeleteCatalagoProdutoWhiteLabel() {
  yield takeLatest(DELETE_CATALAGO_PRODUTO_WHITE_LABEL, sagaDeleteCatalagoProdutoWhiteLabel)
}
