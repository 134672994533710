import { takeLatest, put, call } from 'redux-saga/effects';

import { findGrupoContaAgrareWhiteLabel } from '../service';
import {
  FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL,
  FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL_PENDING,
  FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS,
  FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL_FAILURE
} from '../actionTypes';

function* sagaFindGrupoContaAgrareWhiteLabel(action) {
  yield put({ type: FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL_PENDING, id: action.id })

  try {

    const registro = yield call(findGrupoContaAgrareWhiteLabel, action.id);

    yield put({ type: FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindGrupoContaAgrareWhiteLabel() {
  yield takeLatest(FIND_GRUPO_CONTA_AGRARE_WHITE_LABEL, sagaFindGrupoContaAgrareWhiteLabel)
}
