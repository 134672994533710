import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { httpAuthService } from '@commons';
import { path } from '@commons/path';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }


    render() {
        const { loginReducer } = this.props;

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <i className="mdi mdi-cowboy font-size-1 item-menu-color-intranet" style={{ marginRight: '5px' }} />
                        <span className="item-menu-color-intranet">{loginReducer.user.name.split(' ').shift()} {"  "}</span>
                        <i className="fa fa-angle-down item-menu-color-intranet" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag="a" href="/meuPerfil"><i className="mdi mdi-account-circle font-size-17 align-middle mr-1"></i>Meu perfil</DropdownItem>
                        <hr />
                        {/* <DropdownItem tag="a" href="https://help.agrare.com.br" target='_blank'><i className="mdi mdi-help font-size-17 align-middle mr-1"></i>Ajuda Online</DropdownItem> */}
                        <DropdownItem tag="a" href="https://forms.gle/pmP8qsG1MDYyLaCk8" target='_blank'><i className="mdi mdi-comment-question-outline font-size-17 align-middle mr-1"></i>Deixe seu Feedback</DropdownItem>
                        <DropdownItem tag="a" href="https://forms.gle/zJtvoW8VJi89Vetp9" target='_blank'><i className="mdi mdi-lightbulb font-size-17 align-middle mr-1"></i>Sugira uma Funcionalidade</DropdownItem>
                        <DropdownItem tag="a" href="https://agrare.com.br/termos" target='_blank'><i className="mdi mdi-alert font-size-17 align-middle mr-1"></i>Termos de Uso</DropdownItem>
                        <div className="dropdown-divider"></div>
                        <Link
                            to="/login"
                            onClick={() => httpAuthService.logout({ path })}
                            className="dropdown-item">
                            <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
                            <span>Sair do Sistema</span>
                        </Link>

                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export function mapStateToProps(state) {
    const { loginReducer } = state.entities;
    return { loginReducer };
};

function mapDispatchToProps(dispatch) {
    return {}
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileMenu))



