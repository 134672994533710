import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllImplantacaoEstoqueProducao } from '../service';
import {
  LIST_IMPLANTACAO_ESTOQUE_PRODUCAO,
  LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING,
  LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS,
  LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE
} from '../actionTypes';

function* sagaListAllImplantacaoEstoqueProducao(action) {
  yield put({ type: LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_PENDING })

  try {
    const list = yield call(findAllImplantacaoEstoqueProducao)
    
    yield put({ type: LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_IMPLANTACAO_ESTOQUE_PRODUCAO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllImplantacaoEstoqueProducao() {
  yield takeLatest(LIST_IMPLANTACAO_ESTOQUE_PRODUCAO, sagaListAllImplantacaoEstoqueProducao)
}