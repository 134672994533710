


import watchNewEstagioAplicacaoCadastro from './saga/new';
import watchFindEstagioAplicacaoCadastro from './saga/find';
import watchCreateEstagioAplicacaoCadastro from './saga/create';
import watchListEstagioAplicacaoCadastro from './saga/listTable';
import watchDeleteEstagioAplicacaoCadastro from './saga/delete';
import watchCancelEstagioAplicacaoCadastroRapido from './saga/cancel';

export const estagioAplicacaoCadastroSaga = [
    watchNewEstagioAplicacaoCadastro(),
    watchFindEstagioAplicacaoCadastro(),
    watchCreateEstagioAplicacaoCadastro(),
    watchListEstagioAplicacaoCadastro(),
    watchDeleteEstagioAplicacaoCadastro(),
    watchCancelEstagioAplicacaoCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
