import { takeLatest, put } from 'redux-saga/effects';

import {
  CANCEL_DESPESA_DIRETA_CADASTRO_RAPIDO,
  CANCEL_DESPESA_DIRETA_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelDespesaDiretaCadastroRapido() {
  yield put({ type: CANCEL_DESPESA_DIRETA_CADASTRO_RAPIDO_SUCCESS })
}

export default function* watchCancelDespesaDiretaCadastroRapido() {
  yield takeLatest(CANCEL_DESPESA_DIRETA_CADASTRO_RAPIDO, sagaCancelDespesaDiretaCadastroRapido)
}