import { httpService } from '@commons';


export function createTipoEmbalagemWhiteLabel(payload) {
  return httpService.postApiManager(`/tipoEmbalagemWhiteLabel`, payload);
}

export function updateTipoEmbalagemWhiteLabel(payload) {
  const { id } = payload

  return httpService.putApiManager(`/tipoEmbalagemWhiteLabel/${id}`, payload);
}

export function findTipoEmbalagemWhiteLabel(id) {
  return httpService.getApiManager(`/tipoEmbalagemWhiteLabel/${id}`, true);
}

export function listTipoEmbalagemWhiteLabel(param) {
  return httpService.getApiManager(`/tipoEmbalagemWhiteLabel/${param ?? ''}`, true);
}

export function deleteTipoEmbalagemWhiteLabel(id) {
  return httpService.delApiManager(`/tipoEmbalagemWhiteLabel/${id}`);
}