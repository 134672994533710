
export const CONTA_AGRARE = 'CONTA_AGRARE';
export const CONTA_AGRARE_PENDING = 'CONTA_AGRARE_PENDING';
export const CONTA_AGRARE_SUCCESS = 'CONTA_AGRARE_SUCCESS';
export const CONTA_AGRARE_FAILURE = 'CONTA_AGRARE_FAILURE';

export const TABLE_CONTA_AGRARE = 'TABLE_CONTA_AGRARE';
export const TABLE_CONTA_AGRARE_PENDING = 'TABLE_CONTA_AGRARE_PENDING';
export const TABLE_CONTA_AGRARE_SUCCESS = 'TABLE_CONTA_AGRARE_SUCCESS';
export const TABLE_CONTA_AGRARE_FAILURE = 'TABLE_CONTA_AGRARE_FAILURE';

export const FIND_CONTA_AGRARE = 'FIND_CONTA_AGRARE';
export const FIND_CONTA_AGRARE_PENDING = 'FIND_CONTA_AGRARE_PENDING';
export const FIND_CONTA_AGRARE_SUCCESS = 'FIND_CONTA_AGRARE_SUCCESS';
export const FIND_CONTA_AGRARE_FAILURE = 'FIND_CONTA_AGRARE_FAILURE';

export const UPDATE_CONTA_AGRARE = 'UPDATE_CONTA_AGRARE';
export const UPDATE_CONTA_AGRARE_PENDING = 'UPDATE_CONTA_AGRARE_PENDING';
export const UPDATE_CONTA_AGRARE_SUCCESS = 'UPDATE_CONTA_AGRARE_SUCCESS';
export const UPDATE_CONTA_AGRARE_FAILURE = 'UPDATE_CONTA_AGRARE_FAILURE';

export const NEW_FILTER_CONTA_AGRARE = 'NEW_FILTER_CONTA_AGRARE'
export const NEW_FILTER_CONTA_AGRARE_PEDING = 'NEW_FILTER_CONTA_AGRARE_PEDING'
export const NEW_FILTER_CONTA_AGRARE_SUCCESS = 'NEW_FILTER_CONTA_AGRARE_SUCCESS'
export const NEW_FILTER_CONTA_AGRARE_FAILURE = 'NEW_FILTER_CONTA_AGRARE_FAILURE'

export const NEW_FILTER_NEXT_PAGE_CONTA_AGRARE = 'NEW_FILTER_NEXT_PAGE_CONTA_AGRARE'
export const NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_PENDING = 'NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_PENDING'
export const NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_SUCCESS = 'NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_FAILURE = 'NEW_FILTER_NEXT_PAGE_CONTA_AGRARE_FAILURE'