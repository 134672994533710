import { takeLatest, put, call } from 'redux-saga/effects';

import { findIdentificadorCadastro } from '../service';
import {
  FIND_IDENTIFICADOR_CADASTRO,
  FIND_IDENTIFICADOR_CADASTRO_PENDING,
  FIND_IDENTIFICADOR_CADASTRO_SUCCESS,
  FIND_IDENTIFICADOR_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindIdentificadorCadastro(action) {
  yield put({ type: FIND_IDENTIFICADOR_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findIdentificadorCadastro, action.id);

    yield put({ type: FIND_IDENTIFICADOR_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_IDENTIFICADOR_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindIdentificadorCadastro() {
  yield takeLatest(FIND_IDENTIFICADOR_CADASTRO, sagaFindIdentificadorCadastro)
}
