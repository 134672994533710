import {
  NEW_BAIXAR_CONTA_SUCCESS,
  BAIXAR_CONTA_PAGAMENTO_PENDING,
  BAIXAR_CONTA_PAGAMENTO_SUCCESS,
  BAIXAR_CONTA_PAGAMENTO_FAILURE
} from './actionTypes';


const initialState = { contaSelecionada: null, registro: null, loading: false }


function baixaContaReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_BAIXAR_CONTA_SUCCESS:
            return {
                ...state,
                loading: false,
                contaSelecionada: action.contaSelecionada,
                registro: action.registro
            }
        case BAIXAR_CONTA_PAGAMENTO_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case BAIXAR_CONTA_PAGAMENTO_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case BAIXAR_CONTA_PAGAMENTO_FAILURE:
            return {
                ...state,
                contaSelecionada: action.contaSelecionada,
                registro: action.registro,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { baixaContaReducer };