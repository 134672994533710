


import watchNewUsuarioWhiteLabel from './saga/new';
import watchCreateUsuarioWhiteLabel from './saga/create';
import watchListUsuarioWhiteLabel from './saga/list';
import watchDeleteUsuarioWhiteLabel from './saga/delete';
import watchFindUsuarioWhiteLabel from './saga/find';
import watchAtivoInativoUsuarioWhiteLabel from './saga/activeInactive';

export const usuarioWhiteLabelSaga = [
    watchNewUsuarioWhiteLabel(),
    watchCreateUsuarioWhiteLabel(),
    watchListUsuarioWhiteLabel(),
    watchDeleteUsuarioWhiteLabel(),
    watchFindUsuarioWhiteLabel(),
    watchAtivoInativoUsuarioWhiteLabel(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
