import * as Yup from 'yup';

const validation = Yup.object().shape({
    dataInicial: Yup.date()
        .required('Este campo é obrigatório'),
    dataFinal: Yup.date()
        .required('Este campo é obrigatório'),
});

export const dashboardPecuaria = {
    validation
}