import * as Yup from 'yup';

const newRegistro = {
    descricao: '',
    ativo: true,
    tipoOperacao: null,
    geraDuplicata: true,
    movimentaFinanceiro: true,
    lancaProduto: true,
    movimentaEstoque: true,
    contaGerencial: null,
    historico: ''
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(60, 'Campo deve ter menos do que 60 caracteres')
        .required('Este campo é obrigatório'),
    tipoOperacao: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    historico: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(255, 'Campo deve ter menos do que 255 caracteres')
});

export const naturezaOperacaoCadastro = {
    newRegistro,
    validation
}