import { httpService } from '@commons';

export function findUsuarioWhiteLabel(id) {
  return httpService.getApiManager(`/userAuth/${id}`, true);
}

export function getUsersWhiteLabel(args, identificationCode) {
  return httpService.getApiManager(`/userAuth/usersWhiteLabel/${identificationCode}${args ?? ''}`, true);
}

export function registerUserWhiteLabel(payload) {
  return httpService.postApiManager(`/userAuth/registerUserWhiteLabel`, payload);
}

export function updateUserWhiteLabel(payload) {
  const { id } = payload

  return httpService.putApiManager(`/userAuth/${id}`, payload);
}

export function deleteUsuarioWhiteLabel(id) {
  return httpService.delApiManager(`/userAuth/${id}`, true);
}

export function activateUsuarioWhiteLabel(id) {
  return httpService.putApiManager(`/userAuth/activate/${id}`);
}

export function disableUsuarioWhiteLabel(id) {
  return httpService.putApiManager(`/userAuth/disable/${id}`);
}