import { takeLatest, put, call } from 'redux-saga/effects';

import { getLancamentos } from '../service';
import { storage } from '@commons/storage';
import {
  NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO,
  NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO_PENDING,
  NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO_SUCCESS,
  NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO_FAILURE,
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { formatDateDDMMYYYY } from '@components/common/format';
import { CONST_FILTER_MOVTO_BANCO } from '@commons/consts';

function* sagaNewFilterNextPageControleContaBanco(action) {
  yield put({ type: NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO_PENDING })

  try {
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

    let filterSaved = sessionStorage.getItem(CONST_FILTER_MOVTO_BANCO) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_MOVTO_BANCO)) : null;

    const datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

    let newFilter = {
      ...filterSaved,
      rows: action.rows,
      page: action.page,
    };

    let list = yield call(getLancamentos, idFazenda, newFilter.contaBanco?.id ?? -1, datas, `&page=${newFilter.page}&size=${newFilter.rows}${newFilter.where}`);
      
    sessionStorage.setItem(CONST_FILTER_MOVTO_BANCO, JSON.stringify(newFilter));

    yield put({
      type: NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO_SUCCESS, list: list, filter: newFilter
    });
  } catch (error) {

    yield put({ type: NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO_FAILURE })
    // console.error(error) // eslint-disable-line
  }
}

export default function* watchNewFilterNextPageControleContaBanco() {
  yield takeLatest(NEW_FILTER_NEXT_PAGE_CONTROLE_CONTA_BANCO, sagaNewFilterNextPageControleContaBanco)
}