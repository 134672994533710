import {
    NEW_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS,
    CANCEL_PROCEDIMENTO_PECUARIO_CADASTRO_RAPIDO_SUCCESS,
    TABLE_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING,
    TABLE_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS,
    TABLE_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE,
    LIST_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING,
    LIST_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS,
    LIST_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE,
    FIND_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING,
    FIND_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS,
    FIND_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE,
    DELETE_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING,
    DELETE_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS,
    DELETE_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE,
    CREATE_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING,
    CREATE_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS,
    CREATE_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE,
} from './actionTypes';


const initialState = { list: null, loading: false }


function procedimentoPecuarioCadastroReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                isCadastroRapido: action.isCadastroRapido ?? false,
            }
        case LIST_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING:
        case FIND_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING:
        case DELETE_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING:
        case CREATE_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING:
        case TABLE_PROCEDIMENTO_PECUARIO_CADASTRO_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CANCEL_PROCEDIMENTO_PECUARIO_CADASTRO_RAPIDO_SUCCESS:
            return {
                ...state,
                isCadastroRapido: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_PROCEDIMENTO_PECUARIO_CADASTRO_SUCCESS:
        case CREATE_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                isCadastroRapido: false,
            }
        case DELETE_PROCEDIMENTO_PECUARIO_CADASTRO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { procedimentoPecuarioCadastroReducer };