import {
  UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_PENDING,
  UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_SUCCESS,
  UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_FAILURE
} from './actionTypes';


const initialState = { loading: false }


function contaPagarReceberAbsReducer(state = initialState, action) {

    switch (action.type) {
        case UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case UPDATE_NRO_DOC_CONTA_PAGAR_RECEBER_FAILURE:
            return {
                ...state,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { contaPagarReceberAbsReducer };