import watchNewImplantacaoEstoque from './saga/new';
import watchFindImplantacaoEstoque from './saga/find';
import watchCreateImplantacaoEstoque from './saga/create';
import watchListAllImplantacaoEstoque from './saga/listAll';
import watchListImplantacaoEstoque from './saga/listTable';
import watchDeleteImplantacaoEstoque from './saga/delete';

export const implantacaoEstoqueSaga = [
    watchNewImplantacaoEstoque(),
    watchFindImplantacaoEstoque(),
    watchCreateImplantacaoEstoque(),
    watchListAllImplantacaoEstoque(),
    watchListImplantacaoEstoque(),
    watchDeleteImplantacaoEstoque(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
