import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllContaCartaoCadastro } from '../service';
import {
  LIST_CONTA_CARTAO_CADASTRO,
  LIST_CONTA_CARTAO_CADASTRO_PENDING,
  LIST_CONTA_CARTAO_CADASTRO_SUCCESS,
  LIST_CONTA_CARTAO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAllContaCartaoCadastro(action) {
  yield put({ type: LIST_CONTA_CARTAO_CADASTRO_PENDING })

  try {
    const list = yield call(findAllContaCartaoCadastro)
    
    yield put({ type: LIST_CONTA_CARTAO_CADASTRO_SUCCESS, list: list })
  } catch (error) {

    yield put({ type: LIST_CONTA_CARTAO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllContaCartaoCadastro() {
  yield takeLatest(LIST_CONTA_CARTAO_CADASTRO, sagaListAllContaCartaoCadastro)
}