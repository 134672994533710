import watchNewAnimalEspecieCadastro from './saga/new';
import watchFindAnimalEspecieCadastro from './saga/find';
import watchCreateAnimalEspecieCadastro from './saga/create';
import watchListAnimalEspecieCadastro from './saga/listTable';
import watchDeleteAnimalEspecieCadastro from './saga/delete';
import watchCancelAnimalEspecieCadastroRapido from './saga/cancel';

export const animalEspecieCadastroSaga = [
    watchNewAnimalEspecieCadastro(),
    watchFindAnimalEspecieCadastro(),
    watchCreateAnimalEspecieCadastro(),
    watchListAnimalEspecieCadastro(),
    watchDeleteAnimalEspecieCadastro(),
    watchCancelAnimalEspecieCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
