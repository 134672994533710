import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone-uploader";
import Map from "@components/common/Map/Map";
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_MAP_MODE, CREATE_TALHAO_CADASTRO, HAS_AUTHORITY } from "@handler";
import Breadcrumb from '@components/common/Breadcrumb';
import { Link } from 'react-router-dom';

function ImporterKML() {
  const dispatch = useDispatch();
  const [polygons, setPolygons] = useState([]);

  const startSessionReducer = useSelector((state) => state.entities.startSessionReducer);

  useEffect(() => {
    dispatch({ type: HAS_AUTHORITY, role: 'TALHAO' });
  }, []);

  const handleFileChange = (files) => {
    const file = files[0].file;
    const reader = new FileReader();

    reader.onload = (e) => {
      const kmlContent = e.target.result;
      dispatch({ type: CHANGE_MAP_MODE, args: 'KML' });
      extractPolygonsFromKML(kmlContent);
    };

    reader.readAsText(file);
  };

  const extractPolygonsFromKML = (kmlContent) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(kmlContent, 'text/xml');
    const placemarks = xmlDoc.getElementsByTagName('Placemark');

    const extractedPolygons = Array.from(placemarks).map((placemark) => {
      const nameElement = placemark.getElementsByTagName('name')[0];
      const name = nameElement ? nameElement.textContent : ''

      const coordinates = placemark.getElementsByTagName('coordinates')[0].textContent;
      const polygonCoordinates = coordinates.split(' ').map((coordinate) => {
        const [lng, lat] = coordinate.split(',');
        const parsedLat = parseFloat(lat);
        const parsedLng = parseFloat(lng);
        if (isNaN(parsedLat) || isNaN(parsedLng)) {
          return null;
        }
        return { lat: parsedLat, lng: parsedLng };
      }).filter((coordinate) => coordinate !== null);

      // Converter os pontos de coordenadas para o formato google.maps.LatLng
      const path = polygonCoordinates.map(({ lat, lng }) => new google.maps.LatLng(lat, lng));
      // Calcular a área usando a função computeArea do Google Maps
      const areaInSquareMeters = google.maps.geometry.spherical.computeArea(path);

      let area;
      const { unidadeArea } = startSessionReducer.configSession.fazendaSelecionada;

      if (unidadeArea === undefined || unidadeArea === null || unidadeArea.sigla === 'ha') {
        area = convertSquareMetersToHectares(areaInSquareMeters);
      } else if (unidadeArea.sigla === 'alq') {
        area = convertSquareMetersToAlqueires(areaInSquareMeters);
      } else {
        area = convertSquareMetersToAcres(areaInSquareMeters);
      }

      return {
        nome: name,
        mapaPolygonPath: polygonCoordinates,
        realizaMapeamento: true,
        area: area,
      };
    });

    setPolygons(extractedPolygons);
  };

  // Função para converter metros quadrados para hectares
  function convertSquareMetersToHectares(areaInSquareMeters) {
    const areaInHectares = (areaInSquareMeters / 10000).toFixed(2);
    return parseFloat(areaInHectares);
  }

  // Função para converter metros quadrados para acres
  function convertSquareMetersToAcres(areaInSquareMeters) {
    const areaInAcres = (areaInSquareMeters / 4046.86).toFixed(2);
    return parseFloat(areaInAcres);
  }

  // Função para converter metros quadrados para alqueires
  function convertSquareMetersToAlqueires(areaInSquareMeters) {
    const areaInAlqueires = (areaInSquareMeters / 24200).toFixed(2);
    return parseFloat(areaInAlqueires);
  }

  const onSubmit = () => {
    polygons.map((item) => {
      let values = {
        nome: item.nome,
        mapaPolygonPath: google.maps.geometry.encoding.encodePath(item.mapaPolygonPath),
        area: item.area,
        obs: '',
        realizaMapeamento: true,
        status: true,
      }

      dispatch({ type: CREATE_TALHAO_CADASTRO, data: values });
    });
  }

  return (
    <div className="container-fluid">
      <Row className="align-items-center">
        <Col sm={12}>
          <div className="page-title-box">
            <h4 className="font-size-18">Importar KML</h4>
            <Breadcrumb>
              <React.Fragment>
                <li className="breadcrumb-item">
                  <Link to="/cadastros">Cadastros</Link>
                </li>
                <li className="breadcrumb-item active">Talhão - Importar KML</li>
              </React.Fragment>
            </Breadcrumb>
          </div>
        </Col>
      </Row>

      {polygons.length === 0 ? (
        <Row>
          <Col md={12}>
            <Card className='card-default'>
              <Dropzone
                onSubmit={handleFileChange}
                accept=".kml"
                maxFiles={1}
                multiple={false}
                canCancel={false}
                inputContent="Clique aqui ou arraste seu arquivo KML"
                submitButtonContent="Carregar KML"
                styles={{
                  dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                  inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : { color: 'black' }),
                  dropzoneActive: { borderColor: 'gray' },
                  submitButton: { color: 'white', backgroundColor: 'black' },
                }}
              />
            </Card>
          </Col>
        </Row>
      ) : <></>}

      {polygons.length > 0 ? (
        <Row>
          <Col md={12}>
            <Card className='card-default'>
              <CardHeader>
                <h5>Mapeamentos de Áreas Reconhecidas do KML</h5>
              </CardHeader>
              <CardBody>
                <Map
                  isCadastro={false}
                  polygonList={polygons}
                />
              </CardBody>
              <CardFooter>
                <Button onClick={onSubmit} type='submit'>
                  <i className='fa fa-check' /> Importar
                </Button>
                <Button onClick={() => window.location.reload(false)}
                  className="btn btn-light"
                  style={{ marginLeft: '10px' }}>
                  Cancelar Importação
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      ) : <></>}
    </div>
  );
}

export default React.memo(ImporterKML);
