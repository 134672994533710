import { takeLatest, put, call } from 'redux-saga/effects';

import { listPropriedadeCadastro } from '../service';
import {
  TABLE_PROPRIEDADE_CADASTRO,
  TABLE_PROPRIEDADE_CADASTRO_PENDING,
  TABLE_PROPRIEDADE_CADASTRO_SUCCESS,
  TABLE_PROPRIEDADE_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListPropriedadeCadastro(action) {
  yield put({ type: TABLE_PROPRIEDADE_CADASTRO_PENDING })

  try {
    const table = yield call(listPropriedadeCadastro, action.args)
    yield put({ type: TABLE_PROPRIEDADE_CADASTRO_SUCCESS, table: table })

  } catch (error) {

    yield put({ type: TABLE_PROPRIEDADE_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListPropriedadeCadastro() {
  yield takeLatest(TABLE_PROPRIEDADE_CADASTRO, sagaListPropriedadeCadastro)
}