


import watchNewFornecedorCadastro from './saga/new';
import watchFindFornecedorCadastro from './saga/find';
import watchCreateFornecedorCadastro from './saga/create';
import watchListAllFornecedorCadastro from './saga/listAll';
import watchListFornecedorCadastro from './saga/listTable';
import watchDeleteFornecedorCadastro from './saga/delete';
import watchCancelFornecedorCadastroRapido from './saga/cancel';

export const fornecedorCadastroSaga = [
    watchNewFornecedorCadastro(),
    watchFindFornecedorCadastro(),
    watchCreateFornecedorCadastro(),
    watchListAllFornecedorCadastro(),
    watchListFornecedorCadastro(),
    watchDeleteFornecedorCadastro(),
    watchCancelFornecedorCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
