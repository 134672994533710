import {
    TABLE_NDVI_PENDING,
    TABLE_NDVI_SUCCESS,
    TABLE_NDVI_FAILURE,
    LIST_NDVI_PENDING,
    LIST_NDVI_SUCCESS,
    LIST_NDVI_FAILURE,
    LIST_ALL_LATEST_NDVI_SUCCESS,
    LIST_ALL_LATEST_NDVI_PENDING,
    LIST_ALL_LATEST_NDVI_FAILURE,
    FIND_NDVI_PENDING,
    FIND_NDVI_SUCCESS,
    FIND_NDVI_FAILURE,
    SELECT_NDVI_PENDING,
    SELECT_NDVI_SUCCESS,
    SELECT_NDVI_FAILURE,
} from './actionTypes';


const initialState = {
    list: null,
    allLatest: null,
    item: null,
    loading: false,
};


function ndviReducer(state = initialState, action) {

    switch (action.type) {
        case LIST_NDVI_PENDING:
        case LIST_ALL_LATEST_NDVI_PENDING:
        case FIND_NDVI_PENDING:
        case TABLE_NDVI_PENDING:
        case SELECT_NDVI_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_NDVI_SUCCESS:
            return {
                ...state,
                list: action.list,
                item: null,
                loading: false
            }
        case LIST_NDVI_FAILURE:
            return {
                ...state,
                list: null,
                item: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_ALL_LATEST_NDVI_SUCCESS:
            return {
                ...state,
                allLatest: action.allLatest,
                item: null,
                loading: false
            }
        case LIST_ALL_LATEST_NDVI_FAILURE:
            return {
                ...state,
                allLatest: null,
                item: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_NDVI_SUCCESS:
            return {
                ...state,
                table: action.table,
                item: null,
                loading: false
            }
        case TABLE_NDVI_FAILURE:
            return {
                ...state,
                table: null,
                item: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_NDVI_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                item: null,
                loading: false
            }
        case FIND_NDVI_FAILURE:
            return {
                ...state,
                registro: null,
                item: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case SELECT_NDVI_SUCCESS:
            return {
                ...state,
                item: action.item,
                loading: false
            }
        case SELECT_NDVI_FAILURE:
            return {
                ...state,
                item: null,
                loading: false
            }
        default:
            return state
    }
}

export { ndviReducer };