import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { Button, ButtonGroup } from "reactstrap";
import './styles.css';
import PlanningTab from "./tabs/planningTab";
import ExecutionTab from "./tabs/executionTab";
import HarvestTab from "./tabs/harvestTab";
import NdviTab from "./tabs/ndviTab";
import { formatNumber } from "@components/common/format";

function AreaWindow ({
    area,
    onClose,
    setCenter
}) {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);

    const startSessionReducer = useSelector((state) => state.entities.startSessionReducer);
    const { fazendaSelecionada, safraAtiva } = startSessionReducer.configSession;

    const tabs = [
        <PlanningTab area={area} />,
        <ExecutionTab area={area} />,
        <HarvestTab area={area} />,
        <NdviTab area={area} setCenter={setCenter}/>
    ];

    const handleMenuBtnClick = (index) => {
        setActiveTab(index);
    };

    return (
        <React.Fragment>
            <div className="map-area-header">
                <h3>Dados do Talhão</h3>
                <Button className="btn btn-light" onClick={onClose}>
                    <i className='fa fa-arrow-left' />
                </Button>
            </div>

            {/* <img
                className="map-area-image"
                src="https://cdn.pixabay.com/photo/2016/11/07/05/15/africa-1804896_1280.jpg"
            /> */}
            <div className={`map-area-no-image bg-primary-color`}>
                <em className={`fa fa-map fa-3x`} style={{ color: 'white' }} />
            </div>

            <div className="map-area-info">
                <h5>{area.nome}</h5>
                <span>Área: {formatNumber(area.area, 2) + (fazendaSelecionada.unidadeArea === undefined || fazendaSelecionada.unidadeArea === null ? ' ha' : ' ' + fazendaSelecionada.unidadeArea.sigla)}</span>
            </div>

            <div className="map-area-menus">
                <ButtonGroup>
                    <Button
                        className={`btn btn-light ${activeTab === 0 ? 'active' : ''}`}
                        onClick={() => handleMenuBtnClick(0)}
                    >
                        Planejamento
                    </Button>

                    <Button
                        className={`btn btn-light ${activeTab === 1 ? 'active' : ''}`}
                        onClick={() => handleMenuBtnClick(1)}
                    >
                        Execução
                    </Button>

                    <Button
                        className={`btn btn-light ${activeTab === 2 ? 'active' : ''}`}
                        onClick={() => handleMenuBtnClick(2)}
                    >
                        Colheita
                    </Button>

                    <Button
                        className={`btn btn-light ${activeTab === 3 ? 'active' : ''}`}
                        onClick={() => handleMenuBtnClick(3)}
                    >
                        NDVI
                    </Button>
                </ButtonGroup>
            </div>

            <div className="map-area-body">
                {tabs[activeTab]}
            </div>
        </React.Fragment>
    );
}

export default React.memo(AreaWindow);
